import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";

@Component({
  selector: "app-user-settings-panel",
  templateUrl: "./user-settings-panel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsPanelComponent {
  constructor(
    public commonParametersService: CommonParametersService,
    private readonly pensionPlanService: PensionPlanService,
  ) {}

  public onPayoutAgeChange(age: number): void {
    this.pensionPlanService.changeStartPayoutAge(age);
  }
}
