import { Directive, ElementRef } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[href]",
})
export class HrefDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.setAttribute("rel", "noopener noreferrer");
  }
}
