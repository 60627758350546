import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable, combineLatest, map, mergeMap, switchMap, take, tap } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { SavingsGraphqlClientService } from "../../modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "../../modules/graphql-clients/utils/apollo-base-wrapper";
import { BehaviorStore } from "../../utils/rxjs/store";
import {
  GetPublicPensionPrognosisParametersQuery,
  getPublicPensionPrognosisParametersFromResponse,
} from "./public-pension-prognosis-parameters-static.service";
import { SimulationParametersQueriesService } from "./simulation-parameters-queries.service";
import { toAgeAndMonthOffset } from "../prognoses-services/savings-and-pension.service";
import { StartPayoutAgeService } from "../start-payout-age.service";

export interface PublicPensionPrognosisParametersWithStartPayoutAge {
  publicPensionPrognosisParameters: Graph.PublicPensionPrognosisParameters;
  startPayoutAge: number;
  startMonthOffset: number;
}

const GET_DYNAMIC_PUBLIC_PENSION_PROGNOSIS_PARAMETERS_QUERY = gql`
  query getPublicPensionPrognosisParametersQuery($input: PublicPensionPrognosisParametersInput) {
    publicPensionPrognosisParameters(input: $input) {
      canChangeWorkingPercentage
      canChangeWithdrawalPercentage
      canChangeFutureSalary
      statusMessages {
        messageKey
        messageText
        messageType
      }
      ofaVendors {
        vendorName
        ofaVendorServiceTime {
          years
          months
          days
        }
      }
    }
  }
`;

type DynamicPublicPensionPrognosisParameters = Pick<
  Graph.PublicPensionPrognosisParameters,
  | "canChangeWorkingPercentage"
  | "canChangeWithdrawalPercentage"
  | "canChangeFutureSalary"
  | "statusMessages"
  | "ofaVendors"
>;

@Injectable({
  providedIn: "root",
})
export class PublicPensionProgosisParametersDynamicService {
  public readonly dynamicPublicPensionPrognosisParameters$: Observable<DynamicPublicPensionPrognosisParameters>;
  private readonly rootObject$ = new BehaviorStore<DynamicPublicPensionPrognosisParameters>({});

  constructor(
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly savingsGraphqlClient: SavingsGraphqlClientService,
  ) {
    this.dynamicPublicPensionPrognosisParameters$ = this.rootObject$.asObservable();
  }

  public getLatestPensionPrognosisParametersByStartPayoutAge(): Observable<PublicPensionPrognosisParametersWithStartPayoutAge> {
    return combineLatest([
      this.startPayoutAgeService.getStartPayoutAge(),
      this.simulationParametersQueriesService.getRetirementAgesQuery().pipe(take(1)),
    ]).pipe(
      switchMap(([payoutAge, retirementAges]) => {
        const { startPayoutAge, startMonthOffset } = toAgeAndMonthOffset(retirementAges, payoutAge);
        const variables = toQueryPublicPensionPrognosisParametersArgs(startPayoutAge, startMonthOffset);
        return this.getDynamicPensionPrognosisParameters(variables).pipe(
          map((publicPensionPrognosisParameters) => ({
            publicPensionPrognosisParameters,
            startPayoutAge,
            startMonthOffset,
          })),
        );
      }),
    );
  }

  private getDynamicPensionPrognosisParameters(
    variables: Graph.QueryPublicPensionPrognosisParametersArgs = {},
  ): Observable<DynamicPublicPensionPrognosisParameters> {
    return this.savingsGraphqlClient
      .query<GetPublicPensionPrognosisParametersQuery>({
        query: GET_DYNAMIC_PUBLIC_PENSION_PROGNOSIS_PARAMETERS_QUERY,
        variables,
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map(getPublicPensionPrognosisParametersFromResponse),
        tap((publicPensionPrognosisParameters) => this.rootObject$.next(publicPensionPrognosisParameters)),
      );
  }
}

function toQueryPublicPensionPrognosisParametersArgs(
  firstWithdrawalAge: number | undefined,
  firstWithdrawalMonthOffset: number | undefined,
): Graph.QueryPublicPensionPrognosisParametersArgs {
  return {
    input: {
      firstWithdrawalAge,
      firstWithdrawalMonthOffset,
    },
  };
}
