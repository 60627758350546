import { NorskpensjonEngagement } from "src/app/models/engagements/norskpensjon/norskpensjon-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

interface NorskpensjonContractAndPrognosis {
  contract: Graph.NorskpensjonContract;
  prognosis: Graph.Prognosis;
}

export const NORSKPENSJON_INDIVIDUELLE_ORDNINGER_CONTRACT: Graph.NorskpensjonContract = {
  softId:
    "Fri Fantasi A/S-Testproduktbetegnelse kapitalforsikring-individuelleOrdninger-kapitalforsikring-6398191663_test",
  category: "individuelleOrdninger",
  subCategory: "kapitalforsikring",
  pensionProvider: { name: "Fri Fantasi A/S", __typename: "PensionProvider" },
  startAge: 67,
  productDescription: "Testproduktbetegnelse kapitalforsikring",
  note: "Ingen merknad. Eller?",
  endAge: 77,
  employer: { organizationNumber: null, name: "Kantega A/S", __typename: "Employer" },
  contractNumber: "6398191663_test",
  causeNotCalculated: null,
  causeLackingGrading: null,
  holdings: null,
  prognosis: {
    simulationStatus: {
      severity: Graph.SimulationSeverity.Ok,
      messageKey: null,
      __typename: "SimulationStatus",
    },
    payoutPlan: [
      { amount: 11047, age: 67, __typename: "PayoutPlan" },
      { amount: 11047, age: 68, __typename: "PayoutPlan" },
      { amount: 11047, age: 69, __typename: "PayoutPlan" },
      { amount: 11047, age: 70, __typename: "PayoutPlan" },
      { amount: 11047, age: 71, __typename: "PayoutPlan" },
      { amount: 11047, age: 72, __typename: "PayoutPlan" },
      { amount: 11047, age: 73, __typename: "PayoutPlan" },
      { amount: 11047, age: 74, __typename: "PayoutPlan" },
      { amount: 11047, age: 75, __typename: "PayoutPlan" },
      { amount: 11047, age: 76, __typename: "PayoutPlan" },
    ],
    __typename: "Prognosis",
  },
  __typename: "NorskpensjonContract",
};

export const NORSKPENSJON_IPS_CONTRACT: Graph.NorskpensjonContract = {
  subCategory: "ips",
  startAge: 67,
  softId: "Testleverandør 04-Produktbetegnelse Innskudd individuell-individuelleOrdninger-ips-3111604223_test",
  pensionProvider: { name: "Testleverandør 04", __typename: "PensionProvider" },
  productDescription: "Produktbetegnelse Innskudd individuell",
  note: null,
  holdings: {
    totalMarketValue: 500000,
    passiveMarketValue: null,
    activeMarketValue: null,
    __typename: "NorskpensjonHoldings",
  },
  endAge: 80,
  employer: null,
  contractNumber: "3111604223_test",
  causeNotCalculated: null,
  causeLackingGrading: null,
  category: "individuelleOrdninger",
  prognosis: {
    simulationStatus: {
      severity: Graph.SimulationSeverity.Ok,
      messageKey: null,
      __typename: "SimulationStatus",
    },
    payoutPlan: [
      { amount: 85000, age: 67, __typename: "PayoutPlan" },
      { amount: 85000, age: 68, __typename: "PayoutPlan" },
      { amount: 85000, age: 69, __typename: "PayoutPlan" },
      { amount: 85000, age: 70, __typename: "PayoutPlan" },
      { amount: 85000, age: 71, __typename: "PayoutPlan" },
      { amount: 85000, age: 72, __typename: "PayoutPlan" },
      { amount: 85000, age: 73, __typename: "PayoutPlan" },
      { amount: 85000, age: 74, __typename: "PayoutPlan" },
      { amount: 85000, age: 75, __typename: "PayoutPlan" },
      { amount: 85000, age: 76, __typename: "PayoutPlan" },
      { amount: 85000, age: 77, __typename: "PayoutPlan" },
      { amount: 85000, age: 78, __typename: "PayoutPlan" },
      { amount: 85000, age: 79, __typename: "PayoutPlan" },
    ],
    __typename: "Prognosis",
  },
  __typename: "NorskpensjonContract",
};

export const NORSKPENSJON_OFFENTLIGTJENESTEPENSJON_CONTRACT: Graph.NorskpensjonContract = {
  subCategory: null,
  startAge: null,
  softId: "Offentlig tjenestepensjon-Offentlig tjenestepensjon-offentligTjenestepensjon",
  pensionProvider: { name: "Offentlig tjenestepensjon", __typename: "PensionProvider" },
  productDescription: "Offentlig tjenestepensjon",
  note: null,
  holdings: null,
  endAge: null,
  employer: null,
  contractNumber: null,
  causeNotCalculated: null,
  causeLackingGrading: null,
  category: "offentligTjenestepensjon",
  prognosis: {
    simulationStatus: {
      severity: Graph.SimulationSeverity.BusinessError,
      messageKey: "no_payoutperiod",
      __typename: "SimulationStatus",
    },
    payoutPlan: [],
    __typename: "Prognosis",
  },
  __typename: "NorskpensjonContract",
};

export const NORSKPENSJON_PENSJONSKAPITALBEVIS_CONTRACT: Graph.NorskpensjonContract = {
  subCategory: "pensjonskapitalbevis",
  startAge: 67,
  softId:
    "Testleverandør 04-Produktbetegnelse Innskudd kollektiv-privatTjenestepensjon-pensjonskapitalbevis-4111604223_test",
  pensionProvider: { name: "Testleverandør 04", __typename: "PensionProvider" },
  productDescription: "Produktbetegnelse Innskudd kollektiv",
  note: null,
  holdings: {
    totalMarketValue: 543230,
    passiveMarketValue: null,
    activeMarketValue: null,
    __typename: "NorskpensjonHoldings",
  },
  endAge: 77,
  employer: { organizationNumber: null, name: "Testarbeidsgiver", __typename: "Employer" },
  contractNumber: "4111604223_test",
  causeNotCalculated: null,
  causeLackingGrading: null,
  category: "privatTjenestepensjon",
  prognosis: {
    simulationStatus: {
      severity: Graph.SimulationSeverity.Ok,
      messageKey: null,
      __typename: "SimulationStatus",
    },
    payoutPlan: [
      { amount: 89323, age: 67, __typename: "PayoutPlan" },
      { amount: 89323, age: 68, __typename: "PayoutPlan" },
      { amount: 89323, age: 69, __typename: "PayoutPlan" },
      { amount: 89323, age: 70, __typename: "PayoutPlan" },
      { amount: 89323, age: 71, __typename: "PayoutPlan" },
      { amount: 89323, age: 72, __typename: "PayoutPlan" },
      { amount: 89323, age: 73, __typename: "PayoutPlan" },
      { amount: 89323, age: 74, __typename: "PayoutPlan" },
      { amount: 89323, age: 75, __typename: "PayoutPlan" },
      { amount: 89323, age: 76, __typename: "PayoutPlan" },
    ],
    __typename: "Prognosis",
  },
  __typename: "NorskpensjonContract",
};

export const NORSKPENSJON_INNSKUDDSPENSJON_CONTRACT: Graph.NorskpensjonContract = {
  subCategory: "innskuddspensjon",
  startAge: 67,
  softId:
    "Testleverandør 04-Produktbetegnelse Innskudd kollektiv-privatTjenestepensjon-innskuddspensjon-4111604224_test",
  pensionProvider: { name: "Testleverandør 04", __typename: "PensionProvider" },
  productDescription: "Produktbetegnelse Innskudd kollektiv",
  note: null,
  holdings: {
    totalMarketValue: 2171730,
    passiveMarketValue: 1447820,
    activeMarketValue: 723910,
    __typename: "NorskpensjonHoldings",
  },
  endAge: 77,
  employer: { organizationNumber: null, name: "Testarbeidsgiver", __typename: "Employer" },
  contractNumber: "4111604224_test",
  causeNotCalculated: null,
  causeLackingGrading: null,
  category: "privatTjenestepensjon",
  prognosis: {
    simulationStatus: {
      severity: Graph.SimulationSeverity.Ok,
      messageKey: null,
      __typename: "SimulationStatus",
    },
    payoutPlan: [
      { amount: 287173, age: 67, __typename: "PayoutPlan" },
      { amount: 287173, age: 68, __typename: "PayoutPlan" },
      { amount: 287173, age: 69, __typename: "PayoutPlan" },
      { amount: 287173, age: 70, __typename: "PayoutPlan" },
      { amount: 287173, age: 71, __typename: "PayoutPlan" },
      { amount: 287173, age: 72, __typename: "PayoutPlan" },
      { amount: 287173, age: 73, __typename: "PayoutPlan" },
      { amount: 287173, age: 74, __typename: "PayoutPlan" },
      { amount: 287173, age: 75, __typename: "PayoutPlan" },
      { amount: 287173, age: 76, __typename: "PayoutPlan" },
    ],
    __typename: "Prognosis",
  },
  __typename: "NorskpensjonContract",
};

export const NORSKPENSJON_KAPITALFORSIKRING_CONTRACT: Graph.NorskpensjonContract = {
  subCategory: "kapitalforsikring",
  startAge: 67,
  softId:
    "Fri Fantasi A/S-Testproduktbetegnelse kapitalforsikring-individuelleOrdninger-kapitalforsikring-7111604223_test",
  pensionProvider: { name: "Fri Fantasi A/S", __typename: "PensionProvider" },
  productDescription: "Testproduktbetegnelse kapitalforsikring",
  note: "Ingen merknad. Eller?",
  holdings: {
    totalMarketValue: 30580,
    passiveMarketValue: null,
    activeMarketValue: null,
    __typename: "NorskpensjonHoldings",
  },
  endAge: 77,
  employer: { organizationNumber: null, name: "Kantega A/S", __typename: "Employer" },
  contractNumber: "7111604223_test",
  causeNotCalculated: null,
  causeLackingGrading: null,
  category: "individuelleOrdninger",
  prognosis: {
    simulationStatus: {
      severity: Graph.SimulationSeverity.Ok,
      messageKey: null,
      __typename: "SimulationStatus",
    },
    payoutPlan: [
      { amount: 11047, age: 67, __typename: "PayoutPlan" },
      { amount: 11047, age: 68, __typename: "PayoutPlan" },
      { amount: 11047, age: 69, __typename: "PayoutPlan" },
      { amount: 11047, age: 70, __typename: "PayoutPlan" },
      { amount: 11047, age: 71, __typename: "PayoutPlan" },
      { amount: 11047, age: 72, __typename: "PayoutPlan" },
      { amount: 11047, age: 73, __typename: "PayoutPlan" },
      { amount: 11047, age: 74, __typename: "PayoutPlan" },
      { amount: 11047, age: 75, __typename: "PayoutPlan" },
      { amount: 11047, age: 76, __typename: "PayoutPlan" },
    ],
    __typename: "Prognosis",
  },
  __typename: "NorskpensjonContract",
};

export const NORSKPENSJON_CONTRACTS: Graph.NorskpensjonContract[] = [
  NORSKPENSJON_PENSJONSKAPITALBEVIS_CONTRACT,
  NORSKPENSJON_INNSKUDDSPENSJON_CONTRACT,
  NORSKPENSJON_KAPITALFORSIKRING_CONTRACT,
  NORSKPENSJON_OFFENTLIGTJENESTEPENSJON_CONTRACT,
  NORSKPENSJON_IPS_CONTRACT,
];

export const TEN_YEAR_PAYOUT_PLAN: Graph.PayoutPlan[] = [
  { amount: 11047, age: 67, endDate: "2025-02-28", startDate: "2024-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 68, endDate: "2026-02-28", startDate: "2025-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 69, endDate: "2027-02-28", startDate: "2026-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 70, endDate: "2028-02-28", startDate: "2027-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 71, endDate: "2029-02-28", startDate: "2028-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 72, endDate: "2030-02-28", startDate: "2029-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 73, endDate: "2031-02-28", startDate: "2030-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 74, endDate: "2032-02-28", startDate: "2031-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 75, endDate: "2033-02-28", startDate: "2032-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 76, endDate: "2034-02-28", startDate: "2033-03-01", __typename: "PayoutPlan" },
];

export const TWENTY_YEAR_PAYOUT_PLAN: Graph.PayoutPlan[] = [
  { amount: 11047, age: 67, endDate: "2025-02-28", startDate: "2024-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 68, endDate: "2026-02-28", startDate: "2025-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 69, endDate: "2027-02-28", startDate: "2026-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 70, endDate: "2028-02-28", startDate: "2027-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 71, endDate: "2029-02-28", startDate: "2028-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 72, endDate: "2030-02-28", startDate: "2029-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 73, endDate: "2031-02-28", startDate: "2030-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 74, endDate: "2032-02-28", startDate: "2031-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 75, endDate: "2033-02-28", startDate: "2032-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 76, endDate: "2034-02-28", startDate: "2033-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 78, endDate: "2035-02-28", startDate: "2034-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 79, endDate: "2036-02-28", startDate: "2035-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 80, endDate: "2037-02-28", startDate: "2036-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 81, endDate: "2038-02-28", startDate: "2037-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 82, endDate: "2039-02-28", startDate: "2038-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 83, endDate: "2040-02-28", startDate: "2039-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 84, endDate: "2041-02-28", startDate: "2040-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 85, endDate: "2042-02-28", startDate: "2041-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 86, endDate: "2043-02-28", startDate: "2042-03-01", __typename: "PayoutPlan" },
  { amount: 11047, age: 87, endDate: "2044-02-28", startDate: "2043-03-01", __typename: "PayoutPlan" },
];

export const NORSKPENSJON_ENGAGEMENTS_AND_PROGNOSIS: NorskpensjonContractAndPrognosis[] = [
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "6398191663_test",
      category: "individuelleOrdninger",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: { __typename: "Employer", organizationNumber: null, name: "Kantega A/S" },
      holdings: null,
      note: "Ingen merknad. Eller?",
      pensionProvider: { __typename: "PensionProvider", name: "Fri Fantasi A/S" },
      productDescription: "Testproduktbetegnelse kapitalforsikring",
      subCategory: "kapitalforsikring",
      softId: "1282050181",
      startAge: 67,
      endAge: 77,
    },
    prognosis: {
      __typename: "Prognosis",
      payoutPlan: [
        { __typename: "PayoutPlan", age: 67, amount: 11047 },
        { __typename: "PayoutPlan", age: 68, amount: 11047 },
        { __typename: "PayoutPlan", age: 69, amount: 11047 },
        { __typename: "PayoutPlan", age: 70, amount: 11047 },
        { __typename: "PayoutPlan", age: 71, amount: 11047 },
        { __typename: "PayoutPlan", age: 72, amount: 11047 },
        { __typename: "PayoutPlan", age: 73, amount: 11047 },
        { __typename: "PayoutPlan", age: 74, amount: 11047 },
        { __typename: "PayoutPlan", age: 75, amount: 11047 },
        { __typename: "PayoutPlan", age: 76, amount: 11047 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "2398191663_test",
      category: "individuelleOrdninger",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: null,
      holdings: {
        __typename: "NorskpensjonHoldings",
        totalMarketValue: 500000,
        passiveMarketValue: null,
        activeMarketValue: null,
      },
      note: "000000004",
      pensionProvider: { __typename: "PensionProvider", name: "Testleverandør 04" },
      productDescription: "Produktbetegnelse Innskudd individuell",
      subCategory: "individuell livrente",
      softId: "-1271389527",
      startAge: 67,
      endAge: 77,
    },
    prognosis: {
      __typename: "Prognosis",
      payoutPlan: [
        { __typename: "PayoutPlan", age: 67, amount: 95000 },
        { __typename: "PayoutPlan", age: 68, amount: 95000 },
        { __typename: "PayoutPlan", age: 69, amount: 95000 },
        { __typename: "PayoutPlan", age: 70, amount: 95000 },
        { __typename: "PayoutPlan", age: 71, amount: 95000 },
        { __typename: "PayoutPlan", age: 72, amount: 95000 },
        { __typename: "PayoutPlan", age: 73, amount: 95000 },
        { __typename: "PayoutPlan", age: 74, amount: 95000 },
        { __typename: "PayoutPlan", age: 75, amount: 95000 },
        { __typename: "PayoutPlan", age: 76, amount: 95000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "3398191663_test",
      category: "privatTjenestepensjon",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: { __typename: "Employer", organizationNumber: null, name: "Testarbeidsgiver" },
      holdings: {
        __typename: "NorskpensjonHoldings",
        totalMarketValue: 616950,
        passiveMarketValue: null,
        activeMarketValue: null,
      },
      note: null,
      pensionProvider: { __typename: "PensionProvider", name: "Testleverandør 04" },
      productDescription: "Produktbetegnelse Innskudd kollektiv",
      subCategory: "pensjonskapitalbevis",
      softId: "420194546",
      startAge: 67,
      endAge: 77,
    },
    prognosis: {
      __typename: "Prognosis",
      payoutPlan: [
        { __typename: "PayoutPlan", age: 67, amount: 95000 },
        { __typename: "PayoutPlan", age: 68, amount: 95000 },
        { __typename: "PayoutPlan", age: 69, amount: 95000 },
        { __typename: "PayoutPlan", age: 70, amount: 95000 },
        { __typename: "PayoutPlan", age: 71, amount: 95000 },
        { __typename: "PayoutPlan", age: 72, amount: 95000 },
        { __typename: "PayoutPlan", age: 73, amount: 95000 },
        { __typename: "PayoutPlan", age: 74, amount: 95000 },
        { __typename: "PayoutPlan", age: 75, amount: 95000 },
        { __typename: "PayoutPlan", age: 76, amount: 95000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "4398191663_test",
      category: "individuelleOrdninger",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: { __typename: "Employer", organizationNumber: null, name: "Norsk Pensjon" },
      holdings: null,
      note: "Laget av DomeneFactoryMockImpl",
      pensionProvider: { __typename: "PensionProvider", name: "Testleverandør 04" },
      productDescription: "TestProduktbetegnelse Ytelse individuell",
      subCategory: "foreningskollektiv",
      softId: "219396655",
      startAge: 67,
      endAge: null,
    },
    prognosis: {
      __typename: "Prognosis",
      payoutPlan: [
        { __typename: "PayoutPlan", age: 67, amount: 50000 },
        { __typename: "PayoutPlan", age: 68, amount: 50000 },
        { __typename: "PayoutPlan", age: 69, amount: 50000 },
        { __typename: "PayoutPlan", age: 70, amount: 50000 },
        { __typename: "PayoutPlan", age: 71, amount: 50000 },
        { __typename: "PayoutPlan", age: 72, amount: 50000 },
        { __typename: "PayoutPlan", age: 73, amount: 50000 },
        { __typename: "PayoutPlan", age: 74, amount: 50000 },
        { __typename: "PayoutPlan", age: 75, amount: 50000 },
        { __typename: "PayoutPlan", age: 76, amount: 50000 },
        { __typename: "PayoutPlan", age: 77, amount: 50000 },
        { __typename: "PayoutPlan", age: 78, amount: 50000 },
        { __typename: "PayoutPlan", age: 79, amount: 50000 },
        { __typename: "PayoutPlan", age: 80, amount: 50000 },
        { __typename: "PayoutPlan", age: 81, amount: 50000 },
        { __typename: "PayoutPlan", age: 82, amount: 50000 },
        { __typename: "PayoutPlan", age: 83, amount: 50000 },
        { __typename: "PayoutPlan", age: 84, amount: 50000 },
        { __typename: "PayoutPlan", age: 85, amount: 50000 },
        { __typename: "PayoutPlan", age: 86, amount: 50000 },
        { __typename: "PayoutPlan", age: 87, amount: 50000 },
        { __typename: "PayoutPlan", age: 88, amount: 50000 },
        { __typename: "PayoutPlan", age: 89, amount: 50000 },
        { __typename: "PayoutPlan", age: 90, amount: 50000 },
        { __typename: "PayoutPlan", age: 91, amount: 50000 },
        { __typename: "PayoutPlan", age: 92, amount: 50000 },
        { __typename: "PayoutPlan", age: 93, amount: 50000 },
        { __typename: "PayoutPlan", age: 94, amount: 50000 },
        { __typename: "PayoutPlan", age: 95, amount: 50000 },
        { __typename: "PayoutPlan", age: 96, amount: 50000 },
        { __typename: "PayoutPlan", age: 97, amount: 50000 },
        { __typename: "PayoutPlan", age: 98, amount: 50000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "5398191663_test",
      category: "privatTjenestepensjon",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: { __typename: "Employer", organizationNumber: null, name: "Norsk Pensjon" },
      holdings: null,
      note: "Laget av DomeneFactoryMockImpl",
      pensionProvider: { __typename: "PensionProvider", name: "Testleverandør 04" },
      productDescription: "TestProduktbetegnelse Ytelse kollektiv",
      subCategory: "fripolise",
      softId: "-552179723",
      startAge: 67,
      endAge: null,
    },
    prognosis: {
      __typename: "Prognosis",
      payoutPlan: [
        { __typename: "PayoutPlan", age: 67, amount: 50000 },
        { __typename: "PayoutPlan", age: 68, amount: 50000 },
        { __typename: "PayoutPlan", age: 69, amount: 50000 },
        { __typename: "PayoutPlan", age: 70, amount: 50000 },
        { __typename: "PayoutPlan", age: 71, amount: 50000 },
        { __typename: "PayoutPlan", age: 72, amount: 50000 },
        { __typename: "PayoutPlan", age: 73, amount: 50000 },
        { __typename: "PayoutPlan", age: 74, amount: 50000 },
        { __typename: "PayoutPlan", age: 75, amount: 50000 },
        { __typename: "PayoutPlan", age: 76, amount: 50000 },
        { __typename: "PayoutPlan", age: 77, amount: 50000 },
        { __typename: "PayoutPlan", age: 78, amount: 50000 },
        { __typename: "PayoutPlan", age: 79, amount: 50000 },
        { __typename: "PayoutPlan", age: 80, amount: 50000 },
        { __typename: "PayoutPlan", age: 81, amount: 50000 },
        { __typename: "PayoutPlan", age: 82, amount: 50000 },
        { __typename: "PayoutPlan", age: 83, amount: 50000 },
        { __typename: "PayoutPlan", age: 84, amount: 50000 },
        { __typename: "PayoutPlan", age: 85, amount: 50000 },
        { __typename: "PayoutPlan", age: 86, amount: 50000 },
        { __typename: "PayoutPlan", age: 87, amount: 50000 },
        { __typename: "PayoutPlan", age: 88, amount: 50000 },
        { __typename: "PayoutPlan", age: 89, amount: 50000 },
        { __typename: "PayoutPlan", age: 90, amount: 50000 },
        { __typename: "PayoutPlan", age: 91, amount: 50000 },
        { __typename: "PayoutPlan", age: 92, amount: 50000 },
        { __typename: "PayoutPlan", age: 93, amount: 50000 },
        { __typename: "PayoutPlan", age: 94, amount: 50000 },
        { __typename: "PayoutPlan", age: 95, amount: 50000 },
        { __typename: "PayoutPlan", age: 96, amount: 50000 },
        { __typename: "PayoutPlan", age: 97, amount: 50000 },
        { __typename: "PayoutPlan", age: 98, amount: 50000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "5398191663_test",
      category: "privatTjenestepensjon",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: {
        __typename: "Employer",
        organizationNumber: null,
        name: "Duplicated contractIdentifier Payer",
      },
      holdings: null,
      note: "Laget av DomeneFactoryMockImpl",
      pensionProvider: {
        __typename: "PensionProvider",
        name: "Fripolis Duplicated ContractIdentifier",
      },
      productDescription: "Duplicated ContractIdentifier",
      subCategory: "fripolise",
      softId: "190580933",
      startAge: 67,
      endAge: 68,
    },
    prognosis: {
      payoutPlan: [
        { age: 67, amount: 9000 },
        { age: 68, amount: 9000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "5398191663_test",
      category: "privatTjenestepensjon",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: {
        __typename: "Employer",
        organizationNumber: null,
        name: "Duplicated contractIdentifier Payer",
      },
      holdings: null,
      note: "Laget av DomeneFactoryMockImpl",
      pensionProvider: {
        __typename: "PensionProvider",
        name: "Fripolis Duplicated ContractIdentifier",
      },
      productDescription: "Duplicated ContractIdentifier",
      subCategory: "fripolise",
      softId: "1144809828",
      startAge: 67,
      endAge: 68,
    },
    prognosis: {
      payoutPlan: [
        { age: 67, amount: 10000 },
        { age: 68, amount: 10000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
  {
    contract: {
      __typename: "NorskpensjonContract",
      contractNumber: "5398191663_test",
      category: "privatTjenestepensjon",
      causeLackingGrading: null,
      causeNotCalculated: null,
      employer: {
        __typename: "Employer",
        organizationNumber: null,
        name: "Duplicated contractIdentifier Payer",
      },
      holdings: null,
      note: "Laget av DomeneFactoryMockImpl",
      pensionProvider: {
        __typename: "PensionProvider",
        name: "Fripolis Duplicated ContractIdentifier",
      },
      productDescription: "Duplicated ContractIdentifier",
      subCategory: "fripolise",
      softId: "2099038723",
      startAge: 67,
      endAge: 68,
    },
    prognosis: {
      payoutPlan: [
        { age: 67, amount: 11000 },
        { age: 68, amount: 11000 },
      ],
      simulationStatus: {
        __typename: "SimulationStatus",
        severity: Graph.SimulationSeverity.Ok,
        messageKey: null,
      },
    },
  },
];

const emptyNorskpensjonContract: Partial<Graph.NorskpensjonContract> = {
  pensionProvider: {},
  holdings: {},
  employer: {},
  prognosis: {},
};

export function createNorskpensjonEngagementFromContract(
  contract: Graph.NorskpensjonContract,
  override = emptyNorskpensjonContract,
): NorskpensjonEngagement {
  const overridenContract: Graph.NorskpensjonContract = {
    ...contract,
    ...override,
    pensionProvider: { ...contract.pensionProvider, ...override.pensionProvider },
    holdings: { ...contract.holdings, ...override.holdings },
    employer: { ...contract.employer, ...override.employer },
    prognosis: { ...contract.prognosis, ...override.prognosis },
  };
  const { prognosis, ...restContract } = overridenContract;

  return new NorskpensjonEngagement(restContract, <Graph.Prognosis>prognosis);
}
