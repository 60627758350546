import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";

@Component({
  selector: "app-action-withdrawal-order-explanation",
  templateUrl: "./action-withdrawal-order-explanation.component.html",
  styleUrls: ["./action-withdrawal-order-explanation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionWithdrawalOrderExplanationComponent {
  constructor(private readonly fmsService: FmsService) {}

  public openArticle(): void {
    window.open(this.fmsService.instant("actions.withdrawalOrder.explanation.linkUrl"), "_blank");
  }
}
