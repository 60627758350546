import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { combineLatestWith, first, map } from "rxjs/operators";
import { ICON_BASE_PATH } from "src/app/constants/technical.constants";
import { FmsService } from "src/app/services/fms.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { SessionStorageService, StorageKey } from "src/app/services/session-storage.service";

@Component({
  selector: "app-bank-accounts-auth-step-up",
  templateUrl: "./bank-accounts-auth-step-up.component.html",
  styleUrls: ["./bank-accounts-auth-step-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountsAuthStepUpComponent {
  public altTitle = this.fmsService.instant("common.closeIcon");

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly fmsService: FmsService,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public getShouldShowStepUpCard(): Observable<boolean> {
    return this.keycloakService.isAuthLevel3or4$.pipe(
      combineLatestWith(this.keycloakService.isAdvisorContext$),
      first(),
      map(([isAuthLevel4, isAdvisorContext]) => {
        const hasNotClosedAuthStepupBefore = !this.getAuthStepupClosed();
        const hasUsedInferiorLoginMethod = !isAuthLevel4;
        const isPrivateIndividual = !isAdvisorContext;

        return hasNotClosedAuthStepupBefore && hasUsedInferiorLoginMethod && isPrivateIndividual;
      }),
    );
  }

  public stepUpAuth(): void {
    this.keycloakService.stepUpAuth();
  }

  public getCloseIconPath(): string {
    return `${ICON_BASE_PATH}/icons/01-interface-essential/remove-delete.svg`;
  }

  public getAuthStepupClosed(): boolean {
    return this.sessionStorageService.get<boolean>(StorageKey.AuthStepupClosed, false);
  }

  public closeStepUp(): void {
    this.sessionStorageService.set(StorageKey.AuthStepupClosed, true);
  }
}
