import { Injectable } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, first, map, skip, switchMap } from "rxjs/operators";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
  toFetchPrognosisResult,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { MissingAfpPrognosisError } from "src/app/utils/prognosis-errors";
import { combinePredicates } from "src/app/utils/utils";
import { select } from "../../utils/rxjs/select";
import { SimulationParametersQueriesService } from "../api/simulation-parameters-queries.service";
import { createFullWithdrawalVariables, WithdrawalPeriodsParams } from "../api/withdrawal-periods-variables.utils";
import { ClientDataService } from "../customer-supplied-data/client-data.service";
import { ProfileService } from "../customer-supplied-data/profile.service";
import { FetchAfpFromNorskpensjonService } from "../fetch-afp-from-norskpensjon.service";
import { IncomeService } from "../income/income.service";
import { NorskpensjonRequirementsService } from "./norskpensjon-requirements.service";
import { createGraphSimulationError, SimulationErrorCmsKey } from "./simulation-result.creators";

type AfpPrognosisParams = WithdrawalPeriodsParams;

export const AFP_CONFIG: ServiceConfig = {
  name: "AfpService",
  fmsKey: "afp",
};

@Injectable({
  providedIn: "root",
})
export class AfpService extends AbstractPrognosisFetchService<AfpEngagement, AfpPrognosisParams> {
  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly fetchAfpFromNorskpensjonService: FetchAfpFromNorskpensjonService,
    private readonly incomeService: IncomeService,
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
    private readonly profileService: ProfileService,
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly clientDataService: ClientDataService,
  ) {
    super(AFP_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);

    this.fetchEngagementsOnRequirementsChange().subscribe();
  }

  public fetchPrognosis(_: AfpEngagement, params: AfpPrognosisParams): Observable<FetchPrognosisResult<AfpEngagement>> {
    const norskpensjonArgs = createFullWithdrawalVariables(params, select(this.profileService.hasAfp$));

    return this.fetchAfpFromNorskpensjonService.fetchPrognosis(norskpensjonArgs).pipe(
      map((prognosis) => toFetchPrognosisResult(prognosis, norskpensjonArgs)),
      catchError(() => of({ prognoses: [] })),
    );
  }

  public prognosisSimParams$(): Observable<AfpPrognosisParams> {
    return combineLatest([
      this.incomeService.getAnnualGrossIncomeOrDefault(0),
      this.commonParametersService.postPensionPartTimePercent$,
      this.startPayoutAgeService.getStartPayoutAge(),
      this.clientDataService.simulationParametersPartialWithdrawalEnable$.pipe(map(({ enable }) => enable)),
      this.clientDataService.simulationParameters$,
      this.simulationParametersQueriesService.getRetirementAgesQuery().pipe(catchError(() => of([]))),
    ]).pipe(
      map(([salary, partTimePercent, startPayoutAge, isPartialWithdrawal, simulationParameters, retirementAges]) => {
        return {
          salary,
          partTimePercent,
          startPayoutAge: isPartialWithdrawal
            ? simulationParameters.startPayoutAgePartialWithdrawal ?? startPayoutAge
            : startPayoutAge,
          retirementAges,
        };
      }),
    );
  }

  protected _fetchEngagements(): Observable<AfpEngagement[]> {
    const VALID_ENGAGEMENT$ = of([new AfpEngagement({})]);
    const NO_ENGAGEMENT$ = of([] as AfpEngagement[]);

    return this.isAllowedToFetchContracts().pipe(
      first(),
      switchMap((isAllowedToFetchContracts) => (isAllowedToFetchContracts ? VALID_ENGAGEMENT$ : NO_ENGAGEMENT$)),
    );
  }

  protected composeFailedPrognosis(_: AfpEngagement, isAgeHigherThanMaxSimAge?: boolean | undefined): Graph.Prognosis {
    const key = isAgeHigherThanMaxSimAge ? SimulationErrorCmsKey.AboveMaxAgeError : SimulationErrorCmsKey.GenericError;
    return {
      simulationStatus: createGraphSimulationError(key),
    };
  }

  protected composeMissingPrognosisError(engagement: AfpEngagement): MissingAfpPrognosisError {
    return new MissingAfpPrognosisError(engagement);
  }

  private fetchEngagementsOnRequirementsChange(): Observable<AfpEngagement[]> {
    return this.isAllowedToFetchContracts().pipe(
      skip(1),
      switchMap(() => this.fetchEngagements()),
    );
  }

  private isAllowedToFetchContracts(): Observable<boolean> {
    return combinePredicates([
      this.norskpensjonRequirementsService.getHasAllRequirements(),
      this.profileService.hasAfp$,
    ]);
  }
}
