import { formatCurrency } from "@angular/common";
import { isEmpty, isEqual, range } from "lodash-es";
import { catchError, combineLatest, firstValueFrom, map, mergeMap, Observable, of, take } from "rxjs";
import { currencySanitizerRegex } from "src/app/constants/regex.constants";
import { getIsFiniteNumber } from "src/app/utils/number";
import { MINUS_SIGN, NON_BREAKING_SPACE } from "../constants/technical.constants";
import { getIsEveryItemTrue } from "./array";
import { Monitoring } from "./monitoring";

export enum FormatCurrencyStyle {
  Input,
  View,
  Nullable,
}

export const sanitizeCurrency = (value: string): number => {
  if (typeof value === "string") {
    return parseInt(value.replace(currencySanitizerRegex, ""), 10);
  } else {
    Monitoring.error(
      new Error(`Expected string in sanitizeCurrency(), got ${typeof value}, value: ${JSON.stringify(value)}`),
    );
    return value;
  }
};

const getFiniteNumberOrZero = (value: Nullable<number>): number => (getIsFiniteNumber(value) ? value : 0);

export const formatCurrencyValue = (
  value: Nullable<number | string>,
  formatCurrencyStyle: FormatCurrencyStyle,
): string => {
  const sanitizedValue = getIsString(value) ? sanitizeCurrency(value) : value;

  if (!getIsFiniteNumber(sanitizedValue)) {
    if (FormatCurrencyStyle.Input === formatCurrencyStyle) {
      return `kr${NON_BREAKING_SPACE}`;
    }

    if (FormatCurrencyStyle.View === formatCurrencyStyle) {
      return MINUS_SIGN;
    }

    if (FormatCurrencyStyle.Nullable === formatCurrencyStyle) {
      return "";
    }
  }

  return formatCurrency(getFiniteNumberOrZero(sanitizedValue), "nb", "kr", "", "1.0-0");
};

export const upperCaseFirst = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const lowerCaseFirst = (value: string): string => value.charAt(0).toLowerCase() + value.slice(1);

export const formatPercentage = (val: number): string =>
  val.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

function enumEntries<T extends { [P in keyof T]: T[P] }>(t: T): ReadonlyArray<readonly [string, T[keyof T]]> {
  const entries = Object.entries<T[keyof T]>(t);
  const plainStringEnum = entries.every(([, value]) => typeof value === "string");
  return plainStringEnum ? entries : entries.filter(([, v]) => typeof v !== "string");
}

export function getEnumKeys<T>(t: T): ReadonlyArray<string> {
  return enumEntries(t).map(([key]) => key);
}

export function getEnumValues<T extends { [P in keyof T]: T[P] }>(t: T): Array<T[keyof T]> {
  const values = Object.values<T[keyof T]>(t);
  const plainStringEnum = values.every((value) => typeof value === "string");
  return plainStringEnum ? values : values.filter((v) => typeof v !== "string");
}

export const removeAllSpaces = (input: string): string => input.replace(/\s/gm, "");

export const booleanSort =
  <T>(boolFunction: (c: T) => boolean, reverse: boolean = false) =>
  (a: T, b: T): number =>
    reverse ? Number(boolFunction(a)) - Number(boolFunction(b)) : Number(boolFunction(b)) - Number(boolFunction(a));

export const toHex = (value: number): string => {
  const hex = value.toString(16).toUpperCase();

  return hex.length % 2 === 1 ? "0".concat(hex) : hex;
};

const shiftLeft = (a: number, b: number): number =>
  // eslint-disable-next-line no-bitwise
  a << b;

const to32Bit = (value: number): number =>
  // eslint-disable-next-line no-bitwise
  value & value;

export const hashString = (inString: string, hex = false): string => {
  const hash = range(inString.length)
    .map((i) => inString.charCodeAt(i))
    .reduce((acc, char) => to32Bit(shiftLeft(acc, 5) - acc + char), 0);

  return hex ? toHex(hash) : hash.toString();
};

/**
 * types or native object instances that *CAN NOT* be stringified with JSON.stringify
 * ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
 */
export const nonJsonStringifiableInstanceTypes = [Set, WeakSet, Map, WeakMap, Function, Symbol];

export function naiveObjectComparison<R>(previousObject: R, currentObject: R): boolean {
  return isEqual(previousObject, currentObject);
}

export const hasShadowDom = (): boolean => !!document.head.attachShadow;

/**
 * Random (floating) number
 * @external https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_random
 */
export const random = (a = 1, b = 0, float = false): number =>
  float
    ? Math.min(a, b) + Math.random() * (Math.max(a, b) - Math.min(a, b))
    : Math.floor(
        Math.ceil(Math.min(a, b)) + Math.random() * (Math.floor(Math.max(a, b)) - Math.ceil(Math.min(a, b)) + 1),
      );

const removeQueryParamFromParams = (params: string, paramToRemove: string): string => {
  const param = paramToRemove.split("=");
  const key = param.at(0);
  const value = param.at(1) ?? ".*?";

  return params.replace(new RegExp(`(${key}=${value}(?=&|$)&?)`, "i"), "");
};

export const removeQueryParams = (url: string, paramsToRemove: string[]): string => {
  const parsedUrl = url.split("?");
  const baseUrl = parsedUrl.at(0) || "";
  const originalParams = parsedUrl.at(1);

  if (!originalParams || paramsToRemove.length === 0) {
    return baseUrl;
  }

  const params = paramsToRemove.reduce(removeQueryParamFromParams, originalParams).replace(/&$/, "");

  return params ? `${baseUrl}?${params}` : baseUrl;
};

export const percentCalculator = {
  /**
   * Calculation: `(x / y) * 100`
   */
  xIsWhatPercentageOfY: (x: number, y: number): number => {
    if (typeof x !== "number") {
      Monitoring.error(`[utils::percentCalculator::xIsWhatPercentageOfY] x is of type ${typeof x}. Expected 'number'`);
      return 0;
    }

    if (typeof y !== "number") {
      Monitoring.error(`[utils::percentCalculator::xIsWhatPercentageOfY] y is of type ${typeof y}. Expected 'number'`);
      return 0;
    }

    if (y === 0) {
      Monitoring.error(`[utils::percentCalculator::xIsWhatPercentageOfY] y cannot be equal to zero. Got: ${y}`);
      return 0;
    }

    return (x / y) * 100;
  },
};

export function ignoreReturn<T>(source$: Observable<T>): Observable<void> {
  return source$.pipe(
    take(1),
    mergeMap(() => of<void>()),
    catchError(() => of<void>()),
  );
}

export function firstValueFromIgnoreReturn<T>(source$: Observable<T>): Promise<void> {
  return firstValueFrom(source$).then(() => Promise.resolve());
}

export type Nullable<T> = T | undefined | null;

export function getIsNullable<T>(value: Nullable<T>): value is undefined | null {
  return value === undefined || value === null;
}

export function getIsNotNullable<T>(value: Nullable<T>): value is T {
  return !getIsNullable(value);
}

export function getDifferensePercent(a: number, b: number, fractalDigits = 1): number {
  const difference = a - b;
  const differencePercent = (difference / b) * 100;

  if (differencePercent === Infinity) {
    return 100;
  }

  return Number(differencePercent.toFixed(fractalDigits));
}

export function getIsFalse(value: boolean): boolean {
  return value === false;
}

export function getIsString(value: unknown): value is string {
  return typeof value === "string";
}

export function getIsNotEmpty<T>(value: T): boolean {
  return !isEmpty(value);
}

export function isInMinMax(min: number, max: number, value: number): boolean {
  return min <= value && value <= max;
}

export function combinePredicates(predicates: Observable<boolean>[]): Observable<boolean> {
  return combineLatest(predicates).pipe(map(getIsEveryItemTrue));
}

export function stringToBoolean(booleanString: "true" | "false"): boolean {
  return booleanString === "true";
}

export function removeUrlQueryParamsAndFragment(route: string): string {
  return route.split("?")[0].split("#")[0];
}
