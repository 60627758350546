<stb-form [label]="'publicPensionwithdrawalPercentage.label' | fms" size="flex" [disabled]="disabled">
  <select
    *ngIf="withdrawalPercentages as percentages"
    [ngModel]="selectedPercent$ | async"
    (change)="onWithdrawalPercentageChange($event)"
    [disabled]="disabled"
  >
    <option *ngFor="let percentage of percentages" [value]="percentage.value">
      {{ percentage.viewValue }}
    </option>
  </select>
</stb-form>
