import { Component, Inject } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
} from "@angular/material/legacy-dialog";

export interface DialogData {
  header: string;
  content: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

@Component({
  selector: "app-modal-dialog",
  templateUrl: "modal-dialog.component.html",
  styleUrls: ["modal-dialog.component.scss"],
})
export class ModalDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly dialog: MatDialog,
  ) {}

  public close(): void {
    const { onButtonClick } = this.data;

    if (onButtonClick) {
      onButtonClick();
    } else {
      this.dialog.closeAll();
    }
  }
}
