import { map, mergeMap, Observable, of } from "rxjs";
import { ContractTypeOf } from "src/app/models/engagements/generic-engagement.model";
import { ItpUnfundedEngagement } from "src/app/models/engagements/savings-and-pension/pension-fund-engagement.model";
import {
  Holding,
  HoldingsAndSavingsProfile,
} from "src/app/modules/shared/services/fetch-holdings-and-allocation.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";
import { isAlisEngagement, isItpUnfundedEngagement } from "src/app/utils/engagement.typeguards";
import { Nullable } from "src/app/utils/utils";

export function getContractNumber(engagement$: Observable<AnyEngagement>): Observable<Nullable<string>> {
  return engagement$.pipe(
    map((engagement) => engagement.getContractNumberCustomer() ?? engagement.getContractNumber()),
  );
}

export function getBalanceFromContractOrContractDetails(
  engagement$: Observable<AnyEngagement>,
  contractDetails$: Observable<ContractTypeOf<AnySavingsAndPensionEngagement>>,
): Observable<Nullable<number>> {
  return engagement$.pipe(
    mergeMap((engagement) => {
      if (isItpUnfundedEngagement(engagement)) {
        return contractDetails$.pipe(
          map((contract) => getBalanceForItpUnfundedEngagement(contract as ContractTypeOf<ItpUnfundedEngagement>)),
        );
      }

      return of(engagement.getBalance());
    }),
  );
}

export function getYearlyPremiumAmount(engagement$: Observable<AnyEngagement>): Observable<Nullable<number>> {
  return engagement$.pipe(
    map((engagement) => (isAlisEngagement(engagement) ? engagement.getPremiumYearlyAmount() : undefined)),
  );
}

export function getIsAnbefaltPensjon(profile$: Observable<HoldingsAndSavingsProfile>): Observable<boolean> {
  return profile$.pipe(map((profile) => profile.isAnbefaltPensjon));
}

export function getHoldings(profile$: Observable<HoldingsAndSavingsProfile>): Observable<Holding[]> {
  return profile$.pipe(map((profile) => profile.holdings));
}

export function getHasHoldings(holdings$: Observable<Holding[]>): Observable<boolean> {
  return holdings$.pipe(map((holdings) => holdings?.length > 0));
}

function getBalanceForItpUnfundedEngagement(contractDetails: ContractTypeOf<ItpUnfundedEngagement>): Nullable<number> {
  return contractDetails.holdings?.totalMarketValue?.value;
}
