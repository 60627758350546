import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Options } from "highcharts";
import { combineLatest, Observable, Subject, switchMap } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { NUMBER_OF_PADDED_COLUMNS_IN_AGREEMENTS_CHART } from "src/app/constants/technical.constants";
import { getOptions } from "src/app/modules/shared/components/charts/agreements-chart/agreements-chart.options";
import { addPaddingToSeriesData, ChartService, NumberedSeriesColumnOptions } from "src/app/services/chart.service";
import { PrintService } from "src/app/services/print.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { AbstractChartContainer } from "../abstract-chart-container";

const CHART_PRINT_WIDTH = 875;

@Component({
  selector: "app-agreements-chart",
  templateUrl: "./agreements-chart.component.html",
  styleUrls: ["./agreements-chart.component.scss", "./agreements-chart.component.tooltip.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AgreementsChartComponent extends AbstractChartContainer implements OnDestroy {
  @Input()
  public showStorebrandOnly = false;

  public options$: Observable<Options>;
  public series$: Observable<NumberedSeriesColumnOptions[]>;
  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly chartService: ChartService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly printService: PrintService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
  ) {
    super();

    this.options$ = this.chartService.chartTranslations$.pipe(map(getOptions));
    const engagementsSeriesOnly$ = this.chartService.agreementsColumnSeries$.pipe(
      this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe(),
    );

    const engagementsAndIncomeSeries$ = combineLatest([
      this.chartService.agreementsColumnSeries$,
      this.chartService.annualGrossIncomeSeries$,
    ]).pipe(
      this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe(),
      map(([agreementsColumnSeries, annualGrossIncomeSeries]) => [
        ...agreementsColumnSeries.map(mapToPaddedSeries),
        annualGrossIncomeSeries,
      ]),
    );

    // The income series in completely removed from the chart in
    // Storebrand only mode. The x-axis is adjusted accordingly in ChartComponent
    this.series$ = this.storebrandOnlyService.getIsEnabled().pipe(
      switchMap((isEnabled) => (isEnabled ? engagementsSeriesOnly$ : engagementsAndIncomeSeries$)),
      distinctUntilChanged(),
      takeUntil(this.destroy$),
    );

    this.printService
      .subscribe({
        before: () => this.setChartSize(CHART_PRINT_WIDTH),
        after: () => this.setChartSize(null),
      })
      .unsubscribe(this.destroy$);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setChartSize(width: number | null): void {
    this.chartInstance?.setSize(width, undefined, false);
  }
}

function mapToPaddedSeries(series: NumberedSeriesColumnOptions): NumberedSeriesColumnOptions {
  return {
    ...series,
    data: addPaddingToSeriesData(series.data, NUMBER_OF_PADDED_COLUMNS_IN_AGREEMENTS_CHART),
  };
}
