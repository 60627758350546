import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  apiJson,
  benefitsCheck,
  clientId,
  consentsApi,
  customerApi,
  customerSuppliedDataApi,
  eligibilityCheckApi,
  epkHubApi,
  fmsApi,
  graphApi,
  prognosisLifeNorskpensjonApi,
  rmLeadsApi,
  smartAccountCreationStatusApi,
  summaryPdfApi,
} from "src/app/constants/api.constants";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";
import { select } from "../utils/rxjs/select";
import { ApiHomeService, ApiUrl } from "./api-home.service";
import { KeycloakService } from "./keycloak.service";

export function getHttpHeaderClientId(id = clientId): HttpHeaders {
  return new HttpHeaders().set("ClientId", id);
}

export const httpHeaderNoCache = {
  headers: new HttpHeaders().set("Cache-control", "no-cache"),
};

export enum HttpMethod {
  Delete = "DELETE",
  Post = "POST",
  Put = "PUT",
  Patch = "PATCH",
}

@Injectable({
  providedIn: "root",
})
export class EndpointService {
  constructor(
    private readonly apiHomeService: ApiHomeService,
    private readonly http: HttpClient,
    protected readonly keycloakService: KeycloakService,
  ) {}

  /**
   * Constructs a HTTP GET request with default configs commonly used
   * when calling api resources at Storebrand. It wraps angular's
   * HttpClient.get<T>() and returns the resulting observable.
   */
  public httpGet$<T>(
    url: string,
    options?: {
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<T> {
    return this.http.get<T>(url, {
      ...options,
      headers: (options && options.headers ? options.headers : new HttpHeaders()).set("ClientId", clientId),
    });
  }

  public httpGetWithResponse<T>(
    url: string,
    options?: {
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<HttpResponse<T>> {
    return this.http.get<T>(url, {
      ...options,
      observe: "response",
      headers: (options && options.headers ? options.headers : new HttpHeaders()).set("ClientId", clientId),
    });
  }

  public httpPut$<T, R>(
    url: string,
    options?: {
      body?: T | null;
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<R> {
    return this.httpRequest$<T, R>(HttpMethod.Put, url, options);
  }

  public httpPatch$<T, R>(
    url: string,
    options?: {
      body?: T | null;
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<R> {
    return this.httpRequest$<T, R>(HttpMethod.Patch, url, options);
  }

  public httpPost$<T, R>(
    url: string,
    options?: {
      body?: T | null;
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<R> {
    return this.httpRequest$<T, R>(HttpMethod.Post, url, options);
  }

  public httpDelete$<T, R>(
    url: string,
    options?: {
      body?: T | null;
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<R> {
    return this.httpRequest$<T, R>(HttpMethod.Delete, url, options);
  }

  public httpRequest$<T, R>(
    method: HttpMethod,
    url: string,
    options?: {
      body?: T | null;
      headers?: HttpHeaders;
      params?: HttpParams;
    },
  ): Observable<R> {
    /**
     * Useful defaults to be aware of:
     *  - if no responseType is set, http.request defaults to 'json'
     *  - when body contains object or boolean, new HttpRequest(body)
     *    (called by http.request) will handle serialization
     *  - HttpXhrBackend.handle() auto-detects and sets Content-Type
     */
    const headers = getHeadersWithClientId(options?.headers);
    return this.http.request<R>(method, url, {
      ...options,
      headers,
    });
  }

  public composeCustomerUrl(): Observable<string> {
    return this.composeV2ApiUrl(customerApi);
  }

  public composeEpkHubUrl(): Observable<string> {
    return this.composeV2ApiUrl(epkHubApi);
  }

  public composeConsentUrl(): Observable<string> {
    return this.composeV2ApiUrl(consentsApi);
  }

  public composeNorskpensjonUrl(): Observable<string> {
    return this.composeV2ApiUrl(prognosisLifeNorskpensjonApi);
  }

  public composeCustomerSuppliedDataUrl(): Observable<string> {
    return this.composeV2ApiUrl(customerSuppliedDataApi);
  }

  public composeCustomerSuppliedDataNytUrl(): Observable<string> {
    return this.composeCustomerSuppliedDataUrl().pipe(map((url) => `${url}/nyt`));
  }
  public composeFmsUrl(): string {
    return isStageLocalhost() ? ApiUrl.Localhost + fmsApi : ApiUrl.Production + fmsApi;
  }

  public composeSummaryPdfApiUrl(): Observable<string> {
    return this.composeV2ApiUrl(summaryPdfApi);
  }

  public composeEligibilityCheckApiUrl(): Observable<string> {
    return this.composeV2ApiUrl(eligibilityCheckApi);
  }

  public composeBenefitsCheckUrl(): Observable<string> {
    return this.composeV2ApiUrl(benefitsCheck);
  }

  public composeGraphUrl(): Observable<string> {
    return this.composeV2ApiUrl(graphApi);
  }

  public composeLeadUrl(): Observable<string> {
    return this.composeV2ApiUrl(rmLeadsApi);
  }

  public composeSmartAccountCreationStatusUrl(): Observable<string> {
    return this.composeV2ApiUrl(smartAccountCreationStatusApi);
  }

  public isKeycloakProtectedResource(url: string): Observable<boolean> {
    const isApiHomeUrl = url.includes(apiJson);
    const isFmsUrl = url.includes(fmsApi);
    const isLocalhostUrl = url.match(/^http[s]*:\/\/localhost:[\d]+/);

    if (isApiHomeUrl || isFmsUrl || isLocalhostUrl) {
      return of(false);
    }

    return this.apiHomeService.apiHome$.pipe(
      first(),
      map((apiHomeUrl) => {
        const isApiUrl = url.match(apiHomeUrl);
        const isBenefitsCheckUrl = url.includes(benefitsCheck); // This URL requires a token due to routing
        return !!isApiUrl || isBenefitsCheckUrl;
      }),
    );
  }

  protected getAdvisorFragments(): string {
    return select(this.keycloakService.isAdvisorContext$) ? "/?cmId=" + select(this.keycloakService.cmid.user$) : "";
  }

  /**
   * Takes a URL fragment and composes a new URL from it that complies with the
   * V2 API specification at stb. Specifically used for any keycloak enabled
   * APIs. An interceptor should attach the bearer token to these URLs.
   *
   * @param urlFragment the URL fragment to compose the full V2 URL from
   */
  protected composeV2ApiUrl(urlFragment: string): Observable<string> {
    const url$ = isAbsoluteUrl(urlFragment)
      ? of(urlFragment)
      : this.apiHomeService.apiHome$.pipe(map((url) => url + urlFragment));

    return url$.pipe(first());
  }
}

function isAbsoluteUrl(resourceUrl: string): boolean {
  return !!resourceUrl.match(/^http[s]*:\/\//);
}

function hasClientIdHeader(headers: HttpHeaders): boolean {
  return !!headers?.get("ClientId");
}

function getHeadersWithClientId(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
  return hasClientIdHeader(headers) ? headers : headers.set("ClientId", clientId);
}
