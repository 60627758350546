import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";
import { catchError, concatMap, concatMapTo, tap } from "rxjs/operators";
import { clientId } from "src/app/constants/api.constants";
import { FifoStack } from "src/app/datastructures/queues";
import { handleError } from "src/app/utils/http";
import { memoizeObject$, select, select$, selectObject$ } from "src/app/utils/rxjs/select";
import { BehaviorStore } from "src/app/utils/rxjs/store";
import { Nullable, getIsNotNullable } from "../utils/utils";
import { CustomerService } from "./customer.service";
import { EndpointService, httpHeaderNoCache } from "./endpoint.service";
import { ErrorCategory, ErrorsService } from "./errors.service";
import { norskpensjonPayload, purchaseAssistancePayload, storagePayload } from "./payloads/consent.payloads";
import { KeycloakService } from "src/app/services/keycloak.service";

@Injectable({
  providedIn: "root",
})
export class ConsentService {
  public readonly lastTwoStorageConsents$: Observable<FifoStack<boolean>>;
  public readonly consents$: Observable<Consents.Consent[]>;
  public readonly customerSuppliedDataConsent$: Observable<Nullable<boolean>>;
  public readonly hasNorskPensjonConsent$: Observable<Nullable<boolean>>;
  public readonly purchaseAssistanceConsentValue$: Observable<Nullable<boolean>>;
  public readonly advisorIncognitoMode$ = this.keycloakService.getAdvisorIncognitoMode();

  private readonly _rootObject$ = new BehaviorStore<Consents.RootObject>({
    consents: [],
  });
  private readonly _lastTwoStorageConsents$: BehaviorSubject<FifoStack<boolean>> = new BehaviorSubject(
    new FifoStack({
      maxSize: 2,
    }),
  );

  constructor(
    private readonly endpointService: EndpointService,
    private readonly errorsService: ErrorsService,
    private readonly http: HttpClient,
    private readonly customerService: CustomerService,
    private readonly keycloakService: KeycloakService,
  ) {
    this.lastTwoStorageConsents$ = memoizeObject$(this._lastTwoStorageConsents$);

    this.consents$ = selectObject$(this._rootObject$, (rootObject) => rootObject.consents);

    this.customerSuppliedDataConsent$ = select$(this.consents$, (consents) =>
      this.extractCustomerSuppliedDataConsent(consents),
    );

    this.hasNorskPensjonConsent$ = select$(this.consents$, (consents) => containsNorskPensjonConsent(consents));

    this.purchaseAssistanceConsentValue$ = select$(this.consents$, (consents) =>
      this.getPurchaseAssistanceConsentValue(consents),
    );
  }

  public fetchConsents(): Observable<Consents.RootObject> {
    return this.endpointService.composeConsentUrl().pipe(
      switchMap((url) => this.endpointService.httpGet$<Consents.RootObject>(url, httpHeaderNoCache)),
      tap((res) => {
        if (res) {
          this._rootObject$.next(res);

          if (res.consents) {
            this.pushNextStorageConsent(res.consents);
          }
        }
      }),
      catchError((err) => this.handleError("fetchConsents", err)),
    );
  }

  public postAndFetchPurchaseAssistanceConsent(consent: boolean, purpose: string): Observable<Consents.RootObject> {
    return this.postPurchaseAssistanceConsent(consent, purpose).pipe(concatMapTo(this.fetchConsents()));
  }

  public postAndFetchStorageConsent(consent: boolean, purpose: string): Observable<Consents.RootObject | null> {
    return this.advisorIncognitoMode$.pipe(
      switchMap((isIncognitoMode) => {
        if (isIncognitoMode) {
          return of(null);
        }
        return this.postStorageConsent(consent, purpose).pipe(concatMapTo(this.fetchConsents()));
      }),
    );
  }

  public postAndFetchNavAndOfaConsent(
    navConsent: boolean,
    ofaConsent: boolean,
  ): Observable<CustomerMaster.Response.Customer | null> {
    return this.advisorIncognitoMode$.pipe(
      switchMap((isIncognitoMode) => {
        if (isIncognitoMode) {
          return of(null);
        }
        return this.customerService
          .patchNavAndOfaConsent(navConsent, ofaConsent)
          .pipe(concatMap(() => this.customerService.fetchAndSetCustomerWithoutLoader()));
      }),
      catchError((err) => this.handleError("postAndFetchNavAndOfaConsent", err)),
    );
  }

  public postAndFetchNorskPensjonConsent(consent: boolean, purpose: string): Observable<Consents.RootObject | null> {
    return this.advisorIncognitoMode$.pipe(
      switchMap((isIncognitoMode) => {
        if (isIncognitoMode) {
          return of(null);
        }
        return this.postNorskPensjonConsent(consent, purpose).pipe(concatMapTo(this.fetchConsents()));
      }),
    );
  }

  public extractAllConsents(
    consents: Consents.Consent[] = select(this.consents$),
  ): Pick<Consents.Consent, "consentValue" | "category" | "subCategory">[] {
    return consents
      .filter(
        (c) =>
          (c.category === "storage" && c.subCategory === "customer_supplied_data") ||
          (c.category === "third_party" && c.subCategory === "norsk_pensjon_read_access") ||
          (c.category === "purchase_assistance" && c.subCategory === "din_pensjonsplan"),
      )
      .map((c) => ({
        consentValue: c.consentValue,
        category: `${c.category}`,
        subCategory: `${c.subCategory}`,
      }));
  }

  public hasNoPurchaseAssistanceConsent(): boolean {
    return this.getPurchaseAssistanceConsents().length === 0;
  }

  private pushNextStorageConsent(consents: Consents.Consent[]): void {
    const fifoStack = this._lastTwoStorageConsents$.getValue().clone();
    fifoStack.push(!!this.extractCustomerSuppliedDataConsent(consents));
    this._lastTwoStorageConsents$.next(fifoStack);
  }

  private postStorageConsent(consent: boolean, purpose: string): Observable<unknown> {
    const payload: Consents.Payload = {
      ...storagePayload,
      consent,
      purpose,
    };
    return this.postConsent(payload);
  }

  private postPurchaseAssistanceConsent(consent: boolean, purpose: string): Observable<unknown> {
    const payload: Consents.Payload = {
      ...purchaseAssistancePayload,
      consent,
      purpose,
    };
    return this.postConsent(payload);
  }

  private postNorskPensjonConsent(consent: boolean, purpose: string): Observable<unknown> {
    const payload: Consents.Payload = {
      ...norskpensjonPayload,
      consent,
      purpose,
    };
    return this.postConsent(payload);
  }

  private postConsent(payload: Consents.Payload): Observable<unknown> {
    return this.advisorIncognitoMode$.pipe(
      switchMap((isIncognitoMode) => {
        if (isIncognitoMode) {
          return of(null);
        }
        return this.endpointService.composeConsentUrl().pipe(
          switchMap((url) =>
            this.http.post<unknown>(url, JSON.stringify(payload), {
              headers: new HttpHeaders({
                ClientId: clientId,
                "Content-Type": "application/json",
                "Cache-control": "no-cache",
              }),
            }),
          ),
          catchError((err) => this.handleError("postConsent", err)),
        );
      }),
    );
  }

  private handleError(source: string, error: HttpErrorResponse): Observable<never> {
    this.errorsService.pushError(ErrorCategory.Consent, {
      title: "consentService.datafetch.error.title",
      message: "consentService.datafetch.error.message",
    });
    return handleError(`ConsentService::${source}`, error);
  }

  private extractCustomerSuppliedDataConsent(consents: Consents.Consent[]): Nullable<boolean> {
    return consents
      .filter((c) => c.category === "storage" && c.subCategory === "customer_supplied_data")
      .map((c) => c.consentValue)
      .reduce<Nullable<boolean>>((prev, next) => prev || next, undefined);
  }

  private getPurchaseAssistanceConsentValue(consents: Consents.Consent[] = select(this.consents$)): Nullable<boolean> {
    return this.getPurchaseAssistanceConsents(consents)
      .map((c) => c.consentValue)
      .reduce<Nullable<boolean>>((prev, next) => prev || next, undefined);
  }

  private getPurchaseAssistanceConsents(consents: Consents.Consent[] = select(this.consents$)): Consents.Consent[] {
    return consents.filter((c) => c.category === "purchase_assistance" && c.subCategory === "din_pensjonsplan");
  }
}

export function containsNorskPensjonConsent(consents: Consents.Consent[]): Nullable<boolean> {
  const booleanConsentValues = consents
    .filter(
      ({ category, subCategory, consentValue }) =>
        category === "third_party" && subCategory === "norsk_pensjon_read_access" && getIsNotNullable(consentValue),
    )
    .map(({ consentValue }) => consentValue as boolean);

  if (booleanConsentValues.length === 0) {
    return undefined;
  }

  return booleanConsentValues.some((consentValue) => consentValue === true);
}
