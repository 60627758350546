import { Pipe, PipeTransform } from "@angular/core";
import { accountNumberRegex } from "src/app/constants/regex.constants";
import { Nullable } from "src/app/utils/utils";

@Pipe({ name: "accountNumberFormatter" })
export class AccountNumberFormatterPipe implements PipeTransform {
  transform(value: Nullable<number | string>): Nullable<number | string> {
    if (!value) {
      return value;
    }

    return value.toString().replace(accountNumberRegex, "$1.$2.$3");
  }
}
