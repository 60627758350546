import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  merge,
  Observable,
  of,
  OperatorFunction,
  skip,
  timeout,
} from "rxjs";
import { IContext, IToggle } from "unleash-proxy-client";

export const makeCreateUnleashObservable =
  (ready$: Observable<unknown>, update$: Observable<unknown>, READY_TIMEOUT = 3000) =>
  <T>(project: () => T): Observable<T> => {
    /**
     * If ready$ takes too long to emit, call project anyway, if
     * unleash is down or slow it will default to cached value or empty value
     */
    const valueOnReadyOrTimeout$ = ready$.pipe(
      first(),
      timeout(READY_TIMEOUT),
      map(() => project()),
      catchError(() => of(project())),
    );
    /**
     * update$ also emits on init$ so we skip the first one
     */
    const valueOnUpdate$ = update$.pipe(
      skip(1),
      map(() => project()),
    );

    return merge(valueOnReadyOrTimeout$, valueOnUpdate$).pipe(distinctUntilChanged());
  };

export const filterTogglesWithAppNamePostFix = (context: IContext): OperatorFunction<IToggle[], IToggle[]> =>
  map((toggles) => toggles.filter((toggle) => toggle.name.startsWith(context.appName)));
