/*
 * Ahead of time compiler in production means computed keys are not allowed
 * RouteKey entries and keys in route declarations must match (spelling and casing)
 * Please ensure consistency manually
 */

export enum RouteKey {
  Root = "Root",
  Agreements = "Agreements",
  Accumulation = "Accumulation",
  Needs = "Needs",
  Todo = "Todo",
  Summary = "Summary",
  AgreementsIntro = "AgreementsIntro",
  AccumulationIntro = "AccumulationIntro",
  NeedsIntro = "NeedsIntro",
  TodoIntro = "TodoIntro",
  ActionsIntro = "ActionsIntro",
  SummaryIntro = "SummaryIntro",
  Landing = "Landing",
  WithdrawalOrder = "WithdrawalOrder",
  MinePenger = "MinePenger",
  Actions = "Actions",
  RescueEpkS = "RescueEpkS",
  CalculationDisclaimer = "CalculationDisclaimer",
}

export const routes = {
  Root: "pensjonsplan",
  Agreements: "hvor-kommer-min-pensjon-fra",
  Accumulation: "hvor-mye-far-jeg",
  Needs: "behold-din-livsstil",
  Todo: "hva-kan-jeg-gjore",
  Summary: "oppsummering",
  AgreementsIntro: "intro-hvor-kommer-min-pensjon-fra",
  AccumulationIntro: "intro-hvor-mye-far-jeg",
  NeedsIntro: "intro-behold-din-livsstil",
  TodoIntro: "intro-hva-kan-jeg-gjore",
  ActionsIntro: "intro-ta-kontroll",
  SummaryIntro: "intro-oppsummering",
  Landing: "landingsside",
  WithdrawalOrder: "uttaksrekkefolge",
  MinePenger: "mine-penger",
  Actions: "ta-kontroll",
  RescueEpkS: "behold-egen-pensjonskonto",
  CalculationDisclaimer: "informasjon",
};

export const deprecatedRoutes = {
  Needs: "hva-trenger-jeg",
  NeedsIntro: "intro-hva-trenger-jeg",
  Todo: "hva-kan-jeg-gjore",
  TodoIntro: "intro-hva-kan-jeg-gjore",
  WithdrawalOrder: "uttaksrekkefolge",
};
