import { ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";
import { AbstractNavEngagement } from "src/app/models/engagements/nav-engagement.model";
import { EpkEngagement } from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import { isEpkEngagement, isEpkFleksibelEngagement, isNavEngagement } from "src/app/utils/engagement.typeguards";
import { getIsSimulationStatusNotOk } from "src/app/utils/engagement.utils";
import { getIsNotNullable } from "src/app/utils/utils";

@Component({
  selector: "app-partial-withdrawal-warning",
  templateUrl: "./partial-withdrawal-warning.component.html",
  styleUrl: "./partial-withdrawal-warning.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartialWithdrawalWarningComponent implements OnDestroy {
  @Input()
  public readonly selectedAgePartialWithdrawal!: string;
  @Input()
  public readonly selectedAgeFullWithdrawal!: string;
  @Input()
  public readonly isReady!: boolean;
  @Input()
  public readonly inputError: boolean = false;

  private static readonly PARTIAL_WITHDRAWAL_ERRORS = [
    "sharedSimulationMessages.nav.feil_nav_for_lavt_tidlig_uttak",
    "sharedSimulationMessages.nav.manglende_svar",
    "sharedSimulationMessages.insufficent_funds",
    "sharedSimulationMessages.ips_1600",
    "sharedSimulationMessages.ips_1601",
    "sharedSimulationMessages.itp_1600",
    "sharedSimulationMessages.itp_1601",
    "sharedSimulationMessages.businesserror",
    "sharedSimulationMessages.invalid_date",
    "sharedSimulationMessages.simulation_not_allowed",
    "sharedSimulationMessages.unknown_nav_error",
    "sharedSimulationMessages.ADNAV",
    "sharedSimulationMessages.IKKE_UTTAK_ETTER_SAERALDER",
    "sharedSimulationMessages.prognosis.pensionlife.subsystemerror",
    "sharedSimulationMessages.not_allowed_epk_selvvalgt",
  ];

  public readonly hasError$!: Observable<boolean>;
  public readonly hasAgeInputError$ = new BehaviorSubject<boolean>(false);
  public readonly hasPartialWithdrawalError$ = new BehaviorSubject<boolean>(false);

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly engagementsService: EngagementsService) {
    this.hasError$ = this.engagementsService.allEngagements$.pipe(
      map(this.filterPartialWithdrawalEngagements),
      map((engagements) => engagements.filter((engagement) => getIsSimulationStatusNotOk(engagement))),
      map((engagements) => this.hasError(engagements)),
      takeUntil(this.destroy$),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hasError(engagements: AnyEngagement[]): boolean {
    const ageInputError =
      getIsNotNullable(this.selectedAgePartialWithdrawal) &&
      getIsNotNullable(this.selectedAgeFullWithdrawal) &&
      Number(this.selectedAgePartialWithdrawal) >= Number(this.selectedAgeFullWithdrawal);

    const partialWithdrawalError = engagements.some((engagement) =>
      engagement
        .getSimulationStatus()
        .some(
          (simStat) => simStat.key && PartialWithdrawalWarningComponent.PARTIAL_WITHDRAWAL_ERRORS.includes(simStat.key),
        ),
    );

    this.hasAgeInputError$.next(ageInputError);
    this.hasPartialWithdrawalError$.next(partialWithdrawalError);

    return ageInputError || partialWithdrawalError;
  }

  private filterPartialWithdrawalEngagements(engagements: AnyEngagement[]): (EpkEngagement | AbstractNavEngagement)[] {
    // Only EPK/EPKS and NAV engagements have partial withdrawal support
    return engagements.filter(
      (engagement) =>
        isEpkEngagement(engagement) || isEpkFleksibelEngagement(engagement) || isNavEngagement(engagement),
    ) as (EpkEngagement | AbstractNavEngagement)[];
  }
}
