import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { Observable, combineLatest, filter, firstValueFrom, map, switchMap, take } from "rxjs";
import { InvestmentProfileInPayoutPeriod } from "../../../../../services/api/savings-and-pension-queries.types";
import { CompressionLimitService } from "../../../../../services/compression-limit.service";
import { ClientDataService } from "../../../../../services/customer-supplied-data/client-data.service";
import { EngagementService } from "../../../../../services/engagement.service";
import { AnyEngagement } from "../../../../../services/engagements.service";
import { FmsService } from "../../../../../services/fms.service";
import { getIsSimulationStatusNotOk } from "../../../../../utils/engagement.utils";
import { CurrencyFormatterPipe } from "../../../pipes/currency-formatter.pipe";
import {
  createRadioButton,
  getCompressionLimitFmsKey,
  getInvestmentFmsKey,
  getPayoutSimulationResultDescription as getPayoutSimulationResultDescriptionFmsKey,
} from "./payout-simulation-alternatives-dialog.utils";

@Component({
  selector: "app-payout-simulation-alternatives-dialog",
  templateUrl: "./payout-simulation-alternatives-dialog.component.html",
  styleUrls: ["./payout-simulation-alternatives-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayoutSimulationAlternativesDialogComponent {
  public readonly investmentRadios$: Observable<RadioButton[]>;
  public readonly compressionRadios$: Observable<RadioButton[]>;

  public readonly userFriendlyTextResultOfChanges$: Observable<string | undefined>;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private readonly data: { engagement$: Observable<AnyEngagement> },
    private readonly fms: FmsService,
    private readonly engagementService: EngagementService,
    private readonly currencyFormatter: CurrencyFormatterPipe,
    private readonly clientDataService: ClientDataService,
    private readonly compressionLimitService: CompressionLimitService,
  ) {
    this.userFriendlyTextResultOfChanges$ = this.data.engagement$.pipe(
      switchMap((engagement) => {
        if (getIsSimulationStatusNotOk(engagement)) {
          return [undefined];
        }

        const fmsKey = getPayoutSimulationResultDescriptionFmsKey(engagement);
        const humanReadableDuration = this.engagementService.getHumanReadableDuration(engagement);
        const humanReadableYearlyAmount = this.currencyFormatter
          .transform(engagement.getAverageAnnualPayout() ?? 0, "end")
          .toString();

        return this.fms.translateAsync(fmsKey, { args: { humanReadableDuration, humanReadableYearlyAmount } });
      }),
    );

    const investmentProfileOptions$ = this.data.engagement$.pipe(
      map((engagement) => engagement.getSelectableInvestmentProfileInPayoutPeriod()),
      filter(Boolean),
    );
    const compressionLimitOptions$ = this.data.engagement$.pipe(
      switchMap((engagement) =>
        this.compressionLimitService
          .getIfCompressionLimitChangesPrognosis(engagement.getIdentifier())
          .pipe(map((canChangeCompression) => ({ engagement, canChangeCompression }))),
      ),
      filter(
        ({ engagement, canChangeCompression }) => canChangeCompression && engagement.getCompressionLimits().length > 0,
      ),
      map(({ engagement }) => engagement.getCompressionLimits()),
    );
    const userSelection$ = combineLatest([
      this.data.engagement$.pipe(take(1)),
      this.clientDataService.compressionLimitByEngagement$,
      this.clientDataService.investmentProfileInPayoutPeriodByEngagement$,
    ]).pipe(
      map(([engagement, compressionLimitByEngagement, investmentProfileInPayoutPeriodByEngagement]) => {
        const engagementId = engagement.getIdentifier();
        const compressionLimit = compressionLimitByEngagement.find(([key]) => key === engagementId)?.[1]
          .compressionLimit;
        const investmentProfileInPayoutPeriod = investmentProfileInPayoutPeriodByEngagement.find(
          ([key]) => key === engagementId,
        )?.[1].investmentProfileInPayoutPeriod;

        return {
          compressionLimit,
          investmentProfileInPayoutPeriod,
        };
      }),
    );

    this.investmentRadios$ = combineLatest([userSelection$, investmentProfileOptions$]).pipe(
      switchMap(([userSelection, options]) => {
        const radioButtonObservables$ = options.map((option) => {
          const label$ = this.fms.translateAsync<string>(getInvestmentFmsKey(option));
          const defaultSelection = InvestmentProfileInPayoutPeriod.Guarantee;

          return label$.pipe(
            map((label) =>
              createRadioButton(label, option, userSelection.investmentProfileInPayoutPeriod ?? defaultSelection),
            ),
          );
        });

        return combineLatest(radioButtonObservables$);
      }),
    );

    this.compressionRadios$ = combineLatest([userSelection$, compressionLimitOptions$]).pipe(
      switchMap(([userSelection, options]) => {
        const radioButtonObservables$ = options.map((option) => {
          const label$ = this.fms.translateAsync<string>(getCompressionLimitFmsKey(option));
          const defaultSelection = 0.3;

          return label$.pipe(
            map((label) => createRadioButton(label, option, userSelection.compressionLimit ?? defaultSelection)),
          );
        });

        return combineLatest(radioButtonObservables$);
      }),
    );
  }

  public async setInvestmentChoice(value: InvestmentProfileInPayoutPeriod): Promise<void> {
    const key = (await firstValueFrom(this.data.engagement$)).getSimulationParametersKey();
    this.clientDataService.updateInvestmentProfileInPayoutPeriodByEngagement(key, value);
  }

  public async setCompressionChoice(value: string): Promise<void> {
    const key = (await firstValueFrom(this.data.engagement$)).getSimulationParametersKey();
    this.clientDataService.updateCompressionLimitByEngagement(key, Number(value));
  }
}
