<div class="article-link">
  <a
    [routerLink]="getUrl()"
    queryParamsHandling="merge"
    *ngIf="shouldOpenInternally()"
    [attr.data-trackid]="article.key"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>

  <a [href]="getUrl()" *ngIf="!shouldOpenInternally()" target="_blank" [attr.data-trackid]="article.key">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</div>
