import { Injectable } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { SAVINGS_ADVISOR_AGE_REQUIREMENT } from "src/app/constants/business.constants";
import { CustomerService } from "src/app/services/customer.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { isEngagementFromSavings } from "../utils/engagement.utils";
import { getIsFiniteNumber } from "../utils/number";

@Injectable({ providedIn: "root" })
export class SavingsAdvisorService {
  constructor(
    private readonly customerService: CustomerService,
    private readonly engagementsService: EngagementsService,
  ) {}

  public isEligibleForSavingsAdvisor(): Observable<boolean> {
    return combineLatest(
      [this.lacksSavingsEngagements(), this.meetsAgeRequirement()],
      (lacksSavingsEngagements, meetsAgeRequirement) => {
        return lacksSavingsEngagements && meetsAgeRequirement;
      },
    );
  }

  private meetsAgeRequirement(): Observable<boolean> {
    return this.customerService.age$.pipe(
      map((age) =>
        getIsFiniteNumber(age)
          ? age > SAVINGS_ADVISOR_AGE_REQUIREMENT.min && age <= SAVINGS_ADVISOR_AGE_REQUIREMENT.max
          : false,
      ),
    );
  }

  private lacksSavingsEngagements(): Observable<boolean> {
    return this.engagementsService.allEngagements$.pipe(
      map((allEngagements) => allEngagements.some(isEngagementFromSavings)),
      map((hasSavingEngagements) => !hasSavingEngagements),
    );
  }
}
