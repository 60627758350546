import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { CommonParametersService } from "../../common-parameters.service";
import { IncomeStrategy, createIncomeObject } from "../income.service";
import { FmsKey } from "../../fms/fms";
import { getIsNotNullable } from "../../../utils/utils";

@Injectable()
export class IncomeUser implements IncomeStrategy {
  public income$ = this.commonParametersService.annualGrossIncome$.pipe(
    map((value) => createIncomeObject(value, "USER")),
  );

  constructor(private readonly commonParametersService: CommonParametersService) {}

  public predicate(): Observable<boolean> {
    return of(true);
  }

  public getHintText(): Observable<FmsKey> {
    return this.income$.pipe(
      map(({ value }) => {
        const hasUserIncome = getIsNotNullable(value);

        return hasUserIncome
          ? "financesinput.yearly_income_gross_customer.popover"
          : "financesinput.yearly_income_gross_default";
      }),
    );
  }
}
