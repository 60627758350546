import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";
import { Nullable } from "src/app/utils/utils";

@Pipe({
  name: "fms",
  pure: true,
})
export class FmsPipe implements PipeTransform, OnDestroy {
  private readonly asyncPipe = new AsyncPipe(this.ref);

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly fms: FmsService,
  ) {}

  public ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  public transform<T = any>(value: Nullable<string>, options?: Partial<Fms.TranslationOptions>): T | null {
    if (value == null) {
      return null;
    }

    return this.asyncPipe.transform(this.fms.translateAsync<T>(value, options));
  }
}
