/* eslint-disable fp/no-mutation */

export const getFirstMatch = (regExp: RegExp, input: string): string | undefined => {
  const matches = regExp.exec(input);
  let first;

  if (matches) {
    matches.shift(); // drop the entire match string

    do {
      first = matches.shift();
    } while (!first && matches.length > 0); // stop on the first match group or when empty
  }

  return first;
};
