import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { ActionKey } from "src/app/modules/features/pension-actions/services/actions.service";
import { BehaviorStore, ReplayStore } from "src/app/utils/rxjs/store";
import { Nullable } from "src/app/utils/utils";

export interface ActionComponent {
  action: Action;
  getIsVisible(): Observable<boolean>;
}

export interface ActionRef {
  action: Action;
  viewRef: ViewRef;
  componentRef: ComponentRef<ActionComponent>;
  isVisible: boolean;
}

export interface Action {
  name: string;
  key: ActionKey;
  enabled: boolean;
  order: number;
  categoryId: number;
}

const GRADIENT_HEIGHT_PX = 80;
const SNEAK_PEEK_PX = 60;

@Component({
  selector: "app-actions",
  templateUrl: "./actions.component.html",
  styleUrls: ["./actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent implements AfterViewInit, OnChanges {
  @Input()
  public narrow = false;
  @Input()
  public actionRefs!: ActionRef[];

  @ViewChild("actions", { static: true, read: ViewContainerRef })
  private readonly content!: ViewContainerRef;

  public readonly cardHeight$ = new ReplayStore<Nullable<number>>();
  public readonly isExpandable$ = new ReplayStore<boolean>();
  public readonly isExpanded$ = new BehaviorStore<boolean>(false);

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.renderActionRefs(this.actionRefs);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // First render is handled by ngAfterViewInit()
    if (changes.actionRefs.isFirstChange()) {
      return;
    }

    this.content.clear();
    this.renderActionRefs(this.actionRefs);
  }

  private renderActionRefs(actionRefs: ActionRef[]): void {
    actionRefs.forEach((actionRef) => {
      this.content.insert(actionRef.viewRef);
      actionRef.viewRef.detectChanges();
    });

    this.toggleExpandable(actionRefs);
  }

  private toggleExpandable(actionRefs: ActionRef[]): void {
    const isExpandable = this.getIsExpandable(actionRefs);
    const isExpanded = this.isExpanded$.getValue();

    this.isExpandable$.next(isExpandable);
    this.isExpanded$.next(isExpandable && isExpanded);

    this.cardHeight$.next(isExpandable ? getCardHeightFromActionRefs(actionRefs) : null);

    this.changeDetectorRef.detectChanges();
  }

  private getIsExpandable(actionRefs: ActionRef[]): boolean {
    const threshold = this.narrow ? 2 : 3;
    return actionRefs.length > threshold;
  }
}

function getCardHeightFromActionRefs(actionRefs: ActionRef[]): number {
  const firstActionRef = actionRefs.at(0);

  if (!firstActionRef) {
    return 0;
  }

  const element = firstActionRef.componentRef.location.nativeElement;
  const height = element.offsetHeight;

  return height + GRADIENT_HEIGHT_PX + SNEAK_PEEK_PX;
}
