import { isEmpty } from "lodash-es";
import { getIsFiniteNumber } from "./number";

interface Floor {
  floor: number;
}
interface Ceiling {
  ceil: number;
}

export type Boundary = Floor & Ceiling;

/**
 * Returns the input if it is within the bounds, else the boundary that was exceeded.
 * For invalid input the function returns a valid fallback value if specified, or NaN.
 */
export function getNumberWithinBounds(boundary: Partial<Boundary>, input?: number, fallback: number = NaN): number {
  const getIsNotLegalBoundaryValue = (): boolean =>
    isEmpty(boundary) ||
    [boundary.floor, boundary.ceil].some(
      (val) => !getIsFiniteNumber(val) && val != null, //allow nullable boundaries
    );

  if (!getIsFiniteNumber(input) || getIsNotLegalBoundaryValue()) {
    return fallback;
  }

  return isAFloorBoundary(boundary) && input < boundary.floor
    ? boundary.floor
    : isACeilingBoundary(boundary) && input > boundary.ceil
      ? boundary.ceil
      : input;
}

function isAFloorBoundary(boundary: Partial<Boundary>): boundary is Floor {
  return boundary && "floor" in boundary;
}

function isACeilingBoundary(boundary: Partial<Boundary>): boundary is Ceiling {
  return boundary && "ceil" in boundary;
}
