import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
import { of } from "rxjs";
import { discretePercentageSanitizerPipe } from "src/app/utils/rxjs/pipes";

@Directive({
  selector: "[appDiscretePercentageMask]",
})
export class DiscretePercentageMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener("keydown.backspace", ["$event"])
  onKeyPress(event: any): void {
    of(event.target.value)
      .pipe(discretePercentageSanitizerPipe)
      .subscribe((val) => this.ngControl.valueAccessor?.writeValue(`${Math.floor(val / 10)}%`));
  }

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event: any): void {
    of(event)
      .pipe(discretePercentageSanitizerPipe)
      .subscribe((val) => this.ngControl.valueAccessor?.writeValue(`${val}%`));
  }
}
