<section>
  <h2>{{ "dashboard.linkList.title" | fms }}</h2>
  <ul>
    <stb-link-list
      *ngFor="let link of linkList$ | async"
      [index]="link.key"
      [text]="link.text"
      (linkClick)="onLinkClick(link.url)"
      [attr.data-trackid]="link.trackId"
    >
    </stb-link-list>
  </ul>
</section>
