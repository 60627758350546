<div class="summary-table" [class.summary-table-responsive]="responsive">
  <div class="summary-row" *ngIf="contractNumber">
    <div class="summary-label">
      {{ "engagement.label.contractNumber" | fms }}
    </div>

    <div class="summary-value" data-hj-suppress>
      {{ contractNumber }}
    </div>
  </div>

  <div class="summary-row" *ngIf="yearlyDeposit">
    <div class="summary-label">
      {{ "engagement.label.savingsRate.yearly" | fms }}
    </div>

    <div class="summary-value">
      {{ yearlyDeposit | currencyFormatterPipe: "start" }}
    </div>
  </div>
</div>

<ng-template #amountUnavailable>
  <app-amount-unavailable></app-amount-unavailable>
</ng-template>
