import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable, combineLatest, map, startWith } from "rxjs";
import { ConsentService } from "src/app/services/consent.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { HasPublicPensionInStorebrandService } from "../../../../../services/api/has-public-pension-in-storebrand.service";
import { CustomerService } from "../../../../../services/customer.service";

enum ConsentCardState {
  NorskPensjonConsent = "norskPensjonConsent",
  AuthRequirement = "authRequirement",
  Nothing = "nothing",
  NavConsent = "navConsent",
}

@Component({
  selector: "app-consent-and-stepup-cta-card",
  templateUrl: "./consent-and-stepup-cta-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentAndStepupCtaCardComponent {
  @Input()
  public title!: string;
  @Input()
  public isPensionPlanContext?: boolean = true;

  public readonly ConsentCardState = ConsentCardState;
  public readonly consentCardState$: Observable<ConsentCardState>;

  constructor(
    private readonly consentService: ConsentService,
    private readonly keycloakService: KeycloakService,
    private readonly customerService: CustomerService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
  ) {
    this.consentCardState$ = combineLatest([
      this.keycloakService.isAuthLevel3or4$,
      this.consentService.hasNorskPensjonConsent$,
      this.hasPublicPensionInStorebrandService.isPublicContext$,
      this.customerService.navConsent$,
    ]).pipe(
      map(([hasAuthLevelRequirement, hasNorskPensjonConsent, isPublicContext, hasNavConsent]) => {
        if (isPublicContext && !hasNavConsent) {
          return ConsentCardState.NavConsent;
        }

        if (!isPublicContext) {
          if (!hasAuthLevelRequirement) {
            return ConsentCardState.AuthRequirement;
          }
          if (!hasNorskPensjonConsent) {
            return ConsentCardState.NorskPensjonConsent;
          }
        }

        return ConsentCardState.Nothing;
      }),
    );
    startWith(ConsentCardState.Nothing);
  }
}
