<app-cta-card [title]="title" [description]="description" [buttonText]="buttonText" (buttonClick)="stepUpAuth()">
  <ng-container *ngTemplateOutlet="requirements"></ng-container>
</app-cta-card>

<ng-template #requirements>
  <div class="requirements">
    <ng-container *ngFor="let requirementItem of requirementItems">
      <div class="requirements-item">
        <ng-container *appLet="requirementItem.compliant$ | async as compliant">
          <div class="circle" [class.positive]="compliant" [class.negative]="!compliant">
            <img class="icon" [src]="getStatusIcon(requirementItem) | async" alt />
          </div>
        </ng-container>
        <span class="text" [innerHtml]="getStatusText(requirementItem) | async"></span>
      </div>
    </ng-container>
  </div>
</ng-template>
