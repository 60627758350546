import { Component, Input } from "@angular/core";
import { random } from "src/app/utils/utils";
import { AbstractSkeletonComponent } from "../abstract-skeleton.component";

@Component({
  selector: "app-text-skeleton",
  templateUrl: "./text-skeleton.component.html",
  styleUrls: ["./text-skeleton.component.scss"],
})
export class TextSkeletonComponent extends AbstractSkeletonComponent {
  @Input()
  public lines = 1;
  @Input()
  public type: "dark" | "light" | "green" = "dark";

  public isMultipleLines(): boolean {
    return this.lines > 1;
  }

  public getRandomWidth(): string {
    if (this.lines > 1) {
      return random(70, 100) + "%";
    }

    return "100%";
  }
}
