import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { combineLatest, map, Observable } from "rxjs";
import { ActionWithdrawalOrderDialogComponent } from "src/app/modules/features/pension-actions/components/action-withdrawal-order/action-withdrawal-order-dialog/action-withdrawal-order-dialog.component";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { CustomerService } from "src/app/services/customer.service";
import { RecommendedEngagementWithdrawalOrderService } from "src/app/modules/pension-plan/services/recommended-engagement-withdrawal-order.service";
import { ActionWithdrawalOrderExplanationComponent } from "./action-withdrawal-order-explanation/action-withdrawal-order-explanation.component";
import { getIsNotNullable } from "src/app/utils/utils";

@Component({
  selector: "app-action-withdrawal-order",
  templateUrl: "./action-withdrawal-order.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionWithdrawalOrderComponent implements ActionComponent {
  @Input()
  public action!: Action;

  constructor(
    private readonly dialog: MatDialog,
    private readonly customerService: CustomerService,
    private readonly withdrawalOrderService: RecommendedEngagementWithdrawalOrderService,
  ) {}

  public getIsVisible(): Observable<boolean> {
    return combineLatest([
      this.customerService.age$,
      this.withdrawalOrderService.hasMoreThanOneSortedEngagements$,
    ]).pipe(
      map(
        ([age, hasMoreThanOneSortedEngagements]) =>
          getIsNotNullable(age) && age >= 55 && hasMoreThanOneSortedEngagements,
      ),
    );
  }

  public openWithdrawalOrderDialog(): void {
    this.dialog.open<ActionWithdrawalOrderDialogComponent, Action>(ActionWithdrawalOrderDialogComponent, {
      data: this.action,
    });
  }

  public openWithdrawalOrderExplanationDialog(): void {
    this.dialog.open<ActionWithdrawalOrderExplanationComponent>(ActionWithdrawalOrderExplanationComponent);
  }
}
