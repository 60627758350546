import { inject, InjectionToken } from "@angular/core";
import { ButtonTrackIdTransformer } from "src/app/modules/shared/directives/track-id/button-track-id.transformer";
import { InputTrackIdTransformer } from "src/app/modules/shared/directives/track-id/input-track-id.transformer";
import { LinkTrackIdTransformer } from "src/app/modules/shared/directives/track-id/link-track-id.transformer";
import { RoleTrackIdTransformer } from "src/app/modules/shared/directives/track-id/role-track-id.transformer";
import { TrackIdTransformer } from "src/app/modules/shared/directives/track-id/track-id.directive";

export const TRACK_ID_TRANSFORMERS_TOKEN = new InjectionToken("TrackId transformers", {
  providedIn: "any",
  factory(): TrackIdTransformer[] {
    return [
      inject(ButtonTrackIdTransformer),
      inject(LinkTrackIdTransformer),
      inject(RoleTrackIdTransformer),
      inject(InputTrackIdTransformer),
    ];
  },
});
