import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { SAVINGS_GRAPH_CLIENT_ID } from "../clients/savings-graph/savings-graph-client";
import { ApolloBaseWrapper } from "../utils/apollo-base-wrapper";

@Injectable({
  providedIn: "root",
})
export class SavingsGraphqlClientService extends ApolloBaseWrapper {
  constructor(apollo: Apollo) {
    const apolloBase = apollo.use(SAVINGS_GRAPH_CLIENT_ID);
    super(apolloBase);
  }
}
