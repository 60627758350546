import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-engagement-list-initiatives-expander",
  templateUrl: "./engagement-list-initiatives-expander.component.html",
  styleUrls: ["./engagement-list-initiatives-expander.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesExpanderComponent {
  @Input()
  public showMoreButton!: boolean;

  @Input()
  public showLessButton!: boolean;

  @Output()
  public toggleShowLessMoreButton = new EventEmitter();

  public onToggleShowLessMoreItems(): void {
    this.toggleShowLessMoreButton.emit();
  }
}
