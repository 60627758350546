import { OfaVendor } from "src/app/services/api/savings-and-pension-queries.types";

export const toServiceTimeText = (
  ofaVendor: OfaVendor,
  yearsText: string,
  monthText: string,
  daysText: string,
  dayText: string,
): string => {
  const { ofaVendorServiceTime } = ofaVendor;
  const { years, months, days } = ofaVendorServiceTime ?? {};
  const serviceTimepParts: Array<string | undefined> = [
    years ? `${years} ${yearsText}` : undefined,
    months ? `${months} ${monthText}` : undefined,
    days ? `${days} ${days === 1 ? dayText : daysText}` : undefined,
  ];
  return serviceTimepParts.filter((str) => Boolean(str)).join(" ");
};
