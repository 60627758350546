import { Injectable, InjectionToken } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RouteKey as PensionPlanRouteKey, routes as pensionPlanRoutes } from "src/app/modules/pension-plan/routes";
import { RouteKey as contractsRouteKey, routes as contractsPlanRoutes } from "src/app/modules/contracts/routes";
import { getIsEveryItemTrue } from "src/app/utils/array";
import { composePaths } from "src/app/utils/router.utils";
import { BehaviorStore } from "src/app/utils/rxjs/store";

export const VALID_ROUTES_FOR_STOREBRAND_ONLY_TOKEN = new InjectionToken("Valid routes for Storebrand only", {
  factory: (): string[] => {
    return [
      composePaths(pensionPlanRoutes, PensionPlanRouteKey.Root, PensionPlanRouteKey.Summary),
      composePaths(pensionPlanRoutes, PensionPlanRouteKey.Root, PensionPlanRouteKey.Needs),
      composePaths(pensionPlanRoutes, PensionPlanRouteKey.Root, PensionPlanRouteKey.Accumulation),
      composePaths(pensionPlanRoutes, PensionPlanRouteKey.Root, PensionPlanRouteKey.Agreements),
      composePaths(contractsPlanRoutes, contractsRouteKey.Root),
    ];
  },
});

@Injectable({
  providedIn: "root",
})
export class StorebrandOnlyService {
  private readonly isEnabledByUserStore$ = new BehaviorStore(false);
  private readonly isEnabledForRouteStore$ = new BehaviorStore(false);

  public toggleByUser(isEnabled: boolean): void {
    this.isEnabledByUserStore$.next(isEnabled);
  }

  public toggleForRoute(isEnabled: boolean): void {
    this.isEnabledForRouteStore$.next(isEnabled);
  }

  public getIsEnabled(): Observable<boolean> {
    return combineLatest([this.isEnabledByUserStore$, this.isEnabledForRouteStore$]).pipe(map(getIsEveryItemTrue));
  }
}
