import { animate, query, stagger, state, style, transition, trigger } from "@angular/animations";

const FORM_ANIMATION_TIME = 80;
const EASE_IN_FADE_TIME = 250;
export const FADE_TIME = 200;
export const SLIDE_ANIMATION = "225ms cubic-bezier(0.4, 0.0, 0.2, 1)";
const ANIM_STAGGER_DURATION = 150;
const ANIM_SLIDE_LENGTH = 50;

const FormSlideStates = {
  visible: "visible",
  hiddenLeft: "hiddenLeft",
  hiddenRight: "hiddenRight",
};
export const AFormSlide = {
  animation: trigger("slideInOut", [
    state(
      FormSlideStates.hiddenRight,
      style({
        transform: "translateX(15%)",
        opacity: 0,
      }),
    ),
    state(
      FormSlideStates.visible,
      style({
        transform: "translateX(0%)",
        opacity: 1,
      }),
    ),
    state(
      FormSlideStates.hiddenLeft,
      style({
        transform: "translateX(-15%)",
        opacity: 0,
      }),
    ),
    transition(`* => ${FormSlideStates.visible}`, [animate(`${FORM_ANIMATION_TIME}ms`)]),
    transition(`${FormSlideStates.visible} => *`, [animate(`${FORM_ANIMATION_TIME}ms`)]),
  ]),
  states: {
    ...FormSlideStates,
  },
  animationTime: FORM_ANIMATION_TIME,
};

export const expandCollapseTableRow = trigger("expandCollapseTableRow", [
  state("collapsed", style({ height: "0px", minHeight: "0", borderBottomWidth: 0 })),
  state("expanded", style({ height: "*", borderBottomWidth: 1 })),
  transition("expanded <=> collapsed", animate(SLIDE_ANIMATION)),
]);

export const fadeInOut = trigger("fadeInOut", [
  state(
    "show",
    style({
      height: "*",
      opacity: 1,
      visibility: "visible",
      overflow: "visible",
    }),
  ),
  state(
    "hide",
    style({
      height: "0",
      opacity: 0,
      visibility: "hidden",
      overflow: "hidden",
    }),
  ),
  transition("hide <=> show", [animate(`${FADE_TIME}ms`)]),
]);

export const fadeIn = trigger("fadeIn", [
  transition(":enter", [style({ opacity: 0 }), animate(`${EASE_IN_FADE_TIME}ms ease-in`, style({ opacity: 1 }))]),
]);

export const listAnimation = trigger("listAnimation", [
  transition("* <=> *", [
    query(
      ":scope > *:enter",
      [
        style({
          opacity: 0,
          transform: `translateY(${ANIM_SLIDE_LENGTH}px)`,
        }),
        stagger(ANIM_STAGGER_DURATION, [
          animate(
            "600ms 0ms cubic-bezier(.34,1.56,.64,.88)",
            style({
              opacity: 1,
              transform: "translateY(0)",
            }),
          ),
        ]),
      ],
      { optional: true },
    ),
    query(
      ":scope > *:leave",
      [
        stagger(
          ANIM_STAGGER_DURATION,
          animate(
            "400ms 0ms ease-out",
            style({
              opacity: 0,
              transform: `translateY(${ANIM_SLIDE_LENGTH}px)`,
              height: 0,
            }),
          ),
        ),
      ],
      { optional: true },
    ),
  ]),
]);
