import { Component, Input, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { AbstractSkeletonComponent } from "../abstract-skeleton.component";

@Component({
  selector: "app-list-skeleton",
  templateUrl: "./list-skeleton.component.html",
  styleUrls: ["./list-skeleton.component.scss"],
})
export class ListSkeletonComponent extends AbstractSkeletonComponent implements OnInit {
  @Input()
  public rowCount = 5;

  public rowCount$!: Observable<number[]>;

  ngOnInit(): void {
    this.rowCount$ = of(Array(Number(this.rowCount)));
  }
}
