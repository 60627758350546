import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Observable } from "rxjs";
import { catchError, map, retry, startWith } from "rxjs/operators";
import { getPathForRoute } from "src/app/services/route-data.service";
import { getIsNavigationEndOrCancelOrError } from "src/app/utils/router.utils";

export enum BreadcrumbKey {
  PensionOverview = "breadcrumbs.pensionOverview",
  PensionPlan = "breadcrumbs.pensionPlan",
}

export interface BreadcrumbRouteData {
  key: BreadcrumbKey;
  path: string;
  hideAll?: boolean;
}

export interface Breadcrumb extends BreadcrumbRouteData {
  path: string;
}

@Injectable({
  providedIn: "root",
})
export class BreadcrumbsService {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.router.events.pipe(
      startWith(new NavigationEnd(0, "", "")),
      filter(getIsNavigationEndOrCancelOrError),
      map(() => this.getBreadcrumbsFromActivatedRoute()),
      retry({ delay: 250, count: 4 }),
      catchError(() => []),
    );
  }

  private getBreadcrumbsFromActivatedRoute(): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];

    const addRoute = (route: ActivatedRoute | null): void => {
      while (route?.firstChild) {
        // eslint-disable-next-line fp/no-mutation
        route = route.firstChild;

        const routeData = getBreadcrumbRouteData(route);
        const path = getOrOverridePathForRoute(route, routeData);

        if (routeData && getIsNotEqualPrevious(breadcrumbs, routeData?.key)) {
          breadcrumbs.push({ ...routeData, path });
        }
      }
    };

    addRoute(this.activatedRoute.firstChild);

    return breadcrumbs;
  }
}

function getOrOverridePathForRoute(route: ActivatedRoute, routeData?: BreadcrumbRouteData): string {
  return routeData?.path ?? getPathForRoute(route);
}

function getBreadcrumbRouteData(route: ActivatedRoute): BreadcrumbRouteData {
  return route.snapshot.data?.breadcrumb;
}

function getIsNotEqualPrevious(breadcrumbs: Breadcrumb[], breadcumbKey: BreadcrumbKey): boolean {
  return breadcumbKey ? breadcrumbs.at(-1)?.key !== breadcumbKey : true;
}
