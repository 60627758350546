import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OfaVendor } from "src/app/services/api/savings-and-pension-queries.types";
import { FmsService } from "src/app/services/fms.service";
import { combineLatest, map, Observable } from "rxjs";
import { FmsKey } from "src/app/services/fms/fms";
import { toServiceTimeText } from "src/app/modules/shared/components/engagement-details/engagement-details-public-pension/public-pension-ofa-data/helpers/toServiceTimeText";

@Component({
  selector: "app-public-pension-ofa-data",
  templateUrl: "./public-pension-ofa-data.component.html",
  styleUrls: ["./public-pension-ofa-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicPensionOfaDataComponent {
  @Input()
  public readonly ofaVendors!: OfaVendor[];
  @Input()
  public readonly headerTextFmsId: FmsKey = "publicPensionEngagement.ofa.header";

  constructor(private readonly fms: FmsService) {}

  public getServiceTimeText(ofaVendor: OfaVendor): Observable<string> {
    return combineLatest([
      this.fms.translateAsync<string>("common.years"),
      this.fms.translateAsync<string>("common.mnth"),
      this.fms.translateAsync<string>("common.days"),
      this.fms.translateAsync<string>("common.day"),
    ]).pipe(
      map(([yearsText, monthText, daysText, dayText]) =>
        toServiceTimeText(ofaVendor, yearsText, monthText, daysText, dayText),
      ),
    );
  }
}
