<ng-container *appLet="backUrl$ | async as backUrl">
  <a
    *ngIf="isInternal$ | async"
    class="button-back"
    [attr.data-trackid]="trackId"
    [routerLink]="backUrl"
    [queryParams]="currentQueryParamsWithoutExitUrl$ | async"
  >
    <i>chevron-left</i>
    <span>{{ "breadcrumbs.backButtonText" | fms }}</span>
  </a>

  <a *ngIf="isExternal$ | async" class="button-back" [attr.data-trackid]="trackId" [href]="backUrl">
    <i>chevron-left</i>
    <span>{{ "breadcrumbs.backButtonText" | fms }}</span>
  </a>
</ng-container>
