import { InjectionToken } from "@angular/core";

export enum GeneralChartColors {
  AnnualGrossIncomeColumn = "#DA291C",
  AxisLine = "#c4a587",
  Band = "rgba(25, 25, 25, 0.1)",
  Border = "#fff",
  ColumnDefault = "#268c99",
  GridLine = "#D9D9D9",
  NeedsDefault = "#7cb5ec",
  AxisText = "#666",
}

export enum RangedNeedsColors {
  DataLabel = "#191919",
  AreaMarkerFill = "white",
  AreaFill = "#E8F3E1",
  AreaStroke = "#4A966E",
  ColumnFill = "#4F3B22",
}

export enum ContractsChartColors {
  Blue70 = "#003F4A",
  Blue50 = "#0191A5",
  Blue30 = "#7ACDDC",
  Blue20 = "#D2F5F9",
  Beige90 = "#4F3B22",
  Beige70 = "#957851",
  Beige60 = "#C7AA7F",
  Beige30 = "#F5E4C7",
  Green70 = "#2B412E",
  Green50 = "#5F956A",
  Green30 = "#C6E7C1",
  Green10 = "#E8F3E1",
  Lavender70 = "#4F35A5",
  Lavender50 = "#744AFA",
  Lavender30 = "#AE96FB",
  Lavender20 = "#E8E2FB",
  Pink70 = "#8B2396",
  Pink50 = "#CA32DB",
  Pink30 = "#D777E1",
  Pink20 = "#FBD4FF",
  Camo70 = "#403E0A",
  Camo50 = "#807C14",
  Camo30 = "#CCC85E",
  Camo20 = "#FFFB93",
}

export const CONTRACTS_CHART_COLORS = [
  ContractsChartColors.Blue70,
  ContractsChartColors.Blue50,
  ContractsChartColors.Beige90,
  ContractsChartColors.Beige70,
  ContractsChartColors.Green70,
  ContractsChartColors.Green50,
  ContractsChartColors.Lavender70,
  ContractsChartColors.Lavender50,
  ContractsChartColors.Pink70,
  ContractsChartColors.Pink50,
  ContractsChartColors.Camo50,
];

export const CONTRACTS_CHART_COLORS_TOKEN = new InjectionToken<ContractsChartColors[]>("Contracts chart colors token", {
  factory: (): ContractsChartColors[] => CONTRACTS_CHART_COLORS,
});
