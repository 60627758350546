import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PresentableEngagementItem } from "../../engagement-list-initiatives.utils";

@Component({
  selector: "app-engagement-list-initiatives-item-header",
  templateUrl: "./engagement-list-initiatives-item-header.component.html",
  styleUrls: ["./engagement-list-initiatives-item-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesItemHeaderComponent {
  @Input()
  public item!: PresentableEngagementItem;

  @Input()
  public isNumberedList: boolean = false;
}
