import { Injectable } from "@angular/core";
import { firstValueFrom, map } from "rxjs";
import { MINIMAL_NATIONAL_INSURANCE_BASIC_AMOUNT_PERCENT } from "src/app/constants/business.constants";
import {
  ExternalSavingsEngagement,
  ExternalSavingsEngagementType,
} from "src/app/models/engagements/external-savings-engagement.model";
import { PrognosisQueriesService } from "src/app/services/api/prognosis-queries.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { ExternalSavingsContractWithId } from "src/app/services/customer-supplied-data/profile.service";
import { CustomerService } from "src/app/services/customer.service";
import { PrognosisQueryResult } from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { TaxRatesService } from "src/app/services/tax-rates.service";
import { getIsNullable } from "src/app/utils/utils";

type ContractProps = "type" | "addlInfo" | "contractNumber" | "balance" | "periodicDeposit";

export type ExternalSavingsContractInput = Pick<CustomerSuppliedData.ExternalSaving, ContractProps>;

@Injectable()
export class ExternalSavingsContractBuilderService {
  constructor(
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly customerService: CustomerService,
    private readonly prognosisQueriesService: PrognosisQueriesService,
    private readonly taxRatesService: TaxRatesService,
  ) {}

  public build(input: ExternalSavingsContractInput): Promise<CustomerSuppliedData.ExternalSaving> {
    return Promise.all([this.createFallbackContract(input), this.fetchPrognosisWithOneYearPayoutDuration(input)])
      .then(([contract, prognosis]) => this.createContractWithSmartDurationYears(contract, prognosis))
      .catch(() => this.createFallbackContract(input));
  }

  private async fetchPrognosisWithOneYearPayoutDuration({
    balance,
    type,
  }: ExternalSavingsContractInput): Promise<Graph.Prognosis> {
    const startPayoutAge = await firstValueFrom(this.startPayoutAgeService.getStartPayoutAge());
    const birthYear = await firstValueFrom(this.customerService.birthYear$);
    const gender = await firstValueFrom(this.customerService.gender$);

    if (!birthYear || !gender) {
      throw new Error("Cannot fetch prognosis: missing gender or birthYear");
    }

    const oneYearDefaultParams: Graph.FundOpenPrognosisInput | Graph.BankOpenPrognosisInput = {
      birthYear,
      startPayoutAge,
      gender,
      payoutDurationYears: 1,
      monthsOffset: 0,
    };
    const bankParams: Graph.BankOpenPrognosisInput = { balance, ...oneYearDefaultParams };
    const fundParams: Graph.FundOpenPrognosisInput = { marketValue: balance, ...oneYearDefaultParams };

    const queryResult$ =
      type === ExternalSavingsEngagementType.Fond
        ? this.prognosisQueriesService.getOpenPrognosisFund(fundParams)
        : this.prognosisQueriesService.getOpenPrognosisBank(bankParams);

    return firstValueFrom(
      queryResult$.pipe(
        map((queryResult) => {
          const prognosis = queryResult.data.at(0);

          if (getIsNullable(prognosis)) {
            throw new PrognosisError(queryResult, "Expected valid prognosis, got nothing");
          }

          return prognosis;
        }),
      ),
    );
  }

  private createContractWithSmartDurationYears(
    contract: CustomerSuppliedData.ExternalSaving,
    prognosis: Graph.Prognosis,
  ): CustomerSuppliedData.ExternalSaving {
    const externalSavingsEngagement = new ExternalSavingsEngagement(
      <ExternalSavingsContractWithId>contract, // We pretend to have ID here in order to compile. Assumed safe because it was never there in the first place.
      prognosis,
    );
    const averageAnnualPayout = externalSavingsEngagement.getAverageAnnualPayout() ?? 0;
    const durationYears = averageAnnualPayout / this.getMinimumAmount();

    return {
      ...contract,
      durationYears: getRoundedValueWithinOneAndTen(durationYears),
    };
  }

  private async createFallbackContract(
    input: ExternalSavingsContractInput,
  ): Promise<CustomerSuppliedData.ExternalSaving> {
    const FALLBACK_DURATION_YEARS = 10;
    const startPayoutAge = await firstValueFrom(this.startPayoutAgeService.getStartPayoutAge());

    return {
      ...input,
      isPension: true,
      fromAge: startPayoutAge,
      durationYears: FALLBACK_DURATION_YEARS,
    };
  }

  private getMinimumAmount(): number {
    return this.taxRatesService.getNationalInsuranceBasicAmount() * MINIMAL_NATIONAL_INSURANCE_BASIC_AMOUNT_PERCENT;
  }
}

function getRoundedValueWithinOneAndTen(value: number): number {
  const roundedValue = Math.round(value);

  if (roundedValue >= 10) {
    return 10;
  }

  if (roundedValue <= 0) {
    return 1;
  }

  return roundedValue;
}

class PrognosisError extends Error {
  name = "PrognosisError";

  constructor(
    public readonly engagement: PrognosisQueryResult<unknown>,
    message?: string,
  ) {
    super(message ?? `Got no prognosis in ExternalSavingsContractBuilderService`);
  }
}
