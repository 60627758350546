import { Injectable } from "@angular/core";
import { ButtonTrackIdTransformer } from "src/app/modules/shared/directives/track-id/button-track-id.transformer";
import { TrackIdTransformer } from "src/app/modules/shared/directives/track-id/track-id.directive";

@Injectable()
export class InputTrackIdTransformer implements TrackIdTransformer {
  constructor(private readonly buttonTrackIdTransformer: ButtonTrackIdTransformer) {}

  predicate(element: HTMLElement): boolean {
    return element instanceof HTMLInputElement;
  }

  transform(trackId: string): string {
    return this.buttonTrackIdTransformer.transform(trackId);
  }
}
