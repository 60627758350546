export const consentsMock: Consents.RootObject = {
  consents: [
    {
      category: "purchase_assistance",
      subCategory: "your_pension_number",
      source: "ypn",
      title: "Ditt pensjonstall",
      area: "",
      channel: "",
      consentValue: true,
      purposeCategory: "PERSONALIZED_EXPERIENCE",
      lineOfBusiness: "STB",
      purpose: "Storebrand kan følge meg opp i kjøpsprosess.",
      authLevel: "AUTHENTICATED",
      consentType: "EXPLICIT",
      collectionMethod: "WEB",
      authenticationMethodReference: "pwd",
      thirdParties: [],
      spiCategories: [],
      piiCategories: [],
      createdTimestamp: "2018-09-13T09:39:13.638",
      validFromDate: "2018-09-13T09:39:13.638",
      validToDate: "2018-10-13T00:00",
      history: [],
    },
    {
      category: "purchase_assistance",
      subCategory: "CREATE_BANK_ACCOUNT",
      source: "cba-web",
      title: "",
      area: "",
      channel: "",
      consentValue: true,
      purposeCategory: "PERSONALIZED_EXPERIENCE",
      lineOfBusiness: "STB",
      purpose:
        "Det kan være vi kontakter deg for hjelp og veiledning til å fullføre bestillingen. Meld av (jeg ønsker ikke hjelp til dette)",
      authLevel: "AUTHENTICATED",
      consentType: "EXPLICIT",
      collectionMethod: "WEB",
      authenticationMethodReference: "pwd",
      thirdParties: [],
      spiCategories: [],
      piiCategories: [],
      createdTimestamp: "2018-06-18T11:46:48.089",
      validFromDate: "2018-06-18T11:46:48.089",
      validToDate: "2018-07-18T00:00",
      history: [],
    },
    {
      category: "storage",
      subCategory: "customer_supplied_data",
      source: "nyt",
      title: "Din Pensjonsplan",
      area: "",
      channel: "",
      consentValue: true,
      purposeCategory: "PERSONALIZED_EXPERIENCE",
      lineOfBusiness: "STB",
      purpose: "Kundeprofilen din vil huske og lagre dine opplysninger underveis  ",
      authLevel: "AUTHENTICATED",
      consentType: "EXPLICIT",
      collectionMethod: "WEB",
      authenticationMethodReference: "no:bankid:regular",
      thirdParties: [],
      spiCategories: [],
      piiCategories: [],
      createdTimestamp: "2018-11-26T09:13:50.851",
      validFromDate: "2018-11-26T09:13:50.851",
      validToDate: "",
      history: [],
    },
    {
      category: "third_party",
      subCategory: "norsk_pensjon_read_access",
      source: "nyt",
      title: "Norsk pensjon",
      area: "",
      channel: "",
      consentValue: true,
      purposeCategory: "CONTRACTED_SERVICE",
      lineOfBusiness: "STB",
      purpose:
        "Jeg samtykker til at Storebrand Livsforsikring kan innhente og lagre opplysninger fra Norsk Pensjon om min alderspensjon i Folketrygden og mine private pensjonsavtaler",
      authLevel: "AUTHENTICATED",
      consentType: "EXPLICIT",
      collectionMethod: "WEB",
      authenticationMethodReference: "no:bankid:regular",
      thirdParties: [],
      spiCategories: [],
      piiCategories: [],
      createdTimestamp: "2018-11-26T09:13:50.851",
      validFromDate: "2018-11-26T09:13:50.851",
      validToDate: "",
      history: [],
    },
    {
      category: "purchase_assistance",
      subCategory: "din_pensjonsplan",
      source: "nyt",
      title: "Din Pensjonsplan",
      area: "",
      channel: "",
      consentValue: false,
      purposeCategory: "PERSONALIZED_EXPERIENCE",
      lineOfBusiness: "STB",
      purpose: "Når du benytter tjenesten, kan det hende at vi tar kontakt med deg for hjelp og veiledning",
      authLevel: "AUTHENTICATED",
      consentType: "EXPLICIT",
      collectionMethod: "WEB",
      authenticationMethodReference: "no:bankid:regular",
      thirdParties: [],
      spiCategories: [],
      piiCategories: [],
      createdTimestamp: "2019-02-14T09:45:36.338",
      validFromDate: "2019-02-14T09:45:36.338",
      validToDate: "2019-03-16T00:00",
      history: [],
    },
  ],
};
