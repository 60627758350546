const payload: Consents.PayloadCommon = {
  consentType: "EXPLICIT",
  collectionMethod: "WEB",
  lineOfBusiness: "STB",
  source: "nyt",
  numberOfDaysValid: undefined,
};

export const storagePayload: Consents.PayloadBase = {
  ...payload,
  category: "storage",
  subCategory: "customer_supplied_data",
  consentDurationType: "OPEN_ENDED",
  purposeCategory: "PERSONALIZED_EXPERIENCE",
  title: "Lagre dine data",
};

export const purchaseAssistancePayload: Consents.PayloadBase = {
  ...payload,
  category: "purchase_assistance",
  subCategory: "din_pensjonsplan",
  consentDurationType: "FIXED_NUMBER_OF_DAYS",
  purposeCategory: "PERSONALIZED_EXPERIENCE",
  title: "Din Pensjonsplan",
  numberOfDaysValid: 30,
};

export const norskpensjonPayload: Consents.PayloadBase = {
  ...payload,
  category: "third_party",
  subCategory: "norsk_pensjon_read_access",
  consentDurationType: "OPEN_ENDED",
  purposeCategory: "CONTRACTED_SERVICE",
  title: "Norsk pensjon",
};
