import { combineLatest, first, map, Observable } from "rxjs";
import { PensionArea } from "src/app/constants/business.constants";
import { GraphEngagement } from "src/app/models/engagements/graph-engagement.model";
import { EngagementName } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { select } from "src/app/utils/rxjs/select";
import { EmptyObject } from "src/app/utils/types";
import { PAYOUT_DURATIONS, PayoutDuration } from "./generic-engagement.model";

export const NAV_ENGAGEMENT_ID = "NavGraphFolketrygd";

export abstract class AbstractNavEngagement extends GraphEngagement<EmptyObject, Graph.NorskpensjonSimulationInput> {
  public constructor(prognosis?: Graph.Prognosis) {
    super({}, prognosis);
  }

  public isActive(): boolean {
    return false;
  }

  public getContracts(): unknown[] {
    return [];
  }

  public getIdentifier(): string {
    return NAV_ENGAGEMENT_ID;
  }

  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return select(this.getNameAsync());
  }

  public getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();

    return combineLatest([
      fmsService.translateAsync<string>("sharedProductNames.np.folketrygd.product_description"),
      fmsService.translateAsync<string>("sharedProductNames.np.folketrygd.product_description.description"),
      fmsService.translateAsync<string>("sharedProductNames.np.folketrygd.payer"),
    ]).pipe(
      first(),
      map(([name, description, supplier]) => ({
        name,
        description,
        supplier,
      })),
    );
  }

  public getPayoutDuration(): PayoutDuration {
    return PAYOUT_DURATIONS.infinite;
  }

  public getPensionArea(): PensionArea {
    return PensionArea.Folketrygd;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return false;
  }
}

export class NavEngagementFromNp extends AbstractNavEngagement {}
export class NavEngagementFromStb extends AbstractNavEngagement {}
