import { AnyEngagement } from "src/app/services/engagements.service";
import {
  isAfpEngagement,
  isExternalSavingsEngagement,
  isOtherPensionEngagement,
} from "src/app/utils/engagement.typeguards";

export function getIsUserAddedEngagement(engagement: AnyEngagement): boolean {
  return isExternalSavingsEngagement(engagement) || isOtherPensionEngagement(engagement) || isAfpEngagement(engagement);
}
