<div class="content" *ngIf="canChangePublicPensionWorkingPercentage$ | async">
  <ng-container *appLet="disableFormfields$ | async as disableFormfields">
    <div>
      <h4>
        {{ "simulationAge.partTimeToggle.label" | fms }}
      </h4>

      <app-yes-no-radio-buttons
        [value$]="postPensionPartTimeEnable$"
        (change)="onEnableChange($event)"
        [disabled]="disableFormfields"
      ></app-yes-no-radio-buttons>
    </div>

    <div class="parameters" *ngIf="postPensionPartTimeEnable$ | async">
      <app-part-time-percent-form-field
        class="parameter"
        [disabled]="disableFormfields"
      ></app-part-time-percent-form-field>

      <app-public-pension-withdrawal-percentage-form-field
        class="parameter"
        *ngIf="showPublicPensionWithdrawalPercentFormField$ | async"
        [disabled]="disableFormfields"
      ></app-public-pension-withdrawal-percentage-form-field>

      <app-part-time-end-age-form-field
        class="parameter"
        *ngIf="publicPensionEngagementSimParams$ | async as publicPensionEngagementSimParams"
        [engagement]="publicPensionEngagement$ | async"
        [engagementSimParams]="publicPensionEngagementSimParams"
        [disabled]="disableFormfields"
      ></app-part-time-end-age-form-field>

      <app-post-pension-future-salary-form-field
        class="parameter"
        *ngIf="showExpectedFutureIncomeFormField$ | async"
        [disabled]="disableFormfields"
      ></app-post-pension-future-salary-form-field>
    </div>
  </ng-container>
</div>
