import { Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type Contract =
  | Graph.EpkContract
  | Graph.EpkFleksibelContract
  | Graph.EpkFleksibelPkbContract
  | Graph.EpkEmploymentContract;

export abstract class AbstractEpkEngagement<TContract extends Contract> extends SavingsAndPensionEngagement<TContract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }

  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract);
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.contractNumber;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public isActive(): boolean {
    return true;
  }

  public hasChangeableProfile(): boolean {
    return true;
  }
}

export class EpkEngagement extends AbstractEpkEngagement<Graph.EpkContract> {
  public getAllocationCode(): Nullable<string> {
    return this.contract?.allocationCode;
  }
}
export class EpkFleksibelEngagement extends AbstractEpkEngagement<Graph.EpkFleksibelContract> {
  public getAllocationCode(): Nullable<string> {
    return this.contract?.allocationCode;
  }
}
export class EpkEmploymentEngagement extends AbstractEpkEngagement<Graph.EpkEmploymentContract> {}
