import { AnyEngagement } from "src/app/services/engagements.service";
import { SimulationParametersByEngagement } from "src/app/services/common-parameters.service";
import { getIsVisibleEngagement } from "src/app/utils/engagement.utils";
import { isExternalSavingsEngagement, isOtherPensionEngagement } from "src/app/utils/engagement.typeguards";

export function getSimulationParametersForUnsavedEngagements(
  engagements: AnyEngagement[],
  previousSimulationParams: SimulationParametersByEngagement[],
  startPayoutAge: number,
  hasAfp: boolean,
): SimulationParametersByEngagement[] {
  return engagements
    .filter((anyEngagement) => getIsVisibleEngagement(anyEngagement, hasAfp))
    .filter((anyEngagement) => !isExternalSavingsEngagement(anyEngagement) && !isOtherPensionEngagement(anyEngagement))
    .filter((anyEngagement) => {
      const existsInClientData = previousSimulationParams.some(
        (param) => param[0] === anyEngagement.getSimulationParametersKey(),
      );
      return !existsInClientData;
    })
    .map((anyEngagement) => [anyEngagement.getSimulationParametersKey(), { startPayoutAge }]);
}
