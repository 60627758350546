import { Injector } from "@angular/core";
import { Log } from "./utils/log";
import { Monitoring } from "./utils/monitoring";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

/**
 * Exposes the injector as a singleton and may be used
 * wherever the injector can not be injected as a dependency.
 * PS! Some test speccs may require you to run setAppInjector(TestBed.inject(Injector))
 */
export let AppInjector: Injector;

/**
 * @see https://stackoverflow.com/a/43695820/1162819
 */
export function setAppInjector(injector: Injector): void {
  if (!AppInjector) {
    // eslint-disable-next-line fp/no-mutation
    AppInjector = injector;
  }
}

export function getAppInjector(): Injector {
  if (!AppInjector) {
    const msg = "AppInjector is not set";
    Log.error(msg); // Make the error visible in the jest console
    Monitoring.error(new Error(msg), { ignore: isStageLocalhost() });
  }
  return AppInjector;
}
