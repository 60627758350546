import { Injectable } from "@angular/core";
import { produce } from "immer";
import { isEmpty, uniqWith } from "lodash-es";
import { Observable, ObservedValueOf, combineLatest } from "rxjs";
import { combineLatestWith, distinctUntilChanged, filter, map } from "rxjs/operators";
import { MAX_SIMULATION_AGE } from "src/app/constants/business.constants";
import {
  GenericEngagementOfUnknown,
  GenericEngagementTypeOf,
  PrognosisParametersTypeOf,
} from "src/app/models/engagements/generic-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { ArrayType } from "src/app/utils/array";
import { Monitoring } from "src/app/utils/monitoring";
import { pruneProps } from "src/app/utils/object";
import { select, select$, selectObject$ } from "src/app/utils/rxjs/select";
import { DeepPartial } from "src/app/utils/types";
import { getIsNotNullable } from "src/app/utils/utils";
import {
  isAnyNorskpensjonEngagement,
  isExternalSavingsEngagement,
  isOtherPensionEngagement,
  isPublicPensionEngagement,
  isSavingsAndPensionEngagement,
} from "../utils/engagement.typeguards";
import { ConflictingSimulationParams } from "../utils/errors";
import { PrognosisParametersInput } from "./api/savings-and-pension-queries.types";
import { CustomerService } from "./customer.service";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

export type SimulationParametersByEngagement = ArrayType<
  CustomerSuppliedData.ClientData["simulationParametersByEngagement"]
>;

export type EngagementWithSimParams<T> = [T, SimulationParametersByEngagement];

interface PostPensionSimParams {
  enable: boolean;
  endAge: number;
  partTimePercentage: number;
  futureSalary: number;
  withdrawalPercentage: number;
}

@Injectable({
  providedIn: "root",
})
export class CommonParametersService {
  public annualGrossIncome$: Observable<number | null>;
  public postPensionPartTimeEnable$: Observable<boolean>;
  public postPensionPartTimeEndAge$: Observable<number>;
  public postPensionPartTimePercent$: Observable<number>;
  public postPensionSimParams$: Observable<PostPensionSimParams>;
  public simulationParameters$: Observable<CustomerSuppliedData.ClientData["simulationParameters"]>;
  public isWorkingPartTime$: Observable<boolean>;
  public isAgeHigherThanMaxSimAge$: Observable<boolean>;
  public simulationParametersByEngagementEnable$ = this.clientDataService.simulationParametersByEngagementEnable$;

  constructor(
    private readonly customerService: CustomerService,
    private readonly profileService: ProfileService,
    private readonly clientDataService: ClientDataService,
  ) {
    this.postPensionPartTimePercent$ = select$(this.clientDataService.clientDataWrapper$, (data) =>
      Number(data.clientData?.simulationParameters.postPensionPartTimePercent),
    );

    this.postPensionPartTimeEnable$ = select$(
      this.clientDataService.clientDataWrapper$,
      (data) => !!data.clientData.simulationParameters.postPensionPartTimeEnable,
    );

    this.postPensionPartTimeEndAge$ = select$(this.clientDataService.clientDataWrapper$, (data) =>
      Number(data.clientData.simulationParameters.postPensionPartTimeEndAge),
    );

    const postPensionWithdrawalPercentage$ = select$(
      this.clientDataService.clientDataWrapper$,
      (data) => data.clientData?.simulationParameters.postPensionWithdrawalPercentage,
    );

    const postPensionFutureSalary$ = select$(this.clientDataService.clientDataWrapper$, (data) =>
      Number(data.clientData.simulationParameters.postPensionFutureSalary),
    );

    this.postPensionSimParams$ = combineLatest([
      this.postPensionPartTimeEnable$,
      this.postPensionPartTimeEndAge$,
      this.postPensionPartTimePercent$,
      postPensionWithdrawalPercentage$,
      postPensionFutureSalary$,
    ]).pipe(
      map(([enable, endAge, partTimePercentage, withdrawalPercentage, futureSalary]) => ({
        enable,
        endAge,
        partTimePercentage,
        withdrawalPercentage,
        futureSalary,
      })),
    );

    this.annualGrossIncome$ = select$(this.profileService.profile$, (data) => {
      const value = data?.annualSalary?.value;
      const valueAsNumber =
        typeof value === "string" || typeof value === "number" ? parseInt(value.toString(), 10) : null;
      return Number.isNaN(valueAsNumber) ? null : valueAsNumber;
    });

    this.simulationParameters$ = selectObject$<
      CustomerSuppliedData.ClientDataWrapper,
      ObservedValueOf<typeof CommonParametersService.prototype.simulationParameters$>
    >(this.clientDataService.clientDataWrapper$, (data) => data.clientData.simulationParameters);

    this.isWorkingPartTime$ = select$(
      this.simulationParameters$,
      (simulationParameters) => (simulationParameters?.postPensionPartTimePercent ?? 0) > 0,
    );

    this.isAgeHigherThanMaxSimAge$ = this.customerService.age$.pipe(map((age) => (age ?? 0) > MAX_SIMULATION_AGE));

    /**
     * Due to cross-app pension simulation business rules, certain data is shared
     * across apps through storage in the main document (i.e. profile data). As
     * such, simulation parameters are stored in the following locations:
     *   - External Savings: main document
     *   - All other savings/prognoses: sub-document, because this feature is
     *     smart-pensjon only
     *
     * Since we are storing simulation parameters by engagement IDs in different
     * locations, we need to be aware of ID collisions. This stream emits
     * whenever cross-document ID collisions occur.
     */
    const idCollisions$ = this.clientDataService.simulationParametersByEngagement$.pipe(
      map((list) => list || []),
      combineLatestWith(this.profileService.externalSavings$),
      map(([simParams, external]) => simParams.filter(([key]) => external.some((e) => e.id === key))),
      filter((el) => !isEmpty(el)),
      distinctUntilChanged(),
    );

    /* Handle ID collisions by warning and deleting from sub-document */
    idCollisions$.subscribe((next) => {
      next
        .map<SimulationParametersByEngagement>(([key]) => [key, {}])
        .forEach((params) => this.clientDataService.updateSimulationParametersByEngagement(params));
      const error = new ConflictingSimulationParams(next);
      Monitoring.warn(error);
    });
  }

  public setSimulationParameter(
    params: DeepPartial<
      Pick<
        CustomerSuppliedData.ClientData,
        | "simulationParametersByEngagementEnable"
        | "simulationParameters"
        | "simulationParametersPartialWithdrawalEnable"
      >
    >,
  ): void {
    const patch: Partial<CustomerSuppliedData.ClientData> = {
      simulationParametersByEngagementEnable: params.simulationParametersByEngagementEnable,
      simulationParametersPartialWithdrawalEnable: params.simulationParametersPartialWithdrawalEnable,
      simulationParameters: {
        ...select(this.simulationParameters$),
        ...params.simulationParameters,
      },
    };

    this.clientDataService.updateClientData(pruneProps(patch));
  }

  /**
   * Creates a list of observables for each key in keys. Intended to be
   * passed to creator functions that take multiple Observables, like
   * merge() or zip().
   *
   * To get one emission per value, use merge():
   *   - merge(...mapEngagementListToSimParams$(keys))
   * To get one emission with all values combined in a list, use zip():
   *   - zip(...mapEngagementListToSimParams$(keys))
   */
  public mapEngagementListToSimParams$<T extends GenericEngagementOfUnknown>(
    engagements: T[],
  ): Observable<EngagementWithSimParams<T>>[] {
    return engagements.map((e) => this.mapEngagementToSimParams$<T>(e));
  }

  public mapEngagementToSimParams$<T extends GenericEngagementOfUnknown>(
    engagement: T,
  ): Observable<EngagementWithSimParams<T>> {
    return this.clientDataService.simulationParametersByEngagement$.pipe(
      combineLatestWith(
        this.profileService.externalSavings$.pipe(map(externalSavingsToSimulationParameters)),
        this.clientDataService.otherPensions$.pipe(map(otherPensionsToSimulationParameters)),
        this.clientDataService.compressionLimitByEngagement$,
        this.clientDataService.investmentProfileInPayoutPeriodByEngagement$,
      ),
      map(mergeEngagementSimulationParameters),
      map((allParamsByEngagement) => {
        const key = engagement.getSimulationParametersKey();

        // Extract any value that matches the key
        const paramsByKey: Partial<CustomerSuppliedData.SimulationParameters>[] = allParamsByEngagement
          .filter(([k]) => k === key)
          .map(([_, v]) => v);

        if (paramsByKey.length > 1) {
          // If the key is not unique then notify us we're in trouble
          Monitoring.error({
            ignore: isStageLocalhost(),
            name: "MappingError",
            message: `Engagement key: ${key} matched multiple sets of parameters.
With multiple matches there is no telling which set of parameters is used and will lead to undefined user experience.
High severity issue.`,
            metadata: {
              engagementKey: key,
              simulationParametersByEngagement: allParamsByEngagement,
            },
          });
        }
        // Use either custom params if they exist, and fallback to main simulation
        // parameters in order to deliver a single stream of simulation parameters
        return [engagement, [key, paramsByKey[0] ?? {}]];
      }),
    );
  }

  public updateSimulationParametersByEngagement(
    engagement: GenericEngagementOfUnknown,
    engagementParams: SimulationParametersByEngagement,
  ): void {
    if (isExternalSavingsEngagement(engagement)) {
      this.profileService.updateExternalEngagement(engagementParams);
    } else if (isOtherPensionEngagement(engagement)) {
      const periods = setFirstOtherPensionPeriodParams(engagement, engagementParams);

      this.clientDataService.updateOtherPension(engagement.getSimulationParametersKey(), {
        periods,
      });
    } else {
      this.clientDataService.updateSimulationParametersByEngagement(engagementParams);
    }
  }

  public persistPrognosisParametersInput(engagements: GenericEngagementOfUnknown[]): void {
    const prognosisParametersByEngagement = makePrognosisParametersByEngagement(engagements);

    this.clientDataService.updatePrognosisParametersIfChanged(prognosisParametersByEngagement);
  }
}

function mergeEngagementSimulationParameters([
  params,
  externalParams,
  otherPensionParams,
  compressionParams,
  investmentProfileInPayoutPeriod,
]: [
  SimulationParametersByEngagement[],
  SimulationParametersByEngagement[],
  SimulationParametersByEngagement[],
  SimulationParametersByEngagement[],
  SimulationParametersByEngagement[],
]): typeof params {
  /**
   * Due to OtherPension params being provided through `otherPensionParams`,
   * any OtherPension params that exists in `params` may be both outdated
   * and considered duplicates. These are removed.
   */
  const uniqueOtherPensionParamsAndGeneralParams = uniqWith(
    // The order is important, the first occurance of a duplicate wins
    otherPensionParams.concat(params),
    ([keyA], [keyB]) => keyA === keyB,
  );

  return mergeListsOfSimParamsByEngagement(
    params,
    externalParams,
    uniqueOtherPensionParamsAndGeneralParams,
    compressionParams,
    investmentProfileInPayoutPeriod,
  );
}

export function mergeListsOfSimParamsByEngagement(
  ...simParams: SimulationParametersByEngagement[][]
): SimulationParametersByEngagement[] {
  const mergedParams: SimulationParametersByEngagement[] = [];

  simParams.forEach((paramArray) => {
    paramArray?.forEach(([key, value]) => {
      const existingIndex = mergedParams.findIndex(([existingKey]) => existingKey === key);

      if (existingIndex === -1) {
        mergedParams.push([key, { ...value }]);
      } else {
        Object.assign(mergedParams[existingIndex][1], value);
      }
    });
  });

  return mergedParams;
}

function externalSavingsToSimulationParameters(
  input: ObservedValueOf<typeof ProfileService.prototype.externalSavings$>,
): SimulationParametersByEngagement[] {
  return (input || []).map(({ id, fromAge, durationYears }) => [id, { startPayoutAge: fromAge, durationYears }]);
}

function otherPensionsToSimulationParameters(
  input: CustomerSuppliedData.OtherPension[],
): SimulationParametersByEngagement[] {
  return (input || []).map(({ id, periods }) => {
    const [period] = periods;
    const startPayoutAge = period.fromAge;
    const durationYears = period.duration;

    return [id, { startPayoutAge, durationYears }];
  });
}

function setFirstOtherPensionPeriodParams(
  engagement: OtherPensionEngagement,
  engagementParams: SimulationParametersByEngagement,
): CustomerSuppliedData.OtherPensionPeriod[] {
  const [, simParams] = engagementParams;

  return produce(engagement.getPeriods(), (draft) => {
    if (simParams.startPayoutAge) {
      draft[0].fromAge = simParams.startPayoutAge;
    }

    if (simParams.durationYears) {
      draft[0].duration = simParams.durationYears;
    }
  });
}

export function makePrognosisParametersByEngagement<E extends GenericEngagementTypeOf<E>>(
  engagements: E[],
): CustomerSuppliedData.PrognosisParametersByEngagement {
  const engagementsWithPrognosisParameters = engagements.filter((engagement) =>
    getIsNotNullable(engagement.prognosisParametersInput),
  );

  const storebrandSavings = makePrognosisParametersCategory(
    engagementsWithPrognosisParameters.filter((engagement) => isSavingsAndPensionEngagement(engagement)),
  );

  const storebrandPublic = makePrognosisParametersCategory(
    engagementsWithPrognosisParameters.filter((engagement) => isPublicPensionEngagement(engagement)),
  );
  const norskpensjon = makePrognosisParametersCategory(
    engagementsWithPrognosisParameters.filter((engagement) => isAnyNorskpensjonEngagement(engagement)),
  );

  return pruneProps({
    storebrandSavings,
    storebrandPublic,
    norskpensjon,
  }) as CustomerSuppliedData.PrognosisParametersByEngagement;
}

function makePrognosisParametersCategory<E extends GenericEngagementTypeOf<E>>(
  engagements: E[],
): {
  [x: string]: PrognosisParametersTypeOf<E> & PrognosisParametersInput;
} {
  return engagements.reduce((result, engagement) => {
    return { ...result, [engagement.getIdentifier()]: engagement.prognosisParametersInput };
  }, {});
}
