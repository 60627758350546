import { Injectable } from "@angular/core";
import { routes } from "src/app/modules/pension-plan/routes";
import { NavigateToService } from "src/app/services/navigate-to.service";
import { PensionPlanProgressService } from "./pension-plan-progress.service";

@Injectable({ providedIn: "root" })
export class NavigateToPensionPlanService {
  constructor(
    private readonly navigateToService: NavigateToService,
    private readonly pensionPlanProgressService: PensionPlanProgressService,
  ) {}

  public navigateTo(pensionPlanRoute: string): void {
    const url = `${routes.Root}/${pensionPlanRoute}`;
    this.navigateToService.navigateToUrlWithOnboarding(url);
  }

  public navigateToProgressUrl(): void {
    this.pensionPlanProgressService.getProgressUrl().subscribe((progressUrl) => {
      this.navigateToService.navigateToUrlWithOnboarding(progressUrl);
    });
  }
}
