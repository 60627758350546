<ng-content *ngIf="isTruthy((renderTrigger$ | async)!); else textSkeleton"></ng-content>
<ng-template #textSkeleton>
  <div
    *ngFor="let _ of [].constructor(lines)"
    class="text-skeleton"
    [class.multiple]="isMultipleLines()"
    [class]="type"
    [style.width]="getRandomWidth()"
  >
    <!-- &nbsp; to ensure same height as parent text element -->
    &nbsp;
  </div>
</ng-template>
