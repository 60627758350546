import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first, Observable, OperatorFunction, pipe, switchMap, throwError } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { KeycloakService } from "src/app/services/keycloak.service";
import { Log } from "src/app/utils/log";
import { Monitoring } from "src/app/utils/monitoring";
import { environment } from "src/environments/environment";
import { EndpointService } from "../services/endpoint.service";
import { isStbEmployeeError } from "../utils/errors";
import { getCorrelationIdFromHeaders } from "./correlation.interceptor";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

@Injectable()
export class KeycloakBearerInterceptor implements HttpInterceptor {
  private readonly name = "[KeycloakBearerInterceptor]";

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly endpointService: EndpointService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.endpointService.isKeycloakProtectedResource(req.url).pipe(
      first(),
      switchMap((isProtectedResource) => {
        if (!isProtectedResource || environment.offlineMode) {
          return next.handle(req).pipe(handlError(req));
        }

        return this.keycloakService.getAndUpdateToken().pipe(
          this.logToken("getToken()"),
          mergeMap((token) =>
            next
              .handle(
                req.clone({
                  headers: req.headers.append("Authorization", "Bearer " + token),
                }),
              )
              .pipe(this.logToken("handle()")),
          ),
          handlError(req),
        );
      }),
    );
  }

  private logToken(method: string): OperatorFunction<any, any> {
    return pipe(
      catchError((error) => {
        Log.important(
          `${this.name} ${method} failed.
          ${this.keycloakService.getTokenTimestamps()}`,
        );
        return throwError(error);
      }),
      tap((response: HttpResponseBase) => {
        if (response?.status !== 0) {
          return;
        }

        Log.important(
          `${this.name} ${method} failed with status 0.
          ${this.keycloakService.getTokenTimestamps()}`,
        );
      }),
    );
  }
}

function handlError(req: HttpRequest<any>): OperatorFunction<any, any> {
  return pipe(
    catchError((error) => {
      Monitoring.error(error, {
        tags: {
          correlationId: getCorrelationIdFromHeaders(req.headers),
        },
        extras: {
          error,
          url: req.urlWithParams,
        },
        ignore: isStbEmployeeError(error) || isStageLocalhost(),
      });
      return throwError(error);
    }),
  );
}
