export enum RouteKey {
  Root = "root",
  Article = "article",
}

export const routes = {
  root: "nyttig-om-pensjon",
  article: "artikkel",
  pensionReturnsIn2023: "slik-gikk-det-med-pensonspengene-dine-i-2023",
  backPayments: "tilbakebetale-pensjon",
};
