import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Event, NavigationEnd, Router } from "@angular/router";
import { ChipItem } from "@storeblocks/chip-ng";
import { delay } from "lodash-es";
import { BehaviorSubject, combineLatest, firstValueFrom, fromEvent, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { RouteKey, routes } from "src/app/modules/articles/routes";
import { DynamicArticle } from "src/app/modules/shared/components/articles/article-card/article-card.component";
import { FmsService } from "src/app/services/fms.service";
import { filterUnique } from "src/app/utils/array";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";

// Angular Router automatically scrolls to the top of the page
// on navigation. The scroll event fires somewhere between 0 and
// <the value below> milliseconds after navigation. Use this value
// to delay custom scrolling.
const NATIVE_SCROLL_WINDOW = 20;

@Component({
  selector: "app-articles-overview-page",
  templateUrl: "./articles-overview-page.component.html",
  styleUrls: ["./articles-overview-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesOverviewPageComponent {
  public readonly ALL_TAG = "all";
  public readonly isEnglish = this.fmsService.getLocale() === "en";
  public readonly enabledArticles$!: Observable<DynamicArticle[]>;
  public readonly articles$!: Observable<DynamicArticle[]>;
  public readonly articles = new FormControl<ChipItem[]>([]);

  private lastScrollPosition = 0;
  private readonly selectedTag$ = new BehaviorSubject<string>(this.ALL_TAG);

  constructor(
    private readonly fmsService: FmsService,
    private readonly router: Router,
  ) {
    this.scrollToLastPosition();
    this.saveLastScrollPosition();

    this.enabledArticles$ = this.fmsService
      .translateAsync<DynamicArticle[]>("articles.dynamicArticles")
      .pipe(map(getEnabledArticles));

    this.articles$ = combineLatest([this.enabledArticles$, this.selectedTag$]).pipe(
      map(([articles, selectedTag]) =>
        articles.filter((article) => selectedTag === this.ALL_TAG || article.tags?.some((tag) => tag === selectedTag)),
      ),
    );

    this.initArticleFormControl();
  }

  public setFilter(selectedTag: ChipItem): void {
    this.selectedTag$.next(String(selectedTag.value));
  }

  public trackArticleBy(_: any, article: DynamicArticle): Nullable<string> {
    return article.key;
  }

  public trackTagBy(_: any, tag: string): string {
    return tag;
  }

  private async initArticleFormControl(): Promise<void> {
    const allItemsLabel = await firstValueFrom(this.fmsService.translateAsync("articles.articlesOverview.allArticles"));
    const initialChipItem: ChipItem = {
      id: this.ALL_TAG,
      label: allItemsLabel,
      selected: true,
      trackId: "all-articles",
      value: this.ALL_TAG,
    };

    const tags = await firstValueFrom(this.enabledArticles$.pipe(map(getTagsFromArticles)));
    const tagChipItems = tags.map((tag, index) => ({
      id: tag,
      label: tag,
      selected: false,
      trackId: `articles-filter-${index + 1}`,
      value: tag,
    }));

    this.articles.setValue([initialChipItem, ...tagChipItems]);
  }

  private scrollToLastPosition(): void {
    this.router.events.pipe(filter(isComponentNavigationUrl)).subscribe(() => {
      const lastScrollPosition = this.lastScrollPosition;
      delay(() => window.scrollTo(0, lastScrollPosition), NATIVE_SCROLL_WINDOW);
    });
  }

  private saveLastScrollPosition(): void {
    fromEvent(window, "scroll")
      .pipe(filter(() => isComponentUrl(this.router.url)))
      .subscribe(() => {
        this.lastScrollPosition = window.scrollY;
      });
  }
}

function getEnabledArticles(articles: DynamicArticle[]): DynamicArticle[] {
  return articles.filter((article) => article.enabled);
}

function getTagsFromArticles(articles: DynamicArticle[]): string[] {
  const allTags = articles.flatMap(({ tags }) => tags).filter(getIsNotNullable);

  return filterUnique(allTags);
}

function isComponentUrl(url: string): boolean {
  return url === `/${routes[RouteKey.Root]}`;
}

function isComponentNavigationUrl(event: Event): boolean {
  return event instanceof NavigationEnd && isComponentUrl(event.url);
}
