import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, map } from "rxjs";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { OnboardingService, OnboardingStepKey } from "src/app/services/onboarding.service";
import { ButtonLink, ContractActionBuilder } from "../contract-actions.types";
import { CustomerService } from "../../../../../services/customer.service";

@Injectable()
export class GiveOfaConsentActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly fmsService: FmsService,
    private readonly customerService: CustomerService,
  ) {}

  public predicate(engagement: AnyEngagement): boolean | Observable<boolean> {
    if (!(engagement instanceof PublicPensionEngagement)) {
      return false;
    }

    return this.customerService.ofaConsent$.pipe(map((ofaConsent) => ofaConsent !== true));
  }

  public async build(): Promise<ButtonLink> {
    const [linkLabel, linkTrackId] = await Promise.all([this.getLinkLabel(), this.getLinkTrackId()]);

    return {
      linkLabel,
      linkTrackId,
      onClick: (): void => {
        this.openPublicPensionOnboarding();
      },
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>("contractActions.giveOfaConsent.linkLabel"));
  }

  private getLinkTrackId(): Promise<string> {
    return Promise.resolve("contractAction-giveOfaConsent");
  }

  private openPublicPensionOnboarding(): void {
    this.onboardingService.open({}, [OnboardingStepKey.PublicPension]);
  }
}
