import { Component } from "@angular/core";
import { AlertKey } from "src/app/modules/shared/components/alerts/information-message/information-message.component";

@Component({
  selector: "app-error-page",
  templateUrl: "errors-page.component.html",
  styleUrls: ["errors-page.component.scss"],
})
export class ErrorsPageComponent {
  public readonly AlertKey = AlertKey;

  public reloadPage(): void {
    location.reload();
  }
}
