<div class="summary-table" [class.summary-table-responsive]="responsive">
  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.totalPayout" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ getTotalPayout() | currencyFormatterPipe: "start" }}
      </ng-container>
    </div>
  </div>

  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.payoutDuration" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ getDuration() }}
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="engagement.contract.periods.length > 1">
  <p>
    {{ "engagementBodyOther.periodAverage" | fms }}
  </p>

  <div class="summary-table">
    <div class="summary-row" *ngFor="let period of engagement.contract.periods">
      <div class="summary-label">
        {{ getPeriodLabel(period) }}
      </div>

      <div class="summary-value">
        {{ period.payoutAmountPerYear | currencyFormatterPipe: "start" }}
      </div>
    </div>
  </div>
</ng-container>

<app-contract-type-description
  *ngIf="engagement.contract.replaceOffentligTjenestepensjon"
  [engagement]="engagement"
  [showDescription]="showDescription"
></app-contract-type-description>

<p
  *ngIf="engagement.contract.replaceOffentligTjenestepensjon"
  [innerHTML]="'otherPension.offentligTjenestepensjon' | fms"
></p>

<ng-template #amountUnavailable>
  <app-amount-unavailable></app-amount-unavailable>
</ng-template>
