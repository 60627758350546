import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { WINDOW } from "src/app/tokens";
import { ButtonLink, ContractActionBuilder } from "../contract-actions.types";
import { isFripoliseEngagement } from "src/app/utils/engagement.typeguards";

@Injectable()
export class ConvertToFmiActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly fmsService: FmsService,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {}

  public predicate(engagement: AnyEngagement): boolean {
    return isFripoliseEngagement(engagement);
  }

  public async build(_engagement: AnyEngagement): Promise<ButtonLink> {
    const [linkLabel, linkUrl, linkTrackId] = await Promise.all([
      this.getLinkLabel(),
      this.getLinkUrl(),
      this.getLinkTrackId(),
    ]);

    return {
      linkLabel,
      linkTrackId,
      onClick: (): void => {
        this.window.open(linkUrl, "_blank");
      },
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("contractActions.convertToFMI.linkLabel"));
  }

  private getLinkUrl(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("contractActions.convertToFMI.linkUrl"));
  }

  private getLinkTrackId(): Promise<string> {
    return Promise.resolve("navigate-to-convert-to-fmi-contract-action");
  }
}
