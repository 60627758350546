import { Inject, Injectable, InjectionToken, inject } from "@angular/core";
import { Observable, combineLatest, map, shareReplay, switchMap } from "rxjs";
import { Nullable, getIsNotNullable } from "../../utils/utils";
import { ClientDataService } from "../customer-supplied-data/client-data.service";
import { FmsKey } from "../fms/fms";
import { IncomeEmployer } from "./strategies/income-employer";
import { IncomePublicPension } from "./strategies/income-public-pension";
import { IncomeUser } from "./strategies/income-user";

export interface IncomeStrategy {
  income$: Observable<Income>;
  predicate(): Observable<boolean>;
  getHintText(): Observable<FmsKey>;
}

export interface Income {
  value: Nullable<number>;
  source: CustomerSuppliedData.IncomeSource;
}

@Injectable({
  providedIn: "root",
})
export class IncomeService {
  public readonly income$: Observable<Income>;
  public readonly hint$: Observable<FmsKey>;
  public readonly annualGrossIncome$: Observable<number | undefined>;

  private readonly activeIncomeStrategy$ = getActiveIncomeStrategy(this.incomeStrategies).pipe(shareReplay());

  constructor(
    @Inject(INCOME_STRATEGIES)
    private readonly incomeStrategies: IncomeStrategy[],
    private readonly clientDataService: ClientDataService,
  ) {
    this.income$ = this.activeIncomeStrategy$.pipe(
      switchMap((strategy) => strategy.income$),
      shareReplay(),
    );
    this.hint$ = this.activeIncomeStrategy$.pipe(switchMap((strategy) => strategy.getHintText()));
    this.annualGrossIncome$ = this.income$.pipe(map((income) => income.value ?? undefined));
  }

  public getAnnualGrossIncomeOrDefault(defaultValue: number): Observable<number> {
    return this.annualGrossIncome$.pipe(map((income) => (getIsNotNullable(income) ? income : defaultValue)));
  }

  public setUserSelectedIncomeSource(value: CustomerSuppliedData.ClientData["incomeSource"]): void {
    this.clientDataService.updateIncomeSource(value);
  }
}

export function createIncomeObject(value: Nullable<number>, source: Income["source"]): Income {
  return {
    value,
    source,
  };
}

export const INCOME_STRATEGIES = new InjectionToken("Contract action builders", {
  providedIn: "any",
  factory(): IncomeStrategy[] {
    return [inject(IncomePublicPension), inject(IncomeEmployer), inject(IncomeUser)];
  },
});

function getActiveIncomeStrategy(incomeStrategies: IncomeStrategy[]): Observable<IncomeStrategy> {
  const predicates: Observable<boolean>[] = incomeStrategies.map((strategy) => strategy.predicate());
  const fallbackStrategyIndex = incomeStrategies.length - 1;

  return combineLatest(predicates).pipe(
    map((predicateResults) => predicateResults.findIndex((result) => result)),
    map((truePredicateIndex) => {
      const hasTruePredicate = truePredicateIndex !== -1;
      const strategyIndex = hasTruePredicate ? truePredicateIndex : fallbackStrategyIndex;

      return incomeStrategies[strategyIndex];
    }),
  );
}
