import { BankTransactionOutput } from "../api/bank-rest-api.service";

export const transactionsMock: BankTransactionOutput[] = [
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: 627.0,
    fromAccount: "",
    toAccount: "91005269755",
    transactionText: "Ola Nordmann",
    bookDate: "2021-04-13T00:00:00.000+02:00",
    postingDate: "2021-04-13T00:00:00.000+02:00",
    valueDate: "2021-04-13T00:00:00.000+02:00",
    transactionCode: "impy",
    transactionCodeDescription: "straksbetaling",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -94.25,
    fromAccount: "91005269755",
    toAccount: "",
    transactionText: "STEAM PURCHASE",
    bookDate: "2021-04-13T00:00:00.000+02:00",
    postingDate: "2021-04-09T00:00:00.000+02:00",
    valueDate: "2021-04-09T00:00:00.000+02:00",
    transactionCode: "idab",
    transactionCodeDescription: "Internet kjøp debetkort utland",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -292.0,
    fromAccount: "91005269755",
    toAccount: "91000100337",
    transactionText: "Ola Nordmann",
    bookDate: "2021-04-14T00:00:00.000+02:00",
    postingDate: "2021-04-14T00:00:00.000+02:00",
    valueDate: "2021-04-14T00:00:00.000+02:00",
    transactionCode: "impy",
    transactionCodeDescription: "straksbetaling",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -446.0,
    fromAccount: "91005269755",
    toAccount: "91000100337",
    transactionText: "Ola Nordmann",
    bookDate: "2021-04-14T00:00:00.000+02:00",
    postingDate: "2021-04-14T00:00:00.000+02:00",
    valueDate: "2021-04-14T00:00:00.000+02:00",
    transactionCode: "impy",
    transactionCodeDescription: "straksbetaling",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -2738.25,
    fromAccount: "91005269755",
    toAccount: "91003002028",
    transactionText: "lån 91003002028 15-04-2021",
    bookDate: "2021-04-15T00:00:00.000+02:00",
    postingDate: "2021-04-15T00:00:00.000+02:00",
    valueDate: "2021-04-15T00:00:00.000+02:00",
    transactionCode: "stof",
    transactionCodeDescription: "faste bet.overføringer 2",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -1723.0,
    fromAccount: "91005269755",
    toAccount: "",
    transactionText: "STATENS LÅNEKASSE FOR UTDAN. 15-04-2021",
    bookDate: "2021-04-15T00:00:00.000+02:00",
    postingDate: "2021-04-15T00:00:00.000+02:00",
    valueDate: "2021-04-15T00:00:00.000+02:00",
    transactionCode: "giki",
    transactionCodeDescription: "giroinnbetaling m/KID",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: 30000.0,
    fromAccount: "",
    toAccount: "91005269755",
    transactionText: "- STOREBRAND ASA",
    bookDate: "2021-04-15T00:00:00.000+02:00",
    postingDate: "2021-04-15T00:00:00.000+02:00",
    valueDate: "2021-04-15T00:00:00.000+02:00",
    transactionCode: "lonn",
    transactionCodeDescription: "lønn",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -25000.0,
    fromAccount: "91005269755",
    toAccount: "91003002028",
    transactionText: "Overf. til 91003002028",
    bookDate: "2021-04-16T00:00:00.000+02:00",
    postingDate: "2021-04-16T00:00:00.000+02:00",
    valueDate: "2021-04-16T00:00:00.000+02:00",
    transactionCode: "trhm",
    transactionCodeDescription: "overføring selvbetjening",
  },
  {
    transactionId: randomTransactionId(),
    accountNumber: "91005269755",
    amount: -600.0,
    fromAccount: "91005269755",
    toAccount: "",
    transactionText: "Tibber Norge AS 21-04-2021",
    bookDate: "2021-04-21T00:00:00.000+02:00",
    postingDate: "2021-04-21T00:00:00.000+02:00",
    valueDate: "2021-04-21T00:00:00.000+02:00",
    transactionCode: "giki",
    transactionCodeDescription: "giroinnbetaling m/KID",
  },
];

function randomTransactionId(): string {
  return Array(118)
    .fill(null)
    .map(() => Math.random().toString(36).substr(2))
    .join("");
}
