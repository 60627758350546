import { Component, Input } from "@angular/core";
import { DisplayableHttpError } from "src/app/services/errors.types";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent {
  @Input()
  error!: DisplayableHttpError;
}
