import { formatNumber } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { map, Observable } from "rxjs";
import { FmsService } from "./fms.service";

interface SmartAccountConfig {
  enableCampaign: boolean;
  campaignInterest: string | number;
  todaysInterest: string | number;
}

@Injectable({
  providedIn: "root",
})
export class SmartAccountInterestService {
  private readonly smartAccountConfig$: Observable<SmartAccountConfig>;

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly fms: FmsService,
  ) {
    this.smartAccountConfig$ = this.fms.translateAsync<SmartAccountConfig>("smartAccountConfig");
  }

  public getIsInterestCampaignEnabled(): Observable<boolean> {
    return this.smartAccountConfig$.pipe(
      map((smartAccountConfig) => {
        return smartAccountConfig.enableCampaign;
      }),
    );
  }

  public getNewCustomerFormattedInterest(): Observable<string> {
    return this.smartAccountConfig$.pipe(
      map((smartAccountConfig) => {
        const interest = smartAccountConfig.enableCampaign
          ? smartAccountConfig.campaignInterest
          : smartAccountConfig.todaysInterest;

        return this.formatInterest(interest);
      }),
    );
  }

  public getTodaysFormattedInterest(): Observable<string> {
    return this.smartAccountConfig$.pipe(
      map((smartAccountConfig) => this.formatInterest(smartAccountConfig.todaysInterest)),
    );
  }

  private formatInterest(interest: string | number): string {
    return formatNumber(Number(interest.toString().replace(",", ".")), this.locale, "1.2");
  }
}
