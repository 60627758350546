/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { filter, first, map, mergeMap, Observable, of } from "rxjs";
import { getIsNotNullable } from "src/app/utils/utils";

@Component({
  selector: "app-breadcrumb-back-button",
  templateUrl: "./breadcrumb-back-button.component.html",
  styleUrls: ["./breadcrumb-back-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbBackButtonComponent {
  @Input()
  public to!: string;

  @Input()
  public trackId!: string;

  private readonly hasExitUrlQueryParam$ = this.getExitUrlQueryParam().pipe(map(getIsNotNullable));
  private readonly exitUrlFromQueryParam$ = this.getExitUrlQueryParam().pipe(filter(getIsNotNullable));

  public readonly currentQueryParamsWithoutExitUrl$ = this.getCurrentQueryParamsWithoutExitUrl();
  public readonly backUrl$ = this.hasExitUrlQueryParam$.pipe(
    mergeMap((hasExitUrlQueryParam) => (hasExitUrlQueryParam ? this.exitUrlFromQueryParam$ : of(this.to))),
  );
  public readonly isExternal$ = this.backUrl$.pipe(map(getIsAbsoluteUrl));
  public readonly isInternal$ = this.backUrl$.pipe(map(getIsRelativeUrl));

  constructor(private readonly route: ActivatedRoute) {}

  private getExitUrlQueryParam(): Observable<string | null> {
    return this.route.queryParamMap.pipe(
      map((paramsMap) => paramsMap.get("exitUrl") || paramsMap.get("exiturl")),
      first(),
    );
  }

  private getCurrentQueryParamsWithoutExitUrl(): Observable<Params> {
    return this.route.queryParams.pipe(map((params) => ({ ...params, exitUrl: null, exiturl: null })));
  }
}

function getIsAbsoluteUrl(url: string): boolean {
  return url.indexOf("http://") === 0 || url.indexOf("https://") === 0;
}

function getIsRelativeUrl(url: string): boolean {
  return !getIsAbsoluteUrl(url);
}
