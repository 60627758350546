import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { debounceTime, filter, map, Subject, takeUntil } from "rxjs";
import { DEFAULT_INPUT_DEBOUNCE_TIME } from "src/app/constants/technical.constants";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { getIsNotNullable } from "src/app/utils/utils";

@Component({
  selector: "app-post-pension-future-salary-form-field",
  templateUrl: "./post-pension-future-salary-form-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostPensionFutureSalaryFormFieldComponent implements OnDestroy, OnChanges {
  @Input()
  public readonly disabled = false;

  public formControl: FormControl<number | null> = new FormControl(null, [Validators.required]);

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly commonParametersService: CommonParametersService,
    private readonly pensionPlanService: PensionPlanService,
  ) {
    this.commonParametersService.postPensionSimParams$
      .pipe(
        map((partTime) => partTime.futureSalary),
        takeUntil(this.destroy$),
      )
      .subscribe((futureSalary) => this.formControl.setValue(futureSalary || 0, { emitEvent: false }));

    this.formControl.valueChanges
      .pipe(debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME), filter(getIsNotNullable), takeUntil(this.destroy$))
      .subscribe((salary) => {
        this.pensionPlanService.changePostPensionFutureSalary(salary);
      });
  }

  public ngOnChanges(): void {
    if (this.disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
