import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpCache } from "src/app/services/http-cache.service";
import { Log } from "src/app/utils/log";
import { batcherPipe } from "../utils/rxjs/pipes";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private readonly name = "[CachingInterceptor]";
  private readonly cacheHitBatcher$: Subject<string> = new Subject();
  private readonly cachedResponsesBatcher$: Subject<string> = new Subject();
  private readonly CACHE_BATCHER_DEBOUNCE = 2000;

  constructor(private readonly cache: HttpCache) {
    this.cacheHitBatcher$
      .pipe(batcherPipe(this.cacheHitBatcher$, this.CACHE_BATCHER_DEBOUNCE))
      .subscribe((hits) => Log.important(`${this.name} Cache hits:`, hits));
    this.cachedResponsesBatcher$
      .pipe(batcherPipe(this.cachedResponsesBatcher$, this.CACHE_BATCHER_DEBOUNCE))
      .subscribe((hits) => Log.important(`${this.name} Responses cached:`, hits));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Continue if not cacehable
    if (!isCacheable(req)) {
      return next.handle(req);
    }

    // First, check the cache to see if this request exists.
    const cachedResponse = this.cache.get(req);
    if (cachedResponse) {
      this.cacheHitBatcher$.next(req.urlWithParams);
      // A cached response exists. Serve it instead of forwarding
      // the request to the next handler.
      return observableOf(cachedResponse);
    }

    // No cached response exists. Go to the network, and cache
    // the response when it arrives.
    return next.handle(req).pipe(
      tap((event) => {
        // Remember, there may be other events besides just the response.
        if (event instanceof HttpResponse) {
          this.cachedResponsesBatcher$.next(req.urlWithParams);
          // Update the cache.
          this.cache.put(req, event);
        }
      }),
    );
  }
}

/**
 * Defines whether a request is cacheable or not.
 * @param req the HttpRequest to send
 * @returns false if not cacheable, otherwise true
 */
function isCacheable(req: HttpRequest<any>): boolean {
  // Skip if the request method isn't GET.
  if (req.method !== "GET") {
    return false;
  }

  //Skip if the request header contentType is not application/json
  if (req.responseType !== "json") {
    return false;
  }

  // Skip if caching is disabled for this request.
  return !req.headers.get("Cache-control")?.match(/no-cache|no-store/g);
}
