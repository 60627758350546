<app-action-card
  [title]="'actions.savingsAdvisor.title' | fms"
  [icon]="'actions.savingsAdvisor.icon' | fms"
  [content]="'actions.savingsAdvisor.content' | fms"
  [outlinedPrimaryButton]="false"
  [primaryButton]="'actions.savingsAdvisor.primaryButton' | fms"
  (primaryButtonClick)="routerLinkToSavingsPage()"
  [action]="action"
>
</app-action-card>
