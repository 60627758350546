import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-expansion-panel-accordion",
  templateUrl: "./expansion-panel-accordion.component.html",
  styleUrls: ["./expansion-panel-accordion.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelAccordionComponent {
  @Input()
  public expanded = true;
  @Output()
  public expandedChange = new EventEmitter<boolean>();
  @Input()
  public showTitle = true;
  @Input()
  public showFooter = false;
  @Input()
  public footerExpanded = false;
}
