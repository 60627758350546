import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  EpkEmploymentEngagement,
  EpkEngagement,
  EpkFleksibelEngagement,
} from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import {
  isEpkEmploymentEngagement,
  isEpkEngagement,
  isEpkFleksibelEngagement,
} from "src/app/utils/engagement.typeguards";

export type AnyEpkEngagement = EpkEngagement | EpkFleksibelEngagement | EpkEmploymentEngagement;

@Injectable()
export class ActionEpkService {
  constructor(private readonly engagementsService: EngagementsService) {}

  public getFirstEpkFromStorebrand(): Observable<AnyEpkEngagement | undefined> {
    return this.engagementsService.allEngagements$.pipe(map((engagements) => engagements.find(getIsAnyEpkEngagement)));
  }
}

export function getIsAnyEpkEngagement(engagement: AnyEngagement): engagement is AnyEpkEngagement {
  return isEpkEngagement(engagement) || isEpkFleksibelEngagement(engagement) || isEpkEmploymentEngagement(engagement);
}
