import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { Observable, combineLatest, map } from "rxjs";
import { FmsService } from "src/app/services/fms.service";

@Component({
  selector: "app-yes-no-radio-buttons",
  templateUrl: "./yes-no-radio-buttons.component.html",
})
export class YesNoRadioButtonsComponent implements OnChanges {
  @Input()
  public value$!: Observable<boolean>;
  @Input()
  public readonly disabled = false;

  @Output()
  public change = new EventEmitter<boolean>();

  public buttonGroup$?: Observable<RadioButton[]>;

  private readonly yesText$ = this.fms.translateAsync<string>("common.yes");
  private readonly noText$ = this.fms.translateAsync<string>("common.no");

  constructor(private readonly fms: FmsService) {}

  public ngOnChanges(): void {
    this.buttonGroup$ = combineLatest([this.value$, this.yesText$, this.noText$]).pipe(
      map((props) => toYesNoRadioButtons(...props, this.disabled)),
    );
  }

  public onValueChanged(radioButton: RadioButton): void {
    const booleanValue = radioButton.value === "true";
    this.change.emit(booleanValue);
  }
}

function toYesNoRadioButtons(value: boolean, yesText: string, noText: string, disabled: boolean): RadioButton[] {
  const yesRadioButton$: RadioButton = {
    label: yesText,
    value: "true",
    disabled,
    checked: value,
  };
  const noRadioButton$: RadioButton = {
    label: noText,
    value: "false",
    disabled,
    checked: !value,
  };

  return [noRadioButton$, yesRadioButton$];
}
