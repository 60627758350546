import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ResponseHeaders } from "src/app/modules/graphql-clients/local-schema/response-headers";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { PrognosisQueryResult } from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { ALL_PROGNOSIS_FIELDS_FRAGMENT } from "./graphql-query-fragments";

export interface GetNavFolketrygdPrognosisQuery {
  norskpensjon: {
    folketrygdPrognosis: Graph.Prognosis;
  };
  headers?: ResponseHeaders;
}

export const GET_NAV_FOLKETRYGD_PROGNOSIS_QUERY = gql`
  query getNavFolketrygdPrognosisQuery($input: NorskpensjonInput) {
    headers @client {
      correlationId
    }
    norskpensjon(input: $input) {
      folketrygdPrognosis {
        ...AllPrognosisFields
      }
    }
  }
  ${ALL_PROGNOSIS_FIELDS_FRAGMENT}
`;

@Injectable({
  providedIn: "root",
})
export class NavQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getNavFolketrygdPrognosis(
    variables: Graph.QueryNorskpensjonArgs,
  ): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.savingsGraphqlClient
      .query<GetNavFolketrygdPrognosisQuery>({
        query: GET_NAV_FOLKETRYGD_PROGNOSIS_QUERY,
        variables,
      })
      .pipe(mergeMap(getApolloResponseData), map(getFolketrygdPrognosisFromResponse));
  }
}

function getFolketrygdPrognosisFromResponse(
  query: GetNavFolketrygdPrognosisQuery,
): PrognosisQueryResult<Graph.Prognosis[]> {
  const prognosis = query?.norskpensjon?.folketrygdPrognosis;

  return {
    data: prognosis ? [prognosis] : [],
    headers: query.headers,
  };
}
