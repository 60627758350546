import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { produce } from "immer";
import { first, merge } from "lodash-es";
import { BehaviorSubject, EMPTY, Observable, ObservedValueOf, defaultIfEmpty, firstValueFrom, iif, of } from "rxjs";
import { catchError, combineLatestWith, filter, map, switchMap, tap } from "rxjs/operators";
import { clientId } from "src/app/constants/api.constants";
import { SimulationParametersByEngagement } from "src/app/services/common-parameters.service";
import { ConsentService } from "src/app/services/consent.service";
import { AbstractCustomerSuppliedDataService } from "src/app/services/customer-supplied-data/abstract-customer-supplied-data.service";
import { EndpointService, httpHeaderNoCache } from "src/app/services/endpoint.service";
import { EngagementService } from "src/app/services/engagement.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { SessionStorageService, StorageKey } from "src/app/services/session-storage.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { handleError } from "src/app/utils/http";
import { Monitoring } from "src/app/utils/monitoring";
import { pruneProps } from "src/app/utils/object";
import { memoizeObject$, select, select$, selectObject$ } from "src/app/utils/rxjs/select";
import { Nullable, getIsNotNullable } from "src/app/utils/utils";

export const PROFILEPATCH_BASE: CustomerSuppliedData.ProfilePatch = {
  channel: "NYT",
};

const pruneExternalSavingsProps = (data: CustomerSuppliedData.ExternalSaving): CustomerSuppliedData.ExternalSaving => {
  const { id, ...res } = data;
  return res;
};

const pruneAnyExtraProfileProps = (data: CustomerSuppliedData.ProfilePatch): CustomerSuppliedData.ProfilePatch => {
  return produce(data, (draft) => {
    if (data.hasOwnProperty("externalSavings")) {
      draft.externalSavings = draft.externalSavings?.map(pruneExternalSavingsProps);
    }
  });
};

export type ExternalSavingsContractWithId = Required<CustomerSuppliedData.ExternalSaving>;

export interface ProfileDataWithIdedExternalSavings extends CustomerSuppliedData.Profile {
  externalSavings: CustomerSuppliedData.ObjectElementList<ExternalSavingsContractWithId>;
}

@Injectable({
  providedIn: "root",
})
export class ProfileService extends AbstractCustomerSuppliedDataService {
  public profile$: Observable<CustomerSuppliedData.Profile>;
  public internalSavings$: Observable<CustomerSuppliedData.InternalSaving[]>;
  public externalSavings$: Observable<ExternalSavingsContractWithId[]>;
  public partTimePercent$: Observable<number>;
  public hasAfp$: Observable<boolean>;

  private readonly _profile$: BehaviorSubject<ProfileDataWithIdedExternalSavings>;
  private readonly _profileWriteActive$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly keyCloakService: KeycloakService,
    private readonly consentService: ConsentService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly engagementService: EngagementService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    @Optional()
    @Inject("profileInitData")
    profileInitData?: CustomerSuppliedData.Profile,
  ) {
    super();

    this._profile$ = new BehaviorSubject(
      toProfileDataWithIdedExternalSavings(profileInitData || ProfileService.generateDefaultProfileData()),
    );

    this.profile$ = memoizeObject$(this._profile$);

    this.partTimePercent$ = select$(this.profile$, ({ partTimePercent }) => getValueOrDefault(partTimePercent, 100));

    this.hasAfp$ = select$(this.profile$, ({ hasAFP }) => getValueOrDefault(hasAFP, false));

    this.internalSavings$ = selectObject$(this.profile$, ({ internalSavings }) =>
      internalSavings && internalSavings.value ? internalSavings.value : [],
    );

    this.externalSavings$ = selectObject$(this.profile$, ({ externalSavings }) =>
      externalSavings && externalSavings.value ? <ExternalSavingsContractWithId[]>externalSavings.value : [],
    );

    this.consentService.lastTwoStorageConsents$
      .pipe(
        map((consents) => consents.top() === true && consents.next() === false),
        filter((consentWasGiven) => consentWasGiven),
        switchMap(() =>
          this.writeLocalToRemote(this._profile$.getValue(), this._profileWriteActive$, this.patchProfile.bind(this)),
        ),
      )
      .subscribe();

    this.startPayoutAgeService
      .getStartPayoutAgeRange()
      .pipe(
        map((ageRange) => first(ageRange)),
        filter(getIsNotNullable),
        combineLatestWith(this.externalSavings$),
        filter(([minAge, extSavings]) => extSavings.some((e) => e.fromAge < minAge)),
        map(([minAge, extSavings]) => mapExternalEngagementsToMinAge(extSavings, minAge)),
      )
      .subscribe((extSavings) => {
        this.setProfileExternalSavings(extSavings);
      });
  }

  public get profile(): ObservedValueOf<typeof this._profile$> {
    return this._profile$.getValue();
  }

  public get hasAfp(): boolean {
    return this.profile.hasAFP ? this.profile.hasAFP.value : false;
  }

  public get partTimePercent(): number {
    return this.profile?.partTimePercent?.value ?? 100;
  }

  public get internalSavings(): typeof this.profile.internalSavings {
    return this.profile.internalSavings || { value: [], lastUpdated: 0 };
  }

  public get internalSavingsAgreements(): typeof this.internalSavings.value {
    return this.internalSavings.value;
  }

  public get externalSavings(): typeof this.externalSavingsList.value {
    return this.externalSavingsList.value;
  }

  private get externalSavingsList(): typeof this.profile.externalSavings {
    return this.profile.externalSavings || { value: [], lastUpdated: 0 };
  }

  private static generateDefaultProfileData(): ProfileDataWithIdedExternalSavings {
    return <ProfileDataWithIdedExternalSavings>{};
  }

  public updateExternalEngagement(engagementParams: SimulationParametersByEngagement): void {
    const [key, { startPayoutAge, durationYears }] = engagementParams;
    const patch: Partial<CustomerSuppliedData.ExternalSaving> = pruneProps({
      fromAge: startPayoutAge,
      durationYears,
    });

    const patchedExternalSavings = this.externalSavings.map((externalSaving) =>
      externalSaving.id === key ? { ...externalSaving, ...patch } : externalSaving,
    );

    this.setProfileExternalSavings(patchedExternalSavings);
  }

  public setProfileSalaryYearGross(value: number | string): void {
    this.patchProfile({
      annualSalary: {
        lastUpdated: Date.now(),
        value: Number(value),
      },
    }).subscribe();
  }

  public setProfileHasAfp(value: boolean): Promise<unknown> {
    const request$ = this.patchProfile({
      hasAFP: {
        lastUpdated: Date.now(),
        value: value,
      },
    }).pipe(defaultIfEmpty(null));

    return firstValueFrom(request$);
  }

  public setProfileInternalSavings(savingsAgreements: CustomerSuppliedData.InternalSaving[]): Promise<unknown | null> {
    const request$ = this.patchProfile({
      internalSavings: {
        lastUpdated: Date.now(),
        value: savingsAgreements,
      },
    }).pipe(defaultIfEmpty(null));

    return firstValueFrom(request$);
  }

  public setProfileExternalSaving(patch: CustomerSuppliedData.ExternalSaving): string {
    const copy = [...this.externalSavings];
    const maybeIndex = this.externalSavings.findIndex((el) => el.id === patch.id);
    const index = maybeIndex > -1 ? maybeIndex : copy.length;
    const patchWithId = { ...patch, id: patch.id || copy.length.toString() };

    copy.splice(index, 1, patchWithId);

    this.engagementService.setEngagementInSessionStorage(Number(patchWithId.id));
    this.setProfileExternalSavings(copy);

    return patchWithId.id;
  }

  public setProfileExternalSavings(externalSavings: ExternalSavingsContractWithId[]): void {
    this.patchProfile({
      externalSavings: {
        lastUpdated: Date.now(),
        value: externalSavings,
      },
    }).subscribe();
  }

  public deleteProfileExternalSaving(deleted: CustomerSuppliedData.ExternalSaving): void {
    const copy = [...this.externalSavings];
    const index = copy.findIndex((el) => el.id === deleted.id);

    if (index > -1) {
      copy.splice(index, 1);
    } else {
      Monitoring.warn("Failed to locate external saving. Entry not deleted.", {
        extras: {
          deleted,
        },
      });
    }

    this.engagementService.removeEngagementFromSessionStorage(Number(deleted.id));
    this.setProfileExternalSavings(copy);
  }

  public setProfileFirstYearPensionPayout(amount: number, age: number): void {
    this.patchProfile({
      firstYearPensionPayout: {
        lastUpdated: Date.now(),
        value: { amount, age },
        source: {
          id: this.profile.cmid,
          type: "CMID",
          role: "CUSTOMER",
          ...PROFILEPATCH_BASE,
        },
      },
    }).subscribe();
  }

  /**
   * Prefer using the public accessors, like deleteExternalSavings() or updateProfileHasAfp() to
   * fire-and-forget updates to the profile. The public access to this function serves
   * mainly to make it testable.
   */
  public patchProfile(partialProfile: Partial<ProfileDataWithIdedExternalSavings>): Observable<unknown | never> {
    const patchLocalProfile$ = new Observable((sub) => {
      this.setProfile({ ...this.profile, ...partialProfile });
      sub.next(); // Emit in order to proceed; the setter returns void so emitting nothing is fine
      sub.complete(); // Our job is done, so we complete
    });
    return patchLocalProfile$.pipe(
      map(
        () =>
          Boolean(select(this.consentService.customerSuppliedDataConsent$)) &&
          !select(this.keyCloakService.advisorIncognitoMode$),
      ),
      switchMap((shouldWriteToRemote) =>
        iif(
          () => shouldWriteToRemote,
          this.endpointService.composeCustomerSuppliedDataUrl().pipe(
            switchMap((url) =>
              this.http.patch(url, JSON.stringify(mapProfileToPatch(partialProfile)), {
                headers: new HttpHeaders({
                  ClientId: clientId,
                  "Content-Type": "application/json",
                }),
              }),
            ),
          ),
          EMPTY,
        ),
      ),
      catchError((err) =>
        handleError("ProfileService::patchProfile", {
          ...err,
          message: `payload: ${JSON.stringify(mapProfileToPatch(partialProfile))} `,
        }),
      ),
    );
  }

  public fetchProfile(): Observable<CustomerSuppliedData.Profile> {
    const profileFromSession$ = of(this.readProfileFromSessionStorageOrDefault());
    const profileFromBackend$ = this.endpointService.composeCustomerSuppliedDataUrl().pipe(
      switchMap((url) => this.endpointService.httpGet$<CustomerSuppliedData.Profile>(url, httpHeaderNoCache)),
      map((res) => merge(ProfileService.generateDefaultProfileData(), res)),
      map(toProfileDataWithIdedExternalSavings),
    );

    return iif(
      () => !select(this.consentService.customerSuppliedDataConsent$),
      profileFromSession$,
      this._profileWriteActive$.pipe(
        filter((isActive) => !isActive),
        switchMap(() => profileFromBackend$),
      ),
    ).pipe(
      catchError((err) =>
        handleError("ProfileService::fetchProfile", err, this.readProfileFromSessionStorageOrDefault()),
      ),
      tap((profile) => this.setProfile(profile)),
    );
  }

  /**
   * Always use this setter in order to keep session storage in sync with the
   * in-memory representation of profile. Never next() directly on profile.
   */
  private setProfile(profile: ProfileDataWithIdedExternalSavings): void {
    this.sessionStorageService.set(StorageKey.Profile, profile);
    this._profile$.next(profile);
  }

  private readProfileFromSessionStorageOrDefault(): ProfileDataWithIdedExternalSavings {
    return this.sessionStorageService.get<ProfileDataWithIdedExternalSavings>(
      StorageKey.Profile,
      ProfileService.generateDefaultProfileData(),
    );
  }
}

export function sumExternalSavingsBalance(externalSavings: CustomerSuppliedData.ExternalSaving[]): number {
  return externalSavings.reduce((accu, curr) => accu + curr.balance, 0);
}

export function mapExternalEngagementsToMinAge(
  externalEngagements: ExternalSavingsContractWithId[],
  minFromAge: number,
): ExternalSavingsContractWithId[] {
  return externalEngagements.map((externalSaving) => ({
    ...externalSaving,
    fromAge: !externalSaving.fromAge || externalSaving.fromAge < minFromAge ? minFromAge : externalSaving.fromAge,
  }));
}

export function mapProfileToPatch(
  partialProfile: Partial<CustomerSuppliedData.Profile>,
): CustomerSuppliedData.ProfilePatch {
  const partialProfilePatch: Partial<CustomerSuppliedData.ProfilePatch> = Object.fromEntries(
    Object.entries(partialProfile).map(([key, value]) => [key, getDataElementValueOrValue(value)]),
  );

  const patch: CustomerSuppliedData.ProfilePatch = {
    ...PROFILEPATCH_BASE,
    ...partialProfilePatch,
  };

  return pruneAnyExtraProfileProps(patch);
}

function getValueOrDefault<T, F>(value: Nullable<CustomerSuppliedData.DataElement<T>>, fallback: F): T | F {
  return value?.value ?? fallback;
}

export function toProfileDataWithIdedExternalSavings(
  profile: CustomerSuppliedData.Profile,
): ProfileDataWithIdedExternalSavings {
  if (profile?.externalSavings?.value == null) {
    return <ProfileDataWithIdedExternalSavings>profile;
  }

  return <ProfileDataWithIdedExternalSavings>produce(profile, (draft) => {
    draft.externalSavings.value = draft.externalSavings.value.map((saving, i) => ({
      ...saving,
      id: i.toString(),
    }));
  });
}

function getDataElementValueOrValue(value: CustomerSuppliedData.DataElement<any> | any): any {
  return value?.value ?? value;
}
