<div class="action-card stb-typography" [ngClass]="styling">
  <img [src]="icon" alt />

  <h3 class="stb-mb2">{{ title }}</h3>

  <div class="action-card-content" [innerHTML]="content"></div>

  <div class="buttons-wrapper" [class.secondary-button-wrapper]="!outlinedPrimaryButton">
    <button
      *ngIf="primaryButton"
      [stbButton]="outlinedPrimaryButton ? 'outlined' : 'text'"
      [setIconAfter]="!!primaryButton.icon"
      [iconType]="primaryButton.icon"
      [attr.data-trackid]="primaryButton.trackId"
      (click)="primaryButtonClick.emit()"
    >
      {{ primaryButton.text }}
    </button>

    <div class="secondary-button-wrapper" *ngIf="secondaryButton">
      <button
        stbButton="text"
        [setIconAfter]="!!secondaryButton.icon"
        [iconType]="secondaryButton.icon"
        [attr.data-trackid]="secondaryButton.trackId"
        (click)="secondaryButtonClick.emit()"
      >
        {{ secondaryButton.text }}
      </button>
    </div>
  </div>
</div>
