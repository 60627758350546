import { Directive, forwardRef, Provider } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NON_BREAKING_SPACE } from "src/app/constants/technical.constants";
import { ControlValueDirective } from "src/app/modules/shared/directives/control-value.directive";
import { getIsNotNullable } from "src/app/utils/utils";

const YEARS_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputYearMaskDirective),
  multi: true,
};

type Year = string | number;

@Directive({
  selector: "input[appYearsMask]",
  providers: [YEARS_CONTROL_VALUE_ACCESSOR],
})
export class InputYearMaskDirective extends ControlValueDirective<HTMLInputElement, Year> {
  protected writeToView(value: string | number): void {
    const element = this.elementRef.nativeElement;
    const prettyValue = getIsNotNullable(value) ? `${value}${NON_BREAKING_SPACE}år` : "";

    this.setProperty("value", prettyValue);

    // Position caret before " år"
    const caretPosition = value.toString().length;
    element.setSelectionRange(caretPosition, caretPosition);
  }

  protected sanitizeValue(value: Year): number {
    const normalizedValue = parseInt(value as string, 10);

    if (isNaN(normalizedValue)) {
      return 0;
    }

    return normalizedValue;
  }
}
