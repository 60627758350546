import { AbstractControl } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { BehaviorSubject } from "rxjs";

export interface AddEngagementStep {
  windowTitle: string;
  windowTitleWithName?: string;
  backButtonText: string;
  nextButtonText: string;
  controlToValidate: AbstractControl | undefined;
  submit?: boolean;
}

export abstract class AbstractNewEngagementStepperComponent<T extends object> {
  public engagementSteps: AddEngagementStep[] = [];
  public readonly stepper$ = new BehaviorSubject<number>(0);
  public readonly isSubmitting$ = new BehaviorSubject<boolean>(false);

  protected constructor(protected readonly dialogRef: MatDialogRef<T>) {}

  public onBackButton(): void {
    const stepperValue = this.stepper$.getValue();
    stepperValue !== 0 ? this.stepper$.next(stepperValue - 1) : this.dialogRef.close();
  }

  public async onNextButton(): Promise<void> {
    if (this.isSubmitting$.getValue()) {
      return;
    }

    const stepperValue = this.stepper$.getValue();
    const control = this.engagementSteps[stepperValue].controlToValidate;

    if (this.isValid(control)) {
      if (this.isSubmitStep()) {
        await this.startSubmit();
      }

      if (!this.isFinalStep()) {
        this.stepper$.next(stepperValue + 1);
      } else {
        this.dialogRef.close();
      }
    } else {
      control?.markAllAsTouched();
    }
  }

  public isFinalStep(): boolean {
    return this.stepper$.getValue() === this.engagementSteps.length - 1;
  }

  private isSubmitStep(): boolean {
    const isAnySubmitStep = this.engagementSteps.some((step) => step.submit);

    if (!isAnySubmitStep && this.isFinalStep()) {
      return true;
    }

    const index = this.stepper$.getValue();
    const currentStep = this.engagementSteps.at(index);

    return !!currentStep?.submit;
  }

  private isValid(control: AbstractControl | undefined): boolean {
    return control?.status !== "INVALID";
  }

  private async startSubmit(): Promise<void> {
    this.isSubmitting$.next(true);
    await this.submit();
    this.isSubmitting$.next(false);
  }

  abstract submit(): void | Promise<void>;
}
