<div class="content-container">
  <app-menu></app-menu>

  <app-dev class="hide-only-on-print" *ngIf="showDevPanel$ | async"></app-dev>

  <div class="flex">

    @if (hasCriticalErrors$ | async) {
      <app-error-page></app-error-page>
    } @else if (hasRoleErrors$ | async) {
      <app-role-error></app-role-error>
    } @else {
      <div class="outlet">
        <div *ngIf="!outlet.isActivated" class="page-init-spinner absolute-spinner"></div>

        <div *ngIf="outlet.isActivated" class="container hide-only-on-print">
          <app-breadcrumbs *ngIf="showBreadcrumbs$ | async"></app-breadcrumbs>
        </div>

        <router-outlet id="router-outlet" tabindex="-1" #outlet="outlet"></router-outlet>
      </div>
    }
  </div>

  <app-footer></app-footer>
</div>

<app-refresh-modal></app-refresh-modal>
