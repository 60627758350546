import { Directive, ElementRef, forwardRef, HostListener, Input, Provider, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Nullable } from "src/app/utils/utils";

const INTEGER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  /* eslint-disable @typescript-eslint/no-use-before-define */
  useExisting: forwardRef(() => IntegerMaskDirective),
  multi: true,
};

@Directive({
  selector: "input[appIntegerMask]",
  providers: [INTEGER_CONTROL_VALUE_ACCESSOR],
})
export class IntegerMaskDirective implements ControlValueAccessor {
  @Input()
  public digitCap = 8;

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
  ) {}

  @HostListener("input", ["$event"])
  public onInput(event: Event): void {
    const value: string = (event.target as HTMLInputElement).value;
    const parsedIntegerValue = parseInt(
      value == null
        ? ""
        : value
            .toString()
            .replace(/[^0-9]*/g, "")
            .substring(0, this.digitCap),
      10,
    );

    const nullOrIntegerValue = isNaN(parsedIntegerValue) ? null : parsedIntegerValue;

    this.writeValue(nullOrIntegerValue);
    this.onChange(nullOrIntegerValue);
  }

  @HostListener("blur")
  public onBlur(_: any): void {
    this.onTouched(_);
  }

  public writeValue(value: Nullable<number>): void {
    const inputEl = this.elementRef.nativeElement as HTMLInputElement;
    this.renderer.setProperty(inputEl, "value", value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange: (_: any) => any = (_) => _;
  private onTouched: (_: any) => any = (_) => _;
}
