<div class="background full-page-width-limiter" [class.active]="isActive" *ngIf="actions.length > 0">
  <div class="content">
    <ul class="flex">
      <li *ngFor="let action of actions">
        <ng-container
          *ngIf="isActionTypeInternalLink(action)"
          [ngTemplateOutlet]="InternalLink"
          [ngTemplateOutletContext]="{ action: action }"
        ></ng-container>

        <ng-container
          *ngIf="isActionTypeExternalLink(action)"
          [ngTemplateOutlet]="ExternalLink"
          [ngTemplateOutletContext]="{ action: action }"
        ></ng-container>

        <ng-container
          *ngIf="isActionTypeButtonLink(action)"
          [ngTemplateOutlet]="ButtonLink"
          [ngTemplateOutletContext]="{ action: action }"
        ></ng-container>
      </li>
    </ul>
  </div>
</div>

<ng-template #InternalLink let-action="action">
  <a
    class="anchor-stbButton-wrapper"
    [routerLink]="action.linkRouter"
    queryParamsHandling="merge"
    [attr.data-trackid]="action.linkTrackId"
    tabIndex="-1"
  >
    <button role="link" stbButton="text" [setIconAfter]="true" [iconType]="action.linkIcon">
      <span class="stb-btn-text-custom">{{ action.linkLabel }}</span>
    </button>
  </a>
</ng-template>

<ng-template #ExternalLink let-action="action">
  <a class="anchor-stbButton-wrapper" [href]="action.linkUrl" [attr.data-trackid]="action.linkTrackId" tabIndex="-1">
    <button role="link" stbButton="text" [setIconAfter]="true" [iconType]="action.linkIcon">
      <span class="stb-btn-text-custom">{{ action.linkLabel }}</span>
    </button>
  </a>
</ng-template>

<ng-template #ButtonLink let-action="action">
  <button
    stbButton="text"
    [setIconAfter]="true"
    [iconType]="action.linkIcon"
    [attr.data-trackid]="action.linkTrackId"
    (click)="action.onClick()"
  >
    <span class="stb-btn-text-custom">{{ action.linkLabel }}</span>
  </button>
</ng-template>
