import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

type ChipType = "success" | "success-accent";

@Component({
  selector: "app-chip",
  templateUrl: "./chip.component.html",
  styleUrls: ["./chip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input()
  public text!: string;
  @Input()
  public type: ChipType = "success-accent";

  @HostBinding("class")
  public get getTypeClass(): string {
    return `chip-${this.type}`;
  }
}
