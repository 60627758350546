import { ChangeDetectionStrategy, Component } from "@angular/core";
import { map } from "rxjs";
import { AlertKey } from "src/app/modules/shared/components/alerts/information-message/information-message.component";
import { CustomerService } from "src/app/services/customer.service";
import { SmartAccountService, SmartAccountState } from "src/app/services/smart-account.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  public readonly AlertKey = AlertKey;
  public readonly firstName$ = this.customerService.name$.pipe(map(({ firstName }) => firstName));
  public readonly isQualifiedFromSmartAccount$ = this.smartAccountService.smartAccountState$.pipe(
    map((state) => state === SmartAccountState.Qualified),
  );

  constructor(
    private readonly customerService: CustomerService,
    private readonly smartAccountService: SmartAccountService,
  ) {}
}
