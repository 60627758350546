import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, firstValueFrom, map, tap } from "rxjs";
import { apiJson } from "src/app/constants/api.constants";
import { apiHomeEnvironmentRegex } from "src/app/constants/regex.constants";
import { handleError } from "src/app/utils/http";
import { select$ } from "src/app/utils/rxjs/select";
import { ReplayStore } from "src/app/utils/rxjs/store";
import { GlobalRunningJobsService } from "./running-jobs/global-running-jobs.service";

export enum ApiUrl {
  Localhost = "/api",
  Production = "https://api.storebrand.no",
}

export enum ApiEnvironmentLetter {
  Development = "u",
  Test = "t",
  TestStable = "ts",
  Production = "p",
}

@Injectable()
export class ApiHomeService {
  public readonly apiHome$: Observable<string>;
  public readonly environmentLetter$: Observable<ApiEnvironmentLetter>;

  private readonly rootObject$ = new ReplayStore<ApiHome.RootObject>();

  constructor(
    private readonly http: HttpClient,
    private readonly globalRunningJobsService: GlobalRunningJobsService,
  ) {
    this.apiHome$ = select$(this.rootObject$, ({ apihome }) => apihome);

    this.environmentLetter$ = this.apiHome$.pipe(map((apiHome) => ApiHomeService.getEnvironmentLetter(apiHome)));
  }

  private static getEnvironmentLetter(url: string): ApiEnvironmentLetter {
    const environment = url.match(apiHomeEnvironmentRegex);

    return environment && environment[3] ? (environment[3] as ApiEnvironmentLetter) : ApiEnvironmentLetter.Production;
  }

  public async fetchApiHomeResource(): Promise<ApiHome.RootObject> {
    const request$ = this.http.get<ApiHome.RootObject>(apiJson).pipe(
      catchError((err) =>
        handleError("ApiHomeService::fetchApiHomeResource", err, {
          apihome: ApiUrl.Production,
        }),
      ),
      tap((apiObj) => this.rootObject$.next(apiObj)),
      this.globalRunningJobsService.withLoader("ApiHomeService"),
    );

    return firstValueFrom(request$);
  }
}
