<ng-container *appLet="ofaVendors$ | async as ofaVendors">
  @if (ofaVendors?.length > 0) {
    <div class="ofa-container">
      <app-public-pension-ofa-data [ofaVendors]="ofaVendors"></app-public-pension-ofa-data>
    </div>
  }
</ng-container>

<div class="summary-table bottom-border" [class.summary-table-responsive]="responsive">
  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.totalPayout" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ getTotalPayout() | currencyFormatterPipe: "start" }}
      </ng-container>
    </div>
  </div>

  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.payoutDuration" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ getDuration() }}
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!hasError">
    <ng-container *ngFor="let details of this.contractDetails$ | async">
      <div class="summary-row">
        <div class="summary-label">
          {{ details.key | fms }}
        </div>

        <div class="summary-value">
          <ng-container *ngIf="details.value; else amountUnavailable">
            {{ details.value }}
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<app-contract-type-description [engagement]="engagement"></app-contract-type-description>

<div class="contracts" [class.active]="engagement.isActive()">
  <h3 class="stb-mb2">{{ "publicPensionEngagement.contractDetails" | fms }}</h3>

  <app-public-pension-contract
    *ngFor="let contract of engagement.getContracts()"
    [contract]="contract"
    [engagement]="engagement"
  ></app-public-pension-contract>
</div>

<ng-template #amountUnavailable>
  <app-amount-unavailable></app-amount-unavailable>
</ng-template>
