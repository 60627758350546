<app-action-dialog>
  <ng-container title>
    {{ "actions.epk.stb.dialog.title" | fms }}
  </ng-container>

  <div [innerHTML]="'actions.epk.stb.dialog.content' | fms"></div>

  <app-engagement-list-initiatives
    [headers]="[('actions.epk.stb.dialog.items.simulatedTotal' | fms)!]"
    [items]="items$ | async"
  >
  </app-engagement-list-initiatives>

  <div [innerHTML]="'actions.epk.stb.dialog.note' | fms"></div>

  <ng-container buttons>
    <button stbButton="outlined" mat-dialog-close>
      {{ "dialog.button.close.text" | fms }}
    </button>
  </ng-container>
</app-action-dialog>
