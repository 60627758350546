import { Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type Contract = Graph.PkbContract | Graph.EpkFleksibelPkbContract;

abstract class AbstractPkbEngagement<TContract extends Contract> extends SavingsAndPensionEngagement<TContract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }
  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract);
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.contractNumber;
  }

  public getAllocationCode(): Nullable<string> {
    return this.contract?.allocationCode;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public isActive(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return true;
  }
}

export class PkbEngagement extends AbstractPkbEngagement<Graph.PkbContract> {}
export class EpkFleksibelPkbEngagement extends AbstractPkbEngagement<Graph.EpkFleksibelPkbContract> {}
