<div *ngIf="showMoreButton" class="show-more-button-container">
  <button class="mat-flat-button" (click)="onToggleShowLessMoreItems()">
    <i>arrow-down</i>
    <span>
      {{ "common.showAll" | fms }}
    </span>
  </button>
</div>

<div *ngIf="showLessButton" class="show-less-button-container">
  <button class="mat-flat-button" (click)="onToggleShowLessMoreItems()">
    <i>arrow-up</i>
    <span>
      {{ "common.showLess" | fms }}
    </span>
  </button>
</div>
