import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";

interface ArticlesFaq {
  trackId: string;
  title: string;
  content: string;
}

@Component({
  selector: "app-articles-faq",
  templateUrl: "./articles-faq.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesFaqComponent {
  public list$ = this.fmsService.translateAsync<ArticlesFaq[]>("articlesFaq.list");

  constructor(private readonly fmsService: FmsService) {}
}
