export const composeRelativeUrlMergeQueryParams = (relativePath: string): string => {
  const current = new URL(location.href);
  const target = new URL(location.origin + relativePath);

  const currentSearchParams = new URLSearchParams(current.search);
  const targetSearchParams = new URLSearchParams(target.search);
  const combinedSearchParams = new URLSearchParams();

  currentSearchParams.forEach((value, key) => {
    if (!combinedSearchParams.has(key)) {
      combinedSearchParams.append(key, value);
    }
  });

  targetSearchParams.forEach((value, key) => {
    if (!combinedSearchParams.has(key)) {
      combinedSearchParams.append(key, value);
    }
  });

  return (
    stripQueryParams(relativePath) + (combinedSearchParams.toString() ? "?" + combinedSearchParams.toString() : "")
  );
};

function stripQueryParams(relativePath: string): string {
  return relativePath.replace(/\?.*/, "");
}
