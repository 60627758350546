import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, delay, map, pairwise, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { FADE_TIME, fadeInOut } from "src/app/animations";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { memoize$ } from "src/app/utils/rxjs/select";
import { TICKER_INTERVAL, tickerPipe } from "src/app/utils/rxjs/ticker";
import { TooltipIcon } from "../tips/tip-icon/tip-icon.component";

@Component({
  selector: "app-amount-card",
  templateUrl: "./amount-card.component.html",
  styleUrls: ["./amount-card.component.scss"],
  animations: [fadeInOut],
})
export class AmountCardComponent implements OnInit, OnDestroy {
  @Input()
  public amount$!: Observable<number>;
  @Input()
  public title!: string;
  @Input()
  public tooltipText!: string;
  @Input()
  public enableDifferencePercentage = false;
  @Input()
  public extraInformationTooltipText?: string;

  public questionCircleIcon = TooltipIcon.QuestionCircle;

  public readonly Math = Math;

  public displayValue$!: Observable<number>;
  public loadedAmount$!: Observable<number>;
  public showPercentageDiff$!: Observable<boolean>;
  public percentDisplayValue$!: Observable<number>;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public ngOnInit(): void {
    // amounts that are piped through when data is actually finished loading
    this.loadedAmount$ = memoize$(
      this.amount$.pipe(this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe()),
    );

    // the value that should be displayed in the UI
    this.displayValue$ = memoize$(this.loadedAmount$.pipe(tickerPipe(), takeUntil(this.destroy$)));

    if (this.enableDifferencePercentage) {
      const percentValue$ = memoize$(this.loadedAmount$.pipe(pairwise(), map(calculatePercentageDiff)));

      /**
       * Return false immediately when the percent value changes,
       * wait for the display value to complete animation/counting,
       * then return true (unless 0) to display the new percent value
       */
      this.showPercentageDiff$ = memoize$(
        percentValue$.pipe(
          switchMap((percentValue) =>
            this.displayValue$.pipe(
              debounceTime(TICKER_INTERVAL),
              map(() => percentValue !== 0),
              startWith(false),
            ),
          ),
        ),
      );

      /**
       * Wait for the current percent value to be hidden,
       * then update to the new percent value
       */
      this.percentDisplayValue$ = memoize$(
        this.showPercentageDiff$.pipe(
          delay(FADE_TIME),
          switchMap(() => percentValue$.pipe(take(1))),
        ),
      );
    }
  }
}

function calculatePercentageDiff([before, after]: [number, number]): number {
  if (before === 0) {
    return 0;
  }

  return ((after - before) / before) * 100;
}
