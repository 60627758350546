<div *ngIf="hasBalance$ | async">
  <div class="hint">
    {{ "engagement.label.balance" | fms }}
  </div>

  <div class="paragraph-long-bold">
    {{ balance$ | async | currencyFormatterPipe: "end" }}
  </div>
</div>

<div *ngIf="hasTotalReturn$ | async">
  <div class="hint">
    {{ totalReturnLabel$ | async }}
  </div>

  <div class="paragraph-long-bold">
    {{ formattedTotalReturn$ | async }}
  </div>
</div>
