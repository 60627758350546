import { Injectable, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FmsService } from "src/app/services/fms.service";
import { Nullable } from "src/app/utils/utils";
import { ActionBenefitsComponent } from "../components/action-benefits/action-benefits.component";
import { ActionEpkChangeDurationComponent } from "../components/action-epk-change-duration/action-epk-change-duration.component";
import { ActionEpkMoveToStorebrandComponent } from "../components/action-epk-move-to-storebrand/action-epk-move-to-storebrand.component";
import { ActionExternalPublicPensionComponent } from "../components/action-external-public-pension/action-external-public-pension.component";
import { ActionExtrapensionComponent } from "../components/action-extrapension/action-extrapension.component";
import { ActionHoldingsComponent } from "../components/action-holdings/action-holdings.component";
import { ActionInvestmentOptionsComponent } from "../components/action-investment-options/action-investment-options.component";
import { ActionIpsComponent } from "../components/action-ips/action-ips.component";
import { ActionJustifyAttorneyComponent } from "../components/action-justify-attorney/action-justify-attorney.component";
import { ActionJustifyTestamentComponent } from "../components/action-justify-testament/action-justify-testament.component";
import { ActionMovePkbToStorebrandComponent } from "../components/action-move-pkb-to-storebrand/action-move-pkb-to-storebrand.component";
import { ActionSavingsAdvisorComponent } from "../components/action-savings-advisor/action-savings-advisor.component";
import { ActionSmartAccountComponent } from "../components/action-smart-account/action-smart-account.component";
import { ActionWithdrawalOrderPageComponent } from "../components/action-withdrawal-order-page/action-withdrawal-order-page.component";
import { ActionWithdrawalOrderComponent } from "../components/action-withdrawal-order/action-withdrawal-order.component";
import { Action, ActionComponent } from "../components/actions/actions.component";

export type ActionComponentClass = new (...args: any[]) => ActionComponent;

export enum ActionKey {
  Advisor = "advisor",
  Benefits = "benefits",
  EpkChangeDuration = "epkChangeDuration",
  EpkMoveToStorebrand = "epkMoveToStorebrand",
  ExternalPublicPension = "externalPublicPension",
  ExtraPension = "extraPension",
  Holdings = "holdings",
  InvestmentOptions = "investmentOptions",
  Ips = "ips",
  JustifyAttorney = "justifyAttorney",
  JustifyTestament = "justifyTestament",
  MovePkbToStorebrand = "movePkbToStorebrand",
  RecommendedWithdrawal = "recommendedWithdrawal",
  SavingsAdvisor = "savingsAdvisor",
  SmartAccount = "smartAccount",
  WithdrawalOrderPage = "withdrawalOrderPage",
}

export const ACTION_COMPONENTS = new InjectionToken<Map<ActionKey, ActionComponentClass>>("Action components map", {
  factory: (): Map<ActionKey, ActionComponentClass> =>
    new Map<ActionKey, ActionComponentClass>([
      [ActionKey.Benefits, ActionBenefitsComponent],
      [ActionKey.EpkChangeDuration, ActionEpkChangeDurationComponent],
      [ActionKey.EpkMoveToStorebrand, ActionEpkMoveToStorebrandComponent],
      [ActionKey.ExternalPublicPension, ActionExternalPublicPensionComponent],
      [ActionKey.ExtraPension, ActionExtrapensionComponent],
      [ActionKey.Holdings, ActionHoldingsComponent],
      [ActionKey.InvestmentOptions, ActionInvestmentOptionsComponent],
      [ActionKey.Ips, ActionIpsComponent],
      [ActionKey.JustifyAttorney, ActionJustifyAttorneyComponent],
      [ActionKey.JustifyTestament, ActionJustifyTestamentComponent],
      [ActionKey.MovePkbToStorebrand, ActionMovePkbToStorebrandComponent],
      [ActionKey.RecommendedWithdrawal, ActionWithdrawalOrderComponent],
      [ActionKey.SavingsAdvisor, ActionSavingsAdvisorComponent],
      [ActionKey.SmartAccount, ActionSmartAccountComponent],
      [ActionKey.WithdrawalOrderPage, ActionWithdrawalOrderPageComponent],
    ]),
});

@Injectable({
  providedIn: "root",
})
export class ActionsService {
  constructor(private readonly fmsService: FmsService) {}

  public getActionsFromFms(): Observable<Action[]> {
    return this.fmsService
      .translateAsync<Action[]>("actions.list")
      .pipe(map((actions) => actions.filter(({ enabled }) => enabled).sort(byAscendingOrder)));
  }

  public getActionFromFmsByKey(key: ActionKey): Observable<Nullable<Action>> {
    return this.getActionsFromFms().pipe(map((actions) => actions.find((action) => action.key === key)));
  }
}

function byAscendingOrder<T extends { order: number }>(a: T, b: T): number {
  return a.order - b.order;
}
