import { map, Observable } from "rxjs";
import { MonthlyDeposit, YearlyDeposit } from "src/app/models/engagements/contract-interfaces";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { toSum } from "src/app/utils/array";
import { Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

enum SavingsAgreementStatus {
  Active = "O",
  NotSigned = "V",
}

export type LinkContractAbstract = Graph.LinkContractAbstract & {
  __typename?: string;
  keyValues?: Nullable<Graph.KeyValues>;
};

export abstract class AbstractLinkEngagement<TContract extends Graph.LinkContractAbstract = LinkContractAbstract>
  extends SavingsAndPensionEngagement<TContract>
  implements MonthlyDeposit, YearlyDeposit
{
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }

  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract).pipe(
      map((defaultName) => ({
        ...defaultName,
        name: this.contract.customerSuppliedContractName ?? defaultName.name,
      })),
    );
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference ?? null;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.contractNumber ?? null;
  }

  public getAllocationCode(): Nullable<string> {
    return this.contract?.allocationCode;
  }

  public isSavingsEngagement(): boolean {
    return true;
  }

  public isActive(): boolean {
    return this.getActiveSavingsAgreements().length > 0;
  }

  public hasChangeableProfile(): boolean {
    return true;
  }

  public getMonthlySavingsDeposit(): number {
    return (
      this.getActiveSavingsAgreements()
        .map((agreement) => agreement?.periodicAmount ?? 0)
        .reduce(toSum, 0) ?? 0
    );
  }

  public getYearlySavingsDeposit(): number {
    return (
      this.getActiveSavingsAgreements()
        .map((agreement) => agreement?.yearlyAmount ?? 0)
        .reduce(toSum, 0) ?? 0
    );
  }

  public getActiveSavingsAgreements(): Graph.SavingsAgreement[] {
    const savingsAgreements = this.contract?.savingsAgreements ?? [];
    return savingsAgreements.filter((agreement) => agreement?.status === SavingsAgreementStatus.Active);
  }
}

export class EkstrapensjonEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class FondskontoLinkEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class GarantiEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class IpaLinkEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class IpoEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class IpsEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class LivrenteLinkEngagement extends AbstractLinkEngagement<Graph.LinkContract> {}
export class EkstrapensjonEmploymentEngagement extends AbstractLinkEngagement<Graph.EkstrapensjonEmploymentContract> {
  public getMonthlySavingsDeposit(): number {
    const monthlyUserDeposit = super.getMonthlySavingsDeposit();
    const monthlyEmployerDeposit = this.getYearlyEmployerDeposit() / 12;

    return monthlyUserDeposit + monthlyEmployerDeposit;
  }

  public getYearlySavingsDeposit(): number {
    return super.getYearlySavingsDeposit() + this.getYearlyEmployerDeposit();
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  private getYearlyEmployerDeposit(): number {
    return this.contract?.employerAnnualSavingsAmount ?? 0;
  }
}
