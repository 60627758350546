import { Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type Contract =
  | Graph.HybridMedInvesteringsvalgContract
  | Graph.HybridMedGarantiContract
  | Graph.HybridPensjonsbevisContract;

export abstract class AbstractHybridEngagement<
  TContract extends Contract,
> extends SavingsAndPensionEngagement<TContract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }

  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract);
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.contractNumber;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public isActive(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return false;
  }
}

export class HybridMedInvesteringsvalgEngagement extends AbstractHybridEngagement<Graph.HybridMedInvesteringsvalgContract> {
  public getAllocationCode(): Nullable<string> {
    return this.contract?.allocationCode;
  }
}
export class HybridMedGarantiEngagement extends AbstractHybridEngagement<Graph.HybridMedGarantiContract> {}
export class HybridPensjonsbevisEngagement extends AbstractHybridEngagement<Graph.HybridPensjonsbevisContract> {}
