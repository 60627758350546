<ng-container *ngIf="ipsAction$ | async as ipsAction" [ngSwitch]="ipsAction">
  <app-action-card
    *ngSwitchCase="IpsAction.StartIps"
    [title]="'actions.ips.start.title' | fms"
    [content]="'actions.ips.start.content' | fms"
    [icon]="'actions.ips.start.icon' | fms"
    [primaryButton]="'actions.ips.start.primaryButton' | fms"
    [secondaryButton]="'actions.ips.start.secondaryButton' | fms"
    (primaryButtonClick)="openStartIpsApp()"
    (secondaryButtonClick)="readMoreDialog()"
    [action]="action"
  ></app-action-card>

  <app-action-card
    *ngSwitchCase="IpsAction.IncreaseIps"
    [title]="'actions.ips.increase.title' | fms"
    [content]="
      'actions.ips.increase.content'
        | fms
          : {
              args: {
                totalDeposit: depositThisYear$ | async | currencyFormatterPipe
              }
            }
    "
    [icon]="'actions.ips.increase.icon' | fms"
    [outlinedPrimaryButton]="false"
    [primaryButton]="'actions.ips.increase.primaryButton' | fms"
    [secondaryButton]="'actions.ips.increase.secondaryButton' | fms"
    (primaryButtonClick)="openMonthlySavingsApp()"
    (secondaryButtonClick)="openLumpSumApp()"
    [action]="action"
  ></app-action-card>
</ng-container>
