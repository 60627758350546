<stb-card-medium
  *ngIf="promoCard && promoCard.showMessage"
  [text]="promoCard.text"
  [fluid]="true"
  [iconPath]="promoCard.illustration"
  [trackId]="trackId"
  [class]="promoCard.backgroundColor + ' stb-mb3'"
  (click)="cardClick.emit(promoCard.navigation)"
>
</stb-card-medium>
