import { Injectable } from "@angular/core";
import { catchError, first, from, isObservable, map, mergeMap, Observable, of, throwIfEmpty, toArray } from "rxjs";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import {
  EngagementCategory,
  getEngagementCategory,
  isEngagementFromEmployment,
  isEngagementFromPublic,
  isEngagementFromSavings,
} from "src/app/utils/engagement.utils";
import { concatReduce } from "src/app/utils/rxjs/pipes";
import { booleanSort } from "src/app/utils/utils";

type EngagementSorter = (anyEngagements: AnyEngagement[]) => Observable<AnyEngagement[]> | AnyEngagement[];

@Injectable({
  providedIn: "root",
})
export class EngagementsSorterService {
  public sort = makeEngagementsSorter([
    sortEngagementsByDescendingContractNumber,
    sortEngagementsAlphabetically,
    sortEngagementsByCurrentEngagement,
    this.sortEngagementsByCategory.bind(this),
  ]);

  constructor(private readonly profileService: ProfileService) {}

  private sortEngagementsByCategory(engagements: AnyEngagement[]): AnyEngagement[] {
    const savingsEngagements = engagements.filter(isEngagementFromSavings);
    const employmentEngagements = engagements.filter((engagement) =>
      isEngagementFromEmployment(engagement, this.profileService.hasAfp),
    );
    const publicEngagements = engagements.filter(isEngagementFromPublic);
    const unknownEngagements = engagements.filter(
      (engagement) => getEngagementCategory(engagement, this.profileService.hasAfp) === EngagementCategory.Unknown,
    );

    return [...savingsEngagements, ...employmentEngagements, ...publicEngagements, ...unknownEngagements];
  }
}

function sortEngagementsAlphabetically(engagements: AnyEngagement[]): Observable<AnyEngagement[]> {
  return from(engagements).pipe(
    mergeMap((engagement) => engagement.getNameAsync().pipe(map(({ name }) => ({ name, engagement })))),
    toArray(),
    map((names) => [...names].sort((a, b) => a.name.localeCompare(b.name))),
    map((names) => names.map(({ engagement }) => engagement)),
  );
}

function sortEngagementsByCurrentEngagement(engagements: AnyEngagement[]): AnyEngagement[] {
  return [...engagements].sort(booleanSort((engagement) => engagement.isActive()));
}

function sortEngagementsByDescendingContractNumber(engagements: AnyEngagement[]): AnyEngagement[] {
  return [...engagements].sort((a, b) => ((a.getContractNumber() ?? 0) < (b.getContractNumber() ?? 0) ? 1 : -1));
}

const makeEngagementsSorter =
  (sorters: EngagementSorter[]) =>
  (anyEngagements: AnyEngagement[]): Observable<AnyEngagement[]> => {
    return from(sorters).pipe(
      throwIfEmpty(() => new Error("No engagements sorters provided!")),
      concatReduce((acc, sorter) => {
        const maybeObservable$ = sorter(acc);
        const observable$ = isObservable(maybeObservable$) ? maybeObservable$ : of(maybeObservable$);

        return observable$.pipe(
          first(),
          catchError(() => of(acc)),
        );
      }, anyEngagements),
      catchError(() => of(anyEngagements)),
    );
  };
