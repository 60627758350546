import { Directive, forwardRef, HostListener, Input, Provider } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ControlValueDirective } from "src/app/modules/shared/directives/control-value.directive";
import { Monitoring } from "src/app/utils/monitoring";
import {
  FormatCurrencyStyle,
  formatCurrencyValue,
  getIsNullable,
  Nullable,
  sanitizeCurrency,
} from "src/app/utils/utils";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

export enum MaskMode {
  AllowEmpty,
}

const CURRENCY_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  /* eslint-disable @typescript-eslint/no-use-before-define */
  useExisting: forwardRef(() => CurrencyMaskDirective),
  multi: true,
};

type Currency = Nullable<string | number>;

@Directive({
  selector: "input[appCurrencyMask]",
  providers: [CURRENCY_CONTROL_VALUE_ACCESSOR],
})
export class CurrencyMaskDirective
  extends ControlValueDirective<HTMLInputElement, Currency>
  implements ControlValueAccessor
{
  @Input()
  public readonly appCurrencyMask!: MaskMode | "";
  @Input()
  public digitCap = 8;

  @HostListener("input", ["$event"])
  public onInput(event: InputEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const sanitizedValue = this.sanitizeValue(value);

    const isLargerThanDigitCap = (sanitizedValue?.toString().length ?? 0) > this.digitCap;

    const nextValue = isLargerThanDigitCap ? sanitizedValue?.toString().substring(0, this.digitCap) : sanitizedValue;

    this.writeValue(nextValue);
    this.onChange?.(nextValue);
  }

  public writeValue(value: Currency): void {
    if (getIsNullable(value) || typeof value === "string" || typeof value === "number") {
      this.writeToView(this.sanitizeValue(value));
      return;
    }
    Monitoring.error(
      new Error(`Expected string or number in currency-mask, got ${typeof value}, value: ${JSON.stringify(value)}`),
      { ignore: isStageLocalhost() },
    );
  }

  protected writeToView(value: Currency): void {
    const formattedValue = formatCurrencyValue(
      value,
      this.appCurrencyMask === MaskMode.AllowEmpty ? FormatCurrencyStyle.Nullable : FormatCurrencyStyle.Input,
    );

    const inputEl = this.elementRef.nativeElement;
    this.renderer.setProperty(inputEl, "value", formattedValue);
  }

  protected sanitizeValue(value: Currency): number | undefined {
    const normalizedValue = getIsNullable(value) ? "" : value;
    const sanitizedValue = typeof normalizedValue === "string" ? sanitizeCurrency(normalizedValue) : normalizedValue;

    return Number.isFinite(sanitizedValue) || this.appCurrencyMask === MaskMode.AllowEmpty ? sanitizedValue : undefined;
  }
}
