import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, finalize, share, tap } from "rxjs/operators";
import { Log } from "src/app/utils/log";
import { isStageLocalhost, isStageT } from "src/app/utils/storebrand-staging";

export enum Signal {
  ChunkLoadError = "ChunkLoadError",
  SteppableModalShowActions = "SteppableModalShowActions",
  SteppableModalHasChange = "SteppableModalHasChange",
  SteppableModalTriggerNext = "SteppableModalTriggerNext",
}

export interface Dispatch {
  signal: Signal;
  sourceName: string;
  payload?: any;
}

enum ActionType {
  Dispatch = "DISPATCH",
  Subscription = "SUBSCRIPTION",
  Unsubscribe = "UNSUBSCRIBE",
  Reaction = "REACTION",
}

const defaultStyle = `color: ${Log.Color.Default}; font-weight: bold;`;
const subscriberStyleDef = `color: ${Log.Color.NiceBlue}; font-weight: bold;`;
const signalStyleDef = `color: ${Log.Color.NiceRed}; font-weight: bold;`;

const getBoxColored = (bgColor: string): string => `background-color:${bgColor}; color:white; font-weight:bold;`;

@Injectable({
  providedIn: "root",
})
export class DispatcherService {
  private readonly _bus$: Subject<Dispatch>;
  private readonly bus$: Observable<Dispatch>;
  private logging = true;
  private readonly logTypes: Signal[] = [];
  private readonly logSources: string[] = [];

  constructor() {
    this._bus$ = new Subject();
    this.bus$ = this._bus$.pipe(share());

    this._bus$.subscribe(({ signal, sourceName, payload }) =>
      this.log(ActionType.Dispatch, signal, sourceName, payload),
    );

    if (!(isStageLocalhost() || isStageT())) {
      this.log = (): void => {
        // noop
      };
    }
  }

  public toggleLogging(): boolean {
    this.logging = !this.logging;
    return this.logging;
  }

  public dispatch(signal: Signal, sourceName: string, payload?: any): void {
    this._bus$.next({ signal, sourceName, payload });
  }

  public subscribeTo$(subscribeTo: Signal, subscriberName: string): Observable<Dispatch> {
    this.log(ActionType.Subscription, subscribeTo, subscriberName);
    return this.bus$.pipe(
      filter(({ signal }) => signal === subscribeTo),
      tap(({ signal, payload }) => {
        if (this.logging) {
          this.log(ActionType.Reaction, signal, subscriberName, payload);
        }
      }),
      finalize(() => this.log(ActionType.Unsubscribe, subscribeTo, subscriberName)),
    );
  }

  public log(actionType: ActionType, signal: Signal, subscriberName: string, payload?: any): void {
    if (!this.logging) {
      return;
    }
    if (this.logSources.length > 1 && !this.logSources.includes(subscriberName)) {
      return;
    }
    if (this.logTypes.length > 1 && !this.logTypes.includes(signal)) {
      return;
    }

    const { actionStyleDef, arrow } = this.getStyle(actionType);

    Log.info(
      `%c ${actionType} %c %c${subscriberName} %c${arrow} %c${signal}`,
      actionStyleDef,
      defaultStyle,
      subscriberStyleDef,
      defaultStyle,
      signalStyleDef,
    );
    if (payload) {
      Log.info("Payload:", payload);
    }
  }

  private getStyle(actionType: ActionType): {
    actionStyleDef: string;
    arrow: string;
  } {
    switch (actionType) {
      case ActionType.Dispatch:
        return {
          arrow: "⇒",
          actionStyleDef: getBoxColored(Log.Color.NiceRed),
        };
      case ActionType.Subscription:
        return {
          arrow: "⇐",
          actionStyleDef: getBoxColored(Log.Color.NiceBlue),
        };
      case ActionType.Unsubscribe:
        return {
          arrow: "⇍",
          actionStyleDef: getBoxColored(Log.Color.NiceYellow),
        };
      case ActionType.Reaction:
        return {
          arrow: "⇐",
          actionStyleDef: getBoxColored(Log.Color.NicePurple),
        };
      default:
        return {
          arrow: "?",
          actionStyleDef: getBoxColored(Log.Color.AngryRed),
        };
    }
  }
}
