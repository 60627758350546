import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";

@Component({
  selector: "app-cta-card",
  templateUrl: "./cta-card.component.html",
  styleUrls: ["./cta-card.component.scss"],
})
export class CtaCardComponent {
  @Input()
  public readonly title!: string;
  @Input()
  public readonly description!: string;
  @Input()
  public readonly buttonText!: string;
  @Input()
  public readonly dataTrackId?: string;
  @Input()
  public readonly buttonIcon?: string;
  @HostBinding("class")
  @Input()
  public type: "primary" | "accent" = "primary";
  @Output()
  public readonly buttonClick = new EventEmitter();
}
