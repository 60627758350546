import { combineLatest, first, map, Observable } from "rxjs";
import { AccountProductCodeRegex } from "src/app/constants/business.constants";
import { getAppInjector } from "src/app/injector";
import { EngagementName } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { FmsService } from "src/app/services/fms.service";
import { select } from "src/app/utils/rxjs/select";
import { Nullable } from "src/app/utils/utils";
import { GraphEngagement } from "./graph-engagement.model";
import { getPayoutPlanDuration, PAYOUT_DURATIONS, PayoutDuration } from "./generic-engagement.model";

export class BankEngagement extends GraphEngagement<Graph.BankAccount, Graph.BankOpenPrognosisInput> {
  public override getContracts(): unknown[] {
    return [];
  }

  public override getPayoutDuration(): PayoutDuration {
    if (this.isLifelongPayout()) {
      return PAYOUT_DURATIONS.infinite;
    }
    return getPayoutPlanDuration(this.getPayoutPlan());
  }

  public override isActive(): boolean {
    return false;
  }
  public override isLifelongPayout(): boolean {
    return !!this?.prognosis?.lifeLong;
  }

  /** @deprecated Use getNameAsync instead */
  public override getName(): EngagementName {
    return select(this.getNameAsync());
  }

  public override getNameAsync(): Observable<EngagementName> {
    const fmsService = getAppInjector().get(FmsService);

    if (isSmartAccount(this.contract)) {
      return combineLatest([
        fmsService.translateAsync<string>("BankEngagement.smartAccount.description"),
        fmsService.translateAsync<string>("GenericEngagement.supplier"),
      ]).pipe(
        first(),
        map(([description, payer]) => ({
          name: this.contract.productFriendlyName ?? "",
          description,
          payer,
          supplier: payer,
        })),
      );
    }

    return combineLatest([
      fmsService.translateAsync<string>("sharedProductNames.savings_account"),
      fmsService.translateAsync<string>("sharedProductNames.savings_account.description", {
        logEmpty: false,
      }),
    ]).pipe(
      first(),
      map(([name, description]) => ({
        name,
        description,
        payer: this.contract.productFriendlyName,
        supplier: this.contract.accountNumber ?? undefined,
      })),
    );
  }

  public override getBalance(): Nullable<number> {
    return this.contract.bookBalance;
  }

  public override getContractNumber(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.contract.accountNumber!;
  }

  public override getIdentifier(): string {
    return this.getContractNumber();
  }

  public override isSavingsEngagement(): boolean {
    return true;
  }

  public override hasChangeableProfile(): boolean {
    return false;
  }
}

export function isSmartAccount(data: Graph.BankAccount): boolean {
  return !!data.productCode?.match(AccountProductCodeRegex.SmartAccount);
}

export function isBankAccount(data: Graph.BankAccount): data is Graph.BankAccount {
  return (
    !!data?.productFriendlyName && !!data?.bankAgreementStatus && !!data?.bankAgreementType && data?.bookBalance != null
  );
}
