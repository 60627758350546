<ng-container *appLet="engagementData.periodicDeposit > 0 as hasPeriodicDeposit" [ngSwitch]="engagementData.type">
  <button mat-dialog-close>
    <i>close</i>
  </button>

  <h3 mat-dialog-title class="title">
    <ng-container *ngSwitchCase="SavingsType.Bank">
      {{
        hasPeriodicDeposit
          ? ("ExternalSavingsEngagement.bank.periodic" | fms)
          : ("ExternalSavingsEngagement.bank.single" | fms)
      }}
    </ng-container>
    <ng-container *ngSwitchCase="SavingsType.Fond">
      {{
        hasPeriodicDeposit
          ? ("ExternalSavingsEngagement.fond.periodic" | fms)
          : ("ExternalSavingsEngagement.fond.single" | fms)
      }}
    </ng-container>
    {{ "confirmationStep.added.ending" | fms }}
  </h3>

  <mat-dialog-content *appLet="engagementData.balance > 0 as hasBalance">
    <div class="content-added-wrapper">
      <div class="content-added">
        <i class="check-icon-tablet">check-circle</i>

        <ng-container *ngIf="hasBalance; else onlyPeriodicDeposit">
          <h3 class="large-numbers">
            {{ engagementData.balance | currencyFormatterPipe : "end" }}
          </h3>

          <h4
            *ngIf="hasPeriodicDeposit"
            class="periodic-deposit-addition"
            [innerHtml]="
              'confirmationStep.added.periodicDepositAddition'
                | fms
                  : {
                      args: {
                        periodicDeposit: engagementData.periodicDeposit | currencyFormatterPipe : 'end'
                      }
                    }
            "
          ></h4>

          <h4>
            <ng-container *ngSwitchCase="SavingsType.Bank">{{ "confirmationStep.added.bank" | fms }}</ng-container>
            <ng-container *ngSwitchCase="SavingsType.Fond">{{ "confirmationStep.added.fond" | fms }}</ng-container>
          </h4>
        </ng-container>

        <ng-template #onlyPeriodicDeposit>
          <h3 class="large-numbers">
            {{ engagementData.periodicDeposit | currencyFormatterPipe : "end" }}
          </h3>

          <h4>{{ "confirmationStep.added.periodicDeposit" | fms }}</h4>
        </ng-template>
      </div>

      <div class="content-added-arrow"></div>
    </div>

    <div
      class="content-prognosis"
      *ngIf="injectedEngagementBalance$ | async | currencyFormatterPipe : 'end' as formattedPrognosisBalance"
    >
      <img class="prognosis-icon" [src]="'confirmationStep.content.prognosis.iconSrc' | fms" alt />

      <h3 class="large-numbers">
        {{ formattedPrognosisBalance }}
      </h3>

      <h4 class="payout-age-with-tip" *ngIf="startPayoutAge$ | async as startPayoutAge">
        {{
          "confirmationStep.content.prognosis.estimatedValue.text" | fms : { args: { startPayoutAge: startPayoutAge } }
        }}

        <app-tip-icon
          [tooltipText]="
            'confirmationStep.content.prognosis.estimatedValue.tooltip'
              | fms
                : {
                    args: {
                      prognosisBalance: formattedPrognosisBalance,
                      startPayoutAge: startPayoutAge
                    }
                  }
          "
        ></app-tip-icon>
      </h4>
    </div>

    <ng-container [ngTemplateOutlet]="percentage"></ng-container>
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions>
  <button stbButton="contained" type="button" mat-dialog-close data-trackid="confirmationStep-closeButton">
    {{ "confirmationStep.closeButton.text" | fms }}
  </button>

  <ng-container [ngTemplateOutlet]="percentage"></ng-container>
</mat-dialog-actions>

<ng-template #percentage>
  <app-percentage-total-pension-change
    [divisorEngagements$]="getInjectedEngagement()"
  ></app-percentage-total-pension-change
></ng-template>
