<div class="content" *appLet="buttons$ | async as buttons">
  <div class="progession-row" *appLet="readyToNavigateDebouncer$ | async as readyToNavigate">
    <div class="actions" *appLet="isTablet$ | async as isTablet">
      @if (buttons.previous) {
        @if (buttons?.previous?.visible) {
          @if (buttons.previous.onClick) {
            <button
              (click)="buttons.previous.onClick()"
              stbButton="outlined"
              class="button button-previous"
              data-trackid="pensionplanNavigation-back"
            >
              <ng-container [ngTemplateOutlet]="previous"></ng-container>
            </button>
          } @else {
            @if (buttons.previous.link) {
              <button
                [routerLink]="readyToNavigate ? buttons.previous.link : null"
                queryParamsHandling="merge"
                stbButton="outlined"
                role="link"
                class="button button-previous"
                data-trackid="pensionplanNavigation-back"
              >
                <ng-container [ngTemplateOutlet]="previous"></ng-container>
              </button>
            }
          }
        } @else {
          <div class="button-placeholder"></div>
        }
      }
      <ng-template #previous>
        @if (isTablet) {
          <i>arrow-left</i>
        } @else {
          {{ buttons.previous.text | fms }}
        }
      </ng-template>

      @if (buttons?.next?.visible) {
        @if (buttons.next.onClick) {
          <button
            (click)="buttons.next.onClick()"
            stbButton="contained"
            class="button button-next"
            data-trackid="pensionplanNavigation-next"
          >
            {{ buttons.next.text | fms }}
          </button>
        } @else {
          @if (buttons.next.link) {
            <button
              [routerLink]="readyToNavigate ? buttons.next.link : null"
              queryParamsHandling="merge"
              stbButton="contained"
              role="link"
              class="button button-next"
              data-trackid="pensionplanNavigation-next"
            >
              {{ buttons.next.text | fms }}
            </button>
          }
        }
      }
    </div>

    <div class="embedded">
      <ng-content></ng-content>
    </div>

    @if (progress) {
      <mat-progress-bar mode="determinate" color="accent" [value]="progress"></mat-progress-bar>
    }
  </div>
</div>
