<div class="afp-warning" *ngIf="showSimulationParameters">
  <img [src]="'afpEngagement.secondInfo.iconSrc' | fms" alt />
  <p [innerHTML]="'afpEngagement.secondInfo.text' | fms"></p>
</div>

<app-contract-type-description
  [engagement]="engagement"
  [showDescription]="showDescription"
></app-contract-type-description>

<p
  *ngIf="!showSimulationParameters"
  class="contract-type-description-link"
  [innerHtml]="'afpEngagement.contractTypeDescriptionLink' | fms"
></p>
