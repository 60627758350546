import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { isEmpty } from "lodash-es";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NavigateToWithdrawalOrderAnchorService } from "src/app/modules/shared/services/navigate-to-withdrawal-order-anchor.service";
import { WithdrawalProfileId } from "src/app/modules/shared/services/withdrawal-profiles/withdrawal-profiles.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { Action, ActionComponent } from "../actions/actions.component";

@Component({
  selector: "app-action-withdrawal-order-page",
  templateUrl: "./action-withdrawal-order-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionWithdrawalOrderPageComponent implements ActionComponent {
  @Input()
  public action!: Action;

  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly navigateToWithdrawalOrderAnchorService: NavigateToWithdrawalOrderAnchorService,
  ) {}

  public getIsVisible(): Observable<boolean> {
    return this.clientDataService.simulationParametersByEngagement$.pipe(map(isEmpty));
  }

  public navigateToWithdrawalOrderPage(): void {
    this.navigateToWithdrawalOrderAnchorService.navigate(WithdrawalProfileId.CustomPayoutAge);
  }
}
