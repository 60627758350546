import { ExternalSavingsEngagementType } from "src/app/models/engagements/external-savings-engagement.model";
import { AnyEngagement } from "src/app/services/engagements.service";
import { isExternalSavingsEngagement } from "src/app/utils/engagement.typeguards";
import { getIsNotNullable } from "src/app/utils/utils";

export const getHasContractNumber = (engagement: AnyEngagement): boolean => {
  return getIsNotNullable(engagement.getContractNumber());
};

export const getIsBalanceAboveZero = (engagement: AnyEngagement): boolean => {
  return (engagement.getBalance() ?? 0) > 0;
};

export const getIsExternalSavingsBankEngagement = (engagement: AnyEngagement): boolean =>
  isExternalSavingsEngagement(engagement) && engagement.getType() === ExternalSavingsEngagementType.Bank;
