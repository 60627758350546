import { Event, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import { AnyObject } from "./object";

export enum GlobalQueryParam {
  "DisplayMode" = "displaymode",
}

export enum DisplayMode {
  App = "app",
}

export function getIsDisplayMode(value: DisplayMode): boolean {
  const key = GlobalQueryParam.DisplayMode;

  return window.location.href.includes(`${key}=${value}`);
}

export const getIsNavigationEnd = (event: Event): event is NavigationEnd => event instanceof NavigationEnd;

export const getIsNavigationCancel = (event: Event): event is NavigationCancel => event instanceof NavigationCancel;

export const getIsNavigationError = (event: Event): event is NavigationError => event instanceof NavigationError;

export const getIsNavigationEndOrCancelOrError = (
  event: Event,
): event is NavigationEnd | NavigationCancel | NavigationError =>
  getIsNavigationEnd(event) || getIsNavigationCancel(event) || getIsNavigationError(event);

export const composePaths = (routes: AnyObject, ...keys: string[]): string =>
  keys.reduce((acc, key) => acc.concat("/", routes[key]), "");
