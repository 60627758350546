/* eslint-disable rxjs/finnish */
import { HttpErrorResponse } from "@angular/common/http";
import { isObservable, Observable, of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { Log } from "src/app/utils/log";
import { getIsNotNullable } from "./utils";

export type Logger = (message?: unknown, ...optionalParams: unknown[]) => void;

export const makeHandleError =
  (logger: Logger) =>
  <T>(source: string, error: HttpErrorResponse, result?: T | Observable<T>): Observable<T> => {
    const actualResult$ = isObservable(result) ? result : of(result);

    return actualResult$.pipe(
      tap(() => {
        if (error?.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          logger(`[${source}] Network error:`, error.error);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          logger(`[${source}]`, error);
        }
      }),
      mergeMap((actualResult) => {
        if (getIsNotNullable(actualResult)) {
          return of(actualResult);
        }

        throw error;
      }),
    );
  };

export const handleError = makeHandleError(Log.error);
