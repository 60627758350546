import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { IPS_CUTOFF_AGE, IPS_MAX_YEARLY_SAVINGS_QUOTA } from "src/app/constants/business.constants";
import { ContractTypeOf } from "src/app/models/engagements/generic-engagement.model";
import { IpsEngagement } from "src/app/models/engagements/savings-and-pension/link-engagement.model";
import { AddLumpSumActionBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/add-lump-sum-action-builder";
import { ChangeMonthlySavingsActionBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/change-monthly-savings-action-builder";
import { ContractDetailsService } from "src/app/modules/shared/services/contract-details.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CustomerService } from "src/app/services/customer.service";
import {
  AnySavingsAndPensionEngagement,
  SavingsAndPensionService,
} from "src/app/services/prognoses-services/savings-and-pension.service";
import { isIpsEngagement } from "src/app/utils/engagement.typeguards";
import { getIsNullable } from "src/app/utils/utils";

export type EngagementWithContractDetails = [IpsEngagement, ContractTypeOf<IpsEngagement>];

export enum IpsAction {
  StartIps = "startIps",
  IncreaseIps = "increaseIps",
  Unqualified = "unqualified",
}

@Injectable()
export class ActionIpsService {
  private readonly override$ = new BehaviorSubject<IpsAction | undefined>(undefined);

  constructor(
    private readonly customerService: CustomerService,
    private readonly savingsAndPensionService: SavingsAndPensionService,
    private readonly contractDetailsService: ContractDetailsService,
    private readonly addLumpSumActionBuilder: AddLumpSumActionBuilder,
    private readonly changeMonthlySavingsActionBuilder: ChangeMonthlySavingsActionBuilder,
  ) {}

  public getFirstEngagement(): Observable<IpsEngagement | undefined> {
    return this.getEngagements().pipe(map((engagements) => engagements.at(0)));
  }

  public getIpsAction(): Observable<IpsAction> {
    return combineLatest([this.customerService.age$, this.getEngagements(), this.override$]).pipe(
      switchMap(([age, engagements, override]) => {
        if (override) {
          return of(override);
        }

        if (!age || age >= IPS_CUTOFF_AGE || engagements.length >= 2) {
          return of(IpsAction.Unqualified);
        }

        const firstEngagment = engagements.at(0);

        if (engagements.length === 0 || getIsNullable(firstEngagment)) {
          return of(IpsAction.StartIps);
        }

        return this.getCanIncreaseDeposit(firstEngagment).pipe(
          map((canIncreaseDeposit) => (canIncreaseDeposit ? IpsAction.IncreaseIps : IpsAction.Unqualified)),
        );
      }),
    );
  }

  public overrideIps(state: IpsAction | undefined): void {
    this.override$.next(state);
  }

  public getDepositThisYear(engagement: IpsEngagement): Observable<number> {
    return this.getFirstEngagementWithContractDetails(engagement).pipe(
      map(([, contractDetails]) => {
        const savingsAgreements = engagement.getActiveSavingsAgreements();
        return savingsAgreements.length === 0
          ? getTotalDepositLastQueryYear(contractDetails)
          : getYearlyDeposits(savingsAgreements);
      }),
    );
  }

  public hasPeriodicAmountAboveMaximum(engagement: IpsEngagement): boolean {
    const savingsAgreements = engagement.getActiveSavingsAgreements();
    const periodicAmount = getSumOfPeriodicAmount(savingsAgreements);
    const IPS_MAX_PERIODIC_AMOUNT = IPS_MAX_YEARLY_SAVINGS_QUOTA / 12;

    return periodicAmount > IPS_MAX_PERIODIC_AMOUNT;
  }

  public async openLumpSumApp(engagement: IpsEngagement): Promise<void> {
    window.open(this.addLumpSumActionBuilder.build(engagement).linkUrl, "_blank");
  }

  public async openMonthlySavingsApp(engagement: IpsEngagement): Promise<void> {
    window.open(this.changeMonthlySavingsActionBuilder.build(engagement).linkUrl, "_blank");
  }

  public isIpsQualified(): Observable<boolean> {
    return this.getIpsAction().pipe(map((action) => action === IpsAction.StartIps));
  }

  private getCanIncreaseDeposit(engagement: IpsEngagement): Observable<boolean> {
    return this.getDepositThisYear(engagement).pipe(map((deposit) => deposit < IPS_MAX_YEARLY_SAVINGS_QUOTA));
  }

  private getEngagements(): Observable<IpsEngagement[]> {
    return this.savingsAndPensionService.engagements$.pipe(map(getIpsEngagements));
  }

  private getFirstEngagementWithContractDetails(engagement: IpsEngagement): Observable<EngagementWithContractDetails> {
    return this.contractDetailsService
      .fetchContractDetails(engagement)
      .pipe(
        map<ContractTypeOf<IpsEngagement>, EngagementWithContractDetails>((contractDetails) => [
          engagement,
          contractDetails,
        ]),
      );
  }
}

function getIpsEngagements(engagements: AnySavingsAndPensionEngagement[]): IpsEngagement[] {
  return engagements.filter(isIpsEngagement);
}

function getYearlyDeposits(savingsAgreements: Graph.SavingsAgreement[]): number {
  return savingsAgreements.reduce((sum, { yearlyAmount }) => sum + (yearlyAmount ?? 0), 0);
}

function getSumOfPeriodicAmount(savingsAgreements: Graph.SavingsAgreement[]): number {
  return savingsAgreements.reduce((sum, { periodicAmount }) => sum + (periodicAmount ?? 0), 0);
}

function getTotalDepositLastQueryYear(contract: ContractTypeOf<IpsEngagement>): number {
  return contract?.keyValues?.totalDepositLastQueryYear?.value ?? 0;
}
