import { HttpHeaders } from "@angular/common/http";
import { ApolloError, ApolloLink, DocumentNode, FetchResult, Operation, Resolver } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SchemaLink } from "@apollo/client/link/schema";
import { addMocksToSchema } from "@graphql-tools/mock";
import { makeExecutableSchema } from "@graphql-tools/schema";
import { firstValueFrom } from "rxjs";
import { HttpHeaderKey } from "src/app/interceptors/correlation.interceptor";
import { EndpointService } from "src/app/services/endpoint.service";
import { Monitoring } from "src/app/utils/monitoring";
import { isGraphQLAccessIssue } from "src/app/modules/graphql-clients/utils/isGraphQLAccessIssue";
import { isRequestedByAdvisor, isStageLocalhost } from "src/app/utils/storebrand-staging";
import { GraphQLError } from "graphql/error";

export interface ResolverMap {
  [name: string]: Resolver;
}

export function createClientIdLink(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    const clientId = "smart-pensjon-web";
    const headers = new HttpHeaders().set(HttpHeaderKey.ClientId, clientId);

    operation.setContext({
      headers,
    });

    return forward(operation);
  });
}

export function createDynamicHttpLink(endpointService: EndpointService): ApolloLink {
  return setContext(async () => {
    const url = await firstValueFrom(endpointService.composeGraphUrl());

    return {
      uri: url,
    };
  });
}

export function createErrorReporterLink(): ApolloLink {
  return onError(({ forward, operation, graphQLErrors, networkError }) => {
    const { operationName, variables } = operation;

    const convertedErrors = graphQLErrors?.map(
      (error) =>
        new GraphQLError(error.message, {
          extensions: error.extensions,
        }),
    );

    const skipError = isGraphQLAccessIssue(convertedErrors) && isRequestedByAdvisor();

    if (!skipError) {
      Monitoring.error(
        new ApolloError({
          graphQLErrors: convertedErrors,
          networkError,
          extraInfo: {
            operationName,
            variables,
          },
        }),
        {
          ignore: isStageLocalhost(),
          tags: {
            correlationId: getCorrelationIdFromOperation(operation),
          },
        },
      );
    }

    return forward(operation);
  });
}

type LoggerFunction = (operation: Operation, fetchResult: FetchResult) => void;

export function createResponseLoggerLink(logger: LoggerFunction): ApolloLink {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((fetchResult) => {
      logger(operation, fetchResult);

      return fetchResult;
    });
  });
}

export function createMockSchemaLinkFromResolverMaps(typeDefs: DocumentNode, resolverMaps: ResolverMap[]): ApolloLink {
  const mocks = combineResolverMaps(resolverMaps);
  const schema = makeExecutableSchema({ typeDefs });
  const schemaWithMocks = addMocksToSchema({
    schema,
    mocks,
  });

  return new SchemaLink({ schema: schemaWithMocks });
}

export function combineApolloLinks(links: ApolloLink[]): ApolloLink {
  const [firstLink, ...restLinks] = links;

  return restLinks.reduce((acc, cv) => acc.concat(cv), firstLink);
}

function getCorrelationIdFromOperation(operation: Operation): string {
  return operation.getContext()?.headers?.get(HttpHeaderKey.CorrelationId);
}

function combineResolverMaps(resolverMaps: ResolverMap[]): ResolverMap {
  return resolverMaps.reduce(
    (acc, resolverMap) => ({
      ...acc,
      ...resolverMap,
    }),
    {},
  );
}
