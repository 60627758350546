import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import { OffentligTjenestepensjonEngagementComponent } from "src/app/modules/shared/components/add-engagement/offentlig-tjenestepensjon-engagement/offentlig-tjenestepensjon-engagement.component";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";
import { getIsNullable, Nullable } from "src/app/utils/utils";

@Component({
  selector: "app-action-external-public-pension",
  templateUrl: "./action-external-public-pension.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionExternalPublicPensionComponent implements ActionComponent {
  public action!: Action;

  constructor(
    private readonly dialog: MatDialog,
    private readonly engagementsService: EngagementsService,
    private readonly clientDataService: ClientDataService,
  ) {}

  public getIsVisible(): Observable<boolean> {
    return combineLatest([
      this.engagementsService.canManuallyAddOffentligTjenestepensjon$,
      this.clientDataService.optOutOffentligTjenestepensjon$,
    ]).pipe(map(applyArrayTo(getIsVisible)));
  }

  public openDialog(): void {
    this.dialog.open(OffentligTjenestepensjonEngagementComponent, {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "large-dialog-padding",
      autoFocus: false,
    });
  }
}

function getIsVisible(isQualified: boolean, optOutDate: Nullable<Date>): boolean {
  return isQualified && getIsNullable(optOutDate);
}
