<app-action-card
  [title]="'actions.holdings.title' | fms"
  [icon]="'actions.holdings.icon' | fms"
  [content]="'actions.holdings.content' | fms"
  [outlinedPrimaryButton]="false"
  [primaryButton]="'actions.holdings.primaryButton' | fms"
  (primaryButtonClick)="goToInvestmentOptionPage()"
  [action]="action"
>
</app-action-card>
