import { ApolloLink, gql, Operation } from "@apollo/client/core";
import { HttpHeaderKey } from "src/app/interceptors/correlation.interceptor";
import { Nullable } from "src/app/utils/utils";

export interface ResponseHeaders {
  __typename: "Headers";
  correlationId: Nullable<string>;
}

export const responseHeadersTypeDefs = gql`
  type Headers {
    correlationId: String
  }
`;

export function createAddResponseHeadersLink(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((fetchResult) => {
      const headers: ResponseHeaders = {
        __typename: "Headers",
        correlationId: getCorrelationIdFromResponseHeaders(operation),
      };
      const originalData = fetchResult?.data ?? {};

      return {
        ...fetchResult,
        data: {
          ...originalData,
          headers,
        },
      };
    });
  });
}

function getCorrelationIdFromResponseHeaders(operation: Operation): Nullable<string> {
  const context = operation.getContext();
  return context?.response?.headers?.get(HttpHeaderKey.CorrelationId);
}
