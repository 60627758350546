<ng-container *appLet="hasAnnualGrossIncome$ | async as hasSalary">
  <p class="bold">
    {{ "annualIncomeChangeLink.title" | fms }}
  </p>
  <p
    *ngIf="hasSalary"
    [innerHTML]="'annualIncomeChangeLink.htmlText' | fms: { args: { annualGrossIncome: annualGrossIncome$ | async } }"
  ></p>

  <stb-inline-alert
    *ngIf="!hasSalary"
    width="fluid"
    type="warning"
    [innerHTML]="'annualIncomeChangeLink.missingSalary.htmlText' | fms"
  ></stb-inline-alert>

  <div class="buttons-wrapper">
    <button
      stbButton="text"
      [attr.data-trackid]="hasSalary ? 'annualIncomeChangeLink-changeSalary' : 'annualIncomeChangeLink-addSalary'"
      (click)="openSalaryOnboarding()"
      *ngIf="(isNotPublicContext$ | async) && isNotMinePengerPage"
    >
      {{
        hasSalary ? ("annualIncomeChangeLink.linkText" | fms) : ("annualIncomeChangeLink.missingSalary.linkText" | fms)
      }}
    </button>

    <ng-content></ng-content>
  </div>
</ng-container>
