import { Injectable } from "@angular/core";
import { TrackIdTransformer } from "src/app/modules/shared/directives/track-id/track-id.directive";
import { ButtonTrackIdTransformer } from "src/app/modules/shared/directives/track-id/button-track-id.transformer";
import { LinkTrackIdTransformer } from "src/app/modules/shared/directives/track-id/link-track-id.transformer";

@Injectable()
export class RoleTrackIdTransformer implements TrackIdTransformer {
  constructor(
    private readonly buttonTrackIdTransformer: ButtonTrackIdTransformer,
    private readonly linkTrackIdTransformer: LinkTrackIdTransformer,
  ) {}

  public predicate(element: HTMLElement): boolean {
    return !!this.getTransformer(element);
  }

  public transform(trackId: string, element: HTMLElement): string {
    return this.getTransformer(element)?.transform(trackId, element) ?? trackId;
  }

  private getTransformer(element: HTMLElement): TrackIdTransformer | undefined {
    const role = element.getAttribute("role") ?? "";

    switch (role) {
      case "button":
        return this.buttonTrackIdTransformer;
      case "link":
        return this.linkTrackIdTransformer;
      default:
        return undefined;
    }
  }
}
