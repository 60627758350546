<div class="cards-wrapper">
  <app-dashboard-card
    [dashboardCard]="'dashboard.card.contracts' | fms"
    trackId="dashboard-card-contracts"
    (cardClick)="navigateToUrlWithOnlyConsentOnboarding($event)"
  ></app-dashboard-card>

  <app-dashboard-card
    [dashboardCard]="'dashboard.card.pensionPlan' | fms"
    trackId="dashboard-card-pensionPlan"
    (cardClick)="openPensionPlanLink($event)"
  ></app-dashboard-card>

  <app-dashboard-card
    [dashboardCard]="'dashboard.card.savingForPension' | fms"
    trackId="dashboard-card-savingForPension"
    (cardClick)="onCardClick($event)"
  ></app-dashboard-card>

  @if (noEpkOrEpksInStb$ | async) {
    <app-dashboard-card
      [dashboardCard]="'dashboard.card.moveContracts' | fms"
      trackId="dashboard-card-moveContracts"
      (cardClick)="onCardClick($event)"
    ></app-dashboard-card>
  }

  @if (showActions$ | async) {
    <app-dashboard-card
      [dashboardCard]="'dashboard.card.actions' | fms"
      trackId="dashboard-card-actions"
      (cardClick)="navigateWithOnboarding($event)"
    ></app-dashboard-card>
  }

  @if (showWithdrawalWebCard$ | async) {
    <app-dashboard-card
      [dashboardCard]="'dashboard.card.withdrawal' | fms"
      trackId="dashboard-card-withdrawal"
      (cardClick)="onCardClick($event)"
    ></app-dashboard-card>
  }

  @if (showPayoutsWebCard$ | async) {
    <app-dashboard-card
      [dashboardCard]="'dashboard.card.payouts' | fms"
      trackId="dashboard-card-payouts"
      (cardClick)="onCardClick($event)"
    ></app-dashboard-card>
  }

  @if (showIpsLumpSumCard$ | async) {
    <app-dashboard-card
      [dashboardCard]="'dashboard.card.ipsLumpSum' | fms"
      trackId="dashboard-card-ipsLumpSum"
      (cardClick)="openIpsLumpSumApp()"
      class="ribbon"
    ></app-dashboard-card>
  }

  @if (showIpsIsAboveSavingsLimitCard$ | async) {
    <app-dashboard-card
      [dashboardCard]="'dashboard.card.ipsAboveSavingsLimit' | fms"
      trackId="dashboard-card-ipsAboveSavingsLimit"
      (cardClick)="openIpsMonthlySavingsApp()"
    ></app-dashboard-card>
  }
</div>
