<div class="summary-table details-wrapper bottom-border" [class.summary-table-responsive]="responsive">
  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.totalPayout" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ totalPayout$ | async | currencyFormatterPipe: "start" }}
      </ng-container>
    </div>
  </div>

  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.payoutDuration" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ duration$ | async }}
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="hasHoldings$ | async">
    <ng-container>
      <div class="summary-row">
        <div class="summary-label">
          {{ "engagement.label.savingProfileFund" | fms }}
        </div>

        <div class="summary-value" data-hj-suppress *ngIf="holdings$ | async as holdings">
          <a data-trackid="engagementDetail-savingProfileFondLink" (click)="openDialogWithFunds()">
            <ng-container
              [ngTemplateOutlet]="
                (isAnbefaltPensjon$ | async)
                  ? anbefaltPensjon
                  : holdings.length === 1
                    ? singleHolding
                    : multipleHoldings
              "
            >
              <ng-template #anbefaltPensjon>{{
                "engagement.label.savingProfileFund.anbefaltPensjon" | fms
              }}</ng-template>
              <ng-template #singleHolding>
                {{ holdings[0].name }}
              </ng-template>
              <ng-template #multipleHoldings>{{
                "engagement.label.savingProfileFund.multipleHoldings" | fms
              }}</ng-template>
            </ng-container>
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="summary-row" *ngIf="yearlyPremiumAmount$ | async; let premiumAnnualAmount">
    <div class="summary-label">
      {{ "engagement.label.premiumYearlyAmount" | fms }}
    </div>

    <div class="summary-value">
      {{ premiumAnnualAmount | currencyFormatterPipe: "start" }}
    </div>
  </div>

  <div class="summary-row" *ngIf="contractNumber$ | async; let contractNumber">
    <ng-container *ngIf="!(isBank$ | async); else BankLabel">
      <div class="summary-label">
        {{ "engagement.label.contractNumber" | fms }}
      </div>

      <div class="summary-value" data-hj-suppress>
        {{ contractNumber }}
      </div>
    </ng-container>

    <ng-template #BankLabel>
      <div class="summary-label">
        {{ "engagement.label.accountNumber" | fms }}
      </div>

      <div class="summary-value" data-hj-suppress>
        {{ contractNumber | accountNumberFormatter }}
      </div>
    </ng-template>
  </div>

  <app-savings-rate-summary-rows
    *ngIf="equalGraphEngagement$ | async as equalGraphEngagement"
    [engagement]="equalGraphEngagement"
    [responsive]="responsive"
  ></app-savings-rate-summary-rows>
</div>

<div>
  <app-contract-type-description
    [engagement]="engagement$ | async"
    [showPensionPlanEntry]="showPensionPlanEntry"
    [showDescription]="showDescription"
  ></app-contract-type-description>

  <app-payout-simulation-alternatives
    [engagement$]="engagement$"
    *ngIf="showPaymentOptionIfFMIorFripolise$ | async"
  ></app-payout-simulation-alternatives>
</div>

<ng-template #amountUnavailable>
  <app-amount-unavailable></app-amount-unavailable>
</ng-template>
