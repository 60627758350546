import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";

type CurrencyPosition = "start" | "end" | "none";

@Pipe({ name: "currencyFormatterPipe" })
export class CurrencyFormatterPipe implements PipeTransform {
  private readonly currency: string;

  constructor(
    private readonly fms: FmsService,
    private readonly decimalPipe: DecimalPipe,
  ) {
    this.currency = this.fms.instant("pipe.currencyformatter");
  }

  transform<T>(value: string | T, addCurrencyType?: CurrencyPosition, numberOfDecimals = 0): typeof value {
    const numberValidator =
      (typeof value === "number" && !isNaN(value) && isFinite(value)) || (typeof value === "string" && Number(value));

    if (!numberValidator) {
      return value;
    }

    const formattedValue = this.decimalPipe.transform(value, `1.${numberOfDecimals}-${numberOfDecimals}`) ?? "";

    switch (addCurrencyType) {
      case "start": {
        return `${this.currency} ${formattedValue}`;
      }
      case "end": {
        return `${formattedValue} ${this.currency}`;
      }
      default: {
        return formattedValue;
      }
    }
  }
}
