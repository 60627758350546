import { Injectable } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { PublicPensionPrognosisParametersStaticService } from "src/app/services/api/public-pension-prognosis-parameters-static.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { HasPublicPensionInStorebrandService } from "./api/has-public-pension-in-storebrand.service";
import { ProfileService } from "./customer-supplied-data/profile.service";
import { NorskpensjonRequirementsService } from "./prognoses-services/norskpensjon-requirements.service";

@Injectable({
  providedIn: "root",
})
export class AfpPromotorService {
  constructor(
    private readonly profileService: ProfileService,
    private readonly publicPensionPrognosisParametersStaticService: PublicPensionPrognosisParametersStaticService,
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
    private readonly clientDataService: ClientDataService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
  ) {}

  public getCanAddAfp(): Observable<boolean> {
    return combineLatest([
      this.profileService.hasAfp$,
      this.getHasInactiveOrNoPublicPension(),
      this.norskpensjonRequirementsService.getHasAllRequirements(),
    ]).pipe(
      map(([hasAfp, hasNoPublicPension, hasAllRequirements]) => !hasAfp && hasNoPublicPension && hasAllRequirements),
    );
  }

  public getCanPromoteAfp(): Observable<boolean> {
    return combineLatest([this.getCanAddAfp(), this.clientDataService.optOutAfp$]).pipe(
      map(([canAddAfp, isOptedOut]) => canAddAfp && !isOptedOut),
    );
  }

  private getHasInactiveOrNoPublicPension(): Observable<boolean> {
    return combineLatest([
      this.hasPublicPensionInStorebrandService.isPublicContext$,
      this.publicPensionPrognosisParametersStaticService.getHasEarnedPensionRight(),
    ]).pipe(
      map(([isPublicContext, hasEarnedPensionRight]) => {
        const hasNoPublicPension = !isPublicContext && !hasEarnedPensionRight;

        return hasEarnedPensionRight || hasNoPublicPension;
      }),
    );
  }
}
