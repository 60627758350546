<div
  class="engagement-panel"
  [class.expanded]="isExpanded$ | async"
  [class.active]="engagement.isActive()"
  [class.included]="isIncluded()"
>
  <mat-expansion-panel (expandedChange)="expandedChange($event)">
    <mat-expansion-panel-header>
      <div class="details">
        <div class="name">{{ contract.employer?.name }}</div>

        <app-public-pension-contract-badge class="status" [contracts]="[contract]"> </app-public-pension-contract-badge>
      </div>
    </mat-expansion-panel-header>

    <div class="summary-table single-row">
      <div class="summary-row">
        <div class="summary-label">
          {{ "publicPensionContract.contractNumber" | fms }}
        </div>

        <div class="summary-value">{{ contract.contractNumber }}</div>
      </div>
    </div>

    <ng-container
      *ngIf="(contract.coverages?.ageCoverages?.length ?? 0) > 0"
      [ngTemplateOutlet]="coverages"
      [ngTemplateOutletContext]="{
        header: 'publicPensionContract.coverages.header' | fms,
        coverages: contract.coverages?.ageCoverages
      }"
    ></ng-container>

    <ng-container
      *ngIf="(contract.coverages?.conditionalAgeCoverages?.length ?? 0) > 0"
      [ngTemplateOutlet]="coverages"
      [ngTemplateOutletContext]="{
        header: 'publicPensionContract.additions.header' | fms,
        coverages: contract.coverages?.conditionalAgeCoverages
      }"
    ></ng-container>
  </mat-expansion-panel>
</div>

<ng-template #coverages let-header="header" let-coverages="coverages">
  <div class="summary-table">
    <div class="summary-header summary-row">
      <div class="summary-header-column">
        {{ header }}
      </div>

      <div class="summary-header-column">
        {{ "publicPensionContract.firstYearPayout" | fms }}
      </div>
    </div>

    <div class="summary-row" *ngFor="let coverage of coverages">
      <div class="summary-label">
        <div class="summary-label-title">
          {{ getNameFromCoverage(coverage) }}

          <app-tip-icon *ngIf="getDescriptionFromCoverage(coverage)" (click)="openDialog(coverage)"></app-tip-icon>
        </div>

        <div class="summary-label-subtitle">
          {{
            "publicPensionContract.payoutBetweenYears"
              | fms
                : {
                    args: {
                      fromAge: coverage.fromAndIncludingAge,
                      toAge: coverage.toNotIncludingAge
                    }
                  }
          }}
        </div>
      </div>

      <div class="summary-value">{{ coverage.annualAmount | currencyFormatterPipe }} kr/år</div>
    </div>
  </div>
</ng-template>
