<div class="text">
  <h3 class="stb-mb2">{{ title }}</h3>
  <p [innerHTML]="description"></p>
</div>

<ng-content></ng-content>

<div class="button-container">
  <button
    stbButton="text"
    [attr.data-trackid]="dataTrackId"
    [setIconBefore]="!!buttonIcon"
    [iconType]="buttonIcon"
    (click)="buttonClick.emit()"
  >
    {{ buttonText }}
  </button>
</div>
