import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { routes as pensionPlanRoutes } from "src/app/modules/pension-plan/routes";
import { AnyEngagement } from "src/app/services/engagements.service";
import { getEngagementNameToUseInSentence } from "src/app/utils/engagementName";
import { Nullable } from "src/app/utils/utils";
import { getContractDescription } from "../contracts-list/contract/contract.component";

@Component({
  selector: "app-contract-type-description",
  templateUrl: "./contract-type-description.component.html",
  styleUrls: ["./contract-type-description.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractTypeDescriptionComponent implements OnInit {
  @Input()
  public readonly engagement!: AnyEngagement;
  @Input()
  public showPensionPlanEntry = false;
  @Input()
  public showDescription = true;

  public readonly pensionPlanRoute = `/${pensionPlanRoutes.Root}/${pensionPlanRoutes.Summary}`;
  public contractName!: Promise<string>;
  public readonly description$ = new BehaviorSubject<Nullable<string>>(null);

  public ngOnInit(): void {
    getContractDescription(this.engagement).then((value) => {
      this.description$.next(value);
    });

    this.contractName = getEngagementNameToUseInSentence(this.engagement);
  }
}
