import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouteKey, routes } from "src/app/routes";

@Component({
  selector: "app-needs-page",
  templateUrl: "./needs-page.component.html",
  styleUrls: ["./needs-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeedsPageComponent {
  public readonly frontPageRoute = routes[RouteKey.Root];
}
