import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable, timer } from "rxjs";
import { map, mapTo, startWith, switchMapTo } from "rxjs/operators";
import { OBSERVER_BREAKPOINT_MD_MIN } from "src/app/constants/technical.constants";
import { FmsKey } from "src/app/services/fms/fms";

export interface NavProgressButton {
  visible?: boolean;
  text?: FmsKey;
  link?: string;
  onClick?: () => void;
}

export interface NavProgressButtons {
  next?: NavProgressButton;
  previous?: NavProgressButton;
}

@Component({
  selector: "app-nav-progress",
  templateUrl: "./nav-progress.component.html",
  styleUrls: ["./nav-progress.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavProgressComponent implements OnInit {
  /**
   * When passing this prop to the component using the Async pipe
   * ([buttons]="stream$ | async"), some emissions may get lost due to what we
   * assume is a limitation of the Async pipe combined with a possible
   * race condition in the change detection / render logic of Angular.
   *
   * When multiple emissions are fired almost simultaneoulsy (only a few ms
   * separating them), only the first emission is actually passed to the input.
   *
   * Expecting an Observable and using the Async pipe directly in the
   * template have resolved this issue, making the change detection work
   * as exepcted. Change with caution.
   */
  @Input()
  public readonly buttons$!: Observable<NavProgressButtons>;
  @Input()
  public readonly progress!: number | null;

  public isTablet$?: Observable<boolean>;
  public readyToNavigateDebouncer$?: Observable<boolean>;

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  public ngOnInit(): void {
    this.isTablet$ = this.breakpointObserver
      .observe(OBSERVER_BREAKPOINT_MD_MIN)
      .pipe(map(({ matches: isDesktop }) => !isDesktop));

    this.readyToNavigateDebouncer$ = this.buttons$.pipe(switchMapTo(timer(0).pipe(mapTo(true), startWith(false))));
  }
}
