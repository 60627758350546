import {
  InMemoryDbService,
  ParsedRequestUrl,
  RequestInfo,
  RequestInfoUtilities,
  ResponseOptions,
} from "angular-in-memory-web-api";
import { cloneDeep } from "lodash-es";
import { Observable } from "rxjs";
import {
  apiJson,
  bankAccountsApi,
  benefitsCheck,
  consentsApi,
  customerApi,
  customerSuppliedDataApi,
  eligibilityCheckApi,
  smartAccountCreationStatusApi,
} from "src/app/constants/api.constants";
import { apiHomeMock } from "src/app/services/mocks/apihome-mock";
import { bankCheckingAccountsMock, bankSavingsAccountsMock } from "src/app/services/mocks/bankaccounts-mock";
import { benefitsCheckMock } from "src/app/services/mocks/benefitscheck-mock";
import { consentsMock } from "src/app/services/mocks/consents-mock";
import { customerMock } from "src/app/services/mocks/customer-mock";
import { eligibleMock } from "src/app/services/mocks/eligible-mock";
import { engagementContractsMock } from "src/app/services/mocks/engagement-contracts-mock";
import { accountStatusMock, customerAccountMock } from "src/app/services/mocks/smart-account-mock";
import { frozenSuppliedDataClientDataMock, frozenSuppliedDataMock } from "src/app/services/mocks/supplieddata-mock";
import { transactionsMock } from "src/app/services/mocks/transactions-mock";
import { environment } from "src/environments/environment";
import { Log } from "../utils/log";

class CustomHttpResponse<T extends RequestInfo> {
  private readonly response: ResponseOptions;

  public constructor({ url, headers }: RequestInfo) {
    this.response = { url, headers };
  }

  public withBody(body: T): this {
    this.response.body = cloneDeep(body);
    return this;
  }

  public withStatus(status: number): this {
    this.response.status = status;
    return this;
  }

  public withStatus200(): this {
    return this.withStatus(200);
  }

  public withStatus204(): this {
    return this.withStatus(204);
  }

  public asObject(): ResponseOptions {
    return cloneDeep(this.response);
  }

  public asObservable(): Observable<ResponseOptions> {
    return new Observable((sub) => {
      sub.next(this.asObject());
      sub.complete();
    });
  }
}

export class InMemoryDataService implements InMemoryDbService {
  /**
   * parseRequestUrl override
   *
   * Manipulates the HTTP GET request URL or the parsed result into something
   * the data store can handle. The function can be viewed as a
   * variable to url mapping, turning a request for
   * `/savings/shared/prognosis/external/norskpensjon` into
   * `/norskpensjonPrognosisMock`.
   * It leaves other URLs untouched and forwards to the default parser.
   * It also logs the result of the default parser.
   **/
  public parseRequestUrl(url: string, utils: RequestInfoUtilities): ParsedRequestUrl {
    const offlineMode = environment.offlineMode;
    const enableAllServiceMocks = environment.enableAllServiceMocks || offlineMode;
    const enableAllPrognosisMocks = environment.enableAllPrognosisMocks || offlineMode;
    const mapping = [
      {
        regexp: this.toRegExp(`${bankAccountsApi}/transactions`),
        proxy: "/transactionsMock",
        enabled: enableAllPrognosisMocks,
      },
      {
        regexp: this.toRegExp(apiJson),
        proxy: "/apiHomeMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(`${customerSuppliedDataApi}/nyt`),
        proxy: "/suppliedDataClientDataMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(customerSuppliedDataApi),
        proxy: "/suppliedDataMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(customerApi),
        proxy: "/customerMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(consentsApi),
        proxy: "/consentsMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(benefitsCheck),
        proxy: "/benefitsCheckMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(eligibilityCheckApi),
        proxy: "/eligibleMock",
        enabled: enableAllServiceMocks,
      },
      {
        regexp: this.toRegExp(smartAccountCreationStatusApi),
        proxy: "/customerAccountMock",
        enabled: enableAllServiceMocks,
      },
    ];

    const match = mapping.find((m) => m.enabled && m.regexp.test(url));
    const newUrl = match ? url.replace(match.regexp, `/api${match.proxy}`) : url;

    const parsed = utils.parseRequestUrl(newUrl);
    if (url !== newUrl) {
      Log.trace(`%cparseRequestUrl override of '${url}':`, "color: pink", parsed);
    }
    return parsed;
  }

  public createDb(): Record<string, unknown> {
    return {
      apiHomeMock,
      bankSavingsAccountsMock,
      bankCheckingAccountsMock,
      consentsMock,
      customerMock,
      suppliedDataClientDataMock: frozenSuppliedDataClientDataMock,
      suppliedDataMock: frozenSuppliedDataMock,
      accountStatusMock,
      transactionsMock,
      benefitsCheckMock,
      engagementContractsMock,
      eligibleMock,
      customerAccountMock,
    };
  }

  /** Override handlers for custom processing of non-GET requests */
  protected patch(requestInfo: RequestInfo): ReturnType<typeof InMemoryDataService.prototype.handleRequest> {
    return this.handleRequest(requestInfo);
  }
  protected post(requestInfo: RequestInfo): ReturnType<typeof InMemoryDataService.prototype.handleRequest> {
    return this.handleRequest(requestInfo);
  }
  protected put(requestInfo: RequestInfo): ReturnType<typeof InMemoryDataService.prototype.handleRequest> {
    return this.handleRequest(requestInfo);
  }

  private toRegExp(str: string): RegExp {
    return new RegExp(`${str}.*`);
  }

  /**
   * Defines custom request handling for non-GET requests.
   *
   * @param requestInfo the request to handle
   * @returns the response as an observable
   */
  private handleRequest(requestInfo: RequestInfo): Observable<Response | ResponseOptions> | null {
    if (!environment.offlineMode) {
      return null;
    }

    const { url } = requestInfo;
    const isSuppliedDataPatch: boolean = this.toRegExp(customerSuppliedDataApi).test(url);

    if (isSuppliedDataPatch) {
      return new CustomHttpResponse(requestInfo).withStatus200().asObservable();
    }

    return null;
  }
}
