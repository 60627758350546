<ng-container *ngIf="buttonsToRender$ | async as buttonsToRender">
  <ng-container *ngIf="individualButtons; else combinedButtonInDialog">
    <ng-container *ngFor="let button of buttonsToRender">
      <button
        stbButton="text"
        iconType="add"
        [setIconBefore]="true"
        (click)="newEngagementDialog(button.dialogComponent)"
        [attr.data-trackid]="button.trackIdFmsKey"
      >
        {{ button.textFmsKey | fms }}
      </button>
    </ng-container>
  </ng-container>

  <ng-template #combinedButtonInDialog>
    <button
      *ngIf="buttonsToRender.length > 0"
      stbButton="contained"
      iconType="add"
      [setIconBefore]="true"
      data-trackid="add-pension-savings"
      (click)="openCombinedNewEngagementsDialog(buttonsToRender)"
    >
      {{ "pensionPlan.addEngagementsButtons.combinedButton.text" | fms }}
    </button>
  </ng-template>
</ng-container>
