<div class="column">
  <span class="label">{{ "needsSummary.startPayoutAge.label" | fms }}</span>
  <app-start-payout-age-form-field class="fit-content-select"></app-start-payout-age-form-field>
</div>

<div class="column" *ngIf="firstYearTotal$ | async; let firstYearTotal">
  <span class="label">{{ "needsSummary.firstYearTotal.label" | fms }}</span>
  <div class="value">
    <span>{{ firstYearTotal | currencyFormatterPipe : "end" }}</span>
  </div>
</div>

<div class="column" *ngIf="(percOfSalaryFirstYear$ | async) && !(hasNonInflationAdjustedEngagement$ | async); let percOfSalaryFirstYear">
  <span class="label">
    {{
      "needsSummary.percOfSalary.label"
        | fms : { args: { salary: annualGrossIncome$ | async | currencyFormatterPipe : "end" } }
    }}
  </span>
  <div class="value">
    <span>{{ percOfSalaryFirstYear }}%</span>
  </div>
</div>
