import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { isEmpty } from "lodash-es";
import { Nullable } from "../utils/utils";

export interface RouteData {
  title?: Nullable<string>;
}

@Injectable({
  providedIn: "root",
})
export class RouteDataService {
  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public getRouteData(): RouteData | undefined {
    const child = this.getLastChild();
    return child?.snapshot.data;
  }

  private getLastChild(): ActivatedRoute | null {
    let child = this.activatedRoute.firstChild;

    while (child?.firstChild) {
      // eslint-disable-next-line fp/no-mutation
      child = child.firstChild;
    }

    return child;
  }
}

export function getPathForRoute(activatedRoute: ActivatedRoute): string {
  const separator = "/";
  const pathFromRoot = activatedRoute.pathFromRoot
    .map(({ snapshot }) => snapshot.url.map(({ path }) => path))
    .filter((path) => !isEmpty(path))
    .join(separator);

  return separator + pathFromRoot;
}
