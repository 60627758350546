import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export interface AnnualReturn {
  description: string;
  pct: number;
}

@Component({
  selector: "app-contract-details-annual-return-pct",
  templateUrl: "./contract-details-annual-return-pct.component.html",
  styleUrls: ["./../../../../../shared/components/engagement-details/engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractDetailsAnnualReturnPctComponent {
  @Input()
  public readonly annualReturn!: AnnualReturn;
}
