import { Injectable } from "@angular/core";
import { TrackIdTransformer } from "src/app/modules/shared/directives/track-id/track-id.directive";

@Injectable()
export class ButtonTrackIdTransformer implements TrackIdTransformer {
  public predicate(element: HTMLElement): boolean {
    return element instanceof HTMLButtonElement;
  }

  public transform(trackId: string): string {
    return trackId.replace(/^(button-)?(.*?)(-button)?$/gim, "button-$2");
  }
}
