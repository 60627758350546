import { RouterModule } from "@angular/router";
import { ActionsPageComponent } from "src/app/modules/actions/pages/actions-page/actions-page.component";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "ta-kontroll-over-pensjonen-din",
};

export const routing = RouterModule.forChild([
  {
    path: "",
    component: ActionsPageComponent,
    data: {
      title: "pageTitle.actions",
      breadcrumb: {
        hideAll: true,
      },
    },
  },
]);
