import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, combineLatestWith, map, mergeMap } from "rxjs/operators";
import { BankEngagement, isSmartAccount } from "src/app/models/engagements/bank-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { CustomerService } from "src/app/services/customer.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
  toFetchPrognosisResult,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { handleError } from "src/app/utils/http";
import { MissingBankPrognosisError } from "src/app/utils/prognosis-errors";
import { BankAccountsQueryService } from "../api/bank-accounts-queries.service";
import { PrognosisQueriesService } from "../api/prognosis-queries.service";
import { KeycloakService } from "../keycloak.service";

interface BankPrognosisParams {
  startPayoutAge: number;
  birthYear: number;
  gender: string;
}

type FetchPrognosesParams = CustomerSuppliedData.SimulationParametersByEngagement<BankPrognosisParams>[1];

const BANK_CONFIG: ServiceConfig = {
  name: "BankService",
  fmsKey: "bank",
};

@Injectable({
  providedIn: "root",
})
export class BankService extends AbstractPrognosisFetchService<BankEngagement, BankPrognosisParams> {
  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly bankAccountsQueryService: BankAccountsQueryService,
    private readonly prognosisQueriesService: PrognosisQueriesService,
    private readonly customerService: CustomerService,
    private readonly keycloakService: KeycloakService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    super(BANK_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);
  }

  public fetchPrognosis(
    engagement: BankEngagement,
    params: FetchPrognosesParams,
  ): Observable<FetchPrognosisResult<BankEngagement>> {
    const source = "BankService::fetchPrognosis";

    const request$ = this.prognosisQueriesService.getOpenPrognosisBank(createQueryVariablesBank(engagement, params));

    return request$.pipe(
      map((prognosis) => toFetchPrognosisResult(prognosis)),
      catchError((error) => handleError(source, error, of({ prognoses: [] }))),
    );
  }

  protected _fetchEngagements(): Observable<BankEngagement[]> {
    return this.keycloakService.isAdvisorContext$.pipe(
      mergeMap((isAdvisorContext) => (isAdvisorContext ? of([]) : this.bankAccountsQueryService.fetchBankAccounts())),
      map(filterBankAccounts),
      map((accounts) => accounts.map((account) => new BankEngagement(account))),
    );
  }

  protected prognosisSimParams$(): Observable<Partial<BankPrognosisParams>> {
    return this.startPayoutAgeService.getStartPayoutAge().pipe(
      combineLatestWith(this.customerService.birthYear$, this.customerService.gender$),
      map(([startPayoutAge, birthYear, gender]) => ({
        startPayoutAge,
        birthYear,
        gender,
      })),
    );
  }

  protected composeMissingPrognosisError(engagement: BankEngagement): MissingBankPrognosisError {
    return new MissingBankPrognosisError(engagement);
  }
}

function filterBankAccounts(bankAccounts: Graph.BankAccount[]): Graph.BankAccount[] {
  return bankAccounts.filter(isSmartAccount);
}

function createQueryVariablesBank(
  engagement: BankEngagement,
  {
    birthYear,
    gender,
    startPayoutAge,
    durationYears: payoutDurationYears,
  }: Partial<CustomerSuppliedData.SimulationParameters> & BankPrognosisParams,
): Graph.BankOpenPrognosisInput {
  return {
    balance: engagement.getBalance(),
    birthYear,
    gender,
    monthsOffset: 0,
    payoutDurationYears,
    periodicDeposit: 0,
    startPayoutAge,
  };
}
