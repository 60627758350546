<section class="container mat-typography">
  <app-page-title [text]="'conceptPage.pageTitle' | fms"></app-page-title>

  <app-quote-hero [heroDetails]="'conceptPage.quoteHero' | fms" [illustration]="true"></app-quote-hero>

  <div class="content">
    <div class="priority-box">
      <div class="priority-box-image">
        <img [src]="'conceptPage.priorityBox.imageUrl' | fms" alt />
      </div>

      <div class="priority-box-content">
        <h3>{{ "conceptPage.priorityBox.title" | fms }}</h3>

        <div [innerHTML]="'conceptPage.priorityBox.html' | fms"></div>

        <a
          [routerLink]="'conceptPage.priorityBox.buttonUrl' | fms"
          queryParamsHandling="merge"
          data-trackid="concept-landingpage-readmore-cta"
          mat-stroked-button
        >
          {{ "conceptPage.priorityBox.buttonText" | fms }}
        </a>
      </div>
    </div>

    <iframe
      title="title"
      name="opinionstage-widget"
      src="https://www.opinionstage.com/api/v1/widgets/917793/iframe"
      width="500px"
      height=""
      style="border: none; max-width: 100%; height: 380px"
      allow="fullscreen"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      rereferrerpolicy="no-referrer-when-downgrade"
    ></iframe>

    <h2>{{ "conceptPage.promoboxes.title" | fms }}</h2>

    <app-promo-box-container class="bottom-margin" [promoBoxes]="'conceptPage.promoboxes.config' | fms">
    </app-promo-box-container>

    <div id="concept-survey"></div>
  </div>
</section>
