import { produce } from "immer";
import { isEqual, uniqWith } from "lodash-es";
import { getIsNotNullable, Nullable } from "./utils";

export type ArrayType<T extends Array<any> | null | undefined> = T extends (infer U)[] ? U : never;

export type Comparator<T = any> = (a: T, b: T) => boolean;

export const filterUnique = <T>(array: Array<T>, comparator: Comparator<T> = isEqual): Array<T> =>
  uniqWith(array, comparator);

export const reduceToOccurences = (array: Array<number>): number =>
  array.reduce((acc, value) => (value > 0 ? acc + 1 : acc), 0);

export const reduceToSum = (array: Array<number>): number => array.reduce(toSum, 0);

export function toSum(acc: number, value: number): number {
  return acc + value;
}

/**
 * @see {@link https://stackoverflow.com/a/36963945/1162819}
 */
export const positiveRange = (start: number, end: number, includeEnd = false): number[] =>
  Array.from({ length: (includeEnd ? end + 1 : end) - start }, (_, k) => k + start);

export const getLastItem = <T = unknown>(array: T[] | undefined): T | undefined => array?.at(-1);
export const isEndOfArray = <T = unknown>(index: number, array: T[]): boolean =>
  index === (array.length < 1 ? 0 : array.length - 1);

export const arrayToAverage = (acc: number, cur: number, idx: number, src: Array<number>): number => {
  const next = acc + cur;

  if (!Number.isFinite(next) || typeof cur !== "number") {
    throw new TypeError("Encountered illegal element when reducing array to average");
  }
  return isEndOfArray(idx, src) ? next / src.length : next;
};

export function toArrayOrEmpty<T>(data: Nullable<Array<T>>): Array<T> {
  return Array.isArray(data) ? data : [];
}

export function getReversedArray<T>(array: T[]): T[] {
  return [...array].reverse();
}

export const getIsEveryItemTrue = (items: boolean[]): boolean => items.every((item) => item);

export function getIsEveryItemFalse(items: boolean[]): boolean {
  return items.every((item) => !item);
}

export const getIsSomeItemsTrue = (items: boolean[]): boolean => items.some((item) => item);

export function getIsNotEmpty<T>(items: T[] | undefined): items is [T, ...T[]] {
  return !getIsEmpty(items);
}

export function getIsEmpty<T>(items: T[] | undefined): boolean {
  return items?.length === 0;
}

export const getFirstItem = <T>(items: T[]): T | undefined => items.at(0);

export const getIsEveryNotNullable = <T>(items: Nullable<T>[]): items is T[] => items.every(getIsNotNullable);

export function getNonNullableArray<T>(items: Nullable<Nullable<T>[]>): T[] {
  return getIsNotNullable(items) && getIsEveryNotNullable(items) ? items : [];
}

export function shiftLastElementToTopImmutable(array: number[]): typeof array {
  return produce(array, (draft) => {
    const lastElement = draft.pop();

    if (getIsNotNullable(lastElement)) {
      draft.unshift(lastElement);
    }

    return draft;
  });
}
