import { BehaviorSubject, ReplaySubject, SchedulerLike } from "rxjs";
import { freeze } from "immer";

import { naiveObjectComparison } from "../utils";

export function deepFreeze<T>(obj: T): T {
  return freeze(obj, true);
}

/**
 * An immutable ReplaySubject.
 *
 * ReplayStore extends ReplaySubject by deep freezing
 * all emissions.
 */
export class ReplayStore<T> extends ReplaySubject<T> {
  constructor(bufferSize = 1, windowTime?: number, scheduler?: SchedulerLike) {
    super(bufferSize, windowTime, scheduler);
  }

  /**
   * Deep freezes incoming data and inserts it into the underlying
   * ReplaySubject. Defaults with a bufferSize of 1.
   *
   * @param {T} newData the new data to emit
   */
  next(newData: T): void {
    const frozenData = deepFreeze(newData);
    super.next(frozenData);
  }
}

/**
 * An immutable BehaviorSubject.
 *
 * BehaviorStore extends BehaviorSubject by deep freezing
 * all emissions.
 *
 * @template T the type of the data to store
 */
export class BehaviorStore<T> extends BehaviorSubject<T> {
  constructor(initialData: T) {
    super(deepFreeze(initialData));
  }

  /**
   * Deep freezes incoming data and inserts it into the underlying
   * BehaviorSubject. The incoming object must also pass an
   * inequality check against the previous value.
   *
   * @param {T} newData the new data to replace the old
   */
  next(newData: T): void {
    const frozenData = deepFreeze(newData);
    if (!naiveObjectComparison(frozenData, this.getValue())) {
      super.next(frozenData);
    }
  }
}
