import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { RouteKey, routes } from "src/app/modules/investment-options/routes";
import { EngagementsWithHoldingsProfilesService } from "src/app/modules/shared/services/contracts-with-holdings.service";
import { HoldingsAndSavingsProfile } from "src/app/modules/shared/services/fetch-holdings-and-allocation.service";
import { NavigateToService } from "src/app/services/navigate-to.service";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";
import { getIsNotEmpty } from "src/app/utils/array";

export interface EngagementHoldingsProfile {
  engagement: AnySavingsAndPensionEngagement;
  holdingsProfile: HoldingsAndSavingsProfile;
}

@Component({
  selector: "app-action-holdings",
  templateUrl: "./action-holdings.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionHoldingsComponent implements ActionComponent {
  @Input()
  public action!: Action;

  private readonly engagementHoldingsProfiles$ =
    this.engagementsWithHoldingsProfilesService.getEngagementHoldingsProfiles();

  constructor(
    private readonly navigateToService: NavigateToService,
    private readonly engagementsWithHoldingsProfilesService: EngagementsWithHoldingsProfilesService,
  ) {}

  public getIsVisible(): Observable<boolean> {
    return this.engagementHoldingsProfiles$.pipe(map(getIsNotEmpty));
  }

  public goToInvestmentOptionPage(): Promise<boolean> {
    const investmentOptionRoute = routes[RouteKey.Root];
    return this.navigateToService.navigateWithExitUrl(investmentOptionRoute);
  }
}
