import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CardNavigation, DashboardCard } from "../../dashboard-cards/dashboard-card/dashboard-card.component";

export interface PromoCard extends DashboardCard {
  backgroundColor?: string;
  showMessage: boolean;
}

@Component({
  selector: "app-dashboard-promo-card",
  templateUrl: "./dashboard-promo-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPromoCardComponent {
  @Input()
  public readonly promoCard!: PromoCard | null;
  @Input()
  public readonly trackId!: string;
  @Output()
  public readonly cardClick = new EventEmitter<CardNavigation>();
}
