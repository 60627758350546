import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { combineLatestWith, filter, map } from "rxjs/operators";
import { CurrencyFormatterPipe } from "src/app/modules/shared/pipes/currency-formatter.pipe";
import { AnyEngagement } from "src/app/services/engagements.service";
import { PayoutplanService } from "src/app/services/payoutplan.service";
import { Nullable } from "src/app/utils/utils";

interface TooltipFmsArgs {
  divisorEngagementsTotalValue: Nullable<number | string>;
  allYearsTotal: Nullable<number | string>;
}

@Component({
  selector: "app-percentage-total-pension-change",
  templateUrl: "./percentage-total-pension-change.component.html",
  styleUrls: ["./percentage-total-pension-change.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageTotalPensionChangeComponent implements OnInit {
  @Input()
  private readonly divisorEngagements$!: Observable<AnyEngagement[]>;

  public totalPercentChange$?: Observable<number>;
  public tooltipFmsArgs$?: Observable<TooltipFmsArgs>;
  public readonly Math = Math;

  constructor(
    private readonly payoutPlanService: PayoutplanService,
    private readonly currencyFormatter: CurrencyFormatterPipe,
  ) {}

  ngOnInit(): void {
    this.totalPercentChange$ = this.divisorEngagementsTotalValue().pipe(
      combineLatestWith(this.payoutPlanService.allYearsTotal$),
      map(([divisor, allYearsTotal]) => divisor / (allYearsTotal / 100)),
    );

    this.tooltipFmsArgs$ = this.divisorEngagementsTotalValue().pipe(
      combineLatestWith(this.payoutPlanService.allYearsTotal$),
      map(([divisor, total]) => ({
        divisorEngagementsTotalValue: this.currencyFormatter.transform(divisor),
        allYearsTotal: this.currencyFormatter.transform(total),
      })),
    );
  }

  private divisorEngagementsTotalValue(): Observable<number> {
    return this.divisorEngagements$.pipe(
      filter((divisorEngagements) => divisorEngagements.length > 0),
      map((divisorEngagements) =>
        divisorEngagements.reduce((sum, engagement) => sum + (engagement.getTotalPayout() ?? 0), 0),
      ),
    );
  }
}
