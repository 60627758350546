import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

export interface GetBankAccountsQuery {
  savingsEngagement: {
    bankAccounts: Graph.BankAccount[];
  };
}
export const GET_BANK_ACCOUNTS_QUERY = gql`
  query bankAccountsQuery($input: QueryInput) {
    savingsEngagement(input: $input) {
      bankAccounts {
        currentInterestRate
        accountNumber
        productFriendlyName
        bookBalance
        productCode
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class BankAccountsQueryService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public fetchBankAccounts(): Observable<Graph.BankAccount[]> {
    return this.savingsGraphqlClient
      .query<GetBankAccountsQuery>({
        query: GET_BANK_ACCOUNTS_QUERY,
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map((query) => query?.savingsEngagement?.bankAccounts ?? []),
      );
  }
}
