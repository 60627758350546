import { ChangeDetectionStrategy, Component } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { IncomeService } from "src/app/services/income/income.service";
import { PayoutplanService } from "src/app/services/payoutplan.service";
import { tickerPipe } from "src/app/utils/rxjs/ticker";
import { percentCalculator } from "src/app/utils/utils";
import { EngagementsService } from "src/app/services/engagements.service";
import { isNonInflationAdjustedEngagement } from "src/app/utils/engagement.utils";

@Component({
  selector: "app-needs-summary",
  templateUrl: "./needs-summary.component.html",
  styleUrls: ["./needs-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeedsSummaryComponent {
  public readonly annualGrossIncome$ = this.incomeService.annualGrossIncome$;

  public readonly firstYearTotal$ = this.payoutPlanService.firstYearTotal$.pipe(tickerPipe());

  public readonly percOfSalaryFirstYear$ = this.getPercOfSalaryFirstYear().pipe(tickerPipe(), map(Math.round));

  public readonly hasNonInflationAdjustedEngagement$ = this.engagementsService.allEngagements$.pipe(
    map((allEngagements) => allEngagements.some(isNonInflationAdjustedEngagement)),
  );

  constructor(
    private readonly payoutPlanService: PayoutplanService,
    private readonly incomeService: IncomeService,
    private readonly engagementsService: EngagementsService,
  ) {}

  private getPercOfSalaryFirstYear(): Observable<number> {
    return combineLatest([this.payoutPlanService.firstYearTotal$, this.incomeService.annualGrossIncome$]).pipe(
      map(([firstYearTotal, annualGrossIncome]) =>
        annualGrossIncome ? percentCalculator.xIsWhatPercentageOfY(firstYearTotal, annualGrossIncome) : 0,
      ),
    );
  }
}
