import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { first, firstValueFrom, merge, startWith, switchMap } from "rxjs";
import { AfpService } from "src/app/services/prognoses-services/afp.service";
import { BankService } from "../services/prognoses-services/bank.service";
import { NavService } from "../services/prognoses-services/nav.service";
import { NorskpensjonRequirementsService } from "../services/prognoses-services/norskpensjon-requirements.service";
import { NorskpensjonService } from "../services/prognoses-services/norskpensjon.service";
import { OtherPensionEngagementsService } from "../services/prognoses-services/other-pension-engagements.service";
import { PublicPensionService } from "../services/prognoses-services/public-pension.service";
import { SavingsAndPensionService } from "../services/prognoses-services/savings-and-pension.service";
import { UnavailableNorskpensjonService } from "../services/prognoses-services/unavailable-norskpensjon.service";
import { FetchPrognosesRunningJobsService } from "../services/running-jobs/fetch-prognoses-running-jobs.service";

export const afpResolver: ResolveFn<boolean> = () => {
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);
  const norskpensjonRequirementsService = inject(NorskpensjonRequirementsService);
  const afpService = inject(AfpService);

  const fetchEngagements$ = afpService
    .fetchEngagements()
    .pipe(fetchPrognosesRunningJobsService.withLoader("AfpResolver"));

  norskpensjonRequirementsService
    .getHasAllRequirements()
    .pipe(
      first(Boolean),
      startWith(null),
      switchMap(() => fetchEngagements$),
    )
    .subscribe();

  return true;
};

export const bankResolver: ResolveFn<boolean> = () => {
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);
  const bankService = inject(BankService);

  bankService.fetchEngagements().pipe(fetchPrognosesRunningJobsService.withLoader("BankResolver")).subscribe();
  return true;
};

export const navResolver: ResolveFn<boolean> = () => {
  const navService = inject(NavService);
  const norskpensjonRequirementsService = inject(NorskpensjonRequirementsService);
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);

  const fetchEngagements$ = navService
    .fetchEngagements()
    .pipe(fetchPrognosesRunningJobsService.withLoader("NavResolver"));

  const prefetchPrognosesInRange$ = navService.prefetchPrognosesInRange();

  norskpensjonRequirementsService
    .getHasAllRequirements()
    .pipe(
      first(Boolean),
      startWith(null),
      switchMap(() => merge(fetchEngagements$, prefetchPrognosesInRange$)),
    )
    .subscribe();

  return true;
};

export const norskpensjonResolver: ResolveFn<boolean> = () => {
  const norskpensjonService = inject(NorskpensjonService);
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);

  const fetchEngagements$ = norskpensjonService
    .fetchEngagements()
    .pipe(fetchPrognosesRunningJobsService.withLoader("NorskpensjonResolver"));
  const prefetchPrognosesInRange$ = norskpensjonService.prefetchPrognosesInRange();

  merge(fetchEngagements$, prefetchPrognosesInRange$).subscribe();

  return true;
};

export const unavailableNorskpensjonResolver: ResolveFn<boolean> = () => {
  const unavailableNorskpensjonService = inject(UnavailableNorskpensjonService);
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);

  unavailableNorskpensjonService
    .fetchEngagements()
    .pipe(fetchPrognosesRunningJobsService.withLoader("UnavailableNorskpensjonResolver"))
    .subscribe();

  return true;
};

export const otherPensionEngagementsResolver: ResolveFn<boolean> = () => {
  const otherPensionEngagementsService = inject(OtherPensionEngagementsService);
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);

  otherPensionEngagementsService
    .fetchEngagements()
    .pipe(fetchPrognosesRunningJobsService.withLoader("OtherPensionEngagementsResolver"))
    .subscribe();

  return true;
};

export const publicPensionResolver: ResolveFn<boolean> = () => {
  const publicPensionService = inject(PublicPensionService);
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);

  firstValueFrom(
    publicPensionService.fetchEngagements().pipe(fetchPrognosesRunningJobsService.withLoader("PublicPensionResolver")),
  );

  return true;
};

export const savingsAndPensionResolver: ResolveFn<boolean> = () => {
  const savingsAndPensionService = inject(SavingsAndPensionService);
  const fetchPrognosesRunningJobsService = inject(FetchPrognosesRunningJobsService);

  savingsAndPensionService
    .fetchEngagements()
    .pipe(fetchPrognosesRunningJobsService.withLoader("SavingsAndPensionResolver"))
    .subscribe();

  return true;
};
