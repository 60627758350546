import { ErrorHandler, Inject, Injectable, Optional } from "@angular/core";
import { ErrorHandlerOptions, SentryErrorHandler } from "@sentry/angular-ivy";
import { DispatcherService, Signal } from "src/app/services/dispatcher.service";
import { isChunkLoadError, isHmrSharedModuleError } from "src/app/utils/errors";
import { Log } from "src/app/utils/log";

export const APP_ERROR_HANDLER_OPTIONS = "AppErrorHandlerOptions";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private readonly sentryErrorHandler: SentryErrorHandler;

  public constructor(
    private readonly dispatcherService: DispatcherService,
    @Optional()
    @Inject(APP_ERROR_HANDLER_OPTIONS)
    sentryErrorHandlerOptions: ErrorHandlerOptions = {},
  ) {
    this.sentryErrorHandler = new SentryErrorHandler({
      showDialog: false,
      logErrors: false,
      ...sentryErrorHandlerOptions,
    });
  }

  public handleError(error: Error): void {
    if (isChunkLoadError(error)) {
      this.dispatcherService.dispatch(Signal.ChunkLoadError, AppErrorHandler.name);
      return;
    }

    if (isHmrSharedModuleError(error)) {
      Log.warn("Hot reloaded component in SharedModule, reload if any problems occur");
      return;
    }

    this.sentryErrorHandler.handleError(error);
  }
}
