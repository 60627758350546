import { Component } from "@angular/core";
import { BenefitsService } from "src/app/services/benefits.service";
import { ConsentService } from "src/app/services/consent.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { CustomerService } from "src/app/services/customer.service";
import { FmsService } from "src/app/services/fms.service";
import { AfpService } from "src/app/services/prognoses-services/afp.service";
import { BankService } from "src/app/services/prognoses-services/bank.service";
import { ExternalEngagementsService } from "src/app/services/prognoses-services/external-engagements.service";
import { NorskpensjonService } from "src/app/services/prognoses-services/norskpensjon.service";

@Component({
  selector: "app-api-fetch-menu",
  templateUrl: "./api-fetch-menu.component.html",
})
export class ApiFetchMenuComponent {
  constructor(
    private readonly benefitsService: BenefitsService,
    private readonly afpService: AfpService,
    private readonly bankService: BankService,
    private readonly clientDataService: ClientDataService,
    private readonly consentService: ConsentService,
    private readonly customerService: CustomerService,
    private readonly externalEngagementsService: ExternalEngagementsService,
    private readonly fmsService: FmsService,
    private readonly npService: NorskpensjonService,
    private readonly profileService: ProfileService,
  ) {}

  fetchAfpEngagements(): void {
    this.afpService.fetchEngagements().subscribe();
  }
  fetchBankEngagements(): void {
    this.bankService.fetchEngagements().subscribe();
  }
  fetchExternalEngagements(): void {
    this.externalEngagementsService.fetchEngagements().subscribe();
  }
  fetchNpEngagements(): void {
    this.npService.fetchEngagements().subscribe();
  }

  fetchConsents(): void {
    this.consentService.fetchConsents().subscribe();
  }
  fetchCustomer(): void {
    this.customerService.fetchAndSetCustomerWithLoader().subscribe();
  }
  fetchClientData(): void {
    this.clientDataService.fetchClientDataWrapper().subscribe();
  }
  fetchProfile(): void {
    this.profileService.fetchProfile().subscribe();
  }
  fetchTexts(): void {
    this.fmsService.fetchTexts().subscribe();
  }
  public fetchBenefits(): void {
    this.benefitsService.fetchCustomerBenefits$().subscribe();
  }
}
