import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import {
  DEFAULT_MAX_LIFE_EXPECTANCY_AGE,
  Limit,
  PENSION_NEEDS_DEPRECIATION_FACTOR,
  PENSION_NEEDS_DEPRECIATION_FACTOR_ACTIVATION_YEAR,
  PENSION_NEEDS_RANGED_START_DECIMAL,
} from "src/app/constants/business.constants";
import { PayoutplanService } from "src/app/services/payoutplan.service";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { IncomeService } from "./income/income.service";

@Injectable({
  providedIn: "root",
})
export class PensionNeedsService {
  public readonly pensionNeedsArearangeData$: Observable<PayoutPlan.SeriesArearangeData[]>;
  public readonly firstYearIncomeGap$: Observable<number>;

  constructor(
    private readonly incomeService: IncomeService,
    private readonly payoutPlanService: PayoutplanService,
  ) {
    const completeAgeRangeAcrossChart$: Observable<number[]> = this.payoutPlanService.payoutPlan$.pipe(
      map(getCompleteRangeOfChart),
    );

    const annualIncomeOrZero$ = this.incomeService.annualGrossIncome$.pipe(map((val) => val ?? 0));

    this.pensionNeedsArearangeData$ = memoizeObject$(
      combineLatest([completeAgeRangeAcrossChart$, annualIncomeOrZero$]).pipe(
        map(([ageRange, annualGrossIncome]) =>
          ageRange.map((_, i) => {
            const yearsAsPensioner = i + 1;

            return calculateRangedNeedsDataLine(
              annualGrossIncome ?? 0,
              PENSION_NEEDS_RANGED_START_DECIMAL,
              yearsAsPensioner,
            );
          }),
        ),
      ),
    );

    this.firstYearIncomeGap$ = this.firstYearIncomeGap$ = combineLatest([
      this.payoutPlanService.firstYearTotal$,
      annualIncomeOrZero$,
    ]).pipe(
      filter(([firstYearPayout, firstYearNeeds]) => firstYearPayout != null && firstYearNeeds != null),
      map(([firstYearPayout, lastYearGrossIncome]) => (lastYearGrossIncome ?? 0) - firstYearPayout),
    );
  }
}

function getCompleteRangeOfChart(payoutPlanPeriods: PayoutPlan.PayoutPlanPeriod[]): number[] {
  const startAge = payoutPlanPeriods.at(0)?.startAge;
  const rangeLength =
    startAge && startAge < DEFAULT_MAX_LIFE_EXPECTANCY_AGE ? DEFAULT_MAX_LIFE_EXPECTANCY_AGE - startAge + 1 : 0;
  return Array.from(Array(rangeLength).keys()).map((x) => x + (startAge ?? 0));
}

function calculateRangedNeedsDataLine(
  annualGrossIncome: number,
  percentageLimit: Limit,
  yearsAsPensioner: number,
): PayoutPlan.SeriesArearangeData {
  return {
    low: calculatePensionNeeds(annualGrossIncome, percentageLimit.min, yearsAsPensioner),
    high: calculatePensionNeeds(annualGrossIncome, percentageLimit.max, yearsAsPensioner),
  };
}

function calculatePensionNeeds(annualGrossIncome: number, percentageValue: number, yearsAsPensioner: number): number {
  const percentageDeprecation = Math.max(
    (yearsAsPensioner - PENSION_NEEDS_DEPRECIATION_FACTOR_ACTIVATION_YEAR) * PENSION_NEEDS_DEPRECIATION_FACTOR,
    0,
  );

  return Math.round(annualGrossIncome * (percentageValue - percentageDeprecation));
}
