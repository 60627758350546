import { Pipe, PipeTransform } from "@angular/core";
import { getIsNotNullable } from "src/app/utils/utils";

@Pipe({ name: "titlecaseFirstWord" })
export class TitlecaseFirstWordPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null {
    if (value === "") {
      return "";
    }

    if (getIsNotNullable(value) && value.length > 0) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return null;
  }
}
