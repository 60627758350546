import { Inject, Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { CLIPBOARD } from "../tokens";

@Injectable({
  providedIn: "root",
})
export class CopyValueToClipboardService {
  constructor(
    private readonly snackBar: MatSnackBar,
    @Inject(CLIPBOARD)
    private readonly clipboard: Clipboard,
  ) {}

  public copy(value: string, feedbackMessage?: string): void {
    this.clipboard.writeText(value).then(() => {
      if (feedbackMessage) {
        this.openSnackBar(feedbackMessage);
      }
    });
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, "", {
      duration: 2750,
    });
  }
}
