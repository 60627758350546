import { Injectable } from "@angular/core";
import {
  catchError,
  combineLatest,
  combineLatestWith,
  EMPTY,
  filter,
  first,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  skip,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs";
import {
  AbstractNavEngagement,
  NavEngagementFromNp,
  NavEngagementFromStb,
} from "src/app/models/engagements/nav-engagement.model";
import { mapStatusMessageToSimulationStatus } from "src/app/models/engagements/public-pension-engagement.model";
import { EngagementSimulationStatus } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
  toFetchPrognosisResult,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import {
  createGraphSimulationError,
  SimulationErrorCmsKey,
} from "src/app/services/prognoses-services/simulation-result.creators";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { toArrayOrEmpty } from "src/app/utils/array";
import { MissingNavPrognosisError } from "src/app/utils/prognosis-errors";
import { getIsNotNullable, ignoreReturn } from "src/app/utils/utils";
import { HasPublicPensionInStorebrandService } from "../api/has-public-pension-in-storebrand.service";
import { NavQueriesService } from "../api/nav-queries.service";
import { SimulationParametersQueriesService } from "../api/simulation-parameters-queries.service";
import {
  createFullWithdrawalVariables,
  createPartialWithdrawalVariables,
  PartialWithdrawalPeriodsParams,
  WithdrawalPeriodsParams,
} from "../api/withdrawal-periods-variables.utils";
import { ClientDataService } from "../customer-supplied-data/client-data.service";
import { IncomeService } from "../income/income.service";
import { NorskpensjonRequirementsService } from "./norskpensjon-requirements.service";
import { PublicPensionService } from "./public-pension.service";

export type NavPrognosisParams = WithdrawalPeriodsParams & PartialWithdrawalPeriodsParams;

export const NAV_CONFIG: ServiceConfig = {
  name: "NavService",
  fmsKey: "norskpensjon",
};

@Injectable({
  providedIn: "root",
})
export class NavService extends AbstractPrognosisFetchService<AbstractNavEngagement, NavPrognosisParams> {
  constructor(
    commonParametersService: CommonParametersService,
    prognosesFetchSpinnerService: FetchPrognosesRunningJobsService,
    private readonly navQueriesService: NavQueriesService,
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
    private readonly publicPensionService: PublicPensionService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    private readonly incomeService: IncomeService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly clientDataService: ClientDataService,
  ) {
    super(NAV_CONFIG, commonParametersService, prognosesFetchSpinnerService);

    this.fetchEngagementsOnRequirementsChange().subscribe();
  }

  public fetchPrognosis(
    _: AbstractNavEngagement,
    params: NavPrognosisParams,
  ): Observable<FetchPrognosisResult<AbstractNavEngagement>> {
    const isPartialWithdrawal = getIsNotNullable(params.startPayoutAgePartialWithdrawal);

    const prognosisParametersInput = isPartialWithdrawal
      ? createPartialWithdrawalVariables(params)
      : createFullWithdrawalVariables(params);

    const folketrygdFromNav$ = this.navQueriesService.getNavFolketrygdPrognosis(prognosisParametersInput).pipe(
      map((prognosis) => toFetchPrognosisResult(prognosis)),
      catchError(() => of({ prognoses: [] })),
    );

    const folketrygdPrognosisFromPublicPension$ = this.publicPensionService.getFolketrygdPrognosis().pipe(
      map((prognoses) =>
        prognoses.map(({ paymentPlanFolketrygd, statusMessages }) => [
          {
            payoutPlan: toArrayOrEmpty(paymentPlanFolketrygd),
            simulationStatus: toArrayOrEmpty(statusMessages)
              .map(mapStatusMessageToSimulationStatus)
              .map(toGraphSimulationStatus) as unknown as EngagementSimulationStatus, //TODO: fix this workaround by supporting arrays in the model
          },
        ]),
      ),
      map((prognoses) => prognoses.at(0)),
      map((prognosis) => ({ prognoses: prognosis ?? [] })),
    );

    return this.hasPublicPensionInStorebrandService.isPublicContext$.pipe(
      take(1),
      switchMap((isPublicContext) => (isPublicContext ? folketrygdPrognosisFromPublicPension$ : folketrygdFromNav$)),
    );
  }
  public prefetchPrognosesInRange(): Observable<void> {
    const prognosisSimParams$ = this.prognosisSimParams$().pipe(take(1));
    const fetchPrognoses$ = this.getPrognosisParamsRange(prognosisSimParams$).pipe(
      withLatestFrom(this.norskpensjonRequirementsService.getLatestHasAllRequirements()),
      filter(([_, hasAllRequirements]) => hasAllRequirements),
      map(([paramsRange]) =>
        paramsRange
          .map((params) => createFullWithdrawalVariables(params))
          .map((variables) => ignoreReturn(this.navQueriesService.getNavFolketrygdPrognosis(variables))),
      ),
      mergeMap((queries$) => merge(...queries$)),
    );

    return combineLatest([
      this.commonParametersService.isAgeHigherThanMaxSimAge$.pipe(first()),
      this.hasPublicPensionInStorebrandService.isPublicContext$,
    ]).pipe(
      switchMap(([isAgeHigherThanMaxSimAge, isPublicContext]) =>
        isAgeHigherThanMaxSimAge || isPublicContext ? EMPTY : fetchPrognoses$,
      ),
    );
  }

  protected _fetchEngagements(): Observable<AbstractNavEngagement[]> {
    const VALID_NAV_ENGAGEMENT_FROM_STB$ = of([new NavEngagementFromStb({})]);
    const VALID_NAV_ENGAGEMENT_FROM_NP$ = of([new NavEngagementFromNp({})]);
    const INVALID_ENGAGEMENT_FROM_NP$ = of([] as NavEngagementFromNp[]);

    return this.hasPublicPensionInStorebrandService.isPublicContext$.pipe(
      switchMap((isPublicContext) =>
        isPublicContext
          ? VALID_NAV_ENGAGEMENT_FROM_STB$
          : this.norskpensjonRequirementsService
              .getLatestHasAllRequirements()
              .pipe(
                mergeMap((hasAllRequirements) =>
                  hasAllRequirements ? VALID_NAV_ENGAGEMENT_FROM_NP$ : INVALID_ENGAGEMENT_FROM_NP$,
                ),
              ),
      ),
    );
  }

  protected prognosisSimParams$(): Observable<NavPrognosisParams> {
    return combineLatest([
      this.incomeService.getAnnualGrossIncomeOrDefault(0),
      this.commonParametersService.postPensionSimParams$,
      this.startPayoutAgeService.getStartPayoutAge(),
      this.simulationParametersQueriesService.getRetirementAgesQuery().pipe(catchError(() => of([]))),
      this.clientDataService.simulationParametersPartialWithdrawalEnable$.pipe(map(({ enable }) => enable)),
      this.clientDataService.simulationParameters$,
    ]).pipe(
      map(
        ([
          salary,
          { partTimePercentage: partTimePercentageFullWithdrawal },
          startPayoutAge,
          retirementAges,
          isPartialWithdrawal,
          simulationParameters,
        ]) => {
          if (isPartialWithdrawal) {
            const { startPayoutAgePartialWithdrawal, withdrawalPercentage, partTimePercentage } = simulationParameters;
            return {
              salary,
              partTimePercent: partTimePercentageFullWithdrawal,
              startPayoutAge,
              retirementAges,
              startPayoutAgePartialWithdrawal,
              withdrawalPercentage,
              partTimePercentage,
            };
          }

          return {
            salary,
            partTimePercent: partTimePercentageFullWithdrawal,
            startPayoutAge,
            retirementAges,
          };
        },
      ),
    );
  }

  protected composeFailedPrognosis(_: AbstractNavEngagement, isAgeHigherThanMaxSimAge?: boolean): Graph.Prognosis {
    const key = isAgeHigherThanMaxSimAge
      ? SimulationErrorCmsKey.AboveMaxAgeError
      : SimulationErrorCmsKey.UnknownNavError;

    return {
      simulationStatus: createGraphSimulationError(key),
    };
  }

  protected composeMissingPrognosisError(engagement: AbstractNavEngagement): MissingNavPrognosisError {
    return new MissingNavPrognosisError(engagement);
  }

  private getPrognosisParamsRange<T>(params$: Observable<T>): Observable<T[]> {
    return this.startPayoutAgeService.getStartPayoutAgeRangeForPrefetch().pipe(
      combineLatestWith(params$),
      take(1),
      map(([ages, params]) =>
        ages.map((age) => ({
          ...params,
          startPayoutAge: age,
        })),
      ),
    );
  }

  private fetchEngagementsOnRequirementsChange(): Observable<AbstractNavEngagement[]> {
    return this.norskpensjonRequirementsService.getHasAllRequirements().pipe(
      skip(1),
      switchMap(() => this.fetchEngagements()),
    );
  }
}

function toGraphSimulationStatus({ key, severity }: EngagementSimulationStatus): Graph.SimulationStatus {
  return { messageKey: key, severity };
}
