import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouteKey as ActionsRouteKey, routes as actionsRoutes } from "src/app/modules/actions/actions.routing";
import { composePaths } from "src/app/utils/router.utils";

@Component({
  selector: "app-large-actions-navigation-card",
  templateUrl: "./large-actions-navigation-card.component.html",
  styleUrls: ["./large-actions-navigation-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LargeActionsNavigationCardComponent {
  @Input()
  public splitTitle: boolean = false;

  public readonly actionsRoute = composePaths(actionsRoutes, ActionsRouteKey.Root);
}
