<app-action-card
  [title]="'actions.benefits.title' | fms"
  [icon]="'actions.benefits.icon' | fms"
  [content]="'actions.benefits.content' | fms"
  [outlinedPrimaryButton]="false"
  [primaryButton]="'actions.benefits.primaryButton' | fms"
  [action]="action"
  (primaryButtonClick)="openUrl()"
>
</app-action-card>
