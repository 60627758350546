import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DispatcherService, Signal } from "src/app/services/dispatcher.service";

@Component({
  templateUrl: "./refresh-modal.component.html",
})
export class RefreshModalComponent implements OnDestroy {
  public refresh(): void {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.refresh();
  }
}

@Component({
  selector: "app-refresh-modal",
  template: "",
})
export class RefreshModalHostComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly dispatcherService: DispatcherService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dispatcherService
      .subscribeTo$(Signal.ChunkLoadError, RefreshModalComponent.name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.openDialog());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private openDialog(): void {
    this.dialog.open(RefreshModalComponent);
  }
}
