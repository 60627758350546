import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, concatMap, firstValueFrom, map, of } from "rxjs";
import { MINIMUM_AGE_IN_MONTHS_FOR_WITHDRAWAL } from "src/app/constants/business.constants";
import { ActionIpsService } from "src/app/modules/features/pension-actions/components/action-ips/action-ips.service";
import { PensionPayoutsQueriesService } from "src/app/services/api/pension-payouts-queries.service";
import { CustomerService } from "src/app/services/customer.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { NavigateToPensionPlanService } from "src/app/services/navigate-to-pension-plan.service";
import { NavigateToService } from "src/app/services/navigate-to.service";
import { PensionPlanProgressService } from "src/app/services/pension-plan-progress.service";
import { PensionPlanProgressionState } from "src/app/services/pension-plan-progress.utils";
import { getIsFiniteNumber } from "src/app/utils/number";
import { composeRelativeUrlMergeQueryParams } from "src/app/utils/url";
import { getIsNotNullable, getIsNullable } from "src/app/utils/utils";
import { CardNavigation } from "./dashboard-card/dashboard-card.component";
import { ActionEpkService } from "../../../../modules/features/pension-actions/services/action-epk.service";

export enum NavigationType {
  Absolute, // Any url
  Relative, // Relative to the current domain, mostly to support advisor traffic
  RouterLink, // Internal to the app
}

@Component({
  selector: "app-dashboard-cards",
  templateUrl: "./dashboard-cards.component.html",
  styleUrls: ["./dashboard-cards.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardsComponent {
  public readonly pensionPlanProgressionState$: Observable<PensionPlanProgressionState>;
  public readonly PensionPlanProgressionState = PensionPlanProgressionState;
  public readonly showActions$: Observable<boolean>;
  public readonly showWithdrawalWebCard$: Observable<boolean>;
  public readonly showPayoutsWebCard$: Observable<boolean>;
  public readonly showIpsLumpSumCard$: Observable<boolean>;
  public readonly showIpsIsAboveSavingsLimitCard$: Observable<boolean>;
  public readonly noEpkOrEpksInStb$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly navigateToService: NavigateToService,
    private readonly navigateToPensionPlanService: NavigateToPensionPlanService,
    private readonly customerService: CustomerService,
    private readonly pensionPlanProgressService: PensionPlanProgressService,
    private readonly pensionPayoutsQueriesService: PensionPayoutsQueriesService,
    private readonly actionIpsService: ActionIpsService,
    private readonly engagementsService: EngagementsService,
    private readonly actionEpkService: ActionEpkService,
  ) {
    this.pensionPlanProgressionState$ = this.pensionPlanProgressService.pensionPlanProgressionState$;

    this.showActions$ = this.pensionPlanProgressionState$.pipe(
      map((state) => state !== PensionPlanProgressionState.New),
    );

    this.showWithdrawalWebCard$ = this.customerService.ageInMonths$.pipe(
      map((customerAgeInMonths) =>
        getIsFiniteNumber(customerAgeInMonths) ? customerAgeInMonths >= MINIMUM_AGE_IN_MONTHS_FOR_WITHDRAWAL : false,
      ),
    );

    this.showPayoutsWebCard$ = this.hasContractUnderPayout().pipe(
      concatMap((isUnderPayout) => (isUnderPayout ? of(true) : this.hasPensionPayouts())),
    );

    // ! Uncomment this when we want to show the IPS top-up card again
    // this.showIpsLumpSumCard$ = this.actionIpsService
    //   .getIpsAction()
    //   .pipe(map((ipsAction) => ipsAction === IpsAction.IncreaseIps));
    this.showIpsLumpSumCard$ = of(false);

    this.showIpsIsAboveSavingsLimitCard$ = this.actionIpsService
      .getFirstEngagement()
      .pipe(
        map((engagement) =>
          getIsNullable(engagement) ? false : this.actionIpsService.hasPeriodicAmountAboveMaximum(engagement),
        ),
      );

    this.noEpkOrEpksInStb$ = this.actionEpkService.getFirstEpkFromStorebrand().pipe(map((epk) => getIsNullable(epk)));
  }

  public onCardClick(navigation: CardNavigation): Promise<boolean> | ReturnType<typeof Window.prototype.open> {
    const navigationType = getNavigationType(navigation);
    return this.navigateTo(navigationType, navigation);
  }

  public navigateWithOnboarding(navigation: CardNavigation): void {
    this.navigateToService.navigateToUrlWithOnboarding(navigation.routerLink);
  }

  public navigateToUrlWithOnlyConsentOnboarding(navigation: CardNavigation): void {
    const url = navigation.routerLink ?? "";
    this.navigateToService.navigateToUrlWithOnlyConsentOnboarding(url);
  }

  public openPensionPlanLink(navigation: CardNavigation): void {
    const url = navigation.routerLink ?? "";
    return url === ""
      ? this.navigateToPensionPlanService.navigateToProgressUrl()
      : this.navigateToPensionPlanService.navigateTo(url);
  }

  public async openIpsLumpSumApp(): Promise<void> {
    const engagement = await firstValueFrom(this.actionIpsService.getFirstEngagement());
    return getIsNotNullable(engagement) ? this.actionIpsService.openLumpSumApp(engagement) : Promise.resolve();
  }

  public async openIpsMonthlySavingsApp(): Promise<void> {
    const engagement = await firstValueFrom(this.actionIpsService.getFirstEngagement());
    return getIsNotNullable(engagement) ? this.actionIpsService.openMonthlySavingsApp(engagement) : Promise.resolve();
  }

  private hasContractUnderPayout(): Observable<boolean> {
    return this.engagementsService.allEngagements$.pipe(
      map((engagements) =>
        engagements.some(
          (engagement) => "underPayout" in engagement.contract && engagement.contract.underPayout === true,
        ),
      ),
    );
  }

  private hasPensionPayouts(): Observable<boolean> {
    return this.pensionPayoutsQueriesService.getHasPensionPayouts().pipe(catchError(() => of(false)));
  }

  private navigateTo(navigationType: NavigationType, navigation: CardNavigation): Window | Promise<boolean> | null {
    const target = navigation.target ?? "_blank";

    switch (navigationType) {
      case NavigationType.Absolute:
        return window.open(navigation.href, target);
      case NavigationType.Relative:
        return window.open(composeRelativeUrlMergeQueryParams(navigation.href ?? ""), target);
      case NavigationType.RouterLink:
        return this.router.navigate([navigation.routerLink], {
          queryParamsHandling: "merge",
        });
      default:
        return null;
    }
  }
}

export function getNavigationType(navigation: CardNavigation): NavigationType {
  if (getIsAbsoluteUrl(navigation.href)) {
    return NavigationType.Absolute;
  }
  return getIsRelativePath(navigation.href) ? NavigationType.Relative : NavigationType.RouterLink;
}

function getIsAbsoluteUrl(url = ""): boolean {
  return url.match(/^https?:\/\//) !== null;
}

function getIsRelativePath(url = ""): boolean {
  return url.match(/^\//) !== null;
}
