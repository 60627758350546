import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Log } from "src/app/utils/log";
import { batcherPipe } from "src/app/utils/rxjs/pipes";
import { isStageP } from "src/app/utils/storebrand-staging";

@Injectable()
export class TrackIdErrorService {
  private readonly cache = new Map<HTMLElement, boolean>();
  private readonly errors$ = new Subject<HTMLElement>();

  constructor() {
    this.errors$.pipe(batcherPipe(this.errors$, 300, true)).subscribe((elements) => this.reportErrors(elements));
  }

  public add(element: HTMLElement): void {
    if (this.cache.get(element)) {
      return;
    }

    this.cache.set(element, true);
    this.errors$.next(element);
  }

  private reportErrors(elements: HTMLElement[]): void {
    if (!isStageP()) {
      Log.warn("Missing trackId:", elements);
    }
  }
}
