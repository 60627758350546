<div class="container">
  <app-breadcrumb-back-button [to]="frontPageRoute" trackId="needsPage-back"></app-breadcrumb-back-button>

  <app-page-title [text]="'needsPage.title' | fms" [description]="'needsPage.description' | fms"> </app-page-title>

  <h2 class="stb-mb2">{{ "needsPage.ingress.title" | fms }}</h2>
  <div class="full-page-width-limiter stb-mb6" [innerHTML]="'needsPage.ingress.html' | fms"></div>

  <app-needs-summary class="stb-mb4"></app-needs-summary>
  <app-annual-income-change-link class="stb-block stb-mb6"></app-annual-income-change-link>

  <h2 class="stb-mb2">{{ "needsPage.chart.title" | fms }}</h2>
  <app-ranged-needs-chart class="stb-block stb-mb2"></app-ranged-needs-chart>

  <div class="full-page-width-limiter stb-mb6" [innerHTML]="'needsPage.chart.note' | fms"></div>

  <div class="large-navigation-cards stb-mb6">
    <app-large-fund-calculator-navigation-card></app-large-fund-calculator-navigation-card>
    <app-large-actions-navigation-card></app-large-actions-navigation-card>
  </div>

  <app-disclaimer class="full-page-width-limiter"></app-disclaimer>
</div>
