import { advisorStageRegex, cmidRegex, userStageRegex } from "src/app/constants/regex.constants";
import { getFirstMatch } from "./regexp";

export enum StbStage {
  Produksjon = "PRODUKSJON",
  Localhost = "LOCALHOST",
  Utvikling = "UTVIKLING",
  Test = "TEST",
  TestStabil = "TEST_STABIL",
}

/**
 * Returns the StbStage the app is running at.
 * Due to user and advisor apps being hosted on different domains, the
 * function looks up the context from the url.
 *
 * Defaults to Produksjon.
 */
export function getStbStage(isAdvisor: boolean = isRequestedByAdvisor()): StbStage {
  if (isAdvisor) {
    return identifyStage(extractAdvisorStage());
  }
  return identifyStage(extractUserStage());
}

/**
 * Action wrapper around window.location
 * Necessary for testing in order to mock window.location output.
 */
export const windowLocationActions = {
  getHost: (): string => window.location.host,
  getQuerystring: (): string => window.location.search,
};

/** Replace with block-ignore once feature is supported https://github.com/prettier/prettier/issues/5287 */
/* prettier-ignore */
export function isStageLocalhost(): boolean { return getStbStage() === StbStage.Localhost; }
/* prettier-ignore */
export function isStageU(): boolean { return getStbStage() === StbStage.Utvikling; }
/* prettier-ignore */
export function isStageT(): boolean { return getStbStage() === StbStage.Test; }
/* prettier-ignore */
export function isStageTS(): boolean { return getStbStage() === StbStage.TestStabil; }
/* prettier-ignore */
export function isStageP(): boolean { return getStbStage() === StbStage.Produksjon; }
/* prettier-ignore */
export function isNotStageLocalhost(): boolean { return !isStageLocalhost(); }
/* prettier-ignore */
export function isNotStageU(): boolean { return !isStageU(); }
/* prettier-ignore */
export function isNotStageT(): boolean { return !isStageT(); }
/* prettier-ignore */
export function isNotStageTS(): boolean { return !isStageTS(); }
/* prettier-ignore */
export function isNotStageP(): boolean { return !isStageP(); }

export function isRequestedByAdvisor(): boolean {
  return cmidRegex.test(windowLocationActions.getQuerystring());
}

export function extractAdvisorStage(url?: string): string | undefined {
  return getFirstMatch(advisorStageRegex, url || windowLocationActions.getHost());
}

function extractUserStage(): string | undefined {
  return getFirstMatch(userStageRegex, windowLocationActions.getHost());
}

function identifyStage(stage: string | undefined): StbStage {
  switch (stage) {
    case "localhost":
      return StbStage.Localhost;
    case "u":
      return StbStage.Utvikling;
    case "t":
      return StbStage.Test;
    case "ts":
      return StbStage.TestStabil;
    default:
      return StbStage.Produksjon;
  }
}
