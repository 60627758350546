import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { delay } from "lodash-es";
import { Subscription } from "rxjs";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";

@Component({
  selector: "app-storebrand-only-checkbox",
  templateUrl: "./storebrand-only-checkbox.component.html",
  styleUrls: ["./storebrand-only-checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StorebrandOnlyCheckboxComponent implements OnInit, OnDestroy {
  public readonly switchControl = new FormControl<boolean>(false);
  private readonly subscription = new Subscription();

  constructor(private readonly storebrandOnlyService: StorebrandOnlyService) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.storebrandOnlyService.getIsEnabled().subscribe((value) => {
        this.switchControl.setValue(value, { emitEvent: false });
      }),
    );

    this.subscription.add(
      this.switchControl.valueChanges.subscribe((value) => {
        delay(() => {
          this.storebrandOnlyService.toggleByUser(Boolean(value));
        }, 100);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
