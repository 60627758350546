<app-action-card
  [title]="'actions.justifyTestament.title' | fms"
  [icon]="'actions.justifyTestament.icon' | fms"
  [content]="
    (isFramCustomer$ | async)
      ? ('actions.justifyTestament.FramCustomer.content' | fms)
      : ('actions.justifyTestament.content' | fms)
  "
  [outlinedPrimaryButton]="false"
  [primaryButton]="
    (isFramCustomer$ | async)
      ? ('actions.justifyTestament.FramCustomer.primaryButton' | fms)
      : ('actions.justifyTestament.primaryButton' | fms)
  "
  [action]="action"
  (primaryButtonClick)="openUrl()"
>
</app-action-card>
