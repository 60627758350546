import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { interval } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Nullable } from "src/app/utils/utils";
import { Log } from "../utils/log";

export enum Cookies {
  ActivateDevPanel = "activateDevPanel",
}

export type CookieValue = Nullable<string | number | boolean>;

const COOKIE_WATCH_INTERVAL = 1000;

@Injectable({
  providedIn: "root",
})
export class CookieService {
  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {}

  /**
   * Be aware of the max-age property of these cookies!
   */
  public setCookie(name: string, value: CookieValue): void {
    try {
      this.document.cookie = `${name}=${value};max-age=300;path=/;SameSite=None;Secure`;
    } catch (error) {
      Log.debug(`Failed to set ${name} cookie: `, error);
    }
  }

  public watch$(name: string): Observable<CookieValue | undefined> {
    return interval(COOKIE_WATCH_INTERVAL).pipe(
      map(() => this.document.cookie),
      distinctUntilChanged(),
      map((cookies) =>
        cookies
          .split(";")
          .map((s) => s.trim())
          .map((cookie) => cookie.split("="))
          .find(([key]) => key === name),
      ),
      map((value) => value?.[1]),
    );
  }
}
