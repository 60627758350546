import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  NORSKPENSJON_PROGNOSIS_NOT_ALLOWED_STATUS,
  NORSKPENSJON_PROGNOSIS_NOT_SUPPORTED_STATUS,
} from "../constants/business.constants";
import {
  isNavEngagementFromStb,
  isNorskpensjonEngagement,
  isPublicPensionEngagement,
} from "../utils/engagement.typeguards";
import { AnyEngagement } from "./engagements.service";

export interface EngagementWithLockedPayoutAgeDetector {
  getHasLockedPayoutAge(engagement: AnyEngagement): boolean;
}

export class NorskpensjonEngagementWithLockedPayoutAgeDetector implements EngagementWithLockedPayoutAgeDetector {
  public getHasLockedPayoutAge(engagement: AnyEngagement): boolean {
    if (!isNorskpensjonEngagement(engagement)) {
      return false;
    }

    const lockedPayoutAgeStatuses = [
      NORSKPENSJON_PROGNOSIS_NOT_SUPPORTED_STATUS,
      NORSKPENSJON_PROGNOSIS_NOT_ALLOWED_STATUS,
    ];

    return lockedPayoutAgeStatuses.some((status) => status === engagement.getCauseLackingGrading());
  }
}

export class NavEngagementWithLockedPayoutAgeDetector implements EngagementWithLockedPayoutAgeDetector {
  public getHasLockedPayoutAge(engagement: AnyEngagement): boolean {
    return isNavEngagementFromStb(engagement);
  }
}

export class PublicPensionEngagementWithLockedPayoutAgeDetector implements EngagementWithLockedPayoutAgeDetector {
  public getHasLockedPayoutAge(engagement: AnyEngagement): boolean {
    if (!isPublicPensionEngagement(engagement)) {
      return false;
    }

    const boundary = engagement.getPayoutAgeRangeBoundary();
    return Number.isFinite(boundary?.ceil) && boundary?.floor === boundary?.ceil;
  }
}

export const ENGAGEMENT_WITH_LOCKED_PAYOUT_AGE_DETECTORS = new InjectionToken<EngagementWithLockedPayoutAgeDetector[]>(
  "Engagement with locked payout age detectors",
  {
    providedIn: "root",
    factory: (): EngagementWithLockedPayoutAgeDetector[] => [
      new NorskpensjonEngagementWithLockedPayoutAgeDetector(),
      new PublicPensionEngagementWithLockedPayoutAgeDetector(),
    ],
  },
);

@Injectable({
  providedIn: "root",
})
export class EngagementWithLockedPayoutAgeDetectorService {
  constructor(
    @Inject(ENGAGEMENT_WITH_LOCKED_PAYOUT_AGE_DETECTORS)
    private readonly engagementWithLockedPayoutAgeValidators: EngagementWithLockedPayoutAgeDetector[],
  ) {}

  public getHasLockedPayoutAge(engagement: AnyEngagement): Observable<boolean> {
    return of(this.getIsSomeLockedPayoutAgeDetectorsTrue(engagement));
  }

  private getIsSomeLockedPayoutAgeDetectorsTrue(engagement: AnyEngagement): boolean {
    return this.engagementWithLockedPayoutAgeValidators
      .map((lockedPayoutAgeDetector) => lockedPayoutAgeDetector.getHasLockedPayoutAge(engagement))
      .some((result) => result === true);
  }
}
