import { interval, Observable, OperatorFunction, pipe } from "rxjs";
import { map, pairwise, startWith, switchMap, take } from "rxjs/operators";

/*
 * How often the ticker$ in rx-reusable-observables will emit while ticking
 */
export const TICKER_INTERVAL = 50;

/*
 * Max steps in ticker$ before reaching the endValue
 */
export const TICKER_MAX_STEPS = 10;

/**
 * A function that creates an observable that emits values between startValue and endValue
 * TICKER_MAX_STEPS determines total number of emissions before reaching the target endValue
 * Used for animating numbers increasing/decreasing
 *
 * @export
 * @param {number} startValue starting value of the ticker
 * @param {number} endValue ending value of the ticker
 * @returns {Observable<number>} an observable that emits TICKER_MAX_STEPS number of times; the final emission equals endValue
 */
export function ticker$(startValue: number, endValue: number): Observable<number> {
  return interval(TICKER_INTERVAL).pipe(
    take(TICKER_MAX_STEPS),
    map((count) => count + 1),
    map(
      (count) => startValue * ((TICKER_MAX_STEPS - count) / TICKER_MAX_STEPS) + endValue * (count / TICKER_MAX_STEPS),
    ),
  );
}

/**
 * Takes values in pairs and creates a ticker observables that gradually
 * returns values between the start and end, and finishes with the last
 * value in the pair it received. startWith(0) makes the pipe count up
 * from 0 on initial value
 */
export function tickerPipe(): OperatorFunction<number, number> {
  return pipe(
    startWith(0),
    pairwise(),
    switchMap(([beforeValue, afterValue]: [number, number]) => ticker$(beforeValue, afterValue)),
  );
}
