import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, OperatorFunction, pipe } from "rxjs";
import { map } from "rxjs/operators";
import { naiveObjectComparison } from "src/app/utils/utils";
import { memoizeObject$ } from "../utils/rxjs/select";
import { DisplayableHttpError } from "./errors.types";
import { FmsKey } from "./fms/fms";

interface ErrorText {
  title: FmsKey;
  message: FmsKey;
}

export enum ErrorCategory {
  Consent = "Consent",
  Customer = "Customer",
  Engagement = "Engagement",
  LifeExpectancy = "LifeExpectancy",
  PensionProduct = "PensionProduct",
  Prognosis = "Prognosis",
}

// Would name "Error", but have to avoid naming conflict with JavaScript's Error
export interface ErrorContainer {
  category: ErrorCategory;
  text: ErrorText;
}

export interface RoleError {
  message: string;
}

/**
 * ErrorsService handles all critical errors that result in a broken page.
 * 500-range errors from critical components such as CustomerMaster or
 * ApiHome, should be registered here in order for the error handling
 * component to display appropriate user feedback.
 *
 * Note that client 404 is handled by the router.
 */
@Injectable({
  providedIn: "root",
})
export class ErrorsService {
  public readonly errors$: Observable<ErrorContainer[]>;
  private readonly _criticalErrors$: BehaviorSubject<DisplayableHttpError[]>;
  private readonly _roleErrors$: BehaviorSubject<RoleError[]>;
  /**
   * @deprecated This store will soon be removed. The duplication of
   * error state to this store is subject to errors on its own, as there is
   * no sensible way of resetting the state. Error state should instead be
   * handled by the relevant service that does the fetching, while
   * ErrorsService maps up and exposes useful streams for consumers to act
   * on, acting as an intermediary communicator of all active errors in the
   * current state.
   */
  private readonly _errors$: BehaviorSubject<ErrorContainer[]>;

  constructor() {
    this._criticalErrors$ = new BehaviorSubject<DisplayableHttpError[]>([]);
    this._errors$ = new BehaviorSubject<ErrorContainer[]>([]);
    this._roleErrors$ = new BehaviorSubject<RoleError[]>([]);
    this.errors$ = memoizeObject$(this._errors$);
  }

  public get criticalErrors$(): Observable<DisplayableHttpError[]> {
    return this._criticalErrors$.asObservable();
  }

  public get hasCriticalErrors$(): Observable<boolean> {
    return this._criticalErrors$.pipe(map((arr) => arr.length > 0));
  }

  public get roleErrors$(): Observable<RoleError[]> {
    return this._roleErrors$.asObservable();
  }

  public get hasRoleErrors$(): Observable<boolean> {
    return this._roleErrors$.pipe(map((arr) => arr.length > 0));
  }

  public static categoryFilterPipe(
    categories: ErrorCategory | ErrorCategory[],
  ): OperatorFunction<ErrorContainer[], ErrorContainer[]> {
    return pipe(map((errors) => errors.filter(ErrorsService.filterErrorsByCategory(categories))));
  }

  private static filterErrorsByCategory(category: ErrorCategory | ErrorCategory[]): (error: ErrorContainer) => boolean {
    return (error: ErrorContainer): boolean => ([] as ErrorCategory[]).concat(category).includes(error.category);
  }

  public pushCriticalError({ status, statusText }: HttpErrorResponse, message: string): void {
    this._criticalErrors$.next(this._criticalErrors$.getValue().concat([{ status, statusText, message }]));
  }

  public clearCriticalErrors(): void {
    this._criticalErrors$.next([]);
  }

  public clearAllErrors(): void {
    this._errors$.next([]);
  }

  public clearErrorsByCategory(category: ErrorCategory | ErrorCategory[]): void {
    this._errors$.next(
      this._errors$.getValue().filter((error) => !ErrorsService.filterErrorsByCategory(category)(error)),
    );
  }

  public errorsByCategory$(category: ErrorCategory | ErrorCategory[]): Observable<ErrorContainer[]> {
    return this.errors$.pipe(map((errors) => errors.filter(ErrorsService.filterErrorsByCategory(category))));
  }

  public pushError(category: ErrorCategory, text: ErrorText): void {
    const errorContainer: ErrorContainer = { category, text };
    const isAlreadyReported = this._errors$.getValue().some((error) => naiveObjectComparison(error, errorContainer));

    if (!isAlreadyReported) {
      this._errors$.next(this._errors$.getValue().concat([errorContainer]));
    }
  }

  public hasErrorsInCategory$(categories: ErrorCategory | ErrorCategory[]): Observable<boolean> {
    return this.errors$.pipe(
      ErrorsService.categoryFilterPipe(categories),
      map((arr) => arr.length > 0),
    );
  }

  public pushRoleError(roleError: RoleError): void {
    this._roleErrors$.next(this._roleErrors$.getValue().concat([roleError]));
  }

  public hasNoErrorsInCategory$(categories: ErrorCategory | ErrorCategory[]): Observable<boolean> {
    return this.hasErrorsInCategory$(categories).pipe(map((val) => !val));
  }
}
