import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { EndpointService } from "src/app/services/endpoint.service";
import { select$ } from "src/app/utils/rxjs/select";
import { handleError } from "../utils/http";
import { BehaviorStore } from "../utils/rxjs/store";
import { Nullable } from "../utils/utils";
import { operations } from "./benefits-rest-api.types";

export const NO_BENEFITS: CustomerProgramsResponse = {
  programs: [],
};

export type CustomerProgramsResponse =
  operations["getBenefitProgramsForCustomer"]["responses"]["200"]["content"]["*/*"];

@Injectable({
  providedIn: "root",
})
export class BenefitsService {
  public readonly hasBenefits$: Observable<boolean>;

  private readonly rootObject$ = new BehaviorStore<CustomerProgramsResponse>(NO_BENEFITS);

  constructor(private readonly endpointService: EndpointService) {
    this.hasBenefits$ = select$(this.rootObject$, (result) => getIsBenefitCheckResult(result));
  }

  public fetchCustomerBenefits$(): Observable<CustomerProgramsResponse> {
    return this.endpointService.composeBenefitsCheckUrl().pipe(
      switchMap((url) =>
        this.endpointService.httpGet$<CustomerProgramsResponse>(url).pipe(
          map((data) => (getIsBenefitCheckResult(data) ? data : NO_BENEFITS)),
          tap((result) => this.rootObject$.next(result)),
          catchError((err) => handleError("BenefitsService::fetchCustomerBenefits", err, NO_BENEFITS)),
        ),
      ),
    );
  }
}

function getIsBenefitCheckResult(data: Nullable<unknown>): data is CustomerProgramsResponse {
  const programs = (data as Nullable<CustomerProgramsResponse>)?.programs;
  if (programs == null) {
    return false;
  }
  return programs.length > 0;
}
