import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { RouteKey, routes } from "src/app/modules/smart-account/routes";
import { SmartAccountInterestService } from "src/app/services/smart-account-interest.service";
import { composePaths } from "src/app/utils/router.utils";

@Component({
  selector: "app-smart-account-billboard",
  templateUrl: "./smart-account-billboard.component.html",
  styleUrls: ["./smart-account-billboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartAccountBillboardComponent {
  public readonly newCustomerInterest$: Observable<string>;
  public readonly smartAccountRoute = composePaths(routes, RouteKey.Root);

  constructor(private readonly smartAccountInterestService: SmartAccountInterestService) {
    this.newCustomerInterest$ = this.smartAccountInterestService.getNewCustomerFormattedInterest();
  }
}
