import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Breadcrumb, BreadcrumbsService } from "src/app/services/breadcrumbs.service";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  public readonly breadcrumbs$ = this.breadcrumbsService.getBreadcrumbs();
  public readonly isVisible$ = this.getIsVisible();

  constructor(private readonly breadcrumbsService: BreadcrumbsService) {}

  private getIsVisible(): Observable<boolean> {
    return this.breadcrumbs$.pipe(map(getIsNoneHideAll));
  }
}

function getIsNoneHideAll(breadcrumbs: Breadcrumb[]): boolean {
  return breadcrumbs.every((breadcrumb) => !breadcrumb.hideAll);
}
