import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { WINDOW } from "src/app/tokens";
import { createSavingsWebUrl } from "src/app/utils/createSavingsWebUrl";
import { isPublicPensionEngagement } from "src/app/utils/engagement.typeguards";
import { getHasContractNavigation } from "src/app/utils/engagement.utils";
import { getEngagementNameToUseInSentence } from "src/app/utils/engagementName";
import { filterByPredicatesOrUndefined } from "src/app/utils/rxjs/pipes";
import { Nullable, getIsNotNullable } from "src/app/utils/utils";
import { ContractActionBuilder, ExternalLink } from "../contract-actions.types";

@Injectable()
export class LinkToDetailPageActionBuilder implements ContractActionBuilder {
  constructor(
    private readonly fmsService: FmsService,
    private readonly keycloakService: KeycloakService,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {}

  public predicate(anyEngagement: AnyEngagement): boolean {
    return isPublicPensionEngagement(anyEngagement)
      ? getHasPublicPensionContractDetailsLink(anyEngagement)
      : getHasContractDetailsLink(anyEngagement);
  }

  public async build(anyEngagement: AnyEngagement): Promise<ExternalLink> {
    const [linkLabel, linkUrl, linkTrackId] = await Promise.all([
      this.getLinkLabel(),
      this.getLinkUrl(anyEngagement),
      this.getLinkTrackId(anyEngagement),
    ]);

    return {
      linkLabel,
      linkUrl,
      linkTrackId,
    };
  }

  private getLinkLabel(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("contractActions.linkToDetailPage.linkLabel"));
  }

  private async getLinkUrl(anyEngagement: AnyEngagement): Promise<string> {
    const detailsLink = isPublicPensionEngagement(anyEngagement)
      ? getPublicPensionContractDetailsLink(anyEngagement)
      : getContractDetailsLink(anyEngagement);
    const cmidOrUndefined = await this.getCmidOrUndefined();

    return createSavingsWebUrl(detailsLink, {
      exitUrl: this.window.location.href,
      cmid: cmidOrUndefined,
    });
  }

  private async getLinkTrackId(anyEngagement: AnyEngagement): Promise<string> {
    const productCode = anyEngagement.getProduct()?.productCode;
    const formattedContractType = (await getEngagementNameToUseInSentence(anyEngagement)).replace(" ", "");
    return Promise.resolve(`see-details-of-${productCode ?? formattedContractType}-contract`);
  }

  private getCmidOrUndefined(): Promise<string | undefined> {
    const cmidOrUndefined$ = this.keycloakService.cmid.user$.pipe(
      filterByPredicatesOrUndefined([this.keycloakService.isAdvisorContext$]),
    );

    return firstValueFrom(cmidOrUndefined$);
  }
}

function getContractDetailsLink(engagement: AnyEngagement): Nullable<string> {
  if (getHasContractNavigation(engagement)) {
    return engagement.contract?.contractNavigation?.contractDetailsLink;
  }

  return undefined;
}

function getHasContractDetailsLink(engagement: AnyEngagement): boolean {
  return getIsNotNullable(getContractDetailsLink(engagement));
}

function getHasPublicPensionContractDetailsLink(engagement: PublicPensionEngagement): boolean {
  return getIsNotNullable(getPublicPensionContractDetailsLink(engagement));
}

function getPublicPensionContractDetailsLink(engagement: PublicPensionEngagement): Nullable<string> {
  return engagement.contract
    .map((contract) => contract.contractNavigation?.contractDetailsLink)
    .filter(getIsNotNullable)
    .at(0);
}
