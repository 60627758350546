import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { gql } from "apollo-angular";
import { Observable } from "rxjs";
import { ResponseHeaders } from "src/app/modules/graphql-clients/local-schema/response-headers";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import {
  ALIS_CONTRACT_FIELDS_FRAGMENT,
  EKSTRAPENSJON_EMPLOYMENT_FIELDS_FRAGEMENT,
  EPK_CONTRACT_FIELDS_FRAGMENT,
  EPK_EMPLOYMENT_CONTRACT_FIELDS,
  EPK_FLEKSIBEL_CONTRACT_FIELDS,
  EPK_FLEKSIBEL_PKB_CONTRACT_FIELDS,
  FMI_CONTRACT_FIELDS,
  FRIPOLISE_CONTRACT_FIELDS,
  HYBRID_MED_GARANTI_CONTRACT_FIELDS,
  HYBRID_MED_INVESTERINGSVALG_CONTRACT_FIELDS,
  HYBRID_PENSJONSBEVIS_CONTRACT_FIELDS,
  LINK_CONTRACT_FIELDS_FRAGMENT,
  PENSION_FUND_ITP_CONTRACT_FIELDS_FRAGMENT,
  PENSION_FUND_UNFUNDED_YTP_UNDER_PAYOUT_FIELDS,
  PENSION_FUND_YTP_CONTRACT_FIELDS_FRAGMENT,
  PKB_CONTRACT_FIELDS_FRAGMENT,
  YTP_CONTRACT_FIELDS,
} from "./graphql-query-fragments";

type Engagement = Pick<
  Graph.SavingsEngagement,
  | "askAccounts"
  | "ekstrapensjonContracts"
  | "ekstrapensjonEmploymentContracts"
  | "fondskontoLinkContracts"
  | "garantiContracts"
  | "ipaLinkContracts"
  | "kollektivLivrenteContracts"
  | "ipoContracts"
  | "ipsContracts"
  | "livrenteLinkContracts"
  | "epkContracts"
  | "pkbContracts"
  | "epkFleksibelContracts"
  | "epkFleksibelPkbContracts"
  | "epkEmploymentContracts"
  | "fmiContracts"
  | "fmiMedGarantiContracts"
  | "ytpContracts"
  | "fripoliseContracts"
  | "hybridMedInvesteringsvalgContracts"
  | "hybridMedGarantiContracts"
  | "hybridPensjonsbevisContracts"
  | "ipaAlisContracts"
  | "kapitalforsikringAlisContracts"
  | "livrenteAlisContracts"
  | "pensionFundUnfundedItpContracts"
  | "pensionFundUnfundedYtpContracts"
  | "pensionFundYtpContracts"
  | "pensionFundUnfundedYtpUnderPayoutContracts"
  | "innskuddspensjonMedGarantiContracts"
>;

export interface AllContractsQuery extends Pick<Graph.Query, "savingsEngagement"> {
  headers: ResponseHeaders;
  savingsEngagement: Engagement;
}

export const GET_SAVINGS_AND_PENSION_CONTRACTS_QUERY = gql`
  query getSavingsAndPensionContractsQuery {
    headers @client {
      correlationId
    }
    savingsEngagement {
      askAccounts {
        id
        accountId
        accountName
        productNameFmsKey
        askTaxDetails {
          depositInNok
        }
        holdings {
          cashHoldings {
            amount
          }
          fundHoldingsTotal {
            marketValue {
              amount
            }
            unrealizedProfit {
              amount
            }
          }
        }
        contractNavigation {
          contractDetailsLink
        }
        savingAgreements {
          id
          activeState {
            active
          }
        }
      }
      ekstrapensjonContracts {
        ...LinkContractFields
      }
      ekstrapensjonEmploymentContracts {
        ...EkstrapensjonEmploymentContractFields
      }
      fondskontoLinkContracts {
        ...LinkContractFields
      }
      garantiContracts {
        ...LinkContractFields
      }
      ipaLinkContracts {
        ...LinkContractFields
      }
      kollektivLivrenteContracts {
        ...LinkContractFields
      }
      ipoContracts {
        ...LinkContractFields
      }
      ipsContracts {
        ...LinkContractFields
      }
      livrenteLinkContracts {
        ...LinkContractFields
      }
      ipaAlisContracts {
        ...AlisContractFields
      }
      kapitalforsikringAlisContracts {
        ...AlisContractFields
      }
      livrenteAlisContracts {
        ...AlisContractFields
      }
      epkContracts {
        ...EpkContractFields
      }
      pkbContracts {
        ...PkbContractFields
        contractNavigation {
          contractDetailsLink
        }
      }
      epkFleksibelContracts {
        ...EpkFleksibelContractFields
      }
      epkFleksibelPkbContracts {
        ...EpkFleksibelPkbContractFields
      }
      epkEmploymentContracts {
        ...EpkEmploymentContractFields
      }
      fmiContracts {
        ...FmiContractFields
        contractNavigation {
          contractDetailsLink
        }
      }
      fmiMedGarantiContracts {
        ...FripoliseContractFields
      }
      ytpContracts {
        ...YtpContractFields
      }
      fripoliseContracts {
        ...FripoliseContractFields
      }
      hybridMedInvesteringsvalgContracts {
        ...HybridMedInvesteringsvalgContractFields
      }
      hybridMedGarantiContracts {
        ...HybridMedGarantiContractFields
      }
      hybridPensjonsbevisContracts {
        ...HybridPensjonsbevisContractFields
      }
      pensionFundYtpContracts {
        ...PensionFundYtpContractFields
      }
      pensionFundUnfundedYtpContracts {
        ...PensionFundYtpContractFields
      }
      pensionFundUnfundedItpContracts {
        ...PensionFundItpContractFields
      }
      pensionFundUnfundedYtpUnderPayoutContracts {
        ...PensionFundUnfundedYtpUnderPayoutContractFields
      }
      innskuddspensjonMedGarantiContracts {
        ...FripoliseContractFields
      }
    }
  }
  ${ALIS_CONTRACT_FIELDS_FRAGMENT}
  ${EPK_CONTRACT_FIELDS_FRAGMENT}
  ${PKB_CONTRACT_FIELDS_FRAGMENT}
  ${EPK_FLEKSIBEL_CONTRACT_FIELDS}
  ${EPK_FLEKSIBEL_PKB_CONTRACT_FIELDS}
  ${EPK_EMPLOYMENT_CONTRACT_FIELDS}
  ${FMI_CONTRACT_FIELDS}
  ${YTP_CONTRACT_FIELDS}
  ${FRIPOLISE_CONTRACT_FIELDS}
  ${HYBRID_MED_INVESTERINGSVALG_CONTRACT_FIELDS}
  ${HYBRID_MED_GARANTI_CONTRACT_FIELDS}
  ${HYBRID_PENSJONSBEVIS_CONTRACT_FIELDS}
  ${EKSTRAPENSJON_EMPLOYMENT_FIELDS_FRAGEMENT}
  ${LINK_CONTRACT_FIELDS_FRAGMENT}
  ${PENSION_FUND_YTP_CONTRACT_FIELDS_FRAGMENT}
  ${PENSION_FUND_ITP_CONTRACT_FIELDS_FRAGMENT}
  ${PENSION_FUND_UNFUNDED_YTP_UNDER_PAYOUT_FIELDS}
`;

@Injectable({
  providedIn: "root",
})
export class SavingsAndPensionQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getContracts(): Observable<ApolloQueryResult<AllContractsQuery>> {
    return this.savingsGraphqlClient.query<AllContractsQuery>({
      query: GET_SAVINGS_AND_PENSION_CONTRACTS_QUERY,
      errorPolicy: "all",
    });
  }
}
