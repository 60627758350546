import { ChangeDetectionStrategy, Component } from "@angular/core";
import { firstValueFrom, Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import {
  getEngagementDescription,
  getEngagementTitle,
  getEngagementTotalPayout,
  InitiativeEngagementItem,
} from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives.utils";
import { EngagementsService } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { isOtherPensionEngagement } from "src/app/utils/engagement.typeguards";

@Component({
  selector: "app-offentlig-tjenestepensjon-engagement-receipt",
  templateUrl: "./offentlig-tjenestepensjon-engagement-receipt.component.html",
  styleUrls: ["./offentlig-tjenestepensjon-engagement-receipt.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffentligTjenestepensjonEngagementReceiptComponent {
  public items$ = this.getItems();

  constructor(
    private readonly fmsService: FmsService,
    private readonly engagementsService: EngagementsService,
  ) {}

  private getItems(): Observable<InitiativeEngagementItem[]> {
    const publicPensionEngagements$ = this.engagementsService.allEngagements$.pipe(
      map((allEngagements) =>
        allEngagements
          .filter(isOtherPensionEngagement)
          .filter((otherPensionEngagement) => otherPensionEngagement.isPublicPensionFromNorskPensjon()),
      ),
    );

    return publicPensionEngagements$.pipe(switchMap((el) => this.getInitiativeEngagementItems(el)));
  }

  private async getInitiativeEngagementItems(
    engagements: OtherPensionEngagement[],
  ): Promise<InitiativeEngagementItem[]> {
    const iconSrc = await firstValueFrom(
      this.fmsService.translateAsync<string>("offentligTjenestepensjonEngagementReceipt.tableIcon"),
    );

    return engagements.map((engagement) => ({
      title: getEngagementTitle(engagement),
      description: getEngagementDescription(engagement),
      iconSrc,
      values: [getEngagementTotalPayout(engagement)],
    }));
  }
}
