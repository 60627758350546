import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

export interface CardNavigation {
  routerLink: string;
  href?: string;
  target?: string;
}
export interface DashboardCard {
  text: string;
  illustration: string;
  navigation: CardNavigation;
}

@Component({
  selector: "app-dashboard-card",
  templateUrl: "./dashboard-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardComponent {
  @Input()
  public readonly dashboardCard!: DashboardCard | null;
  @Input()
  public readonly trackId!: string;
  @Output()
  public readonly cardClick = new EventEmitter<CardNavigation>();
}
