import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { combineLatest, firstValueFrom, MonoTypeOperatorFunction, skip, Subject, takeUntil } from "rxjs";
import { filter, first, map, tap } from "rxjs/operators";
import { PublicPensionPrognosisParametersStaticService } from "src/app/services/api/public-pension-prognosis-parameters-static.service";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { discretePercentageSanitizerPipe, generalInputPipe } from "src/app/utils/rxjs/pipes";
import { getIsNullable } from "src/app/utils/utils";

const DEFAULT_LABEL = "simulationPartTimePercent.age.label";

@Component({
  selector: "app-part-time-percent-form-field",
  templateUrl: "./part-time-percent-form-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartTimePercentFormFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public tip?: string;
  @Input()
  public showTooltip? = false;
  @Input()
  public readonly disabled = false;

  @Output()
  public valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  private readonly formGroup?: UntypedFormGroup;

  public label: string = DEFAULT_LABEL;
  public formControl = new UntypedFormControl("", [Validators.required]);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly commonParametersService: CommonParametersService,
    private readonly pensionPlanService: PensionPlanService,
    private readonly publicPensionPrognosisParametersStaticService: PublicPensionPrognosisParametersStaticService,
  ) {}

  public ngOnInit(): void {
    if (this.formGroup) {
      this.formGroup.addControl("partTimePercent", this.formControl);
    }

    this.changePostPensionPartTimePercentOnChange();
    this.prefillPostPensionPartTimePercentOnInit();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public ngOnChanges(): void {
    if (this.disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  /**
   * First emission is triggered by {@link prefillPostPensionPartTimePercentOnInit} which is
   * not an user action, there we skip first emission so that pension plan overview history is empty
   */
  private changePostPensionPartTimePercentOnChange(): void {
    const postPensionPartTimeChanges$ = this.formControl.valueChanges.pipe(
      tap(() => this.valid.emit(this.formControl.valid)),
      filter(() => this.formControl.valid),
      generalInputPipe(discretePercentageSanitizerPipe),
      skipFirstEmissionNotTriggeredByUser(),
      takeUntil(this.destroy$),
    );

    const currentPublicPensionFullTimeMainSalary$ = this.publicPensionPrognosisParametersStaticService
      .getStaticPensionPrognosisParameters()
      .pipe(
        map((prognosisParameters) => {
          const { mainSalary, currentWorkingPercentage } = prognosisParameters;
          if (getIsNullable(mainSalary) || getIsNullable(currentWorkingPercentage)) {
            return undefined;
          }

          return calculateFullTimeSalary(mainSalary, currentWorkingPercentage);
        }),
      );

    combineLatest([postPensionPartTimeChanges$, currentPublicPensionFullTimeMainSalary$.pipe(first())]).subscribe(
      ([postPensionPartTime, fullTimeMainSalary]) => {
        if (fullTimeMainSalary) {
          const calculatedFutureSalary = calculateFutureSalary(fullTimeMainSalary, postPensionPartTime);
          this.pensionPlanService.changePostPensionPartTimePercentAndFutureSalary(
            postPensionPartTime,
            calculatedFutureSalary,
          );
        } else {
          this.pensionPlanService.changePostPensionPartTimePercentAndFutureSalary(postPensionPartTime);
        }
      },
    );
  }

  private async prefillPostPensionPartTimePercentOnInit(): Promise<void> {
    const partTimePercent = await firstValueFrom(this.commonParametersService.postPensionPartTimePercent$);

    if (partTimePercent != null) {
      this.formControl.setValue(partTimePercent + "%");
    }

    this.valid.emit(this.formControl.valid);
  }
}

function skipFirstEmissionNotTriggeredByUser<T>(): MonoTypeOperatorFunction<T> {
  return skip(1);
}

export function calculateFullTimeSalary(currentMainSalary: number, currentWorkingPercentage: number): number {
  return currentMainSalary * (currentWorkingPercentage / 100);
}

export function calculateFutureSalary(fullTimeMainSalary: number, postPensionPartTimePercentage: number): number {
  return Math.round((fullTimeMainSalary / 100) * postPensionPartTimePercentage);
}
