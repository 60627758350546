import { Inject, Injectable } from "@angular/core";
import { Observable, catchError, combineLatest, map, of, switchMap } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";
import { CONTRACT_ACTIONS_ERROR_REPORTER_TOKEN, CONTRACT_ACTION_BUILDERS_TOKEN } from "./contract-actions.tokens";
import { ContractAction, ContractActionBuilder, ContractActionsErrorReporter } from "./contract-actions.types";

@Injectable()
export class ContractActionsService {
  constructor(
    @Inject(CONTRACT_ACTION_BUILDERS_TOKEN)
    private readonly builders: ContractActionBuilder[],
    @Inject(CONTRACT_ACTIONS_ERROR_REPORTER_TOKEN)
    private readonly errorReporter: ContractActionsErrorReporter,
  ) {}

  public getActions(engagement: AnyEngagement): Observable<ContractAction[]> {
    if (this.builders.length === 0) {
      return of([]);
    }

    const listOfActionsObservables = this.builders.map((builder) =>
      getPredicate(builder.predicate(engagement)).pipe(
        switchMap(async (predicate) => (predicate ? await builder.build(engagement) : [])),
        catchError((error) => {
          this.errorReporter(error);
          return of([]);
        }),
      ),
    );

    return combineLatest(listOfActionsObservables).pipe(
      map((actionOrActionsArray) => {
        return addDefaultContractActionFields(actionOrActionsArray.flat());
      }),
    );
  }
}

function addDefaultContractActionFields(actions: ContractAction[]): ContractAction[] {
  const defaultFields: Pick<ContractAction, "linkIcon"> = {
    linkIcon: "arrow-right",
  };

  return actions.map((action) => ({ ...defaultFields, ...action }));
}

function getPredicate(maybePredicate$: boolean | Observable<boolean>): Observable<boolean> {
  return maybePredicate$ instanceof Observable ? maybePredicate$ : of(maybePredicate$);
}
