import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { FmsService } from "src/app/services/fms.service";

@Injectable()
export class ActionWindowService {
  constructor(private readonly fmsService: FmsService) {}

  public async open(fmsKeyUrl: string): Promise<void> {
    const url = await firstValueFrom(this.fmsService.translateAsync<string>(fmsKeyUrl));

    window.open(url, "_blank");
  }
}
