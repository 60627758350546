import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";

@Component({
  selector: "app-engagement-details-afp",
  templateUrl: "./engagement-details-afp.component.html",
  styleUrls: ["./engagement-details-afp.component.scss", "../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementBodyAfpComponent implements EngagementBody {
  @Input()
  public engagement!: AfpEngagement;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;
  @Input()
  public showDescription = true;
}
