import { Injectable } from "@angular/core";
import { combineLatest, filter, first, firstValueFrom, map, Observable, tap } from "rxjs";
import { getSimulationParametersForUnsavedEngagements } from "src/app/modules/shared/services/withdrawal-profiles/get-simulation-parameters-for-unsaved.engagements";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { updateManySimulationParametersByEngagement } from "src/app/services/customer-supplied-data/client-data.utils";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { WithdrawalProfile, WithdrawalProfileId } from "./withdrawal-profiles.service";

@Injectable()
export class CustomPayoutAgeProfile implements WithdrawalProfile {
  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly engagementsService: EngagementsService,
    private readonly fmsService: FmsService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly profileService: ProfileService,
  ) {}

  public getId(): WithdrawalProfileId {
    return WithdrawalProfileId.CustomPayoutAge;
  }

  public getDescription(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("withdrawalProfile.customPayoutAge.description"));
  }

  public getTrackId(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("withdrawalProfile.customPayoutAge.trackId"));
  }

  public async changeProfile(): Promise<void> {
    this.clientDataService.updateClientData({
      simulationParametersByEngagementEnable: true,
      simulationParametersPartialWithdrawalEnable: false,
    });
  }

  public getProfileWhenActive(): Observable<WithdrawalProfile> {
    return this.clientDataService.simulationParametersByEngagementEnable$.pipe(
      filter(({ enable }) => enable),
      tap(() => {
        this.saveUnsavedSimulationParametersByEngagement();
      }),
      map(() => this),
    );
  }

  private saveUnsavedSimulationParametersByEngagement(): void {
    combineLatest([
      this.engagementsService.allEngagements$,
      this.clientDataService.simulationParametersByEngagement$,
      this.startPayoutAgeService.getStartPayoutAge(),
      this.profileService.hasAfp$,
    ])
      .pipe(first())
      .subscribe(([engagements, previousSimulationParams, startPayoutAge, hasAfp]) => {
        const unsavedSimulationParameters = getSimulationParametersForUnsavedEngagements(
          engagements,
          previousSimulationParams,
          startPayoutAge,
          hasAfp,
        );

        if (unsavedSimulationParameters.length > 0) {
          const simulationParametersByEngagement = updateManySimulationParametersByEngagement(
            unsavedSimulationParameters,
            previousSimulationParams,
          );
          this.clientDataService.updateClientData({
            simulationParametersByEngagement,
          });
        }
      });
  }
}
