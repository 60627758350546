import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { BenefitsService } from "src/app/services/benefits.service";
import { FmsService } from "src/app/services/fms.service";
import { SmartAccountService, SmartAccountState } from "src/app/services/smart-account.service";
import { getIsNotNullable } from "src/app/utils/utils";

enum LinkKey {
  DocumentArchive = "archive",
  Payouts = "payouts",
  SmartAccount = "smartAccount",
  Benefit = "benefit",
  FrequentAskedQuestions = "moreAboutPension",
  ContactUs = "contactUs",
}

interface Link {
  key: LinkKey;
  url: string;
  text: string;
  order: number;
  trackId: string;
}

@Component({
  selector: "app-dashboard-link-list",
  templateUrl: "./dashboard-link-list.component.html",
  styleUrls: ["./dashboard-link-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLinkListComponent {
  public readonly linkList$: Observable<Link[]>;

  constructor(
    private readonly fmsService: FmsService,
    private readonly router: Router,
    private readonly benefitsService: BenefitsService,
    private readonly smartAccountService: SmartAccountService,
  ) {
    this.linkList$ = this.getLinkList();
  }

  public getLinkList(): Observable<Link[]> {
    const smartAccount$ = this.fmsService.translateAsync<Link>("dashboard.linkList.smartAccount");
    const benefits$ = this.fmsService.translateAsync<Link>("dashboard.linkList.benefit");
    const moreAboutPension$ = this.fmsService.translateAsync<Link>("dashboard.linkList.moreAboutPension");
    const documentArchive$ = this.fmsService.translateAsync<Link>("dashboard.linkList.documentArchive");
    const contactUs$ = this.fmsService.translateAsync<Link>("dashboard.linkList.contactUs");
    const payouts$ = this.fmsService.translateAsync<Link>("dashboard.linkList.payouts");

    const showSmartAccountLink$ = this.smartAccountService.smartAccountState$.pipe(
      map((state) =>
        [
          SmartAccountState.AccountCreationInProgress,
          SmartAccountState.ActionRequired,
          SmartAccountState.HasMultipleAccounts,
          SmartAccountState.HasOneAccount,
        ].includes(state),
      ),
    );

    return combineLatest([
      showSmartAccountLink$,
      this.benefitsService.hasBenefits$,
      smartAccount$,
      benefits$,
      moreAboutPension$,
      documentArchive$,
      contactUs$,
      payouts$,
    ]).pipe(
      map(
        ([
          showSmartAccountLink,
          hasBenefits,
          smartAccount,
          benefits,
          moreAboutPension,
          documentArchive,
          contactUs,
          payouts,
        ]) =>
          [
            showSmartAccountLink ? smartAccount : undefined,
            hasBenefits ? benefits : undefined,
            moreAboutPension,
            documentArchive,
            contactUs,
            payouts,
          ]
            .filter(getIsNotNullable)
            .sort(orderSorter),
      ),
    );
  }

  public onLinkClick(routerlinkOrUrl: string): Promise<boolean> | Window | null {
    if (routerlinkOrUrl.includes("https")) {
      return window.open(routerlinkOrUrl, "_blank");
    }

    return this.router.navigate([routerlinkOrUrl], {
      queryParamsHandling: "merge",
    });
  }
}

function orderSorter(a: Link, b: Link): number {
  return a.order - b.order;
}
