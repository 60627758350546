<p>
  {{ purpose }}

  <button
    class="opt-button mat-typography"
    *appLet="consentValue$ | async as consentValue"
    (click)="setConsentValue(!consentValue)"
    data-trackId="dashboardPurchaseAssistanceConsent-optToggle"
  >
    <ng-container *ngIf="consentValue; else optIn">{{ "purchaseAssistanceConsent.optOut" | fms }}</ng-container>
    <ng-template #optIn>{{ "purchaseAssistanceConsent.optIn" | fms }}</ng-template>
  </button>
</p>
