import { SimulationSeverity } from "src/app/services/api/savings-and-pension-queries.types";
import { EngagementSimulationStatus } from "../models/pension.model";
import { isAfpEngagement, isNavFromNpEngagement } from "./engagement.typeguards";
import { AnyEngagement } from "../services/engagements.service";
import { MIN_SIMULATION_AGE } from "../constants/business.constants";

export enum AlertType {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Success = "success",
}

export interface Alert {
  fmsHeading?: string;
  fmsText: string;
  type: AlertType;
}

interface ShortAndLongAlert {
  shortAlert: Alert;
  longAlert: Alert;
}

export function simStatusToAlert(status: EngagementSimulationStatus | undefined): Alert | undefined {
  const severity = status?.severity;

  if (severity === SimulationSeverity.SimulationError) {
    return {
      fmsText: "engagement.message.shortError",
      type: AlertType.Error,
    };
  }
  if (severity === SimulationSeverity.BusinessError || severity === SimulationSeverity.NotAllowed) {
    return {
      fmsText: "engagement.message.shortWarning",
      type: AlertType.Warning,
    };
  }

  return undefined;
}

export function getFolketrygdMayBeUnderPaymentAlert(
  engagement: AnyEngagement,
  userAge: number | undefined,
): ShortAndLongAlert | undefined {
  const isTheRightEngagement = isAfpEngagement(engagement) || isNavFromNpEngagement(engagement);
  const showDisclaimer = isTheRightEngagement && canHaveStarterWithdrawalOfFolketrygd(userAge);

  if (showDisclaimer) {
    return {
      shortAlert: { fmsText: "engagement.message.shortInfoText.folketrygdMayBeUnderPayment", type: AlertType.Info },
      longAlert: {
        fmsHeading: "engagement.message.longInfoHeading.folketrygdMayBeUnderPayment",
        fmsText: "engagement.message.longInfoText.folketrygdMayBeUnderPayment",
        type: AlertType.Info,
      },
    };
  }

  return undefined;
}

function canHaveStarterWithdrawalOfFolketrygd(userAge: number | undefined): boolean {
  return userAge != null && userAge >= MIN_SIMULATION_AGE;
}
