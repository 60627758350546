import { Injectable } from "@angular/core";
import { produce } from "immer";
import { isEmpty } from "lodash-es";
import {
  Observable,
  catchError,
  combineLatest,
  delay,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  tap,
  zip,
} from "rxjs";
import {
  DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE,
  PART_TIME_EXPECTED_FUTURE_INCOME_MAX_AGE,
} from "src/app/constants/business.constants";
import { PrognosisTypeOf } from "src/app/models/engagements/generic-engagement.model";
import { NAV_ENGAGEMENT_ID, NavEngagementFromStb } from "src/app/models/engagements/nav-engagement.model";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import { PublicPensionQueriesService } from "src/app/services/api/public-pension-queries.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  PrognosisQueryResult,
  ServiceConfig,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import {
  SimulationErrorCmsKey,
  createGraphSimulationMessageError,
} from "src/app/services/prognoses-services/simulation-result.creators";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { getIsNotEmpty, toArrayOrEmpty } from "src/app/utils/array";
import { getNumberWithinBounds } from "src/app/utils/getNumberWithinBounds";
import { handleError } from "src/app/utils/http";
import { getIsFiniteNumber } from "src/app/utils/number";
import { MissingPublicPensionPrognosisError } from "src/app/utils/prognosis-errors";
import { Nullable, getIsNotNullable, getIsNullable } from "src/app/utils/utils";
import { PublicPensionProgosisParametersDynamicService } from "../api/public-pension-prognosis-parameters-dynamic.service";
import { PublicPensionPrognosisParametersStaticService } from "../api/public-pension-prognosis-parameters-static.service";
import { SimulationParametersQueriesService } from "../api/simulation-parameters-queries.service";
import { PublicPensionConsentsPrognosisTriggerService } from "./public-pension-consents-prognosis-trigger.service";
import { toAgeAndMonthOffset } from "./savings-and-pension.service";

type PublicPensionPrognosisParams = Graph.PublicPensionInput &
  Pick<Graph.FullWithdrawalInput, "startMonthOffset"> &
  Omit<Graph.PublicPensionPrognosisParameters, "__typename"> &
  Required<Pick<CustomerSuppliedData.SimulationParameters, "startPayoutAge">>;

type FetchPrognosesParams = CustomerSuppliedData.SimulationParametersByEngagement<
  PublicPensionPrognosisParams & { workPercentage?: number } & {
    futureSalary?: number;
  }
>[1];

export const PUBLIC_PENSION_CONFIG: ServiceConfig = {
  name: "PublicPensionService",
  fmsKey: "graph",
};

type FolketrygdPrognosisWithSimulationStatus = Required<
  Pick<Graph.PublicPensionPrognosis, "paymentPlanFolketrygd" | "statusMessages">
>;

@Injectable({
  providedIn: "root",
})
export class PublicPensionService extends AbstractPrognosisFetchService<
  PublicPensionEngagement,
  Graph.PublicPensionInput
> {
  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly publicPensionQueriesService: PublicPensionQueriesService,
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly publicPensionPrognosisParametersStaticService: PublicPensionPrognosisParametersStaticService,
    private readonly triggerService: PublicPensionConsentsPrognosisTriggerService,
    private readonly progosisParametersDynamicService: PublicPensionProgosisParametersDynamicService,
  ) {
    super(PUBLIC_PENSION_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);
  }

  public override isStorebrandOnly(): boolean {
    return true;
  }

  public fetchPrognosis(
    _: PublicPensionEngagement,
    params: FetchPrognosesParams,
  ): Observable<FetchPrognosisResult<PublicPensionEngagement>> {
    const source = "PublicPensionService::fetchPrognosesForEngagement";
    const prognosisWithErrorStatusOrNull$ =
      this.progosisParametersDynamicService.dynamicPublicPensionPrognosisParameters$.pipe(
        take(1),
        map(toPrognosisWithErrorStatusOrNull),
      );

    const queryArgs = mapSimulationParametersToQueryArguments(params);
    const validPrognosis$ = this.publicPensionQueriesService.getPublicPensionPrognosis(queryArgs);

    return prognosisWithErrorStatusOrNull$.pipe(
      switchMap((errorPrognosis) => (getIsNotEmpty(errorPrognosis.data) ? of(errorPrognosis) : validPrognosis$)),
      catchError((error) => handleError(source, error, { data: [] })),
      map(toPrognosisWithNonEmptyPayoutPlans),
      tap((prognosis) => {
        this.syncNavSimulationParametersIfSimulationParametersByEngagement(prognosis);
      }),
    );
  }

  public getFolketrygdPrognosis(): Observable<FolketrygdPrognosisWithSimulationStatus[]> {
    return this.getEngagementsAfterLoadingStateFalse().pipe(
      map((engagements) => engagements.map((engagement) => toFolketrygdAndStatusMessages(engagement))),
    );
  }

  protected _fetchEngagements(): Observable<PublicPensionEngagement[]> {
    return combineLatest([
      this.publicPensionPrognosisParametersStaticService.getStaticPensionPrognosisParameters(),
      this.publicPensionQueriesService.getPublicPensionContracts().pipe(map(toArrayOrEmpty)),
    ]).pipe(
      map(([params, contracts]) => (contracts.length > 0 ? [new PublicPensionEngagement(params, contracts)] : [])),
    );
  }

  protected prognosisSimParams$(
    engagementParams?: Partial<CustomerSuppliedData.SimulationParameters>,
  ): Observable<PublicPensionPrognosisParams> {
    return combineLatest([
      this.progosisParametersDynamicService.getLatestPensionPrognosisParametersByStartPayoutAge(),
      this.simulationParametersQueriesService
        .getRetirementAgesQuery()
        .pipe(catchError(() => of([] as Graph.RetirementAge[]))),
      this.commonParametersService.postPensionSimParams$,
      this.triggerService.triggerPrognosisFetch$,
    ]).pipe(
      map(
        ([
          { publicPensionPrognosisParameters, startPayoutAge: _startPayoutAge },
          retirementAges,
          {
            enable: postPensionPartTimeEnable,
            endAge: _postPensionPartTimeEndAge,
            partTimePercentage: postPensionPartTimePercent,
            futureSalary: postPensionFutureSalary,
            withdrawalPercentage: postPensionWithdrawalPercentage,
          },
          ,
        ]) => {
          const { startMonthOffset, startPayoutAge } = toAgeAndMonthOffset(
            retirementAges,
            getNumberWithinBounds(
              {
                floor: publicPensionPrognosisParameters.minimumAgeWithdrawal ?? NaN,
                ceil: NaN,
              },
              engagementParams?.startPayoutAge ?? _startPayoutAge,
              engagementParams?.startPayoutAge ?? _startPayoutAge,
            ),
          );
          const futureSalary =
            _startPayoutAge <= PART_TIME_EXPECTED_FUTURE_INCOME_MAX_AGE ? postPensionFutureSalary : 0;
          const postPensionPartTimeEndAge = getNumberWithinBounds(
            {
              floor: startPayoutAge,
              ceil: Math.max(startPayoutAge, publicPensionPrognosisParameters.maximumAgeWithdrawal ?? NaN),
            },
            _postPensionPartTimeEndAge,
            _postPensionPartTimeEndAge,
          );
          return {
            startPayoutAge,
            startMonthOffset,
            futureSalary,
            postPensionPartTimeEnable,
            postPensionPartTimeEndAge,
            postPensionPartTimePercent,
            postPensionWithdrawalPercentage,
            ...publicPensionPrognosisParameters,
          };
        },
      ),
    );
  }

  protected composeFailedPrognosis(
    engagement: PublicPensionEngagement,
    isAgeHigherThanMaxSimAge?: boolean,
  ): Graph.PublicPensionPrognosis {
    const key = isAgeHigherThanMaxSimAge
      ? SimulationErrorCmsKey.AboveMaxAgeError
      : SimulationErrorCmsKey.UnknownPublicError;

    return {
      statusMessages: (<Graph.PublicPensionStatusMessage[]>[]).concat(createGraphSimulationMessageError(key)),
    };
  }

  protected composeMissingPrognosisError(engagement: PublicPensionEngagement): MissingPublicPensionPrognosisError {
    return new MissingPublicPensionPrognosisError(engagement);
  }

  private async syncNavSimulationParametersIfSimulationParametersByEngagement(
    prognosis: FetchPrognosisResult<PublicPensionEngagement>,
  ): Promise<void> {
    const isEnabled = await firstValueFrom(
      this.commonParametersService.simulationParametersByEngagementEnable$.pipe(map(({ enable }) => enable)),
    );

    if (isEnabled) {
      const startPayoutAge = prognosis.prognoses
        .flatMap((item) => item?.paymentPlanFolketrygd ?? [])
        .map((item) => item?.age ?? undefined)
        .at(0);

      this.commonParametersService.updateSimulationParametersByEngagement(new NavEngagementFromStb({}), [
        NAV_ENGAGEMENT_ID,
        {
          startPayoutAge,
          durationYears: undefined,
        },
      ]);
    }
  }

  private getEngagementsAfterLoadingStateFalse(): Observable<PublicPensionEngagement[]> {
    const toObservableOfRunningJobWithEngagement = (
      engagement: PublicPensionEngagement,
    ): Observable<[PublicPensionEngagement, boolean]> => {
      return this.fetchPrognosesRunningJobsService
        .getIsRunningJobForEngagement(engagement)
        .pipe(map((job) => [engagement, job]));
    };

    const toObservableOfLoadingStateAndEngagement = (
      engagements: PublicPensionEngagement[],
    ): Observable<[PublicPensionEngagement, boolean][]> => {
      return isEmpty(engagements) ? of([]) : zip(...engagements.map(toObservableOfRunningJobWithEngagement));
    };

    return this.engagements$.pipe(
      map((engagements) => engagements.filter((e) => e.hasFolketrygdPrognosis())),
      switchMap(toObservableOfLoadingStateAndEngagement),
      delay(0), // prevent premature emissions before the service has reacted to the sim param change
      filter((engagements) => engagements.every(([, hasRunningJob]) => !hasRunningJob)),
      map((engagements) => engagements.flatMap(([engagement]) => engagement)),
    );
  }
}

const defaultNonPartTimeSimParams = (
  firstWithdrawalAge: number,
  firstWithdrawalMonthOffset: number,
): Graph.QueryPublicPensionPrognosisArgs => ({
  input: {
    firstWithdrawalAge,
    firstWithdrawalMonthOffset,
    workPercentage: 0,
    withdrawalPercentage: 100,
    futureSalary: 0,
    lastWithdrawalAge: undefined,
  },
});

function mapSimulationParametersToQueryArguments(params: FetchPrognosesParams): Graph.QueryPublicPensionPrognosisArgs {
  const {
    startPayoutAge: firstWithdrawalAge,
    startMonthOffset: firstWithdrawalMonthOffset,
    postPensionWithdrawalPercentage,
    postPensionPartTimePercent,
    canChangeWorkingPercentage,
    canChangeFutureSalary,
    postPensionPartTimeEnable,
    maximumAgeWithdrawal,
  } = params;

  const canHavePartTimeSimParams =
    canChangeWorkingPercentage && postPensionPartTimeEnable && firstWithdrawalAge !== maximumAgeWithdrawal;

  if (!canHavePartTimeSimParams) {
    return defaultNonPartTimeSimParams(firstWithdrawalAge, firstWithdrawalMonthOffset);
  }

  const workPercentage = postPensionPartTimePercent ?? 0;
  const withdrawalPercentage = postPensionWithdrawalPercentage ?? DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE;
  const lastWithdrawalAge = getLastWithdrawalAge(params);
  const lastWithdrawalMonthOffset = getIsNullable(lastWithdrawalAge) ? undefined : 0;
  const futureSalary = workPercentage && canChangeFutureSalary ? params.futureSalary : undefined;

  return {
    input: {
      firstWithdrawalAge,
      firstWithdrawalMonthOffset,
      workPercentage,
      withdrawalPercentage,
      lastWithdrawalMonthOffset,
      lastWithdrawalAge,
      futureSalary,
    },
  };
}

export function getLastWithdrawalAge(
  params: Pick<
    FetchPrognosesParams,
    | "canChangeWithdrawalPercentage"
    | "maximumAgeWithdrawal"
    | "postPensionPartTimeEndAge"
    | "startPayoutAge"
    | "postPensionPartTimePercent"
  >,
): Nullable<number> {
  const {
    canChangeWithdrawalPercentage,
    maximumAgeWithdrawal: maxiumPossibleEndAge,
    postPensionPartTimeEndAge: choosenEndAge,
    startPayoutAge,
    postPensionPartTimePercent,
  } = params;

  if (canChangeWithdrawalPercentage === false && postPensionPartTimePercent === 0) {
    return undefined;
  }

  const endAge = choosenEndAge ?? maxiumPossibleEndAge;
  const hasEndAge = getIsNotNullable(endAge);

  if (hasEndAge && endAge <= startPayoutAge) {
    return startPayoutAge + 1;
  }

  return endAge;
}

function toPrognosisWithNonEmptyPayoutPlans(
  queryResult: PrognosisQueryResult<Graph.PublicPensionPrognosis[]>,
): FetchPrognosisResult<PublicPensionEngagement> {
  const [entry] = queryResult.data;

  const prognosis = produce(entry || {}, (draft) => ({
    ...draft,
    paymentPlanAfp62: toArrayOrEmpty(draft.paymentPlanAfp62).filter(isNonZeroPayoutPlan),
    paymentPlanAfp65: toArrayOrEmpty(draft.paymentPlanAfp65).filter(isNonZeroPayoutPlan),
    paymentPlanFolketrygd: toArrayOrEmpty(draft.paymentPlanFolketrygd).filter(isNonZeroPayoutPlan),
    paymentPlanPension: toArrayOrEmpty(draft.paymentPlanPension).filter(isNonZeroPayoutPlan),
    paymentPlanSalary: toArrayOrEmpty(draft.paymentPlanSalary).filter(isNonZeroPayoutPlan),
  }));

  return {
    prognoses: [prognosis],
    metadata: queryResult.headers,
  };
}

function isNonZeroPayoutPlan(payoutPlan: Graph.PayoutPlan): boolean {
  return getIsFiniteNumber(payoutPlan.amount) && payoutPlan.amount > 0;
}

function toFolketrygdAndStatusMessages(
  e: PublicPensionEngagement,
): Required<Pick<Graph.PublicPensionPrognosis, "paymentPlanFolketrygd" | "statusMessages">> {
  return {
    paymentPlanFolketrygd: toArrayOrEmpty(e.prognosis?.paymentPlanFolketrygd),
    statusMessages: toArrayOrEmpty(e.prognosis?.statusMessages),
  };
}

function toPrognosisWithErrorStatusOrNull(
  parameters: Graph.PublicPensionPrognosisParameters,
): PrognosisQueryResult<PrognosisTypeOf<PublicPensionEngagement>[]> {
  const isError = parameters.statusMessages?.some(
    (message) => message.messageType === "S" || message.messageType === "F",
  );

  if (!isError) {
    return { data: [] };
  }

  return {
    data: [
      {
        paymentPlanAfp62: [],
        paymentPlanAfp65: [],
        paymentPlanFolketrygd: [],
        paymentPlanPension: [],
        paymentPlanSalary: [],
        statusMessages: parameters.statusMessages,
      },
    ],
  };
}
