import { positiveRange } from "./array";
import { Boundary, getNumberWithinBounds } from "./getNumberWithinBounds";
import { getIsNullable } from "./utils";

export function getRangeFromBoundary(boundary: Boundary | undefined, floorOverride: number): number[] {
  if (getIsNullable(boundary)) {
    return [];
  }
  const floor = getNumberWithinBounds(boundary, floorOverride, boundary.floor);
  const ceil = boundary.ceil;

  if (floor < floorOverride) {
    return [];
  }

  return positiveRange(floor, ceil, true);
}
