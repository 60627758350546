import { Injectable } from "@angular/core";
import { Observable, take } from "rxjs";
import { BehaviorStore } from "src/app/utils/rxjs/store";
import { getIsNotNullable } from "src/app/utils/utils";

type PrintCallback = () => any | Promise<any>;

interface PrintSubscriber {
  before: PrintCallback;
  after?: PrintCallback;
}

interface PrintUnsubscriber {
  unsubscribe(signal$: Observable<any>): void;
}

@Injectable({ providedIn: "root" })
export class PrintService {
  private readonly subscribers: PrintSubscriber[] = [];
  private readonly isPrinting$ = new BehaviorStore(false);

  public async print(): Promise<boolean> {
    if (this.isPrinting$.getValue()) {
      return true;
    }

    this.isPrinting$.next(true);

    try {
      const beforeCallbacks = this.subscribers.map(({ before }) => before);
      const afterCallbacks = this.subscribers.map(({ after }) => after).filter(getIsNotNullable);

      await Promise.all(executeCallbacks(beforeCallbacks));

      window.print();

      await Promise.all(executeCallbacks(afterCallbacks));

      return true;
    } catch (error) {
      return false;
    } finally {
      this.isPrinting$.next(false);
    }
  }

  public subscribe(subscriber: PrintSubscriber): PrintUnsubscriber {
    this.subscribers.push(subscriber);

    return {
      unsubscribe: (signal$: Observable<any>): void => {
        signal$.pipe(take(1)).subscribe(() => {
          const index = this.subscribers.findIndex((s) => s === subscriber);
          this.subscribers.splice(index, 1);
        });
      },
    };
  }

  public getIsPrinting(): Observable<boolean> {
    return this.isPrinting$.asObservable();
  }
}

function executeCallbacks(callbacks: PrintCallback[]): ReturnType<PrintCallback>[] {
  return callbacks.map((callback) => callback());
}
