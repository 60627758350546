import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable, map, mergeMap } from "rxjs";
import { ContractTypeOf } from "src/app/models/engagements/generic-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";
import { SavingsGraphqlClientService } from "../../graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "../../graphql-clients/utils/apollo-base-wrapper";

type ContractWithPortifolioInsight =
  | Graph.EpkContract
  | Graph.PkbContract
  | Graph.EpkFleksibelContract
  | Graph.EpkFleksibelPkbContract
  | Graph.FmiContract
  | Graph.LinkContract;

export type ContractWithSavingsRate =
  | Graph.EpkContract
  | Graph.EpkEmploymentContract
  | Graph.HybridMedGarantiContract
  | Graph.HybridMedInvesteringsvalgContract;

interface SavingsEngagementQuery {
  savingsEngagement: Graph.SavingsEngagement;
}

const TOTAL_DEPOSIT_LAST_YEAR = gql`
  fragment TotalDepositLastYear on KeyValues {
    totalDepositLastQueryYear {
      value
    }
  }
`;

const HOLDINGS = gql`
  fragment Holdings on Holding {
    funds {
      name
      actualDistribution
    }
  }
`;

const SAVINGS_RATE = gql`
  fragment SavingsRate on SavingsRate {
    basic {
      bottomSalary
      topSalary
      savingsRatePercent
    }
    additional {
      bottomSalary
      topSalary
      savingsRatePercent
    }
  }
`;

export const GET_CONTRACT_DETAILS_QUERY = gql`
  query getContractDetailsQuery($ids: [ID]) {
    savingsEngagement {
      epkContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        employerAnnualSavingsAmount
        savingsRate {
          ...SavingsRate
        }
        underPayout
      }
      pkbContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      fmiContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      ekstrapensjonContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      epkFleksibelContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
      }
      epkFleksibelPkbContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      hybridMedInvesteringsvalgContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        employerAnnualSavingsAmount
        savingsRate {
          ...SavingsRate
        }
        underPayout
      }
      hybridMedGarantiContracts(ids: $ids) {
        id
        employerAnnualSavingsAmount
        savingsRate {
          ...SavingsRate
        }
        underPayout
      }
      epkEmploymentContracts(ids: $ids) {
        id
        employerAnnualSavingsAmount
        savingsRate {
          ...SavingsRate
        }
        underPayout
      }
      ekstrapensjonContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      garantiContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      ipaLinkContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      ipoContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      ipsContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        keyValues {
          ...TotalDepositLastYear
        }
        underPayout
      }
      livrenteLinkContracts(ids: $ids) {
        id
        holdings {
          ...Holdings
        }
        allocationCode
        underPayout
      }
      pensionFundUnfundedItpContracts(ids: $ids) {
        id
        holdings {
          totalMarketValue {
            value
          }
        }
        underPayout
      }
    }
  }
  ${HOLDINGS}
  ${TOTAL_DEPOSIT_LAST_YEAR}
  ${SAVINGS_RATE}
`;

@Injectable()
export class ContractDetailsService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public fetchContractDetails<T extends AnySavingsAndPensionEngagement>(engagement: T): Observable<ContractTypeOf<T>> {
    const id = engagement.getId();

    return this.savingsGraphqlClient
      .query<SavingsEngagementQuery>({
        query: GET_CONTRACT_DETAILS_QUERY,
        variables: { ids: [id] },
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map((contractsDetails) => {
          return Object.values(contractsDetails.savingsEngagement)
            .filter((contracts) => Array.isArray(contracts) && contracts.length > 0)
            .flat()
            .at(0);
        }),
        map((details) => ({
          ...engagement.contract,
          ...details,
        })),
      );
  }
}

export function isContractWithHoldings(
  contract: ContractTypeOf<AnySavingsAndPensionEngagement>,
): contract is ContractWithPortifolioInsight {
  return (
    (contract as ContractWithPortifolioInsight)?.holdings !== undefined &&
    (contract as ContractWithPortifolioInsight)?.allocationCode !== undefined
  );
}

export function isContractWithSavingsRate(
  contract: ContractTypeOf<AnySavingsAndPensionEngagement>,
): contract is ContractWithSavingsRate {
  return (
    (contract as ContractWithSavingsRate)?.employerAnnualSavingsAmount !== undefined &&
    (contract as ContractWithSavingsRate)?.savingsRate !== undefined
  );
}
