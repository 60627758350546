import { NgModule } from "@angular/core";
import { APOLLO_NAMED_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { EndpointService } from "src/app/services/endpoint.service";
import { savingsGraphClientFactory } from "./clients/savings-graph/savings-graph-client";

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: savingsGraphClientFactory,
      deps: [HttpLink, EndpointService],
    },
  ],
})
export class GraphqlClientsModule {}
