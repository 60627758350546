import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { OtherPensionEngagement } from "../models/engagements/other-pension-engagement.model";
import { OtherPensionEngagementsService } from "./prognoses-services/other-pension-engagements.service";

@Injectable({
  providedIn: "root",
})
export class MigrateOtherPensionToSinglePeriodService {
  private readonly otherPensionWithMultiplePeriods$: Observable<OtherPensionEngagement[]>;

  constructor(private readonly otherPensionEngagementsService: OtherPensionEngagementsService) {
    this.otherPensionWithMultiplePeriods$ = this.otherPensionEngagementsService.engagements$.pipe(
      map((engagements) => engagements.filter(hasMultiplePeriods).filter(hasPrognosis).filter(isNotPublicPension)),
      filter((engagements) => engagements.length > 0),
    );
  }

  public init(): void {
    this.otherPensionWithMultiplePeriods$.subscribe((otherPensionsWithMultiplePeriods) => {
      otherPensionsWithMultiplePeriods.forEach((otherPensionEngagement) => {
        this.otherPensionEngagementsService.deleteEngagement(otherPensionEngagement);
        const periodData = generateEngagementDataFromPeriods(otherPensionEngagement);
        this.createEngagementsFromPeriodData(periodData);
      });
    });
  }

  public createEngagementsFromPeriodData(
    periodData: Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension">[],
  ): void {
    periodData.forEach((data) => {
      this.otherPensionEngagementsService.createEngagement(data);
    });
  }
}

export function generateEngagementDataFromPeriods(
  otherPensionEngagement: OtherPensionEngagement,
): Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension">[] {
  return otherPensionEngagement.contract.periods.map(
    (period) =>
      ({
        title: otherPensionEngagement.contract.title,
        payer: otherPensionEngagement.contract.payer,
        replaceOffentligTjenestepensjon: false,
        periods: [period],
      }) as Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension">,
  );
}

function hasMultiplePeriods(otherPensionEngagement: OtherPensionEngagement): boolean {
  return otherPensionEngagement.contract.periods.length > 1;
}

function hasPrognosis(otherPensionEngagement: OtherPensionEngagement): boolean {
  return otherPensionEngagement.prognosis !== undefined;
}

function isNotPublicPension(otherPensionEngagement: OtherPensionEngagement): boolean {
  return !otherPensionEngagement.contract.replaceOffentligTjenestepensjon;
}
