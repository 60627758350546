import {
  AbstractEpkEngagement,
  EpkEmploymentEngagement,
  EpkEngagement,
  EpkFleksibelEngagement,
} from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import { ItpUnfundedEngagement } from "src/app/models/engagements/savings-and-pension/pension-fund-engagement.model";
import { YtpEngagement } from "src/app/models/engagements/savings-and-pension/ytp-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { BankEngagement } from "../models/engagements/bank-engagement.model";
import { ExternalSavingsEngagement } from "../models/engagements/external-savings-engagement.model";
import { GenericEngagementOfUnknown } from "../models/engagements/generic-engagement.model";
import {
  AbstractNavEngagement,
  NavEngagementFromNp,
  NavEngagementFromStb,
} from "../models/engagements/nav-engagement.model";
import { AfpEngagement } from "../models/engagements/norskpensjon/afp-engagement.model";
import { NorskpensjonEngagement } from "../models/engagements/norskpensjon/norskpensjon-engagement.model";
import { UnavailableNorskpensjonEngagement } from "../models/engagements/norskpensjon/unavailable-norskpensjon-engagement.model";
import { OtherPensionEngagement } from "../models/engagements/other-pension-engagement.model";
import { PublicPensionEngagement } from "../models/engagements/public-pension-engagement.model";
import { AlisEngagement } from "../models/engagements/savings-and-pension/alis-engagement.model";
import { FmiEngagement } from "../models/engagements/savings-and-pension/fmi-engagement.model";
import { FripoliseEngagement } from "../models/engagements/savings-and-pension/fripolise-engagement.model";
import {
  HybridMedGarantiEngagement,
  HybridMedInvesteringsvalgEngagement,
  HybridPensjonsbevisEngagement,
} from "../models/engagements/savings-and-pension/hybrid-engagement.model";
import {
  AbstractLinkEngagement,
  EkstrapensjonEmploymentEngagement,
  EkstrapensjonEngagement,
  FondskontoLinkEngagement,
  IpsEngagement,
} from "../models/engagements/savings-and-pension/link-engagement.model";
import {
  EpkFleksibelPkbEngagement,
  PkbEngagement,
} from "../models/engagements/savings-and-pension/pkb-engagement.model";
import { SavingsAndPensionEngagement } from "../models/engagements/savings-and-pension/savings-and-pension-engagement.model";
import { AbstractUCITSEngagement } from "../models/engagements/savings-and-pension/ucits-engagement.model";
import { AnySavingsAndPensionEngagement } from "../services/prognoses-services/savings-and-pension.service";
import { AbstractNorskpensjonEngagement } from "../models/engagements/norskpensjon/abstract-norskpensjon-engagement.model";

export function isAnyEpkEngagement<T extends AbstractEpkEngagement<Graph.Contract>>(
  engagement: GenericEngagementOfUnknown,
): engagement is T {
  return engagement instanceof AbstractEpkEngagement;
}

/**
 * Returns a typecheck indicating whether this engagement's contract and prognosis is fetched from Norsk Pensjon.
 * Note that NavEngagementFromStb is excluded.
 */
export function isAnyNorskpensjonEngagement<
  Nav extends NavEngagementFromNp,
  NP extends AbstractNorskpensjonEngagement<any>,
>(engagement: GenericEngagementOfUnknown): engagement is Nav | NP {
  return engagement instanceof NavEngagementFromNp || engagement instanceof AbstractNorskpensjonEngagement;
}

export function isAfpEngagement(engagement: GenericEngagementOfUnknown): engagement is AfpEngagement {
  return engagement instanceof AfpEngagement;
}

export function isAlisEngagement(engagement: GenericEngagementOfUnknown): engagement is AlisEngagement {
  return engagement instanceof AlisEngagement;
}

export function isBankEngagement(engagement: GenericEngagementOfUnknown): engagement is BankEngagement {
  return engagement instanceof BankEngagement;
}

export function isEpkEngagement(engagement: GenericEngagementOfUnknown): engagement is EpkEngagement {
  return engagement instanceof EpkEngagement;
}

export function isEkstrapensjonEmploymentEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is EkstrapensjonEmploymentEngagement {
  return engagement instanceof EkstrapensjonEmploymentEngagement;
}

export function isEpkFleksibelEngagement(engagement: GenericEngagementOfUnknown): engagement is EpkFleksibelEngagement {
  return engagement instanceof EpkFleksibelEngagement;
}

export function isEpkFleksibelPkbEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is EpkFleksibelPkbEngagement {
  return engagement instanceof EpkFleksibelPkbEngagement;
}

export function isEpkEmploymentEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is EpkEmploymentEngagement {
  return engagement instanceof EpkEmploymentEngagement;
}

export function isFmiEngagement(engagement: GenericEngagementOfUnknown): engagement is FmiEngagement {
  return engagement instanceof FmiEngagement;
}

export function isPkbEngagement(engagement: GenericEngagementOfUnknown): engagement is PkbEngagement {
  return engagement instanceof PkbEngagement;
}

export function isLinkEngagement(engagement: GenericEngagementOfUnknown): engagement is AbstractLinkEngagement {
  return engagement instanceof AbstractLinkEngagement;
}

export function isHybridMedInvesteringsvalgEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is HybridMedInvesteringsvalgEngagement {
  return engagement instanceof HybridMedInvesteringsvalgEngagement;
}

export function isHybridMedGarantiEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is HybridMedGarantiEngagement {
  return engagement instanceof HybridMedGarantiEngagement;
}

export function isHybridPensjonsbevisEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is HybridPensjonsbevisEngagement {
  return engagement instanceof HybridPensjonsbevisEngagement;
}

export function isExternalSavingsEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is ExternalSavingsEngagement {
  return engagement instanceof ExternalSavingsEngagement;
}

export function isFripoliseEngagement(engagement: GenericEngagementOfUnknown): engagement is FripoliseEngagement {
  return engagement instanceof FripoliseEngagement;
}

export function isOtherPensionEngagement(engagement: GenericEngagementOfUnknown): engagement is OtherPensionEngagement {
  return engagement instanceof OtherPensionEngagement;
}

export function isPublicPensionEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is PublicPensionEngagement {
  return engagement instanceof PublicPensionEngagement;
}

export function isEkstrapensjonEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is EkstrapensjonEngagement {
  return engagement instanceof EkstrapensjonEngagement;
}

export function isFondskontoLinkEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is FondskontoLinkEngagement {
  return engagement instanceof FondskontoLinkEngagement;
}

export function isIpsEngagement(engagement: GenericEngagementOfUnknown): engagement is IpsEngagement {
  return engagement instanceof IpsEngagement;
}

export function isNavFromNpEngagement(engagement: GenericEngagementOfUnknown): engagement is NavEngagementFromNp {
  return engagement instanceof NavEngagementFromNp;
}

export function isNavEngagement(engagement: GenericEngagementOfUnknown): engagement is AbstractNavEngagement {
  return engagement instanceof AbstractNavEngagement;
}

export function isUnavailableNorskpensjonEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is UnavailableNorskpensjonEngagement {
  return engagement instanceof UnavailableNorskpensjonEngagement;
}

export function isNorskpensjonEngagement(engagement: GenericEngagementOfUnknown): engagement is NorskpensjonEngagement {
  return engagement instanceof NorskpensjonEngagement;
}

export function isItpUnfundedEngagement(engagement: GenericEngagementOfUnknown): engagement is ItpUnfundedEngagement {
  return engagement instanceof ItpUnfundedEngagement;
}

export function isYtpEngagement(engagement: GenericEngagementOfUnknown): engagement is YtpEngagement {
  return engagement instanceof YtpEngagement;
}

export function isNavEngagementFromStb(engagement: GenericEngagementOfUnknown): engagement is NavEngagementFromStb {
  return engagement instanceof NavEngagementFromStb;
}

export function isSavingsAndPensionEngagement(
  engagement: GenericEngagementOfUnknown,
): engagement is AnySavingsAndPensionEngagement {
  return engagement instanceof SavingsAndPensionEngagement;
}

export function isUCITSEngagement(engagement: GenericEngagementOfUnknown): engagement is AbstractUCITSEngagement {
  return engagement instanceof AbstractUCITSEngagement;
}
