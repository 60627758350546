<app-action-card
  [title]="'actions.withdrawalOrderPage.title' | fms"
  [icon]="'actions.withdrawalOrderPage.icon' | fms"
  [content]="'actions.withdrawalOrderPage.content' | fms"
  [outlinedPrimaryButton]="false"
  [primaryButton]="'actions.withdrawalOrderPage.primaryButton' | fms"
  (primaryButtonClick)="navigateToWithdrawalOrderPage()"
  [action]="action"
>
</app-action-card>
