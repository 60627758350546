import { Injectable } from "@angular/core";
import { QueryOptions } from "@apollo/client/core";
import { gql } from "apollo-angular";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ResponseHeaders } from "src/app/modules/graphql-clients/local-schema/response-headers";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { PrognosisQueryResult } from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { getIsNotNullable } from "src/app/utils/utils";

type GetPrognosisQuery = Pick<Graph.Query, "prognosis"> & {
  headers: ResponseHeaders;
};

type GetOpenPrognosisQuery = Pick<Graph.Query, "openPrognosis"> & {
  headers: ResponseHeaders;
};

const PROGNOSIS_FIELDS_FRAGMENT = gql`
  fragment PrognosisFields on Prognosis {
    id
    xCorrelationId
    simulationStatus {
      messageKey
      severity
    }
    payoutPlan {
      amount
      age
      startDate
      endDate
    }
    oneTimePayout
    lifeLong
    compressed
  }
`;

export const GET_SAVINGS_AND_PENSION_PROGNOSIS_QUERY = gql`
  query getSavingsAndPensionPrognosisQuery($input: PrognosisInput) {
    headers @client {
      correlationId
    }
    prognosis(input: $input) {
      ...PrognosisFields
    }
  }
  ${PROGNOSIS_FIELDS_FRAGMENT}
`;

export const GET_OPEN_PROGNOSIS_BANK_QUERY = gql`
  query getOpenPrognosisBankQuery($bankOpenPrognosisInput: BankOpenPrognosisInput) {
    headers @client {
      correlationId
    }
    openPrognosis {
      bank(bankOpenPrognosisInput: $bankOpenPrognosisInput) {
        ...PrognosisFields
      }
    }
  }
  ${PROGNOSIS_FIELDS_FRAGMENT}
`;

export const GET_OPEN_PROGNOSIS_FUND_QUERY = gql`
  query getOpenPrognosisFundQuery($fundOpenPrognosisInput: FundOpenPrognosisInput) {
    headers @client {
      correlationId
    }
    openPrognosis {
      fund(fundOpenPrognosisInput: $fundOpenPrognosisInput) {
        ...PrognosisFields
      }
    }
  }
  ${PROGNOSIS_FIELDS_FRAGMENT}
`;

@Injectable({
  providedIn: "root",
})
export class PrognosisQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getPrognosis(
    id: Graph.Scalars["ID"]["output"],
    params: Graph.PrognosisInput["parameters"],
  ): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.fetchPrognosis(
      {
        query: GET_SAVINGS_AND_PENSION_PROGNOSIS_QUERY,
        variables: createPrognosisVariables(id, params),
      },
      getPrognosisFromResponse,
    );
  }

  public getOpenPrognosisBank(
    bankOpenPrognosisInput: Graph.BankOpenPrognosisInput,
  ): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.fetchPrognosis(
      {
        query: GET_OPEN_PROGNOSIS_BANK_QUERY,
        variables: { bankOpenPrognosisInput },
      },
      getOpenPrognosisBankFromResponse,
    );
  }

  public getOpenPrognosisFund(
    fundOpenPrognosisInput: Graph.FundOpenPrognosisInput,
  ): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.fetchPrognosis(
      {
        query: GET_OPEN_PROGNOSIS_FUND_QUERY,
        variables: { fundOpenPrognosisInput },
      },
      getOpenPrognosisFundFromResponse,
    );
  }

  private fetchPrognosis(
    options: QueryOptions,
    mappingFn: (query: any) => PrognosisQueryResult<Graph.Prognosis[]>,
  ): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.savingsGraphqlClient
      .query<GetPrognosisQuery>(options)
      .pipe(mergeMap(getApolloResponseData), map(mappingFn));
  }
}

function getPrognosisFromResponse(query: GetPrognosisQuery): PrognosisQueryResult<Graph.Prognosis[]> {
  return {
    data: [query.prognosis].filter(getIsNotNullable),
    headers: query.headers,
  };
}

function getOpenPrognosisBankFromResponse(query: GetOpenPrognosisQuery): PrognosisQueryResult<Graph.Prognosis[]> {
  return {
    data: [query.openPrognosis.bank].filter(getIsNotNullable),
    headers: query.headers,
  };
}

function getOpenPrognosisFundFromResponse(query: GetOpenPrognosisQuery): PrognosisQueryResult<Graph.Prognosis[]> {
  return {
    data: [query.openPrognosis.fund].filter(getIsNotNullable),
    headers: query.headers,
  };
}

function createPrognosisVariables(
  contractId: string,
  parameters: Graph.PrognosisInput["parameters"],
): Graph.QueryPrognosisArgs {
  return {
    input: {
      id: contractId,
      parameters,
    },
  };
}
