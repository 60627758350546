import { isEmpty } from "lodash-es";
import { ObservedValueOf } from "rxjs";
import { pruneProps } from "src/app/utils/object";
import { getIsNotNullable } from "src/app/utils/utils";
import {
  DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE,
  DEFAULT_SIMULATION_AGE,
} from "../../constants/business.constants";
import { SimulationParametersByEngagement } from "../common-parameters.service";
import { ClientDataService } from "./client-data.service";

type SimulationParametersByEngagementMap = Map<string, Partial<CustomerSuppliedData.SimulationParameters>>;

export function updateManySimulationParametersByEngagement(
  manyEngagementParams: SimulationParametersByEngagement[],
  simulationParametersByEngagement: SimulationParametersByEngagement[] = [],
): SimulationParametersByEngagement[] {
  return manyEngagementParams.reduce(
    (acc, cv) => updateOneSimulationParametersByEngagement(cv, acc),
    simulationParametersByEngagement,
  );
}

export function updateOneSimulationParametersByEngagement(
  engagementParams: SimulationParametersByEngagement,
  simulationParametersByEngagement: SimulationParametersByEngagement[] = [],
): SimulationParametersByEngagement[] {
  const [id, params] = engagementParams;
  const paramsMap = createSimulationParametersByEngagementMap(simulationParametersByEngagement);
  const exists = paramsMap.has(id);
  const hasEmptyParams = isEmpty(params);

  const existsAndParamsAreEmpty = exists && hasEmptyParams;

  if (existsAndParamsAreEmpty) {
    paramsMap.delete(id);
  } else if (exists) {
    const updatedParams = {
      ...paramsMap.get(id),
      ...params,
    };

    paramsMap.set(id, pruneSimulationParametersProps(updatedParams));
  } else {
    paramsMap.set(id, pruneSimulationParametersProps(params));
  }

  return Array.from(paramsMap).filter(isNonEmptySimulationParams);
}

function createSimulationParametersByEngagementMap(
  simulationParametersByEngagement: CustomerSuppliedData.SimulationParametersByEngagement<{}>[] = [],
): SimulationParametersByEngagementMap {
  return simulationParametersByEngagement.reduce((paramsMap, params) => paramsMap.set(params[0], params[1]), new Map());
}

function isNonEmptySimulationParams(engagementParams: SimulationParametersByEngagement): boolean {
  const [id, params] = engagementParams;
  return getIsNotNullable(id) && !isEmpty(params);
}

export function pruneSimulationParametersProps(
  simParams: Partial<CustomerSuppliedData.SimulationParameters> | undefined,
): Partial<CustomerSuppliedData.SimulationParameters> {
  const paramsOrEmpty = simParams ?? {};

  return pruneProps(paramsOrEmpty);
}

const defaultAdditionalInfo: CustomerSuppliedData.AdditionalInfo = {
  isPayingWealthTax: false,
  annualWealthTaxExpenses: "0",
  hasBeenTouched: false,
};

export function generateDefaultClientData(): Partial<CustomerSuppliedData.ClientData> {
  return {
    monthlyDebt: "0",
    additionalInfo: defaultAdditionalInfo,
    monthlyIncomeNet: undefined,
    monthlyPensionSavings: "0",
    simulationParameters: generateDefaultSimulationParameters(),
    hasBookedAdvisor: false,
    followUp: {
      advisor: {},
      summaries: [],
      followUpTasks: [],
    },
    progress: [],
    pensionPlan: {},
    agreementsMetaDataMap: {},
    compressionLimitByEngagement: [],
    investmentProfileInPayoutPeriodByEngagement: [],
  };
}

export function generateDefaultSimulationParameters(): ObservedValueOf<
  typeof ClientDataService.prototype.simulationParameters$
> {
  return {
    postPensionPartTimeEndAge: 0,
    postPensionPartTimeEnable: false,
    postPensionPartTimePercent: 0,
    postPensionWithdrawalPercentage: DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE,
    postPensionFutureSalary: 0,
    partTimePercentage: 0,
    withdrawalPercentage: 50,
    startPayoutAgePartialWithdrawal: DEFAULT_SIMULATION_AGE - 1,
  };
}

export function generateDefaultPrognosisParametersInputByEngagement(): CustomerSuppliedData.PrognosisParametersByEngagement {
  return {
    storebrandSavings: {},
    storebrandPublic: {},
    norskpensjon: {},
  };
}
