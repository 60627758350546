import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SmallCard } from "../../cards/small-card/small-card.component";

export interface DynamicArticle {
  key?: string;
  url: string;
  title: string;
  enabled: boolean;
  external: boolean;
  featured: boolean;
  tags?: string[];
  readingTime?: number;
  imageUrl: string;
}

@Component({
  selector: "app-article-card",
  templateUrl: "./article-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardComponent {
  @Input()
  public readonly showImage: boolean = true;

  public card?: SmallCard;

  @Input()
  public set article(article: DynamicArticle) {
    this.card = toCard(article);
  }
}

function toCard({ title, imageUrl }: DynamicArticle): SmallCard {
  return { title, imageUrl };
}
