import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EMPTY, Observable, defer, mergeMap } from "rxjs";
import { LinkToDetailPageActionBuilder } from "src/app/modules/shared/services/contract-actions";
import { ExternalLink } from "src/app/modules/shared/services/contract-actions/contract-actions.types";
import { AnyEngagement } from "src/app/services/engagements.service";

@Component({
  selector: "app-link-to-details-page",
  templateUrl: "./link-to-details-page.component.html",
  styleUrls: ["./link-to-details-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkToDetailsPageComponent {
  @Input()
  public readonly engagement!: AnyEngagement;

  public readonly externalLink$ = defer(() => this.getExternalLinkOrEmpty(this.engagement));

  constructor(private readonly linkToDetailPageActionBuilder: LinkToDetailPageActionBuilder) {}

  public getExternalLinkOrEmpty(engagement: AnyEngagement): Observable<ExternalLink> {
    const predicate$ = defer(async () => this.linkToDetailPageActionBuilder.predicate(engagement));
    const build$ = defer(() => this.linkToDetailPageActionBuilder.build(engagement));

    return predicate$.pipe(mergeMap((predicate) => (predicate ? build$ : EMPTY)));
  }
}
