import { InjectionToken } from "@angular/core";
import langEn from "src/assets/fms/lang-en.json";
import langNo from "src/assets/fms/lang-no.json";

export const TRANSLATIONS = new InjectionToken("translations");

export const dictionary = {
  en: langEn,
  no: langNo,
};

export const TRANSLATION_PROVIDERS = [{ provide: TRANSLATIONS, useValue: dictionary }];

export type Fms = typeof langNo;

export type FmsKey = keyof Fms;
