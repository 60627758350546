import { Injectable } from "@angular/core";
import { combineLatest, filter, firstValueFrom, map, Observable } from "rxjs";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { FmsService } from "src/app/services/fms.service";
import { WithdrawalProfile, WithdrawalProfileId } from "./withdrawal-profiles.service";

@Injectable()
export class SamePayoutAgeProfile implements WithdrawalProfile {
  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly fmsService: FmsService,
  ) {}

  public getId(): WithdrawalProfileId {
    return WithdrawalProfileId.SamePayoutAge;
  }

  public getDescription(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("withdrawalProfile.samePayoutAge.description"));
  }

  public getTrackId(): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync("withdrawalProfile.samePayoutAge.trackId"));
  }

  public async changeProfile(): Promise<void> {
    this.clientDataService.updateClientData({
      simulationParametersByEngagementEnable: false,
      simulationParametersPartialWithdrawalEnable: false,
    });
  }

  public getProfileWhenActive(): Observable<WithdrawalProfile> {
    return combineLatest([
      this.clientDataService.simulationParametersByEngagementEnable$,
      this.clientDataService.simulationParametersPartialWithdrawalEnable$,
    ]).pipe(
      filter(([byEngagement, byPartialWithdrawal]) => !byEngagement.enable && !byPartialWithdrawal.enable),
      map(() => this),
    );
  }
}
