import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { EngagementsService } from "src/app/services/engagements.service";
import { ErrorCategory, ErrorContainer, ErrorsService } from "src/app/services/errors.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { getIsNotNullable } from "src/app/utils/utils";

@Component({
  selector: "app-expandable-data-alerts",
  templateUrl: "./expandable-data-alerts.component.html",
  styleUrls: ["./expandable-data-alerts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableDataAlertsComponent implements OnInit, OnDestroy {
  @Input()
  public category: ErrorCategory | ErrorCategory[] = [
    ErrorCategory.Engagement,
    ErrorCategory.LifeExpectancy,
    ErrorCategory.Prognosis,
    ErrorCategory.Consent,
    ErrorCategory.Customer,
  ];

  public alerts$!: Observable<ErrorContainer[]>;
  public hasAlerts$!: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly engagementsService: EngagementsService,
    private readonly errorsService: ErrorsService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
  ) {}

  private static sortByCategory(a: ErrorContainer, b: ErrorContainer): number {
    if (a.category < b.category) {
      return -1;
    }
    if (a.category > b.category) {
      return 1;
    }
    return 0;
  }

  public ngOnInit(): void {
    this.alerts$ = combineLatest([
      this.engagementsService.allEngagementErrors$.pipe(map((error) => error.filter(getIsNotNullable))),
      this.errorsService.errors$,
    ]).pipe(
      map(([engagementErrors, errors]) => engagementErrors.concat(errors)),
      ErrorsService.categoryFilterPipe(this.category),
      this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe(),
      map((alerts) => [...alerts].sort(ExpandableDataAlertsComponent.sortByCategory)),
      takeUntil(this.destroy$),
    );

    this.hasAlerts$ = this.alerts$.pipe(map((alerts) => alerts.length > 0));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public refresh(): void {
    window.location.reload();
  }
}
