<stb-form
  [size]="size"
  [label]="'financesinput.yearly_income_gross.label' | fms"
  [hint]="hint$ | async | fms"
  [error]="(formControl.statusChanges | async) !== null && formControl.touched && formControl.hasError('required')"
  [errorMessage]="formControl.hasError('required') ? ('financesinput.validation.required' | fms) : undefined"
>
  <input
    appCurrencyMask
    [formControl]="formControl"
    type="tel"
    (keydown.enter)="keydownEnter.emit()"
    [attr.aria-label]="'financesinput.yearly_income_gross.popover' | fms"
  />
</stb-form>
