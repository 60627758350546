import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GeneralChartColors, RangedNeedsColors } from "src/app/constants/highcharts-colors.constants";

@Component({
  selector: "app-chart-legend",
  templateUrl: "./chart-legend.component.html",
  styleUrls: ["./chart-legend.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendComponent {
  @Input()
  public showRangedNeedsLegend = false;

  public readonly incomeColumnColor = GeneralChartColors.AnnualGrossIncomeColumn;
  public readonly rangedNeedsColumnFill = RangedNeedsColors.ColumnFill;
  public readonly rangedNeedsAreaStroke = RangedNeedsColors.AreaStroke;
  public readonly rangedNeedsAreaFill = RangedNeedsColors.AreaFill;
}
