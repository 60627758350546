import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { bankAccountsApi } from "src/app/constants/api.constants";
import { EndpointService } from "../endpoint.service";

export interface BankTransactionOutput {
  accountNumber: string;
  amount: number;
  bookDate: string;
  fromAccount: string;
  postingDate: string;
  toAccount: string;
  transactionCode: string;
  transactionCodeDescription: string;
  transactionId: string;
  transactionText: string;
  valueDate: string;
}
/**
 * Storebrand Bank API, APE (Automatic Product Establishment) and Neos-API proxy.
 *
 * {@link https://t.stb.intra/bank-rest-api/swagger-ui.html}
 */
@Injectable({
  providedIn: "root",
})
export class BankRestApiService extends EndpointService {
  /**
   * GET /bank/accounts/transactions
   */
  public fetchTransactions(accountNumber: string): Observable<BankTransactionOutput[]> {
    const endpointUrl = "/transactions";
    const params = new HttpParams({ fromObject: { accountNumber } });

    return this.composeV2ApiUrl(bankAccountsApi).pipe(
      switchMap((baseUrl) => {
        const url = baseUrl + endpointUrl + this.getAdvisorFragments();

        return this.httpGet$<BankTransactionOutput[]>(url, { params });
      }),
    );
  }
}
