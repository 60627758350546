import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { combineLatest, firstValueFrom, map, take } from "rxjs";
import { tap } from "rxjs/operators";
import { PublicPensionPrognosisParametersStaticService } from "src/app/services/api/public-pension-prognosis-parameters-static.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { HasPublicPensionInStorebrandService } from "../services/api/has-public-pension-in-storebrand.service";

export const removeAfpInPublicContextResolver: ResolveFn<boolean> = () => {
  const publicPensionPrognosisParametersStaticService = inject(PublicPensionPrognosisParametersStaticService);
  const hasPublicPensionInStorebrandService = inject(HasPublicPensionInStorebrandService);
  const profileService = inject(ProfileService);

  const request$ = combineLatest([
    hasPublicPensionInStorebrandService.isPublicContext$,
    publicPensionPrognosisParametersStaticService.getHasEarnedPensionRight(),
    profileService.hasAfp$,
  ]).pipe(
    take(1),
    map(([isPublicContext, hasEarnedPensionRight, hasAfp]) => isPublicContext && !hasEarnedPensionRight && hasAfp),
    tap((shouldRemoveAfp) => {
      if (shouldRemoveAfp) {
        profileService.setProfileHasAfp(false);
      }
    }),
  );

  return firstValueFrom(request$);
};
