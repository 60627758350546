import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE } from "../tokens";
import { StorageService } from "./storage.service";

export enum LocalStorageKey {
  CompletedTourSteps = "completedTourSteps",
}

@Injectable({
  providedIn: "root",
})
export class LocalStorageService extends StorageService {
  constructor(
    @Inject(LOCAL_STORAGE)
    storage: Storage,
  ) {
    super(storage);
  }
}
