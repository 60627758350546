import { Pipe, PipeTransform } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";
import { getIsNullable, Nullable, upperCaseFirst } from "src/app/utils/utils";

type YearArgument = "start" | "end";

@Pipe({ name: "year" })
export class YearPipe implements PipeTransform {
  public year: string;

  constructor(private readonly fms: FmsService) {
    this.year = this.fms.instant("pipe.year");
  }

  public transform(value: string | number, arg?: YearArgument): string | number;

  public transform(value: null | undefined, arg?: YearArgument): null | undefined;

  public transform(value: Nullable<string | number>, arg?: YearArgument): Nullable<string | number>;

  public transform(value: Nullable<string | number>, arg: YearArgument = "end"): Nullable<string | number> {
    const numberValidator = !!(
      (typeof value === "number" && !isNaN(value) && isFinite(value)) ||
      (typeof value === "string" && Number(value))
    );

    if (getIsNullable(value) || !numberValidator) {
      return value;
    }

    return arg === "end" ? `${value} ${this.year}` : `${upperCaseFirst(this.year)} ${value}`;
  }
}
