import { Component } from "@angular/core";
import { ErrorsService, RoleError } from "src/app/services/errors.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-role-error",
  templateUrl: "role-errors.component.html",
})
export class RoleErrorsComponent {
  readonly roleErrors$: Observable<RoleError[]>;
  constructor(private readonly errorsService: ErrorsService) {
    this.roleErrors$ = this.errorsService.roleErrors$;
  }
}
