import { ComponentType } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Observable, combineLatest, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import { AfpPromotorService } from "src/app/services/afp-promotor.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { OffentligTjenestepensjonEngagementComponent } from "../../../../shared/components/add-engagement/offentlig-tjenestepensjon-engagement/offentlig-tjenestepensjon-engagement.component";
import { AfpEngagementComponent } from "../afp-engagement/afp-engagement.component";
import { CombinedNewEngagmentComponent } from "../combined-new-engagment/combined-new-engagment.component";
import { ExternalEngagementComponent } from "../external-engagement/external-engagement.component";
import { OtherPensionEngagementComponent } from "../other-pension-engagement/other-pension-engagement.component";

export enum AddButtonKey {
  Afp = "afpEngagement",
  External = "externalEngagement",
  OffentligTjenestepensjon = "offentligTjenestepensjonEngagement",
  OtherPension = "otherPensionEngagement",
}

export interface AddEngagementButton {
  key: AddButtonKey;
  dialogComponent: ComponentType<any>;
  icon: string;
  textFmsKey: string;
  trackIdFmsKey: string;
  radioTextFmsKey: string;
}

@Component({
  selector: "app-add-engagements-buttons",
  templateUrl: "./add-engagements-buttons.component.html",
  styleUrls: ["./add-engagements-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEngagementsButtonsComponent implements OnInit {
  @Input()
  public readonly buttons: AddButtonKey[] = [
    AddButtonKey.Afp,
    AddButtonKey.External,
    AddButtonKey.OffentligTjenestepensjon,
    AddButtonKey.OtherPension,
  ];
  @Input()
  public readonly individualButtons: Boolean = true;

  public buttonsToRender$: Observable<AddEngagementButton[]> | undefined;

  constructor(
    private readonly dialog: MatDialog,
    private readonly engagementsService: EngagementsService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
    private readonly afpPromotorService: AfpPromotorService,
  ) {}

  public ngOnInit(): void {
    const showOffentligTjenestepensjonButton$: Observable<boolean> =
      this.engagementsService.canManuallyAddOffentligTjenestepensjon$;

    const buttonsToRender$ = combineLatest([
      showOffentligTjenestepensjonButton$,
      this.afpPromotorService.getCanAddAfp(),
    ]).pipe(
      map(([showOffentligTjenestepensjon, showAddAfp]) => {
        const mappedButtons = this.mapAddButtonKeysToButton(this.buttons);

        return mappedButtons.filter((button) => {
          switch (button.key) {
            case AddButtonKey.OffentligTjenestepensjon: {
              return showOffentligTjenestepensjon;
            }
            case AddButtonKey.Afp: {
              return showAddAfp;
            }
            default:
              return true;
          }
        });
      }),
    );

    this.buttonsToRender$ = this.storebrandOnlyService
      .getIsEnabled()
      .pipe(switchMap((storebrandOnly) => (storebrandOnly ? of([]) : buttonsToRender$)));
  }

  public newEngagementDialog(component: ComponentType<any>): void {
    this.dialog.open(component, {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "large-dialog-padding",
      autoFocus: false,
    });
  }

  public openCombinedNewEngagementsDialog(buttonsToRender: AddEngagementButton[]): void {
    this.dialog.open(CombinedNewEngagmentComponent, {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "large-dialog-padding",
      autoFocus: false,
      data: { buttonsToRender },
    });
  }

  private mapAddButtonKeysToButton(addButtonKeys: AddButtonKey[]): AddEngagementButton[] {
    const buttons = [
      {
        key: AddButtonKey.External,
        dialogComponent: ExternalEngagementComponent,
        textFmsKey: "pensionPlan.externalEngagement.addButton.text",
        trackIdFmsKey: "pensionPlan-externalEngagementDialog",
        icon: "add",
        radioTextFmsKey: "pensionPlan.externalEngagement.addButton.radioText",
      },
      {
        key: AddButtonKey.OtherPension,
        dialogComponent: OtherPensionEngagementComponent,
        textFmsKey: "pensionPlan.otherPensionEngagement.addButton.text",
        trackIdFmsKey: "pensionPlan-otherPensionEngagementDialog",
        icon: "add",
        radioTextFmsKey: "pensionPlan.otherPensionEngagement.addButton.radioText",
      },
      {
        key: AddButtonKey.OffentligTjenestepensjon,
        dialogComponent: OffentligTjenestepensjonEngagementComponent,
        textFmsKey: "pensionPlan.offentligTjenestepensjonEngagement.addButton.text",
        trackIdFmsKey: "pensionPlan-offentligTjenestepensjonEngagementDialog",
        icon: "add",
        radioTextFmsKey: "pensionPlan.offentligTjenestepensjonEngagement.addButton.radioText",
      },
      {
        key: AddButtonKey.Afp,
        dialogComponent: AfpEngagementComponent,
        textFmsKey: "pensionPlan.afpEngagement.addButton.text",
        trackIdFmsKey: "button-afpCta",
        icon: "add",
        radioTextFmsKey: "pensionPlan.afpEngagement.addButton.radioText",
      },
    ];

    return buttons.filter((button) => addButtonKeys.includes(button.key));
  }
}
