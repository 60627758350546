import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { random } from "lodash-es";
import { AbstractSkeletonComponent } from "../abstract-skeleton.component";

@Component({
  selector: "app-chart-skeleton",
  templateUrl: "./chart-skeleton.component.html",
  styleUrls: ["./chart-skeleton.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSkeletonComponent extends AbstractSkeletonComponent {
  @Input()
  public width = 75;
  @Input()
  public lines = 15;

  public getLineHeight(): number {
    return random(20, 70);
  }
}
