<div class="summary-table" [class.summary-table-responsive]="responsive">
  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.totalPayout" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ getTotalPayout() | currencyFormatterPipe: "start" }}
      </ng-container>
    </div>
  </div>

  <div class="summary-row mobile-only" *ngIf="showSimulationParameters">
    <div class="summary-label">
      {{ "engagement.label.payoutDuration" | fms }}
    </div>

    <div class="summary-value">
      <ng-container *ngIf="!hasError; else amountUnavailable">
        {{ getDuration() }}
      </ng-container>
    </div>
  </div>

  <div class="summary-row" *ngIf="isPeriodic()">
    <div class="summary-label">
      {{ "engagement.label.periodicSaving" | fms }}
    </div>

    <div class="summary-value">
      {{ engagement.contract.periodicDeposit | currencyFormatterPipe: "start" }}
    </div>
  </div>
</div>

<ng-template #amountUnavailable>
  <app-amount-unavailable></app-amount-unavailable>
</ng-template>
