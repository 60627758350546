import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayModule } from "@angular/cdk/overlay";
import { AsyncPipe, CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from "@angular/material/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterModule } from "@angular/router";
import { AccordionModule } from "@storeblocks/accordion-ng";
import { AlertModule } from "@storeblocks/alert-ng";
import { ButtonModule } from "@storeblocks/button-ng";
import { CardMediumModule } from "@storeblocks/card-medium-ng";
import { CardModule } from "@storeblocks/card-ng";
import { CheckboxModule } from "@storeblocks/checkbox-ng";
import { ChipModule } from "@storeblocks/chip-ng";
import { FormModule } from "@storeblocks/form-ng";
import { InlineAlertModule } from "@storeblocks/inline-alert-ng";
import { LinkListModule } from "@storeblocks/link-list-ng";
import { LoadingIndicatorDotsModule } from "@storeblocks/loading-indicator-dots-ng";
import { PopoverModule } from "@storeblocks/popover-ng";
import { RadioButtonsModule } from "@storeblocks/radio-buttons-ng";
import { SwitchModule } from "@storeblocks/switch-ng";
import { TableModule } from "@storeblocks/table-ng";
import { TabsModule } from "@storeblocks/tabs-ng";
import { HighchartsChartModule } from "highcharts-angular";
import { RoleErrorsComponent } from "src/app/core/pages/role-errors/role-errors.component";
import { ListAdvisorAccessesComponent } from "src/app/list-advisor-accesses/list-advisor-accesses.component";
import { OffentligTjenestepensjonEngagementReceiptComponent } from "src/app/modules/shared/components/add-engagement/offentlig-tjenestepensjon-engagement/offentlig-tjenestepensjon-engagement-receipt/offentlig-tjenestepensjon-engagement-receipt.component";
import { OffentligTjenestepensjonEngagementComponent } from "src/app/modules/shared/components/add-engagement/offentlig-tjenestepensjon-engagement/offentlig-tjenestepensjon-engagement.component";
import { ExpandToggleButtonComponent } from "src/app/modules/shared/components/buttons/expand-toggle-button/expand-toggle-button.component";
import { CalculationDisclaimerLinkComponent } from "src/app/modules/shared/components/calculation-disclaimer-link/calculation-disclaimer-link.component";
import { DynamicExternalHtmlContentComponent } from "src/app/modules/shared/components/dynamic-external-html-content/dynamic-external-html-content.component";
import { EngagementAlertMessageComponent } from "src/app/modules/shared/components/engagement-alert-message/engagement-alert-message.component";
import { EngagementListInitiativesExpanderComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives-expander/engagement-list-initiatives-expander.component";
import { EngagementListInitiativesHeaderComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives-header/engagement-list-initiatives-header.component";
import { EngagementListInitiativesItemEngagementComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives-item/engagement-list-initiatives-item-engagement/engagement-list-initiatives-item-engagement.component";
import { EngagementListInitiativesItemHeaderComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives-item/engagement-list-initiatives-item-header/engagement-list-initiatives-item-header.component";
import { EngagementListInitiativesItemNumberComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives-item/engagement-list-initiatives-item-number/engagement-list-initiatives-item-number.component";
import { EngagementListInitiativesItemComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives-item/engagement-list-initiatives-item.component";
import { EngagementListInitiativesComponent } from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives.component";
import { ExpandableErrorAlertComponent } from "src/app/modules/shared/components/expandable-alerts/expandable-error-alert/expandable-error-alert.component";
import { ExpandableDataAlertsComponent } from "src/app/modules/shared/components/expandable-data-alerts/expandable-data-alerts.component";
import { BookingDescriptionFormFieldComponent } from "src/app/modules/shared/components/form-fields/booking-description-form-field/booking-description-form-field.component";
import { EmailFormFieldComponent } from "src/app/modules/shared/components/form-fields/email-form-field/email-form-field.component";
import { PlanNavigationLargeCardComponent } from "src/app/modules/shared/components/large-navigation-cards/plan-navigation-large-card/plan-navigation-large-card.component";
import { NeedsSummaryComponent } from "src/app/modules/shared/components/needs-summary/needs-summary.component";
import { OnboardingSalaryStepComponent } from "src/app/modules/shared/components/onboarding/onboarding-salary-step/onboarding-salary-step.component";
import { TipBannerComponent } from "src/app/modules/shared/components/tips/tip-banner/tip-banner.component";
import { TipIconComponent } from "src/app/modules/shared/components/tips/tip-icon/tip-icon.component";
import { UserSummaryComponent } from "src/app/modules/shared/components/user-summary/user-summary.component";
import { InputYearMaskDirective } from "src/app/modules/shared/directives/input-year-mask.directive";
import { ButtonTrackIdTransformer } from "src/app/modules/shared/directives/track-id/button-track-id.transformer";
import { InputTrackIdTransformer } from "src/app/modules/shared/directives/track-id/input-track-id.transformer";
import { LinkTrackIdTransformer } from "src/app/modules/shared/directives/track-id/link-track-id.transformer";
import { RoleTrackIdTransformer } from "src/app/modules/shared/directives/track-id/role-track-id.transformer";
import { TrackIdErrorService } from "src/app/modules/shared/directives/track-id/track-id-error.service";
import { TrackIdDirective } from "src/app/modules/shared/directives/track-id/track-id.directive";
import { AvailableActionsBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/available-actions-builder";
import { LinkToSmartAccountDetailsActionBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/link-to-smart-account-details-action-builder";
import incomeServices from "../../services/income/strategies";
import { PublicPensionConsentsPrognosisTriggerService } from "../../services/prognoses-services/public-pension-consents-prognosis-trigger.service";
import { AddAfpCtaCardComponent } from "./components/add-afp-cta-card/add-afp-cta-card.component";
import { AddEngagementsButtonsComponent } from "./components/add-engagement/add-engagements-buttons/add-engagements-buttons.component";
import { AfpEngagementComponent } from "./components/add-engagement/afp-engagement/afp-engagement.component";
import { CombinedNewEngagmentComponent } from "./components/add-engagement/combined-new-engagment/combined-new-engagment.component";
import { ExternalEngagementConfirmationComponent } from "./components/add-engagement/external-engagement/external-engagement-confirmation/external-engagement-confirmation.component";
import { ExternalEngagementComponent } from "./components/add-engagement/external-engagement/external-engagement.component";
import { OtherPensionEngagementComponent } from "./components/add-engagement/other-pension-engagement/other-pension-engagement.component";
import { InformationMessageComponent } from "./components/alerts/information-message/information-message.component";
import { AmountCardComponent } from "./components/amount-card/amount-card.component";
import { AmountUnavailableComponent } from "./components/amount-unavailable/amount-unavailable.component";
import { AnnualIncomeChangeLinkComponent } from "./components/annual-income-change-link/annual-income-change-link.component";
import { ArticleCardComponent } from "./components/articles/article-card/article-card.component";
import { ArticleLinkComponent } from "./components/articles/article-link/article-link.component";
import { ArticleListComponent } from "./components/articles/article-list/article-list.component";
import { BankAccountsAuthStepUpComponent } from "./components/bank-accounts-auth-step-up/bank-accounts-auth-step-up.component";
import { BottomSheetComponent } from "./components/bottom-sheet/bottom-sheet.component";
import { BreadcrumbBackButtonComponent } from "./components/breadcrumb-back-button/breadcrumb-back-button.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { BottomNavButtonComponent } from "./components/buttons/bottom-nav-button/bottom-nav-button.component";
import { SmallCardComponent } from "./components/cards/small-card/small-card.component";
import { ChartLegendComponent } from "./components/chart-legend/chart-legend.component";
import { AgreementsChartComponent } from "./components/charts/agreements-chart/agreements-chart.component";
import { ChartComponent } from "./components/charts/chart/chart.component";
import { MarketValueChartComponent } from "./components/charts/market-value-chart/market-value-chart.component";
import { RangedNeedsChartComponent } from "./components/charts/ranged-needs-chart/ranged-needs-chart.component";
import { ChipComponent } from "./components/chip/chip.component";
import { DataStorageConsentComponent } from "./components/consents/data-storage-consent/data-storage-consent.component";
import { OnboardingConsentComponent } from "./components/consents/onboarding-consent/onboarding-consent.component";
import { PurchaseAssistanceConsentComponent } from "./components/consents/purchase-assistance-consent/purchase-assistance-consent.component";
import { ContractActionsBarComponent } from "./components/contract-actions/contract-actions-bar/contract-actions-bar.component";
import { ContractActionsContainerComponent } from "./components/contract-actions/contract-actions-container/contract-actions-container.component";
import { LinkToDetailsPageComponent } from "./components/contract-actions/link-to-details-page/link-to-details-page.component";
import { ContractTypeDescriptionComponent } from "./components/contract-type-description/contract-type-description.component";
import { ContractBalanceAndTotalReturnComponent } from "./components/contracts-list/contract/contract-balance-and-total-return/contract-balance-and-total-return.component";
import { ContractDetailsAnnualReturnPctComponent } from "./components/contracts-list/contract/contract-details-annual-return-pct/contract-details-annual-return-pct.component";
import { ContractComponent } from "./components/contracts-list/contract/contract.component";
import { SupplierTextOrLogoComponent } from "./components/contracts-list/contract/supplier-text-or-logo/supplier-text-or-logo.component";
import { ContractsListComponent } from "./components/contracts-list/contracts-list.component";
import { ConsentAndStepupCtaCardComponent } from "./components/cta-card/consent-and-stepup-cta-card/consent-and-stepup-cta-card.component";
import { NavConsentCtaCardComponent } from "./components/cta-card/consent-and-stepup-cta-card/nav-consent-cta-card/nav-consent-cta-card.component";
import { NorskpensjonConsentCtaCardComponent } from "./components/cta-card/consent-and-stepup-cta-card/norskpensjon-consent-cta-card/norskpensjon-consent-cta-card.component";
import { NorskpensjonRequirementsCtaCardComponent } from "./components/cta-card/consent-and-stepup-cta-card/norskpensjon-requirements-cta-card/norskpensjon-requirements-cta-card.component";
import { CtaCardComponent } from "./components/cta-card/cta-card.component";
import { OffentligTjenestepensjonCtaCardComponent } from "./components/cta-card/offentlig-tjenestepensjon-cta-card/offentlig-tjenestepensjon-cta-card.component";
import { ApiFetchMenuComponent } from "./components/dev/api-fetch-menu/api-fetch-menu.component";
import { DevComponent } from "./components/dev/dev.component";
import { UserSettingsPanelComponent } from "./components/dev/user-settings-panel/user-settings-panel.component";
import { ModalDialogComponent } from "./components/dialogs/modal-dialog/modal-dialog.component";
import { VideoOverlayComponent } from "./components/dialogs/video-overlay/video-overlay.component";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { EngagementBodyAfpComponent } from "./components/engagement-details/engagement-details-afp/engagement-details-afp.component";
import { EngagementDetailsCommonComponent } from "./components/engagement-details/engagement-details-common/engagement-details-common.component";
import { SavingsRateSummaryRowsComponent } from "./components/engagement-details/engagement-details-common/savings-rate-summary-rows/savings-rate-summary-rows.component";
import { EngagementDetailsExternalComponent } from "./components/engagement-details/engagement-details-external/engagement-details-external.component";
import { EngagementDetailsItpUnfundedComponent } from "./components/engagement-details/engagement-details-itp-unfunded/engagement-details-itp-unfunded.component";
import { EngagementDetailsOtherComponent } from "./components/engagement-details/engagement-details-other/engagement-details-other.component";
import { EngagementDetailsPublicPensionComponent } from "./components/engagement-details/engagement-details-public-pension/engagement-details-public-pension.component";
import { PublicPensionContractComponent } from "./components/engagement-details/engagement-details-public-pension/public-pension-contract/public-pension-contract.component";
import { PublicPensionOfaDataComponent } from "./components/engagement-details/engagement-details-public-pension/public-pension-ofa-data/public-pension-ofa-data.component";
import { EngagementDetailsComponent } from "./components/engagement-details/engagement-details.component";
import { EngagementFooterComponent } from "./components/engagement/engagement-footer/engagement-footer.component";
import { ErrorComponent } from "./components/error/error.component";
import { ExpandableAlertComponent } from "./components/expandable-alerts/expandable-alert/expandable-alert.component";
import { ExpansionPanelAccordionComponent } from "./components/expansion-panel-accordion/expansion-panel-accordion.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AfpFormFieldComponent } from "./components/form-fields/afp-form-field/afp-form-field.component";
import { DepthConsentFormFieldComponent } from "./components/form-fields/depth-consent-form-field/depth-consent-form-field.component";
import { LensRadioButtonComponent } from "./components/form-fields/lens-radio-button/lens-radio-button.component";
import { LensRadioGroupComponent } from "./components/form-fields/lens-radio-group/lens-radio-group.component";
import { PartTimeEndAgeFormFieldComponent } from "./components/form-fields/part-time-end-age-form-field/part-time-end-age-form-field.component";
import { PartTimePercentFormFieldComponent } from "./components/form-fields/part-time-percent-form-field/part-time-percent-form-field.component";
import { PhoneNumberFormFieldComponent } from "./components/form-fields/phone-number-form-field/phone-number-form-field.component";
import { PostPensionFutureSalaryFormFieldComponent } from "./components/form-fields/post-pension-future-salary-form-field/post-pension-future-salary-form-field.component";
import { PublicPensionWithdrawalPercentageFormFieldComponent } from "./components/form-fields/public-pension-withdrawal-percentage-form-field/public-pension-withdrawal-percentage-form-field.component";
import { SalaryProviderRadioFormFieldComponent } from "./components/form-fields/salary-provider-radio-form-field/salary-provider-radio-form-field.component";
import { StartPayoutAgeFormFieldComponent } from "./components/form-fields/start-payout-age-form-field/start-payout-age-form-field.component";
import { TotalDebtFormFieldComponent } from "./components/form-fields/total-debt-form-field/total-debt-form-field.component";
import { TotalSavingsFormFieldComponent } from "./components/form-fields/total-savings-form-field/total-savings-form-field.component";
import { YearlyIncomeGrossFormFieldComponent } from "./components/form-fields/yearly-income-gross-form-field/yearly-income-gross-form-field.component";
import { YesNoRadioButtonsComponent } from "./components/form-fields/yes-no-radio-buttons/yes-no-radio-buttons.component";
import { QuoteHeroComponent } from "./components/heroes/quote-hero/quote-hero.component";
import { VideoHeroSecondaryComponent } from "./components/heroes/video-hero-secondary/video-hero-secondary.component";
import { HoldingsDialogComponent } from "./components/holdings-dialog/holdings-dialog.component";
import { IconLinkComponent } from "./components/icons/icon-link/icon-link.component";
import { NumberCircleComponent } from "./components/icons/number-circle/number-circle.component";
import { PinComponent } from "./components/icons/pin/pin.component";
import { RoundedIconComponent } from "./components/icons/rounded-icon/rounded-icon.component";
import { LabeledNumberComponent } from "./components/labeled-number/labeled-number.component";
import { LargeActionsNavigationCardComponent } from "./components/large-navigation-cards/presets/large-actions-navigation-card/large-actions-navigation-card.component";
import { LargeFundCalculatorNavigationCardComponent } from "./components/large-navigation-cards/presets/large-fund-calculator-navigation-card/large-fund-calculator-navigation-card.component";
import { LargeNeedsNavigationCardComponent } from "./components/large-navigation-cards/presets/large-needs-navigation-card/large-needs-navigation-card.component";
import { LottiePlayerComponent } from "./components/lottie-player/lottie-player.component";
import { MenuComponent } from "./components/menu/menu.component";
import { NavProgressComponent } from "./components/nav-progress/nav-progress.component";
import { OnboardingNorskPensjonAndStorageStepComponent } from "./components/onboarding/onboarding-norsk-pensjon-and-storage-step/onboarding-norsk-pensjon-and-storage-step.component";
import { OnboardingPublicPensionStepComponent } from "./components/onboarding/onboarding-public-pension-step/onboarding-public-pension-step.component";
import { PageTitleComponent } from "./components/page-title/page-title.component";
import { PageNotFoundComponent } from "./components/pagenotfound/pagenotfound.component";
import { PartialWithdrawalWarningComponent } from "./components/partial-withdrawal-warning/partial-withdrawal-warning.component";
import { PayoutSimulationAlternativesDialogComponent } from "./components/payout-simulation-alternatives/payout-simulation-alternatives-dialog/payout-simulation-alternatives-dialog.component";
import { PayoutSimulationAlternativesComponent } from "./components/payout-simulation-alternatives/payout-simulation-alternatives.component";
import { PercentageTotalPensionChangeComponent } from "./components/percentage-total-pension-change/percentage-total-pension-change.component";
import { PostPensionPartTimeInputsComponent } from "./components/post-pension-part-time-percent-radio/post-pension-part-time-inputs.component";
import { PromoBoxContainerComponent } from "./components/promo-box-container/promo-box-container.component";
import { PromoBoxComponent } from "./components/promo-box-container/promo-box/promo-box.component";
import { PublicPensionContractBadgeComponent } from "./components/public-pension-contract-badge/public-pension-contract-badge.component";
import { RefreshModalComponent, RefreshModalHostComponent } from "./components/refresh-modal/refresh-modal.component";
import { SimStatusPopoverInlineAlertComponent } from "./components/sim-status-popover-inline-alert/sim-status-popover-inline-alert.component";
import { SimulationHistoryCardItemPensionAgeComponent } from "./components/simulation-history-card/simulation-history-card-item-pension-age/simulation-history-card-item-pension-age.component";
import { SimulationHistoryCardItemTotalPayoutComponent } from "./components/simulation-history-card/simulation-history-card-item-total-payout/simulation-history-card-item-total-payout.component";
import { SimulationHistoryCardItemComponent } from "./components/simulation-history-card/simulation-history-card-item/simulation-history-card-item.component";
import { SimulationHistoryCardValueComponent } from "./components/simulation-history-card/simulation-history-card-value/simulation-history-card-value.component";
import { SimulationHistoryCardComponent } from "./components/simulation-history-card/simulation-history-card.component";
import { SimulationPartialWithdrawalCardComponent } from "./components/simulation-partial-withdrawal-card/simulation-partial-withdrawal-card.component";
import { SimulationPartialWithdrawalInputsComponent } from "./components/simulation-partial-withdrawal-card/simulation-partial-withdrawal-inputs/simulation-partial-withdrawal-inputs.component";
import { SimulationPartialWithdrawalPayoutsComponent } from "./components/simulation-partial-withdrawal-card/simulation-partial-withdrawal-payouts/simulation-partial-withdrawal-payouts.component";
import { ChartSkeletonComponent } from "./components/skeletons/chart-skeleton/chart-skeleton.component";
import { ListSkeletonComponent } from "./components/skeletons/list-skeleton/list-skeleton.component";
import { TextSkeletonComponent } from "./components/skeletons/text-skeleton/text-skeleton.component";
import { SmartAccountBillboardComponent } from "./components/smart-account-billboard/smart-account-billboard.component";
import { SteppableModalComponent } from "./components/steppable-modal/steppable-modal.component";
import { StorebrandOnlyCheckboxComponent } from "./components/storebrand-only-checkbox/storebrand-only-checkbox.component";
import { ToggleButtonGroupComponent } from "./components/toggle-button-group/toggle-button-group.component";
import { A11yClickDirective } from "./directives/a11y-click.directive";
import { AccountNumberMaskDirective } from "./directives/account-number-mask.directive";
import { CurrencyMaskDirective } from "./directives/currency-mask.directive";
import { DiscretePercentageMaskDirective } from "./directives/discrete-percentage-mask.directive";
import { HrefDirective } from "./directives/href.directive";
import { IntegerMaskDirective } from "./directives/integer-mask.directive";
import { LetDirective } from "./directives/let.directive";
import { NgTemplateNameDirective } from "./directives/ng-template-name.directive";
import { NgLetDirective } from "./directives/nglet.directive";
import { AbsoluteValuePipe } from "./pipes/absolute-value.pipe";
import { AccountNumberFormatterPipe } from "./pipes/account-number-formatter.pipe";
import { CurrencyFormatterPipe } from "./pipes/currency-formatter.pipe";
import { FmsPipe } from "./pipes/fms.pipe";
import { FormatNamePipe } from "./pipes/format-name";
import { RoundNumber } from "./pipes/round-number.pipe";
import { SafeUrlPipe } from "./pipes/safe.pipe";
import { TextReplace } from "./pipes/text-replace.pipe";
import { TitlecaseFirstWordPipe } from "./pipes/titlecase-first-word.pipe";
import { YearPipe } from "./pipes/year.pipe";
import {
  AddLumpSumActionBuilder,
  ChangeMonthlySavingsActionBuilder,
  ChangeSavingsProfileOrFundActionBuilder,
  ContractActionsService,
  ConvertToFmiActionBuilder,
  DeleteContractActionBuilder,
  EditContractActionBuilder,
  EkstrapensjonWithdrawalActionBuilder,
  GiveOfaConsentActionBuilder,
  LinkToDetailPageActionBuilder,
  LinkToOnlineBankingActionBuilder,
  LinkToPayoutsWebActionBuilder,
  MoveSavingsToSmartAccountActionBuilder,
} from "./services/contract-actions";
import { ContractDetailsService } from "./services/contract-details.service";
import { FetchHoldingsAndAllocationService } from "./services/fetch-holdings-and-allocation.service";
import { FetchMarketDataService } from "./services/fetch-market-data.service";
import { NavigateToWithdrawalOrderAnchorService } from "./services/navigate-to-withdrawal-order-anchor.service";
import withdrawalProfilesServices from "./services/withdrawal-profiles";

const EXTERNAL_MODULES = [
  AccordionModule,
  AlertModule,
  ButtonModule,
  CardMediumModule,
  CardModule,
  CheckboxModule,
  ChipModule,
  CommonModule,
  DragDropModule,
  FormModule,
  FormsModule,
  HighchartsChartModule,
  InlineAlertModule,
  LinkListModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  OverlayModule,
  RadioButtonsModule,
  ReactiveFormsModule,
  SwitchModule,
  TableModule,
  LoadingIndicatorDotsModule,
  PopoverModule,
  TabsModule,
];

const PIPES = [
  AbsoluteValuePipe,
  AccountNumberFormatterPipe,
  CurrencyFormatterPipe,
  FmsPipe,
  FormatNamePipe,
  RoundNumber,
  SafeUrlPipe,
  TextReplace,
  TitlecaseFirstWordPipe,
  YearPipe,
];

const COMPONENTS = [
  AddAfpCtaCardComponent,
  AddEngagementsButtonsComponent,
  AfpEngagementComponent,
  AfpFormFieldComponent,
  AgreementsChartComponent,
  AmountCardComponent,
  AmountUnavailableComponent,
  AnnualIncomeChangeLinkComponent,
  ApiFetchMenuComponent,
  ArticleCardComponent,
  ArticleLinkComponent,
  ArticleListComponent,
  BankAccountsAuthStepUpComponent,
  BookingDescriptionFormFieldComponent,
  BottomNavButtonComponent,
  BottomSheetComponent,
  BreadcrumbBackButtonComponent,
  BreadcrumbsComponent,
  ChartComponent,
  ChartLegendComponent,
  ChipComponent,
  CombinedNewEngagmentComponent,
  ConsentAndStepupCtaCardComponent,
  ContractActionsContainerComponent,
  ContractActionsBarComponent,
  ContractBalanceAndTotalReturnComponent,
  ContractComponent,
  ContractDetailsAnnualReturnPctComponent,
  ContractsListComponent,
  ContractTypeDescriptionComponent,
  CtaCardComponent,
  DataStorageConsentComponent,
  DepthConsentFormFieldComponent,
  DevComponent,
  DisclaimerComponent,
  DynamicExternalHtmlContentComponent,
  EmailFormFieldComponent,
  EngagementAlertMessageComponent,
  EngagementBodyAfpComponent,
  EngagementDetailsCommonComponent,
  EngagementDetailsComponent,
  EngagementDetailsExternalComponent,
  EngagementDetailsOtherComponent,
  EngagementDetailsPublicPensionComponent,
  EngagementDetailsItpUnfundedComponent,
  EngagementFooterComponent,
  EngagementListInitiativesComponent,
  EngagementListInitiativesExpanderComponent,
  EngagementListInitiativesHeaderComponent,
  EngagementListInitiativesItemComponent,
  EngagementListInitiativesItemEngagementComponent,
  EngagementListInitiativesItemHeaderComponent,
  EngagementListInitiativesItemNumberComponent,
  ErrorComponent,
  ExpandableAlertComponent,
  ExpandableDataAlertsComponent,
  ExpandableErrorAlertComponent,
  ExpandToggleButtonComponent,
  ExpansionPanelAccordionComponent,
  ExternalEngagementComponent,
  ExternalEngagementConfirmationComponent,
  FooterComponent,
  HoldingsDialogComponent,
  IconLinkComponent,
  InformationMessageComponent,
  LabeledNumberComponent,
  LensRadioButtonComponent,
  LensRadioGroupComponent,
  LinkToDetailsPageComponent,
  LottiePlayerComponent,
  MarketValueChartComponent,
  MenuComponent,
  ModalDialogComponent,
  NavProgressComponent,
  NorskpensjonConsentCtaCardComponent,
  NorskpensjonRequirementsCtaCardComponent,
  NorskpensjonRequirementsCtaCardComponent,
  NumberCircleComponent,
  OffentligTjenestepensjonCtaCardComponent,
  OffentligTjenestepensjonEngagementComponent,
  OffentligTjenestepensjonEngagementReceiptComponent,
  OtherPensionEngagementComponent,
  PageNotFoundComponent,
  PageTitleComponent,
  PartialWithdrawalWarningComponent,
  PartTimeEndAgeFormFieldComponent,
  PartTimePercentFormFieldComponent,
  PayoutSimulationAlternativesComponent,
  PayoutSimulationAlternativesDialogComponent,
  PostPensionPartTimeInputsComponent,
  PercentageTotalPensionChangeComponent,
  PhoneNumberFormFieldComponent,
  PinComponent,
  PostPensionFutureSalaryFormFieldComponent,
  PromoBoxComponent,
  PromoBoxContainerComponent,
  PublicPensionContractBadgeComponent,
  PublicPensionContractComponent,
  PurchaseAssistanceConsentComponent,
  QuoteHeroComponent,
  RangedNeedsChartComponent,
  RefreshModalComponent,
  RefreshModalHostComponent,
  RoundedIconComponent,
  SavingsRateSummaryRowsComponent,
  SimStatusPopoverInlineAlertComponent,
  SmallCardComponent,
  SmartAccountBillboardComponent,
  StartPayoutAgeFormFieldComponent,
  SteppableModalComponent,
  StorebrandOnlyCheckboxComponent,
  SupplierTextOrLogoComponent,
  TipBannerComponent,
  TipIconComponent,
  ToggleButtonGroupComponent,
  TotalDebtFormFieldComponent,
  TotalSavingsFormFieldComponent,
  UserSettingsPanelComponent,
  UserSummaryComponent,
  VideoHeroSecondaryComponent,
  VideoOverlayComponent,
  YearlyIncomeGrossFormFieldComponent,
  YesNoRadioButtonsComponent,
  RoleErrorsComponent,
];

const SKELETON_COMPONENTS = [TextSkeletonComponent, ListSkeletonComponent, ChartSkeletonComponent];

const ONBOARDING_COMPONENTS = [
  OnboardingPublicPensionStepComponent,
  OnboardingSalaryStepComponent,
  OnboardingNorskPensjonAndStorageStepComponent,
];

const DIRECTIVES = [
  AccountNumberMaskDirective,
  A11yClickDirective,
  CurrencyMaskDirective,
  DiscretePercentageMaskDirective,
  HrefDirective,
  IntegerMaskDirective,
  LetDirective,
  NgLetDirective,
  NgTemplateNameDirective,
  InputYearMaskDirective,
];

const SERVICES = [
  AddLumpSumActionBuilder,
  AvailableActionsBuilder,
  ChangeMonthlySavingsActionBuilder,
  ChangeSavingsProfileOrFundActionBuilder,
  ContractActionsService,
  ContractDetailsService,
  DeleteContractActionBuilder,
  EditContractActionBuilder,
  EkstrapensjonWithdrawalActionBuilder,
  FetchHoldingsAndAllocationService,
  FetchMarketDataService,
  GiveOfaConsentActionBuilder,
  LinkToDetailPageActionBuilder,
  LinkToOnlineBankingActionBuilder,
  LinkToPayoutsWebActionBuilder,
  ConvertToFmiActionBuilder,
  LinkToSmartAccountDetailsActionBuilder,
  MoveSavingsToSmartAccountActionBuilder,
  NavigateToWithdrawalOrderAnchorService,
  PublicPensionConsentsPrognosisTriggerService,
  ...withdrawalProfilesServices,
  ...incomeServices,
];

@NgModule({
  declarations: [
    COMPONENTS,
    SKELETON_COMPONENTS,
    DIRECTIVES,
    PIPES,
    ONBOARDING_COMPONENTS,
    SimulationHistoryCardComponent,
    SimulationHistoryCardItemComponent,
    SimulationHistoryCardItemTotalPayoutComponent,
    SimulationHistoryCardValueComponent,
    SimulationHistoryCardItemPensionAgeComponent,
    SimulationPartialWithdrawalCardComponent,
    SimulationPartialWithdrawalInputsComponent,
    SimulationPartialWithdrawalPayoutsComponent,
    CalculationDisclaimerLinkComponent,
    NeedsSummaryComponent,
    PlanNavigationLargeCardComponent,
    LargeActionsNavigationCardComponent,
    LargeNeedsNavigationCardComponent,
    LargeFundCalculatorNavigationCardComponent,
    SalaryProviderRadioFormFieldComponent,
    TrackIdDirective,
    PublicPensionOfaDataComponent,
    PublicPensionWithdrawalPercentageFormFieldComponent,
    OnboardingConsentComponent,
    NavConsentCtaCardComponent,
  ],
  imports: [EXTERNAL_MODULES, RouterModule, ListAdvisorAccessesComponent],
  exports: [
    COMPONENTS,
    SKELETON_COMPONENTS,
    DIRECTIVES,
    EXTERNAL_MODULES,
    PIPES,
    RouterModule,
    SimulationHistoryCardComponent,
    SimulationHistoryCardItemComponent,
    SimulationHistoryCardItemPensionAgeComponent,
    SimulationHistoryCardItemTotalPayoutComponent,
    SimulationHistoryCardValueComponent,
    SimulationPartialWithdrawalCardComponent,
    SimulationPartialWithdrawalInputsComponent,
    SimulationPartialWithdrawalPayoutsComponent,
    CalculationDisclaimerLinkComponent,
    NeedsSummaryComponent,
    PlanNavigationLargeCardComponent,
    LargeActionsNavigationCardComponent,
    LargeNeedsNavigationCardComponent,
    LargeFundCalculatorNavigationCardComponent,
    TrackIdDirective,
  ],
  providers: [
    PIPES,
    AsyncPipe,
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: false,
        autoFocus: false,
        restoreFocus: false,
      },
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: { disabled: true },
    },
    ...SKELETON_COMPONENTS,
    ...SERVICES,
    LinkTrackIdTransformer,
    ButtonTrackIdTransformer,
    InputTrackIdTransformer,
    RoleTrackIdTransformer,
    TrackIdErrorService,
  ],
})
export class SharedModule {}
