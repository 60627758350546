<app-action-dialog>
  <ng-container title>
    {{ "actions.smartAccount.dialog.title" | fms }}
  </ng-container>

  <div class="content">
    <div [innerHTML]="'actions.smartAccount.dialog.content' | fms"></div>

    <button
      stbButton="text"
      [setIconAfter]="true"
      iconType="open-in-new-window"
      data-trackid="actions-smartAccountDialog-readMore"
      (click)="readMore()"
    >
      {{ "actions.smartAccount.dialog.secondaryButtonText" | fms }}
    </button>
  </div>

  <ng-container buttons>
    <button stbButton="outlined" mat-dialog-close>
      {{ "dialog.button.close.text" | fms }}
    </button>
  </ng-container>
</app-action-dialog>
