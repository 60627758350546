import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { PresentableEngagementItemField } from "../../engagement-list-initiatives.utils";

@Component({
  selector: "app-engagement-list-initiatives-item-engagement",
  templateUrl: "./engagement-list-initiatives-item-engagement.component.html",
  styleUrls: ["./engagement-list-initiatives-item-engagement.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesItemEngagementComponent {
  @Input()
  public title!: string;

  @Input()
  public description!: string;

  @Input()
  public iconSrc!: string;

  @Input()
  public fields: PresentableEngagementItemField[] = [];
}
