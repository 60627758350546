import { firstValueFrom } from "rxjs";
import { AnyEngagement } from "../services/engagements.service";

export async function getEngagementNameToUseInSentence(engagement: AnyEngagement): Promise<string> {
  const engagementName = (await firstValueFrom(engagement.getNameAsync())).name;
  const engagementNameWithLowerCaseFirstLetter = engagementName.charAt(0).toLocaleLowerCase() + engagementName.slice(1);
  const isAllUppercase = engagementName === engagementName.toUpperCase();

  return isAllUppercase ? engagementName : engagementNameWithLowerCaseFirstLetter;
}
