import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { composeRelativeUrlMergeQueryParams } from "src/app/utils/url";
import { ActionEpkService } from "../../../../modules/features/pension-actions/services/action-epk.service";
import { CardNavigation } from "../dashboard-cards/dashboard-card/dashboard-card.component";
import { NavigationType, getNavigationType } from "../dashboard-cards/dashboard-cards.component";
import { map, Observable } from "rxjs";
import { CustomerService } from "src/app/services/customer.service";
import { getIsNotNullable } from "src/app/utils/utils";

const CARD_VISIBLE_THRESHOLD_AGE = 55;

@Component({
  selector: "app-dashboard-promo-cards",
  templateUrl: "./dashboard-promo-cards.component.html",
  styleUrls: ["./dashboard-promo-cards.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActionEpkService],
})
export class DashboardPromoCardsComponent {
  public readonly showCard$: Observable<boolean>;
  public readonly cardData$: Observable<string>;
  constructor(
    private readonly router: Router,
    private readonly actionEpkService: ActionEpkService,
    private readonly customerService: CustomerService,
  ) {
    this.showCard$ = this.actionEpkService
      .getFirstEpkFromStorebrand()
      .pipe(map((hasAnyEpkInStorebrand) => getIsNotNullable(hasAnyEpkInStorebrand)));

    this.cardData$ = this.customerService.age$.pipe(
      map((age) =>
        age && age < CARD_VISIBLE_THRESHOLD_AGE
          ? "dashboard.promoCard.stockMarket"
          : "dashboard.promoCard.stockMarket.over55",
      ),
    );
  }

  public onCardClick(navigation: CardNavigation): Promise<boolean> | ReturnType<typeof Window.prototype.open> {
    const navigationType = getNavigationType(navigation);
    return this.navigateTo(navigationType, navigation);
  }

  private navigateTo(navigationType: NavigationType, navigation: CardNavigation): Window | Promise<boolean> | null {
    const target = navigation.target ?? "_blank";

    switch (navigationType) {
      case NavigationType.Absolute:
        return window.open(navigation.href, target);
      case NavigationType.Relative:
        return window.open(composeRelativeUrlMergeQueryParams(navigation.href ?? ""), target);
      case NavigationType.RouterLink:
        return this.router.navigate([navigation.routerLink], {
          queryParamsHandling: "merge",
        });
      default:
        return null;
    }
  }
}
