import { ChangeDetectionStrategy, Component } from "@angular/core";
import { map } from "rxjs/operators";
import {
  getEngagementDescription,
  getEngagementTitle,
  getEngagementTotalPayout,
  InitiativeEngagementItem,
} from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives.utils";
import { FmsService } from "src/app/services/fms.service";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";
import { ActionEpkService, AnyEpkEngagement } from "../../../services/action-epk.service";

@Component({
  selector: "app-action-stb-epk-dialog",
  templateUrl: "./action-epk-stb-dialog.component.html",
  styleUrls: ["./action-epk-stb-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActionEpkService],
})
export class ActionEpkStbDialogComponent {
  public readonly items$ = this.actionEpkService
    .getFirstEpkFromStorebrand()
    .pipe(map((engagement) => this.getItems([engagement])));

  constructor(
    private readonly fmsService: FmsService,
    private readonly actionEpkService: ActionEpkService,
  ) {}

  private getItems(engagements: Nullable<AnyEpkEngagement>[]): InitiativeEngagementItem[] {
    const iconSrc = this.fmsService.instant("actions.epk.stb.dialog.items.icon");

    return engagements.filter(getIsNotNullable).map((engagement) => ({
      title: getEngagementTitle(engagement),
      description: getEngagementDescription(engagement),
      iconSrc,
      values: [getEngagementTotalPayout(engagement)],
    }));
  }
}
