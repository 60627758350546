import { AfterViewChecked, Directive, ElementRef, Inject } from "@angular/core";
import { TrackIdErrorService } from "src/app/modules/shared/directives/track-id/track-id-error.service";
import { TRACK_ID_TRANSFORMERS_TOKEN } from "src/app/modules/shared/directives/track-id/track-id-transformers.tokens";

export interface TrackIdTransformer {
  predicate(element: HTMLElement): boolean;
  transform(trackId: string, element: HTMLElement): string;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "a, button, div[role], input[type=checkbox]",
})
export class TrackIdDirective implements AfterViewChecked {
  constructor(
    @Inject(TRACK_ID_TRANSFORMERS_TOKEN) private readonly transformers: TrackIdTransformer[],
    private readonly trackIdErrorService: TrackIdErrorService,
    private readonly el: ElementRef,
  ) {}

  public ngAfterViewChecked(): void {
    const ATTR_NAME = "data-trackid";

    for (const transformer of this.transformers) {
      const element: HTMLElement = this.el.nativeElement;
      const trackId = element.getAttribute(ATTR_NAME);

      if (!trackId) {
        if (!getHasParentWithTrackId(element)) {
          this.trackIdErrorService.add(element);
        }

        return;
      }

      if (!transformer.predicate(element)) {
        continue;
      }

      const newTrackId = transformer.transform(trackId, element);

      element.setAttribute(ATTR_NAME, newTrackId);
    }
  }
}

function getHasParentWithTrackId(element: HTMLElement): boolean {
  return !!element.closest("[data-trackid]");
}
