import { Injectable } from "@angular/core";
import { produce } from "immer";
import { map } from "rxjs";
import { BankEngagement } from "src/app/models/engagements/bank-engagement.model";
import { ExternalSavingsEngagement } from "src/app/models/engagements/external-savings-engagement.model";
import { AbstractNavEngagement } from "src/app/models/engagements/nav-engagement.model";
import { NorskpensjonEngagement } from "src/app/models/engagements/norskpensjon/norskpensjon-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { UnavailableNorskpensjonEngagement } from "../models/engagements/norskpensjon/unavailable-norskpensjon-engagement.model";
import { isNorskpensjonEngagement, isUCITSEngagement } from "../utils/engagement.typeguards";
import { getIsSimulationStatusNotOk } from "../utils/engagement.utils";
import { select } from "../utils/rxjs/select";
import { ClientDataService } from "./customer-supplied-data/client-data.service";
import { EngagementWithLockedPayoutAgeDetectorService } from "./engagement-with-locked-payout-age-detector.service";
import { AnyEngagement } from "./engagements.service";
import { LinkProductCode } from "./contract-list.service";

/**
 * InternalSavingsService encapsulates the logic for managing the
 * internalSavings object in the profile. Encapsulation is
 * necessary because the internalSavings object is shared with
 * yourpensionnumber, meaning we have to maintain identical
 * business rules across projects. This is where these rules
 * live in the nyt project.
 *
 * Do not be tempted to use functionality from this service in
 * other parts of the project. It is written with internalSavings
 * in mind and nothing else.
 */
@Injectable({
  providedIn: "root",
})
export class InternalSavingsService {
  constructor(
    private readonly profileService: ProfileService,
    private readonly engagementWithLockedPayoutAgeDetectorService: EngagementWithLockedPayoutAgeDetectorService,
    private readonly clientDataService: ClientDataService,
  ) {}

  /**
   * Some engagements, like folketrygden, were previously mandatory and could therefore not be excluded / included by the user.
   * These engagements are no longer mandatory, but the method is kept to determine where to store this boolean property.
   */
  public usedToBeOptional(engagement: AnyEngagement): boolean {
    if (getIsSimulationStatusNotOk(engagement)) {
      return false;
    }

    // Special case where norskpensjon engagements are "locked" and needs to be excludable
    if (this.getIsNorskpensjonEngagementAndHasLockedPayoutAge(engagement)) {
      return true;
    }

    switch (engagement.constructor) {
      case NorskpensjonEngagement:
      case UnavailableNorskpensjonEngagement:
      case PublicPensionEngagement:
      case AbstractNavEngagement:
        return false;
      case BankEngagement:
        return ((engagement as BankEngagement).contract?.bookBalance ?? 0) > 0;
      case ExternalSavingsEngagement:
      default:
        return true;
    }
  }

  public isCustomerPension(engagement: AnyEngagement): boolean {
    if (getIsSimulationStatusNotOk(engagement)) {
      return false;
    }

    // Special case where norskpensjon engagements are "locked" and needs to be excludable
    if (this.getIsNorskpensjonEngagementAndHasLockedPayoutAge(engagement)) {
      return !!this.getInternalSaving(engagement)?.isPension;
    }

    switch (engagement.constructor) {
      case ExternalSavingsEngagement:
        return (engagement as ExternalSavingsEngagement).contract.isPension;
      case OtherPensionEngagement:
        return (engagement as OtherPensionEngagement).contract.includeInPension;
      case UnavailableNorskpensjonEngagement:
        return false;
      default: {
        const isPension =
          this.getInternalSaving(engagement)?.isPension ?? this.getEngagementMetadata(engagement)?.isPension;

        return isPension ?? includedByDefault(engagement);
      }
    }
  }

  public async updateIsPension(engagement: AnyEngagement, isPension: boolean): Promise<void> {
    if (this.usedToBeOptional(engagement)) {
      const internalSavings = upsertInternalSavingIsPension(
        this.profileService.internalSavingsAgreements,
        this.getOrCreateDefaultInternalSaving(engagement),
        { isPension },
      );

      await this.profileService.setProfileInternalSavings(internalSavings);
    } else {
      await this.clientDataService.updateAgreementsMetaDataMap(engagement, isPension);
    }
  }

  private getOrCreateDefaultInternalSaving(engagement: AnyEngagement): CustomerSuppliedData.InternalSaving {
    return this.getInternalSaving(engagement) || createInternalSaving(engagement);
  }

  private getInternalSaving(engagement: AnyEngagement): CustomerSuppliedData.InternalSaving | undefined {
    return this.profileService.internalSavingsAgreements.find((obj) => obj.key === engagement.getIdentifier());
  }

  private getEngagementMetadata(engagement: AnyEngagement): CustomerSuppliedData.AgreementMetaData | undefined {
    const key = engagement.getIdentifier();

    return select(this.clientDataService.agreementsMetaDataMap$.pipe(map((dataMap) => dataMap[key])));
  }

  /**
   * Had to use `select` here because there are many services that depend on
   * `isCustomerPension` and `isRequired` where they do not use `observable`
   */
  private getIsNorskpensjonEngagementAndHasLockedPayoutAge(engagement: AnyEngagement): boolean {
    return (
      isNorskpensjonEngagement(engagement) &&
      select(this.engagementWithLockedPayoutAgeDetectorService.getHasLockedPayoutAge(engagement), {
        fallback: false,
      })
    );
  }
}

function isFondskontoP(engagement: AnyEngagement): boolean {
  const productCode = engagement.getProduct()?.productCode;
  return productCode === LinkProductCode.Fokp;
}

export function excludedByDefault(engagement: AnyEngagement): boolean {
  return isFondskontoP(engagement) || isUCITSEngagement(engagement);
}

function includedByDefault(engagement: AnyEngagement): boolean {
  return !excludedByDefault(engagement);
}

function createInternalSaving(engagement: AnyEngagement): CustomerSuppliedData.InternalSaving {
  return {
    key: engagement.getIdentifier(),
    isPension: false,
  };
}

function upsertInternalSavingIsPension(
  internalSavings: CustomerSuppliedData.InternalSaving[],
  internalSaving: CustomerSuppliedData.InternalSaving,
  props: Pick<CustomerSuppliedData.InternalSaving, "isPension">,
): CustomerSuppliedData.InternalSaving[] {
  const updatedSavingsAgreement = {
    ...internalSaving,
    ...props,
  };

  return produce(internalSavings, (draft) => {
    const index = draft.findIndex((obj) => obj.key === updatedSavingsAgreement.key);

    if (index > -1) {
      draft[index] = updatedSavingsAgreement;
    } else {
      draft.push(updatedSavingsAgreement);
    }
  });
}
