import { combineLatest, first, map, Observable, of } from "rxjs";
import { PensionArea, PensionGroup } from "src/app/constants/business.constants";
import { EngagementName, EngagementSimulationStatus } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnyEngagement } from "src/app/services/engagements.service";
import { ArrayType } from "src/app/utils/array";
import { MissingProductNameFmsKey } from "src/app/utils/errors";
import { Monitoring } from "src/app/utils/monitoring";
import { select } from "src/app/utils/rxjs/select";
import { NotNullProps } from "src/app/utils/types";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";
import {
  GenericEngagement,
  getPayoutPlanDuration,
  PAYOUT_DURATIONS,
  PayoutDuration,
} from "../generic-engagement.model";
import { getAverageAnnualPayout, getTotalPayout } from "../generic-graph-engagement.model";
import { getSimulationStatus } from "../graph-engagement.model";

type SavingsEngagementWithOnlySavingsAndPensionContracts = Omit<
  Graph.SavingsEngagement,
  | "__typename"
  | "bankAccounts"
  | "publicPension"
  | "norskpensjon"
  | "nav"
  | "engagementFlag"
  | "simulationParameters"
  | "pensionPayouts"
>;

interface ContractWithBalance {
  balance: number;
}

interface KeyValuesContract {
  keyValues: Graph.KeyValues;
}

export enum ProductCodeLink {
  HYBANS1 = "HYBANS1",
  GARA = "GARA",
  RGARA = "RGARA",
  AVLOS = "AVLOS",
  AVLOSUT = "AVLOSUT",
  RIPO = "RIPO",
  IPO1 = "IPO1",
  IPS2 = "IPS2",
  RIPS2 = "RIPS2",
  IPS2PM = "IPS2PM",
  RIPA = "RIPA",
  IPAE = "IPAE",
  IPAL = "IPAL",
  IPAU = "IPAU",
  IPAA = "IPAA",
  FOKF = "FOKF",
  FOKFU = "FOKFU",
  FOKTS = "FOKTS",
  FOKTSU = "FOKTSU",
  FOKSTI = "FOKSTI",
  RLIV = "RLIV",
  LINK = "LINK",
  LINKE = "LINKE",
  LINKP = "LINKP",
  LINKU = "LINKU",
  RLINKP = "RLINKP",
  FOKL = "FOKL",
  FOKP = "FOKP",
  RFOKL = "RFOKL",
}

export enum ProductCodeCollective {
  LLBB = "LLBB",
  LLCB = "LLCB",
  LLHB = "LLHB",
  LLHC = "LLHC",
  LLKB = "LLKB",
  LLKC = "LLKC",
  LLPB = "LLPB",
  LPHA = "LPHA",
  LPHB = "LPHB",
  LPHC = "LPHC",
  LPHD = "LPHD",
  LPHO = "LPHO",
  LPKA = "LPKA",
  LPKB = "LPKB",
  LPKC = "LPKC",
  LPKO = "LPKO",
  LPLB = "LPLB",
  LPLO = "LPLO",
  LPPB = "LPPB",
  LPPO = "LPPO",
  LPUA = "LPUA",
  LPUO = "LPUO",
  LPFB = "LPFB",
  LPRA = "LPRA",
  LLTB = "LLTB",
  LLTC = "LLTC",
  LPTA = "LPTA",
  LPTB = "LPTB",
  LPTC = "LPTC",
  LPTO = "LPTO",
  LPTD = "LPTD",
  LLTD = "LLTD",
  ITP1 = "ITP1",
  PKB1 = "PKB1",
  HYBANS1 = "HYBANS1",
  FPI1 = "FPI1",
  LIHH = "LIHH",
  LIHF = "LIHF",
}

export type AnySavingsAndPensionContract = ArrayType<
  SavingsEngagementWithOnlySavingsAndPensionContracts[keyof SavingsEngagementWithOnlySavingsAndPensionContracts]
>;

interface NameableContract {
  customerSuppliedContractName?: Nullable<string>;
  employer?: Nullable<Graph.Employer>;
}

type PartialEngagementName = Omit<EngagementName, "payer"> & Partial<Pick<EngagementName, "payer">>;

export abstract class SavingsAndPensionEngagement<C extends AnySavingsAndPensionContract> extends GenericEngagement<
  C,
  Graph.Prognosis,
  Graph.PrognosisParametersInput
> {
  public constructor(contract: C, prognosis?: Graph.Prognosis) {
    super(contract, prognosis);
  }

  public getDefaultNameAsync(contract: NameableContract): Observable<PartialEngagementName> {
    const productNameFmsKey = this.contract.productNameFmsKey;

    // All products should have an fms key. If it's missing we issue
    // a warning such that we can prompt the backend to supply it.
    if (!productNameFmsKey) {
      Monitoring.warn(new MissingProductNameFmsKey(this.contract));
    }

    const fmsService = this.getFmsService();

    const productNameFmsKey$ = !!productNameFmsKey
      ? fmsService.translateAsync<string>(productNameFmsKey)
      : of(undefined);

    return combineLatest([
      productNameFmsKey$,
      fmsService.translateAsync<string>(`${productNameFmsKey}.description`, {
        logEmpty: false,
      }),
      fmsService.translateAsync<string>("sharedProductNames.unknown_product_group"),
      fmsService.translateAsync<string>("GenericEngagement.supplier"),
    ]).pipe(
      first(),
      map(([productName, description, unnamedProductName, supplier]) => ({
        name: contract.customerSuppliedContractName ?? productName ?? unnamedProductName,
        description,
        supplier,
        payer: contract?.employer?.name,
      })),
    );
  }

  public getPayoutFromAge(): number | undefined {
    const firstPayoutPlan = this.getPayoutPlan().at(0);
    return firstPayoutPlan?.age ?? undefined;
  }

  public getPayoutPlan(): NotNullProps<Graph.PayoutPlan>[] {
    return (this.prognosis?.payoutPlan ?? []) as NotNullProps<Graph.PayoutPlan>[];
  }

  public getContinousPeriods(): unknown[] {
    return [];
  }

  public getId(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.contract!.id!;
  }

  /**
   * @deprecated
   */
  public getIdentifier(): string {
    return this.getId();
  }

  public getSimulationStatus(): EngagementSimulationStatus[] {
    return [getSimulationStatus(this.prognosis?.simulationStatus)];
  }

  public getAverageAnnualPayout(fromAge?: number, toAge?: number): number | null {
    const payoutPlan = this.getPayoutPlan();

    return getAverageAnnualPayout(payoutPlan, fromAge, toAge);
  }

  public getTotalPayout(): number | null {
    const payoutPlan = this.getPayoutPlan();
    const totalPayout = getTotalPayout(payoutPlan);

    return totalPayout || null;
  }

  public getFirstYearPayout(): number {
    const firstYear = this.getPayoutPlan().at(0);
    return firstYear?.amount ?? 0;
  }

  public getPayoutDuration(): PayoutDuration {
    if (this.isLifelongPayout()) {
      return PAYOUT_DURATIONS.infinite;
    }

    return getPayoutPlanDuration(this.getPayoutPlan());
  }

  public isLifelongPayout(): boolean {
    return !!this?.prognosis?.lifeLong;
  }

  public hasStipultatedBaseRatePayout(): boolean {
    return false;
  }

  public getPensionArea(): PensionArea {
    return "TODO: contract.category" as PensionArea;
  }

  public getPensionGroup(): PensionGroup {
    return "TODO: contract.subCategory" as PensionGroup;
  }

  public isPublicPensionFromNorskPensjon(): boolean {
    return false;
  }

  public getBalance(): Nullable<number> {
    if (isContractWithBalance(this.contract) && this.contract.balance > 0) {
      return this.contract.balance;
    }

    if (getIsKeyValuesContract(this.contract)) {
      return this.contract?.keyValues?.marketValue?.value;
    }

    return null;
  }

  public isEqualTo(other: AnyEngagement): boolean {
    return other.getContractNumber() === this.getContractNumber();
  }

  public getXCorrelationIdPrognosis(): Nullable<string> {
    return this.prognosis?.xCorrelationId;
  }

  public getCompressionLimits(): number[] {
    return [];
  }

  public getSelectableInvestmentProfileInPayoutPeriod(): Graph.InvestmentProfileInPayoutPeriod[] {
    return [];
  }

  public isOneTimePayout(): boolean {
    return Boolean(this.prognosis?.oneTimePayout);
  }

  public isCompressed(): boolean {
    return Boolean(this.prognosis?.compressed);
  }

  /**
   * @deprecated used for diffing purposes. To be disposed with TRM-2589.
   */
  public getApiResource(): string {
    return "SavingsRestEngagement";
  }

  /** @deprecated Use getDefaultNameAsync instead */
  protected getDefaultName(contract: NameableContract): PartialEngagementName {
    return select(this.getDefaultNameAsync(contract));
  }
}

function getIsKeyValuesContract<T extends AnySavingsAndPensionContract>(
  contract: T,
): contract is T & KeyValuesContract {
  return getIsNotNullable((contract as KeyValuesContract)?.keyValues);
}

export function isContractWithBalance(contract: any): contract is ContractWithBalance {
  return (contract as ContractWithBalance).balance != null;
}
