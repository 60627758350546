import { Injectable, Renderer2, Type, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface SteppableModalStep {
  beforeNext?(this: SteppableModalStep): boolean | Promise<boolean> | Observable<boolean>;
  next?(this: SteppableModalStep): void | Promise<void> | Observable<void>;
  getTitle(): Observable<string>;
}

export interface Metadata {
  [key: string]: any;
}

export interface Step {
  component: Type<SteppableModalStep>;
  nextButtonText?: string;
  previousButtonText?: string;
  cancelable?: boolean;
  metadata?: Metadata;
  onRender?(metadata?: Metadata): void;
  onNext?(metadata?: Metadata): void;
}

export interface InstancedStep extends Step {
  element: any;
  instance: SteppableModalStep;
}

@Injectable()
export class DynamicStepNavigationService {
  private instancedSteps: InstancedStep[] = [];
  private readonly currentStepIndex$ = new BehaviorSubject<number>(0);

  constructor(private readonly renderer: Renderer2) {}

  public get currentStep$(): Observable<InstancedStep> {
    return this.currentStepIndex$.pipe(map((currentIndex) => this.instancedSteps[currentIndex]));
  }

  public initialize(steps: Step[], viewContainerRef: ViewContainerRef): void {
    this.instancedSteps = this.getInstancedSteps(steps, viewContainerRef);

    this.showComponentWithIndex(0);
  }

  public isFirstStep(): boolean {
    return this.getCurrentIndex() === 0;
  }

  public isLastStep(): boolean {
    return this.getCurrentIndex() === this.instancedSteps.length - 1;
  }

  public isMultipleSteps(): boolean {
    return this.instancedSteps.length > 1;
  }

  public getCurrentStep(): InstancedStep {
    return this.getStepByIndex(this.getCurrentIndex());
  }

  public next(): void {
    this.navigate(1);
  }

  public previous(): void {
    this.navigate(-1);
  }

  private navigate(increment: number): void {
    const nextIndex = this.getNextIndex(increment);
    this.showComponentWithIndex(nextIndex);
    this.currentStepIndex$.next(nextIndex);
  }

  private getStepByIndex(index: number): InstancedStep {
    return this.instancedSteps[index];
  }

  private getCurrentIndex(): number {
    return this.currentStepIndex$.getValue();
  }

  private getNextIndex(increments = 1): number {
    const minIndex = 0;
    const maxIndex = this.instancedSteps.length - 1;
    const nextIndex = this.getCurrentIndex() + increments;

    return increments > 0 ? Math.min(maxIndex, nextIndex) : Math.max(minIndex, nextIndex);
  }

  private showComponentWithIndex(index: number): void {
    this.instancedSteps.forEach((step, stepIndex) => {
      const isCurrentStep = stepIndex === index;

      if (isCurrentStep) {
        this.showElement(step.element);
      } else {
        this.hideElement(step.element);
      }
    });
  }

  private showElement(element: any): void {
    this.renderer.setAttribute(element, "style", "display: block;");
  }

  private hideElement(element: any): void {
    this.renderer.setAttribute(element, "style", "display: none;");
  }

  private getInstancedSteps(steps: Step[], viewContainerRef: ViewContainerRef): InstancedStep[] {
    return steps.map((step) => {
      const { instance, location } = viewContainerRef.createComponent(step.component);

      return {
        cancelable: true,
        instance,
        element: location.nativeElement,
        ...step,
      };
    });
  }
}
