import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, first } from "rxjs/operators";
import { OnboardingService, OnboardingStepKey } from "src/app/services/onboarding.service";

@Injectable({ providedIn: "root" })
export class NavigateToService {
  constructor(
    private readonly router: Router,
    private readonly onboardingService: OnboardingService,
  ) {}

  public navigateToUrlWithOnboarding(url: string): void {
    this.onboardingService
      .shouldOpen()
      .pipe(
        first(),
        catchError(async () => this.navigateWithOnboardingSteps(url)),
      )
      .subscribe((shouldOpen) => {
        if (shouldOpen) {
          this.navigateWithOnboardingSteps(url);
        } else {
          this.router.navigate([url], {
            queryParamsHandling: "merge",
          });
        }
      });
  }

  public navigateToUrlWithOnlyConsentOnboarding(url: string): void {
    this.onboardingService
      .isMissingConsensts()
      .pipe(first())
      .subscribe((isMissingConsensts) => {
        if (isMissingConsensts) {
          this.navigateWithOnboardingSteps(url, [OnboardingStepKey.NorskPensjonAndStorageConsent]);
        } else {
          this.router.navigate([url], {
            queryParamsHandling: "merge",
          });
        }
      });
  }

  public navigateWithExitUrl(route: string, exitUrl = this.router.url): Promise<boolean> {
    const exitUrlWithoutQueryParams = exitUrl.split("?").at(0);

    return this.router.navigate([route], {
      queryParams: { exitUrl: exitUrlWithoutQueryParams },
      queryParamsHandling: "merge",
    });
  }

  private navigateWithOnboardingSteps(url: string, stepKeys?: OnboardingStepKey[]): void {
    this.onboardingService.open(
      {
        onComplete: () =>
          this.router.navigate([url], {
            queryParamsHandling: "merge",
          }),
      },
      stepKeys,
    );
  }
}
