import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { ContractTypeOf } from "src/app/models/engagements/generic-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { OtherPensionPrognosisService } from "src/app/services/prognoses-services/other-pension-prognosis.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { toArrayOrEmpty } from "src/app/utils/array";
import { ClientDataService } from "../customer-supplied-data/client-data.service";
import { DEFAULT_SIMULATION_AGE } from "src/app/constants/business.constants";

export interface OtherPensionPrognosisParams {
  startPayoutAge: number;
}

type OtherPensionEngagementPropertyNames =
  | "includeInPension"
  | "periods"
  | "title"
  | "payer"
  | "replaceOffentligTjenestepensjon";

type EngagementPatch = Pick<ContractTypeOf<OtherPensionEngagement>, OtherPensionEngagementPropertyNames>;

const OTHER_PENSION_CONFIG: ServiceConfig = {
  name: "OtherPension",
  fmsKey: "otherPension",
};

@Injectable({
  providedIn: "root",
})
export class OtherPensionEngagementsService extends AbstractPrognosisFetchService<
  OtherPensionEngagement,
  OtherPensionPrognosisParams
> {
  private readonly startPayoutAge$ = this.clientDataService.startPayoutAge$.pipe(
    map((age) => age ?? DEFAULT_SIMULATION_AGE),
  );

  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly clientDataService: ClientDataService,
    private readonly otherPensionPrognosisService: OtherPensionPrognosisService,
  ) {
    super(OTHER_PENSION_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);

    this.clientDataService.otherPensions$.pipe(switchMap(() => this.fetchEngagements())).subscribe();
  }

  public fetchPrognosis(
    engagement: OtherPensionEngagement,
    params: OtherPensionPrognosisParams,
  ): Observable<FetchPrognosisResult<OtherPensionEngagement>> {
    return this.clientDataService.isSimulationByEngagementEnabled$.pipe(take(1)).pipe(
      switchMap((isSimulationByEngagementEnabled) => {
        // If simulation by engagement is enabled, we use the start payout age from the engagement
        const startPayoutAge = isSimulationByEngagementEnabled
          ? engagement.contract?.periods[0]?.fromAge
          : params.startPayoutAge;
        return from(this.otherPensionPrognosisService.getPrognosis(engagement, startPayoutAge)).pipe(
          map((prognosis) => (<Graph.Prognosis[]>[]).concat(prognosis)),
          map((prognoses) => ({ prognoses })),
        );
      }),
    );
  }

  public patchEngagement(engagement: OtherPensionEngagement, patch: Partial<EngagementPatch>): void {
    const { includeInPension, periods, title, payer, replaceOffentligTjenestepensjon } = patch;

    this.clientDataService.updateOtherPension(engagement.getIdentifier(), {
      includeInPension,
      periods,
      title,
      payer,
      replaceOffentligTjenestepensjon,
    });
  }

  public createEngagement(data: Omit<EngagementPatch, "includeInPension">): void {
    const { periods, title, payer, replaceOffentligTjenestepensjon } = data;

    this.clientDataService.createOtherPension({
      periods,
      title,
      payer,
      replaceOffentligTjenestepensjon,
    });
  }

  public deleteEngagement(engagement: OtherPensionEngagement): void {
    this.clientDataService.deleteOtherPension(engagement.getIdentifier());
  }

  protected _fetchEngagements(): Observable<OtherPensionEngagement[]> {
    return this.clientDataService.otherPensions$.pipe(
      take(1),
      map(toArrayOrEmpty),
      map((contracts) => contracts.map((contract) => new OtherPensionEngagement(contract))),
    );
  }

  protected prognosisSimParams$(): Observable<OtherPensionPrognosisParams> {
    return this.startPayoutAge$.pipe(map((startPayoutAge) => ({ startPayoutAge })));
  }
}
