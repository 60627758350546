<ng-container *ngIf="isSupplierStorebrand$ | async; else IsSupplierNav">
  <img [src]="'contractsComponent.supplierLogo.storebrand' | fms" alt="" />
</ng-container>

<ng-template #IsSupplierNav>
  <ng-container *ngIf="isSupplierNav; else IsOtherSupplier">
    <img [src]="'contractsComponent.supplierLogo.nav' | fms" alt="" />
  </ng-container>
</ng-template>

<ng-template #IsOtherSupplier>
  <div class="paragraph-long text">{{ supplier$ | async }}</div>
</ng-template>
