import { BreakpointObserver } from "@angular/cdk/layout";
import { DatePipe } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { differenceInYears } from "date-fns";
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  filter,
  map,
  mergeMap,
  of,
  startWith,
  switchMap,
  take,
} from "rxjs";
import { HYPHEN_SEPARATOR, OBSERVER_BREAKPOINT_MD_MIN } from "src/app/constants/technical.constants";
import { EpkEmploymentEngagement } from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import { MarketValueChartData } from "src/app/modules/shared/components/charts/market-value-chart/market-value-chart.component";
import { CurrencyFormatterPipe } from "src/app/modules/shared/pipes/currency-formatter.pipe";
import {
  ContractDetailsService,
  isContractWithSavingsRate,
} from "src/app/modules/shared/services/contract-details.service";
import {
  FetchMarketDataService,
  isEngagementWithMarketAndKeyValues,
} from "src/app/modules/shared/services/fetch-market-data.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import {
  AnySavingsAndPensionEngagement,
  SavingsAndPensionService,
} from "src/app/services/prognoses-services/savings-and-pension.service";
import { getIsSmartAccount } from "src/app/utils/engagement.utils";
import { Nullable, getIsNotNullable, getIsNullable } from "src/app/utils/utils";
import { AnnualReturn } from "./contract-details-annual-return-pct/contract-details-annual-return-pct.component";
import { getEngagementNameToUseInSentence } from "src/app/utils/engagementName";
import { isNavFromNpEngagement } from "src/app/utils/engagement.typeguards";

interface Header {
  title: string;
  subtitle: string;
}

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractComponent implements OnInit, AfterViewInit {
  @Input()
  public contract!: AnyEngagement;

  @Input()
  public loading!: boolean;

  @Input()
  public expanded = false;

  @Input()
  public defaultExpanded = false;

  public disableAnimation = true;

  public showDescriptionTooltip!: boolean;
  public hasMovedEpkOut!: boolean;
  public hasEmployerAnnualSavingsAmount$!: Observable<boolean>;
  public getFormattedEmployerAnnualSavingsAmount$!: Observable<number | string>;
  public contractName!: Promise<string>;

  public readonly description$ = new BehaviorSubject<Nullable<string>>(null);
  public readonly showMarketValueChart$: Observable<boolean>;
  public readonly marketValueChartData$: Observable<MarketValueChartData>;
  public readonly annualReturn$: Observable<AnnualReturn | undefined>;

  public isExpanded$ = new BehaviorSubject<boolean>(false);
  public isMobile$: Observable<boolean>;

  constructor(
    private readonly contractDetailsService: ContractDetailsService,
    private readonly savingsAndPensionService: SavingsAndPensionService,
    private readonly currencyFormatterPipe: CurrencyFormatterPipe,
    private readonly fetchMarketDataService: FetchMarketDataService,
    private readonly windowSizeObserver: BreakpointObserver,
    private readonly fmsService: FmsService,
    private readonly datePipe: DatePipe,
  ) {
    this.isMobile$ = this.windowSizeObserver.observe(OBSERVER_BREAKPOINT_MD_MIN).pipe(map(({ matches }) => !matches));

    const engagementWithMarketAndKeyValues$ = this.findEqualGraphEngagement().pipe(
      filter(isEngagementWithMarketAndKeyValues),
    );

    const annualReturnDescriptions$ = engagementWithMarketAndKeyValues$.pipe(
      switchMap((engagement) => this.fetchMarketDataService.fetchFirstMarketValueDate(engagement)),
      map((date) => this.datePipe.transform(date, "dd.LL.yyyy")),
      switchMap((date) =>
        combineLatest([
          this.fmsService.translateAsync("engagement.label.shorterAverageAnnualReturn", {
            args: { date },
          }),
          this.fmsService.translateAsync("engagement.label.averageAnnualReturn"),
        ]),
      ),
      map(([thisYearDescription, annualDescription]) => ({
        thisYearDescription,
        annualDescription,
      })),
    );

    this.annualReturn$ = engagementWithMarketAndKeyValues$.pipe(
      switchMap((engagement) =>
        combineLatest([
          this.fetchMarketDataService.fetchAverageAnnualReturnPct(engagement),
          this.fetchMarketDataService.fetchTotalReturnPctDate(engagement),
          this.fetchMarketDataService.fetchFirstMarketValueDate(engagement),
          annualReturnDescriptions$,
        ]),
      ),
      map(([annualReturn, totalReturn, firstMarketValueDate, { thisYearDescription, annualDescription }]) => {
        if (isWithin366days(firstMarketValueDate, new Date())) {
          return {
            description: thisYearDescription,
            pct: totalReturn,
          };
        }

        return {
          description: annualDescription,
          pct: annualReturn,
        };
      }),
      catchError(() => of(undefined)),
    );

    this.marketValueChartData$ = engagementWithMarketAndKeyValues$.pipe(
      switchMap((engagement) => this.fetchMarketDataService.fetchMarketValueChartData(engagement)),
    );

    const hasMarketValueData$ = this.marketValueChartData$.pipe(
      map((data) => !!(data?.marketValueData?.length && data?.paidInData?.length)),
    );

    this.showMarketValueChart$ = combineLatest([
      engagementWithMarketAndKeyValues$.pipe(map(() => true)),
      hasMarketValueData$.pipe(startWith(true)),
    ]).pipe(
      map(
        ([engagementWithMarketAndKeyValues, hasMarketValueData]) =>
          engagementWithMarketAndKeyValues && hasMarketValueData,
      ),
      catchError(() => of(false)),
    );
  }

  public ngOnInit(): void {
    if (this.defaultExpanded) {
      this.isExpanded$.next(this.defaultExpanded);
    }

    this.showDescriptionTooltip = shouldshowDescriptionTooltip(this.contract);

    if (this.showDescriptionTooltip) {
      getContractDescription(this.contract).then((value) => {
        this.description$.next(value);
      });
    }

    this.hasMovedEpkOut = this.hasMovedEpkOutFromSTB();

    this.hasEmployerAnnualSavingsAmount$ = this.getEmployerAnnualSavingsAmount().pipe(
      map((amount) => amount !== undefined),
    );

    this.getFormattedEmployerAnnualSavingsAmount$ = this.getEmployerAnnualSavingsAmount().pipe(
      map((amount) => this.currencyFormatterPipe.transform(amount, "end") || ""),
    );
  }

  public ngAfterViewInit(): void {
    if (this.contract) {
      this.contractName = getEngagementNameToUseInSentence(this.contract);
    }
  }

  public getSupplier(): string {
    return this.contract.getName().supplier || "";
  }

  public getPayer(): string {
    return this.contract.getName()?.payer || "";
  }

  public getHeader(): Header {
    const { name, payer, supplier } = this.contract.getName();

    return {
      title: name,
      subtitle: getIsSmartAccount(this.contract) ? "" : [supplier, payer].filter(Boolean).join(HYPHEN_SEPARATOR),
    };
  }

  public expandedChange(expanded: boolean): void {
    this.isExpanded$.next(expanded);
  }

  public hasMovedEpkOutFromSTB(): boolean {
    if (!(this.contract instanceof EpkEmploymentEngagement)) {
      return false;
    }

    // If the epkFleksibelContract id field is empty on an EpkEmploymentEngagement, we can assume it has been moved out from STB
    return getIsNullable(this.contract.contract.epkFleksibelContract?.id);
  }

  private getEmployerAnnualSavingsAmount(): Observable<number> {
    return this.findEqualGraphEngagement().pipe(
      mergeMap((engagement) =>
        this.contractDetailsService.fetchContractDetails(engagement).pipe(
          filter(isContractWithSavingsRate),
          map((details) => details.employerAnnualSavingsAmount),
          filter(getIsNotNullable),
        ),
      ),
    );
  }

  private findEqualGraphEngagement(): Observable<AnySavingsAndPensionEngagement> {
    return this.savingsAndPensionService.fetchEngagements().pipe(
      take(1),
      map((engagements) => engagements.find((engagement) => engagement.isEqualTo(this.contract))),
      filter(getIsNotNullable),
    );
  }
}

export async function getContractDescription(engagement: AnyEngagement): Promise<Nullable<string>> {
  return engagement?.getName()?.description ?? "";
}

export function shouldshowDescriptionTooltip(engagement: AnyEngagement): boolean {
  return getIsNotNullable(getContractDescription(engagement)) && !isNavFromNpEngagement(engagement);
}

export function isWithin366days(fromDate: Date, toDate: Date): boolean {
  return differenceInYears(fromDate, toDate) === 0;
}
