<app-action-card
  [title]="'actions.epk.stb.title' | fms"
  [icon]="'actions.epk.stb.icon' | fms"
  [content]="'actions.epk.stb.content' | fms"
  [primaryButton]="'actions.epk.stb.primaryButton' | fms"
  [secondaryButton]="'actions.epk.stb.secondaryButton' | fms"
  (primaryButtonClick)="goToWithdrawalOrderPage()"
  (secondaryButtonClick)="openChangeStbEpkDialog()"
  [action]="action"
>
</app-action-card>
