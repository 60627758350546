import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouteKey, routes } from "../../../../routes";

@Component({
  selector: "app-actions-page",
  templateUrl: "./actions-page.component.html",
  styleUrls: ["./actions-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsPageComponent {
  public readonly frontPageRoute = routes[RouteKey.Root];
}
