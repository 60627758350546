import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export interface HeroDetails {
  title: string;
  subline?: string;
  imageUrl: string;
  imageAlt: string;
  imageText: string;
  imageSubline: string;
}

@Component({
  selector: "app-quote-hero",
  templateUrl: "./quote-hero.component.html",
  styleUrls: ["./quote-hero.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteHeroComponent {
  @Input()
  public readonly heroDetails: Partial<HeroDetails> = {};
  @Input()
  public readonly illustration: boolean = false;
}
