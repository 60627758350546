import { Injectable } from "@angular/core";
import { xor } from "lodash-es";
import { combineLatest, first, merge, Observable, of } from "rxjs";
import { catchError, combineLatestWith, map, mergeMap, skip, switchMap, take, tap } from "rxjs/operators";
import { NorskpensjonEngagement } from "src/app/models/engagements/norskpensjon/norskpensjon-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  PrognosisQueryResult,
  ServiceConfig,
  toFetchPrognosisResult,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import {
  createGraphSimulationError,
  SimulationErrorCmsKey,
} from "src/app/services/prognoses-services/simulation-result.creators";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { ArrayType } from "src/app/utils/array";
import { MissingNorskpensjonPrognosisError } from "src/app/utils/prognosis-errors";
import { filterByPredicates } from "src/app/utils/rxjs/pipes";
import { combinePredicates, getIsNotNullable, ignoreReturn, Nullable } from "src/app/utils/utils";
import { NorskPensjonQueriesService } from "../api/norskpensjon-queries.service";
import { SimulationParametersQueriesService } from "../api/simulation-parameters-queries.service";
import { createFullWithdrawalVariables, WithdrawalPeriodsParams } from "../api/withdrawal-periods-variables.utils";
import { IncomeService } from "../income/income.service";
import { NorskpensjonRequirementsService } from "./norskpensjon-requirements.service";

type NorskpensjonPrognosisParams = WithdrawalPeriodsParams;

type FetchPrognosesParams = CustomerSuppliedData.SimulationParametersByEngagement<NorskpensjonPrognosisParams>[1];

export const NP_CONFIG: ServiceConfig = {
  name: "NorskpensjonService",
  fmsKey: "norskpensjon",
};

@Injectable({
  providedIn: "root",
})
export class NorskpensjonService extends AbstractPrognosisFetchService<
  NorskpensjonEngagement,
  NorskpensjonPrognosisParams
> {
  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
    private readonly norskPensjonQueriesService: NorskPensjonQueriesService,
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly incomeService: IncomeService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    super(NP_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);

    this.fetchEngagementsRequirementsChange().subscribe();
  }

  /**
   * When fetching prognoses by engagement it is possble
   * to get duplicates because softId is not guaranteed to be unique.
   * If we get duplicate prognoses we try to use 'sortIndexWithinPayer'
   * to get the correct one, if it does not exist we get the first one.
   */
  public fetchPrognosis(
    engagement: NorskpensjonEngagement,
    params: FetchPrognosesParams,
  ): Observable<FetchPrognosisResult<NorskpensjonEngagement>> {
    const getNorskpensjonContractsById = makeGetNorskpensjonContractsById(engagement.getSoftId());
    const norskpensjonArgs = createFullWithdrawalVariables(params);

    return this.isAllowedToFetchPrognosis().pipe(
      first(),
      tap((allowedToFetchPrognosis) => {
        if (!allowedToFetchPrognosis) {
          throw new Error("Not allowed to fetch prognosis!");
        }
      }),
      mergeMap(() =>
        this.norskPensjonQueriesService.getNorskpensjonContractsWithPrognosis(norskpensjonArgs).pipe(
          map(getNorskpensjonContractsById),
          map(getNorskpensjonContractsPrognosis),
          map(makeGetIndexSortedPrognosis(engagement)),
          map((prognosis) => toFetchPrognosisResult(prognosis, norskpensjonArgs)),
        ),
      ),
      catchError(() => of({ prognoses: [] })),
    );
  }

  public prefetchPrognosesInRange(): Observable<void> {
    const prognosisSimParams$ = this.prognosisSimParams$().pipe(first());

    return this.getPrognosisParamsRange(prognosisSimParams$).pipe(
      filterByPredicates([this.isAllowedToFetchPrognosis()]),
      map((paramsRange) =>
        paramsRange
          .map((params) => createFullWithdrawalVariables(params))
          .map((norskpensjonArgs) =>
            ignoreReturn(this.norskPensjonQueriesService.getNorskpensjonContractsWithPrognosis(norskpensjonArgs)),
          ),
      ),
      mergeMap((queries$) => merge(...queries$)),
    );
  }

  public getProviderNameFromPublicPension(): Observable<string | undefined> {
    return this.engagements$.pipe(
      map(
        (engagements) =>
          engagements.find((engagement) => engagement.isPublicPensionFromNorskPensjon())?.getName()?.supplier,
      ),
    );
  }

  protected _fetchEngagements(): Observable<NorskpensjonEngagement[]> {
    return this.isAllowedToFetchContracts().pipe(
      first(),
      tap((allowedToFetchContracts) => {
        if (!allowedToFetchContracts) {
          throw new Error("Not allowed to fetch contracts!");
        }
      }),
      mergeMap(() =>
        this.norskPensjonQueriesService.getNorskpensjonContracts().pipe(map(toEngagementModelsWithSortIndex)),
      ),
      catchError(() => of([])),
    );
  }

  protected prognosisSimParams$(): Observable<NorskpensjonPrognosisParams> {
    return combineLatest([
      this.incomeService.getAnnualGrossIncomeOrDefault(0),
      this.commonParametersService.postPensionPartTimePercent$,
      this.startPayoutAgeService.getStartPayoutAge(),
      this.getRetirementAgesOrEmpty(),
    ]).pipe(
      map(([salary, partTimePercent, startPayoutAge, retirementAges]) => ({
        salary,
        partTimePercent,
        startPayoutAge,
        retirementAges,
      })),
    );
  }

  protected composeFailedPrognosis(
    engagement: NorskpensjonEngagement,
    isAgeHigherThanMaxSimAge?: boolean,
  ): Graph.Prognosis {
    const key = isAgeHigherThanMaxSimAge ? SimulationErrorCmsKey.AboveMaxAgeError : SimulationErrorCmsKey.GenericError;

    return {
      simulationStatus: createGraphSimulationError(key),
    };
  }

  protected composeMissingPrognosisError(engagement: NorskpensjonEngagement): MissingNorskpensjonPrognosisError {
    return new MissingNorskpensjonPrognosisError(engagement);
  }

  private getPrognosisParamsRange<T>(params$: Observable<T>): Observable<T[]> {
    return this.startPayoutAgeService.getStartPayoutAgeRangeForPrefetch().pipe(
      combineLatestWith(params$),
      take(1),
      map(([ages, params]) =>
        ages.map((age) => ({
          ...params,
          startPayoutAge: age,
        })),
      ),
    );
  }

  private getRetirementAgesOrEmpty(): Observable<Graph.RetirementAge[]> {
    return this.simulationParametersQueriesService.getRetirementAgesQuery().pipe(
      first(),
      catchError(() => of([])),
    );
  }

  private fetchEngagementsRequirementsChange(): Observable<NorskpensjonEngagement[]> {
    return this.isAllowedToFetchContracts().pipe(
      skip(1),
      switchMap(() => this.fetchEngagements()),
    );
  }

  private isAllowedToFetchContracts(): Observable<boolean> {
    const isBelowMaxSimAge$ = this.commonParametersService.isAgeHigherThanMaxSimAge$.pipe(
      map((isAgeHigherThanMaxSimAge) => !isAgeHigherThanMaxSimAge),
    );

    return combinePredicates([this.norskpensjonRequirementsService.getHasAllRequirements(), isBelowMaxSimAge$]);
  }

  private isAllowedToFetchPrognosis(): Observable<boolean> {
    return combinePredicates([
      this.isAllowedToFetchContracts(),
      this.getRetirementAgesOrEmpty().pipe(map((retirementAges) => retirementAges.length > 0)),
    ]);
  }
}

const makeGetNorskpensjonContractsById =
  (id: Nullable<string>) =>
  (
    queryResult: PrognosisQueryResult<Graph.NorskpensjonContract[]>,
  ): PrognosisQueryResult<Graph.NorskpensjonContract[]> => ({
    ...queryResult,
    data: queryResult.data.filter((contract) => contract.softId === id),
  });

const makeGetIndexSortedPrognosis =
  (engagement: NorskpensjonEngagement) =>
  (queryResult: PrognosisQueryResult<Graph.Prognosis[]>): PrognosisQueryResult<Graph.Prognosis[]> => {
    const prognoses = queryResult.data;
    const maybeSortedPrognosis = prognoses[engagement?.sortIndexWithinPayer];

    if (maybeSortedPrognosis) {
      return {
        ...queryResult,
        data: [maybeSortedPrognosis],
      };
    }

    const [firstPrognosis] = prognoses;

    return {
      ...queryResult,
      data: firstPrognosis ? [firstPrognosis] : [],
    };
  };

function getNorskpensjonContractsPrognosis(
  queryResult: PrognosisQueryResult<Graph.NorskpensjonContract[]>,
): PrognosisQueryResult<Graph.Prognosis[]> {
  const prognosis = queryResult.data
    .filter((contract) => getIsNotNullable(contract))
    .filter((contract) => getIsNotNullable(contract.prognosis))
    .map((contract) => <NonNullable<Graph.Prognosis>>contract.prognosis);

  return {
    ...queryResult,
    data: prognosis,
  };
}

export function addSortIndex(engagements: NorskpensjonEngagement[]): typeof engagements {
  if (!Array.isArray(engagements)) {
    return engagements;
  }

  // As per TRM-1151 we've learned that getIdentifier() is not enough to create an unique id
  // and no unique value is given by the services providing the information
  // thus, we will try this quickfix to increase the level of uniqueness by adding an index
  // to all engagements from the same payer, sorted by pension value.
  // LIMITATIONS: if *agreements* does not contain the full set of records , this approach will fail

  const ids = engagements.map((p) => p.getIdentifier());

  const uniques = <T>(arr: T[]): T[] => xor(...arr.map((a) => [a]));
  const duplicates = <T>(arr: T[]): T[] => xor(arr, uniques(arr));
  const dupes = duplicates(ids);

  if (dupes.length === 0) {
    return engagements;
  }

  const mapEngagementsByIds = (_map: Map<string, typeof engagements>, e: ArrayType<typeof engagements>): typeof _map =>
    _map.set(e.getIdentifier(), (_map.get(e.getIdentifier()) ?? []).concat(e));

  const sortEngagementsByTotalPayout = (array: typeof engagements): typeof engagements =>
    array.length < 2
      ? array
      : [...array]
          .sort(compareTotalPayouts)
          .map((engagement: NorskpensjonEngagement, i: number) => engagement.withSortIndex(i));

  return Array.from(engagements.reduce(mapEngagementsByIds, new Map()))
    .map(([, value]) => value)
    .map(sortEngagementsByTotalPayout)
    .flat();
}

function compareTotalPayouts(a: NorskpensjonEngagement, b: NorskpensjonEngagement): number {
  return getTotalEngagementPayout(a) - getTotalEngagementPayout(b);
}

function getTotalEngagementPayout(engagement: NorskpensjonEngagement): number {
  return engagement.getTotalPayout() ?? 0;
}

function toEngagementModelsWithSortIndex(contracts: Graph.NorskpensjonContract[]): NorskpensjonEngagement[] {
  const output = contracts.map((contract) => new NorskpensjonEngagement(contract));
  return addSortIndex(output);
}
