import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { filter, Observable, Subject } from "rxjs";
import {
  ContractDetailsService,
  ContractWithSavingsRate,
  isContractWithSavingsRate,
} from "src/app/modules/shared/services/contract-details.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";

interface Savings {
  savingsRate: Graph.SavingsRate;
  employerAnnualSavingsAmount: number;
}
@Component({
  selector: "app-savings-rate-summary-rows",
  templateUrl: "./savings-rate-summary-rows.component.html",
  styleUrls: ["../../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavingsRateSummaryRowsComponent implements OnChanges {
  @Input()
  public engagement!: AnySavingsAndPensionEngagement;

  @Input()
  public responsive!: boolean;

  public readonly savings$ = new Subject<Savings>();

  constructor(private readonly contractDetailsService: ContractDetailsService) {}

  public ngOnChanges({ engagement }: SimpleChanges): void {
    this.fetchContractsWithSavingsRate(engagement.currentValue).subscribe(
      ({ savingsRate, employerAnnualSavingsAmount }) => {
        if (savingsRate && employerAnnualSavingsAmount) {
          this.savings$.next({ savingsRate, employerAnnualSavingsAmount });
        }
      },
    );
  }

  private fetchContractsWithSavingsRate(
    engagement: AnySavingsAndPensionEngagement,
  ): Observable<ContractWithSavingsRate> {
    return this.contractDetailsService.fetchContractDetails(engagement).pipe(filter(isContractWithSavingsRate));
  }
}
