import { Injectable } from "@angular/core";
import { defaultIfEmpty, firstValueFrom, forkJoin, map, Observable, switchMap } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";
import {
  AnySavingsAndPensionEngagement,
  SavingsAndPensionService,
} from "src/app/services/prognoses-services/savings-and-pension.service";
import { getIsNotEmpty } from "src/app/utils/utils";
import { FetchHoldingsAndAllocationService, HoldingsAndSavingsProfile } from "./fetch-holdings-and-allocation.service";

export interface EngagementHoldingsProfile {
  engagement: AnyEngagement;
  holdingsProfile: HoldingsAndSavingsProfile;
}

@Injectable({
  providedIn: "root",
})
export class EngagementsWithHoldingsProfilesService {
  constructor(
    private readonly savingsAndPensionService: SavingsAndPensionService,
    private readonly fetchHoldingsAndAllocationService: FetchHoldingsAndAllocationService,
  ) {}

  public getEngagementHoldingsProfiles(): Observable<EngagementHoldingsProfile[]> {
    return this.savingsAndPensionService.engagements$.pipe(
      switchMap((engagements) =>
        forkJoin(engagements.map((engagement) => this.getEngagementHoldingsProfile(engagement))).pipe(
          defaultIfEmpty([]),
        ),
      ),
      map(filterHasHoldings),
    );
  }

  private getEngagementHoldingsProfile(engagement: AnySavingsAndPensionEngagement): Promise<EngagementHoldingsProfile> {
    const holdings$ = this.fetchHoldingsAndAllocationService.fetchHoldingsAndSavingsProfile(engagement).pipe(
      map((holdingsProfile) => ({
        engagement,
        holdingsProfile,
      })),
    );

    return firstValueFrom(holdings$);
  }
}

function filterHasHoldings(holdingsProfiles: EngagementHoldingsProfile[]): EngagementHoldingsProfile[] {
  return holdingsProfiles.filter(({ holdingsProfile: { holdings } }) => getIsNotEmpty(holdings));
}
