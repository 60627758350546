import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { FmsService } from "src/app/services/fms.service";
import { getIsNotNullable } from "src/app/utils/utils";

const SORT_ORDER: Graph.PublicPensionContractStatus[] = [
  Graph.PublicPensionContractStatus.Active,
  Graph.PublicPensionContractStatus.Payout,
];

@Component({
  selector: "app-public-pension-contract-badge",
  templateUrl: "./public-pension-contract-badge.component.html",
  styleUrls: ["./public-pension-contract-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicPensionContractBadgeComponent {
  @Input()
  public contracts!: Graph.PublicPensionContract[];

  constructor(private readonly fmsService: FmsService) {}

  @HostBinding("class")
  public get className(): string | undefined {
    const firstStatus = getStatuses(this.contracts).at(0);
    return firstStatus?.toLowerCase();
  }

  public getStatusText(contracts: Graph.PublicPensionContract[]): string | undefined {
    const firstStatus = getStatuses(contracts).at(0);

    if (!firstStatus) {
      return undefined;
    }

    return this.fmsService.instant(`publicPensionContractBadge.status.${firstStatus.toLowerCase()}`);
  }
}

function getStatuses(contracts: Graph.PublicPensionContract[]): Graph.PublicPensionContractStatus[] {
  return [...contracts]
    .map(getContractStatus)
    .filter(getIsNotNullable)
    .filter(isContractStatusValid)
    .sort(getContractStatusOrder);
}

function getContractStatus(contract: Graph.PublicPensionContract): Graph.PublicPensionContractStatus | undefined {
  return contract.status ?? undefined;
}

function isContractStatusValid(status: Graph.PublicPensionContractStatus): boolean {
  return SORT_ORDER.includes(status);
}

function getContractStatusOrder(status: Graph.PublicPensionContractStatus): number {
  return SORT_ORDER.findIndex((orderedStatus) => orderedStatus === status);
}
