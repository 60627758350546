<app-action-dialog>
  <ng-container title>
    {{ "actions.withdrawalOrder.explanation.title" | fms }}
  </ng-container>

  <div class="content" [innerHTML]="'actions.withdrawalOrder.explanation.content' | fms"></div>

  <button
    stbButton="text"
    iconType="open-in-new-window"
    [setIconAfter]="true"
    data-track-id="actions-withdrawalorder-explanation"
    (click)="openArticle()"
  >
    {{ "actions.withdrawalOrder.explanation.linkText" | fms }}
  </button>

  <ng-container buttons>
    <button stbButton="outlined" mat-dialog-close>
      {{ "dialog.button.close.text" | fms }}
    </button>
  </ng-container>
</app-action-dialog>
