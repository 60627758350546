import { Inject, Injectable } from "@angular/core";
import { SESSION_STORAGE } from "../tokens";
import { StorageService } from "./storage.service";

export enum StorageKey {
  ClientData = "clientData",
  Profile = "profile",
  TempState = "tempState",
  Booking = "booking",
  NewAgreements = "newAgreements",
  AuthStepupClosed = "authStepupClosed",
  DevPanelToggle = "devPanelToggle",
}

@Injectable({
  providedIn: "root",
})
export class SessionStorageService extends StorageService {
  constructor(
    @Inject(SESSION_STORAGE)
    storage: Storage,
  ) {
    super(storage);
  }
}
