import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, combineLatestWith, first, map, timeout } from "rxjs/operators";
import { DEFAULT_TIMEOUT } from "src/app/constants/technical.constants";
import { getIsEveryItemTrue } from "src/app/utils/array";
import { ConsentService } from "../consent.service";
import { KeycloakService } from "../keycloak.service";

@Injectable({
  providedIn: "root",
})
export class NorskpensjonRequirementsService {
  constructor(
    private readonly consentService: ConsentService,
    private readonly keycloakService: KeycloakService,
  ) {}

  public getHasAllRequirements(): Observable<boolean> {
    return this.consentService.hasNorskPensjonConsent$.pipe(
      map(Boolean),
      combineLatestWith(this.keycloakService.isAuthLevel3or4$),
      map(getIsEveryItemTrue),
    );
  }

  public getLatestHasAllRequirements(): Observable<boolean> {
    return this.getHasAllRequirements().pipe(first());
  }

  public getLatestHasConsentRequirement(): Observable<boolean> {
    return this.consentService.hasNorskPensjonConsent$.pipe(
      map(Boolean),
      first(),
      timeout(DEFAULT_TIMEOUT),
      catchError(() => of(false)),
    );
  }

  public getLatestHasAuthLevelRequirement(): Observable<boolean> {
    return this.keycloakService.isAuthLevel3or4$.pipe(
      first(),
      timeout(DEFAULT_TIMEOUT),
      catchError(() => of(false)),
    );
  }
}
