import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export interface SmallCard {
  title: string;
  imageUrl: string;
}

@Component({
  selector: "app-small-card",
  templateUrl: "./small-card.component.html",
  styleUrls: ["./small-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallCardComponent {
  @Input()
  public readonly card!: SmallCard;
  @Input()
  public readonly showImage: boolean = true;
}
