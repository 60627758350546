import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { EndpointService, httpHeaderNoCache } from "../endpoint.service";

/**
 * Customer Master DBD REST API - cm-dbd-rest-api
 */
@Injectable({
  providedIn: "root",
})
export class CmRestApiService {
  constructor(private readonly endpointService: EndpointService) {}
  /**
   * GET /customer/core-data.v3
   */
  public fetchCustomer(): Observable<CustomerMaster.Response.Customer> {
    return this.endpointService
      .composeCustomerUrl()
      .pipe(
        switchMap((url) => this.endpointService.httpGet$<CustomerMaster.Response.Customer>(url, httpHeaderNoCache)),
      );
  }
}
