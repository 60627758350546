import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { formatDistanceToNow } from "date-fns";
import { Observable, combineLatest, switchMap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { CurrencyFormatterPipe } from "src/app/modules/shared/pipes/currency-formatter.pipe";
import { YearPipe } from "src/app/modules/shared/pipes/year.pipe";
import { CurrentSimulationSelectorService } from "src/app/modules/shared/services/current-simulation-selector.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { CustomerService } from "src/app/services/customer.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { IncomeService } from "src/app/services/income/income.service";
import { PayoutplanService } from "src/app/services/payoutplan.service";
import { PensionPlanProgressService } from "src/app/services/pension-plan-progress.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { SmartAccountService, SmartAccountState } from "src/app/services/smart-account.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { isEngagementFromSavings, isNonInflationAdjustedEngagement } from "src/app/utils/engagement.utils";
import { getIsNotNullable, getIsNullable, percentCalculator } from "src/app/utils/utils";

interface PensionAgeArgs {
  timeUntilPensionAge: string;
  pensionAge?: number;
}

@Component({
  selector: "app-user-summary",
  templateUrl: "./user-summary.component.html",
  styleUrls: ["./user-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSummaryComponent {
  @Input()
  public showInBox = false;

  public readonly iconSrc = "https://assets.storebrand.no/icons/magnifying-glass-numbers-single.svg";
  public readonly isLoaded$ = this.fetchPrognosesRunningJobsService.isCurrentYearLoaded$;

  public readonly firstName$ = this.getFirstName();
  public readonly userAge$ = this.getUserAge();
  public readonly pensionAgeArgs$ = this.getPensionAgeArgs();
  public readonly numberOfEngagementsText$ = this.getNumberOfEngagementsText();
  public readonly lastStepVisitText$ = this.getLastStepVisitText();
  public readonly hasSavings$ = this.getHasSavings();
  public readonly hasAnnualSalary$ = this.hasAnnualSalary();
  public readonly annualSalaryText$ = this.getAnnualSalaryText();

  public readonly isQualifiedForSmartAccount$ = this.getIsQualifiedForSmartAccount();
  public readonly isLongSinceLastStepVisit$ = this.pensionPlanProgressService.getIsLongSinceLastPensionPlanVisit();
  public readonly hasAfp$ = this.profileService.hasAfp$;
  public readonly averagePercentOfSalay$ = this.getAveragePercentOfSalary();

  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly currencyFormatterPipe: CurrencyFormatterPipe,
    private readonly currentSimulationSelectorService: CurrentSimulationSelectorService,
    private readonly customerService: CustomerService,
    private readonly engagementsService: EngagementsService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly fmsService: FmsService,
    private readonly incomeService: IncomeService,
    private readonly pensionPlanProgressService: PensionPlanProgressService,
    private readonly smartAccountService: SmartAccountService,
    private readonly yearPipe: YearPipe,
    private readonly payoutplanService: PayoutplanService,
    private readonly profileService: ProfileService,
  ) {}

  private getFirstName(): Observable<string> {
    return this.customerService.name$.pipe(map(({ firstName }) => firstName));
  }

  private getUserAge(): Observable<string> {
    return this.customerService.age$.pipe(
      filter(getIsNotNullable),
      map((age) => this.yearPipe.transform(age).toString()),
    );
  }

  private hasAnnualSalary(): Observable<boolean> {
    return this.incomeService.annualGrossIncome$.pipe(map((income) => income != null));
  }

  private getAnnualSalaryText(): Observable<string> {
    return this.incomeService.annualGrossIncome$.pipe(
      map((annualSalary) => this.currencyFormatterPipe.transform(annualSalary, "end")),
      switchMap((annualSalary) =>
        this.fmsService.translateAsync(this.showInBox ? "userSummaryBox.annualSalary" : "userSummary.annualSalary", {
          args: { annualSalary },
        }),
      ),
    );
  }

  private getPensionAgeArgs(): Observable<PensionAgeArgs> {
    const timeUntilPensionAge$ = this.startPayoutAgeService.getStartPayoutAgeDate().pipe(
      filter(getIsNotNullable),
      map((startPayoutAgeDate) => this.toDistanceFromNow(startPayoutAgeDate)),
    );

    if (this.showInBox) {
      return timeUntilPensionAge$.pipe(map((timeUntilPensionAge) => ({ timeUntilPensionAge })));
    } else {
      const pensionAge$ = this.startPayoutAgeService.getStartPayoutAge();

      return combineLatest([timeUntilPensionAge$, pensionAge$]).pipe(
        map(([timeUntilPensionAge, pensionAge]) => ({
          timeUntilPensionAge,
          pensionAge,
        })),
      );
    }
  }

  private getNumberOfEngagementsText(): Observable<string> {
    return this.currentSimulationSelectorService.select().pipe(
      map((currentSimulation) => currentSimulation.totalEngagements),
      switchMap((engagements) =>
        this.fmsService.translateAsync(this.showInBox ? "userSummaryBox.engagements" : "userSummary.engagements", {
          args: { engagements },
        }),
      ),
    );
  }

  private getLastStepVisitText(): Observable<string> {
    return this.clientDataService.pensionPlan$.pipe(
      map((pensionPlan) => pensionPlan?.progressUrlUpdated),
      filter(getIsNotNullable),
      switchMap((progressUrlUpdated) =>
        this.fmsService.translateAsync("userSummary.lastStepVisit", {
          args: {
            yearsSinceLogin: this.toDistanceFromNow(progressUrlUpdated),
          },
        }),
      ),
    );
  }

  private getHasSavings(): Observable<string> {
    return this.engagementsService.allEngagements$.pipe(
      map((allEngagements) => allEngagements.some(isEngagementFromSavings)),
      switchMap((hasSavings) => {
        if (this.showInBox) {
          return this.fmsService.translateAsync(
            hasSavings ? "userSummaryBox.hasActiveEngagement" : "userSummaryBox.hasNoActiveEngagement",
          );
        } else {
          return this.fmsService.translateAsync(
            hasSavings ? "userSummary.hasActiveEngagement" : "userSummary.hasNoActiveEngagement",
          );
        }
      }),
    );
  }

  private getIsQualifiedForSmartAccount(): Observable<boolean> {
    return this.smartAccountService.smartAccountState$.pipe(
      map((smartAccountState) => smartAccountState === SmartAccountState.Qualified),
    );
  }

  private getAveragePercentOfSalary(): Observable<number | undefined> {
    return combineLatest([
      this.payoutplanService.averageTenYearsTotal$,
      this.incomeService.annualGrossIncome$,
      this.engagementsService.allEngagements$,
    ]).pipe(
      map(([averageTenYearsTotal, income, allEngagements]) => {
        if (getIsNullable(averageTenYearsTotal) || !income || allEngagements.some(isNonInflationAdjustedEngagement)) {
          return undefined;
        }
        return Math.floor(percentCalculator.xIsWhatPercentageOfY(averageTenYearsTotal, income));
      }),
    );
  }

  private toDistanceFromNow(date: number | Date): string {
    return formatDistanceToNow(date, {
      locale: this.fmsService.getDateFnsLocale(),
    });
  }
}
