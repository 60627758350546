import { inject } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ResolveFn } from "@angular/router";
import { Observable, catchError, combineLatest, firstValueFrom, of, take } from "rxjs";
import { ApiHomeService } from "../services/api-home.service";
import { ContractSalaryService } from "../services/api/contract-salary.service";
import { FmsService } from "../services/fms.service";
import { PortfolioInsightService } from "../services/portfolio-insight.service";
import { GlobalRunningJobsService } from "../services/running-jobs/global-running-jobs.service";

export const apiHomeResolver: ResolveFn<Promise<ApiHome.RootObject>> = async () => {
  return inject(ApiHomeService).fetchApiHomeResource();
};

export const contractSalaryResolver: ResolveFn<Promise<number | undefined>> = () => {
  const contractSalaryService = inject(ContractSalaryService);
  const globalRunningJobsService = inject(GlobalRunningJobsService);

  return firstValueFrom(
    contractSalaryService.getSalary().pipe(
      globalRunningJobsService.withLoader("ContractSalaryResolver"),
      catchError(() => of(undefined)),
    ),
  );
};

export const fmsResolver: ResolveFn<Observable<Fms.Translations | undefined>> = () => {
  const fmsService = inject(FmsService);
  const matSnackBar = inject(MatSnackBar);

  return combineLatest([
    fmsService.fetchTexts(),
    fmsService.translateAsync("errors.fms.fetch-failed"),
    fmsService.translateAsync("errors.snackbar.button.close"),
  ]).pipe(
    take(1),
    catchError(([, fetchFailedText, closeText]) => {
      matSnackBar.open(fetchFailedText, closeText, {
        duration: 10000,
      });
      return of(undefined);
    }),
  );
};

export const portfolioInsightResolver: ResolveFn<boolean> = () => {
  inject(PortfolioInsightService)
    .getPortfolioInsightForEngagements()
    .pipe(catchError(() => of(null)))
    .subscribe();

  return of(true);
};
