import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { filter, first, map, tap } from "rxjs/operators";
import { applyArrayTo } from "../utils/applyArrayTo";
import { firstValueFromIgnoreReturn, getIsNotEmpty, getIsNotNullable } from "../utils/utils";
import { SimulationParametersByEngagement } from "./common-parameters.service";
import { ClientDataService } from "./customer-supplied-data/client-data.service";
import { StartPayoutAgeService } from "./start-payout-age.service";

@Injectable({
  providedIn: "root",
})
export class MigrateSimulationParametersService {
  private readonly allEngagementSimParams$: Observable<SimulationParametersByEngagement[]>;

  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    this.allEngagementSimParams$ = combineLatest([
      this.startPayoutAgeService.getFirstPayoutAge().pipe(filter(getIsNotNullable)),
      this.clientDataService.simulationParametersByEngagement$.pipe(filter(getIsNotEmpty)),
    ]).pipe(first(), map(applyArrayTo(toSimParamsPatch)), filter(getIsNotEmpty));
  }

  public init(): Promise<void> {
    const patcher$ = this.allEngagementSimParams$.pipe(
      tap((next) => this.clientDataService.updateSimulationParametersByEngagement(...next)),
    );

    return firstValueFromIgnoreReturn(patcher$);
  }
}

function toSimParamsPatch(
  firstPayoutAge: number,
  params: SimulationParametersByEngagement[],
): SimulationParametersByEngagement[] {
  return params.map(([key, value]) => [key, getParamsWithMaxStartPayoutAgeOf(value, firstPayoutAge)]);
}

function getParamsWithMaxStartPayoutAgeOf(
  params: Partial<CustomerSuppliedData.SimulationParameters>,
  firstPayoutAge: number,
): typeof params {
  const startPayoutAge = params.startPayoutAge;
  if (getIsNotNullable(startPayoutAge)) {
    const maxStartPayoutAge = Math.max(startPayoutAge, firstPayoutAge);
    return { ...params, startPayoutAge: maxStartPayoutAge };
  }

  return params;
}
