<div class="amount-card">
  <app-tip-icon
    class="extra-information-tooltip"
    *ngIf="!!extraInformationTooltipText"
    [tooltipText]="extraInformationTooltipText"
  >
  </app-tip-icon>

  <h3>{{ title }}</h3>

  <h2 class="stb-mt2">
    <app-text-skeleton [renderTrigger$]="loadedAmount$">
      {{ displayValue$ | async | currency : "NOK" : "symbol" : "1.0-0" : "nb" }}
    </app-text-skeleton>
  </h2>

  <span
    class="percentage"
    *ngIf="enableDifferencePercentage"
    matTooltip="{{ tooltipText }}"
    [@fadeInOut]="(showPercentageDiff$ | async) ? 'show' : 'hide'"
    [class]="(percentDisplayValue$ | async)! > 0 ? 'percentage-positive' : 'percentage-negative'"
    >{{ Math.abs((percentDisplayValue$ | async)!) | number : "1.0-0" }}%</span
  >
  <p>{{ "common.grossTax" | fms }}</p>
</div>
