import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { BehaviorSubject, firstValueFrom, isObservable, Observable, of, Subject } from "rxjs";
import { first, mergeMap, pluck, takeUntil } from "rxjs/operators";
import { DispatcherService, Signal } from "src/app/services/dispatcher.service";
import { FmsService } from "src/app/services/fms.service";
import { DynamicStepNavigationService, InstancedStep, Metadata, Step } from "./dynamic-step-navigation.service";
import { StepAnimationService, STEP_ANIMATION_TRIGGER } from "./step-animation.service";

export interface SteppableModalOptions {
  steps: Step[];
  onComplete?(stepMetadata?: Metadata): void;
  onCancel?(stepMetadata?: Metadata): void;
}

@Component({
  selector: "app-steppable-modal",
  templateUrl: "./steppable-modal.component.html",
  styleUrls: ["./steppable-modal.component.scss"],
  animations: [STEP_ANIMATION_TRIGGER],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [StepAnimationService, DynamicStepNavigationService],
})
export class SteppableModalComponent implements OnInit, OnDestroy {
  @ViewChild("content", { static: true, read: ViewContainerRef })
  private readonly content!: ViewContainerRef;

  @ViewChild("resetFocus", { static: true })
  private readonly resetFocus!: ElementRef;

  public readonly loading$ = new BehaviorSubject<boolean>(false);
  public readonly showActions$ = new BehaviorSubject<boolean>(true);
  public readonly isCurrentStepCancelable$ = this.dynamicStepNavigationService.currentStep$.pipe(pluck("cancelable"));
  public readonly stepAnimationState$ = this.stepAnimationService.animationState$;
  public readonly currentStepTitle$: Observable<string>;

  private readonly destroy$ = new Subject<void>();
  private readonly defaultNextButtonText = this.fmsService.instant<string>("steppableModal.next");
  private readonly defaultPreviousButtonText = this.fmsService.instant<string>("steppableModal.previous");
  private readonly unsavedChangesNextButtonText = this.fmsService.instant<string>("steppableModal.saveAndNext");

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly nextButtonText$ = new BehaviorSubject<string>(this.defaultNextButtonText);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly previousButtonText$ = new BehaviorSubject<string>(this.defaultPreviousButtonText);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: SteppableModalOptions,
    private readonly dialogRef: MatDialogRef<SteppableModalComponent>,
    private readonly dispatcherService: DispatcherService,
    private readonly fmsService: FmsService,
    private readonly stepAnimationService: StepAnimationService,
    private readonly dynamicStepNavigationService: DynamicStepNavigationService,
  ) {
    this.currentStepTitle$ = this.dynamicStepNavigationService.currentStep$.pipe(
      mergeMap((currentStep) => currentStep?.instance?.getTitle() ?? of("")),
    );
  }

  public ngOnInit(): void {
    this.dynamicStepNavigationService.initialize(this.data.steps, this.content);

    this.updateShowActionsOnSignal();
    this.updateNextButtonTextOnSignal();
    this.handleStepNavigation();
    this.handleTriggerNextSignal();
  }

  public ngOnDestroy(): void {
    const { metadata } = this.dynamicStepNavigationService.getCurrentStep();

    if (this.data.onCancel) {
      this.data.onCancel(metadata);
    }

    this.destroy$.next();
  }

  public async onNext(): Promise<void> {
    const { instance, onNext, metadata } = this.dynamicStepNavigationService.getCurrentStep();

    this.loading$.next(true);

    const beforeNext = instance.beforeNext || ((): boolean => true);
    const beforeNext$ = beforeNext.call(instance);

    const valid = isObservable(beforeNext$) ? await firstValueFrom(beforeNext$) : await beforeNext$;

    if (!valid) {
      this.loading$.next(false);
      return;
    }

    if (onNext) {
      onNext(metadata);
    }

    if (instance?.next) {
      const next$ = instance.next.call(instance);

      if (isObservable(next$)) {
        await firstValueFrom(next$);
      } else {
        await next$;
      }
    }

    if (this.dynamicStepNavigationService.isLastStep()) {
      // Prevent the onCancel callback from ever being called now that
      // the final modal step is completed
      this.data.onCancel = undefined;

      this.close();

      if (this.data.onComplete) {
        this.data.onComplete(metadata);
      }
    } else {
      this.stepAnimationService.startAnimation();
      this.dynamicStepNavigationService.next();
    }

    this.loading$.next(false);
  }

  public onPrevious(): void {
    this.stepAnimationService.startAnimation();
    this.dynamicStepNavigationService.previous();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public shouldShowPreviousButton(): boolean {
    return this.dynamicStepNavigationService.isMultipleSteps() && !this.dynamicStepNavigationService.isFirstStep();
  }

  private handleStepNavigation(): void {
    this.dynamicStepNavigationService.currentStep$.pipe(takeUntil(this.destroy$)).subscribe((currentStep) => {
      this.updateNextButtonText(currentStep);
      this.updatePreviousButtonText(currentStep);
      this.setClosable(currentStep.cancelable ?? false);
      this.resetFocusToTop();

      if (currentStep.onRender) {
        currentStep.onRender(currentStep.metadata);
      }
    });
  }

  private updateShowActionsOnSignal(): void {
    this.dispatcherService
      .subscribeTo$(Signal.SteppableModalShowActions, SteppableModalComponent.name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ payload }) => {
        this.showActions$.next(payload.payload);
      });
  }

  private updateNextButtonTextOnSignal(): void {
    this.dispatcherService
      .subscribeTo$(Signal.SteppableModalHasChange, SteppableModalComponent.name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setUnsavedChangesNextButtonText();
      });
  }

  private handleTriggerNextSignal(): void {
    this.dispatcherService
      .subscribeTo$(Signal.SteppableModalTriggerNext, SteppableModalComponent.name)
      .pipe(
        first(),
        mergeMap(() => this.onNext()),
      )
      .subscribe();
  }

  private resetFocusToTop(): void {
    if (this.resetFocus) {
      this.resetFocus.nativeElement.focus();
    }
  }

  private setClosable(enable: boolean): void {
    this.dialogRef.disableClose = !enable;
  }

  private updateNextButtonText(step: InstancedStep): void {
    if (this.dynamicStepNavigationService.isLastStep()) {
      this.nextButtonText$.next(this.unsavedChangesNextButtonText);
    } else {
      const nextButtonText = step.nextButtonText ?? this.defaultNextButtonText;
      this.nextButtonText$.next(nextButtonText);
    }
  }

  private updatePreviousButtonText(step: InstancedStep): void {
    const previousButtonText = step.previousButtonText || this.defaultPreviousButtonText;

    this.previousButtonText$.next(previousButtonText);
  }

  private setUnsavedChangesNextButtonText(): void {
    this.nextButtonText$.next(this.unsavedChangesNextButtonText);
  }
}
