import { GenericEngagementOfUnknown } from "src/app/models/engagements/generic-engagement.model";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";

export class MissingPrognosisError extends Error {
  name = "PrognosisError";

  constructor(
    public readonly engagement: GenericEngagementOfUnknown,
    message?: string,
  ) {
    const id = engagement.getIdentifier();

    super(message ?? `Missing prognosis for ${id}`);
  }
}

export class MissingAfpPrognosisError extends MissingPrognosisError {
  name = "MissingAfpPrognosisError";
}

export class MissingBankPrognosisError extends MissingPrognosisError {
  name = "MissingBankPrognosisError";
}

export class MissingExternalSavingsPrognosisError extends MissingPrognosisError {
  name = "MissingExternalSavingsPrognosisError";
}

export class MissingNavPrognosisError extends MissingPrognosisError {
  name = "MissingNavPrognosisError";
}

export class MissingNorskpensjonPrognosisError extends MissingPrognosisError {
  name = "MissingNorskpensjonPrognosisError";
}

export class MissingPublicPensionPrognosisError extends MissingPrognosisError {
  name = "MissingPublicPensionPrognosisError";
}

export class MissingSavingsAndPensionPrognosisError extends MissingPrognosisError {
  name = "MissingSavingsAndPensionPrognosisError";

  constructor(engagement: AnySavingsAndPensionEngagement) {
    const id = engagement.getIdentifier();
    const type = engagement.contract.__typename;

    super(engagement, `Missing prognosis for ${id} (${type})`);
  }
}
