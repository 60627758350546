import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { validateIsTemplateIterable } from "src/app/services/fms.service";

export interface PromoBox {
  picture: string;
  title: string;
  content: string;
}

@Component({
  selector: "app-promo-box-container",
  templateUrl: "./promo-box-container.component.html",
  styleUrls: ["./promo-box-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBoxContainerComponent {
  @Input()
  public readonly promoBoxes!: Array<PromoBox>;

  public readonly validateIsTemplateIterable = validateIsTemplateIterable;
}
