import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EPK_LONG_PAYOUT_DURATION_THRESHOLD } from "src/app/constants/business.constants";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { NavigateToWithdrawalOrderAnchorService } from "src/app/modules/shared/services/navigate-to-withdrawal-order-anchor.service";
import { WithdrawalProfileId } from "src/app/modules/shared/services/withdrawal-profiles/withdrawal-profiles.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { Nullable } from "src/app/utils/utils";
import { ActionEpkService, AnyEpkEngagement } from "../../services/action-epk.service";
import { ActionEpkStbDialogComponent } from "./action-stb-epk-dialog/action-epk-stb-dialog.component";

@Component({
  selector: "app-action-epk-change-duration",
  templateUrl: "./action-epk-change-duration.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActionEpkService],
})
export class ActionEpkChangeDurationComponent implements ActionComponent {
  @Input()
  public action!: Action;

  private readonly hasStbEpkWithShortDuration$: Observable<boolean>;

  constructor(
    private readonly actionEpkService: ActionEpkService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly dialog: MatDialog,
    private readonly navigateToWithdrawalOrderAnchorService: NavigateToWithdrawalOrderAnchorService,
  ) {
    this.hasStbEpkWithShortDuration$ = this.actionEpkService
      .getFirstEpkFromStorebrand()
      .pipe(map(toIsShortPayoutDuration), this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe());
  }

  public async goToWithdrawalOrderPage(): Promise<void> {
    this.navigateToWithdrawalOrderAnchorService.navigate(WithdrawalProfileId.CustomPayoutAge);
  }

  public openChangeStbEpkDialog(): void {
    this.dialog.open<ActionEpkStbDialogComponent, Action>(ActionEpkStbDialogComponent, {
      data: this.action,
      panelClass: "no-max-width",
    });
  }

  public getIsVisible(): Observable<boolean> {
    return this.hasStbEpkWithShortDuration$;
  }
}

function toIsShortPayoutDuration(engagement: Nullable<AnyEpkEngagement>): boolean {
  const duration = engagement?.getPayoutDuration().years ?? Infinity;

  return duration < EPK_LONG_PAYOUT_DURATION_THRESHOLD;
}
