import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { PortfolioInsightQueriesService } from "src/app/services/api/portfolio-insight-queries.service";
import { PortfolioInsight } from "src/app/services/api/savings-and-pension-queries.types";
import { filterEngagementsWithKeyValues } from "../utils/engagement.utils";
import { InternalSavingsService } from "./internal-savings.service";
import {
  AnySavingsAndPensionEngagement,
  SavingsAndPensionService,
} from "./prognoses-services/savings-and-pension.service";

@Injectable({
  providedIn: "root",
})
export class PortfolioInsightService {
  constructor(
    private readonly portfolioInsightQueriesService: PortfolioInsightQueriesService,
    private readonly savingsAndPensionService: SavingsAndPensionService,
    private readonly internalSavingsService: InternalSavingsService,
  ) {}

  public getPortfolioInsightForEngagements(): Observable<PortfolioInsight> {
    return this.savingsAndPensionService.engagements$.pipe(
      first(),
      map(filterEngagementsWithKeyValues),
      map(this.filterIncludedEngagements),
      map(getEngagementIds),
      tap((ids) => {
        if (ids.length === 0) {
          throw new Error("User have no included contracts in storebrand!");
        }
      }),
      switchMap((ids) => this.portfolioInsightQueriesService.getPortfolioInsight(ids)),
    );
  }

  private readonly filterIncludedEngagements = (
    engagements: AnySavingsAndPensionEngagement[],
  ): AnySavingsAndPensionEngagement[] =>
    engagements.filter((engagement) => this.internalSavingsService.isCustomerPension(engagement));
}

function getEngagementIds(engagements: AnySavingsAndPensionEngagement[]): string[] {
  return engagements.map((engagement) => engagement.getId());
}
