import { Directive, ElementRef, HostBinding, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    "[click]:not([href]):not([tabindex]):not([disableA11yClick]), [a11yClick]",
})

/* Makes all elements with either (click)-binding or a11yClick both tabbable and keyboard accessible, 
and removes outline when navigating using a mouse */
export class A11yClickDirective {
  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
  ) {}

  @HostBinding("attr.tabindex")
  public get tabIndex(): number {
    // The directive mistakingly applies to elements with tabindex set
    // programmatically. A workaround for now is to ensure that the
    // original tabindex is preserved.
    const tabIndex = Number((this.el.nativeElement as HTMLElement).getAttribute("tabindex"));

    return tabIndex || 0;
  }

  @HostListener("mousedown") onClick(): void {
    this.renderer.addClass(this.el.nativeElement, "no-outline");
  }

  @HostListener("blur") onblur(): void {
    this.renderer.removeClass(this.el.nativeElement, "no-outline");
  }

  @HostListener("keydown", ["$event"]) onKey(e: KeyboardEvent): void {
    if ([" ", "Spacebar", "Enter"].includes(e.key)) {
      e.preventDefault();
      this.el.nativeElement.click();
    }
  }
}
