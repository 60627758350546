<div class="breadcrumbs" *ngIf="isVisible$ | async; let isVisible">
  <div class="breadcrumb" *ngIf="isVisible">
    <a [href]="'breadcrumbs.overview.url' | fms">
      {{ "breadcrumbs.overview" | fms }}
    </a>
    <i>chevron-right</i>
  </div>

  <ng-container *ngIf="isVisible">
    <div *ngFor="let breadcrumb of breadcrumbs$ | async" class="breadcrumb">
      <a [routerLink]="[breadcrumb.path]" queryParamsHandling="merge">
        {{ breadcrumb.key | fms }}
      </a>
      <i>chevron-right</i>
    </div>
  </ng-container>
</div>
