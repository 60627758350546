<mat-card>
  <div class="item-content">
    <div class="item-group-one">
      <div>
        <div class="item-title">{{ title }}</div>
        <div class="item-label" data-hj-suppress>{{ description }}</div>
      </div>
      <img class="item-icon" [src]="iconSrc" alt />
    </div>

    <div class="item-separator"></div>

    <div class="item-group-two">
      <ng-container *ngFor="let field of fields">
        <div>
          <div class="item-label">
            {{ field.label }}
          </div>
          <div *ngIf="field.value" class="item-value value-header-alignment">
            {{ field.value }}
          </div>
          <div *ngIf="!field.value" class="item-no-value value-header-alignment">
            <i>information</i>
            <span>
              {{ "engagement.error.amountUnavailable" | fms }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</mat-card>
