import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { BenefitsService } from "src/app/services/benefits.service";
import { FmsService } from "src/app/services/fms.service";

@Component({
  selector: "app-action-benefits",
  templateUrl: "./action-benefits.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionBenefitsComponent implements ActionComponent {
  @Input()
  public action!: Action;

  constructor(
    private readonly benefitsService: BenefitsService,
    private readonly fmsService: FmsService,
  ) {}

  public async openUrl(): Promise<void> {
    const url = await firstValueFrom(this.fmsService.translateAsync<string>("actions.benefits.url"));
    window.open(url, "_blank");
  }

  public getIsVisible(): Observable<boolean> {
    return this.benefitsService.hasBenefits$;
  }
}
