import { first, map, Observable } from "rxjs";
import { NORSKPENSJON_PUBLIC_PENSION_UNKNOWN_PENSION_PROVIDER_NAME } from "src/app/constants/business.constants";
import { getProviderNameOrNone } from "src/app/models/engagements/norskpensjon/norskpensjon-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { EngagementName, EngagementSimulationStatus } from "../../pension.model";
import { PAYOUT_DURATIONS, PayoutDuration } from "../generic-engagement.model";
import { AbstractNorskpensjonEngagement } from "./abstract-norskpensjon-engagement.model";

export class UnavailableNorskpensjonEngagement extends AbstractNorskpensjonEngagement<Graph.UnavailablePensionProvider> {
  constructor(public contract: Graph.UnavailablePensionProvider) {
    super(contract);
  }

  public getPayoutFromAge(): number | undefined {
    return undefined;
  }

  public getPayoutPlan(): Graph.PayoutPlan[] {
    return [];
  }

  public getContinousPeriods(): unknown[] {
    return [];
  }

  public isPublicPensionFromNorskPensjon(): boolean {
    return this.getIdentifier() === NORSKPENSJON_PUBLIC_PENSION_UNKNOWN_PENSION_PROVIDER_NAME;
  }

  public getIdentifier(): string {
    return this.contract?.pensionProvider?.name ?? "";
  }

  public getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();

    return fmsService.translateAsync<string>("sharedProductNames.np.unnamed_product").pipe(
      first(),
      map((name) => ({
        name,
        supplier: getProviderNameOrNone(this.contract?.pensionProvider?.name),
      })),
    );
  }

  public getSimulationStatus(): EngagementSimulationStatus[] {
    const { supplier } = this.getName();

    const key = supplier
      ? "unavailableNorskpensjonEngagement.errorMessageWithSupplier"
      : "unavailableNorskpensjonEngagement.errorMessage";

    return [
      {
        key,
        args: { supplier },
        severity: Graph.SimulationSeverity.BusinessError,
      },
    ];
  }

  public getAverageAnnualPayout(): null {
    return null;
  }

  public getTotalPayout(): null {
    return null;
  }

  public getFirstYearPayout(): null {
    return null;
  }

  public getPayoutDuration(): PayoutDuration {
    return PAYOUT_DURATIONS.invalid;
  }

  public isLifelongPayout(): boolean {
    return false;
  }

  public getPensionArea(): null {
    return null;
  }

  public getPensionGroup(): null {
    return null;
  }

  public getContractNumberCustomer(): null {
    return null;
  }

  public getBalance(): null {
    return null;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  /**
   * @deprecated used for diffing purposes. To be disposed with TRM-2589.
   */
  public getApiResource(): string {
    return "savings-graphql";
  }
}
