import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable, map, mergeMap } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { SavingsGraphqlClientService } from "../../modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "../../modules/graphql-clients/utils/apollo-base-wrapper";

const GET_PUBLIC_PENSION_CONTRACT = gql`
  query getPublicPensionContractQuery {
    savingsEngagement {
      publicPensionContracts {
        id
      }
    }
  }
`;

interface SavingsEngagementQuery {
  savingsEngagement: Graph.SavingsEngagement;
}

@Injectable({
  providedIn: "root",
})
export class HasPublicPensionInStorebrandService {
  public readonly isPublicContext$: Observable<boolean>;

  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {
    this.isPublicContext$ = this.savingsGraphqlClient
      .query<SavingsEngagementQuery>({
        query: GET_PUBLIC_PENSION_CONTRACT,
      })
      .pipe(mergeMap(getApolloResponseData), map(hasPublicPensionContracts));
  }
}

function hasPublicPensionContracts(query: SavingsEngagementQuery): boolean {
  const publicPensionContracts = query.savingsEngagement?.publicPensionContracts;

  if (publicPensionContracts) {
    return publicPensionContracts.length > 0;
  }

  return false;
}
