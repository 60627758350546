import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Observable, map, switchMap } from "rxjs";
import { LARGE_DIALOG_WINDOW_WIDTH } from "../../../../constants/technical.constants";
import { InvestmentProfileInPayoutPeriod } from "../../../../services/api/savings-and-pension-queries.types";
import { CompressionLimitService } from "../../../../services/compression-limit.service";
import { AnyEngagement } from "../../../../services/engagements.service";
import { PayoutSimulationAlternativesDialogComponent } from "./payout-simulation-alternatives-dialog/payout-simulation-alternatives-dialog.component";

export interface ContractWithSelectableInvestmentProfileInPayoutPeriod {
  selectableInvestmentProfileInPayoutPeriod?: InvestmentProfileInPayoutPeriod[];
}

export interface ContractWitCompressionLimits {
  compressionLimits?: number[];
}

@Component({
  selector: "app-payout-simulation-alternatives",
  templateUrl: "./payout-simulation-alternatives.component.html",
  styleUrls: ["./payout-simulation-alternatives.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayoutSimulationAlternativesComponent implements OnInit {
  @Input()
  public readonly engagement$!: Observable<AnyEngagement>;

  public predicate$!: Observable<boolean>;
  public hasSelectableInvestmentProfileInPayoutPeriod$: Observable<any> | undefined;
  public hasCompressionLimit$: Observable<any> | undefined;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly compressionLimitService: CompressionLimitService,
  ) {}

  public ngOnInit(): void {
    this.predicate$ = this.engagement$.pipe(
      switchMap((engagement) =>
        this.compressionLimitService
          .getIfCompressionLimitChangesPrognosis(engagement.getIdentifier())
          .pipe(map((canChangeCompression) => ({ engagement, canChangeCompression }))),
      ),
      map(
        ({ engagement, canChangeCompression }) =>
          engagement.getSelectableInvestmentProfileInPayoutPeriod().length > 0 ||
          (engagement.getCompressionLimits().length > 0 && canChangeCompression),
      ),
    );
  }

  public openDialog(): void {
    this.matDialog.open(PayoutSimulationAlternativesDialogComponent, {
      data: { engagement$: this.engagement$ },
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "large-dialog-padding",
      autoFocus: false,
    });
  }
}
