<button mat-dialog-close>
  <i>close</i>
</button>

<h3 mat-dialog-title>{{ "holdingsDialog.title" | fms }}</h3>

<mat-dialog-content class="modal-dialog-content-container">
  <table [attr.aria-label]="'holdingsDialog.table.tableLabel' | fms">
    <tr>
      <th id="fond">{{ "holdingsDialog.table.column.fond" | fms }}</th>
      <th id="distribution">
        {{ "holdingsDialog.table.column.distribution" | fms }}
      </th>
    </tr>
    <ng-container *ngFor="let holding of data.holdings">
      <tr>
        <td>{{ holding.name }}</td>
        <td>{{ holding.actualDistribution }} %</td>
      </tr>
    </ng-container>
  </table>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
    stbButton="contained"
    cdkFocusInitial
    (click)="changeInvestmentButtonClick()"
    data-trackid="holdingsDialog-changeButton"
  >
    {{ "holdingsDialog.changeButton.text" | fms }}
  </button>
</div>
