<app-user-summary></app-user-summary>

@if (!categories) {
  <div class="label">
    {{ "pensionPlan.actionPage.actionsTitle" | fms }}
  </div>
}

@if (isLoaded$ | async) {
  @if (categories) {
    @for (category of categorizedActions$ | async; track category.id) {
      <h2 class="stb-mb2">{{ category.name }}</h2>

      <p *ngIf="!!category.description" class="category-description">
        {{ category.description }}
      </p>

      <app-actions [actionRefs]="category.actions"></app-actions>
    }
  } @else {
    @if (actions$ | async; as actionRefs) {
      <app-actions [actionRefs]="actionRefs"></app-actions>
    }
  }
} @else {
  <div class="skeletons">
    <app-action-skeleton></app-action-skeleton>
    <app-action-skeleton></app-action-skeleton>
    <app-action-skeleton></app-action-skeleton>
  </div>
}

<ng-container #actions></ng-container>
