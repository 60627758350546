import { DOCUMENT, ViewportScroller } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, delay, filter, first, firstValueFrom, mergeMap } from "rxjs";
import { WINDOW } from "src/app/tokens";
import { composePaths } from "src/app/utils/router.utils";
import { HasPublicPensionInStorebrandService } from "../../../services/api/has-public-pension-in-storebrand.service";
import { RouteKey, routes } from "../../pension-plan/routes";
import { WithdrawalProfileId, WithdrawalProfilesService } from "./withdrawal-profiles/withdrawal-profiles.service";

export const URL_FRAGMENT = "uttaksrekkefolge";

export enum ScrollAnchorId {
  WithdrawalSliders = "withdrawalSliders",
  PartTimeInput = "partTimeInput",
}

@Injectable()
export class NavigateToWithdrawalOrderAnchorService {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly withdrawalProfilesService: WithdrawalProfilesService,
    private readonly viewportScroller: ViewportScroller,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {}

  public navigate(id: WithdrawalProfileId): void {
    if (this.isOnSamePage()) {
      this.scrollToAnchorAndChangeProfile(id);
    } else {
      this.navigateWithFragment();
    }
  }

  public onPageInit(): void {
    const fragment$ = this.activatedRoute.fragment.pipe(filter((fragment) => !!fragment?.includes(URL_FRAGMENT)));

    const source$ = combineLatest([fragment$, this.hasPublicPensionInStorebrandService.isPublicContext$]).pipe(
      first(),
      delay(0),
      mergeMap(([, isPublicContext]) =>
        this.withdrawalProfilesService
          .changeWithdrawalProfile(WithdrawalProfileId.CustomPayoutAge)
          .then(() => {
            const id = getScrollAnchorId(isPublicContext);
            const element = this.document.getElementById(id);

            if (element) {
              const position = element.getBoundingClientRect();
              this.viewportScroller.scrollToPosition([0, position.y]);
            }
          })
          .then(() => removeUrlFragment()),
      ),
    );

    source$.subscribe();
  }

  private navigateWithFragment(): void {
    const summaryPath = composePaths(routes, RouteKey.Root, RouteKey.Summary);
    const path = `/smart-pensjon${summaryPath}#${URL_FRAGMENT}`;

    this.window.location.assign(path);
  }

  private async scrollToAnchorAndChangeProfile(id: WithdrawalProfileId): Promise<void> {
    const isPublicContext = await firstValueFrom(this.hasPublicPensionInStorebrandService.isPublicContext$);

    this.scrollToElementId(getScrollAnchorId(isPublicContext));

    return this.withdrawalProfilesService.changeWithdrawalProfile(id);
  }

  private scrollToElementId(elementId: string): void {
    const anchor = this.document.getElementById(elementId);

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }

  private isOnSamePage(): boolean {
    return this.router.url.includes(composePaths(routes, RouteKey.Root, RouteKey.Summary));
  }
}

function getScrollAnchorId(isPublicContext: boolean): ScrollAnchorId {
  return isPublicContext ? ScrollAnchorId.PartTimeInput : ScrollAnchorId.WithdrawalSliders;
}

function removeUrlFragment(): void {
  return window.history.replaceState("", document.title, window.location.pathname + window.location.search);
}
