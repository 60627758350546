import { inject } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap, ResolveFn, Router } from "@angular/router";
import { Observable, catchError, first, firstValueFrom, map, of } from "rxjs";
import { RouteKey, routes } from "src/app/modules/pension-plan/routes";
import { NavigateToPensionPlanService } from "src/app/services/navigate-to-pension-plan.service";
import { PensionPlanProgressService } from "src/app/services/pension-plan-progress.service";
import { PensionPlanProgressionState } from "src/app/services/pension-plan-progress.utils";
import { composePaths } from "src/app/utils/router.utils";

export const NAVIGATE_TO_PENSION_PLAN_QUERY_PARAM_KEY = "q";
export const NAVIGATE_TO_PENSION_PLAN_QUERY_PARAM_VALUE = "beregning";

export const navigateToPensionPlanResolver: ResolveFn<Promise<true>> = async (
  routeSnapshot: ActivatedRouteSnapshot,
) => {
  const activatedRoute = inject(ActivatedRoute);
  const router = inject(Router);
  const navigateToPensionPlanService = inject(NavigateToPensionPlanService);
  const pensionPlanProgressService = inject(PensionPlanProgressService);

  return getHasQueryParam(routeSnapshot.queryParamMap)
    ? navigateToPensionPlan(
        routeSnapshot,
        activatedRoute,
        router,
        navigateToPensionPlanService,
        pensionPlanProgressService,
      )
    : true;
};

async function navigateToPensionPlan(
  routeSnapshot: ActivatedRouteSnapshot,
  route: ActivatedRoute,
  router: Router,
  navigationService: NavigateToPensionPlanService,
  pensionPlanProgressService: PensionPlanProgressService,
): Promise<true> {
  const url = await firstValueFrom(getPensionPlanUrl(pensionPlanProgressService));

  // Remove query param before navigating to pension plan
  await router.navigate([], {
    relativeTo: route,
    queryParams: {
      ...routeSnapshot.queryParams,
      [NAVIGATE_TO_PENSION_PLAN_QUERY_PARAM_KEY]: null,
    },
    queryParamsHandling: "merge",
    replaceUrl: true,
  });

  if (url) {
    navigationService.navigateTo(url);
  } else {
    navigationService.navigateToProgressUrl();
  }

  return true;
}

function getPensionPlanUrl(pensionPlanProgressService: PensionPlanProgressService): Observable<string | undefined> {
  return pensionPlanProgressService.pensionPlanProgressionState$.pipe(
    first(),
    map(toUrl),
    catchError(() => of(undefined)),
  );
}

function getHasQueryParam(paramMap: ParamMap): boolean {
  return paramMap.get(NAVIGATE_TO_PENSION_PLAN_QUERY_PARAM_KEY) === NAVIGATE_TO_PENSION_PLAN_QUERY_PARAM_VALUE;
}

function toUrl(state: PensionPlanProgressionState): string | undefined {
  if (state === PensionPlanProgressionState.New) {
    return composePaths(routes, RouteKey.Landing);
  }

  return undefined;
}
