import { Injectable } from "@angular/core";
import { combineLatestWith, defaultIfEmpty, firstValueFrom, of, OperatorFunction, pipe, timeout } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CookieService, CookieValue } from "src/app/services/cookie.service";
import { CustomerService, getAge, getCustomerId, getGender } from "src/app/services/customer.service";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import {
  isEpkEngagement,
  isEpkFleksibelEngagement,
  isNorskpensjonEngagement,
} from "src/app/utils/engagement.typeguards";
import { getIsEpkFromNorskpensjon } from "src/app/utils/engagement.utils";
import { HasPublicPensionInStorebrandService } from "src/app/services/api/has-public-pension-in-storebrand.service";
import { NorskpensjonService } from "src/app/services/prognoses-services/norskpensjon.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";

enum PublicPension {
  None = "False",
  Storebrand = "Storebrand",
  External = "External",
}

export interface QualtricsEntry {
  key: QsiCookie;
  value: CookieValue;
}

export interface QualtricsValueStore {
  getEntry(): QualtricsEntry;
}

export enum QsiCookie {
  AgeHelper = "QSI_AGE_HELPER",
  GenderHelper = "QSI_GENDER_HELPER",
  PublicPensionHelper = "QSI_PUBLIC_PENSION_HELPER",
  EpkHelper = "QSI_EPK_HELPER",
  EpkSHelper = "QSI_EPK_S_HELPER",
  SurveyTrigger = "LOGGED_OUT_FROM_SMART_PENSION",
}

@Injectable({
  providedIn: "root",
})
export class QualtricsService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly customerService: CustomerService,
    private readonly engagementsService: EngagementsService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    private readonly norskpensjonService: NorskpensjonService,
  ) {
    this.setQualtricsWindowProperties();
  }

  public async setCookies(): Promise<void> {
    this.cookieService.setCookie(
      QsiCookie.AgeHelper,
      await firstValueFrom(this.customerService.age$.pipe(getValueOrUndefined())),
    );

    this.cookieService.setCookie(
      QsiCookie.GenderHelper,
      await firstValueFrom(this.customerService.gender$.pipe(getValueOrUndefined())),
    );

    this.cookieService.setCookie(
      QsiCookie.EpkHelper,
      await firstValueFrom(this.engagementsService.allEngagements$.pipe(map(getEpkValue), getValueOrUndefined())),
    );

    this.cookieService.setCookie(
      QsiCookie.EpkSHelper,
      await firstValueFrom(this.engagementsService.allEngagements$.pipe(map(getEpkSValue), getValueOrUndefined())),
    );

    const publicPension$ = this.engagementsService.hasOffentligTjenestepensjonFlag$.pipe(
      combineLatestWith(
        this.hasPublicPensionInStorebrandService.isPublicContext$,
        this.norskpensjonService.getProviderNameFromPublicPension(),
      ),
      map(applyArrayTo(getPublicPension)),
    );

    this.cookieService.setCookie(
      QsiCookie.PublicPensionHelper,
      await firstValueFrom(publicPension$.pipe(getValueOrUndefined())),
    );

    this.cookieService.setCookie("surveyTrigger", QsiCookie.SurveyTrigger);
  }

  private setQualtricsWindowProperties(): void {
    this.customerService.customer$.subscribe((customer) => {
      window.qualtricsGender = getGender(customer);
      window.qualtricsAge = getAge(customer);
      window.qualtricsCMID = getCustomerId(customer);
    });
  }
}

function getPublicPension(
  hasOffentligTjenestepensjonFlag: boolean,
  isPublicContext: boolean,
  providerName: string | undefined,
): string {
  if (isPublicContext) {
    return PublicPension.Storebrand;
  }

  if (hasOffentligTjenestepensjonFlag) {
    return providerName ?? PublicPension.External;
  }

  return PublicPension.None;
}

function getEpkValue(engagements: AnyEngagement[]): string {
  const supplier = engagements.filter(getIsEpk).map(getSupplier).at(0);

  return supplier ?? "None";
}

function getEpkSValue(engagements: AnyEngagement[]): string {
  const supplier = engagements.filter(isEpkFleksibelEngagement).map(getSupplier).at(0);

  return supplier ?? "None";
}

function getIsEpk(engagement: AnyEngagement): boolean {
  const isEpk = isEpkEngagement(engagement);
  const isNorskpensjonEpk = isNorskpensjonEngagement(engagement) && getIsEpkFromNorskpensjon(engagement);

  return isEpk || isNorskpensjonEpk;
}

function getSupplier(engagement: AnyEngagement): string | undefined {
  return engagement.getName().supplier;
}

function getValueOrUndefined<T>(): OperatorFunction<T, T | undefined> {
  return pipe(
    defaultIfEmpty(undefined),
    timeout(1),
    catchError(() => of(undefined)),
  );
}
