<ng-container *appLet="stepper$ | async as stepper" [formGroup]="otherPensionEngagementForm">
  <button mat-dialog-close>
    <i>close</i>
  </button>
  <h3 mat-dialog-title>{{ engagementSteps[stepper].windowTitle }}</h3>

  <mat-dialog-content>
    <ng-container *ngIf="stepper === 0" formGroupName="firstGroup">
      <p class="margin-bottom">
        {{ "otherPensionEngagement.introduction" | fms }}
      </p>

      <stb-form
        size="large"
        [label]="'otherPensionEngagement.form.title.label' | fms"
        [error]="title.touched && title.hasError('required')"
        [errorMessage]="'otherPensionEngagement.form.title.validation' | fms"
      >
        <input
          data-hj-suppress
          formControlName="title"
          id="title"
          type="text"
          [placeholder]="'otherPensionEngagement.form.title.placeholder' | fms"
        />
      </stb-form>

      <stb-form
        size="large"
        [label]="'otherPensionEngagement.form.payer.label' | fms"
        [error]="payer.touched && payer.hasError('required')"
        [errorMessage]="'otherPensionEngagement.form.payer.validation' | fms"
      >
        <input
          data-hj-suppress
          formControlName="payer"
          id="payer"
          type="text"
          [placeholder]="'otherPensionEngagement.form.payer.placeholder' | fms"
        />
      </stb-form>
    </ng-container>

    <ng-container *ngIf="stepper === 1" formGroupName="secondGroup">
      <div class="flex">
        <stb-form
          size="medium"
          [label]="'otherPensionEngagement.form.amountPerYear.label' | fms"
          [error]="amountPerYear.touched && (amountPerYear.hasError('required') || amountPerYear.hasError('pattern'))"
          [errorMessage]="'otherPensionEngagement.form.amountPerYear.validation' | fms"
        >
          <input appCurrencyMask formControlName="amountPerYear" type="tel" />
        </stb-form>

        <div class="select-form">
          <stb-form
            size="medium"
            [label]="'otherPensionEngagement.form.payoutStartYear.label' | fms"
            [errorMessage]="'otherPensionEngagement.form.payoutStartYear.validation' | fms"
            [error]="fromAge.touched && fromAge.hasError('required')"
          >
            <select formControlName="fromAge" id="fromAge">
              <option [value]="null" hidden disabled>
                {{ "otherPensionEngagement.form.payoutStartYear.label" | fms }}
              </option>
              <option *ngFor="let fromAge of fromAges$ | async" [value]="fromAge">
                {{ fromAge | year }}
              </option>
            </select>
          </stb-form>

          <stb-form
            size="medium"
            [label]="'otherPensionEngagement.form.payoutEndYear.label' | fms"
            [errorMessage]="'otherPensionEngagement.form.payoutEndYear.validation' | fms"
            [error]="endAge.touched && endAge.hasError('required')"
          >
            <select formControlName="endAge" id="endAge">
              <option [value]="null" hidden disabled>
                {{ "otherPensionEngagement.form.payoutEndYear.label" | fms }}
              </option>
              <option *ngFor="let endAge of endAges$ | async; let j = index" [value]="endAge">
                {{ j === 0 ? ("agreement_details.duration.life_long" | fms | titlecase) : (endAge | year) }}
              </option>
            </select>
          </stb-form>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      stbButton="outlined"
      type="button"
      (click)="onBackButton()"
      [attr.data-trackid]="'otherPensionEngagementDialog-backButton' + stepper"
    >
      {{ engagementSteps[stepper].backButtonText }}
    </button>
    <button
      stbButton="contained"
      type="button"
      (click)="onNextButton()"
      [attr.data-trackid]="'otherPensionEngagementDialog-nextButton' + stepper"
    >
      <ng-container *ngIf="hasInjectedEngagement() && this.isFinalStep(); else regularButtonText">{{
        "addEngagement.saveAfterEditButton.text" | fms
      }}</ng-container>
      <ng-template #regularButtonText>{{ engagementSteps[stepper].nextButtonText }}</ng-template>
    </button>
  </mat-dialog-actions>
</ng-container>
