import { Injectable } from "@angular/core";
import { Observable, combineLatest, distinctUntilChanged, map, skip, switchMap } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { SavingsGraphqlClientService } from "../../modules/graphql-clients/services/savings-graph-client.service";
import { memoizeObject$ } from "../../utils/rxjs/select";
import {
  PublicPensionPrognosisParametersWithStartPayoutAge,
  PublicPensionProgosisParametersDynamicService,
} from "../api/public-pension-prognosis-parameters-dynamic.service";
import { CustomerService } from "../customer.service";

const prognosis: keyof Graph.Query = "publicPensionPrognosis";
const prognosisParams: keyof Graph.Query = "publicPensionPrognosisParameters";

@Injectable()
export class PublicPensionConsentsPrognosisTriggerService {
  public readonly triggerPrognosisFetch$: Observable<unknown[]>;
  public readonly newConsent$: Observable<unknown[]>;

  constructor(
    private readonly customerService: CustomerService,
    private readonly savingsGraphqlClientService: SavingsGraphqlClientService,
    private readonly publicPensionPrognosisParametersDynamicService: PublicPensionProgosisParametersDynamicService,
  ) {
    this.triggerPrognosisFetch$ = memoizeObject$(
      combineLatest([
        this.customerService.navConsent$.pipe(distinctUntilChanged()),
        this.customerService.ofaConsent$.pipe(distinctUntilChanged()),
      ]).pipe(),
    );

    this.newConsent$ = this.triggerPrognosisFetch$.pipe(skip(1));
  }

  public evictPublicPensionPrognosisOnNewConsents(): Observable<boolean> {
    return this.newConsent$.pipe(
      map(() => {
        return evictFieldName(prognosis, this.savingsGraphqlClientService);
      }),
    );
  }

  public evictAndFetchParametersOnOfaConsent(): Observable<PublicPensionPrognosisParametersWithStartPayoutAge> {
    return this.customerService.ofaConsent$.pipe(
      distinctUntilChanged(),
      map(() => evictFieldName(prognosisParams, this.savingsGraphqlClientService)),
      switchMap(() =>
        this.publicPensionPrognosisParametersDynamicService.getLatestPensionPrognosisParametersByStartPayoutAge(),
      ),
    );
  }
}

function evictFieldName(fieldName: keyof Graph.Query, graphqlClient: SavingsGraphqlClientService): boolean {
  return graphqlClient.evict({ fieldName });
}
