import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE } from "src/app/constants/technical.constants";
import { SelectorService } from "src/app/interfaces/SelectorService";
import { EngagementSimulationStatus } from "src/app/models/pension.model";
import { SimulationSeverity } from "src/app/services/api/savings-and-pension-queries.types";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import { getFirstPayoutAge, PayoutplanService } from "src/app/services/payoutplan.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";
import { filterVisibleEngagements, getIsSimulationStatusNotOk } from "src/app/utils/engagement.utils";
import { getIsNullable } from "src/app/utils/utils";

export interface CurrentSimulation {
  readonly pensionAge: number;
  readonly pensionAgeTotalPayout: number;
  readonly pensionAgePartialWithdrawal: number;
  readonly pensionAgeTotalPayoutPartialWithdrawal: number;
  readonly partTimePercentage: number;
  readonly withdrawalPercentage: number;
  readonly includedEngagements: number;
  readonly totalEngagements: number;
  readonly simulationErrors: EngagementSimulationStatus[];
}

@Injectable({
  providedIn: "root",
})
export class CurrentSimulationSelectorService implements SelectorService<CurrentSimulation> {
  constructor(
    private readonly profileService: ProfileService,
    private readonly clientDataService: ClientDataService,
    private readonly engagementsService: EngagementsService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly payoutPlanService: PayoutplanService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
  ) {}

  public select(): Observable<CurrentSimulation> {
    const visibleEngagements$ = this.engagementsService.allEngagements$.pipe(
      map((engagements) => filterVisibleEngagements(engagements, this.profileService.hasAfp)),
    );

    const simByEngagementEnable$ = this.clientDataService.simulationParametersByEngagementEnable$.pipe(
      map(({ enable }) => enable),
    );
    const simByPartialWithdrawalEnable$ = this.clientDataService.simulationParametersPartialWithdrawalEnable$.pipe(
      map(({ enable }) => enable),
    );

    const startPayoutAge$ = this.storebrandOnlyService.getIsEnabled().pipe(
      switchMap((storebrandOnly) =>
        storebrandOnly
          ? this.payoutPlanService.payoutPlan$.pipe(map(getFirstPayoutAge))
          : this.startPayoutAgeService.getStartPayoutAge(),
      ),
      map((startPayoutAge) => {
        if (getIsNullable(startPayoutAge)) {
          throw new Error("startPayoutAge$ is nullable!");
        }

        return startPayoutAge;
      }),
    );

    const startPayoutAgePartialWithdrawal$ = this.payoutPlanService.payoutPlan$.pipe(map(getFirstPayoutAge)).pipe(
      map((pensionAgePartialWithdrawal) => {
        if (getIsNullable(pensionAgePartialWithdrawal)) {
          throw new Error("pensionAgePartialWithdrawal$ is nullable!");
        }

        return pensionAgePartialWithdrawal;
      }),
    );

    const partTimePercentage$ = this.clientDataService.simulationParameters$.pipe(
      map((params) => params.partTimePercentage ?? 0),
    );

    const withdrawalPercentage$ = this.clientDataService.simulationParameters$.pipe(
      map((params) => params.withdrawalPercentage ?? 50),
    );

    return combineLatest([
      simByEngagementEnable$,
      simByPartialWithdrawalEnable$,
      startPayoutAge$,
      startPayoutAgePartialWithdrawal$,
      this.payoutPlanService.firstYearTotal$,
      this.payoutPlanService.firstYearFullWithdrawalTotal$,
      this.payoutPlanService.firstYearPartialWithdrawalTotal$,
      visibleEngagements$,
      this.payoutPlanService.payoutPlan$,
      partTimePercentage$,
      withdrawalPercentage$,
    ]).pipe(map(applyArrayTo(createCurrentSimulation)));
  }
}

function createCurrentSimulation(
  simByEngagementEnable: boolean,
  simByPartialWithdrawalEnable: boolean,
  startPayoutAge: number,
  startPayoutAgePartialWithdrawal: number,
  firstYearTotal: number,
  firstYearFullWithdrawalTotal: number,
  firstYearPartialWithdrawalTotal: number,
  engagements: AnyEngagement[],
  payoutPlan: PayoutPlan.PayoutPlanPeriod[],
  partTimePercentage: number,
  withdrawalPercentage: number,
): CurrentSimulation {
  const includedEngagements = getNumberOfIncludedEngagements(payoutPlan, startPayoutAge);
  const totalEngagements = engagements.length;
  const simulationErrors = getEngagementSimulationErrors(engagements);

  const simulation: CurrentSimulation = {
    pensionAge: simByEngagementEnable ? CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE : startPayoutAge,
    pensionAgeTotalPayout: firstYearTotal,
    pensionAgePartialWithdrawal: 0,
    pensionAgeTotalPayoutPartialWithdrawal: 0,
    partTimePercentage: 0,
    withdrawalPercentage: 0,
    includedEngagements,
    totalEngagements,
    simulationErrors,
  };

  if (simByPartialWithdrawalEnable) {
    return {
      ...simulation,
      pensionAgeTotalPayout: firstYearFullWithdrawalTotal,
      pensionAgePartialWithdrawal: startPayoutAgePartialWithdrawal,
      pensionAgeTotalPayoutPartialWithdrawal: firstYearPartialWithdrawalTotal,
      partTimePercentage,
      withdrawalPercentage,
    };
  }

  return simulation;
}

function getNumberOfIncludedEngagements(payoutPlan: PayoutPlan.PayoutPlanPeriod[], startPayoutAge: number): number {
  const pensionAgePayoutPlan = payoutPlan.find(({ startAge }) => startAge === startPayoutAge);

  return pensionAgePayoutPlan?.payoutPlanPayouts?.length ?? 0;
}

function getEngagementSimulationErrors(engagements: AnyEngagement[]): EngagementSimulationStatus[] {
  return engagements
    .filter(getHasEngagementSimulationErrors)
    .flatMap((engagement) => engagement?.getSimulationStatus());
}

function getHasEngagementSimulationErrors(engagement: AnyEngagement): boolean {
  const criticalSimulationResultStatuses = [SimulationSeverity.BusinessError, SimulationSeverity.SimulationError];
  return getIsSimulationStatusNotOk(engagement, criticalSimulationResultStatuses);
}
