import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { combineLatest, firstValueFrom, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { MAX_LIFE_EXPECTANCY_AGE } from "src/app/constants/business.constants";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { FmsService } from "src/app/services/fms.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";
import { shiftLastElementToTopImmutable } from "src/app/utils/array";
import { getRangeFromBoundary } from "src/app/utils/getRangeFromBoundary";
import { parseNumber } from "src/app/utils/number";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";
import {
  AbstractNewEngagementStepperComponent,
  AddEngagementStep,
} from "../../../../shared/components/add-engagement/abstract-new-engagement-stepper.component";

interface OtherPensionEngagementForm {
  firstGroup: FormGroup<FirstFormGroup>;
  secondGroup: FormGroup<SecondFormGroup>;
}

interface FirstFormGroup {
  payer: FormControl<string>;
  title: FormControl<string>;
}

type Year = Nullable<string | number>;

interface SecondFormGroup {
  amountPerYear: FormControl<Nullable<number>>;
  fromAge: FormControl<Year>;
  endAge: FormControl<Year>;
}

@Component({
  selector: "app-other-pension-engagement",
  templateUrl: "./other-pension-engagement.component.html",
  styleUrls: [
    "./other-pension-engagement.component.scss",
    "../../../../shared/components/add-engagement/add-engagement.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherPensionEngagementComponent
  extends AbstractNewEngagementStepperComponent<OtherPensionEngagementComponent>
  implements OnInit
{
  public readonly endAges$: Observable<number[]>;
  public readonly fromAges$: Observable<number[]>;
  public readonly otherPensionEngagementForm: FormGroup<OtherPensionEngagementForm>;
  public readonly payer;
  public readonly title;
  public readonly amountPerYear;
  public readonly fromAge;
  public readonly endAge;

  private readonly engagementStepsControls: Pick<AddEngagementStep, "controlToValidate">[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly injectedEngagement: OtherPensionEngagement,
    protected readonly dialogRef: MatDialogRef<OtherPensionEngagementComponent>,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly fmsService: FmsService,
    private readonly pensionPlanService: PensionPlanService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    super(dialogRef);

    this.otherPensionEngagementForm = new FormGroup({
      firstGroup: new FormGroup({
        payer: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
        title: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      }),
      secondGroup: new FormGroup({
        amountPerYear: new FormControl<Nullable<number>>(null, {
          validators: [Validators.required],
        }),
        fromAge: new FormControl<Year>(null, { validators: [Validators.required] }),
        endAge: new FormControl<Year>(null, { validators: [Validators.required] }),
      }),
    });

    this.payer = this.otherPensionEngagementForm.controls.firstGroup.controls.payer;
    this.title = this.otherPensionEngagementForm.controls.firstGroup.controls.title;
    this.amountPerYear = this.otherPensionEngagementForm.controls.secondGroup.controls.amountPerYear;
    this.fromAge = this.otherPensionEngagementForm.controls.secondGroup.controls.fromAge;
    this.endAge = this.otherPensionEngagementForm.controls.secondGroup.controls.endAge;

    this.engagementStepsControls = [
      {
        controlToValidate: this.otherPensionEngagementForm.controls.firstGroup,
      },
      {
        controlToValidate: this.otherPensionEngagementForm.controls.secondGroup,
      },
    ];

    this.fromAges$ = this.startPayoutAgeService.getFirstPayoutAge().pipe(
      map((val) => val ?? 0),
      map((floor) => getRangeFromBoundary({ floor, ceil: MAX_LIFE_EXPECTANCY_AGE }, NaN)),
    );

    this.endAges$ = memoizeObject$(
      combineLatest([this.fromAges$, this.fromAge.valueChanges.pipe(map((num) => parseNumber(num) ?? 0))]).pipe(
        map(applyArrayTo(toArrayStartingAtValue)),
        map(toArrayWithLeadingLifelongOption),
      ),
    );

    this.endAges$.subscribe((range) => resetFormControlIfNotInRange(range, this.endAge));

    this.engagementSteps = this.fmsService
      .instant<Omit<AddEngagementStep, "controlToValidate">[]>("otherPensionEngagement.fmsEngagementSteps")
      .map((fmsSteps, i) => ({
        ...fmsSteps,
        ...this.engagementStepsControls[i],
      }));
  }

  public ngOnInit(): void {
    this.initiateEditMode();
  }

  public hasInjectedEngagement(): boolean {
    return !!this.injectedEngagement;
  }

  public async submit(): Promise<void> {
    const patch: Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension"> = {
      title: this.title.value,
      payer: this.payer.value,
      replaceOffentligTjenestepensjon: false,
      periods: [
        {
          fromAge: Number(this.fromAge.value),
          duration: this.getEngagementDuration(),
          payoutAmountPerYear: Number(this.amountPerYear.value),
        } as CustomerSuppliedData.OtherPensionPeriod,
      ],
    };

    if (this.hasInjectedEngagement()) {
      await this.pensionPlanService.editOtherPensionEngagement(this.injectedEngagement, patch);
    } else {
      await this.pensionPlanService.addOtherPensionEngagement(patch);
    }

    await firstValueFrom(this.fetchPrognosesRunningJobsService.isCurrentYearLoaded$.pipe(filter(Boolean)));
  }

  private initiateEditMode(): void {
    if (this.hasInjectedEngagement()) {
      const injectedEngagement = this.injectedEngagement.contract;
      const [period] = injectedEngagement.periods;

      this.payer.setValue(injectedEngagement.payer || "");
      this.title.setValue(injectedEngagement.title);
      this.amountPerYear.setValue(period.payoutAmountPerYear);

      /* Subscribe in order to catch emission as this happens before '| async' in the template.
      This to populate mat-select with values */
      const endAgesSubscription = this.endAges$?.subscribe();
      this.fromAge.setValue(period.fromAge);
      this.endAge.setValue(Number(period.fromAge) + Number(period.duration) - 1);
      endAgesSubscription?.unsubscribe();
    }
  }

  private getEngagementDuration(): number {
    return Number(this.endAge.value) - Number(this.fromAge.value) + 1;
  }
}

function toArrayWithLeadingLifelongOption(array: number[]): number[] {
  return shiftLastElementToTopImmutable(array);
}

function toArrayStartingAtValue(array: number[], start: Nullable<number>): number[] {
  const index = getIsNotNullable(start) ? array.findIndex(isEqual(start)) : 0;
  return index > -1 ? array.slice(index) : array;
}

function isEqual(num: number): (_num: number) => boolean {
  return (_num) => _num === num;
}

function resetFormControlIfNotInRange(range: number[], control: FormControl<Year>): void {
  const ctrlValue = parseNumber(control.value);
  if (getIsNotNullable(ctrlValue) && !range.includes(ctrlValue)) {
    control.reset();
  }
}
