/*
 * Ahead of time compiler in production means computed keys are not allowed
 * RouteKey enum values and keys in route declarations must match (spelling and casing)
 * Please ensure consistency manually
 * @todo: Make typescript plugin that ensures this is the case? Simple rule could be:
 * keys in routes & deprecatesroutes must match one of the enum values
 */
export enum RouteKey {
  Root = "Root",
  ContactUs = "ContactUs",
  Concept = "Concept",
}

export const routes = {
  Root: "",
  ContactUs: "booking",
  Concept: "konseptutvikling",
};
