import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { PresentableEngagementItem } from "../engagement-list-initiatives.utils";

@Component({
  selector: "app-engagement-list-initiatives-item",
  templateUrl: "./engagement-list-initiatives-item.component.html",
  styleUrls: ["./engagement-list-initiatives-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesItemComponent {
  @Input()
  public item!: PresentableEngagementItem;

  @Input()
  public isNumberedList: boolean = false;
}
