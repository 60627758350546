import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-engagement-list-initiatives-header",
  templateUrl: "./engagement-list-initiatives-header.component.html",
  styleUrls: ["./engagement-list-initiatives-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesHeaderComponent {
  @Input()
  public headers!: string[];
}
