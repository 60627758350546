import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ConsentService } from "src/app/services/consent.service";
import { FmsService } from "src/app/services/fms.service";
import { Nullable } from "src/app/utils/utils";

@Component({
  selector: "app-purchase-assistance-consent",
  templateUrl: "./purchase-assistance-consent.component.html",
  styleUrls: ["./purchase-assistance-consent.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseAssistanceConsentComponent implements OnInit {
  public readonly purpose: string;
  public readonly consentValue$: Observable<Nullable<boolean>>;

  constructor(
    private readonly consentService: ConsentService,
    private readonly fmsService: FmsService,
  ) {
    this.purpose = this.fmsService.instant("purchaseAssistanceConsent.text");
    this.consentValue$ = this.consentService.purchaseAssistanceConsentValue$;
  }

  public ngOnInit(): void {
    /**
     * The purchase assistance consent is opt-out.
     * Brand new users may not have a consent value at all,
     * in which case we default to 'accepted'.
     */
    if (this.consentService.hasNoPurchaseAssistanceConsent()) {
      this.setConsentValue(true);
    }
  }

  public setConsentValue(value: boolean): void {
    this.consentService.postAndFetchPurchaseAssistanceConsent(value, this.purpose).subscribe();
  }
}
