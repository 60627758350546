import { StbStage } from "src/app/utils/storebrand-staging";

async function loadScript(url: string, defer: boolean): Promise<void> {
  return new Promise((resolve, reject) => {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");

    script.src = url;
    script.async = false;
    script.defer = defer;
    script.onload = (): void => resolve();
    script.onerror = (): void => reject();

    body.appendChild(script);
  });
}

async function* loadHeadlessJqueryGenerator(): AsyncGenerator {
  await loadScript("https://elements.storebrand.no/storebrand.ny/headless/headless-jquery-3.3.1.js", false);

  while (true) {
    yield;
  }
}

async function* loadHeadlessPagePaddingGenerator(): AsyncGenerator {
  await loadScript("https://elements.storebrand.no/storebrand.ny/headless/headless-page-padding-2.0.0.js", false);

  while (true) {
    yield;
  }
}

export function loadMacroContainer(environment: StbStage): Promise<void> {
  const host = environment === StbStage.Produksjon ? "www" : "www-t";

  return loadScript(`https://${host}.storebrand.no/micro/container/index.js`, false);
}

export const loadHeadlessJquery = loadHeadlessJqueryGenerator();
export const loadHeadlessPagePadding = loadHeadlessPagePaddingGenerator();
