import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { AddButtonKey } from "../add-engagement/add-engagements-buttons/add-engagements-buttons.component";

@Component({
  selector: "app-add-afp-cta-card",
  templateUrl: "./add-afp-cta-card.component.html",
  styleUrls: ["./add-afp-cta-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAfpCtaCardComponent {
  public readonly AddButtonKey = AddButtonKey;

  constructor(private readonly clientDataService: ClientDataService) {}

  public hideAfp(): void {
    this.clientDataService.updateOptOutAfp(true);
  }
}
