import { ChangeDetectionStrategy, Component } from "@angular/core";
import { firstValueFrom, map } from "rxjs";
import { RouteKey, routes } from "src/app/routes";
import { FmsService } from "src/app/services/fms.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { isStageP } from "src/app/utils/storebrand-staging";

interface Faq {
  question: string;
  answer: string;
}

@Component({
  selector: "app-manage-pkb-pages",
  templateUrl: "./manage-pkb-pages.component.html",
  styleUrls: ["./manage-pkb-pages.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagePkbPagesComponent {
  public readonly points$ = this.fmsService.translateAsync<string[]>("managePkbPages.points");
  public readonly faq$ = this.fmsService.translateAsync<Faq[]>("managePkbPages.faq");
  public readonly frontPageRoute = routes[RouteKey.Root];

  private readonly sso$ = this.keycloakService.isAuthLevel3or4$;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly url$ = this.sso$.pipe(map(getNorskPensjonUrlWithSso));

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly fmsService: FmsService,
  ) {}

  public async openNorskPensjon(): Promise<void> {
    const url = await firstValueFrom(this.url$);

    window.open(url, "_blank");
  }
}

function getNorskPensjonUrlWithSso(useSso: boolean): string {
  return useSso ? `${getNorskPensjonUrl()}?sso=storebrand` : getNorskPensjonUrl();
}

function getNorskPensjonUrl(): string {
  return !isStageP() ? "https://www.preprod.norskpensjon.no/reservasjon/" : "https://www.norskpensjon.no/reservasjon/";
}
