<details class="list-advisor-accesses">
  <summary>{{ this.fmsService.translateAsync('keycloak.info.heading') | async }}</summary>
  <p>{{ roleInfo$ | async }}</p>
  <ul>
    <li *ngFor="let role of advisorResourceRoles$ | async">
      <p>
        {{ this.fmsService.translateAsync(role) | async }}
      </p>
  </ul>
</details>
