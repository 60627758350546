import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { filterUnique } from "src/app/utils/array";
import { getIsFiniteNumber } from "src/app/utils/number";
import { NotNull } from "src/app/utils/types";
import { getIsNotNullable } from "src/app/utils/utils";

const SALARY_QUERY = gql<unknown, Graph.SavingsEngagement>`
  query getSalaryQuery($input: QueryInput) {
    savingsEngagement(input: $input) {
      epkContracts {
        salary
        employer {
          organizationNumber
        }
      }
      ytpContracts {
        salary
        employer {
          organizationNumber
        }
      }
      hybridMedInvesteringsvalgContracts {
        salary
        employer {
          organizationNumber
        }
      }
      hybridMedGarantiContracts {
        salary
        employer {
          organizationNumber
        }
      }
    }
  }
`;

type Contracts =
  | Graph.EpkContract
  | Graph.YtpContract
  | Graph.HybridMedGarantiContract
  | Graph.HybridMedInvesteringsvalgContract;

type Fields = Pick<Contracts, "salary" | "employer">;

export interface GetSalaryQueryResponse {
  savingsEngagement?: {
    epkContracts?: Fields[];
    ytpContracts?: Fields[];
    hybridMedInvesteringsvalgContracts?: Fields[];
    hybridMedGarantiContracts?: Fields[];
  };
}

@Injectable({
  providedIn: "root",
})
export class ContractSalaryService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getSalary(): Observable<NotNull<Graph.EpkContract["salary"]>> {
    return this.savingsGraphqlClient
      .query<GetSalaryQueryResponse>({ query: SALARY_QUERY })
      .pipe(switchMap(getApolloResponseData), map(getSalaryFromResponse));
  }
}

export function getSalaryFromResponse(data: GetSalaryQueryResponse): number | undefined {
  const contracts = [
    data?.savingsEngagement?.epkContracts,
    data?.savingsEngagement?.ytpContracts,
    data?.savingsEngagement?.hybridMedInvesteringsvalgContracts,
    data?.savingsEngagement?.hybridMedGarantiContracts,
  ]
    .filter(getIsNotNullable)
    .flatMap((contract) => contract);
  const uniqueContracts = filterUnique(contracts, (c) => !!c.employer?.organizationNumber);

  const contractsWithSalary = (uniqueContracts || []).map((contract) => contract.salary).filter(getIsFiniteNumber);

  const hasContractsWithSalary = contractsWithSalary.length > 0;

  return hasContractsWithSalary ? contractsWithSalary.reduce((sum, salary) => sum + salary, 0) : undefined;
}
