<ng-content *ngIf="renderTrigger$ | async; else listSkeleton"></ng-content>
<ng-template #listSkeleton>
  <div *ngFor="let number of rowCount$ | async">
    <!-- &nbsp; to ensure same height as parent text element -->
    <span class="blank-5">&nbsp;</span>
    <span class="blob-skeleton blob-50">&nbsp;</span>
    <span class="blank-5">&nbsp;</span>
    <span class="blob-skeleton blob-15">&nbsp;</span>
    <span class="blank-5">&nbsp;</span>
    <span class="blob-skeleton blob-15">&nbsp;</span>
    <span class="blank-5">&nbsp;</span>
  </div>
</ng-template>
