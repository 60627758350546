/**
 * @file autogenerated by [GraphQL code generator](https://graphql-code-generator.com).
 * via a script in `package.json`. Do not edit this file, as it will get overwritten.
 * @see `codegen.ts` in the project root.
 */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: any; output: any };
}

export interface Account {
  __typename?: "Account";
  assetId?: Maybe<AssetId>;
  holdings?: Maybe<Holding>;
  isExcludedFromProfitCalculation?: Maybe<Scalars["Boolean"]["output"]>;
  keyValues?: Maybe<KeyValues>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  transactions?: Maybe<Array<PortfolioInsightTransaction>>;
}

export interface AccountOperator {
  __typename?: "AccountOperator";
  /** The account operator. E.g 'EIK', 'DAB', 'F' */
  accountOperatorCode?: Maybe<Scalars["String"]["output"]>;
  /** The account name in norwegian. E.g 'EIKA', 'DANSE BANK' */
  accountOperatorName?: Maybe<Scalars["String"]["output"]>;
}

export interface AccumulatedBalance {
  __typename?: "AccumulatedBalance";
  age?: Maybe<Scalars["Int"]["output"]>;
  balance?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["ISO8601Date"]["output"]>;
  maxBalance?: Maybe<Scalars["Int"]["output"]>;
  minBalance?: Maybe<Scalars["Int"]["output"]>;
}

export interface ActiveState {
  __typename?: "ActiveState";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  prettyName?: Maybe<Scalars["String"]["output"]>;
}

export interface AdditionalDataPrognosis {
  __typename?: "AdditionalDataPrognosis";
  balancePayoutStart?: Maybe<Scalars["Int"]["output"]>;
  balancePayoutStartMax?: Maybe<Scalars["Int"]["output"]>;
  balancePayoutStartMin?: Maybe<Scalars["Int"]["output"]>;
}

export interface AgeCoverage {
  __typename?: "AgeCoverage";
  annualAmount?: Maybe<Scalars["Float"]["output"]>;
  coverageName?: Maybe<CoverageName>;
  coverageTypeCode?: Maybe<Scalars["String"]["output"]>;
  fromAndIncludingAge?: Maybe<Scalars["Int"]["output"]>;
  lifelongPayout?: Maybe<Scalars["Boolean"]["output"]>;
  toNotIncludingAge?: Maybe<Scalars["Int"]["output"]>;
}

/**
 * ## Alis Contracts
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface AlisContract extends Contract {
  __typename?: "AlisContract";
  /**  Boolean flag indicating whether the contract is active contract */
  activeContract?: Maybe<Scalars["Boolean"]["output"]>;
  /**  The total balance on the contract. The balance amount may differ from the amount available for withdrawal */
  balance?: Maybe<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in alis */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  ID is on the format {contractNumber}:ALIS_A */
  id?: Maybe<Scalars["ID"]["output"]>;
  /**  Boolean flag indicating whether the contract is classified as an insurance contract */
  insuranceContract?: Maybe<Scalars["Boolean"]["output"]>;
  /**  Boolean flag indicating whether the customer is the joint owner of the contract */
  jointOwner?: Maybe<Scalars["Boolean"]["output"]>;
  /**  Boolean flag indicating whether the customer is the main owner of the contract */
  mainOwner?: Maybe<Scalars["Boolean"]["output"]>;
  /**  How much the customer pays for the contract on a yearly basis */
  premiumYearlyAmount?: Maybe<Scalars["Float"]["output"]>;
  /**  Product Area for ALIS contract. Only for internal use. */
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Internal use. Code used to identify the contract type */
  productMasterCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  List of roles on the contract */
  roles?: Maybe<Array<AlisRole>>;
  /**  Boolean flag indicating whether the contract is classified as a savings contract */
  savingsContract?: Maybe<Scalars["Boolean"]["output"]>;
  /**  Boolean flag indicating whether the contract is eligible for prognosis simulation */
  simulatable?: Maybe<Scalars["Boolean"]["output"]>;
}

/**
 * ## Alis Role
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface AlisRole {
  __typename?: "AlisRole";
  addressingCode?: Maybe<Scalars["String"]["output"]>;
  /**  The customer number of the role holder */
  cmId?: Maybe<Scalars["Int"]["output"]>;
  /**  Flag indicating whether the role holder is the owner of the contract */
  ownerFlag?: Maybe<Scalars["String"]["output"]>;
  /**  Flag indicating whether the role holder is the payer for the contract */
  payerFlag?: Maybe<Scalars["String"]["output"]>;
  /**  Internal code used to classify the role */
  roleCode?: Maybe<Scalars["String"]["output"]>;
  /**  Flag indicating whether the role holder is dead */
  roleDeadFlag?: Maybe<Scalars["String"]["output"]>;
  /**  Text explaining the role */
  roleText?: Maybe<Scalars["String"]["output"]>;
}

export interface Amount {
  __typename?: "Amount";
  currency?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
}

export interface AskTaxDetails {
  __typename?: "AskTaxDetails";
  depositInNok?: Maybe<Scalars["Float"]["output"]>;
  unusedDeductableRiskFreeReturnInNok?: Maybe<Scalars["Float"]["output"]>;
}

export interface AssetId {
  __typename?: "AssetId";
  accountId?: Maybe<Scalars["String"]["output"]>;
  participantId?: Maybe<Scalars["String"]["output"]>;
  systemArea?: Maybe<SystemArea>;
}

export interface AvailableAction {
  __typename?: "AvailableAction";
  actionNavigationLink: Scalars["String"]["output"];
  actionStatus: AvailableActionStatus;
  actionType: AvailableActionTypes;
  supportExitUrl: Scalars["Boolean"]["output"];
}

export enum AvailableActionStatus {
  ActionAllowed = "ACTION_ALLOWED",
  ActionNotAllowed = "ACTION_NOT_ALLOWED",
  InformationNeeded = "INFORMATION_NEEDED",
}

export enum AvailableActionTypes {
  AskMove = "ASK_MOVE",
  BackToCollective = "BACK_TO_COLLECTIVE",
  BuyFund = "BUY_FUND",
  ConvertToFmi = "CONVERT_TO_FMI",
  CreatePeriodicDeposit = "CREATE_PERIODIC_DEPOSIT",
  Downscaling = "DOWNSCALING",
  EditableName = "EDITABLE_NAME",
  FundSwitch = "FUND_SWITCH",
  LumpsumDeposit = "LUMPSUM_DEPOSIT",
  PensionWithdrawal = "PENSION_WITHDRAWAL",
  SavingAgreements = "SAVING_AGREEMENTS",
  SellFund = "SELL_FUND",
  TradeBankAccount = "TRADE_BANK_ACCOUNT",
  UpdatePeriodicDeposit = "UPDATE_PERIODIC_DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

/**
 * # UCITSAccount
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.bank.private
 * * Realm corporate   - TODO
 */
export interface BankAccount {
  __typename?: "BankAccount";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  agreementActive?: Maybe<Scalars["Boolean"]["output"]>;
  agreementValidToDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  aliasName?: Maybe<Scalars["String"]["output"]>;
  bankAgreementStatus?: Maybe<Scalars["String"]["output"]>;
  bankAgreementType?: Maybe<Scalars["String"]["output"]>;
  bookBalance?: Maybe<Scalars["Float"]["output"]>;
  cardTypeName?: Maybe<Scalars["String"]["output"]>;
  cardValidToDate?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  creditLimit?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  currentInterestRate?: Maybe<Scalars["Float"]["output"]>;
  disposableBalance?: Maybe<Scalars["Float"]["output"]>;
  effectiveInterestRate?: Maybe<Scalars["Float"]["output"]>;
  /**  ID is on the format {accountNumber}:SDC_A */
  id?: Maybe<Scalars["ID"]["output"]>;
  instalmentPauseFromDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  instalmentPauseToDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  mortgageTypeName?: Maybe<Scalars["String"]["output"]>;
  participants?: Maybe<Array<BankAccountParticipant>>;
  paymentDueDay?: Maybe<Scalars["Int"]["output"]>;
  paymentFrequency?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  productCode?: Maybe<Scalars["String"]["output"]>;
  productFriendlyName: Scalars["String"]["output"];
  productLevel3?: Maybe<ProductLevel>;
  productLevel4?: Maybe<ProductLevel>;
  reservedAmount?: Maybe<Scalars["Float"]["output"]>;
}

export interface BankAccountParticipant {
  __typename?: "BankAccountParticipant";
  agreementDetailedCode?: Maybe<Scalars["String"]["output"]>;
  customerIdentificator?: Maybe<Scalars["String"]["output"]>;
  participantId?: Maybe<Scalars["String"]["output"]>;
  participantName?: Maybe<Scalars["String"]["output"]>;
  participantTypeName?: Maybe<Scalars["String"]["output"]>;
}

export interface BankOpenPrognosisInput {
  balance?: InputMaybe<Scalars["Float"]["input"]>;
  birthYear?: InputMaybe<Scalars["Int"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  monthsOffset?: InputMaybe<Scalars["Int"]["input"]>;
  payoutDurationYears?: InputMaybe<Scalars["Int"]["input"]>;
  periodicDeposit?: InputMaybe<Scalars["Int"]["input"]>;
  startPayoutAge?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface BaseAmount {
  __typename?: "BaseAmount";
  amount?: Maybe<Scalars["Int"]["output"]>;
  validFrom?: Maybe<Scalars["ISO8601Date"]["output"]>;
}

export interface Beneficiary {
  __typename?: "Beneficiary";
  /** The percentage of the coverage that should go to the beneficiary */
  coveragePercentage?: Maybe<Scalars["Float"]["output"]>;
  /** The code for the coverage type */
  coverageTypeCode?: Maybe<Scalars["String"]["output"]>;
  /** The first name of the beneificiary */
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** The last name of the beneficiary */
  lastName?: Maybe<Scalars["String"]["output"]>;
}

export interface CashHoldings {
  __typename?: "CashHoldings";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  partOfAmountInTransit?: Maybe<Scalars["Float"]["output"]>;
}

/** # Shared details between contracts */
export interface Contract {
  id?: Maybe<Scalars["ID"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
}

export interface ContractNavigation {
  __typename?: "ContractNavigation";
  availableActions?: Maybe<Array<AvailableAction>>;
  contractDetailsLink?: Maybe<Scalars["String"]["output"]>;
}

export interface CostPrice {
  __typename?: "CostPrice";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
}

export interface CoverageName {
  __typename?: "CoverageName";
  english?: Maybe<Scalars["String"]["output"]>;
  norwegian?: Maybe<Scalars["String"]["output"]>;
}

export interface Coverages {
  __typename?: "Coverages";
  ageCoverages?: Maybe<Array<AgeCoverage>>;
  conditionalAgeCoverages?: Maybe<Array<AgeCoverage>>;
  insuranceCoverages?: Maybe<Array<InsuranceCoverage>>;
}

export interface DebtorInfo {
  __typename?: "DebtorInfo";
  approved?: Maybe<Scalars["String"]["output"]>;
  bban?: Maybe<Scalars["String"]["output"]>;
  cancelled?: Maybe<Scalars["String"]["output"]>;
  kid?: Maybe<Scalars["String"]["output"]>;
}

export interface Deferred {
  __typename?: "Deferred";
  from?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
}

export interface Description {
  __typename?: "Description";
  en?: Maybe<Scalars["String"]["output"]>;
  no?: Maybe<Scalars["String"]["output"]>;
}

export interface EkstrapensjonEmploymentContract extends Contract, LinkContractAbstract {
  __typename?: "EkstrapensjonEmploymentContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds. */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  /**  The amount the employer saves yearly for the customer. The amount is invested according to the distribution percentage in investmentProfile */
  employerAnnualSavingsAmount?: Maybe<Scalars["Float"]["output"]>;
  /**
   *  The KID number customer can use to transfer money from their own bank account to the ProLink contract.
   *  Money transferred will be invested according setting of the product propeties to the contract's product code.
   *  The three options are 0. "Don't invest" 1. "Invest according to the current holdings profile" 2. "Invest according to the investment profile"
   *  The current choice for the products in ProLink is 2. Invest according to the investment profile
   */
  fixedKid?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A. */
  id?: Maybe<Scalars["ID"]["output"]>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /** The bank account number in ProLink used when doing one time payments using the fixed kid */
  oneTimeDepositAccountNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile */
  redistributionProfile?: Maybe<Array<RedistributionProfile>>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  /**  Savings agreement or the the periodic deposits that customer does */
  savingsAgreements?: Maybe<Array<SavingsAgreement>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  trade account number in ProLink */
  tradeAccountSell?: Maybe<Scalars["String"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface Employer {
  __typename?: "Employer";
  name?: Maybe<Scalars["String"]["output"]>;
  /**  Can be null when norskpensjon due to the integration not returing organizationNumber */
  organizationNumber?: Maybe<Scalars["String"]["output"]>;
}

/**
 * Boolean flags indicating whether customer has contracts
 * individual = Alis contracts
 * pensionFund = Abakus contracts
 */
export interface EngagementFlag {
  __typename?: "EngagementFlag";
  individual?: Maybe<Scalars["Boolean"]["output"]>;
  pensionFund?: Maybe<Scalars["Boolean"]["output"]>;
}

/**
 * ## Egen pensjonskonto
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface EpkContract extends Contract {
  __typename?: "EpkContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountOperator?: Maybe<AccountOperator>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  annualPremium?: Maybe<Scalars["Float"]["output"]>;
  applicableVacancyRate?: Maybe<Scalars["Float"]["output"]>;
  calculatedNationalInsuranceBasicAmount?: Maybe<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  Date for first deposit in prolink */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  employedDate?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  /**  The amount the employer saves yearly for the customer. The amount is invested according to the distribution percentage in investmentProfile */
  employerAnnualSavingsAmount?: Maybe<Scalars["Float"]["output"]>;
  employmentPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  investmentProfile?: Maybe<Array<InvestmentProfile>>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  pensionQualifyingIncome?: Maybe<Scalars["Float"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  pkrEpkId?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  reductionFund?: Maybe<ReductionFund>;
  renewalDetails?: Maybe<RenewalDetail>;
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated This field is deprecated, use keyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  salary?: Maybe<Scalars["Float"]["output"]>;
  salaryHistory?: Maybe<Array<SalaryHistory>>;
  savingsRate?: Maybe<SavingsRate>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  /**  Contract number in USB */
  usbContractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  usbContractType?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  usbPersonCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  usbProductCode?: Maybe<Scalars["String"]["output"]>;
  usbTermDuration?: Maybe<UsbTermDuration>;
}

/**
 * ## Egen pensjonskonto contract without the investement.
 * The investement has been moved internally to an egen pensjonskonto fleksibel or to a different penion proivder
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface EpkEmploymentContract extends Contract {
  __typename?: "EpkEmploymentContract";
  /** "The account operator. E.g 'EIK', 'DAB', 'F'" */
  accountOperator?: Maybe<AccountOperator>;
  annualPremium?: Maybe<Scalars["Float"]["output"]>;
  applicableVacancyRate?: Maybe<Scalars["Float"]["output"]>;
  calculatedNationalInsuranceBasicAmount?: Maybe<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  contractType?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  employedDate?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  /**  The amount the employer saves yearly for the customer. The amount is transfered to the customers selected pension provider */
  employerAnnualSavingsAmount?: Maybe<Scalars["Float"]["output"]>;
  employmentPercentage?: Maybe<Scalars["Float"]["output"]>;
  epkFleksibelContract?: Maybe<EpkFleksibelContract>;
  id?: Maybe<Scalars["ID"]["output"]>;
  pensionQualifyingIncome?: Maybe<Scalars["Float"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  pkrEpkId?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  renewalDetails?: Maybe<RenewalDetail>;
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  salary?: Maybe<Scalars["Float"]["output"]>;
  salaryHistory?: Maybe<Array<SalaryHistory>>;
  savingsRate?: Maybe<SavingsRate>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  usbTermDuration?: Maybe<UsbTermDuration>;
}

/**
 * ## Egen pensjonskonto fleksibel/Egen pensjonskonto selvalgt(Before the rename)
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface EpkFleksibelContract extends Contract {
  __typename?: "EpkFleksibelContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  Date for first deposit in prolink */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  /** If this contract has a corresponding epkEmploymentContract, this is its id. */
  epkEmploymentContractId?: Maybe<Scalars["ID"]["output"]>;
  fromUsbContract?: Maybe<FromUsbContract>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  investmentProfile?: Maybe<Array<InvestmentProfile>>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  pkrEpkId?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  usbCalculation?: Maybe<UsbCalculation>;
  /**  Contract number in USB */
  usbContractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  usbContractType?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  usbPersonCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  usbProductCode?: Maybe<Scalars["String"]["output"]>;
}

/**
 * ## Egen pensjonskonto fleksibel pkb
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface EpkFleksibelPkbContract extends Contract {
  __typename?: "EpkFleksibelPkbContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  Date for first deposit in prolink */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  fromUsbContract?: Maybe<FromUsbContract>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  investmentProfile?: Maybe<Array<InvestmentProfile>>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  pkrEpkId?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile */
  redistributionProfile?: Maybe<Array<RedistributionProfile>>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  /**  Contract number in USB */
  usbContractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  usbContractType?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  usbPersonCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  usbProductCode?: Maybe<Scalars["String"]["output"]>;
}

export interface EpkSplit {
  __typename?: "EpkSplit";
  activeMarketValue?: Maybe<Amount>;
  passiveMarketValue?: Maybe<Amount>;
}

/**
 * ## Fripolise med investeringsvalg
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface FmiContract extends Contract {
  __typename?: "FmiContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  compressionLimits: Array<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  Date for first deposit in prolink */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  fromUsbContract?: Maybe<FromUsbContract>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  id?: Maybe<Scalars["ID"]["output"]>;
  investmentProfile?: Maybe<Array<InvestmentProfile>>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  selectableInvestmentProfileInPayoutPeriod?: Maybe<Array<Maybe<InvestmentProfileInPayoutPeriod>>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  /**  Contract number in USB */
  usbContractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  usbContractType?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  usbPersonCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  usbProductCode?: Maybe<Scalars["String"]["output"]>;
}

/**
 * # Fripolise
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface FripoliseContract extends Contract {
  __typename?: "FripoliseContract";
  annualPremium?: Maybe<Scalars["Float"]["output"]>;
  canBeConvertedToFmi?: Maybe<Scalars["Boolean"]["output"]>;
  compressionLimits: Array<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  contractType?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  fromUsbContract?: Maybe<FromUsbContract>;
  id?: Maybe<Scalars["ID"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  usbTermDuration?: Maybe<UsbTermDuration>;
}

export interface FromUsbContract {
  __typename?: "FromUsbContract";
  fromContractNumber?: Maybe<Scalars["Int"]["output"]>;
  fromContractType?: Maybe<Scalars["String"]["output"]>;
  fromProductCode?: Maybe<Scalars["String"]["output"]>;
}

export interface FullWithdrawal {
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *  When the customer will do a full withdrawal from pension but still wants to continue working.
   *  This should be annual salary after full withdrawal. Defaults to zero if not provided
   *  Should be calculated by the client. Often this would not be the full time salary when the customer works less than 100%
   */
  salaryAfterFullWithdrawal?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FullWithdrawalInput {
  /**  End age + month offset is optional. Is currently ignored for usb-products, where you can only set start date. If date is invalid(too early, or in violation of business rules) date is ignored and set to date registered on the contract. */
  endAge?: InputMaybe<Scalars["Int"]["input"]>;
  endMonthOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startAge: Scalars["Int"]["input"];
  startMonthOffset: Scalars["Int"]["input"];
}

export interface Fund {
  __typename?: "Fund";
  amount?: Maybe<Amount>;
  /**  The isin of the fund */
  isin?: Maybe<Scalars["String"]["output"]>;
  /**  The name of the fund */
  name?: Maybe<Scalars["String"]["output"]>;
  percent?: Maybe<Scalars["Float"]["output"]>;
}

export interface FundHoldings {
  __typename?: "FundHoldings";
  costPrice?: Maybe<CostPrice>;
  isin?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<MarketValue>;
  name?: Maybe<Scalars["String"]["output"]>;
  nav?: Maybe<NetAssetValue>;
  units?: Maybe<Scalars["Float"]["output"]>;
  unrealizedProfit?: Maybe<UnrealizedProfit>;
  unrealizedProfitInPercent?: Maybe<Scalars["Float"]["output"]>;
}

export interface FundHoldingsTotal {
  __typename?: "FundHoldingsTotal";
  costPrice?: Maybe<CostPrice>;
  marketValue?: Maybe<MarketValue>;
  unrealizedProfit?: Maybe<UnrealizedProfit>;
  unrealizedProfitInPercent?: Maybe<Scalars["Float"]["output"]>;
}

export interface FundOpenPrognosisInput {
  birthYear?: InputMaybe<Scalars["Int"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  monthsOffset?: InputMaybe<Scalars["Int"]["input"]>;
  payoutDurationYears?: InputMaybe<Scalars["Int"]["input"]>;
  periodicDeposit?: InputMaybe<Scalars["Int"]["input"]>;
  startPayoutAge?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FundProfile {
  categoryId?: InputMaybe<Scalars["String"]["input"]>;
  isin: Scalars["String"]["input"];
  marketValueShare?: InputMaybe<Scalars["Float"]["input"]>;
  profileShare?: InputMaybe<Scalars["Float"]["input"]>;
  targetPercentage?: InputMaybe<Scalars["Float"]["input"]>;
}

/**
 * Description: TODO
 * Online/ADS: online warning
 * Scopes required: Realm storebrand - storebrand.life.private
 */
export interface HistoricMarketValue {
  __typename?: "HistoricMarketValue";
  dateOfValue?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
}

export interface Holding {
  __typename?: "Holding";
  cash?: Maybe<Amount>;
  epkSplit?: Maybe<EpkSplit>;
  funds?: Maybe<Array<PortfolioInsightFund>>;
}

/**
 * # Hybrid med garanti
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface HybridMedGarantiContract extends Contract {
  __typename?: "HybridMedGarantiContract";
  annualPremium?: Maybe<Scalars["Float"]["output"]>;
  applicableVacancyRate?: Maybe<Scalars["Float"]["output"]>;
  calculatedNationalInsuranceBasicAmount?: Maybe<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  contractType?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  employedDate?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  /**  The amount the employer saves yearly for the customer. The amount is invested into a guarantee */
  employerAnnualSavingsAmount?: Maybe<Scalars["Float"]["output"]>;
  employmentPercentage?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  pensionQualifyingIncome?: Maybe<Scalars["Float"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  renewalDetails?: Maybe<RenewalDetail>;
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  salary?: Maybe<Scalars["Float"]["output"]>;
  salaryHistory?: Maybe<Array<SalaryHistory>>;
  savingsRate?: Maybe<SavingsRate>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  usbTermDuration?: Maybe<UsbTermDuration>;
}

/**
 * ## Hybrid med investeringsvalg
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface HybridMedInvesteringsvalgContract extends Contract {
  __typename?: "HybridMedInvesteringsvalgContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  annualPremium?: Maybe<Scalars["Float"]["output"]>;
  applicableVacancyRate?: Maybe<Scalars["Float"]["output"]>;
  calculatedNationalInsuranceBasicAmount?: Maybe<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  employedDate?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  /**  The amount the employer saves yearly for the customer. The amount is invested according to the distribution percentage in investmentProfile */
  employerAnnualSavingsAmount?: Maybe<Scalars["Float"]["output"]>;
  employmentPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  investmentProfile?: Maybe<Array<InvestmentProfile>>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  pensionQualifyingIncome?: Maybe<Scalars["Float"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  reductionFund?: Maybe<ReductionFund>;
  renewalDetails?: Maybe<RenewalDetail>;
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  salary?: Maybe<Scalars["Float"]["output"]>;
  salaryHistory?: Maybe<Array<SalaryHistory>>;
  savingsRate?: Maybe<SavingsRate>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  /**  Contract number in USB */
  usbContractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  usbContractType?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  usbPersonCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  usbProductCode?: Maybe<Scalars["String"]["output"]>;
  usbTermDuration?: Maybe<UsbTermDuration>;
}

/**
 * # Pensjonsbevis from hybrid
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface HybridPensjonsbevisContract extends Contract {
  __typename?: "HybridPensjonsbevisContract";
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  contractType?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  fromUsbContract?: Maybe<FromUsbContract>;
  id?: Maybe<Scalars["ID"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
}

export interface InsuranceCoverage {
  __typename?: "InsuranceCoverage";
  annualAmount?: Maybe<Scalars["Float"]["output"]>;
  coverageName?: Maybe<CoverageName>;
  coverageTypeCode?: Maybe<Scalars["String"]["output"]>;
  fromAndIncludingAge?: Maybe<Scalars["Int"]["output"]>;
  lifelongPayout?: Maybe<Scalars["Boolean"]["output"]>;
  toNotIncludingAge?: Maybe<Scalars["Int"]["output"]>;
}

export interface InvestmentProfile {
  __typename?: "InvestmentProfile";
  distributionPercentage?: Maybe<Scalars["Float"]["output"]>;
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

/**
 *  This property is related to FMI contracts and is used when running prognosis and creating new settlement.
 *  Default choice is GUARANTEE.
 */
export enum InvestmentProfileInPayoutPeriod {
  Guarantee = "GUARANTEE",
  Investments = "INVESTMENTS",
}

export interface IpsOpenPrognosisInput {
  admFeeCode: Scalars["String"]["input"];
  birthDate: Scalars["String"]["input"];
  contractNumber: Scalars["String"]["input"];
  fundProfiles?: InputMaybe<Array<FundProfile>>;
  gender: Scalars["String"]["input"];
  inflation: Scalars["Boolean"]["input"];
  lifelongReduction: Scalars["Boolean"]["input"];
  periodicDeposit: Scalars["Float"]["input"];
  singleDeposit: Scalars["Float"]["input"];
  startPayoutAge: Scalars["Int"]["input"];
}

export interface KeyValues {
  __typename?: "KeyValues";
  annualAverageReturn?: Maybe<Amount>;
  annualAverageReturnPct?: Maybe<Scalars["Float"]["output"]>;
  lastQueryYear?: Maybe<Scalars["Int"]["output"]>;
  marketValue?: Maybe<Amount>;
  paidIn?: Maybe<Amount>;
  paidOut?: Maybe<Amount>;
  totalDepositLastQueryYear?: Maybe<Amount>;
  totalReturn?: Maybe<Amount>;
  totalReturnPct?: Maybe<Scalars["Float"]["output"]>;
}

export interface LifeBankAccountNumber {
  __typename?: "LifeBankAccountNumber";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  /** Field reflecting systemområde from UBW, available values are HOV (main),  SAL, UBE, PRO or empty. */
  area?: Maybe<Scalars["String"]["output"]>;
}

export interface LifePayoutTransaction {
  __typename?: "LifePayoutTransaction";
  amount?: Maybe<Scalars["Float"]["output"]>;
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  dateFrom?: Maybe<Scalars["String"]["output"]>;
  dateTo?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  payoutAccount?: Maybe<Scalars["String"]["output"]>;
  payoutArea?: Maybe<PayoutArea>;
  payoutDate?: Maybe<Scalars["String"]["output"]>;
  salaryCode?: Maybe<Scalars["String"]["output"]>;
  transactionNumber?: Maybe<Scalars["String"]["output"]>;
}

export interface LifeTaxInformation {
  __typename?: "LifeTaxInformation";
  percentage?: Maybe<Scalars["String"]["output"]>;
  tableNumber?: Maybe<Scalars["String"]["output"]>;
  taxCode?: Maybe<Scalars["String"]["output"]>;
  taxCodeText?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
}

export interface LifeVoucher {
  __typename?: "LifeVoucher";
  vouchersByArea?: Maybe<Array<VouchersByArea>>;
}

/**
 * ## Link Contracts
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface LinkContract extends Contract, LinkContractAbstract {
  __typename?: "LinkContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds. */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  /**
   *  The KID number customer can use to transfer money from their own bank account to the ProLink contract.
   *  Money transferred will be invested according setting of the product propeties to the contract's product code.
   *  The three options are 0. "Don't invest" 1. "Invest according to the current holdings profile" 2. "Invest according to the investment profile"
   *  The current choice for the products in ProLink is 2. Invest according to the investment profile
   */
  fixedKid?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  id?: Maybe<Scalars["ID"]["output"]>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /** The bank account number in ProLink used when doing one time payments using the fixed kid */
  oneTimeDepositAccountNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile */
  redistributionProfile?: Maybe<Array<RedistributionProfile>>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  /**  Savings agreement or the the periodic deposits that customer does */
  savingsAgreements?: Maybe<Array<SavingsAgreement>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  trade account number in ProLink */
  tradeAccountSell?: Maybe<Scalars["String"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

/** ## Link Contract Interface */
export interface LinkContractAbstract {
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds. */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  managers?: Maybe<Array<Manager>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile */
  redistributionProfile?: Maybe<Array<RedistributionProfile>>;
  reductionFund?: Maybe<ReductionFund>;
  roles?: Maybe<Array<LinkRole>>;
  /**  Savings agreement or the the periodic deposits that customer does */
  savingsAgreements?: Maybe<Array<SavingsAgreement>>;
  seller?: Maybe<LinkSeller>;
  /**  trade account number in ProLink */
  tradeAccountSell?: Maybe<Scalars["String"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

/**
 * ## Link Corporate Contracts
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 */
export interface LinkOrganizationContract extends Contract, LinkContractAbstract {
  __typename?: "LinkOrganizationContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds. */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /** The bank account number in ProLink used when doing one time payments using the fixed kid */
  oneTimeDepositAccountNumber?: Maybe<Scalars["String"]["output"]>;
  organization?: Maybe<Organization>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile */
  redistributionProfile?: Maybe<Array<RedistributionProfile>>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  /**  Savings agreement or the the periodic deposits that customer does */
  savingsAgreements?: Maybe<Array<SavingsAgreement>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  /**  trade account number in ProLink */
  tradeAccountSell?: Maybe<Scalars["String"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

/** The payout period registered in ProLink */
export interface LinkPayout {
  __typename?: "LinkPayout";
  endDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  fromDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  lifeLongPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface LinkRole {
  __typename?: "LinkRole";
  /**  The customer number for the name. nin if it is an customer. organization number if organization. Will only be returned for internal users */
  customerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  The name of the person or organization */
  name?: Maybe<Scalars["String"]["output"]>;
  /**  the roles participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  /**  The type of role */
  roleType?: Maybe<LinkRoleType>;
}

/**
 * INSURED = forsikrede
 * POLICY_HOLDER = forsikringstager
 */
export enum LinkRoleType {
  Insured = "INSURED",
  PolicyHolder = "POLICY_HOLDER",
}

/** Will only be returned if it is an advisor. Customers will never see this field */
export interface LinkSeller {
  __typename?: "LinkSeller";
  /**  The customer number for the name. nin if it is an advisor. Organization number if organization. Will not be return if is a nin */
  customerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  The name of the person or organization */
  name?: Maybe<Scalars["String"]["output"]>;
  /**  The sellers participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
}

export interface Manager {
  __typename?: "Manager";
  /** The expiration date for the manager type */
  expirationDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /** The first name of the manager */
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** The last name of the manager */
  lastName?: Maybe<Scalars["String"]["output"]>;
  /** The code for the manager role */
  managerRoleCode?: Maybe<Scalars["String"]["output"]>;
}

export interface MarketValue {
  __typename?: "MarketValue";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
}

export enum MartialStatusEnum {
  Married = "MARRIED",
  Unmarried = "UNMARRIED",
}

export interface NavTransaction {
  __typename?: "NavTransaction";
  amount?: Maybe<Amount>;
  date?: Maybe<Scalars["ISO8601Date"]["output"]>;
}

export interface NetAssetValue {
  __typename?: "NetAssetValue";
  currency?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
}

/**
 * # Norskpensjon
 *
 * ### Consents
 * All calls to norskpensjon must have an active consent from the customer before calling
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - corporate.internal
 */
export interface Norskpensjon {
  __typename?: "Norskpensjon";
  /** @deprecated Use afpPrognosis instead */
  afp?: Maybe<NorskpensjonContract>;
  afpPrognosis?: Maybe<Prognosis>;
  contracts?: Maybe<Array<NorskpensjonContract>>;
  folketrygdPrognosis?: Maybe<Prognosis>;
  unavailablePensionProviders?: Maybe<Array<UnavailablePensionProvider>>;
}

/**
 * # Norskpensjon Contract
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - corporate.internal
 */
export interface NorskpensjonContract {
  __typename?: "NorskpensjonContract";
  /**
   *  The category the product can have in norskpensjon. Is subject to be changed.
   *  Values can be: individuelleOrdninger, privatAFP, privatTjenestepensjon, offentligTjenestepensjon and folketrygd
   */
  category?: Maybe<Scalars["String"]["output"]>;
  /**
   * Description when grading is not allowed. Values comes direclty from norskpensjon and known values are IKKE_STOTTET and IKKE_TILLATT.
   *
   * IKKE_STOTTET: If the combinations of partial withdrawal and when is not allowd
   * IKKE_TILLATT: if the agreement's regulations doesn't allow for partial withdrawal
   */
  causeLackingGrading?: Maybe<Scalars["String"]["output"]>;
  /**
   * Description when prognosis failed for some reason. Values comes direclty from norskpensjon and known values is UKJENT_PRODUKTTYPE
   *
   * UKJENT_PRODUKTTYPE contract has a invalid combinatin of category and sub category
   */
  causeNotCalculated?: Maybe<Scalars["String"]["output"]>;
  /**  The contract number form the pension provider. May not be unique and can be null */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  The employer when available */
  employer?: Maybe<Employer>;
  /**  Value from norskpensjon. Can be empty. If start age is present then endAge is null then it means lifelong */
  endAge?: Maybe<Scalars["Int"]["output"]>;
  /**  Will not contain the funds for norskpensjon */
  holdings?: Maybe<NorskpensjonHoldings>;
  /**  A note */
  note?: Maybe<Scalars["String"]["output"]>;
  /**  The pension provider when available */
  pensionProvider?: Maybe<PensionProvider>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  The product description. Can by anything and is controlled by norskpensjon */
  productDescription?: Maybe<Scalars["String"]["output"]>;
  prognosis?: Maybe<Prognosis>;
  /**  Generated ID based on the content of the contract, might be non unique. Must be comibned with the input if to be used as cache key. */
  softId?: Maybe<Scalars["ID"]["output"]>;
  /**  Value from norskpensjon. Can be empty */
  startAge?: Maybe<Scalars["Int"]["output"]>;
  /**
   *  The sub category the product can have in norskpensjon. Can be empty
   *  Values can be: fripolise, foreningskollektiv, ipa, ips, kapitalforsikring, kollektiv livrente, individuell livrente, foretakspensjon, innskuddspensjon, fortsettelsesforsikring, fripolisemedinvesteringsvalg, pensjonsbevis, oppsatt pensjon
   */
  subCategory?: Maybe<Scalars["String"]["output"]>;
}

/**
 * Description: TODO
 * Online/ADS: online warning
 * Scopes required: Realm storebrand - storebrand.life.private
 */
export interface NorskpensjonHoldings {
  __typename?: "NorskpensjonHoldings";
  /**  Only available for EPK and EPK selvvalgt */
  activeMarketValue?: Maybe<Scalars["Float"]["output"]>;
  /**  Only available for EPK and EPK selvvalgt */
  passiveMarketValue?: Maybe<Scalars["Float"]["output"]>;
  totalMarketValue?: Maybe<Scalars["Float"]["output"]>;
}

export interface NorskpensjonInput {
  simulationParameters?: InputMaybe<NorskpensjonSimulationInput>;
}

export interface NorskpensjonSimulationInput {
  hasAfp?: InputMaybe<Scalars["Boolean"]["input"]>;
  martialStatus?: InputMaybe<MartialStatusEnum>;
  /** Will the customer parther have an income over 2G(base-amount) */
  partnerHasIncomeOver2G?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Will the customer's partner receive pension */
  partnerHasPension?: InputMaybe<Scalars["Boolean"]["input"]>;
  withdrawalPeriods?: InputMaybe<WithdrawalPeriods>;
}

export interface OfaVendor {
  __typename?: "OfaVendor";
  isStorebrand?: Maybe<Scalars["Boolean"]["output"]>;
  ofaVendorServiceTime?: Maybe<OfaVendorserviceTime>;
  tpNumber?: Maybe<Scalars["Int"]["output"]>;
  vendorName?: Maybe<Scalars["String"]["output"]>;
  yearsOfServiceTimePrognosis?: Maybe<Scalars["Int"]["output"]>;
}

export interface OfaVendorserviceTime {
  __typename?: "OfaVendorserviceTime";
  days?: Maybe<Scalars["Int"]["output"]>;
  months?: Maybe<Scalars["Int"]["output"]>;
  years?: Maybe<Scalars["Int"]["output"]>;
}

export interface OnBehalfOfCustomer {
  __typename?: "OnBehalfOfCustomer";
  cmid?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
}

export interface OnBehalfOfEngagement {
  __typename?: "OnBehalfOfEngagement";
  askAccounts?: Maybe<Array<UcitsAccountOnBehalfOfCustomer>>;
  investorAccounts?: Maybe<Array<UcitsAccountOnBehalfOfCustomer>>;
}

export interface OnBehalfOfEngagementAskAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface OnBehalfOfEngagementInvestorAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface OpenPrognosis {
  __typename?: "OpenPrognosis";
  bank: Prognosis;
  fund: Prognosis;
  ips: Prognosis;
}

export interface OpenPrognosisBankArgs {
  bankOpenPrognosisInput?: InputMaybe<BankOpenPrognosisInput>;
}

export interface OpenPrognosisFundArgs {
  fundOpenPrognosisInput?: InputMaybe<FundOpenPrognosisInput>;
}

export interface OpenPrognosisIpsArgs {
  ipsOpenPrognosisInput?: InputMaybe<IpsOpenPrognosisInput>;
}

export interface Organization {
  __typename?: "Organization";
  name?: Maybe<Scalars["String"]["output"]>;
  organizationNumber?: Maybe<Scalars["String"]["output"]>;
}

export interface OrganizationEngagement {
  __typename?: "OrganizationEngagement";
  /** avlosningspensjonContracts are only relevant for organizations */
  avlosningspensjonContracts?: Maybe<Array<LinkOrganizationContract>>;
  bankAccounts?: Maybe<Array<BankAccount>>;
  ekstrapensjonContracts?: Maybe<Array<LinkOrganizationContract>>;
  fondskontoLinkContracts?: Maybe<Array<LinkOrganizationContract>>;
  garantiContracts?: Maybe<Array<LinkOrganizationContract>>;
  investorAccounts?: Maybe<Array<UcitsOrganizationAccount>>;
}

export interface OrganizationEngagementAvlosningspensjonContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface OrganizationEngagementBankAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  productCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface OrganizationEngagementEkstrapensjonContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface OrganizationEngagementFondskontoLinkContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface OrganizationEngagementGarantiContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface OrganizationEngagementInvestorAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface PartialWithdrawal {
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  /**  salary in this period until fullwithdrawal date */
  salaryInPartialWithdrawalPeriod?: InputMaybe<Scalars["Int"]["input"]>;
  withdrawalPercentage?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PartialWithdrawalInput {
  partTimePercentage?: InputMaybe<Scalars["Float"]["input"]>;
  startAge?: InputMaybe<Scalars["Int"]["input"]>;
  startMonthOffset?: InputMaybe<Scalars["Int"]["input"]>;
  withdrawalPercentage?: InputMaybe<Scalars["Float"]["input"]>;
}

export interface Payer {
  __typename?: "Payer";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface Payout {
  __typename?: "Payout";
  deductionGroup?: Maybe<Scalars["String"]["output"]>;
  deductionSetup?: Maybe<Scalars["String"]["output"]>;
  paidAmount?: Maybe<Scalars["Float"]["output"]>;
  paidDate?: Maybe<Scalars["String"]["output"]>;
  paidMonth?: Maybe<Scalars["Int"]["output"]>;
  paidYear?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  textStripped?: Maybe<Scalars["String"]["output"]>;
}

export enum PayoutArea {
  Collective = "COLLECTIVE",
  Private = "PRIVATE",
  Prolink = "PROLINK",
}

export interface PayoutMethod {
  __typename?: "PayoutMethod";
  abroadTransmission?: Maybe<Scalars["Boolean"]["output"]>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  sendByMail?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface PayoutMonth {
  __typename?: "PayoutMonth";
  paidMonth?: Maybe<Scalars["Int"]["output"]>;
  paidYear?: Maybe<Scalars["Int"]["output"]>;
  payouts?: Maybe<Array<Payout>>;
  totalOtherDeductions?: Maybe<Scalars["Float"]["output"]>;
  totalPaidGrossAmount?: Maybe<Scalars["Float"]["output"]>;
  totalPaidNetAmount?: Maybe<Scalars["Float"]["output"]>;
  totalTax?: Maybe<Scalars["Float"]["output"]>;
}

export interface PayoutPeriod {
  __typename?: "PayoutPeriod";
  compressed?: Maybe<Scalars["Boolean"]["output"]>;
  endAge?: Maybe<Scalars["Int"]["output"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  endMonthOffset?: Maybe<Scalars["Int"]["output"]>;
  lifeLong?: Maybe<Scalars["Boolean"]["output"]>;
  monthlyAmount?: Maybe<Scalars["Float"]["output"]>;
  notices?: Maybe<Array<Maybe<TextElement>>>;
  oneTimePayout?: Maybe<Scalars["Boolean"]["output"]>;
  startAge?: Maybe<Scalars["Int"]["output"]>;
  startDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  startMonthOffset?: Maybe<Scalars["Int"]["output"]>;
  yearlyAmount?: Maybe<Scalars["Int"]["output"]>;
}

export interface PayoutPlan {
  __typename?: "PayoutPlan";
  age?: Maybe<Scalars["Int"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /** Number of months after the customers birthday the payout will end */
  endMonthOffset?: Maybe<Scalars["Int"]["output"]>;
  maxAmount?: Maybe<Scalars["Float"]["output"]>;
  minAmount?: Maybe<Scalars["Float"]["output"]>;
  startDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /** Number of months after the customers birthday the payout starts */
  startMonthOffset?: Maybe<Scalars["Int"]["output"]>;
}

export interface PayoutYear {
  __typename?: "PayoutYear";
  paidYear?: Maybe<Scalars["Int"]["output"]>;
  payoutMonths?: Maybe<Array<PayoutMonth>>;
}

export interface PensionFundHolding {
  __typename?: "PensionFundHolding";
  actualDistribution?: Maybe<Scalars["Float"]["output"]>;
  isin?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Amount>;
  name?: Maybe<Scalars["String"]["output"]>;
  nav?: Maybe<NavTransaction>;
  numberOfShares?: Maybe<Scalars["Float"]["output"]>;
}

export interface PensionFundHoldings {
  __typename?: "PensionFundHoldings";
  funds?: Maybe<Array<PensionFundHolding>>;
  sumDeposit?: Maybe<Amount>;
  totalMarketValue?: Maybe<Amount>;
  totalReturn?: Maybe<Amount>;
  yearlyDeposit?: Maybe<Amount>;
}

/**
 * # PensionFundItpContract
 * "Innskuddsordninger" from Storebrand pensjonstjenester AS/Abakus
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.pensionfunds.private
 * * Realm corporate   - corporate.internal
 */
export interface PensionFundItpContract extends Contract {
  __typename?: "PensionFundItpContract";
  contractNavigation?: Maybe<ContractNavigation>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The employer or the pension fund */
  employer?: Maybe<Employer>;
  /** if the contract is linked to an epk-contract, it will be available here. */
  epkContract?: Maybe<EpkContract>;
  /**  the holdigns for the contract */
  holdings?: Maybe<PensionFundHoldings>;
  /**  The id for the contract */
  id?: Maybe<Scalars["ID"]["output"]>;
  /**  If the scheme is a pension capital certificate/pensjonskapitalbevis/pkb */
  pensionCapitalCertificate?: Maybe<Scalars["Boolean"]["output"]>;
  pensionFundCompanyName?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  The default retirementAge for the contract */
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface PensionFundPayoutOrganization {
  __typename?: "PensionFundPayoutOrganization";
  organizationName?: Maybe<Scalars["String"]["output"]>;
  organizationNumber?: Maybe<Scalars["String"]["output"]>;
  payoutYears?: Maybe<Array<PayoutYear>>;
}

/**
 * # PensionFundYtpUnderPayoutContract - ytelsepensjon under utbetaling
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface PensionFundUnfundedYtpUnderPayoutContract extends Contract {
  __typename?: "PensionFundUnfundedYtpUnderPayoutContract";
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  contractType?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  employedDate?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  employmentPercentage?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  salary?: Maybe<Scalars["Float"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

/**
 * # PensionFundYtpContract
 * "Ytelsesordninger" from Storebrand pensjonstjenester AS/Abakus
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.pensionfunds.private
 * * Realm corporate   - corporate.internal
 */
export interface PensionFundYtpContract extends Contract {
  __typename?: "PensionFundYtpContract";
  contractNavigation?: Maybe<ContractNavigation>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The employer or the pension fund */
  employer?: Maybe<Employer>;
  /**  The id for the contract */
  id?: Maybe<Scalars["ID"]["output"]>;
  /**  If the scheme is paid up policy/fripolise */
  paidUpPolicy?: Maybe<Scalars["Boolean"]["output"]>;
  pensionFundCompanyName?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  The default retirementAge for the contract */
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface PensionFundsVoucher {
  __typename?: "PensionFundsVoucher";
  organizations?: Maybe<Array<PensionFundPayoutOrganization>>;
}

/**
 * Online
 * Fetches payout information for life and pensionfunds
 */
export interface PensionPayouts {
  __typename?: "PensionPayouts";
  lifeBankAccountNumbers?: Maybe<Array<LifeBankAccountNumber>>;
  lifePayouts?: Maybe<LifeVoucher>;
  lifeTaxInformation?: Maybe<Array<LifeTaxInformation>>;
  pensionFundPayouts?: Maybe<PensionFundsVoucher>;
}

export interface PensionProvider {
  __typename?: "PensionProvider";
  name?: Maybe<Scalars["String"]["output"]>;
}

/**
 * Description:
 * Online/ADS: ADS
 * Scopes required: Realm storebrand - storebrand.life.private
 */
export interface PeriodicDepositDetail {
  __typename?: "PeriodicDepositDetail";
  distributionPercentage?: Maybe<Scalars["Float"]["output"]>;
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  periodicAmount?: Maybe<Scalars["Float"]["output"]>;
  yearlyAmount?: Maybe<Scalars["Float"]["output"]>;
}

/**
 * ## Pensjonskapitalbevis
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface PkbContract extends Contract {
  __typename?: "PkbContract";
  /**  Account id in ProLink */
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountOperator?: Maybe<AccountOperator>;
  administrationFeeCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *  AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
   *  LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
   *  STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
   */
  allocationCode?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in ProLink */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  Date for first deposit in prolink */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The name that customer has given */
  customerSuppliedContractName?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  fromUsbContract?: Maybe<FromUsbContract>;
  /** @deprecated This field is depracated, use portfolioInsight.accounts.marketValues[] */
  historicMarketValues?: Maybe<Array<HistoricMarketValue>>;
  holdings?: Maybe<Holding>;
  /**  ID is on the format {participantId}-{accountId}:PL_A.  */
  id?: Maybe<Scalars["ID"]["output"]>;
  investmentProfile?: Maybe<Array<InvestmentProfile>>;
  keyValues?: Maybe<KeyValues>;
  managers?: Maybe<Array<Manager>>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
  /**  Participant id in ProLink */
  participantId?: Maybe<Scalars["String"]["output"]>;
  payout?: Maybe<LinkPayout>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  pkrPkbId?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in ProLink */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile */
  redistributionProfile?: Maybe<Array<RedistributionProfile>>;
  reductionFund?: Maybe<ReductionFund>;
  /** @deprecated This field is deprecated, use KeyValues instead */
  returnOnInvestment?: Maybe<ReturnOnInvestment>;
  roles?: Maybe<Array<LinkRole>>;
  selectableFunds?: Maybe<Array<SelectableFund>>;
  /**  The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon */
  selectableProfiles?: Maybe<Array<SelectableProfile>>;
  seller?: Maybe<LinkSeller>;
  specialRetirementAge?: Maybe<Scalars["Boolean"]["output"]>;
  /**  true if the contract status indicates there exists an active payout */
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  /**  Contract number in USB */
  usbContractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  usbContractType?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  usbPersonCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  usbProductCode?: Maybe<Scalars["String"]["output"]>;
}

export interface PortfolioInsight {
  __typename?: "PortfolioInsight";
  accounts?: Maybe<Array<Account>>;
  isExcludedFromProfitCalculation?: Maybe<Scalars["Boolean"]["output"]>;
  /**  Total keyValues for all selected accounts */
  keyValues?: Maybe<KeyValues>;
  marketValues?: Maybe<Array<PortfolioInsightMarketValue>>;
}

export interface PortfolioInsightFund {
  __typename?: "PortfolioInsightFund";
  actualDistribution?: Maybe<Scalars["Float"]["output"]>;
  fundHistory?: Maybe<Array<Maybe<PortfolioInsightFundHistory>>>;
  fundSheet?: Maybe<Scalars["String"]["output"]>;
  gav?: Maybe<Scalars["Float"]["output"]>;
  isin?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Amount>;
  marketValueGuaranteePortfolioWithProfitToday?: Maybe<Amount>;
  name?: Maybe<Scalars["String"]["output"]>;
  nav?: Maybe<NavTransaction>;
  netPayment?: Maybe<Amount>;
  numberOfShares?: Maybe<Scalars["Float"]["output"]>;
  paidIn?: Maybe<Amount>;
  paidOut?: Maybe<Amount>;
  totalReturn?: Maybe<Amount>;
  totalReturnPct?: Maybe<Scalars["Float"]["output"]>;
}

export interface PortfolioInsightFundHistory {
  __typename?: "PortfolioInsightFundHistory";
  dailyReturn?: Maybe<Amount>;
  dailyReturnPct?: Maybe<Scalars["Float"]["output"]>;
  date?: Maybe<Scalars["ISO8601Date"]["output"]>;
  gav?: Maybe<Scalars["Float"]["output"]>;
  marketValue?: Maybe<Amount>;
  nav?: Maybe<NavTransaction>;
  netPayment?: Maybe<Amount>;
  numberOfShares?: Maybe<Scalars["Float"]["output"]>;
  paidIn?: Maybe<Amount>;
  paidOut?: Maybe<Amount>;
  totalReturn?: Maybe<Amount>;
  totalReturnPct?: Maybe<Scalars["Float"]["output"]>;
}

export interface PortfolioInsightMarketValue {
  __typename?: "PortfolioInsightMarketValue";
  accumulatedPaidIn?: Maybe<Amount>;
  accumulatedPaidOut?: Maybe<Amount>;
  date?: Maybe<Scalars["ISO8601Date"]["output"]>;
  mainCapital?: Maybe<Amount>;
  marketValue?: Maybe<Amount>;
}

export interface PortfolioInsightTransaction {
  __typename?: "PortfolioInsightTransaction";
  amountInTradeCurrency?: Maybe<Amount>;
  description?: Maybe<Description>;
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nav?: Maybe<NavTransaction>;
  numberOfShares?: Maybe<Scalars["Float"]["output"]>;
  payer?: Maybe<Payer>;
  tradeDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
}

export enum PortfolioQueryField {
  AssetIds = "ASSET_IDS",
  FundDetails = "FUND_DETAILS",
  Holdings = "HOLDINGS",
  KeyValues = "KEY_VALUES",
  KeyValuesTotal = "KEY_VALUES_TOTAL",
  MarketValues = "MARKET_VALUES",
  MarketValuesTotal = "MARKET_VALUES_TOTAL",
  Transactions = "TRANSACTIONS",
}

export interface ProductLevel {
  __typename?: "ProductLevel";
  productLevelCode?: Maybe<Scalars["String"]["output"]>;
  productLevelName?: Maybe<Scalars["String"]["output"]>;
}

export interface ProductName {
  __typename?: "ProductName";
  english?: Maybe<Scalars["String"]["output"]>;
  norwegian?: Maybe<Scalars["String"]["output"]>;
}

export interface Prognosis {
  __typename?: "Prognosis";
  accumulatedBalance?: Maybe<Array<AccumulatedBalance>>;
  additionalDataPrognosis?: Maybe<AdditionalDataPrognosis>;
  compressed?: Maybe<Scalars["Boolean"]["output"]>;
  contractId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lifeLong?: Maybe<Scalars["Boolean"]["output"]>;
  oneTimePayout?: Maybe<Scalars["Boolean"]["output"]>;
  payoutPeriods?: Maybe<Array<Maybe<PayoutPeriod>>>;
  payoutPlan?: Maybe<Array<PayoutPlan>>;
  prognosisMetaInformation?: Maybe<PrognosisMetaInformation>;
  simulationStatus?: Maybe<SimulationStatus>;
  strapp?: Maybe<Scalars["String"]["output"]>;
  withdrawalAllowed?: Maybe<Scalars["Boolean"]["output"]>;
  xCorrelationId?: Maybe<Scalars["String"]["output"]>;
}

export interface PrognosisInput {
  id: Scalars["String"]["input"];
  parameters: PrognosisParametersInput;
}

export interface PrognosisMetaInformation {
  __typename?: "PrognosisMetaInformation";
  compressionLimit?: Maybe<Scalars["Float"]["output"]>;
  /**
   * will be returned true if withdrawal can be created automaticalle. Has currently only been tested 100% for EPK.
   * Use with caution
   */
  defaultPayoutDuration?: Maybe<Scalars["Boolean"]["output"]>;
  payoutEndDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  payoutStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  savingsEndDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  savingsStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
}

export interface PrognosisParametersInput {
  /**  Compression limit can be set for FMI, YTP and Fripolise contracts */
  compressionLimit?: InputMaybe<Scalars["Float"]["input"]>;
  fullWithdrawal: FullWithdrawalInput;
  investmentProfileInPayoutPeriod?: InputMaybe<InvestmentProfileInPayoutPeriod>;
  partialWithdrawal?: InputMaybe<PartialWithdrawalInput>;
}

export interface PublicPension {
  __typename?: "PublicPension";
  /** @deprecated Use 'publicPensionContract' for contracts */
  contracts?: Maybe<Array<Maybe<PublicPensionContract>>>;
  /** @deprecated Use 'publicPensionPrognosis' for prognosis */
  publicPensionPrognosis?: Maybe<Prognosis>;
}

export interface PublicPensionContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface PublicPensionActions {
  __typename?: "PublicPensionActions";
  simulatePublicPension?: Maybe<Scalars["Boolean"]["output"]>;
}

/**
 * # PublicPension
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - corporate.internal
 */
export interface PublicPensionContract extends Contract {
  __typename?: "PublicPensionContract";
  /** @deprecated Use 'hasPublicPensionEngagements' found int PublicPensionPrognosisParameters */
  actions?: Maybe<PublicPensionActions>;
  applicableVacancyRate?: Maybe<Scalars["Float"]["output"]>;
  averagePartTimePercentage?: Maybe<Scalars["Float"]["output"]>;
  calculatedNationalInsuranceBasicAmount?: Maybe<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  /**  The employement date */
  employedDate?: Maybe<Scalars["String"]["output"]>;
  /**
   *  Number of months the customer has been working for the employer.
   *  Might be less than the duration of employedDate and resignationDate due to gap in the working history (leave, sickness, etc)
   */
  employedTimeInMonths?: Maybe<Scalars["Int"]["output"]>;
  /**  The employer */
  employer?: Maybe<Employer>;
  /**  The first retirement age when the customer can retire. Default is 67, but some customers may have 58, 60 when special retirement ages */
  firstRetirementAge?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  pensionQualifyingIncome?: Maybe<Scalars["Float"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Position Age - Stillingsalder */
  positionAge?: Maybe<Scalars["Int"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product name in USB */
  productName?: Maybe<ProductName>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  /**  When the employee retired from the employer */
  resignationDate?: Maybe<Scalars["String"]["output"]>;
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  /**  Work and Salary Details */
  salaryHistory?: Maybe<Array<SalaryHistory>>;
  status?: Maybe<PublicPensionContractStatus>;
}

export enum PublicPensionContractStatus {
  Active = "ACTIVE",
  EarnedPensionRight = "EARNED_PENSION_RIGHT",
  Payout = "PAYOUT",
}

/**
 * How many years the employee have worked in public sector for agreements outside Storebrand/other pension providers.
 * The prognosis handles everything related to service time on contracts in Storebrand
 */
export interface PublicPensionInput {
  serviceTimeBefore2011FromOtherProviders?: InputMaybe<Scalars["Int"]["input"]>;
  serviceTimeFromOtherProviders?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PublicPensionPrognosis {
  __typename?: "PublicPensionPrognosis";
  currentWithdrawalDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  paymentPlanAfp62?: Maybe<Array<PayoutPlan>>;
  paymentPlanAfp65?: Maybe<Array<PayoutPlan>>;
  paymentPlanFolketrygd?: Maybe<Array<PayoutPlan>>;
  paymentPlanPension?: Maybe<Array<PayoutPlan>>;
  paymentPlanSalary?: Maybe<Array<PayoutPlan>>;
  /** List of statuses regarding the customer public pension, and whether prognosis can be performed */
  statusMessages?: Maybe<Array<PublicPensionStatusMessage>>;
}

export interface PublicPensionPrognosisInput {
  firstWithdrawalAge?: InputMaybe<Scalars["Int"]["input"]>;
  firstWithdrawalMonthOffset?: InputMaybe<Scalars["Int"]["input"]>;
  futureSalary?: InputMaybe<Scalars["Int"]["input"]>;
  lastWithdrawalAge?: InputMaybe<Scalars["Int"]["input"]>;
  lastWithdrawalMonthOffset?: InputMaybe<Scalars["Int"]["input"]>;
  withdrawalPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  workPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  yearsAddedBefore2011?: InputMaybe<Scalars["Int"]["input"]>;
  yearsAddedBefore2020?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PublicPensionPrognosisParameters {
  __typename?: "PublicPensionPrognosisParameters";
  /** The customers added salary */
  addedSalary?: Maybe<Scalars["Int"]["output"]>;
  /** True if the customer should be asked to input service time from oter providers */
  canChangeFutureSalary?: Maybe<Scalars["Boolean"]["output"]>;
  /** True if the customer should be allowed to do flexible withdrawal */
  canChangeWithdrawalPercentage?: Maybe<Scalars["Boolean"]["output"]>;
  /** True if the customer should be allowed to change their working percentage for the prognosis */
  canChangeWorkingPercentage?: Maybe<Scalars["Boolean"]["output"]>;
  /** The current working percentage for the customer */
  currentWorkingPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** If no firstWithdrawalDate is provided when doing public pension prognosis, this is the one that will be used */
  defaultWithdrawalDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /** True of the customer has an earned pension right */
  earnedPensionRight?: Maybe<Scalars["Boolean"]["output"]>;
  /** False of the customer does not have any public pension engagements. If false -> do not ask for public pension prognosis */
  hasPublicPensionEngagements?: Maybe<Scalars["Boolean"]["output"]>;
  /** The customers main salary */
  mainSalary?: Maybe<Scalars["Int"]["output"]>;
  /** The latest age for which the customer can perform the prognosis */
  maximumAgeWithdrawal?: Maybe<Scalars["Int"]["output"]>;
  /** The earliest age for which the customer can perform the prognosis */
  minimumAgeWithdrawal?: Maybe<Scalars["Int"]["output"]>;
  /** List of vendors that we were able to fetch service time from */
  ofaVendors?: Maybe<Array<OfaVendor>>;
  /** The registered retirement age for the customer */
  retireAge?: Maybe<Scalars["Int"]["output"]>;
  /** True if the customer should be asked to input service time from oter providers */
  showAddedYears?: Maybe<Scalars["Boolean"]["output"]>;
  /** List of statuses regarding the customer public pension, and whether prognosis can be performed */
  statusMessages?: Maybe<Array<PublicPensionStatusMessage>>;
  /** years of service time from ofa that will be used for prognosis */
  yearsOfServiceTimeOfa?: Maybe<Scalars["Int"]["output"]>;
  /** years of service time before 2011 from ofa that will be used for prognosis */
  yearsOfServiceTimeOfaBefore2011?: Maybe<Scalars["Int"]["output"]>;
  /** years of service time before 2020 from ofa that will be used for prognosis */
  yearsOfServiceTimeOfaBefore2020?: Maybe<Scalars["Int"]["output"]>;
}

export interface PublicPensionPrognosisParametersInput {
  firstWithdrawalAge?: InputMaybe<Scalars["Int"]["input"]>;
  firstWithdrawalMonthOffset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PublicPensionStatusMessage {
  __typename?: "PublicPensionStatusMessage";
  /** Uniquely identifying. Should be used for text-lookup in FMS */
  messageKey?: Maybe<Scalars["String"]["output"]>;
  /** Norwegian text describing the status message. Primarily for developers and debugging. Should not be exposed to the customer */
  messageText?: Maybe<Scalars["String"]["output"]>;
  /**
   *  A - warning: Can display perform and show prognosis. Should show message
   *  S - stop: Unable to perform prognosis. Should show message
   *  F - failed: Internal Error for savings-rest-api
   */
  messageType?: Maybe<Scalars["String"]["output"]>;
}

/** Root query element */
export interface Query {
  __typename?: "Query";
  baseAmount?: Maybe<BaseAmount>;
  norskpensjon?: Maybe<Norskpensjon>;
  onBehalfOfEngagement: OnBehalfOfEngagement;
  openPrognosis: OpenPrognosis;
  organizationEngagement: OrganizationEngagement;
  pensionPayouts?: Maybe<PensionPayouts>;
  /**  ids: Storebrand uniqueID */
  portfolioInsight: PortfolioInsight;
  prognosis?: Maybe<Prognosis>;
  publicPensionPrognosis?: Maybe<PublicPensionPrognosis>;
  publicPensionPrognosisParameters?: Maybe<PublicPensionPrognosisParameters>;
  savingsContracts?: Maybe<Array<Maybe<Contract>>>;
  savingsEngagement: SavingsEngagement;
}

/** Root query element */
export interface QueryNorskpensjonArgs {
  input?: InputMaybe<NorskpensjonInput>;
}

/** Root query element */
export interface QueryPortfolioInsightArgs {
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
}

/** Root query element */
export interface QueryPrognosisArgs {
  input?: InputMaybe<PrognosisInput>;
}

/** Root query element */
export interface QueryPublicPensionPrognosisArgs {
  input?: InputMaybe<PublicPensionPrognosisInput>;
}

/** Root query element */
export interface QueryPublicPensionPrognosisParametersArgs {
  input?: InputMaybe<PublicPensionPrognosisParametersInput>;
}

/** Root query element */
export interface QuerySavingsContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

/** Root query element */
export interface QuerySavingsEngagementArgs {
  input?: InputMaybe<QueryInput>;
}

export interface QueryInput {}

export interface Rate {
  __typename?: "Rate";
  bottomRateG?: Maybe<Scalars["Float"]["output"]>;
  bottomSalary?: Maybe<Scalars["Float"]["output"]>;
  savingsRatePercent?: Maybe<Scalars["Float"]["output"]>;
  topRateG?: Maybe<Scalars["Float"]["output"]>;
  topSalary?: Maybe<Scalars["Float"]["output"]>;
}

/** The fund */
export interface RedistributionProfile {
  __typename?: "RedistributionProfile";
  distributionPercentage?: Maybe<Scalars["Float"]["output"]>;
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

/** The target reduction fund if the customer has chosen to be allocated in STDNED1, STDNED3 */
export interface ReductionFund {
  __typename?: "ReductionFund";
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface RenewalDetail {
  __typename?: "RenewalDetail";
  renewalDate?: Maybe<Scalars["String"]["output"]>;
  renewalTxt?: Maybe<Scalars["String"]["output"]>;
}

/**
 * Valid retirement date for birthday at a given age. Will be set to the first in each month ignoring the day of the month.
 * After 62 the first avaible date will be the next month and the first day of the month.
 * Range is usually [62-75!]
 */
export interface RetirementAge {
  __typename?: "RetirementAge";
  age?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  /**
   * How many months after the customers birthday, at the given age retirement should start. 0 indicates the same month as the customers birthday.
   * Example: A customer with birthday 1990-05-16 gives an offset of 1. June will then be the selected month for retirement. If the same customer gives an offset og 2, July will be used.
   */
  monthOffset?: Maybe<Scalars["Int"]["output"]>;
}

/**
 * Description: TODO
 * Online/ADS: online warning
 * Scopes required: Realm storebrand - storebrand.life.private
 */
export interface ReturnOnInvestment {
  __typename?: "ReturnOnInvestment";
  paidIn?: Maybe<Scalars["Float"]["output"]>;
  paidOut?: Maybe<Scalars["Float"]["output"]>;
  profit?: Maybe<Scalars["Float"]["output"]>;
  profitPercent?: Maybe<Scalars["Float"]["output"]>;
  totalMarketValue?: Maybe<Scalars["Float"]["output"]>;
}

export interface Rights {
  __typename?: "Rights";
  createdDateTime?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  validToDate?: Maybe<Scalars["String"]["output"]>;
}

export interface SalaryHistory {
  __typename?: "SalaryHistory";
  /**  This is overtime, bonus paid extra - Tilleggslønn */
  additionalSalary?: Maybe<Scalars["Int"]["output"]>;
  /**  This is base salary for given period - Grunnlønn */
  baseSalary?: Maybe<Scalars["Int"]["output"]>;
  /** iso-date-string 2021-10-12 */
  endDate?: Maybe<Scalars["String"]["output"]>;
  /** iso-date-string 2021-10-12 */
  fromDate?: Maybe<Scalars["String"]["output"]>;
  partTimePercent?: Maybe<Scalars["Float"]["output"]>;
  /**  This is total salary (base salary + additional salary) */
  totalSalary?: Maybe<Scalars["Int"]["output"]>;
}

/**
 * Description:
 * Online/ADS: ADS
 * Scopes required: Realm storebrand - storebrand.life.private
 */
export interface SavingsAgreement {
  __typename?: "SavingsAgreement";
  /**  Which day in the month */
  dayInMonth?: Maybe<Scalars["Int"]["output"]>;
  /**  How much the periodic amount on the savings agreement will increase on the nextIndexingDate */
  indexingRatePercent?: Maybe<Scalars["Float"]["output"]>;
  /**  The savings agreement's kid number. Will match with the customers avtale giro in their bank account number */
  kid?: Maybe<Scalars["String"]["output"]>;
  /**  The date when the indexingRatePercent will increase */
  nextIndexingDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /**  1 = one saving each year, 2 = half year . 4 = quartly, 6 = every other month,  12 = monthly */
  numberOfPeriodsAYear?: Maybe<Scalars["Int"]["output"]>;
  /**  The saving agreement's order number */
  orderNumber?: Maybe<Scalars["String"]["output"]>;
  /**  The amount paid in the at interval of the number of periods a year */
  periodicAmount?: Maybe<Scalars["Float"]["output"]>;
  /**  How the periodic amount is distributed in each of the funds */
  periodicDepositDetails?: Maybe<Array<PeriodicDepositDetail>>;
  /**  The status of the savings agreement. O = active, V = Awaiting confirmation&not signed at NETS */
  status?: Maybe<Scalars["String"]["output"]>;
  /**  When the savings agreement is valid from */
  validFromDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /**  # When the savings agreement is valid to */
  validToDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  /**  the yearly amount saved */
  yearlyAmount?: Maybe<Scalars["Float"]["output"]>;
}

export interface SavingsEngagement {
  __typename?: "SavingsEngagement";
  askAccounts?: Maybe<Array<UcitsAccount>>;
  bankAccounts?: Maybe<Array<BankAccount>>;
  ekstrapensjonContracts?: Maybe<Array<LinkContract>>;
  ekstrapensjonEmploymentContracts?: Maybe<Array<EkstrapensjonEmploymentContract>>;
  engagementFlag?: Maybe<EngagementFlag>;
  epkContracts?: Maybe<Array<EpkContract>>;
  epkEmploymentContracts?: Maybe<Array<EpkEmploymentContract>>;
  epkFleksibelContracts?: Maybe<Array<EpkFleksibelContract>>;
  epkFleksibelPkbContracts?: Maybe<Array<EpkFleksibelPkbContract>>;
  fmiContracts?: Maybe<Array<FmiContract>>;
  fmiMedGarantiContracts?: Maybe<Array<FripoliseContract>>;
  fondskontoLinkContracts?: Maybe<Array<LinkContract>>;
  fripoliseContracts?: Maybe<Array<FripoliseContract>>;
  garantiContracts?: Maybe<Array<LinkContract>>;
  hybridMedGarantiContracts?: Maybe<Array<HybridMedGarantiContract>>;
  hybridMedInvesteringsvalgContracts?: Maybe<Array<HybridMedInvesteringsvalgContract>>;
  hybridPensjonsbevisContracts?: Maybe<Array<HybridPensjonsbevisContract>>;
  innskuddspensjonMedGarantiContracts?: Maybe<Array<FripoliseContract>>;
  investorAccounts?: Maybe<Array<UcitsAccount>>;
  ipaAlisContracts?: Maybe<Array<AlisContract>>;
  ipaLinkContracts?: Maybe<Array<LinkContract>>;
  ipoContracts?: Maybe<Array<LinkContract>>;
  ipsContracts?: Maybe<Array<LinkContract>>;
  kapitalforsikringAlisContracts?: Maybe<Array<AlisContract>>;
  kollektivLivrenteContracts?: Maybe<Array<LinkContract>>;
  livrenteAlisContracts?: Maybe<Array<AlisContract>>;
  livrenteLinkContracts?: Maybe<Array<LinkContract>>;
  pensionFundUnfundedItpContracts?: Maybe<Array<PensionFundItpContract>>;
  pensionFundUnfundedYtpContracts?: Maybe<Array<PensionFundYtpContract>>;
  pensionFundUnfundedYtpUnderPayoutContracts?: Maybe<Array<PensionFundUnfundedYtpUnderPayoutContract>>;
  pensionFundYtpContracts?: Maybe<Array<PensionFundYtpContract>>;
  /** @deprecated Use 'query.pensionPayouts' */
  pensionPayouts?: Maybe<PensionPayouts>;
  pkbContracts?: Maybe<Array<PkbContract>>;
  /** @deprecated Use 'savingsEngagement.publicPensionContracts' for contracts. Use 'publicPensionPrognosis' for prognosis */
  publicPension?: Maybe<PublicPension>;
  publicPensionContracts?: Maybe<Array<PublicPensionContract>>;
  simulationParameters?: Maybe<SimulationParameters>;
  ytpContracts?: Maybe<Array<YtpContract>>;
}

export interface SavingsEngagementAskAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementBankAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  productCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}

export interface SavingsEngagementEkstrapensjonContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementEkstrapensjonEmploymentContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementEpkContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementEpkEmploymentContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementEpkFleksibelContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementEpkFleksibelPkbContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementFmiContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementFmiMedGarantiContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementFondskontoLinkContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementFripoliseContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementGarantiContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementHybridMedGarantiContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementHybridMedInvesteringsvalgContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementHybridPensjonsbevisContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementInnskuddspensjonMedGarantiContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementInvestorAccountsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementIpaAlisContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementIpaLinkContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementIpoContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementIpsContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementKapitalforsikringAlisContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementKollektivLivrenteContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementLivrenteAlisContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementLivrenteLinkContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementPensionFundUnfundedItpContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementPensionFundUnfundedYtpContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementPensionFundUnfundedYtpUnderPayoutContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementPensionFundYtpContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementPkbContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementPublicPensionContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsEngagementYtpContractsArgs {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface SavingsRate {
  __typename?: "SavingsRate";
  additional?: Maybe<Rate>;
  basic?: Maybe<Rate>;
}

/** A fund  that the customer can select from according to the account */
export interface SelectableFund {
  __typename?: "SelectableFund";
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

/** A fund/profile that the customer can select from */
export interface SelectableProfile {
  __typename?: "SelectableProfile";
  isin?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /**  The risk category L = Forsiktig, EF = Ekstra forsiktig, M = Balansert, H = Offensiv, EH = Ekstra Offensiv */
  riskCategory?: Maybe<Scalars["String"]["output"]>;
}

export interface SimulationParameters {
  __typename?: "SimulationParameters";
  customerBirthDate?: Maybe<Scalars["String"]["output"]>;
  retirementAges?: Maybe<Array<RetirementAge>>;
}

export interface SimulationParametersInput {
  martialStatus?: InputMaybe<MartialStatusEnum>;
  /** Will the customer parther have an income over 2G(base-amount) */
  partnerHasIncomeOver2G?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Will the customer's partner receive pension */
  partnerHasPension?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicPension?: InputMaybe<PublicPensionInput>;
  withdrawalPeriods?: InputMaybe<WithdrawalPeriods>;
}

export interface SimulationResult {
  __typename?: "SimulationResult";
  results?: Maybe<Array<Prognosis>>;
}

export enum SimulationSeverity {
  BusinessError = "BUSINESS_ERROR",
  Notice = "NOTICE",
  NotAllowed = "NOT_ALLOWED",
  Ok = "OK",
  SimulationError = "SIMULATION_ERROR",
}

export interface SimulationStatus {
  __typename?: "SimulationStatus";
  messageKey?: Maybe<Scalars["String"]["output"]>;
  severity?: Maybe<SimulationSeverity>;
  simulationMessage?: Maybe<TextElement>;
}

export enum SystemArea {
  PlA = "PL_A",
  SamA = "SAM_A",
}

export interface TextElement {
  __typename?: "TextElement";
  english?: Maybe<Scalars["String"]["output"]>;
  fmsKey?: Maybe<Scalars["String"]["output"]>;
  norwegian?: Maybe<Scalars["String"]["output"]>;
}

/**
 * # UCITS Account
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.funds.private
 * * Realm corporate   - TODO
 */
export interface UcitsAccount extends Contract, UcitsAccountAbstract {
  __typename?: "UCITSAccount";
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountName?: Maybe<Scalars["String"]["output"]>;
  accountOperator?: Maybe<Scalars["String"]["output"]>;
  accountType?: Maybe<Scalars["String"]["output"]>;
  askTaxDetails?: Maybe<AskTaxDetails>;
  closedDate?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  holdings?: Maybe<UcitsHoldings>;
  /**  ID is on the format {accountId}:SAM_A. */
  id?: Maybe<Scalars["ID"]["output"]>;
  legalEntity?: Maybe<Scalars["String"]["output"]>;
  pensionType?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  rights?: Maybe<Array<Rights>>;
  savingAgreements?: Maybe<Array<UcitsSavingAgreement>>;
  seller?: Maybe<Scalars["String"]["output"]>;
  withdrawalAgreements?: Maybe<Array<WithdrawalAgreement>>;
}

/**
 * # UCITS Abstract Account
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.funds.private
 * * Realm corporate   - TODO
 */
export interface UcitsAccountAbstract {
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountName?: Maybe<Scalars["String"]["output"]>;
  accountOperator?: Maybe<Scalars["String"]["output"]>;
  accountType?: Maybe<Scalars["String"]["output"]>;
  askTaxDetails?: Maybe<AskTaxDetails>;
  closedDate?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  holdings?: Maybe<UcitsHoldings>;
  /**  ID is on the format {accountId}:SAM_A. */
  id?: Maybe<Scalars["ID"]["output"]>;
  legalEntity?: Maybe<Scalars["String"]["output"]>;
  pensionType?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  rights?: Maybe<Array<Rights>>;
  savingAgreements?: Maybe<Array<UcitsSavingAgreement>>;
  seller?: Maybe<Scalars["String"]["output"]>;
  withdrawalAgreements?: Maybe<Array<WithdrawalAgreement>>;
}

export interface UcitsAccountOnBehalfOfCustomer extends Contract, UcitsAccountAbstract {
  __typename?: "UCITSAccountOnBehalfOfCustomer";
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountName?: Maybe<Scalars["String"]["output"]>;
  accountOperator?: Maybe<Scalars["String"]["output"]>;
  accountType?: Maybe<Scalars["String"]["output"]>;
  askTaxDetails?: Maybe<AskTaxDetails>;
  closedDate?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  holdings?: Maybe<UcitsHoldings>;
  /**  ID is on the format {accountId}:SAM_A. */
  id?: Maybe<Scalars["ID"]["output"]>;
  legalEntity?: Maybe<Scalars["String"]["output"]>;
  onBehalfOfCustomer?: Maybe<OnBehalfOfCustomer>;
  pensionType?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  rights?: Maybe<Array<Rights>>;
  savingAgreements?: Maybe<Array<UcitsSavingAgreement>>;
  seller?: Maybe<Scalars["String"]["output"]>;
  withdrawalAgreements?: Maybe<Array<WithdrawalAgreement>>;
}

export interface UcitsHoldings {
  __typename?: "UCITSHoldings";
  cashHoldings?: Maybe<CashHoldings>;
  fundHoldings?: Maybe<Array<FundHoldings>>;
  fundHoldingsTotal?: Maybe<FundHoldingsTotal>;
}

/**
 * # UCITS Account
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.funds.private
 * * Realm corporate   - TODO
 */
export interface UcitsOrganizationAccount extends Contract, UcitsAccountAbstract {
  __typename?: "UCITSOrganizationAccount";
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountName?: Maybe<Scalars["String"]["output"]>;
  accountOperator?: Maybe<Scalars["String"]["output"]>;
  accountType?: Maybe<Scalars["String"]["output"]>;
  askTaxDetails?: Maybe<AskTaxDetails>;
  closedDate?: Maybe<Scalars["String"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  holdings?: Maybe<UcitsHoldings>;
  /**  ID is on the format {accountId}:SAM_A. */
  id?: Maybe<Scalars["ID"]["output"]>;
  legalEntity?: Maybe<Scalars["String"]["output"]>;
  organization?: Maybe<Organization>;
  pensionType?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  rights?: Maybe<Array<Rights>>;
  savingAgreements?: Maybe<Array<UcitsSavingAgreement>>;
  seller?: Maybe<Scalars["String"]["output"]>;
  withdrawalAgreements?: Maybe<Array<WithdrawalAgreement>>;
}

export interface UcitsSavingAgreement {
  __typename?: "UCITSSavingAgreement";
  account?: Maybe<Scalars["String"]["output"]>;
  activeState?: Maybe<ActiveState>;
  cashAmount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  dayInMonth?: Maybe<Scalars["Int"]["output"]>;
  debtorInfo?: Maybe<DebtorInfo>;
  deferred?: Maybe<Array<Deferred>>;
  funds?: Maybe<Array<Fund>>;
  id?: Maybe<Scalars["String"]["output"]>;
  months?: Maybe<Array<Scalars["Int"]["output"]>>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  stopDate?: Maybe<Scalars["String"]["output"]>;
}

export interface UnavailablePensionProvider {
  __typename?: "UnavailablePensionProvider";
  /** The category for the product. Values are the same as for the contracts but privatAFP and folketrygd are present when those cannot be fetched */
  category?: Maybe<Scalars["String"]["output"]>;
  /** If the pension provider is completely unavailable */
  completelyUnavailable?: Maybe<Scalars["Boolean"]["output"]>;
  /** The error code when privateAFP and folketryg fails */
  errorCode?: Maybe<Scalars["String"]["output"]>;
  /** Returned only if norskpensjon knows the number of missing contracts. Will be empty if completelyUnavailable is true */
  numberOfMissingContracts?: Maybe<Scalars["Int"]["output"]>;
  /** The pension provider which is unavailable */
  pensionProvider?: Maybe<PensionProvider>;
}

export interface UnrealizedProfit {
  __typename?: "UnrealizedProfit";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
}

export interface UsbCalculation {
  __typename?: "UsbCalculation";
  calculationCode?: Maybe<Scalars["String"]["output"]>;
  calculationTxt?: Maybe<Scalars["String"]["output"]>;
  lastCalculationDate?: Maybe<Scalars["String"]["output"]>;
  validFromDate?: Maybe<Scalars["String"]["output"]>;
}

export interface UsbTermDuration {
  __typename?: "UsbTermDuration";
  termDuration?: Maybe<Scalars["Int"]["output"]>;
  termDurationTxt?: Maybe<UsbTermDurationText>;
}

export enum UsbTermDurationText {
  Annual = "ANNUAL",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Semiannual = "SEMIANNUAL",
}

export interface Voucher {
  __typename?: "Voucher";
  transactionNumber?: Maybe<Scalars["String"]["output"]>;
  transactions?: Maybe<Array<LifePayoutTransaction>>;
}

export interface VouchersByArea {
  __typename?: "VouchersByArea";
  lastPayoutDate?: Maybe<Scalars["String"]["output"]>;
  payoutArea?: Maybe<PayoutArea>;
  payoutMethod?: Maybe<PayoutMethod>;
  vouchers?: Maybe<Array<Voucher>>;
}

export interface WithdrawalAgreement {
  __typename?: "WithdrawalAgreement";
  account?: Maybe<Scalars["String"]["output"]>;
  activeState?: Maybe<ActiveState>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  dayInMonth?: Maybe<Scalars["Float"]["output"]>;
  funds?: Maybe<Array<Fund>>;
  id?: Maybe<Scalars["String"]["output"]>;
  months?: Maybe<Array<Scalars["Float"]["output"]>>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  stopDate?: Maybe<Scalars["String"]["output"]>;
}

export interface WithdrawalPeriods {
  fullWithdrawal?: InputMaybe<FullWithdrawal>;
  partialWithdrawal?: InputMaybe<PartialWithdrawal>;
  salaryBeforeWithdrawal?: InputMaybe<Scalars["Int"]["input"]>;
}

/**
 * # YTP - ytelsepensjon
 *
 * #### OIDC scopes
 * * Realm storebrand  - storebrand.life.private
 * * Realm corporate   - todo
 */
export interface YtpContract extends Contract {
  __typename?: "YtpContract";
  annualPremium?: Maybe<Scalars["Float"]["output"]>;
  applicableVacancyRate?: Maybe<Scalars["Float"]["output"]>;
  calculatedNationalInsuranceBasicAmount?: Maybe<Scalars["Float"]["output"]>;
  compressionLimits: Array<Scalars["Float"]["output"]>;
  contractNavigation?: Maybe<ContractNavigation>;
  /**  Contract number in USB */
  contractNumber?: Maybe<Scalars["String"]["output"]>;
  /**  Contract type in USB */
  contractType?: Maybe<Scalars["String"]["output"]>;
  coverages?: Maybe<Coverages>;
  /**  AgreementId to display to the customer. Not unique and can be changed at anytime */
  customerContractReference?: Maybe<Scalars["String"]["output"]>;
  employedDate?: Maybe<Scalars["String"]["output"]>;
  employer?: Maybe<Employer>;
  employmentPercentage?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  pensionQualifyingIncome?: Maybe<Scalars["Float"]["output"]>;
  pensionSchemeInclusionDate?: Maybe<Scalars["String"]["output"]>;
  /**  Customer number in USB */
  personCustomerNumber?: Maybe<Scalars["String"]["output"]>;
  /**  S = Syk, D = Død, A = Arbeidsdyktig */
  personStatus?: Maybe<Scalars["String"]["output"]>;
  productArea?: Maybe<Scalars["String"]["output"]>;
  /**  Product code in USB */
  productCode?: Maybe<Scalars["String"]["output"]>;
  /**  Object containing FMS key with english and norwegian texts */
  productNameFms?: Maybe<TextElement>;
  /**  Key to lookup in FMS */
  productNameFmsKey?: Maybe<Scalars["String"]["output"]>;
  renewalDetails?: Maybe<RenewalDetail>;
  retirementAge?: Maybe<Scalars["Int"]["output"]>;
  salary?: Maybe<Scalars["Float"]["output"]>;
  salaryHistory?: Maybe<Array<SalaryHistory>>;
  underPayout?: Maybe<Scalars["Boolean"]["output"]>;
  usbCalculation?: Maybe<UsbCalculation>;
  usbTermDuration?: Maybe<UsbTermDuration>;
}
