<div class="summary-table summary-table-responsive">
  <div class="summary-row">
    <div class="summary-label">
      {{ annualReturn.description }}
    </div>
    <div class="summary-value">
      {{ annualReturn.pct | number : "1.0-2" }}
      %
    </div>
  </div>
</div>
