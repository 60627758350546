import { Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type Contract = Graph.YtpContract;

export class YtpEngagement extends SavingsAndPensionEngagement<Contract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }

  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract);
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.contractNumber;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public isActive(): boolean {
    return true;
  }

  public hasStipultatedBaseRatePayout(): boolean {
    return true;
  }

  public hasChangeableProfile(): boolean {
    return false;
  }

  public getCompressionLimits(): number[] {
    return this.contract.compressionLimits;
  }
}
