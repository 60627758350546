import { RouterModule } from "@angular/router";
import { ManagePkbPagesComponent } from "src/app/modules/pages/manage-pkb-pages/manage-pkb-pages.component";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "behandle-pkb",
};

export const routing = RouterModule.forChild([
  {
    path: "",
    component: ManagePkbPagesComponent,
    data: {
      title: "pageTitle.managePkb",
      breadcrumb: {
        hideAll: true,
      },
    },
  },
]);
