import { getIsNullable, Nullable } from "src/app/utils/utils";

export function isTypeNumber(num: Nullable<unknown>): num is number {
  return typeof num === "number";
}

export function getIsFiniteNumber(num: Nullable<unknown>): num is number {
  return isTypeNumber(num) && Number.isFinite(num);
}

export function parseNumber(num: Nullable<string | number>): number | undefined {
  return getIsNullable(num) ? undefined : isTypeNumber(num) ? num : parseInt(num, 10);
}

export function getDeltaInclusive(first: number, second: number): number {
  return getDelta(first, second) + 1;
}

export function getDelta(first: number, second: number): number {
  return Math.abs(second - first);
}
