import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { combineLatest, firstValueFrom, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { EkstrapensjonEngagement } from "src/app/models/engagements/savings-and-pension/link-engagement.model";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { CurrencyFormatterPipe } from "src/app/modules/shared/pipes/currency-formatter.pipe";
import { AddLumpSumActionBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/add-lump-sum-action-builder";
import { ChangeMonthlySavingsActionBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/change-monthly-savings-action-builder";
import { ExtrapensionService, ExtrapensionState } from "src/app/services/extrapension.service";
import { SavingsAndPensionService } from "src/app/services/prognoses-services/savings-and-pension.service";
import { isEkstrapensjonEngagement } from "src/app/utils/engagement.typeguards";
import { getIsNotNullable, getIsNullable, Nullable } from "src/app/utils/utils";
import { ActionWindowService } from "../../services/action-window.service";

interface FmsArgs {
  recommendendMonthlySaving: Nullable<string | number>;
  yearlyDeposit: Nullable<string | number>;
  monthlyDeposit: Nullable<string | number>;
}

@Component({
  selector: "app-action-extrapension",
  templateUrl: "./action-extrapension.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class ActionExtrapensionComponent implements ActionComponent {
  @Input()
  public action!: Action;

  public readonly State = ExtrapensionState;
  public readonly state$ = this.extrapensionService.extrapensionState$;
  public readonly fmsArgs$ = this.getFmsArgs();

  constructor(
    public readonly actionWindowService: ActionWindowService,
    private readonly extrapensionService: ExtrapensionService,
    private readonly savingsAndPensionService: SavingsAndPensionService,
    private readonly changeMonthlySavingsActionBuilder: ChangeMonthlySavingsActionBuilder,
    private readonly addLumpSumActionBuilder: AddLumpSumActionBuilder,
    private readonly currencyFormatter: CurrencyFormatterPipe,
  ) {}

  public getIsVisible(): Observable<boolean> {
    const validStates = [this.State.HasOneWithSingleDeposit, this.State.HasOneWithRecurringDeposit];

    return this.state$.pipe(
      map((state) => {
        if (getIsNullable(state)) {
          return false;
        }

        return validStates.includes(state);
      }),
    );
  }

  public async changeOrAddReccuringSavings(): Promise<void> {
    return firstValueFrom(this.getFirstExtrapension())
      .then((value) => this.changeMonthlySavingsActionBuilder.build(value).linkUrl)
      .then((linkUrl) => {
        window.open(linkUrl, "_blank");
      });
  }

  public async addLumpSum(): Promise<void> {
    return firstValueFrom(this.getFirstExtrapension())
      .then((value) => this.addLumpSumActionBuilder.build(value).linkUrl)
      .then((linkUrl) => {
        window.open(linkUrl, "_blank");
      });
  }

  private getFmsArgs(): Observable<FmsArgs> {
    return combineLatest([this.getFirstExtrapension(), this.extrapensionService.getRecommendendSavingMonthly()]).pipe(
      map(([engagement, recommendendMonthlySaving]) => {
        const yearlyDeposit = this.currencyFormatter.transform(engagement?.getYearlySavingsDeposit() ?? 0);
        const monthlyDeposit = this.currencyFormatter.transform(engagement?.getMonthlySavingsDeposit() ?? 0);

        return {
          yearlyDeposit,
          monthlyDeposit,
          recommendendMonthlySaving: this.currencyFormatter.transform(recommendendMonthlySaving),
        };
      }),
    );
  }

  private getFirstExtrapension(): Observable<EkstrapensjonEngagement> {
    return this.savingsAndPensionService.engagements$.pipe(
      map((engagements) => engagements.filter(isEkstrapensjonEngagement).at(0)),
      filter(getIsNotNullable),
    );
  }
}
