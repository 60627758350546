<div class="add-afp-card full-page-width-limiter">
  <div class="panel-header">
    <h4>{{ "pensionPlan.afpEngagement.title" | fms }}</h4>
    <button
      stbButton="text"
      class="close-button"
      data-trackid="afpCta-close"
      [setIconAfter]="true"
      iconType="close"
      (click)="hideAfp()"
    >
      {{ "pensionPlan.afpEngagement.button.close" | fms }}
    </button>
  </div>
  <div class="panel-body">
    <p>{{ "pensionPlan.afpEngagement.description" | fms }}</p>
    <app-add-engagements-buttons [buttons]="[AddButtonKey.Afp]"></app-add-engagements-buttons>
  </div>
</div>
