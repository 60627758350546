<div [@stepNavigation]="stepAnimationState$ | async">
  <button mat-dialog-close (click)="close()" *ngIf="isCurrentStepCancelable$ | async">
    <i>close</i>
  </button>
  <h3 mat-dialog-title>
    {{ currentStepTitle$ | async }}
  </h3>

  <mat-dialog-content #resetFocus tabindex="-1">
    <ng-container #content></ng-container>
  </mat-dialog-content>
</div>

<div mat-dialog-actions class="actions" *ngIf="showActions$ | async">
  <button
    stbButton="outlined"
    class="action-button"
    (click)="onPrevious()"
    *ngIf="shouldShowPreviousButton()"
    [disabled]="loading$ | async"
  >
    {{ previousButtonText$ | async }}
  </button>

  <button
    stbButton="contained"
    class="action-button"
    [disabled]="loading$ | async"
    [enableSpinner]="loading$ | async"
    (click)="onNext()"
  >
    {{ nextButtonText$ | async }}
  </button>
</div>
