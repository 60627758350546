<stb-form [label]="'afpFormField.label' | fms" [hint]="'afpFormField.tip' | fms">
  <stb-radio-buttons
    *ngIf="radioButtons$ | async as radioButtons"
    [horizontal]="false"
    [group]="radioButtons"
    [hint]
    (valueChanged)="updateAfp($event)"
    data-trackid="AfpFormFieldComponent-afpValueChanged-radioButton"
  >
  </stb-radio-buttons>
</stb-form>
