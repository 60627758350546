import { Injectable } from "@angular/core";
import { isBefore, parseISO } from "date-fns";
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, of, throwError } from "rxjs";
import { catchError, combineLatestWith, map, mergeMap, switchMap, tap } from "rxjs/operators";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { handleError } from "src/app/utils/http";
import { Nullable } from "src/app/utils/utils";
import { isSmartAccount } from "../models/engagements/bank-engagement.model";
import { getFirstItem, getLastItem } from "../utils/array";
import { AcrLevelTooLowError, AdvisorsNotAllowedError } from "../utils/errors";
import { pollWhile } from "../utils/rxjs/pipes";
import { ReplayStore } from "../utils/rxjs/store";
import { BankAccountRestApiService, getAccountsResponse200 } from "./api/bank-account-rest-api.service";
import { components } from "./api/bank-account-rest-api.types";
import { BankAccountsQueryService } from "./api/bank-accounts-queries.service";
import { BankRestApiService, BankTransactionOutput } from "./api/bank-rest-api.service";
import { SmartPensjonRestApiService } from "./api/smart-pensjon-rest-api.service";
import { KeycloakService } from "./keycloak.service";

type BankAccountRestSchemas = components["schemas"];
type SmartApplicationId = BankAccountRestSchemas["CustomerAccountCommand"]["applicationId"] | undefined;
type SmartAccountStatus = BankAccountRestSchemas["Account"]["accountStatus"];
type SmartBalance = Graph.BankAccount["bookBalance"];
export type SmartAccountNumber = Graph.BankAccount["accountNumber"];

export interface SmartAccount {
  state: SmartAccountState;
  applicationId?: SmartApplicationId;
  applicationStatus?: SmartAccountStatus;
  balance?: SmartBalance;
  accountNumber?: SmartAccountNumber;
  interestRate?: Graph.BankAccount["currentInterestRate"];
}

export enum SmartAccountState {
  AccountCreationFailed = "AccountCreationFailed",
  AccountCreationInProgress = "AccountCreationInProgress",
  ActionRequired = "ActionRequired",
  HasMultipleAccounts = "HasMultipleAccounts",
  HasOneAccount = "HasOneAccount",
  Qualified = "Qualified",
  Unknown = "Unknown",
  Unqualified = "Unqualified",
}

@Injectable({
  providedIn: "root",
})
export class SmartAccountService {
  public readonly smartAccount$: Observable<SmartAccount>;
  public readonly smartAccountState$: Observable<SmartAccountState>;
  public readonly balance$: Observable<SmartBalance>;
  public readonly accountNumber$: Observable<SmartAccountNumber>;
  public readonly applicationId$: Observable<SmartApplicationId>;

  private readonly smartAccountStore$ = new ReplayStore<SmartAccount>();
  private readonly override$ = new BehaviorSubject<SmartAccountState | undefined>(undefined);

  constructor(
    private readonly bankAccountsQueryService: BankAccountsQueryService,
    private readonly bankRestApiService: BankRestApiService,
    private readonly bankAccountRestApiService: BankAccountRestApiService,
    private readonly keycloakService: KeycloakService,
    private readonly smartPensjonRestApiService: SmartPensjonRestApiService,
  ) {
    this.smartAccount$ = this.smartAccountStore$.asObservable();

    this.smartAccountState$ = this.override$.pipe(
      combineLatestWith(this.smartAccount$),
      map(([override, { state }]) => override ?? state),
    );

    this.balance$ = this.smartAccount$.pipe(map(({ balance }) => balance));

    this.accountNumber$ = this.smartAccount$.pipe(map(({ accountNumber }) => accountNumber));

    this.applicationId$ = this.smartAccount$.pipe(map(({ applicationId }) => applicationId));
  }

  public pollSmartAccount(): Observable<SmartAccount> {
    function shouldPoll(smartAccount: SmartAccount): boolean {
      return smartAccount.state === SmartAccountState.AccountCreationInProgress;
    }

    const POLL_INTERVAL = 3000;
    const MAX_POLL_COUNT = 20;

    return this.getSmartAccount().pipe(pollWhile(POLL_INTERVAL, shouldPoll, MAX_POLL_COUNT));
  }

  public fetchEligibility(): Observable<SmartAccount.Eligibility> {
    return combineLatest([this.keycloakService.isAdvisorContext$, this.keycloakService.isAuthLevel3or4$]).pipe(
      mergeMap(([isAdvisorContext, isAuthorized]) => {
        if (isAdvisorContext) {
          return throwError(() => new AdvisorsNotAllowedError());
        }
        if (!isAuthorized) {
          return throwError(() => new AcrLevelTooLowError());
        }
        return this.smartPensjonRestApiService.fetchEligibility();
      }),
    );
  }

  public fetchTransactions(): Observable<BankTransactionOutput[]> {
    return this.accountNumber$.pipe(
      distinctUntilChanged(),
      switchMap((accountNumber) => (accountNumber ? this.bankRestApiService.fetchTransactions(accountNumber) : of([]))),
    );
  }

  public overrideSmartAccount(state: SmartAccountState): void {
    this.override$.next(state);
  }

  public getSmartAccount(): Observable<SmartAccount> {
    return combineLatest([this.fetchEligibility(), this.fetchBankAccounts(), this.fetchCustomerAccounts()]).pipe(
      map(([eligibility, bankAccounts, customerAccounts]) => {
        const bankAccount = getBankAccount(bankAccounts);
        const account = getApplicationAccount(customerAccounts);
        const state = toSmartAccountState(eligibility, bankAccounts, account?.accountStatus);

        return {
          state,
          balance: bankAccount?.bookBalance,
          accountNumber: bankAccount?.accountNumber || account?.accountNumber,
          interestRate: bankAccount?.currentInterestRate,
          applicationId: getApplicationId(customerAccounts),
          applicationStatus: account?.accountStatus,
        };
      }),
      catchError((err) =>
        handleError<SmartAccount>("SmartAccountService::getSmartAccountState()", err, {
          state: SmartAccountState.Unknown,
        }),
      ),
      tap((state) => this.smartAccountStore$.next(state)),
    );
  }

  private fetchCustomerAccounts(): Observable<getAccountsResponse200> {
    return this.keycloakService.isAuthLevel3or4$.pipe(
      switchMap((isAuthorized) => {
        if (!isAuthorized) {
          return throwError(() => new AcrLevelTooLowError());
        }

        return this.bankAccountRestApiService.fetchCustomerAccounts().pipe(map(getCustomerAccounts));
      }),
    );
  }

  private fetchBankAccounts(): Observable<Graph.BankAccount[]> {
    return combineLatest([this.keycloakService.isAdvisorContext$, this.keycloakService.isAuthLevel3or4$]).pipe(
      mergeMap(([isAdvisorContext, isAuthorized]) => {
        if (isAdvisorContext) {
          return throwError(() => new AdvisorsNotAllowedError());
        }
        if (!isAuthorized) {
          return throwError(() => new AcrLevelTooLowError());
        }
        return this.bankAccountsQueryService.fetchBankAccounts();
      }),
      map(filterSmartAccounts),
    );
  }
}

function getApplicationId(customerAccounts: getAccountsResponse200): SmartApplicationId | undefined {
  return getLastItem(customerAccounts)?.applicationId;
}

function toSmartAccountState(
  eligility: SmartAccount.Eligibility,
  accounts: Graph.BankAccount[],
  accountStatus: SmartAccountStatus | undefined,
): SmartAccountState {
  const accountState = mapAccountStatusToSmartAccountState(accountStatus);

  if (accounts.length > 1) {
    return SmartAccountState.HasMultipleAccounts;
  }
  if (accounts.length === 1) {
    return SmartAccountState.HasOneAccount;
  }
  if (accountState !== SmartAccountState.Unknown) {
    return accountState;
  }
  if (eligility.payload?.eligible) {
    return SmartAccountState.Qualified;
  }
  return SmartAccountState.Unqualified;
}

function mapAccountStatusToSmartAccountState(status: SmartAccountStatus | undefined): SmartAccountState {
  /*
   * Possible statuses:
   *
   * NEEDINFO                   notice - SDC waiting for system info. No user data needed.
   * IN_PROGRESS                action_required - User application has yet to be finalized
   * COMPLETED                  notice - The application is completed in STB system and is ready for SENDING
   * SENDING                    notice - The application is being transferred from STB to SDC
   *   MANUAL                   notice - SDC has passed the application to manual processing
   *     REJECTED               error - SDC manual processor rejected the application
   *   ERROR                    error - System error
   *     CANCELLED              error - System failed 3 times to retry ERROR.
   *     REJECTED               error - SDC automated processor rejected the application
   * SUBMITTED                  notice - Data has gone to signing
   * DOCUMENT_RECEIVED          notice - Received from SDC. Pre-step before sending to Signicat
   * ESIGNATURE_REQUESTED       notice - Middle-step by Signicat
   * ESIGNATURE_READY           action_required - Ready for signing
   * ESIGNATURE_SIGNED          notice - User has signed the documents
   *   CANCELLED                error - Rejected by user
   * RECEIVED_SIGNED_DOCUMENTS  notice - Received from Signicat. Pre-step before archiving.
   * DOCUMENTS_ARCHIVED         notice - Signed documents archived in SDC
   * DECISION_SENT              notice - Parallell job with DOCUMENTS_ARCHIVED. Not important for the frontend
   */
  switch (status) {
    case "IN_PROGRESS":
    case "ESIGNATURE_READY":
      return SmartAccountState.ActionRequired;
    case "REJECTED":
    case "ERROR":
    case "CANCELLED":
      return SmartAccountState.AccountCreationFailed;
    case "NEEDINFO":
    case "SENDING":
    case "MANUAL":
    case "SUBMITTED":
    case "DOCUMENT_RECEIVED":
    case "ESIGNATURE_REQUESTED":
    case "ESIGNATURE_SIGNED":
    case "RECEIVED_SIGNED_DOCUMENTS":
      return SmartAccountState.AccountCreationInProgress;
    case "DOCUMENTS_ARCHIVED":
    case "DECISION_SENT":
      return SmartAccountState.HasOneAccount;
    default:
      return SmartAccountState.Unknown;
  }
}

function filterSmartAccounts(accounts: Graph.BankAccount[]): Graph.BankAccount[] {
  return accounts.filter(isSmartAccount);
}

function getCustomerAccounts(customerAccounts: getAccountsResponse200): getAccountsResponse200 {
  return customerAccounts
    .filter((customerAccount) => customerAccount.accounts?.some((account) => account.accountType === "SMART_ACCOUNT"))
    .sort(sortCustomerAccountsByDate);
}

function getApplicationAccount(
  customerAccounts: getAccountsResponse200,
): BankAccountRestSchemas["Account"] | undefined {
  return getLastItem(getLastItem(customerAccounts)?.accounts);
}

function getBankAccount(bankAccounts: Graph.BankAccount[]): Nullable<Graph.BankAccount> {
  return getFirstItem(bankAccounts);
}

function sortCustomerAccountsByDate(
  a: BankAccountRestSchemas["CustomerAccountProperties"],
  b: BankAccountRestSchemas["CustomerAccountProperties"],
): number {
  return isBefore(parseISO(a.updatedDate), parseISO(b.updatedDate)) ? -1 : 1;
}
