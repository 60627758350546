import { TitleCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { getIsNullable } from "../../../utils/utils";

@Pipe({ name: "formatName" })
export class FormatNamePipe extends TitleCasePipe implements PipeTransform {
  /**
   * @param value The string to transform to custom title case.
   */
  transform(value: string): string;
  transform(value: null | undefined): null;
  transform(value: string | null | undefined): string | null {
    if (value === "") {
      return "";
    }

    if (getIsNullable(value)) {
      return null;
    }

    return super.transform(value).replace(/-([a-z])/g, (g) => "-" + g[1].toUpperCase());
  }
}
