import { Injectable } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";

@Injectable({
  providedIn: "root",
})
export class TaxRatesService {
  constructor(private readonly fmsService: FmsService) {}

  public getNationalInsuranceBasicAmount(): number {
    const amount = this.fmsService.instant<Fms.Translations>("taxRates.taxValues")["national.insurance.basic.amount"];

    if (!Number.isInteger(amount)) {
      throw new Error("National insurance basic amount is not a number!");
    }

    return amount;
  }
}
