import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { filter, firstValueFrom, map, Observable, take } from "rxjs";
import { nonZeroCurrencyRegex } from "src/app/constants/regex.constants";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import {
  ExternalSavingsEngagement,
  ExternalSavingsEngagementType,
} from "src/app/models/engagements/external-savings-engagement.model";
import { FmsService } from "src/app/services/fms.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import {
  AbstractNewEngagementStepperComponent,
  AddEngagementStep,
} from "../../../../shared/components/add-engagement/abstract-new-engagement-stepper.component";
import { ExternalEngagementConfirmationComponent } from "./external-engagement-confirmation/external-engagement-confirmation.component";
import { ExternalSavingsContractBuilderService } from "./external-savings-contract-builder.service";

enum SavingInterval {
  Ongoing = "ongoing",
  Once = "once",
}

interface TypeFmsOption {
  text: string;
  value: ExternalSavingsEngagementType;
  description: string;
}

interface SavingsInternalFmsOption {
  text: string;
  value: SavingInterval;
}

@Component({
  selector: "app-external-engagement",
  templateUrl: "./external-engagement.component.html",
  styleUrls: [
    "./external-engagement.component.scss",
    "../../../../shared/components/add-engagement/add-engagement.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [ExternalSavingsContractBuilderService],
})
export class ExternalEngagementComponent extends AbstractNewEngagementStepperComponent<ExternalEngagementComponent> {
  public externalEngagementForm: UntypedFormGroup;
  public type: AbstractControl;
  public savingInterval: AbstractControl;
  public balance: AbstractControl;
  public deposit: AbstractControl;
  public title: AbstractControl;
  public payer: AbstractControl;

  public ExternalSavingsEngagementType = ExternalSavingsEngagementType;
  public SavingInterval = SavingInterval;

  public readonly typeRadioButtons$ = this.getTypeRadioButtons();
  public readonly savingIntervalRadioButtons$ = this.getSavingIntervalRadioButtons();

  private readonly engagementStepsControls: Pick<AddEngagementStep, "controlToValidate">[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public injectedEngagement: ExternalSavingsEngagement,
    private readonly fb: UntypedFormBuilder,
    private readonly fmsService: FmsService,
    private readonly pensionPlanService: PensionPlanService,
    private readonly matDialog: MatDialog,
    private readonly externalSavingsContractBuilderService: ExternalSavingsContractBuilderService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    protected readonly dialogRef: MatDialogRef<ExternalEngagementComponent>,
  ) {
    super(dialogRef);

    this.externalEngagementForm = this.fb.group({
      firstGroup: this.fb.group({
        type: [null, [Validators.required]],
      }),
      secondGroup: this.fb.group({
        savingInterval: [null, [Validators.required]],
        balance: [null, [Validators.required]],
        deposit: [null, [Validators.required, Validators.pattern(nonZeroCurrencyRegex)]],
      }),
      thirdGroup: this.fb.group({
        title: [null, [Validators.required]],
        payer: [null, [Validators.required]],
      }),
    });

    this.type = (this.externalEngagementForm.controls.firstGroup as UntypedFormGroup).controls.type;

    this.savingInterval = (
      this.externalEngagementForm.controls.secondGroup as UntypedFormGroup
    ).controls.savingInterval;
    this.balance = (this.externalEngagementForm.controls.secondGroup as UntypedFormGroup).controls.balance;
    this.deposit = (this.externalEngagementForm.controls.secondGroup as UntypedFormGroup).controls.deposit;

    this.title = (this.externalEngagementForm.controls.thirdGroup as UntypedFormGroup).controls.title;
    this.payer = (this.externalEngagementForm.controls.thirdGroup as UntypedFormGroup).controls.payer;

    this.engagementStepsControls = [
      { controlToValidate: this.externalEngagementForm.controls.firstGroup },
      {
        controlToValidate: this.externalEngagementForm.controls.secondGroup,
      },
      { controlToValidate: this.externalEngagementForm.controls.thirdGroup },
    ];

    this.engagementSteps = this.fmsService
      .instant<Omit<AddEngagementStep, "controlToValidate">[]>("externalEngagement.fmsEngagementSteps")
      .map((fmsSteps, i) => ({
        ...fmsSteps,
        ...this.engagementStepsControls[i],
      }));

    this.initiateEditMode();
  }

  public setType(event: RadioButton): void {
    this.type.setValue(event.value);
    this.type.updateValueAndValidity();
  }

  public hasInjectedEngagement(): boolean {
    return !!this.injectedEngagement;
  }

  public setSavingInterval(savingInterval: SavingInterval): void {
    this.savingInterval.setValue(savingInterval);

    if (savingInterval === SavingInterval.Ongoing) {
      this.balance.setValidators(Validators.required);
      this.balance.updateValueAndValidity();
      this.deposit.enable();
    } else {
      this.balance.setValidators([Validators.required, Validators.pattern(nonZeroCurrencyRegex)]);
      this.balance.updateValueAndValidity();
      this.deposit.disable();
    }
  }

  public async submit(): Promise<void> {
    this.hasInjectedEngagement() ? await this.editEngagement() : await this.createEngagement();

    await firstValueFrom(this.fetchPrognosesRunningJobsService.isCurrentYearLoaded$.pipe(filter(Boolean)));
  }

  private initiateEditMode(): void {
    if (this.hasInjectedEngagement()) {
      const { contract } = this.injectedEngagement;
      const savingInterval = toSavingInterval(contract.periodicDeposit);

      this.type.setValue(contract.type);
      this.balance.setValue(contract.balance);
      this.deposit.setValue(contract.periodicDeposit);
      this.title.setValue(contract.addlInfo);
      this.payer.setValue(contract.contractNumber);
      this.setSavingInterval(savingInterval);
    }
  }

  private async editEngagement(): Promise<void> {
    const { contract } = this.injectedEngagement;
    const patch: CustomerSuppliedData.ExternalSaving = {
      type: this.type.value,
      balance: this.balance.value,
      periodicDeposit: this.getPeriodicDeposit(),
      addlInfo: this.title.value,
      contractNumber: this.payer.value,
      isPension: true,
      id: contract?.id,
      fromAge: contract?.fromAge,
      durationYears: contract?.durationYears,
    };

    return this.pensionPlanService.editExternalSavingsEngagement(patch);
  }

  private async createEngagement(): Promise<void> {
    const contract = await this.externalSavingsContractBuilderService.build({
      type: this.type.value,
      balance: this.balance.value,
      periodicDeposit: this.getPeriodicDeposit(),
      addlInfo: this.title.value,
      contractNumber: this.payer.value,
    });

    const contractId = await this.pensionPlanService.addExternalSavingsEngagement(contract);

    return this.showConfirmationDialogAfterClose(contractId, contract);
  }

  private getPeriodicDeposit(): number {
    return this.savingInterval.value === SavingInterval.Ongoing ? this.deposit.value : 0;
  }

  private showConfirmationDialogAfterClose(patchId: string, patch: CustomerSuppliedData.ExternalSaving): void {
    const dialogConfig = {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "no-max-width",
      autoFocus: false,
    };

    this.dialogRef.afterClosed().subscribe(() => {
      this.matDialog.open(ExternalEngagementConfirmationComponent, {
        data: { ...patch, id: patchId },
        ...dialogConfig,
      });
    });
  }

  private getTypeRadioButtons(): Observable<RadioButton[]> {
    return this.fmsService.translateAsync<TypeFmsOption[]>("externalEngagement.form.type.items").pipe(
      take(1),
      map<TypeFmsOption[], RadioButton[]>((radioButtons) => {
        const currentValue = this.type.value;
        const injectedValue = this.injectedEngagement?.getType();

        return radioButtons.map((radioButton) => ({
          label: radioButton.text,
          value: radioButton.value,
          hint: radioButton.description,
          disabled: false,
          checked: (currentValue || injectedValue) === radioButton.value,
        }));
      }),
    );
  }

  private getSavingIntervalRadioButtons(): Observable<RadioButton[]> {
    return this.fmsService.translateAsync<SavingsInternalFmsOption[]>("externalEngagement.form.periodic.items").pipe(
      take(1),
      map<SavingsInternalFmsOption[], RadioButton[]>((radioButtons) => {
        const currentValue = this.savingInterval.value;
        const periodicDeposit = this.injectedEngagement?.contract?.periodicDeposit;
        const injectedValue = periodicDeposit ? toSavingInterval(periodicDeposit) : undefined;

        return radioButtons.map((radioButton) => ({
          label: radioButton.text,
          value: radioButton.value,
          disabled: false,
          checked: (currentValue || injectedValue) === radioButton.value,
        }));
      }),
    );
  }
}

function toSavingInterval(periodicDeposit: number): SavingInterval {
  return periodicDeposit > 0 ? SavingInterval.Ongoing : SavingInterval.Once;
}
