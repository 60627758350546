import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { filter } from "rxjs";
import { map } from "rxjs/operators";
import {
  StorebrandOnlyService,
  VALID_ROUTES_FOR_STOREBRAND_ONLY_TOKEN,
} from "src/app/services/storebrand-only.service";
import { getIsNavigationEnd } from "src/app/utils/router.utils";
import { removeUrlQueryParamsAndFragment } from "src/app/utils/utils";

@Injectable({
  providedIn: "root",
})
export class StorebrandOnlyRouterWatcherService {
  constructor(
    @Inject(VALID_ROUTES_FOR_STOREBRAND_ONLY_TOKEN)
    private readonly validRoutes: string[],
    private readonly router: Router,
    private readonly storebrandOnlyService: StorebrandOnlyService,
  ) {}

  public init(): void {
    this.toggleStorebrandOnlyModeOnRouteChange();
  }

  private toggleStorebrandOnlyModeOnRouteChange(): void {
    this.router.events
      .pipe(
        filter(getIsNavigationEnd),
        map((route) => {
          const urlWithoutQueryParamsAndFragment = removeUrlQueryParamsAndFragment(route.urlAfterRedirects);
          return this.validRoutes.includes(urlWithoutQueryParamsAndFragment);
        }),
      )
      .subscribe((isEnabled) => {
        this.storebrandOnlyService.toggleForRoute(isEnabled);
      });
  }
}
