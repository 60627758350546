import { combineLatest, first, map, Observable } from "rxjs";
import { EngagementName } from "src/app/models/pension.model";
import { toArrayOrEmpty, toSum } from "src/app/utils/array";
import { select } from "src/app/utils/rxjs/select";
import { GraphEngagement } from "./graph-engagement.model";
import { PAYOUT_DURATIONS, PayoutDuration } from "./generic-engagement.model";
import { getIsNullable } from "src/app/utils/utils";
import { MAX_LIFE_EXPECTANCY_AGE } from "src/app/constants/business.constants";

type Contract = CustomerSuppliedData.OtherPension;

export class OtherPensionEngagement extends GraphEngagement<Contract, never> {
  public override getContracts(): unknown[] {
    return [];
  }
  public override getPayoutDuration(): PayoutDuration {
    if (getIsNullable(this.contract.periods) || this.contract.periods.length === 0) {
      return PAYOUT_DURATIONS.invalid;
    }

    // Check src/app/models/engagements/graph-engagement.model.ts comment in isLifelongPayout() method
    // Since savings-graphql is returning lifeLong: null, we need to check if the duration is infinite
    if (this.contract.periods[0].fromAge + this.contract.periods[0].duration >= MAX_LIFE_EXPECTANCY_AGE) {
      return PAYOUT_DURATIONS.infinite;
    }

    return {
      years: this.contract.periods.map(toPeriod).reduce(toSum),
      months: 0,
    };
  }
  public override isActive(): boolean {
    return false;
  }
  public override getIdentifier(): string {
    return this.contract.id;
  }

  /** @deprecated Use getNameAsync instead */
  public override getName(): EngagementName {
    return select(this.getNameAsync());
  }

  public override getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();

    if (this.contract.replaceOffentligTjenestepensjon) {
      return combineLatest([
        fmsService.translateAsync<string>("sharedProductNames.np.offentlig_tjenestepensjon.product_description"),
        fmsService.translateAsync<string>(
          "sharedProductNames.np.offentlig_tjenestepensjon.product_description.description",
        ),
      ]).pipe(
        first(),
        map(([name, description]) => ({
          description,
          name,
          payer: this.contract.payer,
          supplier: this.contract.title,
        })),
      );
    }

    return fmsService.translateAsync<string>("OtherPensionEngagement.name").pipe(
      first(),
      map(([name]) => ({
        name,
        payer: this.contract.title,
        supplier: this.contract.payer,
      })),
    );
  }

  public override isLifelongPayout(): boolean {
    return this.isPublicPensionFromNorskPensjon() || super.isLifelongPayout();
  }

  public override isPublicPensionFromNorskPensjon(): boolean {
    return !!this.contract?.replaceOffentligTjenestepensjon;
  }

  public getPeriods(): Contract["periods"] {
    return toArrayOrEmpty(this.contract.periods);
  }

  public override isSavingsEngagement(): boolean {
    return false;
  }

  public override hasChangeableProfile(): boolean {
    return false;
  }
}

function toPeriod(period: CustomerSuppliedData.OtherPensionPeriod): number {
  return period.duration;
}
