import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { Observable, catchError, firstValueFrom, of, take, map } from "rxjs";
import { environment } from "src/environments/environment";
import { BenefitsService } from "../services/benefits.service";
import { ConsentService } from "../services/consent.service";
import { ClientDataService } from "../services/customer-supplied-data/client-data.service";
import { ProfileService } from "../services/customer-supplied-data/profile.service";
import { CustomerService } from "../services/customer.service";
import { ErrorsService } from "../services/errors.service";
import { FmsService } from "../services/fms.service";
import { GlobalRunningJobsService } from "../services/running-jobs/global-running-jobs.service";
import { Monitoring } from "../utils/monitoring";
import { KeycloakService } from "src/app/services/keycloak.service";
import { switchMap } from "rxjs/operators";

export const benefitsResolver: ResolveFn<boolean> = () => {
  const benefitsService = inject(BenefitsService);
  const keycloakService = inject(KeycloakService);

  // We do not perform benefits check on the customer when advisor is logged in on behalf of the customer.
  return keycloakService.isAdvisorContext$.pipe(
    switchMap((isAdvisorContext) => {
      if (isAdvisorContext) {
        return of(false);
      } else {
        return benefitsService.fetchCustomerBenefits$().pipe(
          map(() => true),
          catchError(() => of(false)),
        );
      }
    }),
  );
};

export const consentResolver: ResolveFn<Observable<any>> = () => {
  if (!environment.production && environment.skipConsents) {
    return of(true);
  }

  const consentService = inject(ConsentService);

  return consentService.fetchConsents().pipe(
    take(1),

    catchError((error) => {
      Monitoring.warn("A consent error occured: ", {
        extras: {
          error,
        },
      });
      return of(false);
    }),
  );
};

export const customerSuppliedClientDataResolver: ResolveFn<
  Observable<CustomerSuppliedData.ClientDataWrapper | undefined>
> = () => {
  const clientDataService = inject(ClientDataService);
  const errorsService = inject(ErrorsService);
  const globalRunningJobsService = inject(GlobalRunningJobsService);

  return clientDataService.fetchClientDataWrapper().pipe(
    take(1),
    catchError((error) => {
      Monitoring.warn("Error fetching client data [%s]", {
        extras: {
          error,
        },
      });
      errorsService.pushCriticalError(error, "Client data is not responding");
      return of(undefined);
    }),
    globalRunningJobsService.withLoader("CustomerSuppliedClientDataResolver"),
  );
};

export const customerSuppliedDataResolver: ResolveFn<Observable<CustomerSuppliedData.Profile | undefined>> = () => {
  const profileService = inject(ProfileService);
  const globalRunningJobsService = inject(GlobalRunningJobsService);

  return profileService.fetchProfile().pipe(
    take(1),
    catchError(() =>
      //TODO: give a user friendly message
      of(undefined),
    ),
    globalRunningJobsService.withLoader("CustomerSuppliedDataResolver"),
  );
};

export const customerResolver: ResolveFn<Observable<CustomerMaster.Response.Customer | undefined>> = () => {
  return inject(CustomerService).fetchAndSetCustomerWithLoader();
};

// TODO: Cleanup
export const languageResolver: ResolveFn<Promise<void>> = async (route: ActivatedRouteSnapshot) => {
  const getLangFromRoute: (_route: ActivatedRouteSnapshot) => Fms.LangCode = (_route) => {
    return _route?.queryParams?.lang;
  };
  const customerToFmsLanguageAdapter: (value: CustomerMaster.Language) => Fms.LangCode = (value) => {
    return value === "EN" ? "en" : "no";
  };

  const fmsService = inject(FmsService);
  const customerService = inject(CustomerService);

  const hasValidQueryParamLanguage = fmsService.isValidLocale(getLangFromRoute(route));

  if (hasValidQueryParamLanguage) {
    fmsService.setLocale(getLangFromRoute(route));
  } else {
    const customerLanguage = await firstValueFrom(customerService.language$);
    fmsService.setLocale(customerToFmsLanguageAdapter(customerLanguage));
  }
};
