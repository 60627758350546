import { cloneDeep } from "lodash-es";
import { filterUnique } from "../utils/array";
import { getIsNotNullable } from "../utils/utils";

interface Stack<T> {
  pop(): T | undefined;
  push(val: T): void;
  size(): number;
  clear(): void;
  top(): T | undefined;
  next(): T | undefined;
  toArray(): T[];
}

interface QueueOptions {
  maxSize: number;
  overflowCallback: Function;
  unique: boolean;
}

export abstract class Queue<T> implements Stack<T> {
  protected _store: T[] = [];
  protected overflowCallback?: Function;
  protected _unique = false;
  protected _maxSize = Infinity;

  constructor({ maxSize = Number.MAX_SAFE_INTEGER, overflowCallback, unique = false }: Partial<QueueOptions> = {}) {
    this._maxSize = maxSize;
    this._unique = unique;
    this.overflowCallback = overflowCallback
      ? overflowCallback
      : (args: unknown): RangeError => {
          throw new RangeError(`Queue full, elements overflowing: ${args}`);
        };
  }
  push(val: T): void {
    // Uniqifiy first in order to not lose items in size constrained Queues.
    // If not, push(2) to [1,2,3] would result in [3,2] instead of [1,3,2]
    if (this._unique) {
      this._store.push(val);
      this.uniquify();
      this._store.pop();
    }
    if (getIsNotNullable(this.overflowCallback) && this.size() === this._maxSize) {
      this.overflowCallback(val);
    } else {
      this._store.push(val);
    }
  }
  size(): number {
    return this._store.length;
  }
  clear(): void {
    this._store.length = 0;
  }
  toString(): string {
    return this._store.toString();
  }
  top(): T | undefined {
    return this._store[this._store.length - 1];
  }
  toArray(): T[] {
    return [...this._store];
  }

  abstract pop(): T | undefined;
  abstract next(): T | undefined;
  abstract uniquify(): T[];
}

export class Fifo<T> extends Queue<T> {
  pop(): T | undefined {
    return this._store.shift();
  }
  next(): T | undefined {
    return this._store[0];
  }
  /**
   * The double reverse is to preserve element position in the stack, as
   * the unique filter will remove the last occurence, which, in a FIFO
   * stack is the 'last in' element.
   * So, although a sub-optimal implementation, the runtime has linear
   * runtime O(N)
   */
  uniquify(): T[] {
    const reversedStore = [...this._store].reverse();
    this._store = filterUnique(reversedStore).reverse();

    return this._store;
  }
}

export class Lifo<T> extends Queue<T> {
  pop(): T | undefined {
    return this._store.pop();
  }
  next(): T | undefined {
    return this.top();
  }
  uniquify(): T[] {
    this._store = filterUnique(this._store);
    return this._store;
  }
}

/**
 * FifoStack behaves like Fifo queue except for that where Fifo handles overflows
 * by spilling over, FifoStack keeps the elements flowing through falling off the bottom.
 */
export class FifoStack<T> extends Fifo<T> {
  constructor(options: Partial<QueueOptions> = {}) {
    super({
      ...options,
      overflowCallback: (arg: any) => {
        this.pop();
        this.push(arg);
      },
    });
  }

  clone(): FifoStack<T> {
    const fifoStack = new FifoStack<T>({
      maxSize: this._maxSize,
      overflowCallback: this.overflowCallback,
      unique: this._unique,
    });

    this._store.forEach((val) => fifoStack.push(typeof val === "object" ? cloneDeep(val) : val));

    return fifoStack;
  }
}
