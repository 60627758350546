import { Injectable } from "@angular/core";
import { FmsService } from "src/app/services/fms.service";
import { getStbStage, StbStage } from "src/app/utils/storebrand-staging";

interface ActionConfig {
  forceAllVisible: boolean;
}

@Injectable()
export class ActionConfigService {
  constructor(private readonly fmsService: FmsService) {}

  public getIsForceAllVisible(): boolean {
    return this.getActionConfig().forceAllVisible;
  }

  private getActionConfig(): ActionConfig {
    const config = this.fmsService.instant<ActionConfig>("actions.config");
    const defaultConfig = getDefaultConfig();
    const isTestEnvironment = getIsStagingTest();

    return isTestEnvironment ? config : defaultConfig;
  }
}

function getIsStagingTest(): boolean {
  return [StbStage.Test, StbStage.Localhost].includes(getStbStage());
}

function getDefaultConfig(): ActionConfig {
  return {
    forceAllVisible: false,
  };
}
