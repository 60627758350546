<div class="container">
  <app-page-title [text]="'articles.articlesOverview.pageTitle' | fms"></app-page-title>

  <div class="filters">
    <stb-chip [formControl]="articles" (chipClicked)="setFilter($event.chip)" chipType="choice"></stb-chip>
  </div>

  <stb-alert type="info" width="fluid" *ngIf="isEnglish">
    {{ "articles.articlesOverview.noTranslationsAlert" | fms }}
  </stb-alert>

  <div class="articles">
    <ng-container *ngFor="let article of articles$ | async; trackBy: trackArticleBy">
      <app-article-link [article]="article">
        <app-article-card [article]="article"></app-article-card>
      </app-article-link>
    </ng-container>
  </div>

  <h2 class="stb-mb2">{{ "articles.articlesOverview.faqTitle" | fms }}</h2>
  <app-articles-faq></app-articles-faq>
</div>
