import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Observable, map } from "rxjs";
import { RouteKey as PlanRouteKey, routes as planRoutes } from "src/app/modules/pension-plan/routes";
import { IncomeService } from "src/app/services/income/income.service";
import { OnboardingService, OnboardingStepKey } from "src/app/services/onboarding.service";
import { getIsNotNullable } from "src/app/utils/utils";
import { HasPublicPensionInStorebrandService } from "../../../../services/api/has-public-pension-in-storebrand.service";
import { CurrencyFormatterPipe } from "../../pipes/currency-formatter.pipe";

@Component({
  selector: "app-annual-income-change-link",
  templateUrl: "./annual-income-change-link.component.html",
  styleUrls: ["./annual-income-change-link.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnualIncomeChangeLinkComponent {
  public readonly hasAnnualGrossIncome$: Observable<boolean>;
  public readonly annualGrossIncome$: Observable<ReturnType<typeof CurrencyFormatterPipe.prototype.transform>>;
  public readonly isNotPublicContext$: Observable<boolean>;
  public readonly isNotMinePengerPage: boolean;

  constructor(
    private readonly incomeService: IncomeService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    private readonly currencyFormatterPipe: CurrencyFormatterPipe,
    private readonly onboardingService: OnboardingService,
  ) {
    this.hasAnnualGrossIncome$ = this.incomeService.annualGrossIncome$.pipe(
      map((income) => getIsNotNullable(income) && income > 0),
    );

    this.isNotPublicContext$ = this.hasPublicPensionInStorebrandService.isPublicContext$.pipe(
      map((isPublicContext) => !isPublicContext),
    );

    const minePengerPageRoute = `/${planRoutes[PlanRouteKey.Root]}/${planRoutes[PlanRouteKey.MinePenger]}`;

    this.isNotMinePengerPage = !window.location.pathname.includes(minePengerPageRoute);

    this.annualGrossIncome$ = this.incomeService.annualGrossIncome$.pipe(
      map((income) => this.currencyFormatterPipe.transform(income, "end")),
    );
  }

  public openSalaryOnboarding(): void {
    this.onboardingService.open({}, [OnboardingStepKey.Salary]);
  }
}
