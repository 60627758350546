import { Injectable } from "@angular/core";
import { Observable, combineLatestWith, distinctUntilChanged, map } from "rxjs";
import { Nullable } from "../utils/utils";
import { ProfileService, sumExternalSavingsBalance } from "./customer-supplied-data/profile.service";
import { IncomeService } from "./income/income.service";
import { RemoteConfigKey, RemoteConfigService } from "./remote-config.service";

export enum AdvisorType {
  Wealth = "WEALTH",
  Omni = "OMNI",
}

@Injectable({
  providedIn: "root",
})
export class AdvisorQualificationService {
  public advisorType$: Observable<Nullable<AdvisorType>>;
  public isQualifiedForAnyAdvisor$: Observable<boolean>;

  constructor(
    private readonly incomeService: IncomeService,
    private readonly profileService: ProfileService,
    private readonly remoteConfigService: RemoteConfigService,
  ) {
    const externalSavingsBalance$ = this.profileService.externalSavings$.pipe(map(sumExternalSavingsBalance));

    this.advisorType$ = this.incomeService.annualGrossIncome$.pipe(
      combineLatestWith(externalSavingsBalance$, this.remoteConfigService.get$(RemoteConfigKey.AdvisorThresholds)),
      map(([annualGrossIncome, savingsBalance, advisorThresholds]) => {
        if (getIsAdvisorTypeWealth(annualGrossIncome ?? 0, savingsBalance, advisorThresholds)) {
          return AdvisorType.Wealth;
        }

        if (getIsAdvisorTypeOmni(annualGrossIncome ?? 0, savingsBalance, advisorThresholds)) {
          return AdvisorType.Omni;
        }

        return null;
      }),
      distinctUntilChanged(),
    );

    this.isQualifiedForAnyAdvisor$ = this.advisorType$.pipe(map((advisorType) => advisorType != null));
  }
}

function getIsAdvisorTypeWealth(
  annualGrossIncome: number,
  savingsBalance: number,
  advisorThresholds: Config.Advisor.Thresholds,
): boolean {
  const isAnnualGrossIncomeAboveThreshold = annualGrossIncome >= advisorThresholds.minWealthAnnualSalary;
  const isSavingsBalanceAboveThreshold = savingsBalance >= advisorThresholds.minWealthSavingsBalance;

  return isAnnualGrossIncomeAboveThreshold || isSavingsBalanceAboveThreshold;
}

function getIsAdvisorTypeOmni(
  annualGrossIncome: number,
  savingsBalance: number,
  advisorThresholds: Config.Advisor.Thresholds,
): boolean {
  const isAnnualGrossIncomeAboveThreshold = annualGrossIncome >= advisorThresholds.minOmniAnnualSalary;
  const isSavingsBalanceAboveThreshold = savingsBalance >= advisorThresholds.minOmniSavingsBalance;

  return isAnnualGrossIncomeAboveThreshold || isSavingsBalanceAboveThreshold;
}
