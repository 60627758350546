import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { AnyEngagement } from "src/app/services/engagements.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { getNumberWithinBounds } from "src/app/utils/getNumberWithinBounds";
import { getRangeFromBoundary } from "src/app/utils/getRangeFromBoundary";

const DEFAULT_LABEL = "simulationPartTimePercentEndAge.label";

@Component({
  selector: "app-part-time-end-age-form-field",
  templateUrl: "./part-time-end-age-form-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartTimeEndAgeFormFieldComponent implements OnChanges {
  @Input()
  public engagement?: AnyEngagement;
  @Input()
  public readonly engagementSimParams?: Partial<CustomerSuppliedData.SimulationParameters>;
  @Input()
  public label: string = DEFAULT_LABEL;
  @Input()
  public tip?: string;
  @Input()
  public readonly disabled = false;

  @Output()
  public selectionChange = new EventEmitter<number>();
  @Output()
  public highlightChange = new EventEmitter<boolean>();

  public endPayoutAgeRange!: string[];
  public selectedAge!: string;

  private endPayoutAgeRangeAsNumbers!: number[];

  constructor(private readonly pensionPlanService: PensionPlanService) {}

  public ngOnChanges(): void {
    if (!this.engagement) {
      return;
    }

    const boundary = this.engagement.getPayoutAgeRangeBoundary();
    const floorOverride = (this.engagementSimParams?.startPayoutAge ?? NaN) + 1; //Partial withdrawal within the same year is not supported
    this.endPayoutAgeRangeAsNumbers = getRangeFromBoundary(boundary, floorOverride);

    this.endPayoutAgeRange = this.endPayoutAgeRangeAsNumbers.map((i) => i.toString());

    this.selectedAge = String(
      getNumberWithinBounds(
        {
          floor: this.endPayoutAgeRangeAsNumbers.at(0),
          ceil: this.endPayoutAgeRangeAsNumbers.at(-1),
        },
        this.engagementSimParams?.postPensionPartTimeEndAge,
      ),
    );
  }

  public async onPayoutAgeChange(event: Event): Promise<void> {
    const value = (event.target as HTMLFormElement).value;

    const age = Number(value);
    this.selectionChange.emit(age);

    this.pensionPlanService.changePostPensionPartTimeEndAge(age);
  }
}
