import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { addYears, differenceInMonths, differenceInYears, getYear } from "date-fns";
import { produce } from "immer";
import { isPlainObject, merge } from "lodash-es";
import { EMPTY, Observable, iif, of } from "rxjs";
import { catchError, first, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { EndpointService } from "src/app/services/endpoint.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { handleError } from "src/app/utils/http";
import { memoizeObject$, select$, selectObject$ } from "src/app/utils/rxjs/select";
import { ReplayStore } from "src/app/utils/rxjs/store";
import { Nullable, getIsNotNullable } from "src/app/utils/utils";
import { DEFAULT_CUSTOMER_LANGUAGE } from "../constants/business.constants";
import { Monitoring } from "../utils/monitoring";
import { CmRestApiService } from "./api/cm-rest-api.service";
import { ErrorCategory, ErrorsService } from "./errors.service";
import { GlobalRunningJobsService } from "./running-jobs/global-running-jobs.service";
import { KEYCLOAK_NO_RESOURCE_ROLE } from "src/app/list-advisor-accesses/constants";

export interface CustomerName {
  allNames: string[];
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  initials: string;
}

export enum Gender {
  Male = "M",
  Female = "F",
}

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  public readonly customer$: Observable<CustomerMaster.Response.Customer>;
  public readonly dateOfBirth$: Observable<string | undefined>;
  public readonly birthYear$: Observable<number | undefined>;
  public readonly age$: Observable<number | undefined>;
  public readonly ageInMonths$: Observable<number | undefined>;
  public readonly name$: Observable<CustomerName>;
  public readonly employedYear$: Observable<number | undefined>;
  public readonly gender$: Observable<Gender | undefined>;
  public readonly depthConsent$: Observable<boolean | undefined>;
  public readonly navConsent$: Observable<boolean | undefined>;
  public readonly ofaConsent$: Observable<boolean | undefined>;
  public readonly framCustomer$: Observable<boolean | undefined>;

  public readonly emailAddressPrivate$: Observable<string | undefined>;
  public readonly phoneNumberMobile$: Observable<string | undefined>;
  public readonly language$: Observable<CustomerMaster.Language>;

  private readonly _customerObject$: ReplayStore<CustomerMaster.Response.Customer> = new ReplayStore();

  constructor(
    private readonly cmRestApiService: CmRestApiService,
    private readonly endpointService: EndpointService,
    private readonly errorsService: ErrorsService,
    private readonly globalRunningJobsService: GlobalRunningJobsService,
    private readonly keyCloakService: KeycloakService,
  ) {
    this.customer$ = memoizeObject$(this._customerObject$);

    this.dateOfBirth$ = select$(this.customer$, (customer) => customer.dateOfBirth?.value);

    this.birthYear$ = select$(this.dateOfBirth$, (dateOfBirth) =>
      dateOfBirth ? getYear(new Date(dateOfBirth)) : undefined,
    );

    this.age$ = select$(this.customer$, getAge);

    this.ageInMonths$ = select$(this.dateOfBirth$, (dateOfBirth) =>
      dateOfBirth ? differenceInMonths(Date.now(), new Date(dateOfBirth)) : undefined,
    );

    this.name$ = selectObject$(this.customer$, (customer) => {
      const trim = (name: Nullable<string>): string => (name || "").trim();

      const firstName = trim(customer.names?.official?.value?.givenNameOne);
      const middleName = trim(customer.names?.official?.value?.middleName);
      const lastName = trim(customer.names?.official?.value?.lastName);
      const names = [firstName, middleName, lastName].filter(Boolean);
      const fullName = names.join(" ");

      return {
        firstName,
        middleName,
        lastName,
        allNames: names,
        fullName,
        initials: firstName.charAt(0) + lastName.charAt(0),
      };
    });

    this.employedYear$ = select$(this.dateOfBirth$, (dateOfBirth) =>
      dateOfBirth ? getYear(addYears(new Date(dateOfBirth), 25)) : undefined,
    );

    this.gender$ = select$(this.customer$, getGender);

    //depthConsent = Konsernsamtykke
    this.depthConsent$ = select$(this.customer$, ({ consents }) => consents?.enterprise?.value);

    this.navConsent$ = select$(this.customer$, ({ consents }) => consents?.simPensionNationalInsSch?.value);
    this.ofaConsent$ = select$(this.customer$, ({ consents }) => consents?.previouslyAccruedPublicSector?.value);

    this.framCustomer$ = select$(this.customer$, ({ consents }) => consents?.focus?.value);

    this.emailAddressPrivate$ = select$(
      this.customer$,
      ({ contactMethods }) => contactMethods?.emailPrivate?.value?.value,
    );

    this.phoneNumberMobile$ = select$(
      this.customer$,
      ({ contactMethods }) => contactMethods?.mobilePrivate?.value?.value,
    );

    this.language$ = select$(this.customer$, (customer) => customer?.language?.value ?? DEFAULT_CUSTOMER_LANGUAGE);
  }

  private static reportMissingNames(customer: CustomerMaster.Response.Customer): void {
    if (!customer?.names?.official?.value?.givenNameOne || !customer?.names?.official?.value?.lastName) {
      Monitoring.message(`Customer name is missing, null or undefined. Notify CustomerMaster!`, Monitoring.Level.Warn);
    }
  }

  public fetchAndSetCustomerWithLoader(): Observable<CustomerMaster.Response.Customer | undefined> {
    return this.fetchAndSetCustomerWithoutLoader().pipe(
      this.globalRunningJobsService.withLoader("CustomerService"),
      catchError(() => of(undefined)),
    );
  }

  public fetchAndSetCustomerWithoutLoader(): Observable<CustomerMaster.Response.Customer> {
    return this.cmRestApiService.fetchCustomer().pipe(
      tap((customer) => {
        if (!isPlainObject(customer)) {
          throw new Error("Customer is not a plain object.");
        }
      }),
      tap(CustomerService.reportMissingNames),
      catchError((err) => {
        if (err && err.status === 403) {
          return this.keyCloakService.isAdvisorContext$.pipe(
            switchMap((isAdvisor: boolean) => {
              if (isAdvisor) {
                this.errorsService.pushRoleError({
                  message: KEYCLOAK_NO_RESOURCE_ROLE,
                });
              }
              return this.handleFetchCustomerError(err);
            }),
          );
        }
        return this.handleFetchCustomerError(err);
      }),
      tap((res) => res && this._customerObject$.next(res)),
    );
  }

  public patchCustomer(customerPatch: Omit<CustomerMaster.Request.PatchCustomer, "triggerClient">): Observable<void> {
    const patchLocalCustomer$ = this._customerObject$.pipe(
      first(),
      tap((customer) => {
        const patchedCustomer = mergePartialCustomer(customer, mapPatchCustomerToPartialCustomer(customerPatch));

        this._customerObject$.next(patchedCustomer);
      }),
    );

    return patchLocalCustomer$.pipe(
      withLatestFrom(this.keyCloakService.advisorIncognitoMode$),
      switchMap(([_, advisorIncognitoMode]) =>
        iif(
          () => !advisorIncognitoMode,
          this.endpointService.composeCustomerUrl().pipe(
            switchMap((url) =>
              this.endpointService.httpPatch$<CustomerMaster.Request.PatchCustomer, void>(url, {
                body: { ...customerPatch, triggerClient: "NYT" },
                headers: new HttpHeaders({
                  "Content-Type": "application/json",
                }),
              }),
            ),
          ),
          EMPTY,
        ),
      ),
    );
  }

  public handleFetchCustomerError(err: any): Observable<CustomerMaster.Response.Customer> {
    this.errorsService.pushError(ErrorCategory.Customer, {
      title: "customerService.datafetch.error.title",
      message: "customerService.datafetch.error.message",
    });

    return handleError("CustomerService::fetchCustomer", err, {});
  }

  public updateDepthConsent(value: boolean): Observable<void> {
    return this.patchCustomer({ enterprise: value });
  }

  public patchNavAndOfaConsent(navConsent: boolean, ofaConsent: boolean): Observable<void> {
    return this.patchCustomer({ simPensionNationalInsSch: navConsent, previouslyAccruedPublicSector: ofaConsent });
  }
}

export function getCustomerId(customer: CustomerMaster.Response.Customer): string | undefined {
  return customer.identificationNumbers?.find((id) => id.value?.type === "customerId")?.value?.value;
}

export function getNin(customer: CustomerMaster.Response.Customer): string | undefined {
  return customer.identificationNumbers?.find((id) => id.value?.type === "norwegianPin")?.value?.value;
}

export function getGender(customer: CustomerMaster.Response.Customer): Gender | undefined {
  return <Gender>customer.gender?.value?.slice(0, 1).toUpperCase();
}

export function getAge(customer: CustomerMaster.Response.Customer): number | undefined {
  const dateOfBirth = customer.dateOfBirth?.value;

  return getIsNotNullable(dateOfBirth) ? differenceInYears(Date.now(), new Date(dateOfBirth)) : undefined;
}

function mergePartialCustomer(
  customer: CustomerMaster.Response.Customer,
  partialCustomer: Partial<CustomerMaster.Response.Customer>,
): CustomerMaster.Response.Customer {
  return merge({}, customer, partialCustomer);
}

function mapPatchCustomerToPartialCustomer(
  patchCustomer: Partial<CustomerMaster.Request.PatchCustomer>,
): Partial<CustomerMaster.Response.Customer> {
  return produce<Partial<CustomerMaster.Response.Customer>>({}, (draft) => {
    const { enterprise, mobilePrivate, emailPrivate } = patchCustomer;

    if (enterprise !== undefined) {
      draft.consents = addConsent(draft, "enterprise", enterprise);
    }

    if (mobilePrivate) {
      draft.contactMethods = addContactMethod(draft, "mobilePrivate", mobilePrivate);
    }

    if (emailPrivate) {
      draft.contactMethods = addContactMethod(draft, "emailPrivate", emailPrivate);
    }
  });
}

function addConsent(
  customer: Partial<CustomerMaster.Response.Customer>,
  property: keyof CustomerMaster.Consents,
  value: boolean,
): CustomerMaster.Consents {
  return {
    ...(customer.consents || {}),
    [property]: {
      value,
    },
  };
}

function addContactMethod(
  customer: Partial<CustomerMaster.Response.Customer>,
  property: keyof CustomerMaster.ContactMethods,
  value: CustomerMaster.ContactMethod["value"],
): CustomerMaster.ContactMethods {
  return {
    ...(customer.contactMethods || {}),
    [property]: {
      value: {
        value,
      },
    },
  };
}
