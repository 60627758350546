<div class="cards-wrapper">
  <app-dashboard-promo-card
    *ngIf="showCard$ | async"
    [promoCard]="cardData$ | async | fms"
    trackId="dashboard-promo-card-stock-market-2024"
    (cardClick)="onCardClick($event)"
  ></app-dashboard-promo-card>
</div>
<div class="cards-wrapper"></div>

