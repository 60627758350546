import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable, of } from "rxjs";

export enum ExpandableAlertType {
  Default = "default",
  Error = "error",
}

@Component({
  selector: "app-expandable-alert",
  templateUrl: "./expandable-alert.component.html",
  styleUrls: ["./expandable-alert.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableAlertComponent {
  @Input()
  public visible$: Observable<boolean> = of(true);
  @Input()
  public open = true;
  @Input()
  public title!: string;
  @Input()
  public titleOpened!: string;
  @Input()
  public titleClosed!: string;
  @Input()
  public footerTitle!: string;
  @Input()
  public footerHtml!: string;

  public type: ExpandableAlertType = ExpandableAlertType.Default;

  public getTitle(): string {
    const titleOpened = this.titleOpened || this.title;
    const titleClosed = this.titleClosed || titleOpened;

    return this.open ? titleOpened : titleClosed;
  }

  public getIcon(): string {
    return this.type === ExpandableAlertType.Error ? "warning-filled" : "information-filled";
  }
}
