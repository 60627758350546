import { RouterModule } from "@angular/router";
import { NeedsPageComponent } from "src/app/modules/needs/pages/needs-page/needs-page.component";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "behold-din-livsstil",
};

export const routing = RouterModule.forChild([
  {
    path: "",
    component: NeedsPageComponent,
    data: {
      title: "pageTitle.needs",
      breadcrumb: {
        hideAll: true,
      },
    },
  },
]);
