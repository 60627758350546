import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { RouteKey, routes } from "src/app/modules/investment-options/routes";
import { EngagementsWithHoldingsProfilesService } from "src/app/modules/shared/services/contracts-with-holdings.service";
import { Action, ActionComponent } from "../actions/actions.component";

@Component({
  selector: "app-action-investment-options",
  templateUrl: "./action-investment-options.component.html",
  styleUrls: ["./action-investment-options.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionInvestmentOptionsComponent implements ActionComponent {
  @Input()
  public action!: Action;
  private readonly hasInvestableContract$: Observable<boolean>;

  constructor(
    private readonly engagementsWithHoldingsProfilesService: EngagementsWithHoldingsProfilesService,
    private readonly router: Router,
  ) {
    this.hasInvestableContract$ = this.engagementsWithHoldingsProfilesService
      .getEngagementHoldingsProfiles()
      .pipe(map((contracts) => contracts.length > 0));
  }

  public getIsVisible(): Observable<boolean> {
    return this.hasInvestableContract$;
  }

  public goToInvestmentOptionPage(): Promise<boolean> {
    return this.router.navigate([routes[RouteKey.Root]], {
      queryParamsHandling: "merge",
    });
  }
}
