<app-action-dialog>
  <ng-container title>
    {{ "actions.ips.dialog.about.title" | fms }}
  </ng-container>

  <div class="content" [innerHTML]="'actions.ips.dialog.about.content' | fms"></div>

  <ng-container buttons>
    <button stbButton="outlined" mat-dialog-close>
      {{ "dialog.button.close.text" | fms }}
    </button>
  </ng-container>
</app-action-dialog>
