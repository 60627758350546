import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { RouteKey, routes } from "src/app/modules/smart-account/routes";
import { composePaths } from "src/app/utils/router.utils";

@Component({
  templateUrl: "./action-smart-account-about-dialog.component.html",
  styleUrls: ["./action-smart-account-about-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSmartAccountAboutDialogComponent {
  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {}

  public readMore(): Promise<boolean> {
    this.dialog.closeAll();
    return this.navigate(RouteKey.Root);
  }

  private navigate(...paths: string[]): Promise<boolean> {
    return this.router.navigate([composePaths(routes, ...paths)], {
      queryParamsHandling: "merge",
    });
  }
}
