import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { ApiEnvironmentLetter, ApiHomeService } from "src/app/services/api-home.service";
import { FmsService } from "src/app/services/fms.service";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { applyArrayTo } from "../utils/applyArrayTo";
import { AnyObject } from "../utils/object";

export enum RemoteConfigKey {
  AdvisorThresholds = "advisorThresholds",
  Prefetching = "prefetching",
  PensionPayoutThresholds = "pensionPayoutThresholds",
}

export interface RawRemoteConfigValue {
  [ApiEnvironmentLetter.Development]: any;
  [ApiEnvironmentLetter.Production]: any;
  [ApiEnvironmentLetter.Test]: any;
  [ApiEnvironmentLetter.TestStable]: any;
}

export type RawRemoteConfig = AnyObject<RawRemoteConfigValue>;

export interface RemoteConfig extends AnyObject {
  [RemoteConfigKey.AdvisorThresholds]: Config.Advisor.Thresholds;
  [RemoteConfigKey.PensionPayoutThresholds]: Config.PensionPayout.Thresholds;
  [RemoteConfigKey.Prefetching]: Config.Prognosis.Prefetch;
}

type Config = Observable<RemoteConfig | AnyObject>;

@Injectable({
  providedIn: "root",
})
export class RemoteConfigService {
  private readonly config$: Config;

  constructor(
    private readonly fmsService: FmsService,
    private readonly apiHomeService: ApiHomeService,
  ) {
    this.config$ = memoizeObject$(
      this.apiHomeService.environmentLetter$.pipe(
        combineLatestWith(this.fmsService.config$),
        map(applyArrayTo(createRemoteConfigFromEnvironment)),
      ),
    );
  }

  public get$<K extends RemoteConfigKey, T extends RemoteConfig[K]>(key: K, fallback: T | null = null): Observable<T> {
    return this.config$.pipe(map((keys) => (keys[key] as T) ?? fallback));
  }

  public getAllConfigs$(): Config {
    return this.config$;
  }
}

/**
 * Picks every value for the current environment, e.g. in
 * test the object will be transformed as follows:
 *```json
 * {
 *   "foo": {
 *     "t": 5000,
 *     "p": 6000
 *   }
 * }
 * ```
 *
 * =>
 *
 * ```json
 * {
 *   "foo": 5000
 * }
 * ```
 */
export function createRemoteConfigFromEnvironment(
  environment: ApiEnvironmentLetter,
  config: RawRemoteConfig,
): RemoteConfig | AnyObject {
  if (!config) {
    return {};
  }

  return Object.entries(config).reduce(
    (result, entry) => ({
      ...result,
      [entry[0]]: entry[1][environment],
    }),
    {},
  );
}
