import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { FmsService } from "src/app/services/fms.service";

enum FmsKeys {
  Icon = "disclaimer.iconSrc",
  Title = "disclaimer.title",
  ShowMoreButtonText = "disclaimer.showMoreButtonText",
  ShowLessButtonText = "disclaimer.showLessButtonText",
  ShortText = "disclaimer.shortText",
  LongText = "disclaimer.longText",
}

@Component({
  selector: "app-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclaimerComponent implements OnInit {
  @Input()
  public isExpandable = true;

  @Input()
  public isExpanded = false;

  @Input()
  public withTitle = false;

  @Input()
  public withIcon = true;

  public readonly shortDisclaimerText$ = this.fmsService.translateAsync<string>(FmsKeys.ShortText);
  public readonly longDisclaimerText$ = this.fmsService.translateAsync<string>(FmsKeys.LongText, {
    args: { exitUrl: this.router.url },
  });

  public readonly icon$ = this.fmsService.translateAsync<string>(FmsKeys.Icon);
  public readonly title$ = this.fmsService.translateAsync<string>(FmsKeys.Title);

  public isExpanded$!: BehaviorSubject<boolean>;
  public buttonText$!: Observable<string>;

  private readonly showMoreButtonText$ = this.fmsService.translateAsync<string>(FmsKeys.ShowMoreButtonText);
  private readonly showLessButtonText$ = this.fmsService.translateAsync<string>(FmsKeys.ShowLessButtonText);

  constructor(
    private readonly fmsService: FmsService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.isExpanded$ = new BehaviorSubject<boolean>(this.isExpanded);

    this.buttonText$ = this.isExpanded$.pipe(
      switchMap((isExpanded) => (isExpanded ? this.showLessButtonText$ : this.showMoreButtonText$)),
    );
  }

  public toggle(): void {
    this.isExpanded$.next(!this.isExpanded$.getValue());
  }
}
