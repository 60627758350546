/*
 * Keycloak specific constants
 */
import { STBAllowedACR, STBAllowedAMR } from "@storebrand-digital/auth";

/**
 * We still have a bunch of customers that rely on password login, hence the al3.
 * Before removing this, we need to make sure that all customers have migrated to BankID.
 */
export const ALLOWED_ACR: STBAllowedACR[] = [
  "https://id.storebrand.no/authn/al3",
  "https://id.storebrand.no/authn/al4",
];
export const ALLOWED_AMR: STBAllowedAMR[] = ["no:bankid:regular", "no:bankid:mobile", "pwd:mfa", "sdc"];

export const FAILED_LOGIN_REDIRECT_URL = "/privat/pensjon/smart-pensjon";
