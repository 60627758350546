import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { map, mergeMap, Observable, of } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnySavingsAndPensionEngagement } from "src/app/services/prognoses-services/savings-and-pension.service";
import { isItpUnfundedEngagement, isUCITSEngagement } from "src/app/utils/engagement.typeguards";
import { getIsNotNullable } from "src/app/utils/utils";
import { SavingsGraphqlClientService } from "../../graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "../../graphql-clients/utils/apollo-base-wrapper";

const KEY_VALUES = gql`
  fragment KeyValues on KeyValues {
    totalReturn {
      value
    }
  }
`;

const GET_TOTAL_RETURN_QUERY = gql`
  query getTotalReturnQuery($ids: [ID]) {
    savingsEngagement {
      epkContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      pkbContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      fmiContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      ekstrapensjonContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      ekstrapensjonEmploymentContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      epkFleksibelContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      epkFleksibelPkbContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      hybridMedInvesteringsvalgContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      garantiContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      ipaLinkContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      ipoContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      ipsContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      livrenteLinkContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
      fondskontoLinkContracts(ids: $ids) {
        keyValues {
          ...KeyValues
        }
      }
    }
  }
  ${KEY_VALUES}
`;

interface SavingsEngagementQuery {
  savingsEngagement: Graph.SavingsEngagement;
}

interface KeyValueContract {
  keyValues?: Pick<Graph.KeyValues, "totalReturn">;
}

@Injectable({
  providedIn: "root",
})
export class FetchTotalReturnService {
  constructor(private readonly savingsGraphqlClientService: SavingsGraphqlClientService) {}

  public fetchTotalReturn(engagement: AnySavingsAndPensionEngagement): Observable<number | undefined> {
    const id = engagement.getId();
    if (isUCITSEngagement(engagement)) {
      return of(engagement.getReturn());
    }

    if (isItpUnfundedEngagement(engagement)) {
      return of(engagement.getTotalReturn());
    }

    return this.savingsGraphqlClientService
      .query<SavingsEngagementQuery>({
        query: GET_TOTAL_RETURN_QUERY,
        variables: { ids: [id] },
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map((response) => {
          return (Object.values(response.savingsEngagement) as [KeyValueContract[] | undefined])
            .filter((keyValueContracts) => {
              return Array.isArray(keyValueContracts) && keyValueContracts.length > 0;
            })
            .flat()
            .map((contract) => {
              return contract?.keyValues?.totalReturn?.value;
            })
            .filter(getIsNotNullable)
            .at(0);
        }),
      );
  }
}
