import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Observable, firstValueFrom, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import {
  ActionIpsService,
  IpsAction,
} from "src/app/modules/features/pension-actions/components/action-ips/action-ips.service";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { FmsService } from "src/app/services/fms.service";
import { getIsNotNullable, getIsNullable } from "src/app/utils/utils";
import { ActionIpsAboutDialogComponent } from "./action-ips-about-dialog/action-ips-about-dialog.component";

@Component({
  selector: "app-action-ips",
  templateUrl: "./action-ips.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionIpsComponent implements ActionComponent {
  @Input()
  public action!: Action;

  public readonly IpsAction = IpsAction;
  public readonly ipsAction$ = this.actionIpsService.getIpsAction();

  public readonly depositThisYear$: Observable<number | undefined>;

  constructor(
    private readonly actionIpsService: ActionIpsService,
    private readonly dialog: MatDialog,
    private readonly fmsService: FmsService,
  ) {
    this.depositThisYear$ = this.actionIpsService.getFirstEngagement().pipe(
      mergeMap((engagement) => {
        if (getIsNullable(engagement)) {
          return of(undefined);
        }
        return this.actionIpsService.getDepositThisYear(engagement);
      }),
    );
  }

  public getIsVisible(): Observable<boolean> {
    return this.ipsAction$.pipe(map((action) => action !== IpsAction.Unqualified));
  }

  public readMoreDialog(): void {
    this.dialog.open<ActionIpsAboutDialogComponent>(ActionIpsAboutDialogComponent, {
      panelClass: "no-max-width",
    });
  }

  public async openStartIpsApp(): Promise<void> {
    window.open(
      await firstValueFrom(this.fmsService.translateAsync<string>("actions.ips.dialog.start.buttonUrl")),
      "_blank",
    );
  }

  public async openMonthlySavingsApp(): Promise<void> {
    const engagement = await firstValueFrom(this.actionIpsService.getFirstEngagement());

    if (getIsNotNullable(engagement)) {
      return this.actionIpsService.openMonthlySavingsApp(engagement);
    }
  }

  public async openLumpSumApp(): Promise<void> {
    const engagement = await firstValueFrom(this.actionIpsService.getFirstEngagement());

    if (getIsNotNullable(engagement)) {
      return this.actionIpsService.openLumpSumApp(engagement);
    }
  }
}
