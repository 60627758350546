import { combineLatest, first, map, Observable } from "rxjs";
import { EngagementName } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { ExternalSavingsContractWithId } from "src/app/services/customer-supplied-data/profile.service";
import { select } from "src/app/utils/rxjs/select";
import { Nullable } from "src/app/utils/utils";
import { PayoutDuration } from "./generic-engagement.model";
import { GraphEngagement } from "./graph-engagement.model";
import { DEFAULT_PAYOUT_DURATION } from "src/app/constants/business.constants";

export enum ExternalSavingsEngagementEvent {
  Periodic = "periodic",
  Single = "single",
}

export enum ExternalSavingsEngagementType {
  Bank = "bank",
  Fond = "fond",
}

export class ExternalSavingsEngagement extends GraphEngagement<
  ExternalSavingsContractWithId,
  Graph.FundOpenPrognosisInput | Graph.BankOpenPrognosisInput
> {
  public override getContracts(): unknown[] {
    return [];
  }
  public override getContractNumber(): string {
    return this.contract?.contractNumber ?? "";
  }
  public override getIdentifier(): string {
    return this.contract.id;
  }
  public override getPayoutDuration(): PayoutDuration {
    return { years: this.contract.durationYears ?? DEFAULT_PAYOUT_DURATION, months: 0 };
  }
  public override isActive(): boolean {
    return false;
  }
  public override isLifelongPayout(): boolean {
    return !!this?.prognosis?.lifeLong;
  }

  /** @deprecated Use getNameAsync instead */
  public override getName(): EngagementName {
    return select(this.getNameAsync());
  }

  public override getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();

    /**
     * FMS-key composition resolves to:
     *
     * ExternalSavingsEngagement.bank.periodic
     * ExternalSavingsEngagement.bank.single
     * ExternalSavingsEngagement.fond.periodic
     * ExternalSavingsEngagement.fond.single
     * ExternalSavingsEngagement.link.periodic
     * ExternalSavingsEngagement.link.single
     * ExternalSavingsEngagement.bank.periodic.description
     * ExternalSavingsEngagement.bank.single.description
     * ExternalSavingsEngagement.fond.periodic.description
     * ExternalSavingsEngagement.fond.single.description
     * ExternalSavingsEngagement.link.periodic.description
     * ExternalSavingsEngagement.link.single.description
     */
    const type = this.contract.type;
    const event = this.getEvent();
    const nameKey = `ExternalSavingsEngagement.${type}.${event}`;
    const descriptionKey = `${nameKey}.description`;

    return combineLatest([
      fmsService.translateAsync<string>(nameKey),
      fmsService.translateAsync<string>(descriptionKey, { logEmpty: false }),
    ]).pipe(
      first(),
      map(([name, description]) => ({
        name,
        description,
        supplier: this.contract.contractNumber,
        payer: this.contract.addlInfo,
      })),
    );
  }

  public getEvent(): ExternalSavingsEngagementEvent {
    return (this.contract?.periodicDeposit ?? 0) > 0
      ? ExternalSavingsEngagementEvent.Periodic
      : ExternalSavingsEngagementEvent.Single;
  }

  public getType(): ExternalSavingsEngagementType | null {
    const type = <ExternalSavingsEngagementType>this.contract.type;

    return Object.values(ExternalSavingsEngagementType).includes(type) ? type : null;
  }

  public override getBalance(): Nullable<number> {
    return this.contract?.balance;
  }

  public override isSavingsEngagement(): boolean {
    return true;
  }

  public override hasChangeableProfile(): boolean {
    return false;
  }
}
