import { Pipe, PipeTransform } from "@angular/core";
import { Monitoring } from "src/app/utils/monitoring";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

@Pipe({ name: "textReplace" })
export class TextReplace implements PipeTransform {
  transform(text: string, searchValue: string | { [key: string]: string }, replaceValue?: string | number): string {
    if (typeof text === "string" && (typeof searchValue === "string" || typeof searchValue === "object")) {
      if (typeof searchValue === "string" && replaceValue != null) {
        return text.replace(new RegExp(searchValue, "g"), replaceValue.toString());
      } else if (
        searchValue === Object(searchValue) &&
        !Array.isArray(searchValue) &&
        typeof searchValue !== "function" &&
        typeof searchValue !== "string"
      ) {
        return Object.keys(searchValue).reduce((acc, key) => acc.replace(new RegExp(key, "g"), searchValue[key]), text);
      }
    }
    Monitoring.error(
      new Error(
        `Expected string in text-replace-pipe, got
      text: ${JSON.stringify(text)} (${typeof text})
      searchValue: ${JSON.stringify(searchValue)} (${typeof searchValue})
      replaceValue: ${JSON.stringify(replaceValue)} (${typeof replaceValue})`,
      ),
      { ignore: isStageLocalhost() },
    );
    return text;
  }
}
