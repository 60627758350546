import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouteKey, routes } from "src/app/modules/articles/routes";
import { composePaths } from "src/app/utils/router.utils";

@Component({
  selector: "app-contact-us-page",
  templateUrl: "./contact-us-page.component.html",
  styleUrls: ["./contact-us-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsPageComponent {
  public readonly articlesRoute = composePaths(routes, RouteKey.Root);

  public openSecureMessageInNewWindow($event: any, url: string): void {
    $event.preventDefault();

    const width = 500;
    const centerLeft = window.outerWidth / 2 + window.screenX - width / 2;
    const height = 550;
    const centerTop = window.outerHeight / 2 + window.screenY - height / 2;

    window.open(url, "_blank", `width=${width}, height=${height}, left=${centerLeft}, top=${centerTop}, resizable=no`);
  }
}
