/*
 * Copyright 2017 Red Hat, Inc. and/or its affiliates
 * and other contributors as indicated by the @author tags.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Injectable } from "@angular/core";
import { AuthClient, createAuthClient } from "@storebrand-digital/auth";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, defer, from, of } from "rxjs";
import {
  combineLatestWith,
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from "rxjs/operators";
import { cmidRegex } from "src/app/constants/regex.constants";
import { Monitoring } from "src/app/utils/monitoring";
import { memoize$, memoizeObject$, select$ } from "src/app/utils/rxjs/select";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";
import { environment } from "src/environments/environment";
import { ALLOWED_ACR } from "../constants/auth.constants";
import { INACTIVE_WARNING_TRESHOLD } from "../constants/business.constants";
import { getIsEveryItemTrue } from "../utils/array";
import { Log } from "../utils/log";
import { getIsNotNullable } from "../utils/utils";
import { SessionStorageService } from "./session-storage.service";
import { getKeycloakOptions } from "./keycloak.utils";

const ADVIOR_INCOGNITO_MODE_STORAGE_KEY = "advisorIncognitoMode";
const ADVISOR_PROVIDER_URL = "/auth/realms/corporate";

enum KeycloakError {
  InteractionRequired = "interaction_required",
}

export interface Cmid {
  fromToken: {
    root$: Observable<STBKeycloakIdTokenParsed["cm_id"] | undefined>;
    onBehalfOf$: Observable<Auth.Advisor.BearerToken["on_behalf_of"]["cm_id"] | undefined>;
  };
  fromUrl: () => string | undefined;
  user$: Observable<string | undefined>;
}

@Injectable()
export class KeycloakService {
  public readonly authClient: AuthClient = createAuthClient(getKeycloakOptions());
  public readonly instance: AuthClient["instance"];
  public readonly parsedIdToken$: Observable<Auth.IdToken | null>;
  public readonly advisorIncognitoMode$: Observable<boolean>;
  public readonly advisorSignature$: Observable<string | undefined>;
  public readonly isAdvisorContext$: Observable<boolean>;
  public readonly isAuthLevel3or4$: Observable<boolean>;
  public readonly cmid: Cmid;
  public readonly amrFromToken$: Observable<string>;
  public readonly acrFromToken$: Observable<string>;
  public readonly timeoutReached$: Observable<number | undefined>;
  public readonly timeoutReset$: Subject<void> = new Subject();

  protected readonly _advisorIncognitoMode$ = new BehaviorSubject<boolean>(
    this.sessionStorageService.get<boolean>(ADVIOR_INCOGNITO_MODE_STORAGE_KEY, true),
  );

  private readonly _parsedRefreshToken$ = new ReplaySubject<Auth.RefreshToken>();
  private readonly _parsedIdToken$ = new BehaviorSubject<Auth.IdToken | null>(null);
  private readonly _parsedBearerToken$ = new BehaviorSubject<Auth.BearerToken | null>(null);
  private readonly _forcedAdvisorMode$ = new BehaviorSubject<boolean>(false);
  private readonly parsedRefreshToken$: Observable<Auth.RefreshToken>;

  constructor(private readonly sessionStorageService: SessionStorageService) {
    this.instance = this.authClient.instance;

    this.parsedIdToken$ = memoizeObject$(this._parsedIdToken$);

    this.parsedRefreshToken$ = memoizeObject$(this._parsedRefreshToken$);

    this.advisorSignature$ = select$(
      this.parsedIdToken$,
      (parsedIdToken) => (KeycloakService.isAdvisorIdToken(parsedIdToken) && parsedIdToken.signature) || undefined,
    );

    this.isAdvisorContext$ = select$(
      this.parsedIdToken$.pipe(combineLatestWith(this._forcedAdvisorMode$)),
      ([parsedIdToken, forcedAdvisorMode]) =>
        forcedAdvisorMode || parsedIdToken?.iss?.includes(ADVISOR_PROVIDER_URL) || Boolean(this.cmid.fromUrl()),
    );

    this.advisorIncognitoMode$ = combineLatest([
      this._advisorIncognitoMode$.asObservable(),
      this.isAdvisorContext$,
    ]).pipe(map(getIsEveryItemTrue), distinctUntilChanged(), shareReplay(1));

    this.isAuthLevel3or4$ = environment.offlineMode
      ? of(true)
      : select$(this.parsedIdToken$, (parsedIdToken) => {
          const acr = parsedIdToken?.acr;
          return getIsNotNullable(acr) && ALLOWED_ACR.includes(acr);
        });

    const cmidFromTokenRoot$ = memoize$(
      this.parsedIdToken$.pipe(map((parsedIdToken) => (<Auth.User.IdToken>parsedIdToken)?.cm_id)),
    );
    const cmidFromTokenOnBehalfOf$ = select$(
      this._parsedBearerToken$,
      (parsedBearerToken) =>
        (KeycloakService.isAdvisorBearerToken(parsedBearerToken) && parsedBearerToken?.on_behalf_of?.cm_id) ||
        undefined,
    );
    const cmidUser$: Cmid["user$"] = select$(
      cmidFromTokenOnBehalfOf$.pipe(combineLatestWith(cmidFromTokenRoot$)),
      ([cmidFromTokenOnBehalfOf, cmidFromTokenRoot]) =>
        cmidFromTokenOnBehalfOf ?? this.cmid.fromUrl() ?? cmidFromTokenRoot,
    );

    this.cmid = {
      fromToken: {
        root$: cmidFromTokenRoot$,
        onBehalfOf$: cmidFromTokenOnBehalfOf$,
      },
      fromUrl: (): string | undefined => {
        const cmid = window.location.search.match(cmidRegex);
        return cmid?.[1] ?? undefined;
      },
      user$: cmidUser$,
    };

    // Authentication Methods Reference
    // (identifiers for authentication method i.e. "no:bankid:mobile")
    this.amrFromToken$ = select$(this.parsedIdToken$, (idTokenParsed) => idTokenParsed?.amr || "");

    // Authentication Level Reference
    // (identifiers for authentication level i.e. "https://id.storebrand.no/authn/al4")
    this.acrFromToken$ = select$(this.parsedIdToken$, (idTokenParsed) => idTokenParsed?.acr || "");

    this.timeoutReached$ = this.parsedRefreshToken$.pipe(
      filter((token) => !!token && !!token.exp),
      map(({ exp }) => exp),
      filter(getIsNotNullable),
      distinctUntilChanged(),
      tap(() => this.timeoutReset$.next()),
      // switchMap is used to make sure the 'delay' operator triggered from the previous emission is aborted
      switchMap((newExp) => of(newExp).pipe(delay(KeycloakService.calculateDelayTime(newExp)))),
    );

    this.instance.onReady = (): void => {
      this.initLoggingAndUpdateTokenSubjects();
    };
  }

  private static calculateDelayTime(exp: number): number {
    const dateNow = Math.floor(Date.now() / 1000);
    return 1000 * (exp - INACTIVE_WARNING_TRESHOLD * 60 - dateNow);
  }

  private static isMutedError(error: KeycloakError): boolean {
    return [KeycloakError.InteractionRequired].includes(error);
  }

  private static isAdvisorIdToken(token: Auth.IdToken | null): token is Auth.Advisor.IdToken {
    return token?.signature != null;
  }

  private static isAdvisorBearerToken(token: Auth.BearerToken | null): token is Auth.Advisor.BearerToken {
    return getIsNotNullable(token?.on_behalf_of);
  }

  public async initLoggingAndUpdateTokenSubjects(): Promise<void> {
    this.authClient.instance.onAuthRefreshSuccess = (): void => {
      this.logKeycloakInfo("onAuthRefreshSuccess");
      this.updateTokenSubjects();
    };

    this.authClient.instance.onAuthLogout = (): void => {
      this.sessionStorageService.wipe();
    };

    try {
      this.updateTokenSubjects();

      this.logKeycloakInfo("init");
    } catch (error: any) {
      if (!KeycloakService.isMutedError(error)) {
        Monitoring.error("Failed to initialize Keycloak", {
          extras: { error },
          ignore: isStageLocalhost(),
        });
      }

      throw error;
    }
  }

  public stepUpAuth(): void {
    this.authClient.stepUp();
  }

  public getAndUpdateToken(): Observable<string | undefined | unknown> {
    if (!this.authClient.authenticated) {
      return from(this.login());
    }

    // Defer to retry promises https://github.com/ReactiveX/rxjs/issues/1596
    return defer(() => this.authClient.getToken());
  }

  public toggleForcedAdvisorMode(): void {
    this._forcedAdvisorMode$.next(!this._forcedAdvisorMode$.getValue());
  }

  public setAdvisorIncognitoMode(advisorIncognitoMode: boolean): void {
    this._advisorIncognitoMode$.next(advisorIncognitoMode);
    this.sessionStorageService.set(ADVIOR_INCOGNITO_MODE_STORAGE_KEY, advisorIncognitoMode);
  }

  public getAdvisorIncognitoMode(): Observable<boolean> {
    return this.advisorIncognitoMode$;
  }

  public getTokenTimestamps(method = "getTokenDates"): string {
    const { idTokenParsed, refreshTokenParsed } = this.authClient.instance;
    const toDate = (timestamp: number | undefined): Date => new Date(parseInt(timestamp + "000", 10));
    return `[KeycloakService::${method}]
  idToken: ${toDate(idTokenParsed?.exp)} (${idTokenParsed?.exp})
  refreshToken: ${toDate(refreshTokenParsed?.exp)} (${refreshTokenParsed?.exp})`;
  }

  public authenticated(): boolean | undefined {
    return this.authClient.authenticated;
  }

  public login(): Promise<unknown> {
    return this.authClient.login();
  }

  public getToken(): Promise<string> {
    return this.authClient.getToken();
  }

  public logout(logoutOptions?: import("keycloak-js").KeycloakLogoutOptions): Promise<unknown> {
    this.sessionStorageService.wipe();
    return this.authClient.logout(logoutOptions);
  }

  public init(): Promise<boolean> {
    return this.authClient.init();
  }

  private updateTokenSubjects(): void {
    this._parsedRefreshToken$.next(<Auth.RefreshToken>this.authClient.instance.refreshTokenParsed);
    this._parsedIdToken$.next(<Auth.IdToken>this.authClient.instance.idTokenParsed);
    this._parsedBearerToken$.next(<Auth.BearerToken>this.authClient.instance.tokenParsed);
  }

  private logKeycloakInfo(method: string): void {
    Log.important(this.getTokenTimestamps(method));
  }
}
