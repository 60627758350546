import { Inject, Injectable } from "@angular/core";
import { add } from "date-fns";
import { range } from "lodash-es";
import { Observable, combineLatest } from "rxjs";
import { delay, map, withLatestFrom } from "rxjs/operators";
import { DEFAULT_MAX_LIFE_EXPECTANCY_AGE, DEFAULT_SIMULATION_AGE } from "src/app/constants/business.constants";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { memoize$, select$, selectObject$ } from "src/app/utils/rxjs/select";
import { Nullable, getIsNullable } from "src/app/utils/utils";
import {
  CONTRACTS_CHART_COLORS_TOKEN,
  ContractsChartColors,
  GeneralChartColors,
} from "../constants/highcharts-colors.constants";
import { PublicSalaryEngagement } from "../models/engagements/public-salary-engagement.model";
import { toArrayOrEmpty } from "../utils/array";
import { getEngagementCategory, isNonInflationAdjustedEngagement } from "../utils/engagement.utils";
import { NotNullProps } from "../utils/types";
import { getRetirementDateByAge } from "./api/withdrawal-periods-variables.utils";
import { AnyEngagement, EngagementsService } from "./engagements.service";
import { InternalSavingsService } from "./internal-savings.service";
import { PublicPensionService } from "./prognoses-services/public-pension.service";
import { StartPayoutAgeService } from "./start-payout-age.service";

type RequiredPayoutPlanProps = "age" | "amount";
type PayoutPlan = Omit<Graph.PayoutPlan, RequiredPayoutPlanProps> &
  NotNullProps<Required<Pick<Graph.PayoutPlan, RequiredPayoutPlanProps>>>;

interface NamedPayoutPlan {
  name: (age?: number) => string;
  contractIdentifier: string;
  payoutPlan: PayoutPlan[];
  simulationCmsKeys: Nullable<string>[];
  category: string;
  contractColor: string;
  index: number;
  showNominalValueAtPaymentHint: boolean;
}

interface PayoutPlanConfig {
  contractColor: GeneralChartColors;
  index: number;
}

@Injectable({
  providedIn: "root",
})
export class PayoutplanService {
  public readonly payoutPlan$: Observable<PayoutPlan.PayoutPlanPeriod[]>;
  public readonly payoutPlanLimitedByMaxLifeExpectancy$: Observable<PayoutPlan.PayoutPlanPeriod[]>;
  public readonly firstYearTotal$: Observable<number>;
  public readonly firstYearFullWithdrawalTotal$: Observable<number>;
  public readonly firstYearPartialWithdrawalTotal$: Observable<number>;
  public readonly firstMonthTotal$: Observable<number>;
  public readonly averageTenYearsTotal$: Observable<number | undefined>;
  public readonly allYearsTotal$: Observable<number>;

  constructor(
    private readonly engagementsService: EngagementsService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly internalSavingsService: InternalSavingsService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
    private readonly profileService: ProfileService,
    private readonly publicPensionService: PublicPensionService,
    @Inject(CONTRACTS_CHART_COLORS_TOKEN)
    private readonly contractsGraphColors: ContractsChartColors[],
  ) {
    const incomeEngagements$ = this.publicPensionService.engagements$.pipe(
      map((engagements) => engagements.map((e) => new PublicSalaryEngagement(e))),
    );

    this.payoutPlan$ = selectObject$(
      combineLatest([
        this.profileService.internalSavings$,
        this.engagementsService.allEngagements$,
        incomeEngagements$,
        this.startPayoutAgeService.getStartPayoutAge(),
      ]),
      ([, allEngagements, incomeEngagements, startPayoutAge]) =>
        mergePayoutPlans(
          this.mapToPayoutPlan(allEngagements, startPayoutAge),
          this.mapToPayoutPlan(incomeEngagements, startPayoutAge, {
            contractColor: GeneralChartColors.AnnualGrossIncomeColumn,
            index: Number.MAX_SAFE_INTEGER,
          }),
        ),
    );

    this.payoutPlanLimitedByMaxLifeExpectancy$ = this.payoutPlan$.pipe(
      map((periode) => periode.filter(({ endAge }) => endAge <= DEFAULT_MAX_LIFE_EXPECTANCY_AGE)),
    );

    /**
     * TRM-933 and TRM-1164 introduces complexity to this stream. The number
     * is consumed by multiple parts of the application that expect it to
     * represent the actual year of retirement. As such it must be tied
     * to the global simulation parameters which are considered to more
     * accurately represent the year of 100% retirement.
     *
     * All changes to this field must include a lookup of usages and
     * verification that the expectations of the consumers are met.
     */
    const payoutPlanAndPayoutAge$ = this.payoutPlan$.pipe(
      /**
       * Delay when life expectancy range changes (simluation age <67),
       * and give isCurrentYearLoaded$ time to emit. This could probably
       * be a conditional delay (e.g. delayWhen()).
       */
      delay(0),
      this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe(),
      withLatestFrom(this.storebrandOnlyService.getIsEnabled(), this.startPayoutAgeService.getStartPayoutAge()),
      map(([payoutPlanPeriods, storebrandOnly, startPayoutAge]) => {
        const newStartPayoutAge = storebrandOnly ? getFirstPayoutAge(payoutPlanPeriods) : startPayoutAge;
        const startPayoutAgePartialWithdrawal = getFirstPayoutAge(payoutPlanPeriods);

        return {
          payoutPlanPeriods,
          newStartPayoutAge,
          startPayoutAgeFullWithdrawal: startPayoutAge,
          startPayoutAgePartialWithdrawal,
        };
      }),
    );

    this.firstYearTotal$ = memoize$(
      payoutPlanAndPayoutAge$.pipe(
        map(({ payoutPlanPeriods, newStartPayoutAge }) => getTotalForPayoutAge(payoutPlanPeriods, newStartPayoutAge)),
      ),
    );

    this.firstYearFullWithdrawalTotal$ = memoize$(
      payoutPlanAndPayoutAge$.pipe(
        map(({ payoutPlanPeriods, startPayoutAgeFullWithdrawal }) =>
          getTotalForPayoutAge(payoutPlanPeriods, startPayoutAgeFullWithdrawal),
        ),
      ),
    );

    this.firstYearPartialWithdrawalTotal$ = memoize$(
      payoutPlanAndPayoutAge$.pipe(
        map(({ payoutPlanPeriods, startPayoutAgePartialWithdrawal }) =>
          getTotalForPayoutAge(payoutPlanPeriods, startPayoutAgePartialWithdrawal),
        ),
      ),
    );

    this.averageTenYearsTotal$ = memoize$(
      payoutPlanAndPayoutAge$.pipe(
        map(({ payoutPlanPeriods, newStartPayoutAge }) => {
          const TEN_YEARS = 10;

          const relevantPayoutPlanPeriods = payoutPlanPeriods.filter(
            (period) =>
              period.startAge >= newStartPayoutAge &&
              period.startAge < newStartPayoutAge + TEN_YEARS &&
              period.total !== 0,
          );

          const hasTenYearsOfPayout = relevantPayoutPlanPeriods.length === TEN_YEARS;

          if (hasTenYearsOfPayout) {
            const payoutSum: number = relevantPayoutPlanPeriods.reduce((sum, period) => sum + period.total, 0);

            return payoutSum / TEN_YEARS;
          }

          return undefined;
        }),
      ),
    );

    this.firstMonthTotal$ = select$(this.firstYearTotal$, (firstYearTotal) => firstYearTotal / 12);

    this.allYearsTotal$ = select$(this.payoutPlan$, (payoutPlan) =>
      payoutPlan.reduce((sum, ageEntry) => sum + ageEntry.total, 0),
    );
  }

  private readonly getNamedPayoutPlans = (engagements: AnyEngagement[]): NamedPayoutPlan[] =>
    engagements
      // only include agreements with payout plan age
      .filter((engagement) => engagement.getPayoutPlan()?.length > 0)
      // only include agreements that are customer pension
      .filter((engagement) => this.internalSavingsService.isCustomerPension(engagement))
      .reverse()
      .map((engagement, index) => ({
        name: (age?: number) => getEngagementName(engagement, age),
        payoutPlan: engagement.getPayoutPlan().map(toTypesafePayoutPlan),
        contractIdentifier: engagement.getIdentifier(),
        simulationCmsKeys: engagement.getSimulationStatus().map(({ key }) => key),
        category: getEngagementCategory(engagement, this.profileService.hasAfp),
        contractColor: this.getContractGraphColorByIndex(index),
        showNominalValueAtPaymentHint: isNonInflationAdjustedEngagement(engagement),
      }))
      .reverse()
      .map((item, index) => ({
        ...item,
        index: index + 1,
      }));

  private mapToPayoutPlan(
    engagements: AnyEngagement[],
    startPayoutAge: number,
    config?: PayoutPlanConfig,
  ): PayoutPlan.PayoutPlanPeriod[] {
    const namedPayoutPlans = this.getNamedPayoutPlans(engagements);
    const showNominalValueAtPaymentHint = engagements.map((engagement) => {
      return {
        contractIdentifier: engagement.getIdentifier(),
        showNominalValueAtPaymentHint: isNonInflationAdjustedEngagement(engagement),
      };
    });

    if (getIsNullable(startPayoutAge)) {
      return [];
    }

    if (namedPayoutPlans.length === 0) {
      return [createPayoutPlanPeriod(startPayoutAge)];
    }

    return namedPayoutPlans.reduce(
      (acc, { payoutPlan: payoutPlan, name, contractIdentifier, contractColor, category, index }) => {
        const low = acc[0].startAge;

        payoutPlan.forEach((payoutPlanEntry) => {
          // The data points for the certain age that have already been mapped
          const existingDataForAge = acc[payoutPlanEntry.age - low];

          // The changes to be done for each iterated agreement for each age entry
          const total = existingDataForAge.total + payoutPlanEntry.amount;
          const newPayoutEntry: PayoutPlan.PayoutplanPayout = {
            name: name(payoutPlanEntry.age),
            amount: payoutPlanEntry.amount,
            contractIdentifier,
            contractColor: config?.contractColor ?? contractColor,
            category,
            index: config?.index ?? index,
            showNominalValueAtPaymentHint:
              showNominalValueAtPaymentHint.find((item) => item.contractIdentifier === contractIdentifier)
                ?.showNominalValueAtPaymentHint ?? false,
          };
          const payoutPlanPayouts = toArrayOrEmpty(existingDataForAge.payoutPlanPayouts).concat(newPayoutEntry);

          // Reassign the entry for that age to include the data regarding the iterated agreement
          // eslint-disable-next-line fp/no-mutation
          acc[payoutPlanEntry.age - low] = {
            ...existingDataForAge,
            total,
            payoutPlanPayouts,
          };
        });

        return acc;
      },
      mapNamedPayoutPlanToPayoutPlanPeriods(namedPayoutPlans),
    );
  }

  private getContractGraphColorByIndex(index: number): string {
    const loopableIndex = index % this.contractsGraphColors.length;
    return this.contractsGraphColors[loopableIndex];
  }
}

export function getFirstPayoutAge(payoutPlan: PayoutPlan.PayoutPlanPeriod[]): number {
  const payouts = payoutPlan.filter(({ total }) => total > 0);
  const [firstPayout] = payouts;

  return firstPayout?.startAge || DEFAULT_SIMULATION_AGE;
}

export function filterPayoutPlanPeriodsByRange(
  payoutPlanPeriods: PayoutPlan.PayoutPlanPeriod[],
  rangeLimit: number[],
): PayoutPlan.PayoutPlanPeriod[] {
  const rangeEdges = getEdgesFromRange(rangeLimit);
  const payoutPlanRange = payoutPlanPeriods.map(({ startAge }) => startAge);
  const payoutPlanEdges = getEdgesFromRange(payoutPlanRange);

  const ageRange = range(Math.min(payoutPlanEdges.min, rangeEdges.min), rangeEdges.max + 1);

  return ageRange.map((age) => findOrCreatePayoutPlanPeriod(payoutPlanPeriods, age));
}

function findOrCreatePayoutPlanPeriod(
  payoutPlanPeriods: PayoutPlan.PayoutPlanPeriod[],
  age: number,
): PayoutPlan.PayoutPlanPeriod {
  const payoutPlanPeriod = payoutPlanPeriods.find(({ startAge }) => startAge === age);

  return payoutPlanPeriod || createPayoutPlanPeriod(age);
}

function getTotalForPayoutAge(payoutPlanPeriods: PayoutPlan.PayoutPlanPeriod[], startPayoutAge: number): number {
  if (startPayoutAge) {
    const { total } = findOrCreatePayoutPlanPeriod(payoutPlanPeriods, startPayoutAge);
    return total;
  }
  return 0;
}

function createPayoutPlanPeriod(age: number): PayoutPlan.PayoutPlanPeriod {
  return {
    startAge: age,
    endAge: age,
    total: 0,
    payoutPlanPayouts: [],
  };
}

function getEdgesFromRange(numberRange: number[]): { min: number; max: number } {
  return {
    min: Math.min(...numberRange),
    max: Math.max(...numberRange),
  };
}

function mapNamedPayoutPlanToPayoutPlanPeriods(namedPayoutPlans: NamedPayoutPlan[]): PayoutPlan.PayoutPlanPeriod[] {
  const ages = namedPayoutPlans.flatMap(mapNamedPayoutPlanToAgeRange);
  const edges = getEdgesFromRange(ages);

  return range(edges.min, edges.max + 1).map(createPayoutPlanPeriod);
}

function mapNamedPayoutPlanToAgeRange(namedPayoutPlan: NamedPayoutPlan): number[] {
  return namedPayoutPlan.payoutPlan.map(({ age }) => age);
}

export function getSimulationDateByAge(
  simulationAge: number,
  dateOfBirth: string,
  retirementAges: Graph.RetirementAge[],
): Date {
  const simulationParamsDateByAge = getRetirementDateByAge(simulationAge, retirementAges);

  const calculatedSimulationDateByAge = add(new Date(dateOfBirth), {
    years: simulationAge,
  });

  return new Date(simulationParamsDateByAge ? simulationParamsDateByAge : calculatedSimulationDateByAge);
}

export function mergePayoutPlans(
  first: PayoutPlan.PayoutPlanPeriod[],
  ...rest: PayoutPlan.PayoutPlanPeriod[][]
): PayoutPlan.PayoutPlanPeriod[] {
  const mergedPlans = first.map((period) =>
    rest
      .flatMap((arr) => arr)
      .filter((el) => el.startAge === period.startAge && el.endAge === period.endAge)
      .reduce(mergePayoutPlanPeriod, period),
  );

  const uniquePeriodsInRest = rest
    .flatMap((arr) => arr)
    .filter((restPeriode) => !first.some((firstPeriode) => firstPeriode.endAge === restPeriode.endAge));

  return mergedPlans.concat(uniquePeriodsInRest);
}

function mergePayoutPlanPeriod(
  first: PayoutPlan.PayoutPlanPeriod,
  second: PayoutPlan.PayoutPlanPeriod,
): PayoutPlan.PayoutPlanPeriod {
  return {
    ...first,
    payoutPlanPayouts: toArrayOrEmpty(first.payoutPlanPayouts).concat(toArrayOrEmpty(second.payoutPlanPayouts)),
    total: first.total + second.total,
  };
}

function getEngagementName(engagement: AnyEngagement, age?: number): string {
  const { name, payer } = engagement.getName(age);
  return payer ? `${name} (${payer})` : name;
}

function toTypesafePayoutPlan(payoutPlan: Graph.PayoutPlan): PayoutPlan {
  const { age, amount } = payoutPlan;
  return { ...payoutPlan, age: age ?? 0, amount: amount ?? 0 };
}
