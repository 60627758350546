import { HYPHEN_SEPARATOR } from "src/app/constants/technical.constants";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FormatCurrencyStyle, formatCurrencyValue, Nullable } from "src/app/utils/utils";

export interface PresentableEngagementItemField {
  label: string;
  value?: Nullable<string>;
}

export interface PresentableEngagementItem {
  title: string;
  description: string;
  iconSrc: string;
  fields: PresentableEngagementItemField[];
  header?: string;
  number: number;
  hideNumber?: boolean;
}

export interface InitiativeEngagementItem {
  title: string;
  description: string;
  iconSrc: string;
  values: Nullable<string>[];
  header?: string;
  hideNumber?: boolean;
}

export function getPresentableEngagementItems(
  items: InitiativeEngagementItem[],
  headers: string[],
): PresentableEngagementItem[] {
  return items.map(({ values, ...rest }, index) => ({
    ...rest,
    number: index + 1,
    fields: getPresentableEngagementItemFields(values, headers),
  }));
}

function getPresentableEngagementItemFields(
  values: Nullable<string>[],
  headers: string[],
): PresentableEngagementItemField[] {
  return values.map((value, i) => ({
    label: headers[i],
    value,
  }));
}

export function sortEngagementsByHighestTotalPayout<T extends AnyEngagement>(engagements: T[]): T[] {
  return [...engagements].sort((a, b) => {
    const firstElement = a.getTotalPayout() || 0;
    const secondElement = b.getTotalPayout() || 0;

    return secondElement - firstElement;
  });
}

export function getEngagementTitle<T extends AnyEngagement>(engagement: T): string {
  const { name } = engagement.getName();

  return name;
}

export function getEngagementDescription<T extends AnyEngagement>(engagement: T): string {
  const { payer, supplier } = engagement.getName();

  if (supplier && payer) {
    return `${supplier}${HYPHEN_SEPARATOR}${payer}`;
  }

  return supplier || "";
}

export function getEngagementTotalPayout<T extends AnyEngagement>(engagement: T): Nullable<string> {
  const totalPayout = engagement.getTotalPayout();

  return totalPayout ? formatCurrencyValue(totalPayout, FormatCurrencyStyle.View) : null;
}
