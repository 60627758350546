import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { firstValueFrom, map, Observable } from "rxjs";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { WINDOW } from "src/app/tokens";
import { isNorskpensjonEngagement } from "src/app/utils/engagement.typeguards";
import { Action, ActionComponent } from "../actions/actions.component";

@Component({
  selector: "app-action-move-pkb-to-storebrand",
  templateUrl: "./action-move-pkb-to-storebrand.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMovePkbToStorebrandComponent implements ActionComponent {
  @Input()
  public action!: Action;

  constructor(
    private readonly engagementsService: EngagementsService,
    private readonly fmsService: FmsService,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {}

  public getIsVisible(): Observable<boolean> {
    return this.engagementsService.allEngagements$.pipe(map(getHasPensjonskapitalbevisFromNorskpensjon));
  }

  public async onPrimaryButtonClick(): Promise<void> {
    const url = await firstValueFrom(this.fmsService.translateAsync<string>("actions.movePkbToStorebrand.url"));
    this.window.open(url, "_blank");
  }
}

function getHasPensjonskapitalbevisFromNorskpensjon(engagements: AnyEngagement[]): boolean {
  return engagements.filter(isNorskpensjonEngagement).some((engagement) => engagement.isPensjonskapitalbevis());
}
