import { GetPublicPensionPrognosisParametersQuery } from "src/app/services/api/public-pension-prognosis-parameters-static.service";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { TWENTY_YEAR_PAYOUT_PLAN } from "src/app/services/mocks/norskpensjon-mock";
import { PublicPensionContractStatus } from "src/app/services/prognoses-services/public-pension.service.vars";
import { ResolverMap } from "../../utils/apollo-link-utils";

const RETIREMENT_AGES: Graph.RetirementAge[] = [
  {
    age: 63,
    date: "2063-01-01",
  },
  {
    age: 64,
    date: "2064-01-01",
  },
  {
    age: 65,
    date: "2065-01-01",
  },
  {
    age: 66,
    date: "2066-01-01",
  },
  {
    age: 67,
    date: "2067-01-01",
  },
  {
    age: 68,
    date: "2068-01-01",
  },
];

const OK_SIMULATION_STATUS: Graph.SimulationStatus = {
  severity: Graph.SimulationSeverity.Ok,
  messageKey: null,
};

export const simulationParametersResolverMap: ResolverMap = {
  SimulationParameters: (): Graph.SimulationParameters => ({
    customerBirthDate: "1992-01-01",
    retirementAges: RETIREMENT_AGES,
  }),
};

export const publicPensionResolverMap: ResolverMap = {
  PublicPension: (): Graph.PublicPension => ({
    contracts: [
      {
        contractNumber: "19636",
        employer: {
          name: "Askøy Kommune",
        },
        status: PublicPensionContractStatus.Active,
        productName: {
          norwegian: "Offentlig tjenestepensjon",
        },
        actions: {
          simulatePublicPension: true,
        },
      },
    ],
    publicPensionPrognosis: {
      payoutPlan: [{ age: 67, amount: 58979.0 }],
      simulationStatus: OK_SIMULATION_STATUS,
    },
  }),
};

export const norskpensjonResolverMap: ResolverMap = {
  Norskpensjon: (): Graph.Norskpensjon => ({
    contracts: [
      {
        category: "offentligTjenestepensjon",
        pensionProvider: {
          name: "KLP",
        },
      },
    ],
    unavailablePensionProviders: [],
  }),
};

export const navResolverMap: ResolverMap = {
  Nav: (): Graph.Norskpensjon => ({
    folketrygdPrognosis: {
      payoutPlan: TWENTY_YEAR_PAYOUT_PLAN,
      simulationStatus: OK_SIMULATION_STATUS,
    },
  }),
};

export const savingsEngagementResolverMap: ResolverMap = {
  SavingsEngagement: (): Graph.SavingsEngagement => ({
    bankAccounts: [
      {
        accountNumber: "11112233333",
        productFriendlyName: "Smartkonto",
        bookBalance: 8385.28,
        productCode: "pps----",
      },
    ],
  }),
};

export const publicPensionPrognosisParametersResolverMap: ResolverMap = {
  Query: (): GetPublicPensionPrognosisParametersQuery => ({
    publicPensionPrognosisParameters: {
      mainSalary: 450000,
      hasPublicPensionEngagements: true,
      defaultWithdrawalDate: "2029-05-01",
    },
  }),
};
