import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from "@angular/core";
import * as lottie from "lottie-web";

@Component({
  selector: "app-lottie-player",
  templateUrl: "./lottie-player.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottiePlayerComponent implements OnInit {
  @Input()
  private readonly path!: string;

  @Input()
  private readonly loop? = false;

  private readonly lottie = lottie.default;

  constructor(private readonly elRef: ElementRef) {}

  public ngOnInit(): void {
    this.lottie.loadAnimation({
      container: this.elRef.nativeElement,
      renderer: "svg",
      loop: this.loop,
      autoplay: true,
      path: this.path,
    });
  }
}
