import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";
import { ItpUnfundedEngagement } from "../../../../../models/engagements/savings-and-pension/pension-fund-engagement.model";
import { Nullable } from "../../../../../utils/utils";

@Component({
  selector: "app-engagement-details-itp-unfunded",
  templateUrl: "./engagement-details-itp-unfunded.component.html",
  styleUrls: ["../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsItpUnfundedComponent implements EngagementBody, OnInit {
  @Input()
  public engagement!: ItpUnfundedEngagement;
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;

  public contractNumber: Nullable<string>;
  public yearlyDeposit: Nullable<number>;

  ngOnInit(): void {
    this.contractNumber = this.engagement.getContractNumberCustomer();
    this.yearlyDeposit = this.engagement.getYearlyDeposit();
  }
}
