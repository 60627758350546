import { InMemoryCache } from "@apollo/client/core";
import { NamedOptions } from "apollo-angular";
import { HttpLink, HttpLinkHandler } from "apollo-angular/http";
import { EndpointService } from "src/app/services/endpoint.service";
import { createAddResponseHeadersLink, responseHeadersTypeDefs } from "../../local-schema/response-headers";
import {
  combineApolloLinks,
  createClientIdLink,
  createDynamicHttpLink,
  createErrorReporterLink,
} from "../../utils/apollo-link-utils";

export const SAVINGS_GRAPH_CLIENT_ID = "savingsGraphClient";

const useIncoming = <T>(_: T, incoming: T): T => {
  return incoming;
};

export const SAVINGS_GRAPH_CLIENT_CACHE = new InMemoryCache({
  typePolicies: {
    SavingsEngagement: {
      merge: true,
    },
    KeyValues: {
      merge: true,
    },
    Norskpensjon: {
      merge: true,
    },
    PensionPayouts: {
      merge: true,
    },
    PublicPensionPrognosis: {
      fields: {
        statusMessages: {
          merge: useIncoming,
        },
        paymentPlanPension: {
          merge: useIncoming,
        },
        paymentPlanFolketrygd: {
          merge: useIncoming,
        },
      },
    },
  },
});

export function savingsGraphClientFactory(httpLink: HttpLink, endpointService: EndpointService): NamedOptions {
  const link = combineApolloLinks([
    createClientIdLink(),
    createErrorReporterLink(),
    createAddResponseHeadersLink(),
    createDynamicHttpLink(endpointService),
    createSavingsGraphHttpLink(httpLink),
  ]);

  return {
    [SAVINGS_GRAPH_CLIENT_ID]: {
      cache: SAVINGS_GRAPH_CLIENT_CACHE,
      link,
      typeDefs: [responseHeadersTypeDefs],
    },
  };
}

function createSavingsGraphHttpLink(httpLink: HttpLink): HttpLinkHandler {
  return httpLink.create({});
}
