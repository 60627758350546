import { Inject, Injectable, InjectionToken } from "@angular/core";
import { fromEvent, Observable, shareReplay } from "rxjs";
import { isStageP } from "src/app/utils/storebrand-staging";
import { EVENTS, IConfig, IContext, IMutableContext, IToggle, IVariant, UnleashClient } from "unleash-proxy-client";
import packageJson from "../../../../package.json";
import { filterTogglesWithAppNamePostFix, makeCreateUnleashObservable } from "./unleash-client-wrapper.utils";

const commonUnleashConfig: Pick<IConfig, "clientKey" | "appName"> = {
  /**
   * The clientKey is the same for production and development
   * Fetched from: {@link https://github.com/storebrand-digital/stb-flux-gitops/blob/main/prod/frontend/unleash-proxy/deployment.yaml}
   */
  clientKey: "d1a1a1cc-f56f-4537-b770-c94f3772ce52",
  appName: packageJson.name,
};

const testUnleashConfig: IConfig = {
  ...commonUnleashConfig,
  url: "https://www-t.storebrand.no/features/proxy",
  clientKey: "d1a1a1cc-f56f-4537-b770-c94f3772ce52",
  refreshInterval: 10,
  environment: "development",
};

const prodUnleashConfig: IConfig = {
  ...commonUnleashConfig,
  url: "https://www.storebrand.no/features/proxy",
  refreshInterval: 60,
  environment: "production",
};

const UNLEASH_CLIENT_TOKEN = new InjectionToken("Unleash client token", {
  providedIn: "root",
  factory(): UnleashClient {
    const config = isStageP() ? prodUnleashConfig : testUnleashConfig;
    return new UnleashClient(config);
  },
});

export interface UnleashContext {
  userId?: string;
}

/**
 * Unleash proxy client docs:
 * {@link https://github.com/Unleash/unleash-proxy-client-js}
 */
@Injectable({
  providedIn: "root",
})
export class UnleashClientWrapperService {
  /**
   * From docs: Emitted after the SDK has successfully started and performed the initial fetch towards the Unleash Proxy.
   */
  public readonly ready$ = this.createUnleashEvent(EVENTS.READY);

  /**
   * From docs: Emitted when an error occurs on init, or when fetch function fails, or when fetch receives a non-ok response object. The error object is sent as payload.
   */
  public readonly error$ = this.createUnleashEvent(EVENTS.ERROR);

  /**
   * From docs: Emitted after the SDK has read local cached data in the storageProvider.
   */
  public readonly init$ = this.createUnleashEvent(EVENTS.INIT);

  /**
   * From docs: Emitted every time the Unleash Proxy return a new feature toggle configuration. The SDK will emit this event as part of the initial fetch from the SDK.
   */
  public readonly update$ = this.createUnleashEvent(EVENTS.UPDATE);

  public readonly impression$ = this.createUnleashEvent(EVENTS.IMPRESSION);

  constructor(
    @Inject(UNLEASH_CLIENT_TOKEN)
    private readonly unleashClient: UnleashClient,
  ) {}

  public start(context: UnleashContext): Promise<void> {
    return this.unleashClient.start().then(() => this.unleashClient.updateContext(context));
  }

  public stop(): Promise<void> {
    this.unleashClient.stop();
    return Promise.resolve();
  }

  public isEnabled(toggleName: string): Observable<boolean> {
    return this.createUnleashObservable(() => this.unleashClient.isEnabled(toggleName));
  }

  public getVariant(toggleName: string): IVariant {
    return this.unleashClient.getVariant(toggleName);
  }

  public getAllToggles(): Observable<IToggle[]> {
    return this.createUnleashObservable(() => this.unleashClient.getAllToggles()).pipe(
      filterTogglesWithAppNamePostFix(this.getContext()),
    );
  }

  public updateContext(context: IMutableContext): Promise<void> {
    return this.unleashClient.updateContext(context);
  }

  public getContext(): IContext {
    return this.unleashClient.getContext();
  }

  private createUnleashObservable<T>(project: () => T): Observable<T> {
    return makeCreateUnleashObservable(this.ready$, this.update$)(project);
  }

  /**
   * All unleash event listeners needs to be registered before the client is started.
   * We use shareReplay so late subscribers wont miss important events like init and ready.
   */
  private createUnleashEvent<T = void>(eventName: string): Observable<T> {
    return fromEvent<T>(this.unleashClient, eventName).pipe(shareReplay(1));
  }
}
