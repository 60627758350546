import { Injectable } from "@angular/core";
import { ChildActivationEnd, Router } from "@angular/router";
import { differenceInMonths } from "date-fns";
import { produce } from "immer";
import { Observable, Subscription, combineLatest, of, startWith, distinctUntilChanged } from "rxjs";
import { catchError, combineLatestWith, filter, first, map, switchMap, take } from "rxjs/operators";
import { getIsSomeItemsTrue } from "src/app/utils/array";
import { Nullable, getIsNotNullable, getIsNullable, removeQueryParams } from "src/app/utils/utils";
import { RouteKey } from "../modules/pension-plan/routes";
import { InvalidPensionPlanUrl } from "../utils/errors";
import { memoizeObject$ } from "../utils/rxjs/select";
import { HasPublicPensionInStorebrandService } from "./api/has-public-pension-in-storebrand.service";
import { ClientDataService } from "./customer-supplied-data/client-data.service";
import {
  PensionPlanProgressionState,
  PensionPlanRoute,
  getCurrentRoute,
  getIsLastRoute,
  getLastValidRoute,
  getRouteForUrl,
  getStepByUrl,
  getStepRouteKeys,
  getUrlForRouteKey,
  toPlanRoutes,
} from "./pension-plan-progress.utils";

@Injectable({
  providedIn: "root",
})
export class PensionPlanProgressService {
  public readonly hasFinishedPensionPlan$: Observable<boolean>;
  public readonly pensionPlanProgressionState$: Observable<PensionPlanProgressionState>;
  public readonly planRoutes$: Observable<PensionPlanRoute[]>;
  public readonly currentRoute$: Observable<PensionPlanRoute | undefined>;

  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    private readonly router: Router,
  ) {
    this.hasFinishedPensionPlan$ = this.clientDataService.pensionPlan$.pipe(map(toHasFinishedPensionPlan));

    this.pensionPlanProgressionState$ = this.hasFinishedPensionPlan$.pipe(
      map((hasFinishedPensionPlan) => {
        return hasFinishedPensionPlan ? PensionPlanProgressionState.Finished : PensionPlanProgressionState.New;
      }),
    );

    this.planRoutes$ = memoizeObject$(
      this.hasPublicPensionInStorebrandService.isPublicContext$.pipe(
        map((isPublicContext) =>
          getStepRouteKeys().filter(
            ({ key }) => !isPublicContext || !(key === RouteKey.NeedsIntro || key === RouteKey.Needs),
          ),
        ),
        map((routes) => toPlanRoutes(routes)),
      ),
    );

    this.currentRoute$ = memoizeObject$(
      this.planRoutes$.pipe(
        switchMap(() => shouldUpdateRoutes(this.router)),
        filter((shouldUpdate) => shouldUpdate),
        map(() => getCurrentRoute(this.router)),
        distinctUntilChanged(),
      ),
    );
  }

  public getProgressUrl(): Observable<string> {
    const fallbackUrl = getUrlForRouteKey(RouteKey.Landing);

    return this.clientDataService.pensionPlan$.pipe(
      first(),
      map(toProgressUrl),
      map(toValidPensionPlanUrl),
      map(toIntroUrlIfPensionPlanInProgress),
      catchError(() => of(fallbackUrl)),
    );
  }

  public update(nextPensionPlan: CustomerSuppliedData.PensionPlanProgress): Subscription {
    return this.clientDataService.pensionPlan$
      .pipe(
        take(1),
        map((currentPensionPlan) => getLeadingPensionPlan({ currentPensionPlan, nextPensionPlan })),
      )
      .subscribe((newPensionPlan) => this.clientDataService.updatePensionPlan(newPensionPlan));
  }

  public hasCompletedStep(key: RouteKey): Observable<boolean> {
    const thresholdIndex$ = this.planRoutes$.pipe(map((routes) => routes.findIndex((route) => route.key === key)));

    return this.clientDataService.pensionPlan$.pipe(
      combineLatestWith(this.planRoutes$, thresholdIndex$),
      map(
        ([pensionPlan, routes, thresholdIndex]) =>
          routes.findIndex((route) => route.url === pensionPlan?.progressUrl) >= thresholdIndex,
      ),
    );
  }

  public resetNavigationProgress(): void {
    this.clientDataService.updatePensionPlan({
      progressUrl: undefined,
      progressUrlUpdated: undefined,
    });
  }

  public getIsLongSinceLastPensionPlanVisit(): Observable<boolean> {
    const DIFFERENCE_THRESHOLD_IN_MONTHS = 12;
    const STEPS = [RouteKey.Actions, RouteKey.Summary];

    const hasCompletedSomeSteps$ = combineLatest(STEPS.map((key) => this.hasCompletedStep(key))).pipe(
      map(getIsSomeItemsTrue),
    );

    return this.clientDataService.pensionPlan$.pipe(
      map((plan) => plan?.progressUrlUpdated),
      combineLatestWith(hasCompletedSomeSteps$),
      map(
        ([progressUrlUpdated, hasCompletedSomeSteps]) =>
          hasCompletedSomeSteps &&
          getIsNotNullable(progressUrlUpdated) &&
          differenceInMonths(new Date(), progressUrlUpdated) >= DIFFERENCE_THRESHOLD_IN_MONTHS,
      ),
    );
  }
}

function getLeadingPensionPlan({
  currentPensionPlan,
  nextPensionPlan,
}: {
  currentPensionPlan: CustomerSuppliedData.PensionPlanProgress | undefined;
  nextPensionPlan: CustomerSuppliedData.PensionPlanProgress | undefined;
}): CustomerSuppliedData.PensionPlanProgress {
  const leadingProgressUrl = getLeadingProgressUrl({
    currentUrl: currentPensionPlan?.progressUrl,
    nextUrl: nextPensionPlan?.progressUrl,
  });
  const DEFAULT_PLAN: CustomerSuppliedData.PensionPlanProgress = {
    progressUrlUpdated: Date.now(),
  };

  return produce(nextPensionPlan ?? DEFAULT_PLAN, (draft) => {
    const isProgress = leadingProgressUrl !== currentPensionPlan?.progressUrl;
    const isEqual = !isProgress && leadingProgressUrl === nextPensionPlan?.progressUrl;

    draft.progressUrl = isProgress ? leadingProgressUrl : undefined;
    draft.progressUrlUpdated = isProgress || isEqual ? Date.now() : undefined;
  });
}

function getLeadingProgressUrl({
  currentUrl,
  nextUrl,
}: {
  currentUrl: Nullable<string>;
  nextUrl: Nullable<string>;
}): string | undefined {
  const cleanNextUrl = removeQueryParams(nextUrl ?? "", []);
  const current = getRouteForUrl(currentUrl)?.key;
  const next = getRouteForUrl(cleanNextUrl)?.key;

  const lastValidRoute = getLastValidRoute(current, next);

  return getIsNullable(lastValidRoute) ? undefined : getUrlForRouteKey(lastValidRoute);
}

export function shouldUpdateRoutes(router: Router): Observable<boolean> {
  return router.events.pipe(
    map((event) => event instanceof ChildActivationEnd),
    startWith(true),
  );
}

function toHasFinishedPensionPlan(pensionPlan: CustomerSuppliedData.PensionPlanProgress | undefined): boolean {
  return [pensionPlan?.progressUrl].map(getRouteForUrl).map(getIsLastRoute).at(0) ?? false;
}

function toValidPensionPlanUrl(url: string): string {
  const resultUrl = getRouteForUrl(url);
  if (getIsNullable(resultUrl)) {
    throw new InvalidPensionPlanUrl(url);
  }

  return resultUrl.url;
}

function toIntroUrlIfPensionPlanInProgress(url: string): string {
  const currentPensionPlanStep = getStepByUrl(url);

  if (getIsNullable(currentPensionPlanStep)) {
    throw new Error("Could not find pension plan step from url!");
  }

  const hasIntroSteps = currentPensionPlanStep.intros.length > 0;
  const hasNotFinishedPensionPlan = url !== getUrlForRouteKey(RouteKey.Summary);
  const shouldGotoIntroStep = hasIntroSteps && hasNotFinishedPensionPlan;
  const nextStepKey = shouldGotoIntroStep ? currentPensionPlanStep.intros.at(0) : currentPensionPlanStep.route;

  if (getIsNullable(nextStepKey)) {
    throw new Error("Could not find pension plan step from url!");
  }

  return getUrlForRouteKey(nextStepKey.key);
}

function toProgressUrl(pensionPlan: CustomerSuppliedData.PensionPlanProgress | undefined): string {
  const url = pensionPlan?.progressUrl;
  if (getIsNullable(url)) {
    throw new InvalidPensionPlanUrl(url, "pensionPlan.progressUrl");
  }
  return url;
}
