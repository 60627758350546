import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

/**
 * !IMPORTANT NOTICE! This pipe marks urls as trusted, and must never
 * be used on anything but trusted resources. If the resource is not
 * trusted you will introduce a security vulnerability. If you have
 * no idea what this means, read the linked documentation or find a
 * security expert.
 *
 * See https://angular.io/guide/security#bypass-security-apis
 */
@Pipe({ name: "safeUrl" })
export class SafeUrlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}
  transform(url: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
