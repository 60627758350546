import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { EndpointService, httpHeaderNoCache } from "../endpoint.service";
import { operations } from "./bank-account-rest-api.types";

type getAccounts = operations["getCustomerAccount"];
export type getAccountsResponse200 = getAccounts["responses"]["200"]["content"]["application/json"][];

/**
 * Storebrand Bank API, APE (Automatic Product Establishment) and Neos-API proxy.
 *
 * {@link https://t.stb.intra/gcp/bank-account-rest-api/swagger-ui.html}
 */
@Injectable({
  providedIn: "root",
})
export class BankAccountRestApiService extends EndpointService {
  /**
   * GET /savings/retirement-management/smartaccount/eligible
   * TODO: Currently uses a deprecated endpoint. Retarget to new endpoint when available.
   */
  public fetchEligibility(): Observable<SmartAccount.Eligibility> {
    return this.composeEligibilityCheckApiUrl().pipe(switchMap((url) => this.httpGet$<SmartAccount.Eligibility>(url)));
  }

  /**
   * GET /customer-account
   */
  public fetchCustomerAccounts(): Observable<getAccountsResponse200> {
    return this.composeSmartAccountCreationStatusUrl().pipe(
      switchMap((url) => this.httpGet$<getAccountsResponse200>(url, httpHeaderNoCache)),
    );
  }
}
