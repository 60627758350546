export function getIsStorageAvailable(storage: Storage): boolean {
  try {
    const testKey = "__storage_test__";
    const testValue = "__test_value__";

    storage.setItem(testKey, testValue);

    const hasTestValue = storage.getItem(testKey) === testValue;

    if (hasTestValue) {
      storage.removeItem(testKey);
    }

    return hasTestValue;
  } catch (e) {
    return false;
  }
}
