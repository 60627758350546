import { combineLatest, first, map, Observable } from "rxjs";
import { EngagementName } from "src/app/models/pension.model";
import { EmptyObject } from "src/app/utils/types";
import { PAYOUT_DURATIONS, PayoutDuration } from "../generic-engagement.model";
import { AbstractNorskpensjonEngagement } from "./abstract-norskpensjon-engagement.model";

const AFP_ENGAGEMENT_ID = "AFP-Privat";

export class AfpEngagement extends AbstractNorskpensjonEngagement<EmptyObject> {
  public getIdentifier(): string {
    return AFP_ENGAGEMENT_ID;
  }

  public getNameAsync(): Observable<EngagementName> {
    const fmsService = this.getFmsService();

    return combineLatest([
      fmsService.translateAsync<string>("sharedProductNames.AFP"),
      fmsService.translateAsync<string>("sharedProductNames.AFP.description"),
      fmsService.translateAsync<string>("AfpEngagement.supplier"),
    ]).pipe(
      first(),
      map(([name, description, supplier]) => ({ name, description, supplier })),
    );
  }

  public getPayoutDuration(): PayoutDuration {
    return PAYOUT_DURATIONS.infinite;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }
}
