import { inject, Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { combineLatest, iif, Observable, ObservedValueOf } from "rxjs";
import { combineLatestWith, map, mergeMap, startWith, switchMap, tap } from "rxjs/operators";
import { BankEngagement } from "src/app/models/engagements/bank-engagement.model";
import { ExternalSavingsEngagement } from "src/app/models/engagements/external-savings-engagement.model";
import { AbstractNavEngagement } from "src/app/models/engagements/nav-engagement.model";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import { NorskpensjonEngagement } from "src/app/models/engagements/norskpensjon/norskpensjon-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { PublicSalaryEngagement } from "src/app/models/engagements/public-salary-engagement.model";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { selectObject$ } from "src/app/utils/rxjs/select";
import { GenericEngagementOfUnknown } from "../models/engagements/generic-engagement.model";
import { UnavailableNorskpensjonEngagement } from "../models/engagements/norskpensjon/unavailable-norskpensjon-engagement.model";
import { PublicPensionEngagement } from "../models/engagements/public-pension-engagement.model";
import { EngagementsSorterService } from "../modules/shared/services/engagements-sorter.service";
import { isAfpEngagement, isOtherPensionEngagement } from "../utils/engagement.typeguards";
import { arrayFilterPipe } from "../utils/rxjs/pipes";
import { HasPublicPensionInStorebrandService } from "./api/has-public-pension-in-storebrand.service";
import { CommonParametersService } from "./common-parameters.service";
import { ClientDataService } from "./customer-supplied-data/client-data.service";
import {
  AbstractPrognosisFetchService,
  EngagementService,
} from "./prognoses-services/abstract-prognosis-fetch.service";
import { AfpService } from "./prognoses-services/afp.service";
import { BankService } from "./prognoses-services/bank.service";
import { ExternalEngagementsService } from "./prognoses-services/external-engagements.service";
import { NavService } from "./prognoses-services/nav.service";
import { NorskpensjonService } from "./prognoses-services/norskpensjon.service";
import { OtherPensionEngagementsService } from "./prognoses-services/other-pension-engagements.service";
import { PublicPensionService } from "./prognoses-services/public-pension.service";
import {
  AnySavingsAndPensionEngagement,
  SavingsAndPensionService,
} from "./prognoses-services/savings-and-pension.service";
import { UnavailableNorskpensjonService } from "./prognoses-services/unavailable-norskpensjon.service";

export type AnyEngagement =
  | NorskpensjonEngagement
  | UnavailableNorskpensjonEngagement
  | AfpEngagement
  | BankEngagement
  | AbstractNavEngagement
  | PublicSalaryEngagement
  | PublicPensionEngagement
  | OtherPensionEngagement
  | ExternalSavingsEngagement
  | AnySavingsAndPensionEngagement;

export const ENGAGEMENT_SERVICES_TOKEN = new InjectionToken("Engagement services", {
  providedIn: "any",
  factory(): EngagementService[] {
    return [
      inject(AfpService),
      inject(BankService),
      inject(ExternalEngagementsService),
      inject(NavService),
      inject(NorskpensjonService),
      inject(OtherPensionEngagementsService),
      inject(PublicPensionService),
      inject(SavingsAndPensionService),
      inject(UnavailableNorskpensjonService),
    ];
  },
});

export const INIT_ENGAEGMENTS_INJECTION_TOKEN = "initEngagements$";

@Injectable({
  providedIn: "root",
})
export class EngagementsService {
  public allEngagements$: Observable<AnyEngagement[]>;
  public allEngagementErrors$: Observable<
    ObservedValueOf<typeof AbstractPrognosisFetchService.prototype.engagementHttpErrors$>[]
  >;
  public readonly hasOffentligTjenestepensjonFlag$: Observable<boolean>;
  public readonly canManuallyAddOffentligTjenestepensjon$: Observable<boolean>;
  private readonly unfilteredAllEngagements$: Observable<any[]>;

  constructor(
    @Inject(ENGAGEMENT_SERVICES_TOKEN)
    private readonly engagementServices: EngagementService[],
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
    private readonly clientDataService: ClientDataService,
    private readonly engagementsSorterService: EngagementsSorterService,
    private readonly commonParametersService: CommonParametersService,
    @Optional()
    @Inject(INIT_ENGAEGMENTS_INJECTION_TOKEN)
    initEngagements$?: Observable<AnyEngagement[]>,
  ) {
    this.unfilteredAllEngagements$ = selectObject$(
      combineLatest(this.engagementServices.map((service) => service.engagements$)),
      (allEngagements) => allEngagements.flat(1),
    );

    const storebrandOnlyEngagements$ = combineLatest(
      this.engagementServices.filter((service) => service.isStorebrandOnly()).map((service) => service.engagements$),
    ).pipe(map((engagements) => engagements.flat()));

    this.allEngagements$ =
      initEngagements$ ||
      this.storebrandOnlyService.getIsEnabled().pipe(
        switchMap((storebrandOnly) =>
          iif(() => storebrandOnly, storebrandOnlyEngagements$, this.unfilteredAllEngagements$).pipe(
            combineLatestWith(
              //Used for change detetion when including/excluding contracts
              this.clientDataService.agreementsMetaDataMap$,
            ),
            mergeMap(([engagements]) => this.engagementsSorterService.sort(engagements)),
          ),
        ),
      );

    const allEngagementHttpErrors$ = combineLatest(
      this.engagementServices.map((e) => e.engagementHttpErrors$.pipe(startWith(null))),
    );

    this.allEngagementErrors$ = selectObject$(
      allEngagementHttpErrors$,
      (allHttpErrors) =>
        <ObservedValueOf<typeof EngagementsService.prototype.allEngagementErrors$>>(
          allHttpErrors.filter((err) => Boolean(err))
        ),
    );

    this.hasOffentligTjenestepensjonFlag$ = this.allEngagements$.pipe(
      map((engagements) =>
        engagements.some(
          (e) => typeof e?.isPublicPensionFromNorskPensjon === "function" && e.isPublicPensionFromNorskPensjon(),
        ),
      ),
    );

    const hasManuallyAddedOffentligTjenestepensjon$: Observable<boolean> = this.allEngagements$.pipe(
      arrayFilterPipe((engagement) => !isAfpEngagement(engagement)),
      map((engagements) =>
        engagements.some(
          (engagement) => isOtherPensionEngagement(engagement) && engagement.isPublicPensionFromNorskPensjon(),
        ),
      ),
    );

    this.canManuallyAddOffentligTjenestepensjon$ = combineLatest([
      this.storebrandOnlyService.getIsEnabled(),
      this.hasPublicPensionInStorebrandService.isPublicContext$,
      this.hasOffentligTjenestepensjonFlag$,
      hasManuallyAddedOffentligTjenestepensjon$,
    ]).pipe(
      map(
        ([isStorebrandOnly, isPublicContext, isAbleToAdd, hasManuallyAdded]) =>
          !isStorebrandOnly && !isPublicContext && isAbleToAdd && !hasManuallyAdded,
      ),
    );
  }

  public beginPersistingPrognosisParametersByEngagement(): Observable<GenericEngagementOfUnknown[]> {
    return this.unfilteredAllEngagements$.pipe(
      tap((allEngagements) => this.commonParametersService.persistPrognosisParametersInput(allEngagements)),
    );
  }
}
