import { ChangeDetectionStrategy, Component } from "@angular/core";
import { OnboardingService, OnboardingStepKey } from "../../../../../../services/onboarding.service";

@Component({
  selector: "app-nav-consent-cta-card",
  templateUrl: "./nav-consent-cta-card.component.html",
  styleUrls: ["./nav-consent-cta-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavConsentCtaCardComponent {
  constructor(private readonly onboardingService: OnboardingService) {}

  public openConsent(): void {
    const steps = [OnboardingStepKey.PublicPension];
    this.onboardingService.open({}, steps);
  }
}
