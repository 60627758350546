import { inject } from "@angular/core";
import { CanActivateChildFn } from "@angular/router";
import { KeycloakService } from "src/app/services/keycloak.service";

export const canActivateApp: CanActivateChildFn = () => {
  const authService = inject(KeycloakService);
  if (authService.authenticated()) {
    return true;
  }

  authService.login();

  return false;
};
