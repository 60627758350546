import { Injectable } from "@angular/core";
import { SeriesArearangeOptions, SeriesColumnOptions } from "highcharts";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { filterPayoutPlanPeriodsByRange, PayoutplanService } from "src/app/services/payoutplan.service";
import { PensionNeedsService } from "src/app/services/pension-needs.service";
import { filterUnique } from "src/app/utils/array";
import { selectObject$ } from "src/app/utils/rxjs/select";
import { DEFAULT_MAX_LIFE_EXPECTANCY_AGE } from "../constants/business.constants";
import { GeneralChartColors } from "../constants/highcharts-colors.constants";
import { BLANK_XAXIS_POINT } from "../modules/shared/components/charts/chart/chart.default-functions";
import { applyArrayTo } from "../utils/applyArrayTo";
import { getRangeFromBoundary } from "../utils/getRangeFromBoundary";
import { naiveObjectComparison } from "../utils/utils";
import { FmsService } from "./fms.service";
import { IncomeService } from "./income/income.service";
import { StartPayoutAgeService } from "./start-payout-age.service";

export type NumberedSeriesColumnOptions = SeriesColumnOptions & {
  data: number[];
  index: number;
};

export enum SeriesType {
  Arearange = "arearange",
  Column = "column",
  Pie = "pie",
}

export interface ChartTranslations {
  longTooltipTitle: string;
  years: string;
}

@Injectable({
  providedIn: "root",
})
export class ChartService {
  public readonly payoutColumnSeries$: Observable<NumberedSeriesColumnOptions>;
  public readonly annualGrossIncomeSeries$: Observable<NumberedSeriesColumnOptions>;
  public readonly needsArearangeSeries$: Observable<SeriesArearangeOptions>;
  public readonly agreementsColumnSeries$: Observable<NumberedSeriesColumnOptions[]>;
  public readonly chartTranslations$: Observable<ChartTranslations>;
  public readonly xAxisCompleteRange$: Observable<number[]>;
  public readonly xAxisActivePayoutsRange$: Observable<number[]>;

  constructor(
    private readonly fmsService: FmsService,
    private readonly incomeService: IncomeService,
    private readonly payoutPlanService: PayoutplanService,
    private readonly pensionNeedsService: PensionNeedsService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    this.xAxisCompleteRange$ = this.startPayoutAgeService
      .getFirstPayoutAge()
      .pipe(map((floor) => getRangeFromBoundary({ floor, ceil: DEFAULT_MAX_LIFE_EXPECTANCY_AGE }, NaN)));

    this.xAxisActivePayoutsRange$ = this.payoutPlanService.payoutPlan$.pipe(
      map(([{ startAge }]) => startAge),
      map((floor) => getRangeFromBoundary({ floor, ceil: DEFAULT_MAX_LIFE_EXPECTANCY_AGE }, NaN)),
    );

    this.payoutColumnSeries$ = selectObject$(
      combineLatest([this.payoutPlanService.payoutPlan$, this.xAxisActivePayoutsRange$]).pipe(
        map(applyArrayTo(filterPayoutPlanPeriodsByRange)),
      ),
      (payoutPlan) =>
        <NumberedSeriesColumnOptions>{
          type: SeriesType.Column,
          data: payoutPlan.map(({ total }) => total),
        },
    );

    this.annualGrossIncomeSeries$ = combineLatest([
      this.incomeService.annualGrossIncome$,
      this.fmsService.translateAsync<string>("chartService.annualGrossIncomeSeries.name"),
    ]).pipe(
      map(
        ([annualGrossIncome, name]) =>
          <NumberedSeriesColumnOptions>{
            color: GeneralChartColors.AnnualGrossIncomeColumn,
            data: [annualGrossIncome],
            id: "annualGrossIncome",
            name,
            index: 0,
            type: SeriesType.Column,
            cursor: "default",
          },
      ),
    );

    this.needsArearangeSeries$ = this.pensionNeedsService.pensionNeedsArearangeData$.pipe(
      map((series) => toSeriesArearangeOptions(series, SeriesType.Arearange)),
      distinctUntilChanged(naiveObjectComparison),
    );

    this.agreementsColumnSeries$ = combineLatest([this.payoutPlanService.payoutPlan$, this.xAxisCompleteRange$]).pipe(
      map(applyArrayTo(filterPayoutPlanPeriodsByRange)),
      map((payoutPlan) => {
        const emptySeriesForKeepingXaxis: NumberedSeriesColumnOptions = {
          id: "emptySeriesForKeepingXaxis",
          type: "column",
          data: new Array(payoutPlan.length).fill(0),
          index: 0,
        };
        return [emptySeriesForKeepingXaxis, ...mapPayoutPlanPeriodToSeriesColumnOptions(payoutPlan)];
      }),
    );

    this.chartTranslations$ = combineLatest([
      this.fmsService.translateAsync("chartService.chartTranslations.longTooltipTitle"),
      this.fmsService.translateAsync("chartService.chartTranslations.years"),
    ]).pipe(
      map(([longTooltipTitle, years]) => ({
        longTooltipTitle,
        years,
      })),
    );
  }
}

function mapPayoutPlanPeriodToSeriesColumnOptions(
  payoutPlanPeriods: PayoutPlan.PayoutPlanPeriod[],
): NumberedSeriesColumnOptions[] {
  if (!payoutPlanPeriods || !Array.isArray(payoutPlanPeriods)) {
    return [];
  }

  const seriesColumnOptions = filterUnique(
    payoutPlanPeriods.flatMap(({ payoutPlanPayouts }) =>
      (payoutPlanPayouts ?? []).map(({ name, contractColor, contractIdentifier, index }) => ({
        type: SeriesType.Column,
        name: name,
        id: contractIdentifier + name,
        color: contractColor,
        data: getSeriesColumnOptionsData(payoutPlanPeriods, name ?? "", contractIdentifier),
        index,
      })),
    ),
  );

  return seriesColumnOptions as NumberedSeriesColumnOptions[];
}

function getSeriesColumnOptionsData(
  payoutPlanPeriods: PayoutPlan.PayoutPlanPeriod[],
  name: string,
  contractIdentifier: string,
): number[] {
  return payoutPlanPeriods
    .map((period) =>
      (period.payoutPlanPayouts ?? []).find(
        (payout) => payout.name === name && payout.contractIdentifier === contractIdentifier,
      ),
    )
    .map((payout) => payout?.amount ?? 0);
}

/**
 * payoutPlanXAxisPadding descibes payoutPlanPeriods' XAxis start index.
 *
 * E.g. payoutPlanXAxisPadding = 3 return: ['26', '0', '0', '67', '68', '69'...]
 * where '26' is customerAge and '67' is the first value of payoutPlanPeriods
 */
export function generateXAxisCategoryOption(
  xAxisRange: number[],
  customerAge?: number,
  payoutPlanXAxisPadding = 2,
): string[] {
  return customerAge
    ? [customerAge].concat(addPaddingToSeriesData(xAxisRange, payoutPlanXAxisPadding - 1)).map(String)
    : xAxisRange.map(String);
}

export function addPaddingToSeriesData(seriesData: number[], paddingLength = 2): number[] {
  return [...Array(paddingLength).fill(BLANK_XAXIS_POINT), ...seriesData];
}

function toSeriesArearangeOptions(data: SeriesArearangeOptions["data"], type: SeriesType): SeriesArearangeOptions {
  return {
    type,
    data,
  } as SeriesArearangeOptions;
}
