import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { BehaviorSubject } from "rxjs";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import { ModalDialogComponent } from "src/app/modules/shared/components/dialogs/modal-dialog/modal-dialog.component";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { FmsService } from "src/app/services/fms.service";
import { InternalSavingsService } from "src/app/services/internal-savings.service";

@Component({
  selector: "app-public-pension-contract",
  templateUrl: "./public-pension-contract.component.html",
  styleUrls: ["../../engagement-details-shared.scss", "./public-pension-contract.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicPensionContractComponent {
  @Input()
  public contract!: Graph.PublicPensionContract;
  @Input()
  public engagement!: PublicPensionEngagement;

  public readonly isExpanded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly dialog: MatDialog,
    private readonly fmsService: FmsService,
    private readonly internalSavingsService: InternalSavingsService,
  ) {}

  public expandedChange(expanded: boolean): void {
    this.isExpanded$.next(expanded);
  }

  public isIncluded(): boolean {
    return this.internalSavingsService.isCustomerPension(this.engagement);
  }

  public getNameFromCoverage(coverage: Graph.AgeCoverage): string | undefined {
    return this.translate(
      `pensionCoverageDescriptions.usb.coverage.${coverage.coverageTypeCode}`,
      coverage.coverageName?.norwegian ?? undefined,
    );
  }

  public getDescriptionFromCoverage(coverage: Graph.AgeCoverage): string | undefined {
    return this.translate(`pensionCoverageDescriptions.usb.coverage.${coverage.coverageTypeCode}.description`);
  }

  public openDialog(coverage: Graph.AgeCoverage): void {
    const header = this.getNameFromCoverage(coverage);
    const content = this.getDescriptionFromCoverage(coverage);

    this.dialog.open(ModalDialogComponent, {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      autoFocus: false,
      restoreFocus: false,
      panelClass: "large-dialog-padding",
      data: { header, content },
    });
  }

  private translate(key: string, fallback?: string): string | undefined {
    const translation = this.fmsService.instant(key);
    return translation !== key ? translation : fallback;
  }
}
