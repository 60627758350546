import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { firstValueFrom, map, Observable } from "rxjs";
import { FmsService } from "src/app/services/fms.service";
import { getIsNullable } from "src/app/utils/utils";
import { ActionStyling } from "../action-card/action-card.component";
import { ActionAboutEpkDialogComponent } from "../action-epk-change-duration/action-about-epk-dialog/action-about-epk-dialog.component";
import { ActionEpkService } from "../../services/action-epk.service";
import { Action, ActionComponent } from "../actions/actions.component";

@Component({
  selector: "app-action-epk-move-to-storebrand",
  templateUrl: "./action-epk-move-to-storebrand.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActionEpkService],
})
export class ActionEpkMoveToStorebrandComponent implements ActionComponent {
  @Input()
  public readonly action!: Action;
  @Input()
  public readonly styling?: ActionStyling = ActionStyling.RegularAction;

  private readonly hasNoStbEpk$: Observable<boolean>;

  constructor(
    private readonly fmsService: FmsService,
    private readonly dialog: MatDialog,
    private readonly actionEpkService: ActionEpkService,
  ) {
    this.hasNoStbEpk$ = this.actionEpkService
      .getFirstEpkFromStorebrand()
      .pipe(map((engagement) => getIsNullable(engagement)));
  }

  public getIsVisible(): Observable<boolean> {
    return this.hasNoStbEpk$;
  }

  public async openMoveEpkApp(): Promise<void> {
    const exitUrl = window.location.href;

    window.open(
      await firstValueFrom(
        this.fmsService.translateAsync<string>("actions.epk.moveEpkAppUrl", {
          args: { exitUrl },
        }),
      ),
      "_blank",
    );
  }

  public openAboutEpkDialog(): void {
    this.dialog.open<ActionAboutEpkDialogComponent, Action>(ActionAboutEpkDialogComponent, {
      data: this.action,
      panelClass: "no-max-width",
    });
  }
}
