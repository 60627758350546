<mat-accordion>
  <mat-expansion-panel
    class="expansion"
    [expanded]="expanded"
    (expandedChange)="expandedChange.emit($event)"
    [hideToggle]="true"
    [class.padding-top]="!showTitle"
  >
    <mat-expansion-panel-header *ngIf="showTitle">
      <ng-content select="[title]"></ng-content>
    </mat-expansion-panel-header>

    <ng-content></ng-content>

    <mat-action-row *ngIf="showFooter">
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="footerExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-content select="[footerTitle]"></ng-content>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-content select="[footer]"></ng-content>
      </mat-expansion-panel>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
