import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { combineLatestWith, filter } from "rxjs/operators";
import { EngagementWithLockedPayoutAgeDetectorService } from "src/app/services/engagement-with-locked-payout-age-detector.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { getIsUserAddedEngagement } from "./engagement-footer.utils";

interface FooterRow {
  icon: string;
  text: string;
}

@Component({
  selector: "app-engagement-footer",
  templateUrl: "./engagement-footer.component.html",
  styleUrls: ["./engagement-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementFooterComponent implements OnChanges {
  @Input()
  public readonly engagement!: AnyEngagement;

  public footerRows: FooterRow[] = [];

  constructor(
    private readonly fms: FmsService,
    private readonly engagementWithLockedPayoutAgeDetectorService: EngagementWithLockedPayoutAgeDetectorService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {}

  public ngOnChanges(): void {
    const engagementPayoutAge = this.engagement.getPayoutFromAge();

    this.footerRows = [];

    if (getIsUserAddedEngagement(this.engagement)) {
      this.footerRows = [
        ...this.footerRows,
        {
          icon: this.fms.instant("engagement.footer.userAdded.iconSrc"),
          text: this.fms.instant("engagement.footer.userAdded.text"),
        },
      ];
    }

    this.engagementWithLockedPayoutAgeDetectorService
      .getHasLockedPayoutAge(this.engagement)
      .pipe(
        combineLatestWith(this.startPayoutAgeService.getStartPayoutAge()),
        filter(([hasLockedPayoutAge, startPayoutAge]) => {
          const isFirstPayoutAgeNotEqualStartPayoutAge = this.engagement.getPayoutFromAge() !== startPayoutAge;

          return hasLockedPayoutAge && isFirstPayoutAgeNotEqualStartPayoutAge;
        }),
      )
      .subscribe(() => {
        this.footerRows = [
          ...this.footerRows,
          {
            icon: this.fms.instant("engagement.footer.lockedPayoutAge.iconSrc"),
            text: this.fms.instant("engagement.footer.lockedPayoutAge.text", {
              args: { engagementPayoutAge: engagementPayoutAge ?? "-" },
            }),
          },
        ];
      });
  }
}
