<app-engagement-details-common
  *ngIf="isCommon()"
  [engagement]="engagement"
  [hasError]="hasError"
  [showSimulationParameters]="showSimulationParameters"
  [showPensionPlanEntry]="showPensionPlanEntry"
  [showDescription]="showDescription"
  [responsive]="responsive"
></app-engagement-details-common>

<app-engagement-details-afp
  *ngIf="isAfp(engagement)"
  [engagement]="engagement"
  [showSimulationParameters]="showSimulationParameters"
  [showDescription]="showDescription"
  [responsive]="responsive"
></app-engagement-details-afp>

<app-engagement-details-external
  *ngIf="isExternalSavings(engagement)"
  [engagement]="engagement"
  [hasError]="hasError"
  [showSimulationParameters]="showSimulationParameters"
  [responsive]="responsive"
></app-engagement-details-external>

<app-engagement-details-itp-unfunded
  *ngIf="isItpUnfunded(engagement)"
  [engagement]="engagement"
  [hasError]="hasError"
  [showSimulationParameters]="showSimulationParameters"
  [responsive]="responsive"
></app-engagement-details-itp-unfunded>

<app-engagement-details-other
  *ngIf="isOtherPension(engagement)"
  [engagement]="engagement"
  [hasError]="hasError"
  [showSimulationParameters]="showSimulationParameters"
  [showDescription]="showDescription"
  [responsive]="responsive"
></app-engagement-details-other>

<app-engagement-details-public-pension
  *ngIf="isPublicPension(engagement)"
  [engagement]="engagement"
  [hasError]="hasError"
  [showSimulationParameters]="showSimulationParameters"
  [responsive]="responsive"
></app-engagement-details-public-pension>
