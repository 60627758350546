import {
  ProductCodeLink,
  ProductCodeCollective,
} from "src/app/models/engagements/savings-and-pension/savings-and-pension-engagement.model";

export const engagementContractsMock: InvestmentReturns.Response = {
  payload: {
    linkContracts: [
      {
        contractNumber: "111",
        productCode: ProductCodeLink.AVLOS,
        productArea: <InvestmentReturns.ProductAreaEnum>"",
        startDate: "",
        annualPeriodicDepositSum: 0,
        marketValueSum: 100,
        investment: {
          subProduct: {
            allocation: <InvestmentReturns.AllocationEnum>"LN3",
          },
          holdings: [
            {
              name: "Elon Musk take my money fond",
              isin: "",
              marketValue: 100,
              numberOfShares: 0,
              fundPrice: 0,
              fundPriceDate: "",
            },
          ],
        },
      },
    ],
    collectiveContracts: [
      {
        contractNumber: "222",
        productCode: ProductCodeCollective.FPI1,
        productArea: <InvestmentReturns.ProductAreaEnum>"",
        pensionInsuranceType: <InvestmentReturns.PensionInsuranceTypeEnum>"",
        startDate: "",
        marketValueSum: 100,
        investment: {
          subProduct: {
            allocation: <InvestmentReturns.AllocationEnum>"TOMAL",
          },
          holdings: [
            {
              name: "Storebrand Balansert I",
              isin: "",
              marketValue: 24.0,
              numberOfShares: 0,
              fundPrice: 0,
              fundPriceDate: "",
            },
            {
              name: "Tesla Inc",
              isin: "",
              marketValue: 76,
              numberOfShares: 0,
              fundPrice: 0,
              fundPriceDate: "",
            },
          ],
        },
      },
      {
        contractNumber: "333",
        productCode: ProductCodeCollective.FPI1,
        productArea: <InvestmentReturns.ProductAreaEnum>"",
        pensionInsuranceType: <InvestmentReturns.PensionInsuranceTypeEnum>"",
        startDate: "",
        marketValueSum: 100,
        investment: {
          subProduct: {
            allocation: <InvestmentReturns.AllocationEnum>"REBA1",
          },
          holdings: [
            {
              name: "Berkshire Hathaway fond",
              isin: "",
              marketValue: 100,
              numberOfShares: 0,
              fundPrice: 0,
              fundPriceDate: "",
            },
          ],
        },
      },
    ],
  },
};
