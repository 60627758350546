import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-engagement-list-initiatives-item-number",
  templateUrl: "./engagement-list-initiatives-item-number.component.html",
  styleUrls: ["./engagement-list-initiatives-item-number.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesItemNumberComponent {
  @Input()
  public number!: number;

  @Input()
  public hideNumber!: boolean;
}
