import { HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * HttpCache is the abstract base class for all HttpCache implementations.
 * It serves to expose the accessor behaviors to any interceptors.
 * Request matching and cache invalidation must be handled in the
 * implementations.
 *
 * Change the active implementation by modifying the provider in app.module
 *
 * For more info please refer to
 * https://angular.io/guide/http#intercepting-all-requests-or-responses
 */
export abstract class HttpCache {
  /**
   * Returns a cached response, if any, or null if not present.
   */
  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;

  /**
   * Adds or updates the response in the cache.
   */
  abstract put(req: HttpRequest<any>, resp: HttpResponse<any>): void;
}

const httpCache: Map<HttpRequest<any>, HttpResponse<any>> = new Map();

/**
 * HttpCacheImpl is a very simple cache all implementation of HttpCache.
 *
 * Beware, HttpRequest.clone() does _NOT_ create a deep clone of the body,
 * instead returning a reference. This means it's possible to operate with
 * a dirty cache if the body mutates, potentially creating bugs that are
 * hard to track down. For performance reasons this behavior is intended
 * by Angular https://github.com/angular/angular/issues/41454
 *
 * Significant effort has been spent on debugging dirty caches in the
 * past, resolved by costly use of 'lodash/cloneDeep' on the body. With
 * recent changes to tooling (Immer) and lint rules (fp/no-mutation) the
 * application is in a better spot in terms of helping developers detect
 * and remove unintended mutations. As such, the cache no longer clones
 * the body.
 */
@Injectable()
export class HttpCacheImpl extends HttpCache {
  get<T>(req: HttpRequest<any>): HttpResponse<T> | undefined {
    return httpCache.get(this.requestMatcher(req))?.clone();
  }

  put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    httpCache.set(this.requestMatcher(req), resp);
  }

  private requestMatcher(req: HttpRequest<any>): any {
    return Object.entries({
      url: req.url,
      body: req.body,
      method: req.method,
      headers: req.headers,
      params: req.params,
      urlWithParams: req.urlWithParams,
    }).toString();
  }
}
