<stb-form [label]="label" size="flex" [hint]="tip">
  <select
    [ngModel]="selectedAge$ | async"
    (change)="onPayoutAgeChange($event)"
    id="startPayoutAge"
    [disabled]="!(isCurrentYearLoaded$ | async)"
  >
    <option *ngIf="showCustomWithdrawalOrderOption$ | async" [value]="CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE">
      {{ "startPayoutAgeFormField.option.custom" | fms }}
    </option>

    <option *ngFor="let val of startPayoutAgeRange$ | async" [value]="val">
      {{ val | year }}
    </option>
  </select>
</stb-form>
