import { CustomerProgramsResponse } from "../benefits.service";

export const benefitsCheckMock: CustomerProgramsResponse = {
  programs: [
    {
      benefits: [
        {
          code: "123",
          description: {
            no: "Gjelder ppå alt",
          },
          name: "10% rabatt ekstra på forsikring",
        },
        {
          code: "124",
          description: {},
          name: "Boliglånrente",
        },
        {
          code: "52",
          description: {
            no: "I Individuell pensjonssparing (IPS) får du lavere forvaltningskostnader for våre spareprofiler",
          },
          name: "Spar i IPS",
        },
        {
          code: "58",
          description: {
            no: "Lavere forvaltningskostnad på våre spareprofiler\nDette er en endring i tekst bare for å teste",
          },
          name: "Spar i Fondskonto",
        },
        {
          code: "70",
          description: {
            no: "Med Boliglån Ung får du som er under 34 år ekstra lav boliglånsrente fra 1,39 %. Klikk her for å søke.",
          },
          name: "Boliglån Ung – superlav rente",
        },
        {
          code: "3",
          description: {
            no: "Lån med svært gunstige betingelser til ansatte i Storebrand.",
          },
          name: "Boliglån",
        },
        {
          code: "24",
          description: {
            no: "Rask behandling av de beste spesialistene og garantert sykehusbehandling innen 14 dager.",
          },
          name: "Lavere pris på helseforsikring",
        },
        {
          code: "20",
          description: {
            no: "Lavere forvaltningskostnad for spareløsningen Anbefalt Pensjon og våre spareprofiler",
          },
          name: "Spar i Ekstrapensjon",
        },
        {
          code: "10",
          description: {
            no: "20 % rabatt på forsikring av bil, hus, innbo, hytte m.m.",
          },
          name: "Lavere pris på forsikring",
        },
        {
          code: "17",
          description: {
            no: "Boligkreditt med fleksibel nedbetaling inntil 70% av boligens verdi. Beste rente får du som helkunde når du bruker Storebrand Bank aktiv som hovedbank, med lønnskonto, nettbank, VISA-kort og kredittkort. Dette gjelder også for låntaker og eventuell medlåntager.",
          },
          name: "Boligkreditt (rammelån)",
        },
        {
          code: "32",
          description: {
            no: "Førstehjemslån inntil 85% av boligens verdi. Inntil 30 års nedbetalingstid.  Beste rente får du som helkunde når du bruker Storebrand Bank aktiv",
          },
          name: "Førstehjemslån",
        },
        {
          code: "30",
          description: {
            no: "Kontoen for deg over 60 år. Din pensjon fra Storebrand blir utbetalt til kontoen med vår beste rente fra første krone. Ubegrenset antall uttak.",
          },
          name: "Smartkonto",
        },
        {
          code: "31",
          description: {
            no: "Kontoen for deg over 60 år.  Din pensjon i Storebrand Livsforsikring utbetalt til kontoen. Vår beste rente fra første krone. Ubegrenset antall uttak.",
          },
          name: "Smartkonto",
        },
        {
          code: "29",
          description: {
            no: "Velg et fond som er tilpasset din sparehorisont! Aksjeandelen trappes gradvis ned slik at risikoen reduseres når utbetalingstidspunktet nærmer seg",
          },
          name: "Storebrand Pensjonsfond",
        },
        {
          code: "5",
          description: {
            no: "Høyere utbetaling ved sykehusopphold",
          },
          name: "Barneforsikring",
        },
        {
          code: "38",
          description: {
            no: "10 % lavere pris på forsikringen Kritisk sykdom",
          },
          name: "Kritisk Sykdom",
        },
        {
          code: "37",
          description: {
            no: "Ingen årsgebyr på Visa- og kredittkort. Gebyrfrihet i hverdagen med høy rente på brukskonto. Gratis reise- og avbestillingsforsikring på kredittkort",
            en: "ENGLISH TRANSLAION of description Ingen årsgebyr på Visa- og kredittkort. Gebyrfrihet i hverdagen med høy rente på brukskonto. Gratis reise- og avbestillingsforsikring på kredittkort",
          },
          name: "Fordelskonto",
        },
      ],
      name: "Fordelsprogram for ansatte",
      code: "1",
      organizationNumber: "XXXXXXXX",
      organizationName: "Test ORG AS",
      type: "B2B2C",
    },
  ],
};
