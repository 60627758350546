import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import { EngagementsService } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { AfpService } from "src/app/services/prognoses-services/afp.service";
import { getIsNullable, Nullable } from "src/app/utils/utils";
import {
  AbstractNewEngagementStepperComponent,
  AddEngagementStep,
} from "../../../../shared/components/add-engagement/abstract-new-engagement-stepper.component";
import { ProfileService } from "../../../../../services/customer-supplied-data/profile.service";

export class AfpEngagementComponentError extends Error {
  name = "AfpEngagementComponentError";
}

@Component({
  selector: "app-afp-engagement",
  templateUrl: "./afp-engagement.component.html",
  styleUrls: ["./afp-engagement.component.scss", "../../../../shared/components/add-engagement/add-engagement.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AfpEngagementComponent extends AbstractNewEngagementStepperComponent<AfpEngagementComponent> {
  public readonly afpValue$: Observable<Nullable<number>>;
  public readonly hasAfpValue$: Observable<boolean>;
  public readonly hasOffentligTjenestepensjonFlag$: Observable<boolean>;

  private afpEngagement: AfpEngagement = new AfpEngagement({});

  constructor(
    private readonly afpService: AfpService,
    private readonly fmsService: FmsService,
    private readonly pensionPlanService: PensionPlanService,
    protected readonly dialogRef: MatDialogRef<AfpEngagementComponent>,
    public readonly engagementsService: EngagementsService,
    private readonly profileService: ProfileService,
  ) {
    super(dialogRef);

    this.engagementSteps = this.fmsService
      .instant<Omit<AddEngagementStep, "controlToValidate">[]>("afpEngagement.fmsEngagementSteps")
      .map((fmsSteps) => ({ ...fmsSteps, controlToValidate: undefined }));

    const afpPrognosis$ = this.afpService.prognosisSimParams$().pipe(
      switchMap((params) => this.afpService.fetchPrognosis(this.afpEngagement, params)),
      map((result) => {
        const prognosis = result.prognoses.at(0);
        if (getIsNullable(prognosis)) {
          throw new AfpEngagementComponentError("AFP prognosis did not arrive");
        }
        return prognosis;
      }),
    );

    this.afpValue$ = afpPrognosis$.pipe(
      map((prognosis) => {
        this.afpEngagement = this.afpEngagement.withPrognosis(prognosis);
        return this.afpEngagement?.getAverageAnnualPayout();
      }),
    );

    this.hasAfpValue$ = this.afpValue$.pipe(
      switchMap(() => this.profileService.hasAfp$),
      map((result: boolean): boolean => result),
      startWith(false),
    );

    this.hasOffentligTjenestepensjonFlag$ = this.engagementsService.hasOffentligTjenestepensjonFlag$;
  }

  public async submit(): Promise<void> {
    if (!this.afpEngagement) {
      throw new AfpEngagementComponentError("Cannot add AFP because the engagement is missing");
    }

    return this.pensionPlanService.addAfpEngagement(this.afpEngagement);
  }
}
