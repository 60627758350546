<div class="contracts" *appLet="readyToRender$ | async as readyToRender">
  <ng-container
    *appLet="'engagementList.savings.title' | fms as title"
    [ngTemplateOutlet]="ContractList"
    [ngTemplateOutletContext]="{
      headerText: getHeaderText({ title }),
      contractItems: savingsContractItems$ | async,
      skeletons: 1,
      readyToRender: readyToRender,
      emptyContractItemsOutlet: emptySavings
    }"
  ></ng-container>

  <ng-template #emptySavings>
    <a [routerLink]="savingsPageRouterLink">
      <button stbButton="text" iconType="add" [setIconBefore]="true" data-trackid="start-own-savings-for-pension">
        {{ "engagementList.savings.emptyButtonText" | fms }}
      </button>
    </a>
  </ng-template>

  <ng-container
    *appLet="'engagementList.employment.title' | fms as title"
    [ngTemplateOutlet]="ContractList"
    [ngTemplateOutletContext]="{
      headerText: getHeaderText({ title }),
      contractItems: employmentContractItems$ | async,
      skeletons: 1,
      readyToRender: readyToRender,
      emptyContractItemsOutlet: emptyEmployment,
      ctaOutlet: ctaOutlet
    }"
  >
  </ng-container>

  <ng-template #ctaOutlet>
    <app-offentlig-tjeneste-cta-card></app-offentlig-tjeneste-cta-card>

    <app-add-afp-cta-card *ngIf="showAddAfpCard$ | async"></app-add-afp-cta-card>
  </ng-template>

  <ng-template #emptyEmployment>
    <button
      stbButton="text"
      iconType="arrow-right"
      [setIconAfter]="true"
      (click)="openLink('engagementList.employment.emptyButtonUrl')"
      data-trackid="contractList-emptyEmployment"
    >
      {{ "engagementList.employment.emptyButtonText" | fms }}
    </button>
  </ng-template>

  <ng-container
    *appLet="'engagementList.public.title' | fms as title"
    [ngTemplateOutlet]="ContractList"
    [ngTemplateOutletContext]="{
      headerText: getHeaderText({ title }),
      contractItems: publicContractItems$ | async,
      skeletons: 1,
      defaultExpanded: true,
      readyToRender: readyToRender,
      emptyContractItemsOutlet: emptyPublic
    }"
  ></ng-container>

  <ng-template #emptyPublic>
    {{ "engagementList.public.emptyText" | fms }}
  </ng-template>
</div>

<ng-template
  #ContractList
  let-headerText="headerText"
  let-contractItems="contractItems"
  let-skeletons="skeletons"
  let-defaultExpanded="defaultExpanded"
  let-readyToRender="readyToRender"
  let-emptyContractItemsOutlet="emptyContractItemsOutlet"
  let-ctaOutlet="ctaOutlet"
>
  <div class="content">
    <div class="content-header">
      <h2 class="column-title">
        {{ headerText.title }}
      </h2>
    </div>

    <div class="content-list" *ngIf="readyToRender; else loading">
      <mat-accordion
        *ngIf="contractItems.length > 0; else EmptyContractItems"
        [@listAnimation]="contractItems.length"
        multi
      >
        <app-contract
          *ngFor="let contractItem of contractItems; trackBy: trackById"
          [id]="contractItem.contract.getIdentifier()"
          [contract]="toAnyEngagement(contractItem.contract)"
          [loading]="false"
          [expanded]="contractItem.expanded"
          [defaultExpanded]="defaultExpanded && contractItems.length === 1"
        ></app-contract>
      </mat-accordion>

      <ng-template #EmptyContractItems>
        <div class="empty-contract-items">
          <ng-container [ngTemplateOutlet]="emptyContractItemsOutlet"></ng-container>
        </div>
      </ng-template>
      <ng-container [ngTemplateOutlet]="ctaOutlet"></ng-container>
    </div>

    <ng-template #loading>
      <div class="content-loading">
        <app-contract *ngFor="let i of [].constructor(skeletons)" [loading]="true"></app-contract>
      </div>
    </ng-template>
  </div>
</ng-template>
