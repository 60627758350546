export const clientId = "nyt";
export const logoutUrl = "https://www.storebrand.no/logg-ut/";
export const apiJson = "/api.json";
export const fmsApi = "/open/content/texts/nyt";
export const localFmsApi = "fms";

export const consentsApi = "/customer/consents";
export const customerApi = "/customer/core-data.v3";
export const epkHubApi = "/pension-core/pkr/person";

export const benefitsCheck = "/benefits/programs/customers";

const prognosis = "/savings/shared/prognosis";
export const prognosisLifeNorskpensjonApi = `${prognosis}/external/norskpensjon`;
export const prognosisProductsInternalApi = `/open${prognosis}/products-internal`;

export const bankAccountsApi = "/bank/accounts";

export const customerSuppliedDataApi = "/customer/supplied-data";

// a value starting with http:// will be interpreted "as-is", without being prefixed
// with this.apiHomeService.apiHome$
// const rmRestApi = "http://localhost:4200/savings/retirement-management";
const rmRestApi = "/savings/retirement-management";
export const summaryPdfApi = `${rmRestApi}/pdf/summary`;
export const eligibilityCheckApi = `${rmRestApi}/smartaccount/eligible`;
export const rmLeadsApi = `${rmRestApi}/leads`;

export const linkProductPurchasesApi = "/savings/link/productpurchases";

export const graphApi = "/savings/graph/graphql";

export const smartAccountCreationStatusApi = "/me/customer-account";
