import { isEmpty } from "lodash-es";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { InvalidPrognosisArgumentError } from "src/app/utils/errors";
import { getIsNullable, Nullable } from "src/app/utils/utils";
import { DEFAULT_SIMULATION_AGE } from "../../constants/business.constants";

const HUNDRED_PERCENT = 100;

export interface WithdrawalPeriodsParams {
  startPayoutAge: number;
  salary: number;
  partTimePercent?: number;
  retirementAges: Graph.RetirementAge[];
}

export interface PartialWithdrawalPeriodsParams {
  startPayoutAge: number;
  startPayoutAgePartialWithdrawal?: number;
  salary: number;
  partTimePercentage?: number;
  withdrawalPercentage?: number;
  retirementAges: Graph.RetirementAge[];
}

export function createFullWithdrawalVariables(
  params: WithdrawalPeriodsParams,
  hasAfp$?: Graph.InputMaybe<boolean> | undefined,
): Graph.QueryNorskpensjonArgs {
  const { salary, retirementAges, startPayoutAge, partTimePercent } = params;
  const fromDate = getRetirementDateByAge(startPayoutAge, retirementAges);

  if (getIsNullable(fromDate) || isEmpty(fromDate)) {
    throw new InvalidPrognosisArgumentError(params, "Could not find retirementDate");
  }

  const salaryAfterFullWithdrawal =
    salary && partTimePercent ? getSalaryBasedOnPartTime(salary, partTimePercent) : null;

  return {
    input: {
      simulationParameters: {
        withdrawalPeriods: {
          salaryBeforeWithdrawal: salary,
          fullWithdrawal: {
            fromDate,
            salaryAfterFullWithdrawal,
          },
        },
        hasAfp: hasAfp$ ?? false,
      },
    },
  };
}

export function createPartialWithdrawalVariables(params: PartialWithdrawalPeriodsParams): Graph.QueryNorskpensjonArgs {
  const {
    salary,
    retirementAges,
    startPayoutAge,
    startPayoutAgePartialWithdrawal,
    partTimePercentage,
    withdrawalPercentage,
  } = params;
  const fromDateFullWithdrawal = getRetirementDateByAge(startPayoutAge, retirementAges);
  const fromDatePartialWithdrawal = getRetirementDateByAge(
    startPayoutAgePartialWithdrawal ?? DEFAULT_SIMULATION_AGE,
    retirementAges,
  );

  if (
    getIsNullable(fromDateFullWithdrawal) ||
    isEmpty(fromDateFullWithdrawal) ||
    getIsNullable(fromDatePartialWithdrawal) ||
    isEmpty(fromDatePartialWithdrawal)
  ) {
    throw new InvalidPrognosisArgumentError(params, "Could not find retirementDate");
  }

  const adjustedSalary = salary && partTimePercentage ? getSalaryBasedOnPartTime(salary, partTimePercentage) : 0;

  return {
    input: {
      simulationParameters: {
        withdrawalPeriods: {
          salaryBeforeWithdrawal: salary,
          fullWithdrawal: {
            fromDate: fromDateFullWithdrawal,
          },
          partialWithdrawal: {
            fromDate: fromDatePartialWithdrawal,
            salaryInPartialWithdrawalPeriod: adjustedSalary,
            withdrawalPercentage,
          },
        },
      },
    },
  };
}

export function getSalaryBasedOnPartTime(salary: number, partTimePercent: number): number {
  const partialSalaryFloat = salary * (partTimePercent / HUNDRED_PERCENT);

  return Number(partialSalaryFloat.toFixed(0));
}

export function getRetirementDateByAge(age: number, retirementAges: Graph.RetirementAge[]): Nullable<string> {
  const searchResult = retirementAges?.find((retirementAge) => retirementAge.age === age);

  return searchResult?.date ?? undefined;
}
