import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
  ExternalSavingsEngagement,
  ExternalSavingsEngagementEvent,
  ExternalSavingsEngagementType,
} from "src/app/models/engagements/external-savings-engagement.model";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";
import { EngagementService } from "src/app/services/engagement.service";

@Component({
  selector: "app-engagement-details-external",
  templateUrl: "./engagement-details-external.component.html",
  styleUrls: ["../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsExternalComponent implements EngagementBody {
  @Input()
  public engagement!: ExternalSavingsEngagement;
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;

  constructor(private readonly engagementService: EngagementService) {}

  public isBank(): boolean {
    return this.engagement.getType() === ExternalSavingsEngagementType.Bank;
  }

  public isPeriodic(): boolean {
    return this.engagement.getEvent() === ExternalSavingsEngagementEvent.Periodic;
  }

  public getTotalPayout(): number | null {
    return this.engagement.getTotalPayout();
  }

  public getDuration(): string {
    return this.engagementService.getHumanReadableDuration(this.engagement);
  }
}
