import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from "rxjs";
import { combineLatestWith, map, mergeMap } from "rxjs/operators";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { MonthlyDeposit } from "../models/engagements/contract-interfaces";
import { EkstrapensjonEngagement } from "../models/engagements/savings-and-pension/link-engagement.model";
import { isEkstrapensjonEngagement } from "../utils/engagement.typeguards";
import { memoize$ } from "../utils/rxjs/select";
import { isInMinMax, Nullable } from "../utils/utils";
import { CustomerService } from "./customer.service";
import { AnyEngagement, EngagementsService } from "./engagements.service";
import { FmsService } from "./fms.service";
import { IncomeService } from "./income/income.service";

interface Income {
  minIncome: number;
  maxIncome: number;
  oneTimeAmount: number;
  othersAmount: number;
  savingAgreementAmount: number;
}

interface SavingsAmount {
  maxAge: number;
  minAge: number;
  income: Income[];
  oneTimeAmount: number;
  othersAmount: number;
}

export enum ExtrapensionState {
  UnqualifiedToChange,
  HasOneWithSingleDeposit,
  HasOneWithRecurringDeposit,
  HasMultiple,
}

@Injectable({
  providedIn: "root",
})
export class ExtrapensionService {
  public readonly extrapensionState$: Observable<ExtrapensionState | undefined>;
  private readonly override$ = new BehaviorSubject<ExtrapensionState | undefined>(undefined);

  constructor(
    private readonly customerService: CustomerService,
    private readonly engagementsService: EngagementsService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly fmsService: FmsService,
    private readonly incomeService: IncomeService,
  ) {
    this.extrapensionState$ = memoize$(
      combineLatest([this.getExistingStbEkstrapensjons(), this.override$.asObservable()]).pipe(
        map(([activeStbExtrapensions, override]) =>
          hasOverride(override) ? override : deduceEkstrapensjonState(activeStbExtrapensions),
        ),
      ),
    );
  }

  public async getRecommendedSavingsAmount(age: number, salary: number): Promise<number> {
    const savingsAmount: SavingsAmount[] = await firstValueFrom(
      this.fmsService.translateAsync("extraPension.savingsAmount"),
    );

    const savingAgreementAmounts = savingsAmount
      .filter(({ minAge, maxAge }) => isInMinMax(minAge, maxAge, age))
      .flatMap(({ income }) =>
        income
          .filter(({ minIncome, maxIncome }) => isInMinMax(minIncome, maxIncome, salary))
          .map(({ savingAgreementAmount }) => savingAgreementAmount),
      );

    return savingAgreementAmounts.at(0) ?? 0;
  }

  public getRecommendendSavingMonthly(): Observable<number> {
    return this.customerService.age$.pipe(
      combineLatestWith(this.incomeService.annualGrossIncome$),
      mergeMap(([customerAge, annualGrossIncome]) =>
        this.getRecommendedSavingsAmount(customerAge ?? 0, annualGrossIncome ?? 0),
      ),
    );
  }

  public overrideExtrapension(state: ExtrapensionState): void {
    this.override$.next(state);
  }

  public getExistingStbEkstrapensjons(): Observable<EkstrapensjonEngagement[]> {
    return this.engagementsService.allEngagements$.pipe(
      this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe(),
      map<AnyEngagement[], EkstrapensjonEngagement[]>((engagements) => engagements.filter(isEkstrapensjonEngagement)),
    );
  }
}

function deduceEkstrapensjonState(ekstrapensjonContracts: MonthlyDeposit[]): ExtrapensionState {
  if (ekstrapensjonContracts.length === 1) {
    return ekstrapensjonContracts[0].getMonthlySavingsDeposit() > 0
      ? ExtrapensionState.HasOneWithRecurringDeposit
      : ExtrapensionState.HasOneWithSingleDeposit;
  }

  if (ekstrapensjonContracts.length > 1) {
    return ExtrapensionState.HasMultiple;
  }

  return ExtrapensionState.UnqualifiedToChange;
}

function hasOverride(override: Nullable<ExtrapensionState>): boolean {
  return override != null;
}
