<ng-container [ngSwitch]="consentCardState$ | async">
  <app-nav-consent-cta-card *ngSwitchCase="ConsentCardState.NavConsent"></app-nav-consent-cta-card>

  <app-norskpensjon-consent-cta-card
    *ngSwitchCase="ConsentCardState.NorskPensjonConsent"
    [title]="title"
    [enableSalaryRequirement]="isPensionPlanContext"
  ></app-norskpensjon-consent-cta-card>

  <app-norskpensjon-requirements-cta-card
    *ngSwitchCase="ConsentCardState.AuthRequirement"
  ></app-norskpensjon-requirements-cta-card>
</ng-container>
