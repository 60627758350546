import { ChangeDetectionStrategy, Component } from "@angular/core";
import { isBoolean } from "lodash-es";
import { Observable, distinctUntilChanged, firstValueFrom, map } from "rxjs";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { PublicPensionService } from "src/app/services/prognoses-services/public-pension.service";
import { PublicPensionProgosisParametersDynamicService } from "../../../../services/api/public-pension-prognosis-parameters-dynamic.service";
import { FetchPrognosesRunningJobsService } from "../../../../services/running-jobs/fetch-prognoses-running-jobs.service";

@Component({
  selector: "app-post-pension-part-time-inputs",
  templateUrl: "./post-pension-part-time-inputs.component.html",
  styleUrls: ["./post-pension-part-time-inputs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostPensionPartTimeInputsComponent {
  public readonly disableFormfields$: Observable<boolean>;

  public readonly postPensionPartTimeEnable$ =
    this.commonParametersService.postPensionPartTimeEnable$.pipe(distinctUntilChanged());

  public readonly publicPensionEngagementWithSimParams$ =
    this.publicPensionService.hotStreamsOfSimulationParametersByEngagement$;

  public readonly publicPensionEngagementSimParams$ = this.publicPensionEngagementWithSimParams$.pipe(
    map(([, [, params]]) => params),
  );

  public readonly publicPensionEngagement$ = this.publicPensionEngagementWithSimParams$.pipe(
    map(([engagement]) => engagement),
  );

  public readonly canChangePublicPensionWorkingPercentage$: Observable<boolean>;

  public readonly showPublicPensionWithdrawalPercentFormField$: Observable<boolean>;

  public readonly showExpectedFutureIncomeFormField$: Observable<boolean>;

  private readonly params$ = this.progosisParametersDynamicService.dynamicPublicPensionPrognosisParameters$;

  constructor(
    private readonly commonParametersService: CommonParametersService,
    private readonly pensionPlanService: PensionPlanService,
    private readonly publicPensionService: PublicPensionService,
    private readonly progosisParametersDynamicService: PublicPensionProgosisParametersDynamicService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
  ) {
    this.disableFormfields$ = this.fetchPrognosesRunningJobsService.isLoading$;

    this.canChangePublicPensionWorkingPercentage$ = this.params$.pipe(
      map(({ canChangeWorkingPercentage }) => !!canChangeWorkingPercentage),
    );

    this.showPublicPensionWithdrawalPercentFormField$ = this.params$.pipe(
      map(({ canChangeWithdrawalPercentage }) => !!canChangeWithdrawalPercentage),
    );

    this.showExpectedFutureIncomeFormField$ = this.params$.pipe(
      map(({ canChangeFutureSalary }) => !!canChangeFutureSalary),
    );
  }

  public async onEnableChange(value: boolean | Event): Promise<void> {
    if (isBoolean(value)) {
      const canChange = await firstValueFrom(this.canChangePublicPensionWorkingPercentage$);
      const postPensionPartTimeEnable = !!canChange && value;

      this.pensionPlanService.changePostPensionPartTimeEnable(postPensionPartTimeEnable);
    }
  }
}
