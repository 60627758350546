import { ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { ExtraErrorData } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import { Breadcrumb, BreadcrumbHint, Event, SeverityLevel } from "@sentry/types";
import { Log } from "src/app/utils/log";
import { getStbStage, isNotStageP, isStageLocalhost } from "src/app/utils/storebrand-staging";
import { AppInjector } from "../injector";
import { getDatadogLogger } from "@storebrand-digital/common-datadog";

enum MonitoringLevel {
  Debug = "debug",
  Info = "info",
  Warn = "warn",
  Error = "error",
  Fatal = "fatal",
}

type MonitoringError = Error | object | string;

abstract class Utils {
  public static isIgnoredError(event: Event): boolean {
    /**
     * When Google Translate chrome extension is activated,
     * it floods sentry with errors that we cannot fix. So we ignore them instead.
     * @see https://medium.com/@amir.harel/a-b-target-classname-indexof-is-not-a-function-at-least-not-mine-8e52f7be64ca
     */
    const isGoogleTranslateError = (ev: Event): boolean =>
      ev.exception?.values?.some((value) => value.value?.includes("a[b].target.className.indexOf is not a function")) ??
      false;

    return isGoogleTranslateError(event);
  }

  public static mapSeverity(level: Monitoring.Level): SeverityLevel {
    switch (level) {
      case MonitoringLevel.Fatal:
        return "fatal";
      case MonitoringLevel.Error:
        return "error";
      case MonitoringLevel.Warn:
        return "warning";
      case MonitoringLevel.Info:
        return "info";
      case MonitoringLevel.Debug:
        return "debug";
      default:
        return "error";
    }
  }
}

export abstract class Monitoring {
  public static Level = MonitoringLevel;

  private static readonly defaultOptions: Partial<Monitoring.Options> = {
    environment: getStbStage(),
    beforeSend: Monitoring.beforeSend,
    beforeBreadcrumb: Monitoring.beforeBreadcrumb,
    attachStacktrace: true,
  };

  /**
   * Initialize the monitoring tool.
   *
   * @param depth - Temporary parameter while normalizeDepth must be one above ExtraErrorData.depth, see https://github.com/getsentry/sentry-javascript/issues/2539
   */
  public static init(options: Monitoring.Options, depth = 3): void {
    if (!isStageLocalhost()) {
      Sentry.init({
        ...this.defaultOptions,
        integrations: [
          new ExtraErrorData({ depth: depth }),
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          }),
          new Integrations.BrowserTracing({
            tracingOrigins: [],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        normalizeDepth: depth + 1,
        tracesSampleRate: 0.25,
        ...options,
      });
    }
  }

  public static setUser(user: Monitoring.User): void {
    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }

  public static getTraceService(): typeof Sentry.TraceService {
    return Sentry.TraceService;
  }

  public static debug(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    this.send(error, { ...options, level: Monitoring.Level.Debug });
  }

  public static info(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    this.send(error, { ...options, level: Monitoring.Level.Info });
  }

  public static warn(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    this.send(error, { ...options, level: Monitoring.Level.Warn });
  }

  public static error(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    this.send(error, { ...options, level: Monitoring.Level.Error });
  }

  public static fatal(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    this.send(error, { ...options, level: Monitoring.Level.Fatal });
  }

  public static message(message: string, level = Monitoring.Level.Info): void {
    Sentry.captureMessage(message, Utils.mapSeverity(level));
  }

  private static beforeSend(event: Event): Event | null {
    if (Utils.isIgnoredError(event)) {
      return null;
    }

    if (isNotStageP()) {
      const log = (...values: unknown[]): void => {
        Log.trace("[Sentry]", ...values);
      };

      if (event.exception) {
        // Exception sent using Sentry.captureException
        log(...(event.exception.values ?? []));
      } else if (event.message) {
        // Message sent using Sentry.captureMessage
        log(event.message);
      } else {
        log(event);
      }
    }

    return event;
  }

  private static beforeBreadcrumb(breadcrumb: Breadcrumb, hint: BreadcrumbHint | undefined): Breadcrumb | null {
    if (breadcrumb.category !== "ui.click") {
      return breadcrumb;
    }

    const { id, innerText, localName, className } = hint?.event?.target ?? {};
    const idProp = id.length > 0 ? ` id=${id}` : "";
    const text = innerText.length > 50 ? `${innerText.substring(0, 35)}...[truncated]` : innerText;

    return {
      ...breadcrumb,
      message: `${breadcrumb.message} --> <${localName} class=${className}${idProp}>${text}</${localName}>`,
    };
  }

  private static send(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    if (options.ignore) {
      return;
    }

    const capture = (err: MonitoringError): void | string => {
      /**
       * When in Angular context use Angular's ErrorHandler.
       * Errors that occur before or during bootstrapping must use the default captureException.
       */
      const errorHandler = AppInjector?.get(ErrorHandler);
      const captureException = errorHandler?.handleError.bind(errorHandler) || Sentry.captureException;

      return typeof err === "string" ? this.message(err, options.level || this.Level.Error) : captureException(err);
    };

    Sentry.withScope((scope) => {
      const { extras, tags, level } = options;

      if (tags) {
        scope.setTags(tags);
      }

      if (extras) {
        scope.setExtras(extras);
      }

      if (level) {
        scope.setLevel(Utils.mapSeverity(level));
      }

      capture(error);
    });

    this.sendDatadogEvent(error, options);
  }

  private static sendDatadogEvent(error: MonitoringError, options: Monitoring.ErrorOptions = {}): void {
    return getDatadogLogger().error(JSON.stringify(error), options);
  }
}

export namespace Monitoring {
  export interface User {
    [key: string]: any;
  }

  export interface Extra {
    [key: string]: any;
    message?: string;
  }

  export interface ErrorOptions {
    tags?: Extra;
    extras?: Extra;
    level?: Level;
    ignore?: boolean;
  }

  export interface Options extends Sentry.BrowserOptions {
    dsn: string;
    release: string;
  }

  export type Level = MonitoringLevel;
}
