import { NamedOptions } from "apollo-angular";
import { Log } from "src/app/utils/log";
import {
  combineApolloLinks,
  createMockSchemaLinkFromResolverMaps,
  createResponseLoggerLink,
} from "../../utils/apollo-link-utils";
import { SAVINGS_GRAPH_CLIENT_CACHE, SAVINGS_GRAPH_CLIENT_ID } from "../savings-graph/savings-graph-client";
import { typeDefs } from "../savings-graph/savings-graph-typeDefs";
import {
  navResolverMap,
  norskpensjonResolverMap,
  publicPensionPrognosisParametersResolverMap,
  publicPensionResolverMap,
  savingsEngagementResolverMap,
  simulationParametersResolverMap,
} from "./mock-savings-graph-resolvers";

export function mockSavingsGraphClientFactory(): NamedOptions {
  const link = combineApolloLinks([
    createResponseLoggerLink((operation, fetchResult) => {
      Log.info("savings-graph", operation.operationName, fetchResult);
    }),
    createMockSchemaLinkFromResolverMaps(typeDefs, [
      simulationParametersResolverMap,
      publicPensionResolverMap,
      norskpensjonResolverMap,
      navResolverMap,
      savingsEngagementResolverMap,
      publicPensionPrognosisParametersResolverMap,
    ]),
  ]);
  return {
    [SAVINGS_GRAPH_CLIENT_ID]: {
      cache: SAVINGS_GRAPH_CLIENT_CACHE,
      link,
    },
  };
}
