import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import { CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE } from "../constants/technical.constants";
import { GenericEngagementOfUnknown } from "../models/engagements/generic-engagement.model";
import { OtherPensionEngagement } from "../models/engagements/other-pension-engagement.model";
import { ChangeReason, SimulationHistoryService } from "../modules/shared/services/simulation-history.service";
import { isExternalSavingsEngagement, isOtherPensionEngagement } from "../utils/engagement.typeguards";
import { getIsNotNullable } from "../utils/utils";
import { CommonParametersService, SimulationParametersByEngagement } from "./common-parameters.service";
import { ProfileService } from "./customer-supplied-data/profile.service";
import { AnyEngagement } from "./engagements.service";
import { InternalSavingsService } from "./internal-savings.service";
import { OtherPensionEngagementsService } from "./prognoses-services/other-pension-engagements.service";

type OtherPensionEngagementUpdate = Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension">;

export enum PensionPlanChangeEvent {
  EngagementInclusionChanged,
}

@Injectable({
  providedIn: "root",
})
export class PensionPlanService {
  public readonly pensionPlanChanges$ = new Subject<PensionPlanChangeEvent>();

  constructor(
    private readonly commonParametersService: CommonParametersService,
    private readonly otherPensionEngagementsService: OtherPensionEngagementsService,
    private readonly profileService: ProfileService,
    private readonly simulationHistoryService: SimulationHistoryService,
    private readonly internalSavingsService: InternalSavingsService,
  ) {}

  public async changeStartPayoutAge(startPayoutAge: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.StartPayoutAgeChanged);

    const customizedPayoutAge = startPayoutAge === CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE;

    if (customizedPayoutAge) {
      this.commonParametersService.setSimulationParameter({
        simulationParametersByEngagementEnable: true,
        simulationParametersPartialWithdrawalEnable: false,
      });
    } else {
      this.commonParametersService.setSimulationParameter({
        simulationParametersByEngagementEnable: false,
        simulationParametersPartialWithdrawalEnable: false,
        simulationParameters: {
          startPayoutAge,
        },
      });
    }
  }

  public async changeStartPayoutAgePartialWithdrawal(startPayoutAgePartialWithdrawal: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.StartPayoutAgePartialWithdrawalChanged);

    this.commonParametersService.setSimulationParameter({
      simulationParametersByEngagementEnable: false,
      simulationParametersPartialWithdrawalEnable: true,
      simulationParameters: {
        startPayoutAgePartialWithdrawal,
      },
    });
  }

  public async changeStartPayoutAgeFullWithdrawal(startPayoutAge: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.StartPayoutAgeChanged);

    this.commonParametersService.setSimulationParameter({
      simulationParametersByEngagementEnable: false,
      simulationParametersPartialWithdrawalEnable: true,
      simulationParameters: {
        startPayoutAge,
      },
    });
  }

  public async changePartialWithdrawalPercentage(withdrawalPercentage: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PartialWithdrawalPercentage);

    this.commonParametersService.setSimulationParameter({
      simulationParametersByEngagementEnable: false,
      simulationParametersPartialWithdrawalEnable: true,
      simulationParameters: {
        withdrawalPercentage,
      },
    });
  }

  public async changePartialWithdrawalPartTimePercentage(partTimePercentage: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PartialWithdrawalPartTimePercentage);

    this.commonParametersService.setSimulationParameter({
      simulationParametersByEngagementEnable: false,
      simulationParametersPartialWithdrawalEnable: true,
      simulationParameters: {
        partTimePercentage,
      },
    });
  }

  public async changePostPensionPartTimeEnable(postPensionPartTimeEnable: boolean): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PostPensionPartTimeEnableChanged);

    this.commonParametersService.setSimulationParameter({
      simulationParameters: {
        postPensionPartTimeEnable,
      },
    });
  }

  public async changePostPensionPartTimeEndAge(postPensionPartTimeEndAge: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PostPensionPartTimeEndAgeChanged);

    this.commonParametersService.setSimulationParameter({
      simulationParameters: {
        postPensionPartTimeEndAge,
      },
    });
  }

  public async changePostPensionPartTimePercentAndFutureSalary(
    postPensionPartTimePercent: number,
    postPensionFutureSalary?: number,
  ): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PostPensionPartTimePercentChanged);
    if (getIsNotNullable(postPensionFutureSalary)) {
      this.commonParametersService.setSimulationParameter({
        simulationParameters: {
          postPensionFutureSalary,
          postPensionPartTimePercent,
        },
      });
    } else {
      this.commonParametersService.setSimulationParameter({
        simulationParameters: {
          postPensionPartTimePercent,
        },
      });
    }
  }

  public async changePostPensionFutureSalary(postPensionFutureSalary: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PostPensionFutureSalary);

    this.commonParametersService.setSimulationParameter({
      simulationParameters: {
        postPensionFutureSalary,
      },
    });
  }

  public async changePostPensionWithdrawalPercentage(postPensionWithdrawalPercentage: number): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.PostPensionWithdrawalPercentage);

    this.commonParametersService.setSimulationParameter({
      simulationParameters: {
        postPensionWithdrawalPercentage,
      },
    });
  }

  public async changeEngagementSimulationParameters(
    engagement: GenericEngagementOfUnknown,
    engagementParams: SimulationParametersByEngagement,
  ): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.EngagementSimulationChanged);
    this.commonParametersService.updateSimulationParametersByEngagement(engagement, engagementParams);
  }

  public async updateEngagementIsIncluded(engagement: AnyEngagement, value: boolean): Promise<void> {
    const changeReason = value ? ChangeReason.IncludedEngagement : ChangeReason.ExcludedEngagement;
    await this.simulationHistoryService.saveCurrentSimulation(changeReason);
    this.pensionPlanChanges$.next(PensionPlanChangeEvent.EngagementInclusionChanged);

    if (isExternalSavingsEngagement(engagement)) {
      this.profileService.setProfileExternalSaving({
        ...engagement.contract,
        isPension: value,
      });
    } else if (isOtherPensionEngagement(engagement)) {
      this.otherPensionEngagementsService.patchEngagement(engagement, {
        includeInPension: value,
      });
    } else {
      this.internalSavingsService.updateIsPension(engagement, value);
    }
  }

  public async addOtherPensionEngagement(engagement: OtherPensionEngagementUpdate): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.AddedEngagement);
    this.otherPensionEngagementsService.createEngagement(engagement);
  }

  public async editOtherPensionEngagement(
    engagement: OtherPensionEngagement,
    update: OtherPensionEngagementUpdate,
  ): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.EditedEngagement);
    this.otherPensionEngagementsService.patchEngagement(engagement, update);
  }

  public async deleteOtherPensionEngagement(engagement: OtherPensionEngagement): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.DeletedEngagement);
    this.otherPensionEngagementsService.deleteEngagement(engagement);
  }

  public async addAfpEngagement(engagement: AfpEngagement): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.AddedEngagement);
    await this.profileService.setProfileHasAfp(true);
    await this.internalSavingsService.updateIsPension(engagement, true);
  }

  public async deleteAfpEngagement(): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.DeletedEngagement);
    await this.profileService.setProfileHasAfp(false);
  }

  public async addExternalSavingsEngagement(engagement: CustomerSuppliedData.ExternalSaving): Promise<string> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.AddedEngagement);
    return this.profileService.setProfileExternalSaving(engagement);
  }

  public async editExternalSavingsEngagement(engagement: CustomerSuppliedData.ExternalSaving): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.EditedEngagement);
    this.profileService.setProfileExternalSaving(engagement);
  }

  public async deleteExternalSavingsEngagement(engagement: CustomerSuppliedData.ExternalSaving): Promise<void> {
    await this.simulationHistoryService.saveCurrentSimulation(ChangeReason.DeletedEngagement);
    this.profileService.deleteProfileExternalSaving(engagement);
  }
}
