import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

export const bankSavingsAccountsMock: Graph.BankAccount[] = [
  {
    accountNumber: "91003002028",
    productFriendlyName: "Dreamskonto",
    bookBalance: 5000.98,
    productCode: "ssd----",
  },
];

export const bankCheckingAccountsMock: Graph.BankAccount[] = [
  {
    accountNumber: "91005269755",
    productFriendlyName: "Smartkonto",
    bookBalance: 43210,
    productCode: "pp-----",
  },
  {
    accountNumber: "91003002027",
    productFriendlyName: "PLASSERINGSKONTO FFA",
    bookBalance: 170000.0,
    productCode: "pl-----",
  },
];

export const bankPrognosisMock: Graph.Prognosis = {
  simulationStatus: { severity: Graph.SimulationSeverity.Ok, messageKey: null },
  payoutPlan: [
    { age: 67, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2025-02-28", startDate: "2024-03-01" },
    { age: 68, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2026-02-28", startDate: "2025-03-01" },
    { age: 69, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2027-02-28", startDate: "2026-03-01" },
    { age: 70, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2028-02-28", startDate: "2027-03-01" },
    { age: 71, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2029-02-28", startDate: "2028-03-01" },
    { age: 72, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2030-02-28", startDate: "2029-03-01" },
    { age: 73, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2031-02-28", startDate: "2030-03-01" },
    { age: 74, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2032-02-28", startDate: "2031-03-01" },
    { age: 75, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2033-02-28", startDate: "2032-03-01" },
    { age: 76, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2034-02-28", startDate: "2033-03-01" },
    { age: 77, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2035-02-28", startDate: "2034-03-01" },
    { age: 78, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2036-02-28", startDate: "2035-03-01" },
    { age: 79, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2037-02-28", startDate: "2036-03-01" },
    { age: 80, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2038-02-28", startDate: "2037-03-01" },
    { age: 81, amount: 12276, minAmount: 0, maxAmount: 0, endDate: "2039-02-28", startDate: "2038-03-01" },
  ],
};
