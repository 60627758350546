<stb-form [label]="label | fms" size="flex" [hint]="tip" [disabled]="disabled">
  <select
    *ngIf="endPayoutAgeRange as range"
    [ngModel]="selectedAge"
    (change)="onPayoutAgeChange($event)"
    id="startPayoutAge"
    [disabled]="disabled || !range.length"
  >
    <option *ngFor="let val of range" [value]="val">
      {{ val | year }}
    </option>
  </select>
</stb-form>
