import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { getIsNotNullable } from "../../../utils/utils";
import { PublicPensionPrognosisParametersStaticService } from "../../api/public-pension-prognosis-parameters-static.service";
import { IncomeStrategy, createIncomeObject } from "../income.service";
import { FmsKey } from "../../fms/fms";

@Injectable()
export class IncomePublicPension implements IncomeStrategy {
  public income$ = this.publicPensionPrognosisParametersStaticService.getStaticPensionPrognosisParameters().pipe(
    map((publicPension) => {
      const value = publicPension.mainSalary;
      return createIncomeObject(value, "PUBLIC");
    }),
  );

  constructor(
    private readonly publicPensionPrognosisParametersStaticService: PublicPensionPrognosisParametersStaticService,
  ) {}

  public predicate(): Observable<boolean> {
    return this.income$.pipe(map(({ value }) => getIsNotNullable(value)));
  }

  public getHintText(): Observable<FmsKey> {
    return of("financesinput.yearly_income_gross_public.popover");
  }
}
