import { AxisLabelsFormatterContextObject, GradientColorObject, numberFormat, PatternObject, Point } from "highcharts";
import { ChartTranslations } from "src/app/services/chart.service";

export const BLANK_XAXIS_POINT = "0";

export function toCurrency(value: number): string {
  return numberFormat(value, 0, ",", " ");
}

export function defaultTooltipHeader(x: string | number | undefined, translations: ChartTranslations): string {
  return `<span class="tooltip-header"><strong>${x}</strong> ${translations.years}</span>`;
}

export function defaultTooltipPoint(point: Point, boldNameAndValue: boolean, valueOverride?: string): string | false {
  if (point?.y && point.y > 0) {
    const fontWeightClass = boldNameAndValue ? "tooltip-point-name-bold" : "";
    const value = valueOverride ?? toCurrency(point.y);

    return `<div class="tooltip-point">
        <span class="data-hj-suppress tooltip-point-name ${fontWeightClass}">
          ${getPointColor(point.color)}
          ${point.series.name}
        </span>

        <span class="tooltip-point-value">
          kr
          <span class="${fontWeightClass}">
            ${value}
          </span>
        </span>
    </div>`;
  }

  return false;
}

const dotWrapper = (body: string): string => {
  return `<span class="xaxis-dot-wrapper">${body}</span>`;
};

const flexWrapper = (body: string): string => {
  return `<span class="xaxis-flex-wrapper">${body}</span>`;
};

export const xAxisDefaultFormatter = function (this: AxisLabelsFormatterContextObject): string {
  return this.axis.defaultLabelFormatter.call(this);
};

export const xAxisYearLabelsFormatter = function (
  this: AxisLabelsFormatterContextObject,
  startPayoutAge: number,
): string {
  const year = Number(this.value);
  const selectedValue = this.chart.getSelectedPoints()[0]?.category;

  const largeDot = dotWrapper(`<div class="xaxis-large-dot"></div>`);
  const smallDot = dotWrapper(`<div class="xaxis-small-dot"></div>`);
  const payoutAgeDot = dotWrapper(
    `<img class="xaxis-payout-age-dot" src="https://elements.storebrand.no/din-pensjonsplan/icons-svg/custom/graphs/pension-age-graphic.svg"/>`,
  );
  const selectedStyle = `style="color: white; background: #404040; border-radius: 4px; padding-left: 3px; padding-right: 3px"`;

  const yearLabel = flexWrapper(`${largeDot}${year}`);
  const selectedYearLabel = flexWrapper(`${largeDot}<b ${selectedStyle}>${year}</b>`);
  const payoutAgeLabel = flexWrapper(`${payoutAgeDot}<b>${year}</b>`);
  const selectedPayoutAgeLabel = flexWrapper(`${payoutAgeDot}<b ${selectedStyle}>${year}</b>`);
  const lastInFrequencyLabel = flexWrapper(`${largeDot}<span>${year} år</span>`);
  const selectedLastInFrequencyLabel = flexWrapper(`${largeDot}<b ${selectedStyle}>${year} år</b>`);

  const YEAR_LABEL_TICK = 5;
  const isInTick = year % YEAR_LABEL_TICK === 0;
  const isWithinValuesAfterLastTick =
    (this.axis.max || 0) - this.pos <= ((this.axis.max || 0) - this.pos + year) % YEAR_LABEL_TICK;

  if (this.isFirst) {
    return yearLabel;
  }

  if (this.value === BLANK_XAXIS_POINT) {
    return "";
  }

  if (year === Number(selectedValue)) {
    if (year === startPayoutAge) {
      return selectedPayoutAgeLabel;
    }
    if (isWithinValuesAfterLastTick) {
      return selectedLastInFrequencyLabel;
    }

    return selectedYearLabel;
  }

  if (year === startPayoutAge) {
    return payoutAgeLabel;
  }

  if (isInTick) {
    return isWithinValuesAfterLastTick && year > Number(selectedValue ?? 0) ? lastInFrequencyLabel : yearLabel;
  }
  return flexWrapper(smallDot);
};

function getPointColor(color: string | GradientColorObject | PatternObject | undefined): string {
  return `<div class="tooltip-point-color" style="background: ${color}"></div>`;
}
