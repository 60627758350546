import { Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type PensionFundContract =
  | Graph.PensionFundItpContract
  | Graph.PensionFundUnfundedYtpUnderPayoutContract
  | Graph.PensionFundYtpContract;

export abstract class PensionFundEngagement<
  TContract extends PensionFundContract,
> extends SavingsAndPensionEngagement<TContract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }
  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract);
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference;
  }

  public getContractNumber(): Nullable<string> {
    return this.getContractNumberCustomer();
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return true;
  }
}

export class YtpFundedEngagement extends PensionFundEngagement<Graph.PensionFundYtpContract> {
  public isActive(): boolean {
    return false;
  }
}

/**
 * Unfunded means that the contract has no reserve such that if the company goes bankrupt the pension fund will be lost.
 */
export class YtpUnfundedEngagement extends PensionFundEngagement<Graph.PensionFundYtpContract> {
  public isActive(): boolean {
    return false;
  }
}

export class ItpUnfundedEngagement extends PensionFundEngagement<Graph.PensionFundItpContract> {
  public isActive(): boolean {
    return false;
  }

  public getIsLinkedToEpk(): boolean {
    return getIsNotNullable(this.contract.epkContract);
  }

  public getIsLinkedTo(epkContract: Graph.EpkContract): boolean {
    return epkContract.id === this.getLinkedEpk()?.id;
  }

  public getLinkedEpk(): Graph.PensionFundItpContract["epkContract"] {
    return this.contract.epkContract;
  }

  public override getSimulationParametersKey(): string {
    return this.contract.epkContract?.id ?? this.getId();
  }

  public getTotalMarketValue(): number | undefined {
    return this.contract.holdings?.totalMarketValue?.value ?? undefined;
  }

  public getTotalReturn(): number | undefined {
    return this.contract.holdings?.totalReturn?.value ?? undefined;
  }

  public getYearlyDeposit(): number | undefined {
    return this.contract.holdings?.yearlyDeposit?.value ?? undefined;
  }
}

export class PensionFundUnfundedYtpUnderPayoutEngagement extends PensionFundEngagement<Graph.PensionFundUnfundedYtpUnderPayoutContract> {
  /**
   * Contracts under payout are no longer active.
   */
  public isActive(): boolean {
    return false;
  }

  public getContractNumber(): string | undefined {
    return this.contract?.contractNumber ?? undefined;
  }
}
