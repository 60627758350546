import { animate, state, style, transition, trigger } from "@angular/animations";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

const SHOW = "show";
const HIDE = "hide";

export const STEP_ANIMATION_TRIGGER = trigger("stepNavigation", [
  state(SHOW, style({ opacity: 1 })),
  state(HIDE, style({ opacity: 0 })),
  transition(`${HIDE} => ${SHOW}`, [animate("0.7s ease")]),
]);

@Injectable()
export class StepAnimationService {
  private readonly _animationState$ = new BehaviorSubject<string>(SHOW);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly animationState$ = this._animationState$.asObservable();

  public startAnimation(): void {
    this._animationState$.next(HIDE);

    // Delay state change so animation will trigger
    setTimeout(() => {
      this._animationState$.next(SHOW);
    }, 1);
  }
}
