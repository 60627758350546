import { PensionArea, PensionGroup } from "src/app/constants/business.constants";
import { GenericEngagement } from "src/app/models/engagements/generic-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { toSum } from "src/app/utils/array";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";

export abstract class GenericGraphEngagement<C, P, TPrognosisParams> extends GenericEngagement<C, P, TPrognosisParams> {
  public constructor(contract: C, prognosis?: P) {
    super(contract, prognosis);
  }

  public getTotalPayout(): number | null {
    const payoutPlan = this.getPayoutPlan();

    return getTotalPayout(payoutPlan);
  }

  public getAverageAnnualPayout(fromAge?: number, toAge?: number): number | null {
    const payoutPlan = this.getPayoutPlan();

    return getAverageAnnualPayout(payoutPlan, fromAge, toAge);
  }

  public getContinousPeriods(): any[] {
    return [];
  }

  public getPensionArea(): PensionArea | null {
    return null;
  }

  public isPublicPensionFromNorskPensjon(): boolean {
    return false;
  }

  public getBalance(): Nullable<number> {
    return null;
  }

  public getContractNumber(): string {
    return "";
  }

  public getContractNumberCustomer(): Nullable<string> {
    return null;
  }

  public getPensionGroup(): PensionGroup | null {
    return null;
  }

  public getCompressionLimits(): number[] {
    return [];
  }

  public getSelectableInvestmentProfileInPayoutPeriod(): Graph.InvestmentProfileInPayoutPeriod[] {
    return [];
  }

  /**
   * @deprecated used for diffing purposes. To be disposed with TRM-2589.
   */
  public getApiResource(): string {
    return "savings-graphql";
  }

  public isOneTimePayout(): boolean {
    return false;
  }

  public isCompressed(): boolean {
    return false;
  }

  public hasStipultatedBaseRatePayout(): boolean {
    return false;
  }

  public abstract getContracts(): unknown[];
  public abstract getPayoutPlan(): Graph.PayoutPlan[];
  public abstract isActive(): boolean;
}

export function getTotalPayout(payoutPlan: Graph.PayoutPlan[]): number | null {
  const totalPayout = payoutPlan.map(toAmount).reduce(toSum, 0);

  return totalPayout || null;
}

export function getAverageAnnualPayout(
  payoutPlan: Graph.PayoutPlan[],
  fromAge?: number,
  toAge?: number,
): number | null {
  const isRangedPayout = !!fromAge || !!toAge;
  const payoutPlanToUse = isRangedPayout
    ? filterPayoutPlansForPartialWithdrawal(payoutPlan, fromAge, toAge)
    : payoutPlan;

  const sumAnnualPayout = getTotalPayout(payoutPlanToUse);
  const countAnnualPayouts = payoutPlanToUse.length;
  const averageAnnualPayout = (sumAnnualPayout ?? 0) / countAnnualPayouts;
  const hasAnnualPayouts = countAnnualPayouts > 0;

  return hasAnnualPayouts ? averageAnnualPayout : null;
}

function filterPayoutPlansForPartialWithdrawal(
  payoutPlan: Graph.PayoutPlan[],
  fromAge?: number,
  toAge?: number,
): Graph.PayoutPlan[] {
  return payoutPlan.filter(
    (plan) => getIsNotNullable(plan.age) && (!fromAge || plan.age >= fromAge) && (!toAge || plan.age < toAge),
  );
}

function toAmount({ amount }: Graph.PayoutPlan): number {
  return amount ?? 0;
}
