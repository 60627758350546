import { Log } from "src/app/utils/log";
import artifactInfo from "../../artifactInfo.json";
import { EnvConfig, SentryDsn } from "./envconfig";

const { gitShaShort, sentryRelease } = artifactInfo;
export const environment: EnvConfig = {
  production: true,
  hmr: false,
  dsn: SentryDsn.PROD,
  sentryRelease,
  appVersion: `${gitShaShort}`,
  logLevel: Log.Level.Important,
};
