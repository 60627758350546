/*
 * Ahead of time compiler in production means computed keys are not allowed
 * RouteKey entries and keys in route declarations must match (spelling and casing)
 * Please ensure consistency manually
 */

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "spare-til-pensjon",
};
