import { MonoTypeOperatorFunction } from "rxjs";
import { tap } from "rxjs/operators";
import { Primitives } from "src/app/utils/types";
import { getStbStage, isNotStageP, StbStage } from "./storebrand-staging";

enum LogColor {
  Default = "orange",
  LightGray = "#C9C9C9",
  Gray = "#878685",
  NiceBlue = "#586BA4",
  NiceRed = "#ED5041",
  AngryRed = "#E81E17",
  NiceYellow = "#F68E5F",
  NicePurple = "#A36D90",
}

enum LogLevel {
  Off,
  Fatal,
  Error,
  Warn,
  Important,
  Info,
  Debug,
  Trace,
  All,
}

export abstract class Log {
  public static Level = LogLevel;
  public static Color = LogColor;
  public static LOGLEVEL = LogLevel.Debug; //Default loglevel for anything that does not create app.module, e.g. Jest
  private static lastLogLevel: LogLevel;

  public static fatal(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Fatal }, message, ...optionalParams);
  }
  public static error(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Error }, message, ...optionalParams);
  }
  public static warn(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Warn }, message, ...optionalParams);
  }
  public static important(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Important }, message, ...optionalParams);
  }
  public static info(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Info }, message, ...optionalParams);
  }
  public static debug(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Debug }, message, ...optionalParams);
  }
  public static trace(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.Trace }, message, ...optionalParams);
  }
  public static all(message: unknown, ...optionalParams: unknown[]): void {
    Log.log({ level: Log.Level.All }, message, ...optionalParams);
  }

  public static log(config: Log.Config, message: unknown, ...optionalParams: any[]): void {
    /**
     * WARNING! Do not try to assing console.log to a variable. You can copy the method to a variable,
     * but you cannot use the variable to call the method in IE. It will explode when the console is not
     * open. Ref: https://www.beyondjava.net/console-log-surprises-with-internet-explorer-11-and-edge
     */
    if (config.level <= Log.LOGLEVEL) {
      const timestamp = `[${new Intl.DateTimeFormat("no-NB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        fractionalSecondDigits: 3,
      }).format(Date.now())}]`;
      const prefix = this.prefix(config.level);
      const color = this.getColorFromLevel(config.level);
      const style = this.style(config.color || color);
      const clearStyle = this.clearStyle();
      const payloadIsPrimitive = Primitives.includes(typeof message);

      switch (config.level) {
        case Log.Level.Fatal:
        case Log.Level.Error: {
          payloadIsPrimitive
            ? console.error(`${timestamp} ${prefix} ${message}`, style, clearStyle, ...optionalParams)
            : console.error(timestamp, prefix, style, clearStyle, message, ...optionalParams);
          break;
        }
        case Log.Level.Warn: {
          payloadIsPrimitive
            ? console.warn(`${timestamp} ${prefix} ${message}`, style, clearStyle, ...optionalParams)
            : console.warn(timestamp, prefix, style, clearStyle, message, ...optionalParams);
          break;
        }
        case Log.Level.Important:
        case Log.Level.Info:
        case Log.Level.Debug:
        case Log.Level.Trace:
        case Log.Level.All:
        default:
          payloadIsPrimitive
            ? console.log(`${timestamp} ${prefix} ${message}`, style, clearStyle, ...optionalParams)
            : console.log(timestamp, prefix, style, clearStyle, message, ...optionalParams);
      }
    }
  }

  public static setLogLevel(level?: Log.Level, options: Log.LevelConfig = { silent: false }): void {
    // eslint-disable-next-line fp/no-mutation
    Log.LOGLEVEL = Log.inferLogLevel(level);

    if (!options.silent) {
      Log.info("Log level:", Log.Level[Log.LOGLEVEL].toUpperCase());
    }
  }

  public static disable(): void {
    // eslint-disable-next-line fp/no-mutation
    Log.lastLogLevel = Log.LOGLEVEL;
    Log.setLogLevel(Log.Level.Off, { silent: true });
  }

  public static enable(): void {
    if (Log.lastLogLevel) {
      Log.setLogLevel(Log.lastLogLevel, { silent: true });
    }
  }

  private static inferLogLevel(override?: Log.Level): Log.Level {
    if (override != null) {
      return override;
    }
    switch (getStbStage()) {
      case StbStage.Localhost:
        return Log.Level.Debug;
      case StbStage.Test:
      case StbStage.TestStabil:
      case StbStage.Utvikling:
        return Log.Level.Info;
      default:
        return Log.Level.Important;
    }
  }

  private static getColorFromLevel(level: Log.Level): Log.Color {
    switch (level) {
      case Log.Level.Fatal:
        return Log.Color.AngryRed;
      case Log.Level.Error:
        return Log.Color.NiceRed;
      case Log.Level.Warn:
        return Log.Color.NiceYellow;
      case Log.Level.Important:
        return Log.Color.NicePurple;
      case Log.Level.Info:
        return Log.Color.NiceBlue;
      case Log.Level.Debug:
        return Log.Color.Gray;
      case Log.Level.Trace:
      case Log.Level.All:
        return Log.Color.LightGray;
      default:
        return Log.Color.Default;
    }
  }

  private static prefix(level: Log.Level): string {
    const label = Log.Level[level].toUpperCase();
    return isNotStageP() ? `%c ${label} %c` : `[${label}]`;
  }

  private static style(color: Log.Color): string {
    return isNotStageP() ? `background-color: ${color}; font-weight: bold; color: white;` : "";
  }

  private static clearStyle(): string {
    return isNotStageP() ? `background-color: none; font-weight: normal; color: currentcolor;` : "";
  }
}

export namespace Log {
  export interface Config {
    level: LogLevel;
    color?: LogColor;
  }

  export interface LevelConfig {
    silent: boolean;
  }

  export type Level = LogLevel;

  export type Color = LogColor;
}

/* prettier-ignore */
export const logFatal = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.fatal(message, ...optionalParams, val));
/* prettier-ignore */
export const logError = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.error(message, ...optionalParams, val));
/* prettier-ignore */
export const logWarn = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.warn(message, ...optionalParams, val));
/* prettier-ignore */
export const logImportant = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.important(message, ...optionalParams, val));
/* prettier-ignore */
export const logInfo = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.info(message, ...optionalParams, val));
/* prettier-ignore */
export const logDebug = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.debug(message, ...optionalParams, val));
/* prettier-ignore */
export const logTrace = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.trace(message, ...optionalParams, val));
/* prettier-ignore */
export const logAll = <T>(message?: unknown, ...optionalParams: unknown[]): MonoTypeOperatorFunction<T> => tap(val => Log.all(message, ...optionalParams, val));
