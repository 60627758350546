import { BehaviorSubject, EMPTY, merge, Observable } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

export abstract class AbstractCustomerSuppliedDataService {
  protected writeLocalToRemote<T>(
    data: T,
    writeActive$: BehaviorSubject<boolean>,
    writeFn: (data: T) => Observable<unknown | never>,
  ): Observable<unknown | never> {
    const signalWrite$ = new Observable((sub) => {
      writeActive$.next(true);
      sub.next();
      sub.complete();
    });
    return merge(
      signalWrite$,
      writeFn(data).pipe(
        //TODO: Handle error gracefully
        catchError(() => EMPTY),
        finalize(() => writeActive$.next(false)),
      ),
    );
  }
}
