import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_NAMED_OPTIONS } from "apollo-angular";
import { mockSavingsGraphClientFactory } from "./clients/mock-savings-graph/mock-savings-graph-client";

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: mockSavingsGraphClientFactory,
    },
  ],
})
export class MockGraphqlClientsModule {}
