import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MissingCorrelationIdWarning } from "../utils/errors";
import { Monitoring } from "../utils/monitoring";
import { getIsNullable, Nullable } from "../utils/utils";

export enum HttpHeaderKey {
  CorrelationId = "x-correlation-id",
  ClientId = "clientid",
}

@Injectable()
export class CorrelationInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip if the request header contentType is not application/json
    if (req.responseType !== "json") {
      return next.handle(req);
    }

    const reqWithCorrelationIdHeader = req.clone({
      headers: setCorrelationIdHeaders(req.headers),
    });

    // Go to the network with x-correlation-id header
    return next.handle(reqWithCorrelationIdHeader).pipe(
      map((event) =>
        // Return with the x-correlation-id as a header
        event instanceof HttpResponse ? addCorrelationIdToResponseHeaders(event, reqWithCorrelationIdHeader) : event,
      ),
    );
  }
}

export function getCorrelationIdFromHeaders(headers?: HttpHeaders): string | null {
  if (!(headers instanceof HttpHeaders)) {
    return null;
  }

  const correlationId = headers.get(HttpHeaderKey.CorrelationId);

  if (getIsNullable(correlationId)) {
    Monitoring.warn(
      new MissingCorrelationIdWarning(
        `Failed to find header ${HttpHeaderKey.CorrelationId}. Are we looking before the request passed the interceptor? Documentation https://angular.io/guide/http#interceptor-order`,
      ),
    );
  }
  return correlationId;
}

function createUniqueId(): string {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

function setCorrelationIdHeaders(headers: HttpHeaders, correlationId?: Nullable<string>): HttpHeaders {
  const clientId = "smart-pensjon-web";
  const composedCorrelationId = `${clientId}.${createUniqueId()}`;
  return headers.set(HttpHeaderKey.CorrelationId, correlationId ?? composedCorrelationId);
}

function addCorrelationIdToResponseHeaders(response: HttpResponse<any>, req: HttpRequest<any>): HttpResponse<any> {
  const headers = setCorrelationIdHeaders(
    response.headers ?? new HttpHeaders(),
    getCorrelationIdFromHeaders(req.headers),
  );
  return response.clone({
    headers,
  });
}
