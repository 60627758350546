import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { Observable, combineLatest, map, mergeMap, tap } from "rxjs";
import { FmsService } from "src/app/services/fms.service";
import { IncomeService } from "../../../../../services/income/income.service";
import { SalaryForm } from "../../onboarding/onboarding-salary-step/onboarding-salary-step.component";

type IncomeSource = CustomerSuppliedData.ClientData["incomeSource"];

export interface SalaryRadioFormField {
  selectedRadioSource: IncomeSource;
}

interface TypedRadioButton extends RadioButton {
  readonly value: IncomeSource;
}

@Component({
  selector: "app-salary-provider-radio-form-field",
  templateUrl: "./salary-provider-radio-form-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryProviderRadioFormFieldComponent implements OnInit {
  @Input()
  public formGroup!: FormGroup<SalaryForm>;

  public readonly radioButtons$: Observable<TypedRadioButton[]>;

  private readonly formControl = new FormControl<SalaryRadioFormField>(
    { selectedRadioSource: undefined },
    { nonNullable: true },
  );

  constructor(
    private readonly fmsService: FmsService,
    private readonly incomeService: IncomeService,
  ) {
    this.radioButtons$ = this.incomeService.income$.pipe(
      tap(({ source }) => {
        this.updateFormFieldValue(source);
      }),
      mergeMap(({ source }) => {
        return this.createRadioButtons(source);
      }),
    );
  }

  public ngOnInit(): void {
    this.formGroup.addControl("salaryRadio", this.formControl);
  }

  public updateFormFieldValue(value: IncomeSource): void {
    this.formControl.setValue({ selectedRadioSource: value });
  }

  private createRadioButtons(radioValue: IncomeSource): Observable<TypedRadioButton[]> {
    return combineLatest([
      this.fmsService.translateAsync<string>("onboardingSalaryStep.radioOptions.employerGivenSalary"),
      this.fmsService.translateAsync<string>("onboardingSalaryStep.radioOptions.userGivenSalary"),
    ]).pipe(
      map(([employerGivenSalaryText, userGivenSalaryText]) => [
        {
          label: employerGivenSalaryText,
          value: "EMPLOYER",
          disabled: false,
          checked: radioValue === "EMPLOYER",
        },
        {
          label: userGivenSalaryText,
          value: "USER",
          disabled: false,
          checked: radioValue === "USER",
        },
      ]),
    );
  }
}
