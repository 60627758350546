import { ChangeDetectionStrategy, Component } from "@angular/core";
import {
  ExpandableAlertComponent,
  ExpandableAlertType,
} from "src/app/modules/shared/components/expandable-alerts/expandable-alert/expandable-alert.component";

@Component({
  selector: "app-expandable-error-alert",
  templateUrl: "../expandable-alert/expandable-alert.component.html",
  styleUrls: ["../expandable-alert/expandable-alert.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableErrorAlertComponent extends ExpandableAlertComponent {
  public type = ExpandableAlertType.Error;
}
