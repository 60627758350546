import { Options, TooltipFormatterContextObject } from "highcharts";
import { GeneralChartColors } from "src/app/constants/highcharts-colors.constants";
import { ChartTranslations } from "src/app/services/chart.service";
import { defaultTooltipPoint, toCurrency } from "../chart/chart.default-functions";

function getEngagementsTooltipFormatter(
  contextObject: TooltipFormatterContextObject,
  translations: ChartTranslations,
): string[] | false {
  const points = contextObject.points ?? [];
  const total = points.at(0)?.total;
  const header = getTooltipHeader(contextObject.x, total, translations);
  const lines = points
    .filter(({ point }) => (point.y ?? 0) > 0)
    .map(({ point }) => `<hr class="tooltip-point-line">` + defaultTooltipPoint(point, false));

  return lines.length > 0 ? [header, lines.join("")] : false;
}

function getTooltipHeader(
  age: string | number | undefined,
  total: number | undefined,
  translations: ChartTranslations,
): string {
  const totalHtml = total
    ? `<div class="engagements-tooltip-header-total">
         kr <strong>${toCurrency(total)}</strong>
       </div>`
    : "";

  return `
    <div class="engagements-tooltip-header">
      <div class="engagements-tooltip-header-age">${translations.longTooltipTitle} (${age} ${translations.years})</div>
      ${totalHtml}
    </div>`;
}

export function getOptions(translations: ChartTranslations): Options {
  return {
    plotOptions: {
      column: {
        borderWidth: 1,
        borderColor: GeneralChartColors.Border,
      },
    },
    tooltip: {
      distance: 16,
      formatter: function (): string[] | false {
        return getEngagementsTooltipFormatter(this, translations);
      },
    },
    chart: { spacingBottom: 0 },
  };
}
