import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { EndpointService } from "../endpoint.service";

export enum LeadType {
  Registration = "Registration",
  MeetingBooking = "MeetingBooking",
  SavingSalary = "SavingSalary",
  AfpAdvisory = "AfpAdvisory",
}

export interface CreateLeadRequestBody {
  leadType: LeadType;
  description: string;
  phoneNumber: string;
  emailAddress: string;
  hasAfp?: boolean;
}

export interface CreateLeadResponse {
  body: {};
  statusCode: string;
  statusCodeValue: number;
}

/**
 * Smart-pensjon-rest-api
 */
@Injectable({
  providedIn: "root",
})
export class SmartPensjonRestApiService {
  constructor(private readonly endpointService: EndpointService) {}

  /**
   * GET /savings/retirement-management/smartaccount/eligible
   */
  public fetchEligibility(): Observable<SmartAccount.Eligibility> {
    return this.endpointService
      .composeEligibilityCheckApiUrl()
      .pipe(switchMap((url) => this.endpointService.httpGet$<SmartAccount.Eligibility>(url)));
  }

  /**
   * POST /savings/retirement-management/leads
   */
  public createLead(body: CreateLeadRequestBody): Observable<CreateLeadResponse> {
    return this.endpointService.composeLeadUrl().pipe(
      switchMap((url) =>
        this.endpointService.httpPost$<CreateLeadRequestBody, CreateLeadResponse>(url, {
          body,
        }),
      ),
    );
  }
}
