import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import {
  catchError,
  combineLatest,
  filter,
  first,
  map,
  mergeMap,
  Observable,
  of,
  race,
  Subject,
  take,
  takeUntil,
  timeout,
  withLatestFrom,
} from "rxjs";
import { tap } from "rxjs/operators";
import { SteppableModalStep } from "src/app/modules/shared/components/steppable-modal/dynamic-step-navigation.service";
import { ConsentService } from "src/app/services/consent.service";
import { DispatcherService, Signal } from "src/app/services/dispatcher.service";
import { FmsService } from "src/app/services/fms.service";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { getIsNotNullable, Nullable, stringToBoolean } from "src/app/utils/utils";

interface ConsentLabels {
  yesLabel: string;
  noLabel: string;
}

@Component({
  selector: "app-onboarding-norsk-pensjon-and-storage-step",
  templateUrl: "./onboarding-norsk-pensjon-and-storage-step.component.html",
  styleUrls: ["../onboarding-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingNorskPensjonAndStorageStepComponent implements AfterViewInit, OnDestroy, SteppableModalStep {
  public readonly storageRadioButtons$: Observable<RadioButton[]>;
  public readonly norskPensjonRadioButtons$: Observable<RadioButton[]>;

  public readonly formGroup: UntypedFormGroup;
  public readonly storageConsentControl: AbstractControl<Nullable<boolean>, Nullable<boolean>>;
  public readonly norskPensjonConsentControl: AbstractControl<Nullable<boolean>, Nullable<boolean>>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly consentService: ConsentService,
    private readonly fmsService: FmsService,
    private readonly fb: UntypedFormBuilder,
    private readonly dispatcherService: DispatcherService,
  ) {
    this.formGroup = this.fb.group({
      storageConsentControl: [null, [Validators.required]],
      norskPensjonConsentControl: [null, [Validators.required]],
    });

    this.storageConsentControl = this.formGroup.controls.storageConsentControl;
    this.norskPensjonConsentControl = this.formGroup.controls.norskPensjonConsentControl;

    const consentLabels$ = memoizeObject$(
      combineLatest([
        this.fmsService.translateAsync<string>("onboardingConsents.yes"),
        this.fmsService.translateAsync<string>("onboardingConsents.no"),
      ]).pipe(
        map(([yesLabel, noLabel]) => ({
          yesLabel,
          noLabel,
        })),
      ),
    );

    this.storageRadioButtons$ = combineLatest([this.consentService.customerSuppliedDataConsent$, consentLabels$]).pipe(
      tap(([value]) => this.storageConsentControl.setValue(value)),
      map(([hasStorageConsent, consentLabels]) => this.createConsentRadioButtons(hasStorageConsent, consentLabels)),
    );
    this.norskPensjonRadioButtons$ = combineLatest([this.consentService.hasNorskPensjonConsent$, consentLabels$]).pipe(
      tap(([value]) => this.norskPensjonConsentControl.setValue(value)),
      map(([hasNorskPensjonConsent, consentLabels]) =>
        this.createConsentRadioButtons(hasNorskPensjonConsent, consentLabels),
      ),
    );
  }

  public ngAfterViewInit(): void {
    const radioButtonChangedByUser$ = race([
      this.storageConsentControl.valueChanges.pipe(
        map(() => this.storageConsentControl.value),
        filter((value) => value !== null),
      ),
      this.norskPensjonConsentControl.valueChanges.pipe(
        map(() => this.norskPensjonConsentControl.value),
        filter((value) => value !== null),
      ),
    ]).pipe(take(1), takeUntil(this.destroy$));

    radioButtonChangedByUser$.subscribe(() =>
      this.dispatcherService.dispatch(
        Signal.SteppableModalHasChange,
        OnboardingNorskPensjonAndStorageStepComponent.name,
        true,
      ),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public setStorageControl(item: RadioButton): void {
    const booleanValue = stringToBoolean(item.value);
    this.storageConsentControl.markAsTouched();
    this.storageConsentControl.setValue(booleanValue);
  }

  public setNorskPensjonControl(item: RadioButton): void {
    const booleanValue = stringToBoolean(item.value);
    this.norskPensjonConsentControl.markAsTouched();
    this.norskPensjonConsentControl.setValue(booleanValue);
  }

  public getTitle(): Observable<string> {
    return this.fmsService.translateAsync("onboardingConsents.title");
  }

  public beforeNext(): Observable<boolean> {
    const valid = this.storageConsentControl.valid && this.norskPensjonConsentControl.valid;

    if (!valid) {
      this.markControlsAsTouched();
      return of(false);
    }

    const updateStorage$ = this.consentService.customerSuppliedDataConsent$.pipe(
      first(),
      withLatestFrom(this.fmsService.translateAsync<string>("onboardingConsents.dataStorage.purposeText")),
      mergeMap(([initalConsentValue, fmsText]) => {
        const currentConstentValue = this.storageConsentControl.value;

        if (initalConsentValue !== currentConstentValue && getIsNotNullable(currentConstentValue)) {
          return this.consentService.postAndFetchStorageConsent(currentConstentValue, fmsText).pipe(map(() => true));
        }
        return of(true);
      }),
    );

    const updateNorskPensjon$ = this.consentService.hasNorskPensjonConsent$.pipe(
      first(),
      withLatestFrom(this.fmsService.translateAsync<string>("onboardingConsents.norskPensjon.purposeText")),
      mergeMap(([initalConsentValue, fmsText]) => {
        const currentConstentValue = this.norskPensjonConsentControl.value;

        if (initalConsentValue !== currentConstentValue && getIsNotNullable(currentConstentValue)) {
          return this.consentService
            .postAndFetchNorskPensjonConsent(currentConstentValue, fmsText)
            .pipe(map(() => true));
        }
        return of(true);
      }),
    );

    return combineLatest([updateStorage$, updateNorskPensjon$]).pipe(
      timeout(5000),
      map(() => true),
      catchError(() => of(true)),
    );
  }

  private markControlsAsTouched(): void {
    this.storageConsentControl.markAsTouched();
    this.storageConsentControl.updateValueAndValidity();
    this.norskPensjonConsentControl.markAsTouched();
    this.norskPensjonConsentControl.updateValueAndValidity();
  }

  private createConsentRadioButtons(
    constentValue: Nullable<boolean> = undefined,
    labels: ConsentLabels,
  ): RadioButton[] {
    const hasConsentValue = getIsNotNullable(constentValue);

    return [
      {
        label: labels.yesLabel,
        value: true.toString(),
        disabled: false,
        checked: hasConsentValue ? constentValue : false,
      },
      {
        label: labels.noLabel,
        value: false.toString(),
        disabled: false,
        checked: hasConsentValue ? !constentValue : false,
      },
    ];
  }
}
