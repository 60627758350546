import { Injectable } from "@angular/core";
import { combineLatest, map } from "rxjs";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { excludedByDefault } from "./internal-savings.service";

export enum LinkProductCode {
  Fokf = "FOKF",
  Fokp = "FOKP",
  Ips2 = "IPS2",
}

export interface ContractItem {
  id: string;
  contract: AnyEngagement;
  expanded: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ContractListService {
  public readonly contractItems$ = memoizeObject$(
    combineLatest([
      this.engagementsService.allEngagements$.pipe(map(toContractItems)),
      this.profileService.internalSavings$,
    ]).pipe(
      map(([engagements, internalSavings]) =>
        engagements.filter(({ contract }) => shouldShowContract(contract, internalSavings)),
      ),
    ),
  );

  constructor(
    private readonly engagementsService: EngagementsService,
    private readonly profileService: ProfileService,
  ) {}
}

function toContractItems(engagements: AnyEngagement[]): ContractItem[] {
  return engagements.map((engagement) => ({
    id: engagement.getIdentifier(),
    contract: engagement,
    expanded: false,
  }));
}

function shouldShowContract(engagement: AnyEngagement, internalSaving: CustomerSuppliedData.InternalSaving[]): boolean {
  if (excludedByDefault(engagement)) {
    const internalSavingIsPension = internalSaving.find((e) => e.key === engagement.getIdentifier())?.isPension;

    return internalSavingIsPension ?? false;
  }

  return true;
}
