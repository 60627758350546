import {
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  SubscriptionOptions,
} from "@apollo/client/core";
import { Cache } from "@apollo/client/cache";
import { ApolloBase, ExtraSubscriptionOptions, QueryRef, WatchQueryOptions } from "apollo-angular";
import { EmptyObject } from "apollo-angular/types";
import { Observable, of, throwError } from "rxjs";

export class ApolloBaseWrapper {
  constructor(private readonly apolloBase: ApolloBase) {}

  public query<A, B extends OperationVariables = EmptyObject>(
    options: QueryOptions<B>,
  ): Observable<ApolloQueryResult<A>> {
    return this.apolloBase.query(options);
  }

  public watchQuery<A, B extends OperationVariables = EmptyObject>(options: WatchQueryOptions<B, A>): QueryRef<A, B> {
    return this.apolloBase.watchQuery(options);
  }

  public mutate<A, B extends OperationVariables = EmptyObject>(
    options: MutationOptions<A, B>,
  ): Observable<FetchResult<A>> {
    return this.apolloBase.mutate(options);
  }

  public evict(options: Cache.EvictOptions): boolean {
    return this.apolloBase.client.cache.evict(options);
  }

  public subscribe<A, B extends OperationVariables = EmptyObject>(
    options: SubscriptionOptions<B>,
    extra?: ExtraSubscriptionOptions,
  ): Observable<FetchResult<A>> {
    return this.apolloBase.subscribe(options, extra);
  }
}

export function getApolloResponseData<T>(response: ApolloQueryResult<T>): Observable<T> {
  return response?.data
    ? of(response.data)
    : throwError(() => response?.error ?? new Error("Missing error from graphql"));
}
