import { cloneDeepWith, defaultsDeep, isNil, isPlainObject, mapValues, omitBy, overSome } from "lodash-es";
// import _isNaN from "lodash-es/isNaN";
import { Nullable, getIsNullable } from "src/app/utils/utils";
import { isTypeNumber } from "./number";
import { MappingFunction, NotNullProps } from "./types";

// const _isNaN = require("lodash-es/isNaN");
export interface AnyObject<T = any> {
  [name: string]: T;
}

export const valueMap = <T, R>(obj: AnyObject<T>, map: MappingFunction<T, R>): { [key: string]: R } =>
  Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, map(val)]));

/**
 * Diffs two objects, returning a new object that is a subset
 * of the second argument containing the first property whose
 * value differs from the corresponding property of the first
 * argument.
 *
 * @param oOld the old object to compare against
 * @param oNew the new object to get the diff from
 */
export function getFirstPropDiff<T extends AnyObject>(oOld: T, oNew: T): Nullable<T> {
  if (!oOld && !oNew) {
    return undefined;
  } else if (!oOld) {
    return oNew;
  } else if (!oNew) {
    return oOld;
  }

  return Object.fromEntries([Object.entries(oNew).find(([key, val]) => oOld[key] !== val) ?? []]);
}

/**
 * Prunes undefined properties from input.
 *
 * Warning: will return empty object for Map, WeakMap, Set, WeakSet, Function
 */
export function pruneProps<T>(
  inObj: T,
  reviver: (key: string, value: any) => any = (_: string, value): any | undefined =>
    value && typeof value === "object" && Object.keys(value).length === 0 ? undefined : value,
): T {
  return inObj == null ? inObj : JSON.parse(JSON.stringify(inObj), reviver);
}

export function mergeDeepWith<T, U extends object>(
  object: T,
  sources: U | U[],
  customizer?: (val: any) => any | undefined,
): T & U {
  const cust =
    customizer ??
    ((value: any): any | undefined =>
      value == null || Number.isNaN(value) ? undefined : isPlainObject(value) ? mapValues(value, cust) : value);
  return defaultsDeep({}, cloneDeepWith(object, cust), ...(<U[]>[]).concat(sources).map((s) => cloneDeepWith(s, cust)));
}

/**
 * Shallow pruning of null properties. Useful when you want to remove properties
 * with 'null' values, e.g. from an API response, and want the type to represent it.
 *
 * @param inObj the object to prune
 * @returns a new object representing the input with no null or undefined properties
 */
export function toPartialNotNull<T extends { [P in keyof T]: Nullable<T[P]> }>(inObj: T): Partial<NotNullProps<T>> {
  return omitBy<T>(
    inObj,
    overSome(isNil, (val) => isTypeNumber(val) && isNaN(val)),
  ) as Partial<NotNullProps<T>>;
}

export function getIsAnyPropNullable(obj: AnyObject): boolean {
  return Object.values(obj).some((prop) => getIsNullable(prop));
}
