import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { combineLatestWith, map, take, tap } from "rxjs/operators";
import { loadHeadlessJquery, loadHeadlessPagePadding } from "src/app/utils/external-scripts";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { getIsNullable, Nullable } from "src/app/utils/utils";

const POLLING_DELAY = 250;
const POLLING_LIMIT = 10;

@Component({
  selector: "app-dynamic-external-html-content",
  templateUrl: "./dynamic-external-html-content.component.html",
  styleUrls: ["./dynamic-external-html-content.component.scss"],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicExternalHtmlContentComponent implements OnInit {
  @ViewChild("content", { static: true })
  public content!: ElementRef;
  @Input()
  public url: Nullable<string>;
  @Output()
  public load = new EventEmitter<void>();

  public content$!: Observable<SafeHtml>;

  constructor(
    private readonly http: HttpClient,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  public ngOnInit(): void {
    if (getIsNullable(this.url)) {
      return;
    }

    /**
     * The headless scripts have no immediate effect as long as this component is
     * using ViewEncapsulation.ShadowDom. The jQuery selectors cannot
     * pierce the shadow root. The files are loaded only to make certain
     * functions available, like stbFaq().
     *
     * Not using ShadowDom will introduce other problems, like our global
     * styling affecting the styling of articles.
     *
     * @see https://robdodson.me/dont-use-jquery-with-shadow-dom/
     */
    this.content$ = memoizeObject$(
      this.http.get(this.url, { responseType: "text" }).pipe(
        combineLatestWith(loadHeadlessJquery.next(), loadHeadlessPagePadding.next()),
        map(([result]) => this.domSanitizer.bypassSecurityTrustHtml(result)),
        tap(() => this.load.emit()),
      ),
    );

    this.content$.pipe(take(1)).subscribe(() => this.pollContent());
  }

  private pollContent(): void {
    const polls: any = {};

    const poll = (action: () => boolean): void => {
      const actionName = action.toString();
      // eslint-disable-next-line fp/no-mutation
      polls[actionName] = polls[actionName] || 0;

      if (!action() && polls[actionName] < POLLING_LIMIT) {
        // eslint-disable-next-line fp/no-mutation
        polls[actionName] += 1;
        setTimeout(() => poll(action), POLLING_DELAY);
      }
    };

    poll(() => this.initAccordions());
  }

  private initAccordions(): boolean {
    const { nativeElement } = this.content;
    const faqs = nativeElement.querySelectorAll("[data-widget=stbFaq]");

    faqs.forEach((faq: any) => {
      // @ts-ignore jQuery is explicitly loaded in this component.
      jQuery(faq).stbFaq();
    });

    return faqs.length > 0;
  }
}
