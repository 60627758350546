<app-engagement-list-initiatives-header [headers]="getHeaders()"> </app-engagement-list-initiatives-header>

<div>
  <ng-container *ngFor="let item of getVisibleItems()">
    <app-engagement-list-initiatives-item class="list-item" [item]="item" [isNumberedList]="isNumberedList">
    </app-engagement-list-initiatives-item>
  </ng-container>
</div>

<app-engagement-list-initiatives-expander
  [showMoreButton]="getShouldShowAllButton()"
  [showLessButton]="getShouldShowLessButton()"
  (toggleShowLessMoreButton)="toggleShouldShowAllItems()"
>
</app-engagement-list-initiatives-expander>
