import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable, map, mergeMap, startWith } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";
import { ReplayStore } from "src/app/utils/rxjs/store";
import { ContractActionsService } from "../../../services/contract-actions/contract-actions.service";
import { ContractAction } from "../../../services/contract-actions/contract-actions.types";

@Component({
  selector: "app-contract-actions-container",
  templateUrl: "./contract-actions-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractActionsContainerComponent {
  public readonly engagement$: ReplayStore<AnyEngagement> = new ReplayStore();
  public readonly actions$: Observable<ContractAction[]>;

  constructor(private readonly contractActionsService: ContractActionsService) {
    const maxActions = 4;
    this.actions$ = this.engagement$.pipe(
      mergeMap((engagement) => this.contractActionsService.getActions(engagement)),
      map((actions) => actions.slice(0, maxActions)),
      startWith([]),
    );
  }

  @Input()
  public set engagement(engagement: AnyEngagement) {
    this.engagement$.next(engagement);
  }
}
