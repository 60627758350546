import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { InvestmentProfileInPayoutPeriod } from "../../../../../services/api/savings-and-pension-queries.types";
import { AnyEngagement } from "../../../../../services/engagements.service";
import { FmsKey } from "../../../../../services/fms/fms";
import { Monitoring } from "../../../../../utils/monitoring";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

export function getInvestmentFmsKey(selectedProfile: string): FmsKey {
  if (selectedProfile === InvestmentProfileInPayoutPeriod.Guarantee) {
    return "payoutSimulationAlternatives.dialog.investmentRadioOptions.guarantee";
  }
  if (selectedProfile === InvestmentProfileInPayoutPeriod.Investments) {
    return "payoutSimulationAlternatives.dialog.investmentRadioOptions.investment";
  }

  Monitoring.error(
    new Error(
      `Unexpected investmentProfileInPayoutPeriod, got:
        ${selectedProfile}`,
    ),
    { ignore: isStageLocalhost() },
  );
  return "common.unknown";
}

export function getCompressionLimitFmsKey(selectedCompression: number): FmsKey {
  if (selectedCompression === 0.3) {
    return "payoutSimulationAlternatives.dialog.compressionLimitRadioOptions.low";
  }
  if (selectedCompression === 0.5) {
    return "payoutSimulationAlternatives.dialog.compressionLimitRadioOptions.high";
  }

  Monitoring.error(
    new Error(
      `Unexpected compressionLimit, got:
        ${selectedCompression}`,
    ),
    { ignore: isStageLocalhost() },
  );
  return "common.unknown";
}

export function createRadioButton(
  label: string,
  option: string | number,
  selectedOption?: InvestmentProfileInPayoutPeriod | number,
): RadioButton {
  const checked = option === selectedOption;

  return {
    label,
    value: option.toString(),
    disabled: false,
    checked,
  };
}

export function getPayoutSimulationResultDescription(engagement: AnyEngagement): FmsKey {
  if (engagement.isOneTimePayout()) {
    return "payoutSimulationAlternatives.dialog.result.oneTimePayout";
  }
  if (engagement.isLifelongPayout()) {
    return "payoutSimulationAlternatives.dialog.result.lifeLong";
  }
  return "payoutSimulationAlternatives.dialog.result.default";
}
