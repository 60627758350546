<ng-container *ngIf="hasEmployerSalary$ | async; else noEmployer">
  <div [innerHTML]="employerDesciption$ | async"></div>
  <app-salary-provider-radio-form-field [formGroup]="form"></app-salary-provider-radio-form-field>
</ng-container>

<ng-template #noEmployer>
  <div [innerHTML]="'onboardingSalaryStep.description.noEmployer.html' | fms"></div
></ng-template>

<app-yearly-income-gross-form-field
  [class.visible]="showYearlyIncomeGrossFormField$ | async"
  [formGroup]="form"
  [isOnboardingContext]="true"
  (keydownEnter)="onEnter()"
></app-yearly-income-gross-form-field>
