import { format, sub } from "date-fns";

const birthDate = format(sub(new Date(), { years: 67 }), "yyyy-MM-dd");

export const customerMock: CustomerMaster.Response.Customer = {
  couchbaseId: undefined,
  lastUpdated: 1621330818811,
  confidential: {
    value: false,
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  addresses: {
    residence: {
      value: {
        addressLine1: "Brattbakken 6",
        addressLine2: "",
        addressLine3: "",
        careOf: "",
        street: undefined,
        streetNumber: undefined,
        postalCode: "1353",
        city: "Bærums Verk",
        country: "NO",
        addressQuality: undefined,
      },
      metaData: {
        lastUpdated: 1479312509324,
        lastVerifiedDate: 0,
        updatedBy: "DSF_load",
      },
    },
  },
  customerIdList: [
    {
      value: {
        number: "110834210",
        primary: true,
      },
      metaData: {
        lastUpdated: 1276319773919,
        lastVerifiedDate: 0,
        updatedBy: "STB_INIT",
      },
    },
  ],
  contactMethods: {
    telephonePrivate: {
      value: {
        value: "32465800",
        undeliverableReason: "32465800",
      },
      metaData: {
        lastUpdated: 1621330820009,
        lastVerifiedDate: 1621288800000,
        updatedBy: "PRIVDASH:110834210",
      },
    },
    emailPrivate: {
      value: {
        value: "test.HELLERUD-TEST@stb-noreply.no",
        undeliverableReason: "test.HELLERUD-TEST@stb-noreply.no",
      },
      metaData: {
        lastUpdated: 1621330819476,
        lastVerifiedDate: 1621288800000,
        updatedBy: "PRIVDASH:110834210",
      },
    },
    emailBusiness: {
      value: {
        value: "test.HELLERUD-TEST@stb-noreply.no",
        undeliverableReason: "test.HELLERUD-TEST@stb-noreply.no",
      },
      metaData: {
        lastUpdated: 1510086301587,
        lastVerifiedDate: 1510086301344,
        updatedBy: "KND",
      },
    },
    mobilePrivate: {
      value: {
        value: "99999997",
        undeliverableReason: "99999997",
      },
      metaData: {
        lastUpdated: 1621330819707,
        lastVerifiedDate: 0,
        updatedBy: "PRIVDASH:110834210",
      },
    },
    faxBusiness: {
      value: {
        value: "32465800",
        undeliverableReason: "32465800",
      },
      metaData: {
        lastUpdated: 1567682712291,
        lastVerifiedDate: 0,
        updatedBy: "WOLF_SYNCH",
      },
    },
    mobilePurchased: {
      value: {
        value: "32465800",
        undeliverableReason: "32465800",
      },
      metaData: {
        lastUpdated: 1276319773917,
        lastVerifiedDate: 0,
        updatedBy: "STB_INIT",
      },
    },
    telephoneBusiness: {
      value: {
        value: "32465800",
        undeliverableReason: "32465800",
      },
      metaData: {
        lastUpdated: 1567682712276,
        lastVerifiedDate: 0,
        updatedBy: "WOLF_SYNCH",
      },
    },
  },
  consents: {
    directMail: {
      value: true,
      metaData: {
        lastUpdated: 1613745075591,
        lastVerifiedDate: 0,
        updatedBy: "MASK",
      },
    },
    enterprise: {
      value: false,
      metaData: {
        lastUpdated: 1613745081884,
        lastVerifiedDate: 0,
        updatedBy: "MASK",
      },
    },
    teleMarketing: {
      value: false,
      metaData: {
        lastUpdated: 1613745075591,
        lastVerifiedDate: 0,
        updatedBy: "MASK",
      },
    },
    electronicSolicitation: {
      value: true,
      metaData: {
        lastUpdated: 1256770800000,
        lastVerifiedDate: 0,
        updatedBy: "1SL",
      },
    },
    electronicCustomer: {
      value: true,
      metaData: {
        lastUpdated: 1385620068832,
        lastVerifiedDate: 0,
        updatedBy: "sq2",
      },
    },
    previouslyAccruedPublicSector: {
      value: true,
      metaData: {
        lastUpdated: 1385620068832,
        lastVerifiedDate: 0,
        updatedBy: "d5g",
      },
    },
    simPensionNationalInsSch: {
      value: true,
      metaData: {
        lastUpdated: 1385620068832,
        lastVerifiedDate: 0,
        updatedBy: "d5g",
      },
    },
  },
  preferredDistributionChannel: {
    value: "portal",
    metaData: {
      lastUpdated: 1386435418955,
      lastVerifiedDate: 0,
      updatedBy: "KND",
    },
  },
  preferredAddress: {
    value: "residence",
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  crs: {
    value: undefined,
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  taxIdentities: {},
  fatca: {
    value: undefined,
    metaData: undefined,
  },
  dateOfBirth: {
    value: birthDate,
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  gender: {
    value: "Male",
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  maritalStatus: {
    value: undefined,
    metaData: undefined,
  },
  citizenship: {
    value: {
      firstCitizenship: "NO",
      secondCitizenship: undefined,
      thirdCitizenship: undefined,
    },
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  birthplace: {
    value: "NO",
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  names: {
    official: {
      value: {
        givenNameOne: "Marte Bernhard",
        givenNameTwo: "",
        givenNameThree: "",
        middleName: "",
        lastName: "Hegre-Test",
      },
      metaData: {
        lastUpdated: 1479312509282,
        lastVerifiedDate: 0,
        updatedBy: "DSF_load",
      },
    },
  },
  advantagePrograms: [],
  identities: {
    eBankId: {
      value: {
        identityNumber: "12345678910",
        identityStatus: "active",
        description: "Keycloak a3a63ac4-0676-42da-9c4a-919cbeb9780c",
        countryOfIssue: undefined,
        issueDate: undefined,
        expiryDate: "2022-05-06T00:00:00.000+02:00",
        startDate: "2021-05-06T00:00:00.000+02:00",
      },
      metaData: {
        lastUpdated: 1620297568798,
        lastVerifiedDate: 0,
        updatedBy: "110834210",
      },
    },
  },
  status: {
    value: "Aktiv",
    metaData: {
      lastUpdated: 1621330818811,
      lastVerifiedDate: 0,
      updatedBy: "PRIVDASH:110834210",
    },
  },
  unofficialDeceasedDate: {
    value: undefined,
    metaData: undefined,
  },
  officialDeceasedDate: {
    value: undefined,
    metaData: undefined,
  },
  identificationNumbers: [
    {
      value: {
        value: "12345678910",
        type: "norwegianPin",
        primary: undefined,
        endDate: undefined,
      },
      metaData: {
        lastUpdated: 1276319773777,
        lastVerifiedDate: 0,
        updatedBy: "STB_INIT",
      },
    },
    {
      value: {
        value: "110834210",
        type: "customerId",
        primary: true,
        endDate: undefined,
      },
      metaData: {
        lastUpdated: 1276319773919,
        lastVerifiedDate: 0,
        updatedBy: "STB_INIT",
      },
    },
  ],
};
