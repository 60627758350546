import { Injectable } from "@angular/core";
import { range } from "lodash-es";
import { MAX_LIFE_EXPECTANCY_AGE } from "src/app/constants/business.constants";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

@Injectable({
  providedIn: "root",
})
export class OtherPensionPrognosisService {
  public async getPrognosis(engagement: OtherPensionEngagement, startPayoutAge: number): Promise<Graph.Prognosis> {
    const payoutPlan = await this.getPayoutPlan(engagement, startPayoutAge);

    return {
      simulationStatus: { severity: Graph.SimulationSeverity.Ok, messageKey: null },
      payoutPlan,
    };
  }

  private async getPayoutPlan(
    otherPensionEngagement: OtherPensionEngagement,
    startPayoutAge: number,
  ): Promise<Graph.PayoutPlan[]> {
    const payoutRange = this.getPayoutRange(otherPensionEngagement, startPayoutAge);
    const firstPeriod = otherPensionEngagement.contract.periods.at(0);
    const payoutAmountPerYear = firstPeriod?.payoutAmountPerYear ?? 0;

    return payoutRange.map((age) => ({
      age,
      amount: payoutAmountPerYear,
      minAmount: payoutAmountPerYear,
      maxAmount: payoutAmountPerYear,
    }));
  }

  private getPayoutRange(otherPensionEngagement: OtherPensionEngagement, startPayoutAge: number): number[] {
    const { contract } = otherPensionEngagement;
    const { replaceOffentligTjenestepensjon, periods } = contract;
    const [{ duration }] = periods;

    const toAge = replaceOffentligTjenestepensjon ? this.getLifeLongToAge() : startPayoutAge + duration;

    return range(startPayoutAge, toAge);
  }

  private getLifeLongToAge(): number {
    return MAX_LIFE_EXPECTANCY_AGE + 1;
  }
}
