import { Injectable } from "@angular/core";
import { gql } from "@apollo/client/core";
import { first, map, mergeMap, Observable, switchMap } from "rxjs";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import { KeycloakService } from "src/app/services/keycloak.service";
import { AdvisorsNotAllowedError } from "src/app/utils/errors";
import { PensionFundPayoutOrganization, Query, VouchersByArea } from "./savings-and-pension-queries.types";

const GET_HAS_PENSION_PAYOUTS_QUERY = gql`
  query getHasPensionPayoutsQuery {
    pensionPayouts {
      lifePayouts {
        vouchersByArea {
          payoutArea
        }
      }
      pensionFundPayouts {
        organizations {
          organizationNumber
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class PensionPayoutsQueriesService {
  constructor(
    private readonly savingsGraphqlClient: SavingsGraphqlClientService,
    private readonly keycloakService: KeycloakService,
  ) {}

  public getHasPensionPayouts(): Observable<boolean> {
    return this.keycloakService.isAdvisorContext$.pipe(
      first(),
      switchMap((isAdvisorContext) => {
        if (isAdvisorContext) {
          throw new AdvisorsNotAllowedError();
        }

        return this.savingsGraphqlClient
          .query<Query>({
            query: GET_HAS_PENSION_PAYOUTS_QUERY,
            errorPolicy: "ignore",
          })
          .pipe(mergeMap(getApolloResponseData), map(getHasPensionPayouts));
      }),
    );
  }
}

function getHasPensionPayouts(query: Query): boolean {
  const vouchersByArea = getVouchersByArea(query);
  const hasVouchersByArea = vouchersByArea.length > 0;
  const organizations = getOrganizations(query);
  const hasOrganizations = organizations.length > 0;

  return hasVouchersByArea || hasOrganizations;
}

function getVouchersByArea(query: Query): VouchersByArea[] {
  return query?.pensionPayouts?.lifePayouts?.vouchersByArea ?? [];
}

function getOrganizations(query: Query): PensionFundPayoutOrganization[] {
  return query?.pensionPayouts?.pensionFundPayouts?.organizations ?? [];
}
