import { Observable, combineLatest, map, of } from "rxjs";
import { ContractSalaryService } from "../../api/contract-salary.service";
import { ClientDataService } from "../../customer-supplied-data/client-data.service";
import { IncomeStrategy, createIncomeObject } from "../income.service";
import { Injectable } from "@angular/core";
import { getIsNotNullable, getIsNullable } from "../../../utils/utils";
import { IncomeUser } from "./income-user";
import { getIsSomeItemsTrue } from "../../../utils/array";
import { FmsKey } from "../../fms/fms";

@Injectable()
export class IncomeEmployer implements IncomeStrategy {
  public income$ = this.contractSalaryService.getSalary().pipe(
    map((salary) => {
      return createIncomeObject(salary, "EMPLOYER");
    }),
  );

  constructor(
    private readonly contractSalaryService: ContractSalaryService,
    private readonly clientDataService: ClientDataService,
    private readonly incomeUser: IncomeUser,
  ) {}

  public predicate(): Observable<boolean> {
    const userWantsEmployerIncome$ = this.clientDataService.incomeSource$.pipe(map((source) => source === "EMPLOYER"));
    const isMissingUserIncomeAndHasEmployerIncome$ = combineLatest([this.income$, this.incomeUser.income$]).pipe(
      map(([{ value: employerGivenIncome }, { value: userGivenIncome }]) => {
        return getIsNotNullable(employerGivenIncome) && getIsNullable(userGivenIncome);
      }),
    );
    return combineLatest([userWantsEmployerIncome$, isMissingUserIncomeAndHasEmployerIncome$]).pipe(
      map(getIsSomeItemsTrue),
    );
  }

  public getHintText(): Observable<FmsKey> {
    return of("financesinput.yearly_income_gross_employer.popover");
  }
}
