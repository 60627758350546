import { Injectable } from "@angular/core";
import { catchError, interval, Observable, of } from "rxjs";
import { debounce, distinctUntilChanged, map } from "rxjs/operators";
import { RUNNING_JOBS_DEBOUNCE_TIME } from "src/app/constants/technical.constants";
import { memoize$ } from "src/app/utils/rxjs/select";
import { AbstractRunningJobsService } from "./abstract-running-jobs.service";

@Injectable({
  providedIn: "root",
})
export class GlobalRunningJobsService extends AbstractRunningJobsService {
  public readonly isLoading$: Observable<boolean>;

  constructor() {
    super();

    this.isLoading$ = memoize$(
      this.runningJobs$.pipe(
        map(getHasRunningJobs),
        distinctUntilChanged(),
        debounce(getDebounceDuration),
        catchError(() => of(false)),
      ),
    );
  }
}

function getHasRunningJobs(runningJobs: string[]): boolean {
  return runningJobs.length > 0;
}

function getDebounceDuration(hasRunningJobs: boolean): Observable<number> {
  return hasRunningJobs ? of(0) : interval(RUNNING_JOBS_DEBOUNCE_TIME);
}
