import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import {
  MatLegacyTooltip as MatTooltip,
  LegacyTooltipPosition as TooltipPosition,
} from "@angular/material/legacy-tooltip";
import { Subject, fromEvent } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum TooltipIcon {
  InformationCircle = "information-circle",
  QuestionCircle = "question-circle",
}

@Component({
  selector: "app-tip-icon",
  templateUrl: "./tip-icon.component.html",
  styleUrls: ["./tip-icon.component.scss"],
})
export class TipIconComponent implements OnInit, OnDestroy {
  @ViewChild(MatTooltip, { static: true })
  public tooltip!: MatTooltip;
  @Output()
  public open: EventEmitter<any> = new EventEmitter();
  @Output()
  public close: EventEmitter<any> = new EventEmitter();
  @Input()
  public tooltipText = "";
  @Input()
  public alwaysVisible? = false;
  @Input()
  public tabIndex?: number | string;
  @Input()
  public marginLeft?: boolean = false;

  public tooltipPosition: TooltipPosition = "below";
  private readonly destroy$: Subject<void> = new Subject();

  public ngOnInit(): void {
    this.hideTooltipOnScroll();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  private hideTooltipOnScroll(): void {
    fromEvent(window, "scroll")
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.tooltip.hide());
  }
}
