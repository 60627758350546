import { Injectable } from "@angular/core";
import { ProfileService } from "./customer-supplied-data/profile.service";
import { CustomerService } from "./customer.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EngagementListRerenderTriggerService {
  public readonly triggers$: Observable<unknown>[];

  constructor(
    private readonly profileService: ProfileService,
    private readonly customerService: CustomerService,
  ) {
    const recalculateIsPensionTrigger$ = this.profileService.internalSavings$;
    const ofaConsentContractActionTrigger$ = this.customerService.ofaConsent$;

    this.triggers$ = [recalculateIsPensionTrigger$, ofaConsentContractActionTrigger$];
  }
}
