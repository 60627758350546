import { InjectionToken } from "@angular/core";

export const CLIPBOARD = new InjectionToken<Clipboard>("Navigator clipboard token", {
  factory: (): Clipboard => navigator.clipboard,
});

export const SESSION_STORAGE = new InjectionToken<Storage>("Session storage token", {
  factory: (): Storage => sessionStorage,
});

export const LOCAL_STORAGE = new InjectionToken<Storage>("Local storage token", {
  factory: (): Storage => localStorage,
});

export const WINDOW = new InjectionToken<Window>("Window token", {
  factory: (): Window => window,
});
