<div class="split">
  <div class="image">
    <img [src]="'smartAccountBillboard.imageUrl' | fms" alt />
  </div>

  <div class="text">
    <h2>{{ "smartAccountBillboard.title" | fms }}</h2>

    <p
      [innerHTML]="
        'smartAccountBillboard.description' | fms : { args: { newCustomerInterest: newCustomerInterest$ | async } }
      "
    ></p>

    <button
      stbButton="text"
      [setIconAfter]="true"
      iconType="arrow-right"
      [routerLink]="[smartAccountRoute]"
      data-trackid="link-learn-more-about-smartkonto"
    >
      {{ "smartAccountBillboard.buttonText" | fms }}
    </button>
  </div>
</div>
