import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { combineLatestWith, first, map, Observable } from "rxjs";
import { RecommendedEngagementWithdrawalOrderService } from "src/app/modules/pension-plan/services/recommended-engagement-withdrawal-order.service";
import {
  getEngagementDescription,
  getEngagementTitle,
  getEngagementTotalPayout,
  InitiativeEngagementItem,
} from "src/app/modules/shared/components/engagement-list/engagement-list-initiatives/engagement-list-initiatives.utils";
import { NavigateToWithdrawalOrderAnchorService } from "src/app/modules/shared/services/navigate-to-withdrawal-order-anchor.service";
import { WithdrawalProfileId } from "src/app/modules/shared/services/withdrawal-profiles/withdrawal-profiles.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { isNavEngagement } from "src/app/utils/engagement.typeguards";

enum IconFmsKey {
  IconPensjonsavtaleLevel1Nav = "icons.pensjonsavtale-level-1-nav",
  IconPensjonsavtaleLevel2Employment = "icons.pensjonsavtale-level-2-employment",
  IconPensjonsavtaleLevel3Savings = "icons.pensjonsavtale-level-3-savings",
}

@Component({
  selector: "app-action-withdrawal-order-dialog",
  templateUrl: "./action-withdrawal-order-dialog.component.html",
  styleUrls: ["./action-withdrawal-order-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionWithdrawalOrderDialogComponent {
  public readonly hasSortableEngagements$ = this.withdrawalOrderService.hasMoreThanOneSortedEngagements$;

  public readonly items$ = this.getSortedEngagements().pipe(
    combineLatestWith(this.getUnsortedEngagements()),
    map(([sortedEngagements, unsortedEngagements]) => [...sortedEngagements, ...unsortedEngagements]),
  );

  constructor(
    private readonly dialogRef: MatDialogRef<ActionWithdrawalOrderDialogComponent>,
    private readonly fmsService: FmsService,
    private readonly withdrawalOrderService: RecommendedEngagementWithdrawalOrderService,
    private readonly navigateToWithdrawalOrderAnchorService: NavigateToWithdrawalOrderAnchorService,
  ) {}

  public onPrimaryButtonClick(): void {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.navigateToWithdrawalOrderAnchorService.navigate(WithdrawalProfileId.CustomPayoutAge);
      });

    this.dialogRef.close();
  }

  private getSortedEngagements(): Observable<InitiativeEngagementItem[]> {
    return this.withdrawalOrderService.sortedEngagements$.pipe(
      map((engagements) => engagements.map((engagement) => this.getPresentableSortedEngagement(engagement))),
    );
  }

  private getUnsortedEngagements(): Observable<InitiativeEngagementItem[]> {
    return this.withdrawalOrderService.unsortedEngagements$.pipe(
      map((engagement) => this.getPresentableUnsortedEngagements(engagement)),
    );
  }

  private getIconSrc(engagement: AnyEngagement): string {
    if (engagement.isSavingsEngagement()) {
      return this.fmsService.instant(IconFmsKey.IconPensjonsavtaleLevel3Savings);
    }

    if (isNavEngagement(engagement)) {
      return this.fmsService.instant(IconFmsKey.IconPensjonsavtaleLevel1Nav);
    }

    return this.fmsService.instant(IconFmsKey.IconPensjonsavtaleLevel2Employment);
  }

  private getPresentableSortedEngagement(engagement: AnyEngagement): InitiativeEngagementItem {
    return {
      title: getEngagementTitle(engagement),
      description: getEngagementDescription(engagement),
      iconSrc: this.getIconSrc(engagement),
      values: [getEngagementTotalPayout(engagement)],
    };
  }

  private getPresentableUnsortedEngagements(engagements: AnyEngagement[]): InitiativeEngagementItem[] {
    const unsortedItemsHeader = this.fmsService.instant("actions.withdrawalOrder.dialog.unsortedItemsHeader");

    return engagements.map((engagement, index) => ({
      ...this.getPresentableSortedEngagement(engagement),
      header: index === 0 ? unsortedItemsHeader : undefined,
      hideNumber: true,
    }));
  }
}
