/* This file contains constants related to the business logic
  or business-specific services or systems */

export const MIN_SIMULATION_AGE = 62;
export const MAX_SIMULATION_AGE = 75;
export const DEFAULT_SIMULATION_AGE = 67;
export const DEFAULT_LIFE_EXPECTANCY_INPUT_AGE = 67;
export const MAX_LIFE_EXPECTANCY_AGE = 99;
export const DEFAULT_MAX_LIFE_EXPECTANCY_AGE = 90;
export const MAX_SIMULATION_HISTORY = 3;
export const DEFAULT_PREFETCH_BOTTOM = 3;
export const DEFAULT_PREFETCH_TOP = 3;

export const PENSION_NEEDS_REDUCTION_FACTOR = 0.0;
export const PENSION_NEEDS_DEPRECIATION_FACTOR = 0.02;
export const PENSION_NEEDS_DEPRECIATION_FACTOR_ACTIVATION_YEAR = 10;
export const PENSION_NEEDS_SIGNIFICANT_PAYOUT_CHANGE_BREAKPOINT = 0.1;
export const PENSION_NEEDS_RANGED_START_DECIMAL: Limit = {
  min: 0.65,
  max: 0.85,
};

export const MAX_AGE_SAVINGS_PRODUCT = 67;
export const IPS_CUTOFF_AGE = 66;
export const IPS_MAX_YEARLY_SAVINGS_QUOTA = 15000;

export const SAVINGS_ADVISOR_AGE_REQUIREMENT: Limit = {
  min: 18,
  max: 67,
};

export const PLACEMENT_ACCOUNT_AGE_REQUIREMENT = 55;

export const DEFAULT_PAYOUT_DURATION = 10;

export const OFFENTLIG_TJENESTEPENSJON_MAXIMUM_AGE = 75;

export const PUBLIC_PENSION_AFP_WARNING_BIRTH_YEAR: Limit = {
  min: 1954,
  max: 1962,
};

export const PART_TIME_EXPECTED_FUTURE_INCOME_MAX_AGE = 66;

/* The inactive warning modal will pop up when there is less time remaining
   until token expiry than the following constant (measured in minutes) */
export const INACTIVE_WARNING_TRESHOLD = 2;

/**
 * 15 was (and may still be) the default payout duration in the
 * prognosis service until payout duration was added as a parameter. Instead
 * of omitting the parameter, we're fallbacking to our own default value
 * for old external savings without duration provided by the customer.
 */
export const DEFAULT_EXTERNAL_SAVINGS_PAYOUT_DURATION = 15;

/**
 * EPK defaults to 10 years duration at 67, and will last minimum until 76 for early withdrawal.
 * For this reason, 15 and above is considered a long duration.
 */
export const EPK_LONG_PAYOUT_DURATION_THRESHOLD = 15;

/* Endpoint for fetching AFP prognosis does not allow hasAnnualGrossIncome: null */
export const DEFAULT_ANNUAL_SALARY_FOR_AFP = 0;

/**
 * Defines all keys used to identify products in norskpensjon-rest.
 * Ref rest/norskpensjon-rest/norskpensjon-rest-api/src/main/java/no/storebrand/rest/norskpensjon/enums/NorskpensjonProductCode.java
 */
export enum ProductKeys {
  Afp = "AFP",
  Folketrygd = "FOLKETRYGD",
  OffentligTjenestepensjon = "OFFENTLIG_TJENESTEPENSJON",
}

/**
 * Defines the known values for the 'source' query parameter
 */
export enum KnownSources {
  CreateBankAccount = "cba",
}

/**
 * Bank account product code as defined by SDC
 */
export const AccountProductCodeRegex = {
  SmartAccount: /^pp.{5}$/, // any "plasseringskonto pensjon" aka "smartkonto"
  StorebrandBrukskonto: "pl-----",
  Dreamskonto: "ssd----",
};

export enum PensionArea {
  ASK = "ask",
  Ekstrapensjon = "ekstrapensjon",
  Folketrygd = "folketrygd",
  Fondskontolink = "fondskontolink",
  Garanti = "garanti",
  Hybrid = "hybrid",
  IndIPA = "ind_ipa",
  IndividuelleOrdninger = "individuelleOrdninger",
  IndKapitalforsikring = "ind_kapitalforsikring",
  IndLivrente = "ind_livrente",
  IPA = "ipa",
  IPS = "ips",
  IPS2 = "ips2",
  ITP = "itp",
  Livrente = "livrente",
  Nav = "nav",
  OffentligTjenestepensjon = "offentligTjenestepensjon",
  Pensjonskasse = "pensjonskasse",
  YTP = "ytp",
  Fleksipensjon = "fleksipensjon",
}

export enum PensionGroup {
  AbakusPkasseYtp = "abakus_pkasse_ytp",
  AFP = "AFP",
  Bank = "Bank",
  Ekstrapensjon = "ekstrapensjon",
  Fond = "Fond",
  Fondskontolink = "fondskontolink",
  FPI = "fpi",
  Fripolise = "fripolise",
  Garanti = "garanti",
  HybridGray = "hybrid_gray",
  HybridInvGray = "hybrid_inv_gray",
  IPA = "ipa",
  IPS = "ips",
  IPS2 = "ips2",
  ITP = "itp",
  Kapitalforsikring = "kapitalforsikring",
  Pensjonskapitalbevis = "pensjonskapitalbevis",
  YTP = "ytp",
}

export enum ProductCode {
  LPTB = "LPTB", // Fripolise
  FOKTS = "FOKTS",
  FOKTSU = "FOKTSU",
}

export const COMPANY_PAID_EKSTRAPENSJON_PRODUCT_CODES = [ProductCode.FOKTS, ProductCode.FOKTSU];

export const CHANGEABLE_PORTFOLIOS = [
  PensionGroup.ITP,
  PensionGroup.FPI,
  PensionGroup.Ekstrapensjon,
  PensionGroup.IPS,
  PensionGroup.IPS2,
  PensionGroup.IPA,
  PensionGroup.Pensjonskapitalbevis,
  PensionGroup.HybridInvGray,
  PensionGroup.Fondskontolink,
];

export const WITHDRAWAL_PHASE_SIMULATIONSTATUS_KEY = "not_allowed_in_withdrawal_phase";

export const NOT_ALLOWED_IN_PAYOUT_PHASE_SIMULATION_STATUS_KEY = "not_allowed_in_payout_phase";

export interface Limit {
  min: number;
  max: number;
}

export const DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE = 100;

export const STOREBRAND_ORGANIZATION_NUMBER = "958995369";

export const AFP_MAX_COMPENSATION_BIRTH_YEAR = 1962;
export const AFP_MAX_COMPENSATION_PAYOUT_AGE = 66;
export const AFP_MIN_ANNUAL_GROSS_INCOME = 100000;

export const MINIMUM_AGE_IN_MONTHS_FOR_WITHDRAWAL = 61.5 * 12;

/**
 * Nav prognosis and NorskPensjon prognosis only supports these percentages for partial withdrawal
 * 04.07.2024: partialWithdrawal.withdrawalPercentage must be 20, 40, 50, 60 or 80
 */
export const SUPPORTED_PARTIAL_WITHDRAWAL_PERCENTAGES = [20, 40, 50, 60, 80];

/**
 * Public pension from Norsk Pensjon will always have a pension provider (supplier),
 * but uses a hard-coded name when the pension provider is unknown
 */
export const NORSKPENSJON_PUBLIC_PENSION_UNKNOWN_PENSION_PROVIDER_NAME = "Offentlig tjenestepensjon";

export const NORSKPENSJON_PROGNOSIS_NOT_ALLOWED_STATUS = "IKKE_TILLATT";
export const NORSKPENSJON_PROGNOSIS_NOT_SUPPORTED_STATUS = "IKKE_STOTTET";

export const DAYS_IN_A_YEAR = 365;

export const TOUR_START_DELAY = 3000;

/**
 * Used to calculate 30% of G
 */
export const MINIMAL_NATIONAL_INSURANCE_BASIC_AMOUNT_PERCENT = 0.3;

export const DEFAULT_CUSTOMER_LANGUAGE: CustomerMaster.Language = "NO";

export const APP_TITLE_FMS_KEY = "pageTitle.main";
