<button
  class="btn-toggle hide-only-on-print"
  [class.active]="isExpanded"
  (click)="onClick()"
  data-trackid="button-toggle-show-engagement"
>
  <div class="btn-container">
    <span class="text">
      {{ getButtonText() | fms }}
    </span>
    <i class="icon" [class.rotated]="isExpanded">chevron-down</i>
  </div>
</button>
