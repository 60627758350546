import { AxisTypeValue, Options } from "highcharts";
import { xAxisDefaultFormatter } from "../chart/chart.default-functions";

export const getOptions = (): Options => ({
  xAxis: {
    crosshair: false,
    type: "datetime" as AxisTypeValue,
    //default maxPadding value
    maxPadding: 0.01,
    //default categories value is undefined. Lodash merge with default options removes undefined
    categories: null as unknown as undefined,
    labels: {
      formatter: xAxisDefaultFormatter,
    },
  },
  colors: ["#0191A5", "#4F3B22"],
  legend: {
    enabled: true,
    align: "right",
  },
  plotOptions: {
    series: {
      cursor: "default",
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: {
        enabled: false,
      },
      point: {
        events: {
          click: (): boolean => {
            return false;
          },
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
});
