<app-action-card
  [title]="'actions.epk.title' | fms"
  [icon]="'actions.epk.icon' | fms"
  [content]="'actions.epk.content' | fms"
  [primaryButton]="'actions.epk.primaryButton' | fms"
  [secondaryButton]="'actions.epk.secondaryButton' | fms"
  (primaryButtonClick)="openMoveEpkApp()"
  (secondaryButtonClick)="openAboutEpkDialog()"
  [action]="action"
  [styling]="styling"
>
</app-action-card>
