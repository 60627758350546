import { Injectable } from "@angular/core";
import { Observable, combineLatest, debounceTime, firstValueFrom } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { EndpointService, getHttpHeaderClientId } from "src/app/services/endpoint.service";
import { Nullable, getIsNullable } from "src/app/utils/utils";
import { AdvisorQualificationService } from "../advisor-qualification.service";
import { LeadType } from "../api/smart-pensjon-rest-api.service";
import { ConsentService } from "../consent.service";
import { ProfileService, sumExternalSavingsBalance } from "../customer-supplied-data/profile.service";
import { CustomerService } from "../customer.service";
import { IncomeService } from "../income/income.service";

@Injectable({
  providedIn: "root",
})
export class LeadService {
  constructor(
    private readonly advisorQualificationService: AdvisorQualificationService,
    private readonly customerService: CustomerService,
    private readonly endpointService: EndpointService,
    private readonly profileService: ProfileService,
    private readonly incomeService: IncomeService,
    private readonly consentService: ConsentService,
  ) {}

  public beginPostingLeadsForRegistrationSavingsAndAfp(): Observable<Leads.Response.Lead | undefined> {
    return combineLatest([
      this.getLeadBase(),
      this.advisorQualificationService.isQualifiedForAnyAdvisor$,
      this.consentService.purchaseAssistanceConsentValue$,
      this.consentService.customerSuppliedDataConsent$,
    ]).pipe(
      debounceTime(100),
      switchMap(([leadBase, isQualifiedForAnyAdvisor, purchaseAssistanceConsent, customerDataConsent]) => {
        if (
          getIsNullable(leadBase) ||
          isMissingRequiredFieldsOrConsents(leadBase, purchaseAssistanceConsent, customerDataConsent)
        ) {
          return [undefined];
        }

        const leadType = getLeadType(leadBase.hasAfp, isQualifiedForAnyAdvisor);
        const lead = buildLeadData(leadBase, leadType);
        return this.publishLead(lead);
      }),
    );
  }

  private async publishLead(body: Leads.Request.Lead): Promise<Leads.Response.Lead> {
    return firstValueFrom(
      this.endpointService.composeLeadUrl().pipe(
        take(1),
        switchMap((url) =>
          this.endpointService.httpPost$<Leads.Request.Lead, Leads.Response.Lead>(url, {
            body,
            headers: getHttpHeaderClientId("smart-pensjon-web"),
          }),
        ),
      ),
    );
  }

  private getLeadBase(): Observable<Leads.Request.LeadBase | undefined> {
    return combineLatest([
      this.customerService.phoneNumberMobile$,
      this.customerService.emailAddressPrivate$,
      this.profileService.hasAfp$,
      this.incomeService.getAnnualGrossIncomeOrDefault(0),
      this.profileService.externalSavings$.pipe(map(sumExternalSavingsBalance)),
    ]).pipe(
      map(([phoneNumber, emailAddress, hasAfp, salaryAmount, savingsAmount]) => {
        if (getIsNullable(phoneNumber) || getIsNullable(emailAddress)) {
          return undefined;
        }

        return {
          phoneNumber,
          emailAddress,
          hasAfp,
          salaryAmount,
          savingsAmount,
        };
      }),
    );
  }
}

function getLeadType(hasAfp: boolean, hasReachedSalaryAndOrSavingsThreshold: boolean): LeadType {
  if (hasAfp) {
    return LeadType.AfpAdvisory;
  }
  if (hasReachedSalaryAndOrSavingsThreshold) {
    return LeadType.SavingSalary;
  }

  return LeadType.Registration;
}

function buildLeadData(leadBase: Leads.Request.LeadBase, leadType: LeadType, description = ""): Leads.Request.Lead {
  return {
    ...leadBase,
    leadType,
    description,
  };
}
function isMissingRequiredFieldsOrConsents(
  leadBase: Leads.Request.LeadBase | undefined,
  purchaseAssistanceConsent: Nullable<boolean>,
  customerDataConsent: Nullable<boolean>,
): boolean {
  return (
    getIsNullable(leadBase) ||
    getIsNullable(purchaseAssistanceConsent) ||
    getIsNullable(customerDataConsent) ||
    purchaseAssistanceConsent === false ||
    customerDataConsent === false
  );
}
