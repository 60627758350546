import { Nullable } from "./utils";

export enum SavingsWebExitClient {
  MinPensjon = "min-pensjon",
  SmartPensjon = "smart-pensjon",
}

export interface SavingsWebSearchParams {
  exitClient?: SavingsWebExitClient;
  exitUrl?: string;
  cmid?: string;
}

export enum SupportedSavingsWebSearchParamKey {
  ExitClient = "exitclient",
  ExitUrl = "exiturl",
  CmId = "cmid",
}

export function createSavingsWebUrl(
  url: Nullable<string>,
  { exitClient, cmid, exitUrl }: SavingsWebSearchParams = {},
): string {
  const params = new URLSearchParams();

  if (exitUrl) {
    params.append(SupportedSavingsWebSearchParamKey.ExitUrl, exitUrl);
  }

  if (exitClient) {
    params.append(SupportedSavingsWebSearchParamKey.ExitClient, exitClient);
  }

  if (cmid) {
    params.append(SupportedSavingsWebSearchParamKey.CmId, cmid);
  }

  const hasParams = Array.from(params).length > 0;
  const urlOrEmpty = url ?? "";

  return hasParams ? `${url}?${params}` : urlOrEmpty;
}
