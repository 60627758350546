import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatLegacyDialog as MatDialog,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { combineLatest, first, map, Observable, take } from "rxjs";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import { FmsService } from "src/app/services/fms.service";
import { getIsNotNullable } from "src/app/utils/utils";
import { AddEngagementButton } from "../add-engagements-buttons/add-engagements-buttons.component";

@Component({
  selector: "app-combined-new-engagment",
  templateUrl: "./combined-new-engagment.component.html",
  styleUrls: ["../../../../shared/components/add-engagement/add-engagement.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinedNewEngagmentComponent {
  public readonly buttonsToRender: AddEngagementButton[];
  public readonly radioButtons$: Observable<RadioButton[]>;
  public formControl: FormControl = new FormControl("", [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { buttonsToRender: AddEngagementButton[] },
    private readonly matDialog: MatDialog,
    private readonly fms: FmsService,
  ) {
    this.buttonsToRender = this.data.buttonsToRender;

    this.radioButtons$ = combineLatest(this.buttonsToRender.map((button) => this.createRadioButton(button))).pipe(
      first(),
    );
  }

  public setFormControl(item: RadioButton): void {
    this.formControl.markAsTouched();
    this.formControl.setValue(item.value);
  }

  public onNextButton(formControlValue: string): void {
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
    if (this.formControl.valid) {
      this.matDialog.afterAllClosed.pipe(take(1)).subscribe(() => {
        const component = this.buttonsToRender.find((button) => button.key === formControlValue)?.dialogComponent;

        if (getIsNotNullable(component)) {
          this.matDialog.open(component, {
            width: LARGE_DIALOG_WINDOW_WIDTH,
            panelClass: "large-dialog-padding",
            autoFocus: false,
          });
        }
      });

      this.matDialog.closeAll();
    }
  }

  private createRadioButton(button: AddEngagementButton): Observable<RadioButton> {
    return this.fms.translateAsync<string>(button.radioTextFmsKey).pipe(
      map((radioText) => ({
        label: radioText,
        value: button.key,
        disabled: false,
        checked: false,
      })),
    );
  }
}
