import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { getIsNotNullable } from "src/app/utils/utils";

@Component({
  selector: "app-labeled-number",
  templateUrl: "./labeled-number.component.html",
  styleUrls: ["./labeled-number.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledNumberComponent {
  @Input()
  public value!: number;
  @Input()
  public suffix!: string;

  public isAvailable = getIsNotNullable;
}
