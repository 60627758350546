import { differenceInMonths, parseISO } from "date-fns";
import { immerable, produce } from "immer";
import { Observable } from "rxjs";
import { PensionArea, PensionGroup } from "src/app/constants/business.constants";
import { getAppInjector } from "src/app/injector";
import { EngagementName, EngagementSimulationStatus } from "src/app/models/pension.model";
import { FmsService } from "src/app/services/fms.service";
import { Boundary } from "src/app/utils/getNumberWithinBounds";
import { Nullable, getIsNotNullable, getIsNullable } from "src/app/utils/utils";

import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

export type ContractTypeOf<T> = T extends GenericEngagement<infer X, unknown, unknown> ? X : never;
export type PrognosisTypeOf<T> = T extends GenericEngagement<unknown, infer X, unknown> ? X : never;
export type PrognosisParametersTypeOf<T> = T extends GenericEngagement<unknown, unknown, infer X> ? X : never;
export type GenericEngagementTypeOf<E> = GenericEngagement<
  ContractTypeOf<E>,
  PrognosisTypeOf<E>,
  PrognosisParametersTypeOf<E>
>;
export type GenericEngagementOfUnknown = GenericEngagement<unknown, unknown, unknown>;

interface Product {
  productCode: string | undefined;
}

export interface PayoutDuration {
  months: number | null;
  years: number | null;
}

export const PAYOUT_DURATIONS: { [key: string]: PayoutDuration } = {
  infinite: { years: Infinity, months: Infinity },
  invalid: { years: null, months: null },
};

/**
 * GenericEngagement is the base class for all engagements and
 * prognoses. It contains functions and data relevant for the scope
 * of all engagements.
 *
 * Important note: Keep business rules away from the model! For every
 * change to the model you should evaluate thoroughly if the feature
 * belongs here or in a service. For instance, it can be tempting to
 * expand each prognosis with an isPension flag as this may seem
 * relevant to the individual prognosis and should follow the object.
 * But it is the business rules that determine the value of this flag,
 * and that would introduce a dependency on either the model or the
 * creator to know the business rules in order to instantiate an
 * object. In addition, the flag is stored to the profile, meaning
 * that we would be duplicating the profile state to the objects. That
 * would be wrong for several reasons, but mainly because it is not
 * DRY. The duplication results in extra maintenance, and it is hard
 * to reason about, especially for new developers entering the
 * project. The isPension flag belongs in a service that defines the
 * business rules.
 */
export abstract class GenericEngagement<TContract, TPrognosis, TPrognosisParams> {
  [immerable] = true;

  protected constructor(
    public readonly contract: TContract,
    public readonly prognosis?: TPrognosis,
    public readonly contractTraceReference?: string,
    public readonly prognosisParametersInput?: TPrognosisParams,
  ) {}

  public withPrognosis(prognosis: TPrognosis): this {
    const that = produce<GenericEngagementOfUnknown>(this, (draft) => {
      draft.prognosis = prognosis;
    });

    return that as this;
  }

  public withPrognosisParametersInput(input: TPrognosisParams | null): this {
    const that = produce<GenericEngagementOfUnknown>(this, (draft) => {
      if (getIsNotNullable(input)) {
        draft.prognosisParametersInput = input;
      }
    });

    return that as this;
  }

  public withContractTraceReference(trace: Nullable<string>): this {
    const that = produce<GenericEngagementOfUnknown>(this, (draft) => {
      if (getIsNotNullable(trace)) {
        draft.contractTraceReference = trace;
      }
    });

    return that as this;
  }

  public getSimulationParametersKey(): string {
    return this.getIdentifier();
  }

  public getContractTraceReference(): string {
    return this.contractTraceReference ?? "Not implemented";
  }

  public getPayoutAgeRangeBoundary(): Boundary | undefined {
    return undefined;
  }

  public getProduct(): Nullable<Product> {
    return { productCode: (this.contract as any)?.productCode ?? undefined };
  }

  protected getFmsService(): FmsService {
    return getAppInjector().get(FmsService);
  }

  public abstract getPayoutFromAge(): number | undefined;

  public abstract getPayoutPlan(): unknown[];

  public abstract getContinousPeriods(): unknown[];

  public abstract isPublicPensionFromNorskPensjon(): boolean;

  public abstract getIdentifier(): string;

  /** @deprecated Use getNameAsync instead */
  public abstract getName(age?: number): EngagementName;

  public abstract getNameAsync(age?: number): Observable<EngagementName>;

  public abstract getSimulationStatus(): EngagementSimulationStatus[];

  public abstract getAverageAnnualPayout(): Nullable<number>;

  public abstract getTotalPayout(): number | null;

  public abstract getFirstYearPayout(): Nullable<number>;

  public abstract getPayoutDuration(): PayoutDuration;

  public abstract isLifelongPayout(): boolean;

  public abstract getPensionArea(): Nullable<PensionArea>;

  public abstract getPensionGroup(): Nullable<PensionGroup>;

  public abstract getContractNumber(): Nullable<string>;

  public abstract getContractNumberCustomer(): Nullable<string>;

  public abstract getBalance(): Nullable<number>;

  public abstract isActive(): boolean;

  /**
   * Contracts that not have current gibalance by design
   */
  public abstract hasStipultatedBaseRatePayout(): boolean;

  public abstract isSavingsEngagement(): boolean;

  /**
   * Defines whether the user may change the
   * investment profile of this contract.
   */
  public abstract hasChangeableProfile(): boolean;

  /**
   * @deprecated used for diffing purposes. To be disposed with TRM-2589.
   */
  public abstract getApiResource(): string;

  public abstract getCompressionLimits(): number[];

  public abstract getSelectableInvestmentProfileInPayoutPeriod(): Graph.InvestmentProfileInPayoutPeriod[];

  public abstract isOneTimePayout(): boolean;

  public abstract isCompressed(): boolean;
}

export const getPayoutPlanDuration = (payoutPlan: Graph.PayoutPlan[]): PayoutDuration => {
  const firstPayoutPlanDate = payoutPlan.at(0)?.startDate;
  const lastPayoutPlanDate = payoutPlan.at(-1)?.endDate;

  if (getIsNullable(firstPayoutPlanDate) || getIsNullable(lastPayoutPlanDate)) {
    return PAYOUT_DURATIONS.invalid;
  }

  const monthsInPeriod = differenceInMonths(parseISO(lastPayoutPlanDate), parseISO(firstPayoutPlanDate)) + 1;
  if (monthsInPeriod < 12) {
    return { months: monthsInPeriod, years: 0 };
  }

  const differenceInYears = Math.floor(monthsInPeriod / 12);
  const additionalMonths = monthsInPeriod % 12;

  return { months: additionalMonths, years: differenceInYears };
};
