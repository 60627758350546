import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable, of, startWith } from "rxjs";
import { map } from "rxjs/operators";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";
import { YearPipe } from "src/app/modules/shared/pipes/year.pipe";
import { EngagementService } from "src/app/services/engagement.service";
import { Nullable } from "src/app/utils/utils";
import { PublicPensionProgosisParametersDynamicService } from "../../../../../services/api/public-pension-prognosis-parameters-dynamic.service";
import { CurrencyFormatterPipe } from "../../../pipes/currency-formatter.pipe";

interface ContractDetail {
  key: string;
  value: Nullable<number | string>;
}

@Component({
  selector: "app-engagement-details-public-pension",
  templateUrl: "./engagement-details-public-pension.component.html",
  styleUrls: ["../engagement-details-shared.scss", "./engagement-details-public-pension.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsPublicPensionComponent implements OnInit, EngagementBody {
  @Input()
  public engagement!: PublicPensionEngagement;
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;

  public contractDetails$!: Observable<ContractDetail[]>;

  public readonly ofaVendors$ =
    this.publicPensionProgosisParametersDynamicService.dynamicPublicPensionPrognosisParameters$.pipe(
      map(({ ofaVendors }) => ofaVendors || []),
      startWith([]),
    );

  constructor(
    private readonly currencyFormatterPipe: CurrencyFormatterPipe,
    private readonly yearPipe: YearPipe,
    private readonly engagementService: EngagementService,
    private readonly publicPensionProgosisParametersDynamicService: PublicPensionProgosisParametersDynamicService,
  ) {}

  public ngOnInit(): void {
    const prognosisBoundaries$: Observable<ContractDetail[]> = of(this.engagement.prognosisParameters).pipe(
      map((parameters) => [
        {
          key: "publicPensionEngagement.label.salary",
          value: this.currencyFormatterPipe.transform(parameters?.mainSalary, "start"),
        },
        {
          key: "publicPensionEngagement.label.addedSalary",
          value: this.currencyFormatterPipe.transform(parameters?.addedSalary, "start"),
        },
        {
          key: "publicPensionEngagement.label.minimumAgeWithdrawal",
          value: this.yearPipe.transform(parameters?.minimumAgeWithdrawal),
        },
        {
          key: "publicPensionEngagement.label.maximumAgeWithdrawal",
          value: this.yearPipe.transform(parameters?.maximumAgeWithdrawal),
        },
      ]),
    );

    this.contractDetails$ = prognosisBoundaries$;
  }

  public getTotalPayout(): number | null {
    return this.engagement.getTotalPayout();
  }

  public getDuration(): string {
    return this.engagementService.getHumanReadableDuration(this.engagement);
  }
}
