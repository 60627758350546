import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import { Primitive } from "src/app/utils/types";
import { getIsNullable, Nullable } from "src/app/utils/utils";

@Directive()
export abstract class ControlValueDirective<
  TElementRef extends HTMLElement,
  TValue extends Nullable<Primitive> = Primitive,
> implements ControlValueAccessor
{
  protected onChange?: (_: any) => {};
  protected onTouched?: (_: any) => {};

  constructor(
    protected readonly renderer: Renderer2,
    protected readonly elementRef: ElementRef<TElementRef>,
  ) {}

  @HostListener("input", ["$event"])
  public onInput(event: Event): void {
    const value: TValue = (event.target as HTMLInputElement).value as TValue;
    const sanitizedValue = this.sanitizeValue(value);

    this.writeValue(sanitizedValue);
    this.onChange?.(sanitizedValue);
  }

  @HostListener("blur")
  public onBlur(_: any): void {
    this.onTouched?.(_);
  }

  public writeValue(value: TValue): void {
    if (getIsNullable(value) || typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
      this.writeToView(this.sanitizeValue(value));
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = (val, ...args): number => fn(this.sanitizeValue(val), ...args);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, "disabled", isDisabled);
  }

  protected setProperty(key: keyof TElementRef, value: TValue): void {
    this.renderer.setProperty(this.elementRef.nativeElement, key as string, value);
  }

  protected abstract sanitizeValue(value: TValue): TValue;
  protected abstract writeToView(value: TValue): void;
}
