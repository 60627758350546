import { Inject, inject, Injectable, InjectionToken } from "@angular/core";
import { ChipItem } from "@storeblocks/chip-ng";
import { defer, map, merge, mergeMap, Observable, of, toArray } from "rxjs";
import { PartialWithdrawalProfile } from "src/app/modules/shared/services/withdrawal-profiles/partial-withdrawal.profile";
import { SimulationHistoryService } from "../simulation-history.service";
import { CustomPayoutAgeProfile } from "./custom-payout-age.profile";
import { SamePayoutAgeProfile } from "./same-payout-age.profile";

export enum WithdrawalProfileId {
  SamePayoutAge = "SamePayoutAge",
  CustomPayoutAge = "CustomPayoutAge",
  PartialWithdrawal = "PartialWithdrawal",
}

export interface WithdrawalProfile {
  getId(): WithdrawalProfileId;
  getDescription(): Promise<string>;
  getTrackId(): Promise<string>;
  changeProfile(): Promise<void>;
  getProfileWhenActive(): Observable<WithdrawalProfile>;
}

export interface WithdrawalProfileChip extends ChipItem {
  readonly value: WithdrawalProfileId;
}

export const WITHDRAWAL_PROFILES_TOKEN = new InjectionToken("Withdrawal profiles token", {
  providedIn: "any",
  factory(): WithdrawalProfile[] {
    return [inject(SamePayoutAgeProfile), inject(CustomPayoutAgeProfile), inject(PartialWithdrawalProfile)];
  },
});

@Injectable()
export class WithdrawalProfilesService {
  constructor(
    @Inject(WITHDRAWAL_PROFILES_TOKEN)
    private readonly profiles: WithdrawalProfile[],
    private readonly simulationHistoryService: SimulationHistoryService,
  ) {}

  public getActiveWithdrawalProfile(): Observable<WithdrawalProfile> {
    const profiles = this.profiles.map((it) => it.getProfileWhenActive());
    return merge(...profiles);
  }

  public async changeWithdrawalProfile(id: WithdrawalProfileId): Promise<void> {
    const profile = this.profiles.find((it) => it.getId() === id);

    if (profile) {
      await profile.changeProfile();
      this.simulationHistoryService.clearSimulationHistory();
    }
  }

  public getWithdrawalProfiles(): WithdrawalProfile[] {
    return this.profiles;
  }

  public getWithdrawalProfilesChips(): Observable<WithdrawalProfileChip[]> {
    return this.getActiveWithdrawalProfile().pipe(mergeMap(makeCreateWithdrawalProfileChips(this.profiles)));
  }
}

const makeCreateWithdrawalProfileChips =
  (profiles: WithdrawalProfile[]) =>
  (activeProfile: WithdrawalProfile): Observable<WithdrawalProfileChip[]> =>
    of(...profiles).pipe(
      mergeMap((profile) =>
        defer(() =>
          Promise.all([
            profile.getId(),
            profile.getDescription(),
            profile.getId() === activeProfile.getId(),
            profile.getTrackId(),
          ]),
        ).pipe(
          map(([value, label, selected, trackId]) => ({
            id: value,
            label,
            selected,
            trackId,
            value,
            disabled: false,
          })),
        ),
      ),
      toArray(),
    );
