import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { BehaviorStore } from "../utils/rxjs/store";

type CanChangeCompressionByEngagement = [string, { canCompress: boolean }];

@Injectable({
  providedIn: "root",
})
export class CompressionLimitService {
  private readonly canChangeCompressionByEngagement$ = new BehaviorStore<CanChangeCompressionByEngagement[]>([]);

  public async storeIfCompressionLimitAffectsPrognosis(id: string, affectsPrognosis: boolean): Promise<void> {
    const currentValue = this.canChangeCompressionByEngagement$.value;
    const filteredCurrent = currentValue.filter(([entryId]) => entryId !== id);

    const update: CanChangeCompressionByEngagement = [id, { canCompress: affectsPrognosis }];

    this.canChangeCompressionByEngagement$.next([...filteredCurrent, update]);
  }

  public getIfCompressionLimitChangesPrognosis(id: string): Observable<boolean> {
    return this.canChangeCompressionByEngagement$
      .asObservable()
      .pipe(map((engagement) => engagement.find(([entryId]) => entryId === id)?.[1].canCompress ?? false));
  }
}
