import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatLegacyTooltip as MatTooltip } from "@angular/material/legacy-tooltip";
import { Observable, Subject, fromEvent, map, takeUntil } from "rxjs";
import { EngagementSimulationStatus } from "src/app/models/pension.model";
import { FmsService } from "src/app/services/fms.service";
import { Alert, simStatusToAlert } from "src/app/utils/alert.utils";
import { getIsNotNullable } from "src/app/utils/utils";

interface PopoverAlert extends Alert {
  tooltip: string;
}

@Component({
  selector: "app-sim-status-popover-inline-alert",
  templateUrl: "./sim-status-popover-inline-alert.component.html",
  styleUrls: ["./sim-status-popover-inline-alert.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimStatusPopoverInlineAlertComponent implements OnInit, OnDestroy {
  @ViewChild(MatTooltip, { static: false })
  public popover!: MatTooltip | undefined;

  @Input()
  private readonly simStatus!: EngagementSimulationStatus;

  public alert$!: Observable<PopoverAlert | undefined>;
  private readonly destroy$: Subject<void> = new Subject();

  public constructor(private readonly fmsService: FmsService) {}

  public ngOnInit(): void {
    this.hideTooltipOnScroll();
    this.alert$ = this.getAlert(this.simStatus);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public hideTooltip(): void {
    if (getIsNotNullable(this.popover)) {
      this.popover.disabled = true;
    }
  }

  public click(): void {
    if (getIsNotNullable(this.popover)) {
      this.popover.disabled = false;
      this.popover.toggle();
    }
  }

  private hideTooltipOnScroll(): void {
    fromEvent(window, "scroll")
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.popover?.hide());
  }

  private getAlert(simulationStatus: EngagementSimulationStatus): Observable<PopoverAlert | undefined> {
    const { key, args } = simulationStatus;

    return this.fmsService.translateAsync<string>(key, { args }).pipe(
      map((tooltip) => {
        const alert = simStatusToAlert(simulationStatus);

        return alert ? { ...alert, tooltip } : undefined;
      }),
    );
  }
}
