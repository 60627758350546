import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FmsService } from "src/app/services/fms.service";
import { AlertType } from "src/app/utils/alert.utils";

export enum AlertKey {
  Dashboard = "dashboard",
  PensionPlan = "pension-plan",
  Contracts = "contracts",
}

export interface InfoMessage {
  key: AlertKey;
  showMessage: boolean;
  type: AlertType;
  heading: string;
  innerHTML: string;
  closeButtonLabel: string;
}

@Component({
  selector: "app-information-message",
  templateUrl: "./information-message.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationMessageComponent implements OnInit {
  @Input()
  private readonly alertKey!: AlertKey;

  public readonly infoMessageClosed$ = new BehaviorSubject<boolean>(false);
  public infoMessage!: InfoMessage | undefined;

  constructor(private readonly fmsService: FmsService) {}

  ngOnInit(): void {
    this.infoMessage = this.fmsService
      .instant<InfoMessage[]>("infoMessage")
      .find((infoMessage: InfoMessage) => infoMessage.key === this.alertKey);
  }
}
