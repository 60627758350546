<button style="color: white" mat-button [matMenuTriggerFor]="fetches">Fetch</button>

<mat-menu #fetches="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="engagements">Engagements</button>
  <button mat-menu-item [matMenuTriggerFor]="other">Other</button>
</mat-menu>

<mat-menu #engagements="matMenu">
  <button mat-menu-item (click)="fetchAfpEngagements()">afp</button>
  <button mat-menu-item (click)="fetchBankEngagements()">bank</button>
  <button mat-menu-item (click)="fetchExternalEngagements()">external</button>
  <button mat-menu-item (click)="fetchNpEngagements()">norskpensjon</button>
</mat-menu>

<mat-menu #other="matMenu">
  <button mat-menu-item (click)="fetchConsents()">consent-rest-api</button>
  <button mat-menu-item (click)="fetchCustomer()">customer-rest-api</button>
  <button mat-menu-item [matMenuTriggerFor]="customerSupplied">customer-supplied-rest-api</button>
  <button mat-menu-item (click)="fetchTexts()">fms-rest-api</button>
  <button mat-menu-item (click)="fetchBenefits()">benefits-rest-api</button>
</mat-menu>

<mat-menu #customerSupplied="matMenu">
  <button mat-menu-item (click)="fetchProfile()">profile</button>
  <button mat-menu-item (click)="fetchClientData()">supplied-data</button>
</mat-menu>
