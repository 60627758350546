import { Log } from "src/app/utils/log";

export enum SentryDsn {
  PROD = "https://a32f86d027ef48388338e9f41a6fb602@sentry.io/1388144",
  DUMMY = "https://acacaeaccacacacabcaacdacdacadaca@sentry.io/000001",
}

export interface EnvConfig {
  production: boolean;
  hmr: boolean;
  dsn: SentryDsn;
  sentryRelease: string;
  offlineMode?: boolean;
  enableAllServiceMocks?: boolean;
  enableAllPrognosisMocks?: boolean;
  skipConsents?: boolean;
  appVersion?: string;
  logLevel?: Log.Level;
}

export const applicationId = "387e5e03-6fc9-434e-bb93-1c79bf665a7e";
export const clientToken = "pub9a3c74e7dfb88a494f734f262e656e73";
