<div
  class="card-wrapper"
  [class.split]="splitTitle"
  (click)="cardClicked(url)"
  [attr.data-trackid]="trackId"
  role="button"
>
  <h3>{{ title }}</h3>

  <img alt [src]="illustration" />

  <button role="link" class="stb-button" stbButton="text" iconType="arrow-right" [setIconAfter]="true">
    {{ buttonText }}
  </button>
</div>
