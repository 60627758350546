<button
  type="button"
  #tooltip="matTooltip"
  (click)="tooltip.toggle()"
  [matTooltip]="tooltipText"
  [matTooltipPosition]="tooltipPosition"
  [class.always-visible]="alwaysVisible"
  [attr.tabindex]="tabIndex"
  matTooltipClass="storeblocks-styling"
  [ngClass]="{ 'margin-left': marginLeft }"
>
  <i>information</i>
</button>
