import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { mergeMap, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import { PortfolioInsight, Query } from "src/app/services/api/savings-and-pension-queries.types";

type GetPortfolioInsightQuery = Pick<Query, "portfolioInsight">;

const GET_PORTFOLIO_INSIGHT_QUERY = gql`
  query getPortfolioInsightQuery($ids: [String]) {
    headers @client {
      correlationId
    }
    portfolioInsight(ids: $ids) {
      keyValues {
        marketValue {
          value
        }
        totalReturn {
          value
        }
        annualAverageReturnPct
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class PortfolioInsightQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getPortfolioInsight(ids: string[]): Observable<PortfolioInsight> {
    return this.savingsGraphqlClient
      .query<GetPortfolioInsightQuery>({
        query: GET_PORTFOLIO_INSIGHT_QUERY,
        variables: { ids },
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map(({ portfolioInsight }) => portfolioInsight),
      );
  }
}
