import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ResponseHeaders } from "src/app/modules/graphql-clients/local-schema/response-headers";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { PrognosisQueryResult } from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { getIsNotNullable } from "src/app/utils/utils";
import {
  NORSKPENSJON_CONTRACT_FIELDS_FRAGMENT,
  NORSKPENSJON_CONTRACT_UNIQUE_FIELDS_FRAGMENT,
} from "./graphql-query-fragments";

export interface GetNorskpensjonContractsQuery {
  norskpensjon: {
    contracts: Graph.NorskpensjonContract[];
  };
  headers?: ResponseHeaders;
}
export interface GetAfpFromNorskpensjonQuery {
  norskpensjon: {
    afpPrognosis: Graph.Prognosis;
  };
  headers?: ResponseHeaders;
}

export const GET_NORSKPENSJON_CONTRACTS_QUERY = gql`
  query getNorskpensjonContractsQuery($input: NorskpensjonInput) {
    norskpensjon(input: $input) {
      contracts {
        ...NorskpensjonContractFields
      }
    }
  }
  ${NORSKPENSJON_CONTRACT_FIELDS_FRAGMENT}
`;

export interface GetUnavailableNorskpensjonContractsQuery {
  norskpensjon: {
    unavailablePensionProviders: Graph.UnavailablePensionProvider[];
  };
}

export const GET_UNAVAILABLE_NORSKPENSJON_CONTRACTS_QUERY = gql`
  query getUnavailableNorskpensjonContractsQuery($input: NorskpensjonInput) {
    norskpensjon(input: $input) {
      unavailablePensionProviders {
        category
        numberOfMissingContracts
        errorCode
        completelyUnavailable
        pensionProvider {
          name
        }
      }
    }
  }
`;

export const GET_NORSKPENSJON_PROGNOSIS_QUERY = gql`
  query getNorskpensjonPrognosisQuery($input: NorskpensjonInput) {
    headers @client {
      correlationId
    }
    norskpensjon(input: $input) {
      contracts {
        ...NorskpensjonContractUniqueFields
        prognosis {
          payoutPlan {
            age
            amount
          }
          simulationStatus {
            severity
            messageKey
          }
        }
      }
    }
  }
  ${NORSKPENSJON_CONTRACT_UNIQUE_FIELDS_FRAGMENT}
`;

export const GET_AFP_PROGNOSIS_FROM_NORSKPENSJON_QUERY = gql`
  query getAfpPrognosisFromNorskpensjonQuery($input: NorskpensjonInput) {
    headers @client {
      correlationId
    }
    norskpensjon(input: $input) {
      afpPrognosis {
        payoutPlan {
          age
          amount
        }
        simulationStatus {
          severity
          messageKey
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class NorskPensjonQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getNorskpensjonContracts(): Observable<Graph.NorskpensjonContract[]> {
    return this.savingsGraphqlClient
      .query<GetNorskpensjonContractsQuery>({
        query: GET_NORSKPENSJON_CONTRACTS_QUERY,
      })
      .pipe(mergeMap(getApolloResponseData), map(getNorskpensjonContractsFromResponse));
  }

  public getUnavailableNorskpensjonContracts(): Observable<Graph.UnavailablePensionProvider[]> {
    return this.savingsGraphqlClient
      .query<GetUnavailableNorskpensjonContractsQuery>({
        query: GET_UNAVAILABLE_NORSKPENSJON_CONTRACTS_QUERY,
      })
      .pipe(mergeMap(getApolloResponseData), map(getUnavailableNorskpensjonContractsFromResponse));
  }

  public getNorskpensjonContractsWithPrognosis(
    variables: Graph.QueryNorskpensjonArgs,
  ): Observable<PrognosisQueryResult<Graph.NorskpensjonContract[]>> {
    return this.savingsGraphqlClient
      .query<GetNorskpensjonContractsQuery>({
        query: GET_NORSKPENSJON_PROGNOSIS_QUERY,
        variables,
      })
      .pipe(mergeMap(getApolloResponseData), map(getNorskpensjonContractsWithPrognosisFromResponse));
  }

  public getAfpPrognosis(variables: Graph.QueryNorskpensjonArgs): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.savingsGraphqlClient
      .query<GetAfpFromNorskpensjonQuery>({
        query: GET_AFP_PROGNOSIS_FROM_NORSKPENSJON_QUERY,
        variables,
      })
      .pipe(mergeMap(getApolloResponseData), map(getAfpPrognosesFromResponse));
  }
}

function getNorskpensjonContractsFromResponse(query: GetNorskpensjonContractsQuery): Graph.NorskpensjonContract[] {
  return query?.norskpensjon?.contracts ?? [];
}

function getNorskpensjonContractsWithPrognosisFromResponse(
  query: GetNorskpensjonContractsQuery,
): PrognosisQueryResult<Graph.NorskpensjonContract[]> {
  return {
    data: query?.norskpensjon?.contracts ?? [],
    headers: query.headers,
  };
}

function getUnavailableNorskpensjonContractsFromResponse(
  query: GetUnavailableNorskpensjonContractsQuery,
): Graph.UnavailablePensionProvider[] {
  return query?.norskpensjon?.unavailablePensionProviders ?? [];
}

function getAfpPrognosesFromResponse(query: GetAfpFromNorskpensjonQuery): PrognosisQueryResult<Graph.Prognosis[]> {
  const prognosis = query.norskpensjon?.afpPrognosis;

  return {
    data: [prognosis].filter(getIsNotNullable),
    headers: query.headers,
  };
}
