import { catchError, interval, merge, Observable, of, pipe, UnaryFunction } from "rxjs";
import { Injectable } from "@angular/core";
import { combineLatestWith, debounce, distinctUntilChanged, filter, map, mapTo, switchMap } from "rxjs/operators";
import { RUNNING_JOBS_DEBOUNCE_TIME } from "src/app/constants/technical.constants";
import { GenericEngagementOfUnknown } from "src/app/models/engagements/generic-engagement.model";
import { memoize$ } from "src/app/utils/rxjs/select";
import { BehaviorStore } from "src/app/utils/rxjs/store";
import { AbstractRunningJobsService } from "./abstract-running-jobs.service";

@Injectable({
  providedIn: "root",
})
export class FetchPrognosesRunningJobsService extends AbstractRunningJobsService {
  public readonly isCurrentYearLoaded$: Observable<boolean>;
  public readonly isLoading$: Observable<boolean>;
  public readonly runningEngagementJobs$: Observable<string[]>;

  private readonly fetchingPrognosisForEngagement$ = new BehaviorStore<GenericEngagementOfUnknown[]>([]);

  constructor() {
    super();

    this.isCurrentYearLoaded$ = memoize$(
      this.runningJobs$.pipe(
        combineLatestWith(this.fetchingPrognosisForEngagement$),
        map(getIsCurrentYearLoaded),
        distinctUntilChanged(),
        debounce(getDebounceDuration),
        catchError(() => of(false)),
      ),
    );

    this.isLoading$ = this.isCurrentYearLoaded$.pipe(map((value) => !value));

    this.runningEngagementJobs$ = this.fetchingPrognosisForEngagement$.pipe(map(getJobNamesFromEngagements));

    this.startLongRunningJobWatcher(merge(this.runningJobs$, this.runningEngagementJobs$));
  }

  public waitForCurrentYearLoadedPipe<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(switchMap((val: T) => this.isCurrentYearLoaded$.pipe(filter(Boolean), mapTo(val))));
  }

  public startFetchingPrognosisForEngagement(engagement: GenericEngagementOfUnknown): void {
    this.fetchingPrognosisForEngagement$.next([...this.fetchingPrognosisForEngagement$.getValue(), engagement]);
  }

  public stopFetchingPrognosisForEngagement(engagement: GenericEngagementOfUnknown): void {
    this.fetchingPrognosisForEngagement$.next(
      this.fetchingPrognosisForEngagement$.getValue().filter((e) => isNotEqualIdentifier(e, engagement)),
    );
  }

  public getIsRunningJobForEngagement(engagement: GenericEngagementOfUnknown): Observable<boolean> {
    return this.runningEngagementJobs$.pipe(
      map((runningJobs) => runningJobs.some((job) => job === getJobNameFromEngagement(engagement))),
    );
  }
}

function getIsCurrentYearLoaded([fetchesInProgress, fetchingPrognosisForEngagement]: [
  string[],
  GenericEngagementOfUnknown[],
]): boolean {
  return fetchesInProgress.length === 0 && fetchingPrognosisForEngagement.length === 0;
}

function getDebounceDuration(isCurrentYearLoaded: boolean): Observable<number> {
  return !isCurrentYearLoaded ? of(0) : interval(RUNNING_JOBS_DEBOUNCE_TIME);
}

function isNotEqualIdentifier(a: GenericEngagementOfUnknown, b: GenericEngagementOfUnknown): boolean {
  return a.getIdentifier() !== b.getIdentifier();
}

function getJobNameFromEngagement(engagement: GenericEngagementOfUnknown): string {
  const { name } = engagement.getName();
  const id = engagement.getIdentifier();

  return `${name}:${id}`;
}

function getJobNamesFromEngagements(engagements: GenericEngagementOfUnknown[]): string[] {
  return engagements.map(getJobNameFromEngagement);
}
