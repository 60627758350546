/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { map, withLatestFrom } from "rxjs/operators";
import { AfpEngagement } from "src/app/models/engagements/norskpensjon/afp-engagement.model";
import { BankEngagement } from "src/app/models/engagements/bank-engagement.model";
import { ExternalSavingsEngagement } from "src/app/models/engagements/external-savings-engagement.model";
import { AbstractNavEngagement } from "src/app/models/engagements/nav-engagement.model";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { PublicPensionEngagement } from "src/app/models/engagements/public-pension-engagement.model";
import {
  KapitalforsikringEngagement,
  LivrenteAlisEngagement,
} from "src/app/models/engagements/savings-and-pension/alis-engagement.model";
import {
  EpkEmploymentEngagement,
  EpkEngagement,
  EpkFleksibelEngagement,
} from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import {
  FripoliseEngagement,
  InnskuddspensjonMedGarantiEngagement,
} from "src/app/models/engagements/savings-and-pension/fripolise-engagement.model";
import {
  HybridMedGarantiEngagement,
  HybridMedInvesteringsvalgEngagement,
  HybridPensjonsbevisEngagement,
} from "src/app/models/engagements/savings-and-pension/hybrid-engagement.model";
import {
  EkstrapensjonEngagement,
  FondskontoLinkEngagement,
  GarantiEngagement,
  IpaLinkEngagement,
  IpsEngagement,
  LivrenteLinkEngagement,
} from "src/app/models/engagements/savings-and-pension/link-engagement.model";
import {
  YtpFundedEngagement,
  YtpUnfundedEngagement,
} from "src/app/models/engagements/savings-and-pension/pension-fund-engagement.model";
import { PkbEngagement } from "src/app/models/engagements/savings-and-pension/pkb-engagement.model";
import { YtpEngagement } from "src/app/models/engagements/savings-and-pension/ytp-engagement.model";
import { AnyEngagement, EngagementsService } from "src/app/services/engagements.service";
import { InternalSavingsService } from "src/app/services/internal-savings.service";

const RECOMMENDED_WITHDRAWAL_ORDER_BY_TYPE = [
  AfpEngagement,
  AbstractNavEngagement,
  FripoliseEngagement,
  PublicPensionEngagement,
  YtpUnfundedEngagement,
  YtpFundedEngagement,
  YtpEngagement,
  HybridMedGarantiEngagement,
  HybridMedInvesteringsvalgEngagement,
  HybridPensjonsbevisEngagement,
  InnskuddspensjonMedGarantiEngagement,
  EpkEngagement,
  EpkFleksibelEngagement,
  EpkEmploymentEngagement,
  PkbEngagement,
  IpaLinkEngagement,
  GarantiEngagement,
  KapitalforsikringEngagement,
  LivrenteAlisEngagement,
  LivrenteLinkEngagement,
  IpsEngagement,
  EkstrapensjonEngagement,
  FondskontoLinkEngagement,
  BankEngagement,
  OtherPensionEngagement,
  ExternalSavingsEngagement,
];

type GetUnsortedEngagementsArgs = [AnyEngagement[], AnyEngagement[]];

@Injectable({
  providedIn: "root",
})
export class RecommendedEngagementWithdrawalOrderService {
  private readonly allEngagements$ = this.engagementsService.allEngagements$.pipe(
    map((engagements) => this.filterIncludedEngagements(engagements)),
  );

  public readonly sortedEngagements$ = this.allEngagements$.pipe(map(sortEngagementsByRecommendedWithdrawalOrder));

  public readonly unsortedEngagements$ = this.sortedEngagements$.pipe(
    withLatestFrom(this.allEngagements$),
    map(getUnsortedEngagements),
  );

  public readonly hasMoreThanOneSortedEngagements$ = this.sortedEngagements$.pipe(
    map((engagements) => engagements.length > 1),
  );

  public readonly hasUnsortedEngagementsEngagements$ = this.unsortedEngagements$.pipe(
    map((engagements) => engagements.length > 0),
  );

  constructor(
    private readonly engagementsService: EngagementsService,
    private readonly internalSavingsService: InternalSavingsService,
  ) {}

  private filterIncludedEngagements(engagements: AnyEngagement[]): AnyEngagement[] {
    return engagements.filter((engagement) => this.internalSavingsService.isCustomerPension(engagement));
  }
}

const sortEngagementsByRecommendedWithdrawalOrder = (engagements: AnyEngagement[]): AnyEngagement[] =>
  RECOMMENDED_WITHDRAWAL_ORDER_BY_TYPE.flatMap((engagementType) =>
    engagements.filter((engagement) => engagement instanceof engagementType),
  );

function getUnsortedEngagements(args: GetUnsortedEngagementsArgs): AnyEngagement[] {
  const [filteredEngagements, allEngagements] = args;
  const filteredEngagementIds = filteredEngagements.map((item) => item.getIdentifier());

  return allEngagements.filter((engagement) => !filteredEngagementIds.includes(engagement.getIdentifier()));
}
