<h3 mat-dialog-title>{{ "refreshModal.title" | fms }}</h3>
<mat-dialog-content class="modal-dialog-content-container">
  <p>
    {{ "refreshModal.description" | fms }}
  </p>
</mat-dialog-content>

<div mat-dialog-actions>
  <button stbButton="contained" (click)="refresh()">
    {{ "refreshModal.action" | fms }}
  </button>
</div>
