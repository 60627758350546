<h2 *ngIf="withTitle" class="stb-mb2">{{ title$ | async }}</h2>

<div class="content">
  <div class="left" *ngIf="withIcon">
    <img [src]="icon$ | async" alt />
  </div>

  <div class="right">
    <div [innerHtml]="shortDisclaimerText$ | async"></div>

    <div *ngIf="isExpanded$ | async" [innerHtml]="longDisclaimerText$ | async"></div>

    <button
      *ngIf="isExpandable"
      class="link"
      [attr.data-trackid]="
        (isExpanded$ | async) ? 'minimize-info-about-calculations' : 'see-more-info-about-calculations'
      "
      (click)="toggle()"
    >
      {{ buttonText$ | async }}
    </button>
  </div>
</div>
