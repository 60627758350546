import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
  ButtonLink,
  ContractAction,
  ExternalLink,
  InternalLink,
} from "../../../services/contract-actions/contract-actions.types";

@Component({
  selector: "app-contract-actions-bar",
  templateUrl: "./contract-actions-bar.component.html",
  styleUrls: ["./contract-actions-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractActionsBarComponent {
  @Input()
  public readonly title: string = "";
  @Input()
  public readonly actions: ContractAction[] = [];
  @Input()
  public readonly isActive?: boolean;

  public isActionTypeButtonLink(contractAction: ContractAction): contractAction is ButtonLink {
    return !!(contractAction as ButtonLink).onClick;
  }

  public isActionTypeExternalLink(contractAction: ContractAction): contractAction is ExternalLink {
    return !!(contractAction as ExternalLink).linkUrl;
  }

  public isActionTypeInternalLink(contractAction: ContractAction): contractAction is InternalLink {
    return !!(contractAction as InternalLink).linkRouter;
  }
}
