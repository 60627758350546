<app-action-card
  [title]="'actions.smartAccount.title' | fms"
  [icon]="'actions.smartAccount.icon' | fms"
  [content]="content$ | async"
  [primaryButton]="'actions.smartAccount.primaryButton' | fms"
  [secondaryButton]="'actions.smartAccount.secondaryButton' | fms"
  (primaryButtonClick)="openSmartAccountCreatorClient()"
  (secondaryButtonClick)="openAboutDialog()"
  [action]="action"
>
</app-action-card>
