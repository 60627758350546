import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-promo-box",
  templateUrl: "./promo-box.component.html",
  styleUrls: ["./promo-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBoxComponent {
  @Input()
  promoPicture!: string;

  @Input()
  promoTitle!: string;

  @Input()
  promoContent!: string;
}
