import Highcharts, { DashStyleValue, Options } from "highcharts";
import { GeneralChartColors } from "src/app/constants/highcharts-colors.constants";
import { HIGHCHARTS_YAXIS_BREAKPOINT } from "src/app/constants/technical.constants";
import { ChartTranslations } from "src/app/services/chart.service";
import { getIsNullable } from "src/app/utils/utils";
import {
  BLANK_XAXIS_POINT,
  defaultTooltipHeader,
  defaultTooltipPoint,
  xAxisYearLabelsFormatter,
} from "./chart.default-functions";

const getDefaultChart = (): Highcharts.ChartOptions => {
  return {
    animation: {
      duration: 200,
    },
    backgroundColor: undefined,
    spacingTop: 15,
    spacingBottom: 10,
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
    events: {
      redraw(): void {
        const hasPlotband = (this.xAxis[0].options.plotBands?.length ?? 0) > 0;
        const hasNoSelectedPoints = this.getSelectedPoints()?.length === 0;

        if (hasPlotband && hasNoSelectedPoints) {
          this.update(
            {
              xAxis: {
                plotBands: [],
              },
            },
            true,
            false,
            false,
          );
        }
      },
    },
  };
};

const getDefaultLang = (): Highcharts.LangOptions => {
  return {
    numericSymbols: undefined,
    numericSymbolMagnitude: 1000000,
  };
};

const getDefaultTooltip = (translations: ChartTranslations): Highcharts.TooltipOptions => {
  return {
    hideDelay: 250,
    borderWidth: 0,
    backgroundColor: "white",
    shared: true,
    useHTML: true,
    padding: 12,
    borderRadius: 8,
    outside: true,
    formatter(): string[] | false {
      if (getIsNullable(this.x) || getIsNullable(this.points) || this.points[0].point.category === BLANK_XAXIS_POINT) {
        return false;
      }

      const header = defaultTooltipHeader(this.x, translations);
      const points = this.points
        .filter((formattedPoint) => (formattedPoint.point.y || 0) > 0)
        .map(({ point }) => `<hr class="tooltip-point-line">` + defaultTooltipPoint(point, true))
        .join("");

      return [header, points];
    },
  };
};

const getDefaultxAxis = (startPayoutAge: number, categories: string[]): Highcharts.XAxisOptions => {
  return {
    startOnTick: false,
    endOnTick: false,
    categories: categories,
    lineColor: GeneralChartColors.GridLine,
    crosshair: {
      color: GeneralChartColors.Band,
      zIndex: 3,
    },
    labels: {
      rotation: 0,
      useHTML: true,
      style: {
        fontFamily: "Open Sans",
        textOverflow: "none",
        whiteSpace: "nowrap",
        fontSize: "0.875em",
      },

      formatter: function (): string {
        return xAxisYearLabelsFormatter.call(this, startPayoutAge);
      },
    },
  };
};
const getDefaultyAxis = (): ExtendedYAxisOptions => {
  return {
    title: undefined,
    tickAmount: 5,
    gridLineColor: GeneralChartColors.GridLine,
    minorGridLineColor: GeneralChartColors.GridLine,
    minorGridLineDashStyle: "ShortDash" as DashStyleValue,
    minorTickInterval: 1,
    minorTickAmount: 1,
    labels: {
      formatter: function (): string {
        const formattedValue = this.value.toLocaleString("nb").replace(/([\d\s]+)\s(\d+)$/, "<strong>$1</strong> $2");
        return this.isFirst ? `kr <strong>${formattedValue}</strong>` : formattedValue;
      },
      useHTML: true,
      style: {
        color: GeneralChartColors.AxisText,
        fontSize: "0.875em",
        "min-width": "65px",
        "max-width": "65px",
        textOverflow: "none",
        whiteSpace: "nowrap",
      },
    },
  };
};

const getDefaultPlotOptions = (): Highcharts.PlotOptions => {
  return {
    column: {
      groupPadding: 0.075,
      pointPadding: 0.0,
      stacking: "normal",
      borderWidth: 1,
      borderColor: GeneralChartColors.Border,
      borderRadius: 0,
    },
    series: {
      animation: false,
      states: {
        inactive: {
          opacity: 1,
        },
        select: {
          color: undefined,
          borderColor: GeneralChartColors.Border,
          animation: false,
          borderWidth: 0,
        },
        hover: {
          brightness: 0,
        },
      },
    },
  };
};

export const defaultOptions = (
  startPayoutAge: number,
  translations: ChartTranslations,
  categories: string[] = [],
): Options => ({
  chart: getDefaultChart(),
  lang: getDefaultLang(),
  tooltip: getDefaultTooltip(translations),
  credits: {
    enabled: false,
  },

  exporting: {
    enabled: false,
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  xAxis: getDefaultxAxis(startPayoutAge, categories),
  yAxis: getDefaultyAxis(),
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: HIGHCHARTS_YAXIS_BREAKPOINT,
        },
        chartOptions: {
          yAxis: {
            labels: {
              style: {
                "min-width": "30px",
                "max-width": "30px",
                whiteSpace: "normal",
              },
            },
          },
        },
      },
    ],
  },
  plotOptions: getDefaultPlotOptions(),
});

export interface ExtendedYAxisOptions extends Highcharts.YAxisOptions {
  /**
   * (Custom property) Sets the number of minorTicks in between regular
   * tick marks.
   *
   * Solution implemented in https://github.com/highcharts/highcharts/issues/13336
   */
  minorTickAmount: number;
}
