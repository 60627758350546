import { Injectable } from "@angular/core";
import { produce } from "immer";
import { getIsStorageAvailable } from "../utils/getIsStorageAvailable";
import { AnyObject } from "../utils/object";
import { getIsNotNullable } from "../utils/utils";

const ROOT_STORAGE_KEY = "appData";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private readonly isStorageAvailable: boolean;

  constructor(private readonly storage: Storage) {
    this.isStorageAvailable = getIsStorageAvailable(this.storage);
  }

  public set<T = unknown>(key: string, newValue: T): void {
    if (this.isStorageAvailable) {
      const previousValue = this.getAppData<AnyObject>() ?? {};
      const nextValue = produce(previousValue, (draft) => {
        draft[key] = newValue;
      });

      this.storage.setItem(ROOT_STORAGE_KEY, JSON.stringify(nextValue));
    }
  }

  public get<T>(key: string, fallback: T): T {
    const appDataString = this.storage.getItem(ROOT_STORAGE_KEY);

    if (!this.isStorageAvailable || !appDataString) {
      return fallback;
    }

    const appData: AnyObject<T> = JSON.parse(appDataString);

    return getIsNotNullable(appData[key]) ? appData[key] : fallback;
  }

  public clear(key: string): void {
    this.set(key, undefined);
  }

  public wipe(): void {
    if (this.isStorageAvailable) {
      this.storage.removeItem(ROOT_STORAGE_KEY);
    }
  }

  public isAvailable(): boolean {
    return this.isStorageAvailable;
  }

  public getAppData<T>(): T | undefined {
    const appDataString = this.storage.getItem(ROOT_STORAGE_KEY);

    if (!this.isStorageAvailable || !appDataString) {
      return undefined;
    }

    return JSON.parse(appDataString);
  }
}
