<figure class="chart-component">
  <app-chart-skeleton
    *ngIf="showSkeletons$ | async; else chart"
    [lines]="skeletons"
    [width]="skeletonsWidth"
    [style.height]="height + 'px'"
  >
  </app-chart-skeleton>

  <ng-template #chart>
    <highcharts-chart
      [Highcharts]="Highcharts"
      constructorType="chart"
      (chartInstance)="emitChartInstance($event)"
      [options]="optionsWithSeries$ | async"
      [oneToOne]="oneToOne"
    ></highcharts-chart>
  </ng-template>
</figure>
