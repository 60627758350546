import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable, firstValueFrom, map, of, shareReplay } from "rxjs";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { CustomerService } from "src/app/services/customer.service";
import { FmsService } from "src/app/services/fms.service";

@Component({
  selector: "app-action-justify-attorney",
  templateUrl: "./action-justify-attorney.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionJustifyAttorneyComponent implements ActionComponent {
  @Input()
  public action!: Action;

  public readonly isFramCustomer$ = this.customerService.framCustomer$.pipe(
    map((value) => value === true),
    shareReplay(1),
  );

  constructor(
    private readonly customerService: CustomerService,
    private readonly fmsService: FmsService,
  ) {}

  public async openUrl(): Promise<void> {
    const isFramCustomer = await firstValueFrom(this.isFramCustomer$);
    const url = isFramCustomer
      ? await firstValueFrom(this.fmsService.translateAsync<string>("actions.justifyAttorney.FramCustomer.url"))
      : await firstValueFrom(this.fmsService.translateAsync<string>("actions.justifyAttorney.url"));
    window.open(url, "_blank");
  }

  public getIsVisible(): Observable<boolean> {
    return of(true);
  }
}
