import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AVAILABLE_ACTION_TYPES_WHITELIST_TOKEN } from "src/app/modules/shared/services/contract-actions/action-builders/available-actions-builder.tokens";
import {
  ContractActionBuilder,
  ExternalLink,
} from "src/app/modules/shared/services/contract-actions/contract-actions.types";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { getHasContractNavigation } from "src/app/utils/engagement.utils";

@Injectable()
export class AvailableActionsBuilder implements ContractActionBuilder {
  constructor(
    private readonly fmsService: FmsService,
    @Inject(AVAILABLE_ACTION_TYPES_WHITELIST_TOKEN)
    private readonly whitelist: Graph.AvailableActionTypes[],
  ) {}

  public predicate(engagement: AnyEngagement): boolean {
    return this.getActions(engagement).length > 0;
  }

  public build(engagement: AnyEngagement): Promise<ExternalLink[]> {
    const actions = this.getActions(engagement);
    const externalLinks = actions.map((action) => this.toExternalLink(action));

    return Promise.all(externalLinks);
  }

  private getActions(engagement: AnyEngagement): Graph.AvailableAction[] {
    if (!getHasContractNavigation(engagement)) {
      return [];
    }

    const { availableActions } = engagement.contract.contractNavigation;
    const actions = availableActions ?? [];

    return actions.filter(getIsAllowed).filter((action) => this.getIsWhitelisted(action));
  }

  private async toExternalLink(action: Graph.AvailableAction): Promise<ExternalLink> {
    const { actionNavigationLink, actionType } = action;
    const linkLabel = await this.getLinkLabel(action);
    const linkUrl = actionNavigationLink ?? "";

    return {
      linkLabel,
      linkUrl,
      linkTrackId: `availableAction-${actionType}`,
    };
  }

  private getLinkLabel(action: Graph.AvailableAction): Promise<string> {
    return firstValueFrom(this.fmsService.translateAsync<string>(`availableActionNames.${action.actionType}`));
  }

  private getIsWhitelisted(action: Graph.AvailableAction): boolean {
    return this.whitelist.includes(action.actionType);
  }
}

function getIsAllowed(action: Graph.AvailableAction): boolean {
  return action.actionStatus === "ACTION_ALLOWED";
}
