import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { isAfpEngagement, isNavEngagement } from "src/app/utils/engagement.typeguards";
import { getIsUserAddedEngagement } from "../../../engagement/engagement-footer/engagement-footer.utils";

@Component({
  selector: "app-supplier-text-or-logo",
  templateUrl: "./supplier-text-or-logo.component.html",
  styleUrls: ["./supplier-text-or-logo.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierTextOrLogoComponent implements OnChanges {
  @Input()
  public readonly engagement!: AnyEngagement;

  public supplier$!: Observable<string | undefined>;
  public isSupplierStorebrand$!: Observable<boolean>;
  public isSupplierNav!: boolean;

  constructor(private readonly fmsService: FmsService) {}

  public ngOnChanges(): void {
    this.supplier$ = this.getSupplier(this.engagement);
    this.isSupplierStorebrand$ = this.getIsSupplierStorebrand(this.supplier$, this.engagement);
    this.isSupplierNav = getIsSupplierNav(this.engagement);
  }

  private getSupplier(engagement: AnyEngagement): Observable<string | undefined> {
    return engagement.getNameAsync().pipe(map(({ supplier }) => supplier));
  }

  private getIsSupplierStorebrand(
    supplier$: Observable<string | undefined>,
    engagement: AnyEngagement,
  ): Observable<boolean> {
    return combineLatest([this.fmsService.translateAsync<string>("GenericEngagement.supplier"), supplier$]).pipe(
      map(([storebrandSupplier, supplier]) => {
        const isStorebrandSupplier = supplier === storebrandSupplier;
        const isNotUserAdded = !getIsUserAddedEngagement(engagement);

        return isStorebrandSupplier && isNotUserAdded;
      }),
    );
  }
}

function getIsSupplierNav(engagement: AnyEngagement): boolean {
  const isFolketrygd = isNavEngagement(engagement);
  const isAfp = isAfpEngagement(engagement);

  return isFolketrygd || isAfp;
}
