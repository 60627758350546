import { Injectable } from "@angular/core";
import { SessionStorageService, StorageKey } from "src/app/services/session-storage.service";
import { getIsNullable } from "../utils/utils";
import { AnyEngagement } from "./engagements.service";
import { FmsService } from "./fms.service";

@Injectable({
  providedIn: "root",
})
export class EngagementService {
  constructor(
    private readonly fms: FmsService,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public getChangeSavingsProfileUrl(engagement: AnyEngagement): string {
    return this.fms.instant("agreement_details.changeSavingsProfileUrl", {
      args: {
        contractNumber: engagement.getContractNumber(),
        exitUrl: window.location.href,
      },
    });
  }

  public setEngagementInSessionStorage(engagementId: number): void {
    const previousEngagementIds = this.sessionStorageService.get<number[]>(StorageKey.NewAgreements, []);
    const isIncluded = previousEngagementIds.includes(engagementId);

    if (!isIncluded) {
      const nextEngagementIds = [...previousEngagementIds, engagementId];

      this.sessionStorageService.set(StorageKey.NewAgreements, nextEngagementIds);
    }
  }

  public removeEngagementFromSessionStorage(engagementId: number): void {
    const engagementData = this.sessionStorageService.get<number[]>(StorageKey.NewAgreements, []);
    const index = engagementData.findIndex((id) => id === engagementId);

    if (index > -1) {
      engagementData.splice(index, 1);
      this.sessionStorageService.set(StorageKey.NewAgreements, engagementData);
    }
  }

  public getHumanReadableDuration(engagement: AnyEngagement): string {
    const { years, months } = engagement.getPayoutDuration();

    if (getIsNullable(years) || getIsNullable(months)) {
      return "";
    }

    switch (years) {
      case null:
        return "";
      case Infinity:
        return this.fms.instant("agreement_details.duration.life_long", {
          firstLetterUpperCase: true,
        });
      default: {
        const yearText = `${years} ${this.fms.instant("agreement_details.duration.years")}`;
        if (months === 0) {
          return yearText;
        }

        return `${yearText} ${months} ${this.fms.instant("agreement_details.duration.months")}`;
      }
    }
  }
}
