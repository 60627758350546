import { Directive, EventEmitter, Output } from "@angular/core";
import Highcharts from "highcharts";

@Directive()
export abstract class AbstractChartContainer {
  @Output()
  public chartInitialized = new EventEmitter<void>();

  protected chartInstance: Highcharts.Chart | undefined;

  public highchartsInitialized(chartInstance: Highcharts.Chart): void {
    this.chartInstance = chartInstance;
    this.chartInitialized.emit();
  }
}
