import { freeze } from "immer";

const suppliedDataMock: CustomerSuppliedData.Profile = {
  lastUpdated: 1539182081400,
  cmid: "701183002",
  desiredEndOfPayouts: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  annualSalary: {
    value: 600000,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1537513889347,
  },
  birthYearsOfChildren: {
    value: [1940],
    source: {
      id: "701183002",
      type: "CMID",
      role: "CUSTOMER",
      channel: "PRIVACY_DASHBOARD",
    },
    lastUpdated: 1529933942066,
  },
  yearlyPensionPayout: {
    value: 346000,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1537513889347,
  },
  desiredMonthlySavings: {
    value: 300,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  desiredPensionRate: {
    value: 70,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1537513889347,
  },
  desiredRetirementAge: {
    value: 70,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  desiredYearlyPension: {
    value: 600,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  knownDebt: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  maritalStatus: {
    value: "MARRIED",
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1537513889347,
  },
  partnerBirthYear: {
    value: 1902,
    source: {
      id: "701183002",
      type: "CMID",
      role: "CUSTOMER",
      channel: "PRIVACY_DASHBOARD",
    },
    lastUpdated: 1529925707921,
  },
  clientDataRefs: [
    {
      value: { key: "client_data_CMID_701183002_YPN", client: "YPN" },
      source: {
        id: "701183002",
        type: "CMID",
        role: "CUSTOMER",
        channel: "NYT",
      },
      lastUpdated: 1533191897251,
    },
    {
      value: { key: "client_data_CMID_701183002_NYT", client: "NYT" },
      source: {
        id: "701183002",
        type: "CMID",
        role: "CUSTOMER",
        channel: "NYT",
      },
      lastUpdated: 1537525475190,
    },
  ],
  hasAFP: {
    value: false,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "NYT" },
    lastUpdated: 1539182081400,
  },
  externalSavings: {
    value: [
      {
        type: "bank",
        balance: 1000000,
        periodicDeposit: 0,
        contractNumber: "123456",
        addlInfo: "stuff",
        isPension: false,
        fromAge: 67,
        durationYears: 5,
      },
    ],
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1537513889347,
  },
  internalSavings: {
    value: [
      {
        key: "SPL-FOKF-2833479-false",
        isPension: true,
      },
      {
        key: "91003002027",
        isPension: false,
      },
      {
        key: "23130647986-NO0010317282",
        isPension: false,
      },
    ],
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  firstYearPensionPayout: null,
};

const suppliedDataMock2: CustomerSuppliedData.Profile = {
  lastUpdated: 1547488051683,
  cmid: "110749497",
  desiredEndOfPayouts: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  annualSalary: {
    value: 600000,
    source: {
      id: "110749497",
      type: "CMID",
      role: "CUSTOMER",
      channel: "NYT",
    },
    lastUpdated: 1547121982734,
  },
  partTimePercent: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  birthYearsOfChildren: {
    value: [0, 0],
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  yearlyPensionPayout: {
    value: 453000,
    source: {
      id: "110749497",
      type: "CMID",
      role: "CUSTOMER",
      channel: "YPN",
    },
    lastUpdated: 1538128971088,
  },
  desiredMonthlySavings: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  desiredPensionRate: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  desiredRetirementAge: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  desiredYearlyPension: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  knownDebt: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  maritalStatus: {
    value: "M",
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  partnerBirthYear: {
    value: 0,
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  clientDataRefs: [
    {
      value: {
        key: "client_data_CMID_110749497_YPN",
        client: "YPN",
      },
      source: {
        id: "110749497",
        type: "CMID",
        role: "CUSTOMER",
        channel: "NYT",
      },
      lastUpdated: 1533193172246,
    },
    {
      value: {
        key: "client_data_CMID_110749497_NYT",
        client: "NYT",
      },
      source: {
        id: "110749497",
        type: "CMID",
        role: "CUSTOMER",
        channel: "NYT",
      },
      lastUpdated: 1547488051683,
    },
  ],
  hasAFP: {
    value: true,
    source: {
      id: "110749497",
      type: "CMID",
      role: "CUSTOMER",
      channel: "NYT",
    },
    lastUpdated: 1539178736699,
  },
  externalSavings: {
    value: [
      {
        type: "bank",
        balance: 0,
        periodicDeposit: 0,
        contractNumber: "asdf",
        addlInfo: "asdf",
        isPension: true,
        fromAge: 72,
        durationYears: 10,
      },
      {
        type: "fond",
        balance: 1230,
        periodicDeposit: 0,
        contractNumber: "fdsa",
        addlInfo: "fdsa",
        isPension: true,
        fromAge: 67,
        durationYears: 5,
      },
    ],
    source: {
      id: "110749497",
      type: "CMID",
      role: "CUSTOMER",
      channel: "NYT",
    },
    lastUpdated: 1547453656535,
  },
  internalSavings: {
    value: [],
    source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "YPN" },
    lastUpdated: 1529476140092,
  },
  firstYearPensionPayout: null,
};

const suppliedDataClientDataMock: CustomerSuppliedData.ClientDataWrapper = {
  source: { id: "701183002", type: "CMID", role: "CUSTOMER", channel: "NYT" },
  lastUpdated: 1537525475188,
  cmid: "701183002",
  client: "NYT",
  clientData: {
    agreementsMetaDataMap: {},
    monthlyPensionSavings: "2000",
    monthlyDebt: "1000",
    additionalInfo: {
      otherMonthlyIncomeNet: "10000",
      futureMonthlyDebtCosts: "200",
      totalSavings: "200000",
      isPayingWealthTax: false,
      annualWealthTaxExpenses: "0",
      monthlyDebtCosts: "50000",
      totalDebt: "12000",
      monthlySavings: "3000",
      hasBeenTouched: true,
    },
    simulationParameters: {
      postPensionPartTimeEndAge: 0,
      postPensionPartTimeEnable: false,
      postPensionPartTimePercent: 0,
      postPensionWithdrawalPercentage: 100,
      postPensionFutureSalary: 0,
      startPayoutAge: 67,
      startPayoutAgePartialWithdrawal: 66,
      partTimePercentage: 0,
      withdrawalPercentage: 50,
    },
    simulationParametersByEngagement: [],
    simulationParametersByEngagementEnable: false,
    simulationParametersPartialWithdrawalEnable: false,
    monthlyIncomeNet: "30000",
    hasBookedAdvisor: true,
    followUp: {
      advisor: {},
      followUpTasks: [
        {
          title: "Slå sammen avtaler",
          contentMarkdown: `Donec nec justo eget felis facilisis fermentum.
* Aliquam porttitor mauris sit amet orci.
 - Aenean dignissim pellentesque felis.`,
        },
        {
          title: "Se på risikoprofil",
          contentMarkdown:
            "Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.",
          //completed: true
        },
        {
          title: "Ta ut AFP",
        },
      ],
      summaries: [
        {
          title: "Pensjoneringstidspunkt",
          content: "Ved 67 år, vil vurdere 64 år om sluttpakken er god",
        },
        {
          title: "Tidlig uttak",
          content: "Ønsker å ta ut AFP og starte et gradert uttak av Folketrygden",
        },
        {
          title: "Økonomi",
          content: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.

Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.`,
        },
        {
          title: "Hva bør du tenke på",
          content: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.

Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.`,
        },
      ],
    },
    otherPensions: [
      {
        id: "1",
        title: "James Bond Secret Pension",
        includeInPension: true,
        replaceOffentligTjenestepensjon: false,
        periods: [
          {
            payoutAmountPerYear: 3000,
            fromAge: 68,
            duration: 5,
          },
        ],
      },
      {
        id: "420",
        title: "Offentlig tjenestepensjon",
        includeInPension: true,
        replaceOffentligTjenestepensjon: true,
        periods: [
          {
            payoutAmountPerYear: 69,
            fromAge: 69,
            duration: 5,
          },
        ],
      },
      {
        id: "1337",
        includeInPension: true,
        title: "The Horses Royal Fund",
        replaceOffentligTjenestepensjon: false,
        periods: [
          {
            payoutAmountPerYear: 1500000,
            fromAge: 73,
            duration: 2,
          },
        ],
      },
      {
        id: "131",
        includeInPension: true,
        title: "Minas gangsterfond (Fresh)",
        replaceOffentligTjenestepensjon: false,
        periods: [
          {
            payoutAmountPerYear: 30000,
            fromAge: 69,
            duration: 3,
          },
          {
            payoutAmountPerYear: 20000,
            fromAge: 72,
            duration: 2,
          },
        ],
      },
    ],
    progress: [],
    pensionPlan: {},
    optOutOffentligTjenestepensjon: undefined,
    optOutSmartAccount: undefined,
    optOutAfp: undefined,
    incomeSource: "USER",
    compressionLimitByEngagement: [],
    investmentProfileInPayoutPeriodByEngagement: [],
  },
};

const suppliedDataClientDataMock2: CustomerSuppliedData.ClientDataWrapper = {
  clientData: {
    monthlyDebt: "0",
    monthlyIncomeNet: "1234",
    additionalInfo: {
      otherMonthlyIncomeNet: "0",
      futureMonthlyDebtCosts: "0",
      totalSavings: "0",
      isPayingWealthTax: false,
      annualWealthTaxExpenses: "0",
      monthlyDebtCosts: "0",
      totalDebt: "0",
      monthlySavings: "0",
      hasBeenTouched: false,
    },
    monthlyPensionSavings: "0",
    hasBookedAdvisor: false,
    simulationParameters: {
      startPayoutAge: 75,
      startPayoutAgePartialWithdrawal: 74,
      postPensionPartTimeEndAge: 0,
      postPensionPartTimeEnable: false,
      postPensionWithdrawalPercentage: 100,
      postPensionFutureSalary: 0,
      postPensionPartTimePercent: 0,
      partTimePercentage: 0,
      withdrawalPercentage: 50,
    },
    simulationParametersByEngagement: [],
    simulationParametersByEngagementEnable: false,
    simulationParametersPartialWithdrawalEnable: false,
    followUp: {
      advisor: {},
      summaries: [],
      followUpTasks: [],
    },
    otherPensions: [],
    progress: [],
    pensionPlan: {},
    optOutOffentligTjenestepensjon: undefined,
    optOutSmartAccount: undefined,
    optOutAfp: undefined,
    agreementsMetaDataMap: {},
    incomeSource: "USER",
    compressionLimitByEngagement: [],
    investmentProfileInPayoutPeriodByEngagement: [],
  },
  source: {
    id: "110749497",
    type: "CMID",
    role: "CUSTOMER",
    channel: "NYT",
  },
  lastUpdated: 1547488051679,
  cmid: "110749497",
  client: "NYT",
};

export const frozenSuppliedDataClientDataMock = freeze(suppliedDataClientDataMock, true);
export const frozenSuppliedDataClientDataMock2 = freeze(suppliedDataClientDataMock2, true);
export const frozenSuppliedDataMock = freeze(suppliedDataMock, true);
export const frozenSuppliedDataMock2 = freeze(suppliedDataMock2, true);
