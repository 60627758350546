import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";

export enum SimulationErrorCmsKey {
  GenericError = "prognosis.pensionlife.subsystemerror",
  AboveMaxAgeError = "simulationAgeHigherThanMaxAge",
  UnknownNavError = "unknown_nav_error",
  UnknownPublicError = "public_pension_unknown_simulation_error",
}

export function createGraphSimulationError(key: Nullable<SimulationErrorCmsKey>): Graph.SimulationStatus {
  return {
    severity: Graph.SimulationSeverity.SimulationError,
    messageKey: key || SimulationErrorCmsKey.GenericError,
  };
}

export function createGraphSimulationMessageError(key: SimulationErrorCmsKey): Graph.PublicPensionStatusMessage {
  return {
    messageType: Graph.SimulationSeverity.SimulationError,
    messageKey: key || SimulationErrorCmsKey.GenericError,
  };
}
