import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { formatISO, parseISO } from "date-fns";
import { produce } from "immer";
import { cloneDeep, isEqual, merge } from "lodash-es";
import {
  EMPTY,
  Observable,
  catchError,
  combineLatestWith,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  from,
  iif,
  map,
  of,
  switchMap,
  take,
  tap,
} from "rxjs";
import { clientId } from "src/app/constants/api.constants";
import { ConsentService } from "src/app/services/consent.service";
import { AbstractCustomerSuppliedDataService } from "src/app/services/customer-supplied-data/abstract-customer-supplied-data.service";
import { EndpointService, httpHeaderNoCache } from "src/app/services/endpoint.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { SessionStorageService, StorageKey } from "src/app/services/session-storage.service";
import { toArrayOrEmpty } from "src/app/utils/array";
import { handleError } from "src/app/utils/http";
import { Monitoring } from "src/app/utils/monitoring";
import { pruneProps } from "src/app/utils/object";
import { memoizeObject$, select, select$, selectObject$ } from "src/app/utils/rxjs/select";
import { BehaviorStore, ReplayStore } from "src/app/utils/rxjs/store";
import { Nullable } from "src/app/utils/utils";
import { InvestmentProfileInPayoutPeriod, PrognosisParametersInput } from "../api/savings-and-pension-queries.types";
import { SimulationParametersByEngagement } from "../common-parameters.service";
import { EngagementService } from "../engagement.service";
import { AnyEngagement } from "../engagements.service";
import {
  generateDefaultClientData,
  generateDefaultPrognosisParametersInputByEngagement,
  updateManySimulationParametersByEngagement,
} from "./client-data.utils";

@Injectable({
  providedIn: "root",
})
export class ClientDataService extends AbstractCustomerSuppliedDataService {
  public clientDataWrapper$: Observable<CustomerSuppliedData.ClientDataWrapper>;
  public startPayoutAge$: Observable<number | undefined>;
  public clientData$: Observable<CustomerSuppliedData.ClientData>;
  public simulationParameters$: Observable<CustomerSuppliedData.ClientData["simulationParameters"]>;
  public hasSimulated$: Observable<boolean>;
  public otherPensions$: Observable<CustomerSuppliedData.OtherPension[]>;
  public additionalInfo$: Observable<CustomerSuppliedData.AdditionalInfo>;
  public hasAdditionalInfo$: Observable<boolean>;
  public monthlyIncomeNet$: Observable<string>;
  public optOutOffentligTjenestepensjon$: Observable<Date | undefined>;
  public optOutAfp$: Observable<Date | undefined>;
  public optOutSmartAccount$: Observable<Date | undefined>;
  public otherMonthlyIncomeNet$: Observable<Nullable<string>>;
  public monthlySavings$: Observable<Nullable<string>>;
  /** @deprecated refers to a value set by the unused TotalSavingsFormField.
   * Use profileService's sumExternalSavingsBalance instead
   */
  public totalSavings$: Observable<string | undefined>;
  public isPayingWealthTax$: Observable<boolean>;
  public annualWealthTaxExpenses$: Observable<string>;
  public totalDebt$: Observable<Nullable<string>>;
  public futureMonthlyDebtCosts$: Observable<Nullable<string>>;
  public monthlyDebtCosts$: Observable<Nullable<string>>;
  public progress$: Observable<CustomerSuppliedData.Progress[]>;
  public pensionPlan$: Observable<CustomerSuppliedData.PensionPlanProgress | undefined>;
  public simulationParametersByEngagement$: Observable<
    CustomerSuppliedData.ClientData["simulationParametersByEngagement"]
  >;
  public simulationParametersByEngagementEnable$: Observable<{
    enable: boolean;
    switches: {
      userActivated: boolean;
      featureAllowed: boolean;
    };
  }>;
  public simulationParametersPartialWithdrawalEnable$: Observable<{
    enable: boolean;
    switches: {
      userActivated: boolean;
      featureAllowed: boolean;
    };
  }>;
  public agreementsMetaDataMap$: Observable<CustomerSuppliedData.AgreementsMetaDataMap>;
  public incomeSource$: Observable<CustomerSuppliedData.ClientData["incomeSource"]>;
  public compressionLimitByEngagement$: Observable<CustomerSuppliedData.CompressionLimitByEngagement>;
  public investmentProfileInPayoutPeriodByEngagement$: Observable<CustomerSuppliedData.InvestmentProfileInPayoutPeriodByEngagement>;
  public isSimulationByEngagementEnabled$: Observable<boolean>;

  private readonly _clientDataWrapper$: ReplayStore<CustomerSuppliedData.ClientDataWrapper>;
  private readonly _clientDataWriteActive$ = new BehaviorStore<boolean>(false);
  private readonly prognosisParametersByEngagement$: Observable<CustomerSuppliedData.PrognosisParametersByEngagement>;

  constructor(
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly keyCloakService: KeycloakService,
    private readonly consentService: ConsentService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly engagementService: EngagementService,
    @Optional()
    @Inject("clientDataWrapperInitData")
    clientDataWrapperInitData$?: typeof ClientDataService.prototype._clientDataWrapper$,
  ) {
    super();

    this._clientDataWrapper$ =
      clientDataWrapperInitData$ instanceof ReplayStore ? clientDataWrapperInitData$ : new ReplayStore();

    this.clientDataWrapper$ = memoizeObject$(this._clientDataWrapper$);

    this.clientData$ = selectObject$(this.clientDataWrapper$, (clientDataWrapper) => clientDataWrapper?.clientData);

    this.simulationParameters$ = selectObject$(this.clientData$, (clientData) => clientData?.simulationParameters);

    this.startPayoutAge$ = select$(
      this.simulationParameters$,
      (simulationParameters) => simulationParameters.startPayoutAge,
    );

    this.isSimulationByEngagementEnabled$ = select$(
      this.clientData$,
      ({ simulationParametersByEngagementEnable }) => simulationParametersByEngagementEnable,
    );

    this.simulationParametersByEngagementEnable$ = selectObject$(
      this.clientData$.pipe(map((data) => !!data?.simulationParametersByEngagementEnable)),
      (userActivated) => ({
        enable: userActivated,
        switches: {
          userActivated,
          featureAllowed: true,
        },
      }),
    );

    this.simulationParametersPartialWithdrawalEnable$ = selectObject$(
      this.clientData$.pipe(map((data) => !!data?.simulationParametersPartialWithdrawalEnable)),
      (userActivated) => ({
        enable: userActivated,
        switches: {
          userActivated,
          featureAllowed: true,
        },
      }),
    );

    const simulationParametersByEngagementStartPayoutAndDuration$: Observable<
      CustomerSuppliedData.SimulationParametersByEngagement<{}>[]
    > = this.clientData$.pipe(
      map((clientData) => toArrayOrEmpty(clientData.simulationParametersByEngagement)),
      map((simParamsByEngagements) =>
        simParamsByEngagements.map((simParamsByEngagement) => {
          const { startPayoutAge, durationYears } = simParamsByEngagement[1];
          return [
            simParamsByEngagement[0],
            {
              startPayoutAge,
              durationYears,
            },
          ];
        }),
      ),
    );

    this.simulationParametersByEngagement$ = this.simulationParametersByEngagementEnable$.pipe(
      map(({ enable }) => enable),
      switchMap((enable) => iif(() => enable, simulationParametersByEngagementStartPayoutAndDuration$, of([]))),
    );

    this.prognosisParametersByEngagement$ = this.clientData$.pipe(
      map(
        (clientData) =>
          clientData.prognosisParametersInputByEngagement ?? generateDefaultPrognosisParametersInputByEngagement(),
      ),
    );

    this.hasSimulated$ = select$(this.clientData$, ({ pensionNeeds }) => !!pensionNeeds?.hasSimulated);

    this.progress$ = selectObject$(this.clientData$, ({ progress }) => progress || []);

    this.additionalInfo$ = selectObject$(this.clientData$, (clientData) => clientData.additionalInfo);

    this.hasAdditionalInfo$ = select$(
      this.clientData$,
      (clientData) => clientData.hasBookedAdvisor || clientData.additionalInfo.hasBeenTouched,
    );

    this.optOutOffentligTjenestepensjon$ = selectObject$(this.clientData$, ({ optOutOffentligTjenestepensjon }) =>
      optOutOffentligTjenestepensjon ? parseISO(optOutOffentligTjenestepensjon) : undefined,
    );

    this.optOutSmartAccount$ = selectObject$(this.clientData$, ({ optOutSmartAccount }) =>
      optOutSmartAccount ? parseISO(optOutSmartAccount) : undefined,
    );

    this.optOutAfp$ = selectObject$(this.clientData$, ({ optOutAfp }) => (optOutAfp ? parseISO(optOutAfp) : undefined));

    this.otherPensions$ = selectObject$(this.clientData$, ({ otherPensions }) => otherPensions ?? []);

    this.monthlyIncomeNet$ = select$(this.clientData$, ({ monthlyIncomeNet }) => monthlyIncomeNet);

    this.otherMonthlyIncomeNet$ = select$(this.additionalInfo$, ({ otherMonthlyIncomeNet }) => otherMonthlyIncomeNet);

    this.monthlySavings$ = select$(this.additionalInfo$, ({ monthlySavings }) => monthlySavings);

    this.totalSavings$ = select$(this.additionalInfo$, ({ totalSavings }) => totalSavings);

    this.isPayingWealthTax$ = select$(this.additionalInfo$, ({ isPayingWealthTax }) => isPayingWealthTax);

    this.annualWealthTaxExpenses$ = select$(
      this.additionalInfo$,
      ({ annualWealthTaxExpenses }) => annualWealthTaxExpenses,
    );

    this.totalDebt$ = select$(this.additionalInfo$, ({ totalDebt }) => totalDebt);

    this.futureMonthlyDebtCosts$ = select$(
      this.additionalInfo$,
      ({ futureMonthlyDebtCosts }) => futureMonthlyDebtCosts,
    );

    this.monthlyDebtCosts$ = select$(this.additionalInfo$, ({ monthlyDebtCosts }) => monthlyDebtCosts);

    this.pensionPlan$ = selectObject$(this.clientData$, ({ pensionPlan }) => pensionPlan);

    this.agreementsMetaDataMap$ = selectObject$(this.clientData$, ({ agreementsMetaDataMap }) => agreementsMetaDataMap);

    this.incomeSource$ = selectObject$(this.clientData$, ({ incomeSource }) => incomeSource);

    this.consentService.lastTwoStorageConsents$
      .pipe(
        map((consents) => consents.top() === true && consents.next() === false),
        filter((consentWasGiven) => consentWasGiven),
        switchMap(() =>
          this.writeLocalToRemote(
            select(this._clientDataWrapper$),
            this._clientDataWriteActive$,
            this.patchClientDataWrapper.bind(this),
          ),
        ),
      )
      .subscribe();

    this.compressionLimitByEngagement$ = selectObject$(
      this.clientData$,
      ({ compressionLimitByEngagement }) => compressionLimitByEngagement,
    );

    this.investmentProfileInPayoutPeriodByEngagement$ = selectObject$(
      this.clientData$,
      ({ investmentProfileInPayoutPeriodByEngagement }) => investmentProfileInPayoutPeriodByEngagement,
    );
  }

  public updateClientData(partialClientData: Partial<CustomerSuppliedData.ClientData>): void {
    const clientDataPatch$ = this._clientDataWrapper$.pipe(
      take(1),
      map((clientDataWrapper) =>
        cloneDeep({
          ...(clientDataWrapper ?? {}),
          clientData: {
            ...select(this.clientData$),
            ...partialClientData,
          },
        }),
      ),
    );

    clientDataPatch$.pipe(switchMap((patch) => this.patchClientDataWrapper(patch))).subscribe({
      error: (err) =>
        Monitoring.warn("Failed to save client data", {
          extras: {
            err,
          },
        }),
    });
  }

  /**
   * Warning: Will store any params without cross-referencing other
   * sim params storage locations, like those tracked by ProfileService.
   * Not expected to be used directly.
   *
   * @see CommonParametersService.prototype.updateSimulationParametersByEngagement
   */
  public updateSimulationParametersByEngagement(...manyEngagementParams: SimulationParametersByEngagement[]): void {
    this.simulationParametersByEngagement$
      .pipe(
        first(),
        map((originalSimulationParametersByEngagement) =>
          updateManySimulationParametersByEngagement(manyEngagementParams, originalSimulationParametersByEngagement),
        ),
      )
      .subscribe((updatedSimulationParametersByEngagement) => {
        this.updateClientData({
          simulationParametersByEngagement: updatedSimulationParametersByEngagement,
        });
      });
  }

  public async updateCompressionLimitByEngagement(engagementKey: string, value: number): Promise<void> {
    const current = await firstValueFrom(this.compressionLimitByEngagement$);
    const filteredCurrent = current.filter(([key]) => key !== engagementKey);

    const update: [string, Pick<PrognosisParametersInput, "compressionLimit">] = [
      engagementKey,
      { compressionLimit: value },
    ];

    this.updateClientData({
      compressionLimitByEngagement: [...filteredCurrent, update],
    });
  }

  public async updateInvestmentProfileInPayoutPeriodByEngagement(
    engagementKey: string,
    value: InvestmentProfileInPayoutPeriod,
  ): Promise<void> {
    const current = await firstValueFrom(this.investmentProfileInPayoutPeriodByEngagement$);
    const filteredCurrent = current.filter(([key]) => key !== engagementKey);

    const update: [string, Pick<PrognosisParametersInput, "investmentProfileInPayoutPeriod">] = [
      engagementKey,
      { investmentProfileInPayoutPeriod: value },
    ];
    this.updateClientData({
      investmentProfileInPayoutPeriodByEngagement: [...filteredCurrent, update],
    });
  }

  public async getSimulationParameterByEngagementPatch(
    simulationParametersKey: string,
    simulationParameterPropertiesToPatch: Partial<CustomerSuppliedData.SimulationParameters>,
  ): Promise<Partial<CustomerSuppliedData.SimulationParameters>> {
    const allCurrentSimulationParameters = await firstValueFrom(
      this.clientData$.pipe(map((clientData) => toArrayOrEmpty(clientData.simulationParametersByEngagement))),
    );
    const currentSimParams = allCurrentSimulationParameters
      .find(([key]) => simulationParametersKey === key)
      ?.at(1) as Partial<CustomerSuppliedData.SimulationParameters>;

    return { ...currentSimParams, ...simulationParameterPropertiesToPatch };
  }

  public async updatePrognosisParametersIfChanged(
    newPrognosisParameters: CustomerSuppliedData.PrognosisParametersByEngagement,
  ): Promise<void> {
    const currentParameters = await firstValueFrom(this.prognosisParametersByEngagement$);
    const areParametersChanged = !isEqual(currentParameters, newPrognosisParameters);

    if (areParametersChanged) {
      this.updateClientData({ prognosisParametersInputByEngagement: newPrognosisParameters });
    }
  }

  public updateAdditionalInfo(partialAdditionalInfo: Partial<CustomerSuppliedData.AdditionalInfo>): void {
    this.updateClientData({
      additionalInfo: this.mergeAdditionalInfo(partialAdditionalInfo),
    });
  }

  public mergeAdditionalInfo(
    partialAdditionalInfo: Partial<CustomerSuppliedData.AdditionalInfo>,
  ): CustomerSuppliedData.AdditionalInfo {
    return {
      ...select(this.additionalInfo$),
      ...partialAdditionalInfo,
      hasBeenTouched: true,
    };
  }

  public updatePensionPlan(pensionPlan: Partial<CustomerSuppliedData.PensionPlanProgress>): void {
    return this.updateClientData({
      pensionPlan: {
        ...select(this.pensionPlan$),
        ...pruneProps(pensionPlan),
      },
    });
  }

  public async updateAgreementsMetaDataMap(engagement: AnyEngagement, isIncluded: boolean): Promise<void> {
    const id = engagement.getIdentifier();
    const dataMap = await firstValueFrom(this.agreementsMetaDataMap$);

    const agreementsMetaDataMap = {
      ...dataMap,
      ...{
        [id]: { isPension: isIncluded },
      },
    };

    return this.updateClientData({
      agreementsMetaDataMap,
    });
  }

  public updateOptOutOffentligTjenestepensjon(value: boolean): void {
    this.updateClientData({
      optOutOffentligTjenestepensjon: value ? formatISO(new Date()) : undefined,
    });
  }

  public updateOptOutSmartAccount(value: boolean): void {
    this.updateClientData({
      optOutSmartAccount: value ? formatISO(new Date()) : undefined,
    });
  }

  public updateOptOutAfp(value: boolean): void {
    this.updateClientData({
      optOutAfp: value ? formatISO(new Date()) : undefined,
    });
  }

  public updateIncomeSource(value: CustomerSuppliedData.ClientData["incomeSource"]): void {
    this.updateClientData({
      incomeSource: value,
    });
  }

  /**
   * Create new other pension.
   * Intended to be used only by OtherPensionEngagementService.
   * All other use should go via that service.
   *
   * @param otherPensionInput the other pension to create
   */
  public createOtherPension(
    otherPensionInput: Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension">,
  ): void {
    /**
     * Note the id is just Date.now(). This is fine while otherPension exist only
     * within client data, and are not compared to other users otherPension. If this
     * changes, the id generation algorithm must also change.
     */
    const id = Date.now().toString();
    const otherPension: CustomerSuppliedData.OtherPension = {
      ...otherPensionInput,
      id,
      includeInPension: true,
    };

    const otherPensions = select(this.otherPensions$) || [];

    this.engagementService.setEngagementInSessionStorage(Number(id));

    this.updateOtherPensions(otherPensions.concat([otherPension]));
  }

  /**
   * Patch other pension.
   * Intended to be used only by OtherPensionEngagementService.
   * All other use should go via that service.
   *
   * @param id the identifier associated with this other pension
   * @param newData the patch
   */
  public updateOtherPension(id: string, newData: Partial<CustomerSuppliedData.OtherPension>): void {
    const otherPensions = select(this.otherPensions$);
    const targetPensionIndex = otherPensions.findIndex((otherPension) => otherPension.id === id);

    if (targetPensionIndex < 0) {
      throw new Error(
        `Attempted to udpate other pension with id ${id}, but it does not exist in the list of otherPensions in client data service: ${otherPensions
          .map((otherPension) => otherPension.id)
          .join(",")}`,
      );
    }

    this.engagementService.setEngagementInSessionStorage(Number(id));

    const updatedOtherPension = {
      ...otherPensions[targetPensionIndex],
      ...pruneProps(newData),
    };

    const mutatedOtherPensions = produce(otherPensions, (draft) => {
      draft[targetPensionIndex] = updatedOtherPension;
    });

    this.updateOtherPensions(mutatedOtherPensions);
  }

  /**
   * Delete other pension.
   * Intended to be used only by OtherPensionEngagementService.
   * All other use should go via that service.
   *
   * @param inId the identifier associated with this other pension
   */
  public deleteOtherPension(inId: string): void {
    const otherPensions = select(this.otherPensions$);
    const indexToRemove = otherPensions.findIndex(({ id }) => id === inId);
    if (indexToRemove < 0) {
      throw new Error(
        `Attempted to remove other pension with id ${inId}, but it does not exist in the list of otherPensions in client data service: ${otherPensions
          .map((otherPension) => otherPension.id)
          .join(",")}`,
      );
    }
    otherPensions.splice(indexToRemove, 1);

    this.engagementService.removeEngagementFromSessionStorage(Number(inId));

    this.updateClientData({ otherPensions });
  }

  public fetchClientDataWrapper(): Observable<CustomerSuppliedData.ClientDataWrapper> {
    const clientDataFromSession$ = from(this.readClientDataWrapperFromSessionStorageOrDefault());

    const clientDataFromBackend$ = this.endpointService.composeCustomerSuppliedDataNytUrl().pipe(
      switchMap((url) => this.endpointService.httpGet$<CustomerSuppliedData.ClientDataWrapper>(url, httpHeaderNoCache)),
      combineLatestWith(from(this.generateDefaultClientDataWrapper())),
      map(([res, _default]) => merge({}, _default, res) as CustomerSuppliedData.ClientDataWrapper),
    );

    return this.consentService.customerSuppliedDataConsent$.pipe(
      take(1),
      switchMap((consent) =>
        consent
          ? this._clientDataWriteActive$.pipe(
              filter((isActive) => !isActive),
              switchMap(() => clientDataFromBackend$),
            )
          : clientDataFromSession$,
      ),
      catchError((err) =>
        handleError(
          "ClientDataService::fetchClientDataWrapper",
          err,
          from(this.readClientDataWrapperFromSessionStorageOrDefault()),
        ),
      ),
      tap((clientDataWrapper) => this.setClientDataWrapper(clientDataWrapper)),
    );
  }

  /**
   * Prefer using the public accessors, like set clientDataWrapper or set simulationParameters to
   * fire-and-forget clientData updates. The public access to this function serves
   * mainly to make it testable.
   */
  public patchClientDataWrapper(
    clientDataWrapper: CustomerSuppliedData.ClientDataWrapper,
  ): Observable<unknown | never> {
    const updateSessionStorage$ = new Observable((sub) => {
      this.setClientDataWrapper(clientDataWrapper);
      sub.next(); // Emit in order to proceed; the write returns void so emitting nothing is fine
      sub.complete(); // Our job is done, so we complete
    });

    const shouldWriteToRemote$ = forkJoin([
      this.consentService.customerSuppliedDataConsent$.pipe(take(1)),
      this.keyCloakService.advisorIncognitoMode$.pipe(take(1)),
    ]).pipe(
      map(
        ([customerSuppliedDataConsent, advisorIncognitoMode]) => customerSuppliedDataConsent && !advisorIncognitoMode,
      ),
    );

    return updateSessionStorage$.pipe(
      switchMap(() => shouldWriteToRemote$),
      switchMap((shouldWriteToRemote) =>
        shouldWriteToRemote
          ? this.endpointService.composeCustomerSuppliedDataNytUrl().pipe(
              switchMap((url) =>
                this.http.put(
                  url,
                  JSON.stringify({
                    channel: "NYT",
                    clientData: clientDataWrapper.clientData,
                  }),
                  {
                    headers: new HttpHeaders({
                      ClientId: clientId,
                      "Content-Type": "application/json",
                    }),
                  },
                ),
              ),
            )
          : EMPTY,
      ),
    );
  }

  private setClientDataWrapper(clientDataWrapper: CustomerSuppliedData.ClientDataWrapper): void {
    this.sessionStorageService.set(StorageKey.ClientData, clientDataWrapper);
    this._clientDataWrapper$.next(cloneDeep(clientDataWrapper));
  }

  private async readClientDataWrapperFromSessionStorageOrDefault(): Promise<CustomerSuppliedData.ClientDataWrapper> {
    return this.sessionStorageService.get(StorageKey.ClientData, this.generateDefaultClientDataWrapper());
  }

  private async generateDefaultClientDataWrapper(): Promise<CustomerSuppliedData.ClientDataWrapper> {
    return <CustomerSuppliedData.ClientDataWrapper>{
      clientData: generateDefaultClientData(),
    };
  }

  private updateOtherPensions(otherPensions: CustomerSuppliedData.OtherPension[]): void {
    this.updateClientData({
      otherPensions,
    });
  }
}
