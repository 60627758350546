import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

type Theme = "dark" | "light";

@Component({
  selector: "app-pin",
  templateUrl: "./pin.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinComponent {
  @Input()
  public toggle = false;

  public pin!: string;
  public pinned!: string;

  @Input()
  public set theme(theme: Theme) {
    switch (theme) {
      case "dark": {
        this.pin = "pinned";
        this.pinned = "pin";
        break;
      }
      case "light":
      default: {
        this.pin = "pin";
        this.pinned = "pinned";
      }
    }
  }
}
