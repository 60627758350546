import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { getIsNotNullable } from "src/app/utils/utils";

import {
  InitiativeEngagementItem,
  PresentableEngagementItem,
  getPresentableEngagementItems,
} from "./engagement-list-initiatives.utils";

@Component({
  selector: "app-engagement-list-initiatives",
  templateUrl: "./engagement-list-initiatives.component.html",
  styleUrls: ["./engagement-list-initiatives.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementListInitiativesComponent {
  @Input()
  public headers?: (string | null)[];

  @Input()
  public items?: InitiativeEngagementItem[];

  @Input()
  public itemsCutoff: number = 2;

  @Input()
  public isNumberedList: boolean = false;

  public shouldShowAllItems: boolean = false;

  public getItems(): InitiativeEngagementItem[] {
    return this.items ?? [];
  }

  public getHeaders(): string[] {
    return this.headers?.filter(getIsNotNullable) ?? [];
  }

  public getVisibleItems(): PresentableEngagementItem[] {
    const presentableItems = getPresentableEngagementItems(this.getItems(), this.getHeaders());

    return this.shouldShowAllItems ? presentableItems : presentableItems.slice(0, this.itemsCutoff + 1);
  }

  public getHasEngagements(): boolean {
    return this.getItems().length > 0;
  }

  public getHasMoreThanCutoffItems(): boolean {
    return this.getItems().length > this.itemsCutoff;
  }

  public getShouldShowAllButton(): boolean {
    return !this.shouldShowAllItems && this.getHasMoreThanCutoffItems();
  }

  public getShouldShowLessButton(): boolean {
    return this.shouldShowAllItems && this.getHasMoreThanCutoffItems();
  }

  public toggleShouldShowAllItems(): void {
    this.shouldShowAllItems = !this.shouldShowAllItems;
  }
}
