import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Action, ActionComponent } from "src/app/modules/features/pension-actions/components/actions/actions.component";
import { SavingsAdvisorService } from "src/app/services/savings-advisor.service";
import { routes as savingForPensionRoutes } from "../../../../saving-for-pension/routes";

@Component({
  selector: "app-action-savings-advisor",
  templateUrl: "./action-savings-advisor.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSavingsAdvisorComponent implements ActionComponent {
  @Input()
  public action!: Action;

  constructor(
    private readonly actionSavingsAdvisorService: SavingsAdvisorService,
    private readonly router: Router,
  ) {}

  public async routerLinkToSavingsPage(): Promise<boolean> {
    return this.router.navigate(["/", savingForPensionRoutes.Root], {
      queryParamsHandling: "merge",
    });
  }

  public getIsVisible(): Observable<boolean> {
    return this.actionSavingsAdvisorService.isEligibleForSavingsAdvisor();
  }
}
