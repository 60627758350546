<div
  [ngClass]="{ 'wrapper stb-typography': showDescription || showPensionPlanEntry }"
  *ngIf="description$ | async; let description"
>
  <div *ngIf="showDescription" class="paragraph-long-bold">
    {{ "contractTypeDescription.title" | fms: { args: { contractName: contractName | async } } }}
  </div>

  <p *ngIf="showDescription || showPensionPlanEntry">
    <span *ngIf="showDescription" [innerHtml]="description"></span>
    <span *ngIf="showPensionPlanEntry" class="description-extended">
      {{ "ytelsespensjon.description.extended" | fms }}
    </span>
  </p>

  <div *ngIf="showPensionPlanEntry" class="description-action-container">
    <a class="anchor-stbButton-wrapper" [routerLink]="pensionPlanRoute" queryParamsHandling="merge" tabIndex="-1">
      <button role="link" stbButton="text" [setIconAfter]="true" iconType="arrow-right">
        {{ "ytelsespensjon.description.action.label" | fms }}
      </button>
    </a>
  </div>
</div>
