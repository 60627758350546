import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

const GET_STATIC_PUBLIC_PENSION_PROGNOSIS_PARAMETERS_QUERY = gql`
  query getPublicPensionPrognosisParametersQuery {
    publicPensionPrognosisParameters {
      addedSalary
      currentWorkingPercentage
      earnedPensionRight
      hasPublicPensionEngagements
      mainSalary
      maximumAgeWithdrawal
      minimumAgeWithdrawal
      retireAge
      showAddedYears
      statusMessages {
        messageKey
        messageText
        messageType
      }
    }
  }
`;

export type StaticPublicPensionPrognosisParameters = Omit<
  Graph.PublicPensionPrognosisParameters,
  "canChangeWorkingPercentage" | "canChangeWithdrawalPercentage" | "canChangeFutureSalary" | "ofaVendors"
>;

export type GetPublicPensionPrognosisParametersQuery = Pick<Graph.Query, "publicPensionPrognosisParameters">;

@Injectable({
  providedIn: "root",
})
export class PublicPensionPrognosisParametersStaticService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getHasEarnedPensionRight(): Observable<boolean> {
    return this.getStaticPensionPrognosisParameters().pipe(
      map((parameters) => parameters.earnedPensionRight ?? false),
      catchError(() => of(false)),
    );
  }

  public getStaticPensionPrognosisParameters(): Observable<StaticPublicPensionPrognosisParameters> {
    return this.savingsGraphqlClient
      .query<GetPublicPensionPrognosisParametersQuery>({
        query: GET_STATIC_PUBLIC_PENSION_PROGNOSIS_PARAMETERS_QUERY,
      })
      .pipe(mergeMap(getApolloResponseData), map(getPublicPensionPrognosisParametersFromResponse));
  }
}

export function getPublicPensionPrognosisParametersFromResponse(
  query: GetPublicPensionPrognosisParametersQuery,
): Graph.PublicPensionPrognosisParameters {
  return query?.publicPensionPrognosisParameters ?? {};
}
