import { Directive, Input } from "@angular/core";
import { Observable } from "rxjs";

/**
 * As of Angular 9, undecorated parent component classes are no longer allowed.
 *
 * The @Component decorator requires an object which makes no sense for
 * abstract classes. An empty @Directive decorator works the same way.
 *
 * @see https://angular.io/guide/migration-undecorated-classes
 */
@Directive()
export abstract class AbstractSkeletonComponent {
  @Input()
  renderTrigger$!: Observable<boolean | number>;

  public isTruthy(value: boolean | number): boolean {
    return value != null && value !== false;
  }
}
