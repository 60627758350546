<app-engagement-list-initiatives-item-header *ngIf="!!item.header" [item]="item" [isNumberedList]="isNumberedList">
</app-engagement-list-initiatives-item-header>

<div class="item-content">
  <app-engagement-list-initiatives-item-number
    *ngIf="isNumberedList"
    class="item-content-number"
    [number]="item.number"
    [hideNumber]="item.hideNumber"
  >
  </app-engagement-list-initiatives-item-number>
  <app-engagement-list-initiatives-item-engagement
    class="item-content-engagement"
    [class.numbered-item]="isNumberedList"
    [title]="item.title"
    [description]="item.description"
    [iconSrc]="item.iconSrc"
    [fields]="item.fields"
  >
  </app-engagement-list-initiatives-item-engagement>
</div>
