import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { EngagementBody } from "src/app/modules/pension-plan/components/engagement/engagement-body/engagement-body.component";
import { EngagementService } from "src/app/services/engagement.service";
import { FmsService } from "src/app/services/fms.service";

@Component({
  selector: "app-engagement-details-other",
  templateUrl: "./engagement-details-other.component.html",
  styleUrls: ["./engagement-details-other.component.scss", "../engagement-details-shared.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsOtherComponent implements EngagementBody {
  @Input()
  public engagement!: OtherPensionEngagement;
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;
  @Input()
  public showDescription = true;

  constructor(
    private readonly engagementService: EngagementService,
    private readonly fmsService: FmsService,
  ) {}

  public getTotalPayout(): number | null {
    return this.engagement.getTotalPayout();
  }

  public getDuration(): string {
    return this.engagementService.getHumanReadableDuration(this.engagement);
  }

  public getPeriodLabel(period: CustomerSuppliedData.OtherPensionPeriod): string {
    return this.fmsService.instant("common.fromToYear", {
      args: {
        startAge: period.fromAge,
        endAge: period.fromAge + period.duration - 1,
      },
    });
  }
}
