export const Primitives = ["string", "number", "boolean"];
export type Primitive = string | number | boolean;
export type MappingFunction<T, R> = (mappable: T) => R;
export type ValuesOf<T> = T[keyof T];
export type NotNull<T> = T extends null ? never : T;
export type NotNullProps<T> = {
  [P in keyof T]: NotNull<T[P]>;
};

export type InterfaceOf<T> = Pick<T, keyof T>;
export type EmptyObject = Record<string, never>;
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
