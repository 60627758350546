import { Component, Input } from "@angular/core";
import { NavigateToService } from "src/app/services/navigate-to.service";

@Component({
  selector: "app-plan-navigation-large-card",
  templateUrl: "./plan-navigation-large-card.component.html",
  styleUrls: ["./plan-navigation-large-card.component.scss"],
})
export class PlanNavigationLargeCardComponent {
  @Input()
  public title!: string;
  @Input()
  public illustration!: string;
  @Input()
  public buttonText!: string;
  @Input()
  public url!: string;
  @Input()
  public trackId!: string;
  @Input()
  public splitTitle: boolean = false;

  constructor(private readonly navigateToService: NavigateToService) {}

  public cardClicked(routerLink: string): void {
    if (getIsAbsoluteUrl(routerLink)) {
      window.open(routerLink, "_blank");
    } else {
      this.navigateToService.navigateWithExitUrl(routerLink);
    }
  }
}

function getIsAbsoluteUrl(url: string): boolean {
  return /^https?:\/\//.test(url);
}
