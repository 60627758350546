import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-expand-toggle-button",
  templateUrl: "./expand-toggle-button.component.html",
  styleUrls: ["./expand-toggle-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandToggleButtonComponent {
  @Input()
  public isExpanded!: boolean;

  @Output()
  public toggleClick = new EventEmitter<boolean>();

  public getButtonText(): string {
    return `toggleContentButton.buttonText.${this.isExpanded ? "showLess" : "showMore"}`;
  }

  public onClick(): void {
    this.isExpanded = !this.isExpanded;
    this.toggleClick.emit(!this.isExpanded);
  }
}
