import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, combineLatestWith, map, switchMap, take } from "rxjs/operators";
import {
  ExternalSavingsEngagement,
  ExternalSavingsEngagementType,
} from "src/app/models/engagements/external-savings-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { CustomerService } from "src/app/services/customer.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
  toFetchPrognosisResult,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { toArrayOrEmpty } from "src/app/utils/array";
import { handleError } from "src/app/utils/http";
import { getIsFiniteNumber } from "src/app/utils/number";
import { MissingExternalSavingsPrognosisError } from "src/app/utils/prognosis-errors";
import { Nullable } from "src/app/utils/utils";
import { DEFAULT_EXTERNAL_SAVINGS_PAYOUT_DURATION, DEFAULT_SIMULATION_AGE } from "../../constants/business.constants";
import { PrognosisQueriesService } from "../api/prognosis-queries.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";

export interface ExternalSavingsPrognosisParams {
  birthYear: number | undefined;
  gender: string | undefined;
}

type FetchPrognosesParams = CustomerSuppliedData.SimulationParametersByEngagement<ExternalSavingsPrognosisParams>[1];

const EXTERNAL_CONFIG: ServiceConfig = {
  name: "ExternalEngagementsService",
  fmsKey: "externalContracts",
};

@Injectable({
  providedIn: "root",
})
export class ExternalEngagementsService extends AbstractPrognosisFetchService<
  ExternalSavingsEngagement,
  ExternalSavingsPrognosisParams
> {
  private readonly startPayoutAge$ = this.clientDataService.startPayoutAge$.pipe(
    map((age) => age ?? DEFAULT_SIMULATION_AGE),
  );

  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly customerService: CustomerService,
    private readonly prognosisQueriesService: PrognosisQueriesService,
    private readonly profileService: ProfileService,
    private readonly clientDataService: ClientDataService,
  ) {
    super(EXTERNAL_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);
    this.profileService.externalSavings$.pipe(switchMap(() => this.fetchEngagements())).subscribe();
  }

  public fetchPrognosis(
    engagement: ExternalSavingsEngagement,
    params: FetchPrognosesParams,
  ): Observable<FetchPrognosisResult<ExternalSavingsEngagement>> {
    const source = "ExternalEngagementsService::fetchSinglePrognosis";

    return this.clientDataService.isSimulationByEngagementEnabled$.pipe(take(1)).pipe(
      switchMap((isCustomPayoutAgeActivated) => {
        const startPayoutAgeParam = isCustomPayoutAgeActivated ? engagement.contract.fromAge : params.startPayoutAge;

        const request$ =
          engagement.getType() === ExternalSavingsEngagementType.Fond
            ? this.prognosisQueriesService.getOpenPrognosisFund(
                createQueryVariablesFund(engagement, params, startPayoutAgeParam),
              )
            : this.prognosisQueriesService.getOpenPrognosisBank(
                createQueryVariablesBank(engagement, params, startPayoutAgeParam),
              );

        return request$.pipe(
          map((prognosis) => toFetchPrognosisResult(prognosis)),
          catchError((error) => handleError(source, error, of({ prognoses: [] }))),
        );
      }),
    );
  }

  protected _fetchEngagements(): Observable<ExternalSavingsEngagement[]> {
    return this.profileService.externalSavings$.pipe(
      take(1),
      map(toArrayOrEmpty),
      map((contracts) => contracts.map((contract) => new ExternalSavingsEngagement(contract))),
    );
  }

  protected prognosisSimParams$(): Observable<FetchPrognosesParams> {
    return this.startPayoutAge$.pipe(
      combineLatestWith(this.customerService.birthYear$, this.customerService.gender$),
      map(([startPayoutAge, birthYear, gender]) => {
        return {
          startPayoutAge,
          birthYear,
          gender,
        };
      }),
    );
  }

  protected composeMissingPrognosisError(engagement: ExternalSavingsEngagement): MissingExternalSavingsPrognosisError {
    return new MissingExternalSavingsPrognosisError(engagement);
  }
}

function createQueryVariablesBank(
  engagement: ExternalSavingsEngagement,

  { birthYear, gender }: Partial<CustomerSuppliedData.SimulationParameters> & ExternalSavingsPrognosisParams,
  startPayoutAgeParam: number = engagement.contract.fromAge,
): Graph.BankOpenPrognosisInput {
  const { periodicDeposit } = engagement.contract;
  const { years } = engagement.getPayoutDuration();

  return {
    balance: engagement.getBalance(),
    birthYear,
    gender,
    monthsOffset: 0,
    payoutDurationYears: years || DEFAULT_EXTERNAL_SAVINGS_PAYOUT_DURATION,
    periodicDeposit: ensureNumber(periodicDeposit),
    startPayoutAge: ensureNumber(startPayoutAgeParam),
  };
}

function createQueryVariablesFund(
  externalSaving: ExternalSavingsEngagement,
  params: Partial<CustomerSuppliedData.SimulationParameters> & ExternalSavingsPrognosisParams,
  startPayoutAgeParam: number = externalSaving.contract.fromAge,
): Graph.FundOpenPrognosisInput {
  const { balance, ...rest } = createQueryVariablesBank(externalSaving, params, startPayoutAgeParam);
  return {
    ...rest,
    marketValue: balance,
  };
}

function ensureNumber(value: Nullable<number>): number {
  return Math.max(0, Math.floor(getIsFiniteNumber(value) ? value : 0));
}
