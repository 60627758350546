import { ChangeDetectionStrategy, Component } from "@angular/core";
import Highcharts, { Options, SeriesArearangeOptions } from "highcharts";
import { cloneDeep } from "lodash-es";
import { combineLatest, Observable } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { ChartService, generateXAxisCategoryOption, NumberedSeriesColumnOptions } from "src/app/services/chart.service";
import { CustomerService } from "src/app/services/customer.service";
import { FmsService } from "src/app/services/fms.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { memoizeObject$ } from "src/app/utils/rxjs/select";
import { AbstractChartContainer } from "../abstract-chart-container";
import {
  backgroundArearangeOptions,
  foregroundArearangeOptions,
  getOptions,
  needsAreaRangePointStart,
  payoutSeriesPointStart,
} from "./ranged-needs-chart.options";

type PayoutSeries = NumberedSeriesColumnOptions;
type RangedNeedsSeries = SeriesArearangeOptions;
type Series = PayoutSeries | RangedNeedsSeries;

const PAYOUT_SERIES_NAME = "pensionNeeds.graph.pensionTotalSeriesName";
const NEEDS_SERIES_NAME = "pensionNeeds.graph.pensionNeedsSeriesName";

interface CustomArearangeData extends PayoutPlan.SeriesArearangeData {
  marker?: { enabled: boolean };
}

enum SeriesStyle {
  Foreground,
  Background,
}

@Component({
  selector: "app-ranged-needs-chart",
  templateUrl: "./ranged-needs-chart.component.html",
  styleUrls: ["./ranged-needs-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangedNeedsChartComponent extends AbstractChartContainer {
  public readonly options$: Observable<Options>;
  public readonly series$: Observable<Series[]>;

  constructor(
    private readonly chartService: ChartService,
    private readonly fmsService: FmsService,
    private readonly customerService: CustomerService,
    public readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
  ) {
    super();

    this.series$ = this.getSeries$();

    this.options$ = combineLatest([
      this.chartService.xAxisActivePayoutsRange$,
      this.customerService.age$,
      this.chartService.chartTranslations$,
    ]).pipe(
      map(([xAxisRange, customerAge, translations]) =>
        getOptions(generateXAxisCategoryOption(xAxisRange, customerAge, payoutSeriesPointStart), translations),
      ),
    );
  }

  public highchartsInitialized(chartInstance: Highcharts.Chart): void {
    super.highchartsInitialized(chartInstance);
  }

  private getPayoutSeries$(): Observable<NumberedSeriesColumnOptions> {
    return this.chartService.payoutColumnSeries$.pipe(
      map((payoutColumnSeries) => ({
        pointStart: payoutSeriesPointStart,
        type: payoutColumnSeries.type,
        data: payoutColumnSeries.data,
        name: this.fmsService.instant(PAYOUT_SERIES_NAME),
        index: 0,
      })),
    );
  }

  private getRangedNeedsSeries$(style: SeriesStyle): Observable<SeriesArearangeOptions> {
    return memoizeObject$(
      combineLatest([this.chartService.needsArearangeSeries$, this.fmsService.translateAsync(NEEDS_SERIES_NAME)]).pipe(
        map(([needsRangeAreaSeries, name]) => {
          let data: CustomArearangeData[] = needsRangeAreaSeries.data as PayoutPlan.SeriesArearangeData[];

          // eslint-disable-next-line fp/no-mutation
          data = extrudeSeriesInBackwardsDirection(data, payoutSeriesPointStart - needsAreaRangePointStart);

          // eslint-disable-next-line fp/no-mutation
          data = addMarkerFirstIndex(data);

          return <SeriesArearangeOptions>{
            ...(style === SeriesStyle.Foreground ? foregroundArearangeOptions : backgroundArearangeOptions),
            pointStart: needsAreaRangePointStart,
            type: needsRangeAreaSeries.type,
            data,
            name,
          };
        }),
      ),
    );
  }

  private getSeries$(): Observable<Series[]> {
    return this.getPayoutSeries$().pipe(
      combineLatestWith(
        this.getRangedNeedsSeries$(SeriesStyle.Foreground),
        this.getRangedNeedsSeries$(SeriesStyle.Background), //Background to be included after it's 'linkedTo' series
        this.chartService.annualGrossIncomeSeries$,
      ),
      this.fetchPrognosesRunningJobsService.waitForCurrentYearLoadedPipe(),
    );
  }
}

function addMarkerFirstIndex(data: PayoutPlan.SeriesArearangeData[]): CustomArearangeData[] {
  const dataWithMarker: CustomArearangeData[] = cloneDeep(data);
  // eslint-disable-next-line fp/no-mutation
  dataWithMarker[0] = {
    ...dataWithMarker[0],
    marker: {
      enabled: true,
    },
  };
  return dataWithMarker;
}

function extrudeSeriesInBackwardsDirection(
  data: PayoutPlan.SeriesArearangeData[],
  nTimes: number,
): PayoutPlan.SeriesArearangeData[] {
  return [...Array(nTimes).fill(data[0]), ...data];
}
