import { Observable } from "rxjs";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type Contract = Graph.FmiContract;

export class FmiEngagement extends SavingsAndPensionEngagement<Contract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName(this.contract);
  }

  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync(this.contract);
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.customerContractReference;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.contractNumber;
  }

  public getAllocationCode(): Nullable<string> {
    return this.contract?.allocationCode;
  }

  public isSavingsEngagement(): boolean {
    return false;
  }

  public isActive(): boolean {
    return false;
  }

  public hasChangeableProfile(): boolean {
    return true;
  }

  public getCompressionLimits(): number[] {
    return this.contract.compressionLimits;
  }

  public getSelectableInvestmentProfileInPayoutPeriod(): Graph.InvestmentProfileInPayoutPeriod[] {
    const selectableInvestment = this.contract.selectableInvestmentProfileInPayoutPeriod;

    if (selectableInvestment && selectableInvestment.length > 0) {
      return selectableInvestment as Graph.InvestmentProfileInPayoutPeriod[];
    } else {
      return [];
    }
  }
}
