@if (isReady && (hasError$ | async)) {
  @if (inputError && (hasAgeInputError$ | async)) {
    <div class="warning input-error">
      <i class="warning-icon">warning-filled</i>
      <span class="hint paragraph-long-bold">{{
        "partialWithdrawalWarning.partialWithdrawalBeforeFullWithdrawal.description" | fms
      }}</span>
    </div>
  } @else if (!inputError && !(hasAgeInputError$ | async) && (hasPartialWithdrawalError$ | async)) {
    <div class="warning">
      <i class="warning-icon">warning-filled</i>
      <span class="hint paragraph-long-bold">{{ "partialWithdrawalWarning.generic.description" | fms }}</span>
    </div>
  }
}
