<h4>{{ radio.title | fms }}</h4>
<p>
  {{ radio.description | fms }}
</p>

<stb-radio-buttons
  [name]="radio.title"
  [group]="radio.radioButtons"
  [warning]="(radio.formControl.statusChanges | async) === 'INVALID'"
  [message]="radio.errorMessage | fms"
  (valueChanged)="setControl(radio.formControl, $event)"
  [horizontal]="true"
></stb-radio-buttons>
