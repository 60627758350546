import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, combineLatest, filter, firstValueFrom, map, mergeMap } from "rxjs";
import { SteppableModalStep } from "src/app/modules/shared/components/steppable-modal/dynamic-step-navigation.service";
import { ContractSalaryService } from "src/app/services/api/contract-salary.service";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { DispatcherService, Signal } from "src/app/services/dispatcher.service";
import { FmsService } from "src/app/services/fms.service";
import { getIsNotNullable } from "src/app/utils/utils";
import { ClientDataService } from "../../../../../services/customer-supplied-data/client-data.service";
import { CurrencyFormatterPipe } from "../../../pipes/currency-formatter.pipe";
import { SalaryRadioFormField } from "../../form-fields/salary-provider-radio-form-field/salary-provider-radio-form-field.component";

export interface SalaryForm {
  salaryRadio?: FormControl<SalaryRadioFormField>;
  yearlyIncomeGross?: FormControl<number | undefined>;
}

@Component({
  selector: "app-onboarding-salary-step",
  templateUrl: "./onboarding-salary-step.component.html",
  styleUrls: ["./onboarding-salary-step.component.scss", "../onboarding-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSalaryStepComponent implements SteppableModalStep {
  public readonly showYearlyIncomeGrossFormField$: Observable<boolean>;
  public readonly hasEmployerSalary$: Observable<boolean>;
  public readonly employerDesciption$: Observable<string>;

  public form: FormGroup<SalaryForm>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly profileService: ProfileService,
    private readonly fmsService: FmsService,
    private readonly contractSalaryService: ContractSalaryService,
    private readonly currencyFormatterPipe: CurrencyFormatterPipe,
    private readonly commonParametersService: CommonParametersService,
    private readonly dispatcherService: DispatcherService,
    private readonly clientDataService: ClientDataService,
  ) {
    this.form = this.formBuilder.group({});

    this.hasEmployerSalary$ = this.contractSalaryService.getSalary().pipe(map(getIsNotNullable));
    this.employerDesciption$ = this.contractSalaryService.getSalary().pipe(
      filter((salary) => getIsNotNullable(salary)),
      map((salary) => this.currencyFormatterPipe.transform(salary, "end")),
      mergeMap((formattedEmployerSalary) =>
        this.fmsService.translateAsync("onboardingSalaryStep.description.hasEmployer.html", {
          args: { formattedEmployerSalary },
        }),
      ),
    );

    this.showYearlyIncomeGrossFormField$ = combineLatest([this.hasEmployerSalary$, this.form.valueChanges]).pipe(
      map(([hasEmployerSalary, formValue]) => {
        const mustProvideSalary = !hasEmployerSalary;
        const wantUserGivenSalary = formValue.salaryRadio?.selectedRadioSource === "USER";

        return mustProvideSalary || wantUserGivenSalary;
      }),
    );
  }

  public onEnter(): void {
    this.dispatcherService.dispatch(Signal.SteppableModalTriggerNext, OnboardingSalaryStepComponent.name);
  }

  public beforeNext(): boolean {
    this.form.controls.yearlyIncomeGross?.markAsTouched();
    this.form.controls.yearlyIncomeGross?.updateValueAndValidity();

    const wantsEmployerSalary = this.form.controls.salaryRadio?.value.selectedRadioSource === "EMPLOYER";

    return wantsEmployerSalary || !!this.form.controls.yearlyIncomeGross?.valid;
  }

  public async next(): Promise<void> {
    const currentUserGivenIncome = await firstValueFrom(this.commonParametersService.annualGrossIncome$);

    const salaryRadio = this.form.controls.salaryRadio?.value;
    if (salaryRadio) {
      const { selectedRadioSource: radioValue } = salaryRadio;
      this.clientDataService.updateIncomeSource(radioValue);

      if (radioValue === "EMPLOYER") {
        return;
      }
    }

    const formIncome = this.form.controls.yearlyIncomeGross?.value;
    const formFieldIncomeIsChanged = getIsNotNullable(formIncome) && formIncome !== currentUserGivenIncome;
    if (formFieldIncomeIsChanged) {
      this.profileService.setProfileSalaryYearGross(formIncome);
    }
  }

  public getTitle(): Observable<string> {
    return this.fmsService.translateAsync("onboardingSalaryStep.title");
  }
}
