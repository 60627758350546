<button mat-dialog-close data-trackid="combinedNewEngagementDialog-closeButton">
  <i>close</i>
</button>
<h3 mat-dialog-title>
  {{ "combinedNewEngagementDialog.title" | fms }}
</h3>

<mat-dialog-content>
  <stb-radio-buttons
    name="storageRadio"
    [legend]="'combinedNewEngagementDialog.radio.legend' | fms"
    [group]="radioButtons$ | async"
    [message]="'combinedNewEngagementDialog.radio.error' | fms"
    [warning]="(formControl.statusChanges | async) !== null && formControl.touched && formControl.hasError('required')"
    (valueChanged)="setFormControl($event)"
  >
  </stb-radio-buttons>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-dialog-close stbButton="outlined" type="button" data-trackid="combinedNewEngagementDialog-backButton">
    {{ "common.cancel" | fms }}
  </button>
  <button
    stbButton="contained"
    type="button"
    (click)="onNextButton(formControl.value)"
    [attr.data-trackid]="'combinedNewEngagementDialog-nextButton-' + formControl.value"
  >
    {{ "combinedNewEngagementDialog.nextButton.text" | fms }}
  </button>
</mat-dialog-actions>
