import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { Observable, combineLatest, filter, firstValueFrom } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { MAX_LIFE_EXPECTANCY_AGE, OFFENTLIG_TJENESTEPENSJON_MAXIMUM_AGE } from "src/app/constants/business.constants";
import { nonZeroCurrencyRegex } from "src/app/constants/regex.constants";
import { OtherPensionEngagement } from "src/app/models/engagements/other-pension-engagement.model";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { FmsService, replace } from "src/app/services/fms.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { NorskpensjonService } from "src/app/services/prognoses-services/norskpensjon.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { select } from "src/app/utils/rxjs/select";
import { AbstractNewEngagementStepperComponent, AddEngagementStep } from "../abstract-new-engagement-stepper.component";

@Component({
  selector: "app-offentlig-tjenestepensjon-engagement",
  templateUrl: "./offentlig-tjenestepensjon-engagement.component.html",
  styleUrls: ["./offentlig-tjenestepensjon-engagement.component.scss", "../add-engagement.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffentligTjenestepensjonEngagementComponent extends AbstractNewEngagementStepperComponent<OffentligTjenestepensjonEngagementComponent> {
  public offentligEngagementForm: UntypedFormGroup;
  public hasEnoughTimeInPublicSectorRadioButtons$: Observable<RadioButton[]>;
  public hasEnoughTimeInPublicSector: AbstractControl;
  public removeSuggestionToAdd: AbstractControl;
  public annualAmount: AbstractControl;
  public fromAge: AbstractControl;
  public title: AbstractControl;
  public payer: AbstractControl;
  public ageRange$: Observable<number[]>;

  private readonly engagementStepsControls: Pick<AddEngagementStep, "controlToValidate">[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public injectedEngagement: OtherPensionEngagement,
    protected readonly dialogRef: MatDialogRef<OffentligTjenestepensjonEngagementComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly fmsService: FmsService,
    private readonly clientDataService: ClientDataService,
    private readonly pensionPlanService: PensionPlanService,
    private readonly norskpensjonService: NorskpensjonService,
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    super(dialogRef);

    this.offentligEngagementForm = this.fb.group({
      firstGroup: this.fb.group({
        hasEnoughTimeInPublicSector: [null, [Validators.required]],
        removeSuggestionToAdd: [
          select(this.clientDataService.optOutOffentligTjenestepensjon$.pipe(map((date) => date != null))),
        ],
      }),
      secondGroup: this.fb.group({
        annualAmount: [null, [Validators.required, Validators.pattern(nonZeroCurrencyRegex)]],
        fromAge: [null, [Validators.required]],
      }),
      thirdGroup: this.fb.group({
        title: [null, [Validators.required]],
        payer: [null, [Validators.required]],
      }),
    });

    this.hasEnoughTimeInPublicSector = (
      this.offentligEngagementForm.controls.firstGroup as UntypedFormGroup
    ).controls.hasEnoughTimeInPublicSector;
    this.removeSuggestionToAdd = (
      this.offentligEngagementForm.controls.firstGroup as UntypedFormGroup
    ).controls.removeSuggestionToAdd;

    this.annualAmount = (this.offentligEngagementForm.controls.secondGroup as UntypedFormGroup).controls.annualAmount;
    this.fromAge = (this.offentligEngagementForm.controls.secondGroup as UntypedFormGroup).controls.fromAge;

    this.title = (this.offentligEngagementForm.controls.thirdGroup as UntypedFormGroup).controls.title;
    this.payer = (this.offentligEngagementForm.controls.thirdGroup as UntypedFormGroup).controls.payer;

    this.engagementStepsControls = [
      { controlToValidate: undefined },
      { controlToValidate: this.offentligEngagementForm.controls.firstGroup },
      {
        controlToValidate: this.offentligEngagementForm.controls.secondGroup,
      },
      { controlToValidate: this.offentligEngagementForm.controls.thirdGroup },
      { controlToValidate: undefined },
    ];

    this.engagementSteps = this.fmsService
      .instant<Omit<AddEngagementStep, "controlToValidate">[]>("offentligTjenestepensjonEngagement.fmsEngagementSteps")
      .map((fmsSteps, i) => ({
        ...fmsSteps,
        ...this.engagementStepsControls[i],
      }));

    this.hasEnoughTimeInPublicSectorRadioButtons$ = combineLatest([
      this.fmsService.translateAsync<string>("offentligTjenestepensjonEngagement.form.hasEnoughTimeInPublicSector.no"),
      this.fmsService.translateAsync<string>("offentligTjenestepensjonEngagement.form.hasEnoughTimeInPublicSector.yes"),
    ]).pipe(
      map(([noText, yesText]) => [
        {
          label: noText,
          value: "false",
          disabled: false,
          checked: false,
        },
        {
          label: yesText,
          value: "true",
          disabled: false,
          checked: this.hasInjectedEngagement(),
        },
      ]),
    );

    this.ageRange$ = this.startPayoutAgeService
      .getStartPayoutAgeRange()
      .pipe(map((range) => range.filter((number) => number <= OFFENTLIG_TJENESTEPENSJON_MAXIMUM_AGE)));

    this.prefillFormFields();
  }

  public hasInjectedEngagement(): boolean {
    return !!this.injectedEngagement;
  }

  public getProviderName(): Observable<string | undefined> {
    return this.norskpensjonService.getProviderNameFromPublicPension();
  }

  public getWindowTitle(): Observable<string> {
    return this.stepper$.pipe(
      map((stepper) => this.engagementSteps[stepper]),
      withLatestFrom(this.getProviderName()),
      map(([{ windowTitle, windowTitleWithName }, providerName]) =>
        replace(windowTitleWithName && providerName ? windowTitleWithName : windowTitle, {
          name: providerName,
        }),
      ),
    );
  }

  public getIntroText(): Observable<string> {
    return this.getProviderName().pipe(
      map((providerName) =>
        this.fmsService.instant(
          providerName
            ? "offentligTjenestepensjonEngagement.form.introWithName"
            : "offentligTjenestepensjonEngagement.form.intro",
          { args: { name: providerName } },
        ),
      ),
    );
  }

  public sethasEnoughTimeInPublicSectorControl(item: RadioButton): void {
    const booleanValue = item.value === "true";
    this.hasEnoughTimeInPublicSector.markAsTouched();
    this.hasEnoughTimeInPublicSector.setValue(booleanValue);
  }

  public exitButtonAfterFalseOccupation(): void {
    this.clientDataService.updateOptOutOffentligTjenestepensjon(this.removeSuggestionToAdd.value);

    if (this.hasInjectedEngagement()) {
      this.pensionPlanService.deleteOtherPensionEngagement(this.injectedEngagement);
    }

    this.dialogRef.close();
  }

  public async submit(): Promise<void> {
    const patch: Omit<CustomerSuppliedData.OtherPension, "id" | "includeInPension"> = {
      periods: [
        {
          fromAge: Number(this.fromAge.value),
          duration: MAX_LIFE_EXPECTANCY_AGE - Number(this.fromAge.value) + 1,
          payoutAmountPerYear: Number(this.annualAmount.value),
        },
      ],
      title: this.title.value,
      payer: this.payer.value,
      replaceOffentligTjenestepensjon: true,
    };

    if (this.hasInjectedEngagement()) {
      await this.pensionPlanService.editOtherPensionEngagement(this.injectedEngagement, patch);
    } else {
      await this.pensionPlanService.addOtherPensionEngagement(patch);
    }

    await firstValueFrom(this.fetchPrognosesRunningJobsService.isCurrentYearLoaded$.pipe(filter(Boolean)));
  }

  private async prefillFormFields(): Promise<void> {
    const providerName = await firstValueFrom(this.getProviderName());

    if (this.hasInjectedEngagement()) {
      const { contract } = this.injectedEngagement;
      const { title, payer, periods } = contract;
      const [{ payoutAmountPerYear, fromAge }] = periods;

      this.hasEnoughTimeInPublicSector.setValue(true);
      this.annualAmount.setValue(payoutAmountPerYear);
      this.fromAge.setValue(fromAge);
      this.title.setValue(title);
      this.payer.setValue(payer);

      this.stepper$.next(2);
    }

    if (providerName) {
      this.title.setValue(providerName);
    }
  }
}
