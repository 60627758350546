import { EngagementName } from "src/app/models/pension.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { ArrayType } from "src/app/utils/array";
import { select } from "src/app/utils/rxjs/select";
import { GraphEngagement } from "../graph-engagement.model";

type NorskpensjonContracts = Pick<Graph.Norskpensjon, "contracts" | "unavailablePensionProviders">;

type AnyNorskpensjonContract = ArrayType<NorskpensjonContracts[keyof NorskpensjonContracts]>;

export abstract class AbstractNorskpensjonEngagement<C extends AnyNorskpensjonContract> extends GraphEngagement<
  C,
  Graph.NorskpensjonSimulationInput
> {
  constructor(
    public contract: C,
    prognosis?: Graph.Prognosis,
  ) {
    super(contract, prognosis);
  }

  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return select(this.getNameAsync());
  }

  public getContracts(): unknown[] {
    return [];
  }

  public hasChangeableProfile(): boolean {
    return false;
  }

  public isActive(): boolean {
    return false;
  }
}
