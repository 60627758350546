import { DOCUMENT, PlatformLocation } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { produce } from "immer";
import { get, template } from "lodash-es";
import { Observable, of, ReplaySubject, Subject } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { localFmsApi } from "src/app/constants/api.constants";
import { TRANSLATIONS } from "src/app/services/fms/fms";
import { RawRemoteConfig } from "src/app/services/remote-config.service";
import { Monitoring } from "src/app/utils/monitoring";
import { stbUrlRegex } from "../constants/regex.constants";
import { handleError } from "../utils/http";
import { AnyObject } from "../utils/object";
import { batcherPipe } from "../utils/rxjs/pipes";
import { select, selectObject$ } from "../utils/rxjs/select";
import { getStbStage, isStageLocalhost, StbStage } from "../utils/storebrand-staging";
import { getIsNotNullable, Nullable } from "../utils/utils";
import { ApiEnvironmentLetter, ApiHomeService } from "./api-home.service";
import { EndpointService } from "./endpoint.service";
import { KeycloakService } from "./keycloak.service";
import { GlobalRunningJobsService } from "./running-jobs/global-running-jobs.service";

export const DEFAULT_LOCALE: Fms.LangCode = "no";

/**
 * jest throws error if we try to import date-fns/locale the right way.
 * This is a workaround
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const nb = require("date-fns/locale/nb/index");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const enUS = require("date-fns/locale/en-US/index");

@Injectable({
  providedIn: "root",
})
export class FmsService {
  public readonly fmsLoaded$ = new Subject<void>();
  public readonly texts$ = new ReplaySubject<Fms.Translations>();
  public readonly config$: Observable<RawRemoteConfig>;
  public readonly userFinances$: Observable<UserFinances.Groupings>;

  private readonly missingKeysBatcher$ = new Subject();
  private readonly translationCache: Map<unknown, unknown> = new Map();
  private locale: Fms.LangCode = DEFAULT_LOCALE;

  constructor(
    @Optional()
    @Inject(TRANSLATIONS)
    private readonly translations: Fms.Dictionary,
    private readonly globalRunningJobsService: GlobalRunningJobsService,
    private readonly keycloakService: KeycloakService,
    private readonly endpointService: EndpointService,
    private readonly apiHomeService: ApiHomeService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly platformLocation: PlatformLocation,
  ) {
    if (this.translations) {
      this.texts$.next(translations[this.getLocale()]);
    }

    this.missingKeysBatcher$.pipe(batcherPipe(this.missingKeysBatcher$, 300, true)).subscribe((missingKeys) => {
      Monitoring.error(new Error("Missing translation keys: " + missingKeys.join(", ")), {
        ignore: isStageLocalhost(),
      });
    });

    this.config$ = selectObject$(this.texts$, ({ config }) => config);

    this.userFinances$ = selectObject$(this.texts$, ({ userFinances }) => userFinances);
  }

  public getLocale(): Fms.LangCode {
    return this.locale ?? DEFAULT_LOCALE;
  }

  public setLocale(lang: Fms.LangCode): void {
    this.locale = lang;
    this.document.documentElement.lang = lang;
  }

  public isValidLocale(lang: string): lang is Fms.LangCode {
    const locales: Fms.LangCode[] = ["no", "en"];
    return locales.some((locale) => locale === lang);
  }

  public getDateFnsLocale(): Locale {
    if (this.getLocale() === "en") {
      return enUS;
    }

    return nb;
  }

  public fetchTexts(): Observable<Fms.Translations> {
    const httpGet = (url: string): Observable<Fms.Translations> =>
      this.endpointService.httpGet$(this.generateUrlWithTargetLangParams(url));

    return httpGet(this.endpointService.composeFmsUrl()).pipe(
      catchError(() => httpGet(this.composeFallbackFmsUrl())),
      catchError((err: HttpErrorResponse) => handleError("FmsService::fetchTexts", err, {})),
      tap((texts) => this.texts$.next(texts)),
      finalize(() => this.fmsLoaded$.next()),
      this.globalRunningJobsService.withLoader("FmsService"),
    );
  }

  /**
   * @deprecated use translateAsync instead
   */
  public instant<T = string>(
    key: string | undefined,
    {
      logMissing = true,
      logEmpty = true,
      firstLetterUpperCase = false,
      args = null,
    } = {} as Partial<Fms.TranslationOptions>,
  ): T {
    return select<T>(
      this.translateAsync(key, {
        logMissing,
        logEmpty,
        firstLetterUpperCase,
        args,
      }),
    );
  }

  public translateAsync<T = string>(
    key: Nullable<string>,
    {
      logMissing = true,
      logEmpty = true,
      firstLetterUpperCase = false,
      args = null,
    } = {} as Partial<Fms.TranslationOptions>,
  ): Observable<T> {
    /* Cache Start */
    const cachedTranslation = this.translationCache.get(translationMatcher(key, { firstLetterUpperCase, args }));
    const cacheHit = getIsNotNullable(cachedTranslation);

    if (cacheHit) {
      return of(cachedTranslation as T);
    }
    /* Cache End */

    const logMissingTranslation = (translation: unknown): void => {
      if (translation === "" && logEmpty) {
        this.missingKeysBatcher$.next(key);
      }
      if (translation === key && logMissing) {
        this.missingKeysBatcher$.next(key);
      }
    };
    const toFormattedStringOrDefault = (input: any): T =>
      typeof input === "string" && firstLetterUpperCase ? input.charAt(0).toUpperCase() + input.slice(1) : input;

    return this.translate(key).pipe(
      tap(logMissingTranslation),
      map((translation) => this.flattenFmsEntry(translation, this.getLocale(), args)),
      map(toFormattedStringOrDefault),
      tap((translation) =>
        /* Populate cache */
        this.translationCache.set(translationMatcher(key, { firstLetterUpperCase, args }), translation),
      ),
    );
  }

  private generateUrlWithTargetLangParams(url: string): string {
    return `${url}?lang=${this.getLocale()}&version=${this.getVersion()}`;
  }

  private composeFallbackFmsUrl(): string {
    const baseHref = this.platformLocation.getBaseHrefFromDOM();
    return baseHref + localFmsApi;
  }

  private getVersion(): Fms.FmsVersion {
    switch (getStbStage(select(this.keycloakService.isAdvisorContext$))) {
      case StbStage.Localhost:
      case StbStage.Utvikling:
      case StbStage.Test:
      case StbStage.TestStabil:
        return "pending";
      case StbStage.Produksjon:
      default:
        return "active";
    }
  }

  /**
   * Recursively iterates over keys in an object,
   * any entries with keys 'no' or 'en' are flattened according to localeStr
   */
  private flattenFmsEntry(fmsEntry: any, localeStr: Fms.LangCode, args?: Nullable<object>): any {
    switch (true) {
      case typeof fmsEntry !== "object":
        return this.replaceUrls(replace(fmsEntry, args));
      case Array.isArray(fmsEntry):
        return fmsEntry.map((entry: any) => this.flattenFmsEntry(entry, localeStr, args));
      case Object.keys(fmsEntry).some((key) => ["en", "no"].includes(key)):
        return this.replaceUrls(replace(fmsEntry[localeStr], args));
      default:
        return Object.keys(fmsEntry).reduce<AnyObject>(
          (acc, curr) =>
            produce(acc, (draft) => {
              draft[curr] = this.flattenFmsEntry(fmsEntry[curr], localeStr, args);
            }),
          {},
        );
    }
  }

  /**
   * Translates the given key to a readable string of the language
   * given by the user profile. Dictionaries can be:
   *   - remote dictionary (always up to date)
   *   - local copy of remote dictionary (may be outdated)
   *
   * If no translation is found the untranslated key is returned.
   *
   * @param key the translation key to translate
   * @return the translation if it exists, otherwise the key
   */
  private translate<T = unknown>(key: Nullable<string>): Observable<T> {
    return this.texts$.pipe(map((texts) => get(texts, key || "") ?? key));
  }

  /**
   * Recursively replaces every occurance of a (www.)storebrand.no
   * URL to match the current environment
   */
  private replaceUrls(input: any): any {
    const env = select(this.apiHomeService.environmentLetter$);
    const { Development, Test, TestStable, Production } = ApiEnvironmentLetter;

    const replacements = {
      [Development]: "www-u",
      [Test]: "www-t",
      [TestStable]: "www-ts",
      [Production]: "www",
    };

    if (typeof input === "string" && !!input.match(stbUrlRegex)) {
      const matches = stbUrlRegex.exec(input);
      const urlEnv = matches?.at(2);
      const isProdUrl = !urlEnv || urlEnv === replacements[Production];

      if (env === Production && !isProdUrl) {
        Monitoring.message(`Non-production URL found in FMS: ${input}`, Monitoring.Level.Warn);
      }

      return input.replace(stbUrlRegex, `$1${replacements[env]}.$3`);
    }

    return input;
  }
}

export function replace(text: string = "", args?: Nullable<object>): string {
  if (args) {
    return template(text)(args);
  }

  return text;
}

export function validateIsTemplateIterable(val: any): boolean {
  if (val == null || typeof val === "string" || typeof val[Symbol.iterator] !== "function") {
    Monitoring.error(new Error(`Expected fms key with value: \n"${val}" to be iterable`), {
      ignore: isStageLocalhost(),
    });
    return false;
  }
  return true;
}

function translationMatcher(
  key: Nullable<string>,
  { firstLetterUpperCase, args }: Omit<Fms.TranslationOptions, "logMissing" | "logEmpty">,
): string {
  return JSON.stringify({ key, firstLetterUpperCase, args });
}
