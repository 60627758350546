import { inject, InjectionToken } from "@angular/core";
import { Monitoring } from "src/app/utils/monitoring";
import { AddLumpSumActionBuilder } from "./action-builders/add-lump-sum-action-builder";
import { AvailableActionsBuilder } from "./action-builders/available-actions-builder";
import { ChangeMonthlySavingsActionBuilder } from "./action-builders/change-monthly-savings-action-builder";
import { ChangeSavingsProfileOrFundActionBuilder } from "./action-builders/change-savings-profile-or-fund-action-builder";
import { DeleteContractActionBuilder } from "./action-builders/delete-contract-action-builder";
import { EditContractActionBuilder } from "./action-builders/edit-contract-action-builder";
import { EkstrapensjonWithdrawalActionBuilder } from "./action-builders/ekstrapensjon-withdrawal-action-builder.service";
import { GiveOfaConsentActionBuilder } from "./action-builders/give-ofa-consent-action-builder";
import { LinkToOnlineBankingActionBuilder } from "./action-builders/link-to-online-banking-action-builder";
import { LinkToPayoutsWebActionBuilder } from "./action-builders/link-to-payouts-web-action-builder";
import { LinkToSmartAccountDetailsActionBuilder } from "./action-builders/link-to-smart-account-details-action-builder";
import { MoveSavingsToSmartAccountActionBuilder } from "./action-builders/move-savings-to-smart-account-action-builder";
import { ContractActionBuilder, ContractActionsErrorReporter } from "./contract-actions.types";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";
import { ConvertToFmiActionBuilder } from "src/app/modules/shared/services/contract-actions/action-builders/convert-to-fmi-action-builder";

export const CONTRACT_ACTION_BUILDERS_TOKEN = new InjectionToken("Contract action builders", {
  providedIn: "any",
  factory(): ContractActionBuilder[] {
    return [
      inject(ChangeSavingsProfileOrFundActionBuilder),
      inject(ChangeMonthlySavingsActionBuilder),
      inject(AddLumpSumActionBuilder),
      inject(MoveSavingsToSmartAccountActionBuilder),
      inject(LinkToSmartAccountDetailsActionBuilder),
      inject(EkstrapensjonWithdrawalActionBuilder),
      inject(LinkToOnlineBankingActionBuilder),
      inject(LinkToPayoutsWebActionBuilder),
      inject(AvailableActionsBuilder),
      inject(EditContractActionBuilder),
      inject(GiveOfaConsentActionBuilder),
      inject(DeleteContractActionBuilder),
      inject(ConvertToFmiActionBuilder),
    ];
  },
});

export const CONTRACT_ACTIONS_ERROR_REPORTER_TOKEN = new InjectionToken("Contract actions error reporter", {
  providedIn: "any",
  factory(): ContractActionsErrorReporter {
    return (errors: Error[]): void => {
      Monitoring.error(new Error("Failed to get contract actions!"), {
        extras: { errors },
        ignore: isStageLocalhost(),
      });
    };
  },
});
