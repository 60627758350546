import { produce } from "immer";
import { isEqual, toPlainObject } from "lodash-es";
import { Observable, first, merge, of, mergeMap } from "rxjs";
import {
  catchError,
  combineLatestWith,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import {
  GenericEngagementOfUnknown,
  GenericEngagementTypeOf,
  PrognosisParametersTypeOf,
  PrognosisTypeOf,
} from "src/app/models/engagements/generic-engagement.model";
import { ResponseHeaders } from "src/app/modules/graphql-clients/local-schema/response-headers";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { ErrorCategory, ErrorContainer } from "src/app/services/errors.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { PrognosisIdCollision } from "src/app/utils/errors";
import { handleError } from "src/app/utils/http";
import { Monitoring } from "src/app/utils/monitoring";
import { mergeDeepWith } from "src/app/utils/object";
import { MissingPrognosisError } from "src/app/utils/prognosis-errors";
import { memoize$, memoizeObject$, select } from "src/app/utils/rxjs/select";
import { ReplayStore } from "src/app/utils/rxjs/store";
import { Nullable } from "src/app/utils/utils";
import { FmsKey } from "../fms/fms";
import { SimulationErrorCmsKey, createGraphSimulationError } from "./simulation-result.creators";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

interface ComparableEngagement {
  [key: string]: unknown;
  id: string;
}

type EngagementWithSimParams<E extends GenericEngagementTypeOf<E>, P> = [
  E,
  CustomerSuppliedData.SimulationParametersByEngagement<P>,
];

export interface ServiceConfig {
  name: string;
  fmsKey: string;
  disablePrognosisSpinner$?: Observable<boolean>;
}

export interface PrognosisQueryResult<T> {
  data: T;
  headers?: ResponseHeaders;
}

export interface FetchPrognosisResult<T extends GenericEngagementOfUnknown> {
  prognoses: PrognosisTypeOf<T>[];
  metadata?: {
    correlationId?: ResponseHeaders["correlationId"];
    prognosisInput?: PrognosisParametersTypeOf<T>;
  };
}

export interface EngagementService {
  engagements$: Observable<any>;
  engagementHttpErrors$: Observable<Nullable<ErrorContainer>>;
  isStorebrandOnly: () => boolean;
}

/**
 * TEngagement defines the type of engagement model for this service,
 * for example LifeEngagement.
 */
export abstract class AbstractPrognosisFetchService<
  TEngagementModel extends GenericEngagementTypeOf<TEngagementModel>,
  TPrognosisSimParams extends object,
> implements EngagementService
{
  public readonly engagementsFetchedSignal$: Observable<boolean>;
  public readonly hotStreamsOfSimulationParametersByEngagement$: Observable<
    EngagementWithSimParams<TEngagementModel, TPrognosisSimParams>
  >;

  private readonly _engagements$: ReplayStore<TEngagementModel[]> = new ReplayStore();
  private readonly _engagementHttpErrors$: ReplayStore<Nullable<ErrorContainer>> = new ReplayStore();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public engagements$: Observable<TEngagementModel[]> = memoizeObject$(this._engagements$);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly engagementHttpErrors$: Observable<Nullable<ErrorContainer>> = memoizeObject$(
    this._engagementHttpErrors$,
  );

  private readonly updatePrognosesByEngagementSimParams$: Observable<TEngagementModel>;
  private engagementsInitialized = false;

  protected constructor(
    protected config: ServiceConfig,
    protected commonParametersService: CommonParametersService,
    protected fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
  ) {
    this.hotStreamsOfSimulationParametersByEngagement$ = this.engagements$.pipe(
      distinctUntilChanged(isEqualEngagements),
      switchMap((engagements) =>
        // switch to hot streams of simulation parameters per engagement
        merge(
          ...this.commonParametersService.mapEngagementListToSimParams$(engagements).map((observable$) =>
            observable$.pipe(
              switchMap(([engagement, [key, params]]) =>
                this.prognosisSimParams$(params).pipe(
                  map((val) => val ?? {}),
                  map((val) => [engagement, [key, mergeDeepWith(val, params)]]),
                ),
              ),
            ),
          ),
        ).pipe(
          /**
           * Merge does not support passing generics explicitly after rxjs v7 upgrade.
           * Typecasting the result to the correct type here instead
           */
          map((value) => value as EngagementWithSimParams<TEngagementModel, TPrognosisSimParams>),
        ),
      ),
    );

    this.updatePrognosesByEngagementSimParams$ = this.hotStreamsOfSimulationParametersByEngagement$.pipe(
      withLatestFrom(this.config.disablePrognosisSpinner$ ?? of(false)),
      tap(
        ([[engagement], disablePrognosisSpinner]) =>
          !disablePrognosisSpinner &&
          this.fetchPrognosesRunningJobsService.startFetchingPrognosisForEngagement(engagement),
      ),
      // Force async emissions also when prognosis requests hits cache and would
      // normally continue as a synchronous emission
      delay(0),
      mergeMap(
        ([[engagement, keyedParams]]) =>
          // switch to completing streams of prognoses
          this.fetchPrognosisByEngagement(engagement, keyedParams),
        8,
      ),
      // Limit concurrency to 8 to prevent too many concurrent requests that cause out of memory errors
      tap((p) => this.updateEngagement(p)), // update engagement with prognosis as side effect
      withLatestFrom(this.config.disablePrognosisSpinner$ ?? of(false)),
      tap(
        ([engagement, disablePrognosisSpinner]) =>
          !disablePrognosisSpinner &&
          this.fetchPrognosesRunningJobsService.stopFetchingPrognosisForEngagement(engagement),
      ),
      map(([engagement]) => engagement),
    );

    // Engagement services are themselves responsible for updating prognoses when
    // engagements are loaded.
    this.updatePrognosesByEngagementSimParams$.subscribe({
      complete: () =>
        Monitoring.warn(`[${config.name}] Got complete signal from UpdatePrognosesByEngagementSimParams$`),
    });

    this.engagementsFetchedSignal$ = memoize$(this.engagements$.pipe(map(Boolean)));

    this.engagementHttpErrors$ = this.engagementHttpErrors$.pipe(
      combineLatestWith(this.config.disablePrognosisSpinner$ ?? of(false)),
      map(([errors, isDisabled]) => (isDisabled ? null : errors)),
    );
  }

  public fetchEngagements(): Observable<TEngagementModel[]> {
    const source = `${this.config.name}::fetchEngagements`;
    return this._fetchEngagements().pipe(
      take(1),
      catchError((error) => {
        const title = `errors.datafetch.${this.config.fmsKey}.title` as FmsKey;
        const message = `errors.datafetch.${this.config.fmsKey}` as FmsKey;

        this.addEngagementHttpError({
          category: ErrorCategory.Engagement,
          text: {
            title,
            message,
          },
        });

        return handleError(source, error, []);
      }),
      tap((engagements) => {
        if (engagements && !isEqualEngagements(select(this.engagements$, { fallback: [] }), engagements)) {
          this.engagementsInitialized = true;
          this._engagements$.next(engagements);
        }
      }),
      finalize(() => {
        // Ensure default values in case of 204 or similar, such that
        // EngagementsService.allEngagements$ is not blocked by combineLatest
        if (!this.engagementsInitialized) {
          this._engagements$.next([]);
        }
      }),
    );
  }

  public isStorebrandOnly(): boolean {
    return false;
  }

  protected addEngagementHttpError(error: ErrorContainer): void {
    this._engagementHttpErrors$.next(error);
  }

  protected composeFailedPrognosis(
    engagement: TEngagementModel,
    isAgeHigherThanMaxSimAge?: boolean,
  ): PrognosisTypeOf<TEngagementModel> {
    const cmsKey = isAgeHigherThanMaxSimAge
      ? SimulationErrorCmsKey.AboveMaxAgeError
      : SimulationErrorCmsKey.GenericError;

    return {
      ...(engagement?.prognosis || {}),
      simulationResult: createGraphSimulationError(cmsKey),
    } as PrognosisTypeOf<TEngagementModel>;
  }

  protected composeMissingPrognosisError(engagement: TEngagementModel): MissingPrognosisError {
    return new MissingPrognosisError(engagement);
  }

  private updateEngagement(engagement: TEngagementModel): void {
    if (!engagement) {
      return; // TODO: Early exit on failed prognosis. Should roll back sim params to previous value.
    }
    const updatedEngagements = [...select(this.engagements$, { copy: false })].map((e) =>
      e.getIdentifier() === engagement.getIdentifier() ? engagement : e,
    );
    this._engagements$.next(updatedEngagements);
  }

  private fetchPrognosisByEngagement(
    engagement: TEngagementModel,
    keyedParams: CustomerSuppliedData.SimulationParametersByEngagement<TPrognosisSimParams>,
  ): Observable<TEngagementModel> {
    const [, params] = keyedParams;

    return this.commonParametersService.isAgeHigherThanMaxSimAge$.pipe(
      first(),
      switchMap((isAgeHigherThanMaxSimAge) => {
        if (isAgeHigherThanMaxSimAge) {
          throw this.composeFailedPrognosis(engagement, true);
        }

        return this.fetchPrognosis(engagement, params);
      }),
      map((fetchPrognosisResult) => {
        const { prognoses, metadata } = fetchPrognosisResult;
        const error = this.getPrognosisError(engagement, prognoses);
        const prognosis = prognoses ? prognoses.at(0) : undefined;

        if (error) {
          Monitoring.error(error, {
            tags: { correlationId: metadata?.correlationId },
            extras: { params },
            ignore: isStageLocalhost(),
          });

          throw this.composeFailedPrognosis(engagement);
        }

        if (!prognosis) {
          throw this.composeFailedPrognosis(engagement);
        }

        return engagement.withPrognosis(prognosis).withPrognosisParametersInput(metadata?.prognosisInput ?? null);
      }),
      catchError((failedPrognosis) => of(engagement.withPrognosis(failedPrognosis))),
    );
  }

  private getPrognosisError(
    engagement: TEngagementModel,
    prognoses?: PrognosisTypeOf<TEngagementModel>[],
  ): PrognosisIdCollision<TEngagementModel> | MissingPrognosisError | undefined {
    if (!Array.isArray(prognoses)) {
      return undefined;
    }

    if (prognoses.length > 1) {
      return new PrognosisIdCollision("Multiple prognoses mapped to the same engagement", engagement, prognoses);
    }

    if (prognoses.length === 0) {
      return this.composeMissingPrognosisError(engagement);
    }

    return undefined;
  }

  public abstract fetchPrognosis(
    engagement: TEngagementModel,
    params: CustomerSuppliedData.SimulationParametersByEngagement<TPrognosisSimParams>[1],
  ): Observable<FetchPrognosisResult<TEngagementModel>>;

  protected abstract prognosisSimParams$(
    engagementParams?: Partial<CustomerSuppliedData.SimulationParameters>,
  ): Observable<Partial<TPrognosisSimParams> | null>;

  protected abstract _fetchEngagements(): Observable<TEngagementModel[]>;
}

export function isEqualEngagements<T extends GenericEngagementOfUnknown>(previous: T[], next: T[]): boolean {
  return isEqual(toComparableEngagements(previous), toComparableEngagements(next));
}

function toComparableEngagements<T extends GenericEngagementOfUnknown>(engagements: T[]): ComparableEngagement[] {
  return engagements.map((engagement) => {
    const id = engagement.getIdentifier();
    const props = produce<GenericEngagementOfUnknown>(toPlainObject(engagement), (draft) => {
      delete draft.prognosis;
      delete draft.contractTraceReference;
    });

    return {
      id,
      ...props,
    };
  });
}

export function toFetchPrognosisResult<T extends GenericEngagementOfUnknown>(
  queryResult: PrognosisQueryResult<PrognosisTypeOf<T>[]>,
  prognosisInput?: PrognosisParametersTypeOf<T>,
): FetchPrognosisResult<T> {
  return {
    prognoses: queryResult.data,
    metadata: { ...queryResult.headers, prognosisInput },
  };
}
