import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Options, SeriesSplineOptions } from "highcharts";
import { combineLatest, map, Observable, of } from "rxjs";
import { getOptions } from "src/app/modules/shared/components/charts/market-value-chart/market-value-chart.options";
import { FmsService } from "src/app/services/fms.service";

export interface MarketValueChartData {
  marketValueData: SeriesSplineOptions["data"];
  paidInData: SeriesSplineOptions["data"];
}

@Component({
  selector: "app-market-value-chart",
  templateUrl: "./market-value-chart.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketValueChartComponent implements OnInit {
  @Input()
  private readonly marketValueChartData$!: Observable<MarketValueChartData>;

  public options$: Observable<Options>;
  public styledSeries$: Observable<SeriesSplineOptions[]> | undefined;

  constructor(private readonly fmsService: FmsService) {
    this.options$ = of(getOptions());
  }

  public ngOnInit(): void {
    this.styledSeries$ = combineLatest([
      this.marketValueChartData$,
      this.fmsService.translateAsync<string>("chartLegend.marketValue"),
      this.fmsService.translateAsync<string>("chartLegend.mainCapital"),
    ]).pipe(
      map(([chartData, markedValue, paidIn]) => [
        {
          data: chartData.marketValueData,
          name: markedValue,
          type: "spline",
        },
        {
          data: chartData.paidInData,
          name: paidIn,
          dashStyle: "Dash",
          type: "spline",
        },
      ]),
    );
  }
}
