<stb-form
  size="flex"
  [label]="'financesinput.part_time_percent.label' | fms"
  [error]="formControl.touched && formControl.hasError('required')"
  [errorMessage]="'financesinput.validation.required' | fms"
  [disabled]="disabled"
>
  <input
    appDiscretePercentageMask
    [formControl]="formControl"
    id="partTimePercent"
    type="tel"
    [placeholder]="'financesinput.part_time_percent.placeholder' | fms"
    [attr.aria-label]="'financesinput.part_time_percent.popover' | fms"
  />
</stb-form>
