/* eslint-disable */
export const alphaNumericRegex = /^[ÆØÅæøåAÄäÅåÖö\w0-9\- .,]+$/i;
export const digitsOnlyRegex = /^\d+$/;
export const positiveNaturalNumbersRegex = /^[+]?[0-9]+$/;
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&æøåÆØÅ*+/=?^_`{|}~-]+@([a-zA-Z0-9-_æøåÆØÅ])+\.([a-zA-Z0-9-_æøåÆØÅ.]+).*[a-z]+$/;
export const mobileRegex = /^(?:\s*\d){8}$/;
export const currencyRegex = /(^kr\s[\d\s]+$)|(^\d+$)/;
export const accountNumberRegex = /([0-9]{4})([0-9]{2})([0-9]{5})/;
// the following two are used for when a user has partially input correct currency
export const uncompletedCurrencyRegex = /^k(r\s?)?$/;
export const nonZeroCurrencyRegex = /(^kr\s[1-9][\d\s]*$)|(^[1-9][\s\d]*$)/;
export const nonZeroMaskEdgeCaseRegex = /^kr\s0[1-9]$/;
export const currencyAmountRegex = /^kr\s([\d\s]+)|([\d\s]+)$/;

// since validator runs before mask directive, kr 01 etc. must be accepted
export const nonZeroCurrencyRegexForMaskDirective = new RegExp(
  `(${nonZeroCurrencyRegex.source})|(${nonZeroMaskEdgeCaseRegex.source})`,
);
export const cmidRegex = /[?]cmid=([\d]*)/i;
export const userStageRegex = /www\-(u|t|ts)\.|(www)\.|(localhost)/;
export const advisorStageRegex =
  /(u|t|ts|pr)\.stb\.intra|(www)\.intra\.storebrand\.no|www\-(u|t|ts)\.intra\.storebrand\.no|(localhost)/;
export const currencySanitizerRegex = /[^0-9.,\-]*/g;
export const apiHomeEnvironmentRegex = /(api(-(u|t|ts))?)\..+/;
export const stbSubdomainRegex = /(:\/\/|[^\.]+\.)storebrand\.no/;
export const stbUrlRegex = /((?:https?:)?\/\/)(?:(www(?:-(?:u|ts?))?)\.)?(storebrand\.no)/gi;
