import { map, Observable, pipe, tap, UnaryFunction } from "rxjs";
import { isSmartAccount } from "src/app/models/engagements/bank-engagement.model";
import { NorskpensjonEngagement } from "src/app/models/engagements/norskpensjon/norskpensjon-engagement.model";
import { AbstractLinkEngagement } from "src/app/models/engagements/savings-and-pension/link-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { Account } from "src/app/services/api/savings-and-pension-queries.types";
import {
  CHANGEABLE_PORTFOLIOS,
  NOT_ALLOWED_IN_PAYOUT_PHASE_SIMULATION_STATUS_KEY,
} from "../constants/business.constants";
import { EngagementSimulationStatus } from "../models/pension.model";
import { AnyEngagement } from "../services/engagements.service";
import { AnySavingsAndPensionEngagement } from "../services/prognoses-services/savings-and-pension.service";
import { getPortfolioInsightAccounts } from "./apolloQuerySelectors";
import {
  isAfpEngagement,
  isBankEngagement,
  isEkstrapensjonEmploymentEngagement,
  isEkstrapensjonEngagement,
  isEpkEngagement,
  isEpkFleksibelEngagement,
  isEpkFleksibelPkbEngagement,
  isExternalSavingsEngagement,
  isFmiEngagement,
  isFripoliseEngagement,
  isHybridMedGarantiEngagement,
  isHybridMedInvesteringsvalgEngagement,
  isHybridPensjonsbevisEngagement,
  isIpsEngagement,
  isLinkEngagement,
  isNavEngagement,
  isNorskpensjonEngagement,
  isOtherPensionEngagement,
  isPkbEngagement,
  isPublicPensionEngagement,
  isUnavailableNorskpensjonEngagement,
  isYtpEngagement,
} from "./engagement.typeguards";
import { getIsNotNullable, Nullable } from "./utils";

interface PayoutPlanLike {
  age?: number | null;
  amount?: number | null;
}

export enum EngagementCategory {
  Public = "Public",
  Savings = "Savings",
  Employment = "Employment",
  Unknown = "Unknown",
}

export function getPayoutForAge(forAge: number, payoutPlan: PayoutPlanLike[]): Nullable<number> {
  return payoutPlan.find((payout) => payout?.age === forAge)?.amount;
}

export function hasChangeablePortfolio(engagement: AnyEngagement): boolean {
  return CHANGEABLE_PORTFOLIOS.some((pensionGroup) => pensionGroup === engagement.getPensionGroup());
}

export function getIsEpkFromNorskpensjon(engagement: NorskpensjonEngagement): boolean {
  return engagement.contract.subCategory === "innskuddspensjon";
}

export function hasEngagementBalance(engagement: AnyEngagement): boolean {
  return getIsNotNullable(engagement.getBalance());
}

export const NotOkSeverities = [
  Graph.SimulationSeverity.BusinessError,
  Graph.SimulationSeverity.NotAllowed,
  Graph.SimulationSeverity.SimulationError,
];
export function getIsSimulationStatusNotOk(engagement: AnyEngagement, severities = NotOkSeverities): boolean {
  return engagement
    .getSimulationStatus()
    .map(({ severity }) => severity)
    .some((severity) => severities.includes(severity));
}

export function getIsSimulationStatusOk(engagement: AnyEngagement): boolean {
  return !getIsSimulationStatusNotOk(engagement);
}

export function toMostImportantSimulationStatus(
  statuses: EngagementSimulationStatus[],
): EngagementSimulationStatus | undefined {
  return statuses.sort((first, second) => byGreatestSeverity(first?.severity, second?.severity)).at(-1);
}

export function getIsInPayoutPhase(engagement: AnyEngagement): boolean {
  return engagement
    .getSimulationStatus()
    .some((simulationStatus) => simulationStatus?.key?.includes(NOT_ALLOWED_IN_PAYOUT_PHASE_SIMULATION_STATUS_KEY));
}

function byGreatestSeverity(
  first: EngagementSimulationStatus["severity"],
  second: EngagementSimulationStatus["severity"],
): number {
  const statusBySeverity = [
    Graph.SimulationSeverity.Ok,
    Graph.SimulationSeverity.Notice,
    Graph.SimulationSeverity.BusinessError,
    Graph.SimulationSeverity.NotAllowed,
    Graph.SimulationSeverity.SimulationError,
  ];

  return statusBySeverity.indexOf(first) - statusBySeverity.indexOf(second);
}

export function getIsSmartAccount(engagement: AnyEngagement): boolean {
  return isBankEngagement(engagement) && isSmartAccount(engagement.contract);
}

export function getIsInvestableEngagement(engagement: AnyEngagement): engagement is AbstractLinkEngagement {
  return isEkstrapensjonEngagement(engagement) || isIpsEngagement(engagement);
}

export function getEngagementCategory(engagement: AnyEngagement, hasAfp: boolean): EngagementCategory {
  if (isEngagementFromEmployment(engagement, hasAfp)) {
    return EngagementCategory.Employment;
  }
  if (isEngagementFromPublic(engagement)) {
    return EngagementCategory.Public;
  }
  if (isEngagementFromSavings(engagement)) {
    return EngagementCategory.Savings;
  }

  return EngagementCategory.Unknown;
}

export function isEngagementFromSavings(engagement: AnyEngagement): boolean {
  return engagement.isSavingsEngagement();
}

export function isEngagementFromEmployment(
  engagement: AnyEngagement,
  hasAfp: boolean,
  includePublicPension = false,
): boolean {
  const isOffentligTjenestepensjon =
    (isNorskpensjonEngagement(engagement) || isUnavailableNorskpensjonEngagement(engagement)) &&
    engagement.isPublicPensionFromNorskPensjon();
  const isNav = isNavEngagement(engagement);
  const isEngagementAfpAndNotHaveAfp = isAfpEngagement(engagement) && !hasAfp;

  return (
    !(engagement.isSavingsEngagement() || isNav || isOffentligTjenestepensjon || isEngagementAfpAndNotHaveAfp) ||
    (includePublicPension && isOffentligTjenestepensjon)
  );
}

export function isEngagementFromPublic(engagement: AnyEngagement): boolean {
  return isNavEngagement(engagement);
}

export function getIsVisibleEngagement(engagement: AnyEngagement, hasAfp: boolean): boolean {
  return (
    isEngagementFromSavings(engagement) ||
    isEngagementFromEmployment(engagement, hasAfp) ||
    isEngagementFromPublic(engagement)
  );
}

export function filterVisibleEngagements(engagements: AnyEngagement[], hasAfp: boolean): AnyEngagement[] {
  return engagements.filter((engagement) => getIsVisibleEngagement(engagement, hasAfp));
}

export function getHasContractNavigation<T extends AnyEngagement>(
  anyEngagement: T,
): anyEngagement is T & {
  contract: T["contract"] & {
    contractNavigation: Graph.ContractNavigation;
  };
} {
  return getIsNotNullable((<any>anyEngagement.contract).contractNavigation);
}

export function getHasKeyInformation(engagement: AnyEngagement): boolean {
  const doesNotHaveKeyInformation =
    isNavEngagement(engagement) ||
    isAfpEngagement(engagement) ||
    isBankEngagement(engagement) ||
    isExternalSavingsEngagement(engagement) ||
    isOtherPensionEngagement(engagement) ||
    isPublicPensionEngagement(engagement);
  return !doesNotHaveKeyInformation;
}

export function getIsEngagementWithKeyValues(engagement: AnySavingsAndPensionEngagement): boolean {
  return (
    isEkstrapensjonEmploymentEngagement(engagement) ||
    isEpkEngagement(engagement) ||
    isEpkFleksibelEngagement(engagement) ||
    isEpkFleksibelPkbEngagement(engagement) ||
    isFmiEngagement(engagement) ||
    isHybridMedInvesteringsvalgEngagement(engagement) ||
    isLinkEngagement(engagement) ||
    isPkbEngagement(engagement)
  );
}

export function filterEngagementsWithKeyValues(
  engagements: AnySavingsAndPensionEngagement[],
): AnySavingsAndPensionEngagement[] {
  return engagements.filter(getIsEngagementWithKeyValues);
}

const createContractIdFromAccount = (account: Account): string => {
  const participantId = account?.assetId?.participantId ?? "";
  const accountId = account?.assetId?.accountId ?? "";
  const systemArea = account?.assetId?.systemArea ?? "";
  return `${participantId}-${accountId}:${systemArea}`;
};

export const makeFilterAccountsByContractId =
  (contractId: string) =>
  (accounts: Account[]): Account[] =>
    accounts.filter((account) => createContractIdFromAccount(account) === contractId);

export function mapAccountsFromQueryById(
  contractId: string,
): UnaryFunction<Observable<Graph.Query>, Observable<Graph.Account[]>> {
  return pipe(
    map(getPortfolioInsightAccounts),
    map(makeFilterAccountsByContractId(contractId)),
    tap((accounts) => {
      if (accounts.length > 1) {
        throw new Error("Multiple portfolio insight accounts for single id!");
      }
    }),
  );
}

export function hasEngagementContractUnderPayout(engagement: AnyEngagement): boolean {
  return Array.isArray(engagement.contract)
    ? engagement.contract.some((con) => "underPayout" in con && con.underPayout === true)
    : "underPayout" in engagement.contract && engagement.contract.underPayout === true;
}

/**
 * Checks if the given engagement is one of the specified types that are not adjusted for inflation.
 *
 * In Smart pensjon, almost all prognosis calculations are adjusted for inflation. However, for certain products,
 * the calculations are not adjusted for inflation. This function identifies those products.
 *
 * The following products are considered non-inflation-adjusted:
 * - Paid-up policies ("Fripoliser")
 * - Defined benefits agreements ("Ytelsespensjoner")
 * - Hybrid pensjon with guarantee ("Hybridpensjon med garanti")
 * - Pension certificate ("Pensjonsbevis")
 *
 * @param engagement - The engagement to check.
 * @returns True if the engagement is non-inflation-adjusted, false otherwise.
 */
export function isNonInflationAdjustedEngagement(engagement: AnyEngagement): boolean {
  return (
    isFripoliseEngagement(engagement) ||
    isHybridMedGarantiEngagement(engagement) ||
    isHybridPensjonsbevisEngagement(engagement) ||
    isYtpEngagement(engagement)
  );
}
