import { Type } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConceptPageComponent } from "src/app/core/pages/concept-page/concept-page.component";
import { ContactUsPageComponent } from "src/app/core/pages/contact-us-page/contact-us-page.component";
import { ActionsModule } from "src/app/modules/actions/actions.module";
import { RouteKey as ActionsRouteKey, routes as actionsRoutes } from "src/app/modules/actions/actions.routing";
import { RouteKey as ArticlesRouteKey, routes as articleRoutes } from "src/app/modules/articles/routes";
import { RouteKey as ContractsRouteKey, routes as contractsRoutes } from "src/app/modules/contracts/routes";
import {
  RouteKey as InvestmentOptionsRouteKey,
  routes as investmentOptionsRoutes,
} from "src/app/modules/investment-options/routes";
import { NeedsModule } from "src/app/modules/needs/needs.module";
import { RouteKey as NeedsRouteKey, routes as needsRoutes } from "src/app/modules/needs/needs.routing";
import { ManagePkbPagesModule } from "src/app/modules/pages/manage-pkb-pages/manage-pkb-pages.module";
import {
  RouteKey as ManagePkbRouteKey,
  routes as managePkbRoutes,
} from "src/app/modules/pages/manage-pkb-pages/manage-pkb-pages.routing";
import {
  RouteKey as PlanRouteKey,
  deprecatedRoutes as planDeprecatedRoutes,
  routes as planRoutes,
} from "src/app/modules/pension-plan/routes";
import { RouteKey as SmartAccountRouteKey, routes as smartAccountRoutes } from "src/app/modules/smart-account/routes";
import { navigateToPensionPlanResolver } from "src/app/resolvers/navigate-to-pension-plan.resolver";
import { BreadcrumbKey } from "src/app/services/breadcrumbs.service";
import { environment } from "src/environments/environment";
import { DashboardComponent } from "./core/pages/dashboard/dashboard.component";
import { canActivateApp } from "./guards/auth.guard";
import { ArticlesModule } from "./modules/articles/articles.module";
import { ContractsModule } from "./modules/contracts/contracts.module";
import { InvestmentOptionsModule } from "./modules/investment-options/investment-options.module";
import { PensionPlanModule } from "./modules/pension-plan/pension-plan.module";
import { PageNotFoundComponent } from "./modules/shared/components/pagenotfound/pagenotfound.component";
import { smartAccountResolver } from "./modules/smart-account/resolvers/smart-account.resolver";
import { SmartAccountModule } from "./modules/smart-account/smart-account.module";
import {
  apiHomeResolver,
  contractSalaryResolver,
  fmsResolver,
  portfolioInsightResolver,
} from "./resolvers/app.resolver";
import {
  afpResolver,
  bankResolver,
  navResolver,
  norskpensjonResolver,
  otherPensionEngagementsResolver,
  publicPensionResolver,
  savingsAndPensionResolver,
  unavailableNorskpensjonResolver,
} from "./resolvers/engagement.resolver";
import { removeAfpInPublicContextResolver } from "./resolvers/remove-afp-in-public-context.resolver";
import {
  benefitsResolver,
  consentResolver,
  customerResolver,
  customerSuppliedClientDataResolver,
  customerSuppliedDataResolver,
  languageResolver,
} from "./resolvers/user.resolver";
import { RouteKey, routes } from "./routes";
import { SavingForPensionModule } from "./modules/saving-for-pension/saving-for-pension.module";
import {
  RouteKey as SavingForPensionKey,
  routes as savingForPensionRoutes,
} from "src/app/modules/saving-for-pension/routes";
/**
 * Renamed or deprecated routes for backward compability.
 * A visit to any route in this list will trigger a monitor warning, which is
 * useful for catching old links spread around both inside and outside
 * the application.
 */
export const monitoredDeprecatedUrls: string[] = Object.values(planDeprecatedRoutes).map(
  (value) => `${planRoutes[PlanRouteKey.Root]}/${value}`,
);

export const appRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        resolve: {
          apiHomeResolver,
        },
        children: [
          {
            path: "",
            canActivateChild: environment.offlineMode ? [] : [canActivateApp],
            resolve: [],
            children: [
              {
                path: "",
                resolve: {
                  customerResolver,
                  contractSalaryResolver,
                  languageResolver,
                },
                children: [
                  {
                    path: "",
                    resolve: {
                      consentResolver,
                      fmsResolver,
                    },
                    children: [
                      {
                        path: "",
                        resolve: {
                          customerSuppliedDataResolver,
                          customerSuppliedClientDataResolver,
                        },
                        children: [
                          {
                            path: "",
                            resolve: {
                              removeAfpInPublicContextResolver,
                              afpResolver,
                              bankResolver,
                              benefitsResolver,
                              navResolver,
                              norskpensjonResolver,
                              otherPensionEngagementsResolver,
                              publicPensionResolver,
                              savingsAndPensionResolver,
                              smartAccountResolver,
                              unavailableNorskpensjonResolver,
                              portfolioInsightResolver,
                            },
                            children: [
                              {
                                path: routes[RouteKey.Root],
                                resolve: { navigateToPensionPlanResolver },
                                component: DashboardComponent,
                              },
                              {
                                path: routes[RouteKey.Root],
                                data: {
                                  breadcrumb: {
                                    key: BreadcrumbKey.PensionPlan,
                                  },
                                },
                                children: [
                                  {
                                    path: routes[RouteKey.ContactUs],
                                    component: ContactUsPageComponent,
                                  },
                                  {
                                    path: routes[RouteKey.Concept],
                                    component: ConceptPageComponent,
                                  },
                                  {
                                    path: planRoutes[PlanRouteKey.Root],
                                    loadChildren: (): Promise<Type<PensionPlanModule>> =>
                                      import("./modules/pension-plan/pension-plan.module").then(
                                        (m) => m.PensionPlanModule,
                                      ),
                                  },
                                  {
                                    path: smartAccountRoutes[SmartAccountRouteKey.Root],
                                    loadChildren: (): Promise<Type<SmartAccountModule>> =>
                                      import("./modules/smart-account/smart-account.module").then(
                                        (m) => m.SmartAccountModule,
                                      ),
                                  },
                                  {
                                    path: investmentOptionsRoutes[InvestmentOptionsRouteKey.Root],
                                    data: {
                                      breadcrumb: {
                                        hideAll: true,
                                      },
                                    },
                                    loadChildren: (): Promise<Type<InvestmentOptionsModule>> =>
                                      import("./modules/investment-options/investment-options.module").then(
                                        (m) => m.InvestmentOptionsModule,
                                      ),
                                  },
                                  {
                                    path: articleRoutes[ArticlesRouteKey.Root],
                                    loadChildren: (): Promise<Type<ArticlesModule>> =>
                                      import("./modules/articles/articles.module").then((m) => m.ArticlesModule),
                                  },
                                  {
                                    path: contractsRoutes[ContractsRouteKey.Root],
                                    loadChildren: (): Promise<Type<ContractsModule>> =>
                                      import("./modules/contracts/contracts.module").then((m) => m.ContractsModule),
                                  },
                                  {
                                    path: actionsRoutes[ActionsRouteKey.Root],
                                    loadChildren: (): Promise<Type<ActionsModule>> =>
                                      import("./modules/actions/actions.module").then((m) => m.ActionsModule),
                                  },
                                  {
                                    path: needsRoutes[NeedsRouteKey.Root],
                                    loadChildren: (): Promise<Type<NeedsModule>> =>
                                      import("./modules/needs/needs.module").then((m) => m.NeedsModule),
                                  },
                                  {
                                    path: managePkbRoutes[ManagePkbRouteKey.Root],
                                    loadChildren: (): Promise<Type<ManagePkbPagesModule>> =>
                                      import("./modules/pages/manage-pkb-pages/manage-pkb-pages.module").then(
                                        (m) => m.ManagePkbPagesModule,
                                      ),
                                  },
                                  {
                                    path: savingForPensionRoutes[SavingForPensionKey.Root],
                                    loadChildren: (): Promise<Type<SavingForPensionModule>> =>
                                      import("./modules/saving-for-pension/saving-for-pension.module").then(
                                        (m) => m.SavingForPensionModule,
                                      ),
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "**",
                component: PageNotFoundComponent,
                data: {
                  breadcrumb: {
                    key: BreadcrumbKey.PensionPlan,
                    path: "/",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export const routing = RouterModule.forRoot(appRoutes, {
  enableTracing: false,
  scrollPositionRestoration: "enabled",
});
