import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { PrognosisQueryResult } from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { NorskPensjonQueriesService } from "./api/norskpensjon-queries.service";
import { NorskpensjonRequirementsService } from "./prognoses-services/norskpensjon-requirements.service";

@Injectable({
  providedIn: "root",
})
export class FetchAfpFromNorskpensjonService {
  constructor(
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
    private readonly norskPensjonQueriesService: NorskPensjonQueriesService,
  ) {}

  public fetchPrognosis(args: Graph.QueryNorskpensjonArgs): Observable<PrognosisQueryResult<Graph.Prognosis[]>> {
    return this.norskpensjonRequirementsService
      .getLatestHasAllRequirements()
      .pipe(
        mergeMap((hasAllRequirements) =>
          hasAllRequirements ? this.norskPensjonQueriesService.getAfpPrognosis(args) : of({ data: [] }),
        ),
      );
  }
}
