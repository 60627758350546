import { FormControl } from "@angular/forms";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { FmsKey } from "../../../../../services/fms/fms";
import { getIsNotNullable } from "../../../../../utils/utils";

export interface ConsentRadio {
  title: FmsKey;
  description: FmsKey;
  errorMessage: FmsKey;
  initialConsentValue: boolean | undefined;
  formControl: FormControl;
  radioButtons: RadioButton[];
}

export interface ConsentLabels {
  yesLabel: string;
  noLabel: string;
}

export function getNavConsentRadio(
  initialConsentValue: boolean | undefined,
  labels: ConsentLabels,
  formControl: FormControl,
): ConsentRadio {
  return createConsentRadio(
    "onboardingPublicPensionStep.navConsent.title",
    "onboardingPublicPensionStep.navConsent.description",
    "onboardingPublicPensionStep.navConsent.error",
    initialConsentValue,
    labels,
    formControl,
  );
}

export function getOfaConsentRadio(
  currentConsentValue: boolean | undefined,
  labels: ConsentLabels,
  formControl: FormControl,
): ConsentRadio {
  return createConsentRadio(
    "onboardingPublicPensionStep.ofaConsent.title",
    "onboardingPublicPensionStep.ofaConsent.description",
    "onboardingPublicPensionStep.ofaConsent.error",
    currentConsentValue,
    labels,
    formControl,
  );
}

function createConsentRadio(
  title: FmsKey,
  descriptionKey: FmsKey,
  errorKey: FmsKey,
  initialConsentValue: boolean | undefined,
  labels: ConsentLabels,
  formControl: FormControl,
): ConsentRadio {
  return {
    initialConsentValue,
    title,
    description: descriptionKey,
    errorMessage: errorKey,
    formControl,
    radioButtons: getRadioButtons(initialConsentValue, labels),
  };
}

function getRadioButtons(currentConsent: boolean | undefined, labels: ConsentLabels): RadioButton[] {
  return [
    {
      label: labels.yesLabel,
      value: "true",
      disabled: false,
      checked: getIsNotNullable(currentConsent) ? currentConsent : false,
    },
    {
      label: labels.noLabel,
      value: "false",
      disabled: false,
      checked: getIsNotNullable(currentConsent) ? !currentConsent : false,
    },
  ];
}
