<ng-container *ngIf="isAvailable(value); else Unavailable">
  <span class="value">
    {{ value | currencyFormatterPipe }}
  </span>
  <span class="suffix">{{ suffix }}</span>
</ng-container>

<ng-template #Unavailable>
  <span class="value">
    {{ "engagement.error.amountUnavailable" | fms }}
  </span>
</ng-template>
