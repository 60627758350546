import { HttpErrorResponse } from "@angular/common/http";
import { GenericEngagementOfUnknown, PrognosisTypeOf } from "src/app/models/engagements/generic-engagement.model";
import { EngagementName } from "src/app/models/pension.model";
import { environment } from "src/environments/environment";
import { AnySavingsAndPensionContract } from "../models/engagements/savings-and-pension/savings-and-pension-engagement.model";
import { WithdrawalPeriodsParams } from "../services/api/withdrawal-periods-variables.utils";
import { getNonNullableArray } from "./array";
import { Nullable } from "./utils";

interface Collision extends EngagementName {
  id: string;
}

export class KeycloakUpdateTokenError extends Error {
  name = "KeycloakUpdateTokenError";

  constructor(
    message: string,
    public readonly error: unknown,
  ) {
    super(message);
  }
}

export class KeycloakAuthError extends Error {
  name = "KeycloakAuthError";
}

export class KeycloakAuthRefreshError extends Error {
  name = "KeycloakAuthRefreshError";
}

export class EngagementError extends Error {
  name = "EngagementError";
}

export class AcrLevelTooLowError extends Error {
  name = "AcrLevelTooLowError";
}

export class AdvisorsNotAllowedError extends Error {
  name = "AdvisorsNotAllowedError";
}

export class LongRunningJobWarning extends Error {
  name = "LongRunningJobWarning";
}

export class RedirectWarning extends Error {
  name = "RedirectWarning";
}

export class MissingTrackIdError extends Error {
  name = "MissingTrackIdError";
}

export class MissingCorrelationIdWarning extends Error {
  name = "MissingCorrelationIdWarning";
}

export class ConflictingSimulationParams extends Error {
  name = "ConflictingSimulationParams";

  constructor(
    public simulationParameters: CustomerSuppliedData.ClientData["simulationParametersByEngagement"],
    msg?: string,
  ) {
    super(msg);
  }
}

export class MissingProductNameFmsKey extends Error {
  name = "MissingProductNameFmsKey";

  constructor(
    public contract: AnySavingsAndPensionContract,
    msg?: string,
  ) {
    super(msg);
  }
}

export class PrognosisIdCollision<TEngagement extends GenericEngagementOfUnknown> extends Error {
  name = "PrognosisIdCollision";
  collisions: Collision[];

  constructor(msg: string, engagement: TEngagement, prognoses?: PrognosisTypeOf<TEngagement>[]) {
    super(msg);

    this.collisions = getNonNullableArray(prognoses)
      .map((prognosis) => engagement.withPrognosis(prognosis))
      .map((engagementWithPrognosis) => ({
        ...engagementWithPrognosis.getName(),
        id: engagementWithPrognosis.getIdentifier(),
      }));
  }
}

export class InvalidPensionPlanUrl extends Error {
  name = "InvalidPensionPlanUrl";

  constructor(
    public url: Nullable<string>,
    msg?: string,
  ) {
    super(msg);
  }
}

export class InvalidPrognosisArgumentError extends Error {
  name = "InvalidPrognosisArgumentError";

  constructor(
    public params: WithdrawalPeriodsParams,
    msg?: string,
  ) {
    super(msg);
  }
}

/**
 * Hot reloading a component in SharedModule causes the component to be declared in SharedModules twice.
 * An error from the Angular compiler is printed, but the application seem to run as expected anyway.
 * No solution to prevent the error has been found, and as long as the application is behaving
 * the error message is replaced by a short warning.
 */
export const isHmrSharedModuleError = (error: any): boolean => {
  const hrmSharedModuleErrorRegex = /Error: Uncaught \(in promise\):.+SharedModule and SharedModule/;
  return environment.hmr && hrmSharedModuleErrorRegex.test(error);
};

export const isChunkLoadError = (error: any): boolean => error?.rejection?.name === "ChunkLoadError";

export const isStbEmployeeError = (error: HttpErrorResponse): boolean =>
  error?.message === "You are not allowed to simulate on this customer using CRM";
