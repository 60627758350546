import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";

export const GET_RETIREMENT_AGES_QUERY = gql`
  query getRetirementAgesQuery($input: QueryInput) {
    savingsEngagement(input: $input) {
      simulationParameters {
        retirementAges {
          age
          monthOffset
          date
        }
      }
    }
  }
`;

export interface GetRetirementAgesQuery {
  savingsEngagement: {
    simulationParameters: {
      retirementAges: Graph.RetirementAge[];
    };
  };
}

@Injectable({
  providedIn: "root",
})
export class SimulationParametersQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getRetirementAgesQuery(): Observable<Graph.RetirementAge[]> {
    return this.savingsGraphqlClient
      .query<GetRetirementAgesQuery>({
        query: GET_RETIREMENT_AGES_QUERY,
      })
      .pipe(mergeMap(getApolloResponseData), map(getRetirementAgesFromResponse));
  }
}

function getRetirementAgesFromResponse(query: GetRetirementAgesQuery): Graph.RetirementAge[] {
  return query?.savingsEngagement?.simulationParameters?.retirementAges ?? [];
}
