import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AnyEngagement } from "src/app/services/engagements.service";
import {
  isAfpEngagement,
  isExternalSavingsEngagement,
  isItpUnfundedEngagement,
  isOtherPensionEngagement,
  isPublicPensionEngagement,
} from "src/app/utils/engagement.typeguards";

@Component({
  selector: "app-engagement-details",
  templateUrl: "./engagement-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementDetailsComponent {
  @Input()
  public engagement!: AnyEngagement;
  @Input()
  public hasError!: boolean;
  @Input()
  public showSimulationParameters = true;
  @Input()
  public responsive = true;
  @Input()
  public showPensionPlanEntry = false;
  @Input()
  public showDescription = true;

  public isAfp = isAfpEngagement;
  public isExternalSavings = isExternalSavingsEngagement;
  public isItpUnfunded = isItpUnfundedEngagement;
  public isOtherPension = isOtherPensionEngagement;
  public isPublicPension = isPublicPensionEngagement;

  public isCommon(): boolean {
    return isCommonEngagement(this.engagement);
  }
}

export function isCommonEngagement(engagement: AnyEngagement): boolean {
  return !(
    isAfpEngagement(engagement) ||
    isExternalSavingsEngagement(engagement) ||
    isItpUnfundedEngagement(engagement) ||
    isOtherPensionEngagement(engagement) ||
    isPublicPensionEngagement(engagement)
  );
}
