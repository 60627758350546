import { Inject, Injectable, InjectionToken } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Observable, catchError, combineLatest, iif, map, of, switchMap, tap } from "rxjs";
import {
  AlisEngagement,
  IpaAlisEngagement,
  KapitalforsikringEngagement,
  LivrenteAlisEngagement,
} from "src/app/models/engagements/savings-and-pension/alis-engagement.model";
import {
  EpkEmploymentEngagement,
  EpkEngagement,
  EpkFleksibelEngagement,
} from "src/app/models/engagements/savings-and-pension/epk-engagement.model";
import { FmiEngagement } from "src/app/models/engagements/savings-and-pension/fmi-engagement.model";
import {
  FmiMedGarantiEngagement,
  FripoliseEngagement,
  InnskuddspensjonMedGarantiEngagement,
} from "src/app/models/engagements/savings-and-pension/fripolise-engagement.model";
import {
  HybridMedGarantiEngagement,
  HybridMedInvesteringsvalgEngagement,
  HybridPensjonsbevisEngagement,
} from "src/app/models/engagements/savings-and-pension/hybrid-engagement.model";
import {
  AbstractLinkEngagement,
  EkstrapensjonEmploymentEngagement,
  EkstrapensjonEngagement,
  FondskontoLinkEngagement,
  GarantiEngagement,
  IpaLinkEngagement,
  IpoEngagement,
  IpsEngagement,
  LinkContractAbstract,
  LivrenteLinkEngagement,
} from "src/app/models/engagements/savings-and-pension/link-engagement.model";
import {
  ItpUnfundedEngagement,
  PensionFundUnfundedYtpUnderPayoutEngagement,
  YtpFundedEngagement,
  YtpUnfundedEngagement,
} from "src/app/models/engagements/savings-and-pension/pension-fund-engagement.model";
import {
  EpkFleksibelPkbEngagement,
  PkbEngagement,
} from "src/app/models/engagements/savings-and-pension/pkb-engagement.model";
import {
  AbstractUCITSEngagement,
  AskEngagement,
} from "src/app/models/engagements/savings-and-pension/ucits-engagement.model";
import { YtpEngagement } from "src/app/models/engagements/savings-and-pension/ytp-engagement.model";
import { isGraphQLAccessIssue } from "src/app/modules/graphql-clients/utils/isGraphQLAccessIssue";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
  toFetchPrognosisResult,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import {
  SimulationErrorCmsKey,
  createGraphSimulationError,
} from "src/app/services/prognoses-services/simulation-result.creators";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { getNonNullableArray } from "src/app/utils/array";
import {
  isEpkEngagement,
  isEpkFleksibelEngagement,
  isHybridMedInvesteringsvalgEngagement,
  isItpUnfundedEngagement,
} from "src/app/utils/engagement.typeguards";
import { MissingSavingsAndPensionPrognosisError } from "src/app/utils/prognosis-errors";
import { isRequestedByAdvisor } from "src/app/utils/storebrand-staging";
import { getIsNotNullable, getIsNullable } from "src/app/utils/utils";
import { PrognosisQueriesService } from "../api/prognosis-queries.service";
import { AllContractsQuery, SavingsAndPensionQueriesService } from "../api/savings-and-pension-queries.service";
import { SimulationParametersQueriesService } from "../api/simulation-parameters-queries.service";
import { WithdrawalPeriodsParams } from "../api/withdrawal-periods-variables.utils";
import { CompressionLimitService } from "../compression-limit.service";
import { ErrorCategory, ErrorContainer } from "../errors.service";
import { FmsKey } from "../fms/fms";
import { IncomeService } from "../income/income.service";
import { GraphQLError } from "graphql/error";

type SavingsPrognosisParams = Omit<WithdrawalPeriodsParams, "retirementAges"> &
  Pick<Graph.FullWithdrawalInput, "startMonthOffset"> &
  Pick<Graph.PartialWithdrawalInput, "startMonthOffset"> &
  Pick<Graph.PrognosisParametersInput, "compressionLimit" | "investmentProfileInPayoutPeriod">;

export type FetchPrognosesParams = CustomerSuppliedData.SimulationParametersByEngagement<SavingsPrognosisParams>[1];

export type AnySavingsAndPensionEngagement =
  | AlisEngagement
  | EpkEngagement
  | EpkFleksibelEngagement
  | EpkFleksibelPkbEngagement
  | ItpUnfundedEngagement
  | AbstractLinkEngagement<LinkContractAbstract>
  | YtpFundedEngagement
  | YtpUnfundedEngagement
  | FmiEngagement
  | YtpEngagement
  | PensionFundUnfundedYtpUnderPayoutEngagement
  | FripoliseEngagement
  | HybridMedInvesteringsvalgEngagement
  | HybridMedGarantiEngagement
  | HybridPensjonsbevisEngagement
  | PkbEngagement
  | EpkEmploymentEngagement
  | AbstractUCITSEngagement;

export const SAVINGS_CONFIG: ServiceConfig = {
  name: "SavingsService",
  fmsKey: "savings",
};

export const DEPRECATED_USE_SAVINGS_GRAPHQL_TOKEN = new InjectionToken("Deprecated UseSavingsGraphql toggle", {
  factory(): boolean {
    return true;
  },
});

@Injectable({
  providedIn: "root",
})
export class SavingsAndPensionService extends AbstractPrognosisFetchService<
  AnySavingsAndPensionEngagement,
  SavingsPrognosisParams
> {
  constructor(
    commonParametersService: CommonParametersService,
    fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly prognosisQueriesService: PrognosisQueriesService,
    private readonly savingsAndPensionQueriesService: SavingsAndPensionQueriesService,
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly incomeService: IncomeService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
    private readonly compressionLimitService: CompressionLimitService,
    private readonly clientDataService: ClientDataService,
    /**
     * All the unit tests for payoutPlan.service are written with the deprecated "UseSavingsGraphql"
     * toggle false in mind. Setting the value true by default will break all the tests and require rewrite.
     * Add this temporary flag to simulate the removed feature toggle.
     * Remove when unit tests in payoutPlan.service is rewriten.
     *
     */
    @Inject(DEPRECATED_USE_SAVINGS_GRAPHQL_TOKEN)
    private readonly useSavingsGraphql: boolean = true,
  ) {
    super(SAVINGS_CONFIG, commonParametersService, fetchPrognosesRunningJobsService);

    this.engagements$ = this.useSavingsGraphql ? this.engagements$ : of([]);
  }

  public override isStorebrandOnly(): boolean {
    return true;
  }

  public fetchPrognosis(
    engagement: AnySavingsAndPensionEngagement,
    params: FetchPrognosesParams,
  ): Observable<FetchPrognosisResult<AnySavingsAndPensionEngagement>> {
    const regularPrognosis$ = this.getPrognosis(engagement, params);

    if (engagement.getCompressionLimits().length === 0) {
      return regularPrognosis$;
    }

    return getPrognosisWithSanitizedAndStoredCompressionLimit(
      engagement,
      params,
      regularPrognosis$,
      this.compressionLimitService.storeIfCompressionLimitAffectsPrognosis.bind(this.compressionLimitService),
      this.getPrognosisByCompression.bind(this),
    );
  }

  protected _fetchEngagements(): Observable<AnySavingsAndPensionEngagement[]> {
    return this.savingsAndPensionQueriesService.getContracts().pipe(
      tap((queryResult) => {
        this.registerPartialContractErrors(queryResult);
      }),
      map(
        ({
          data: {
            savingsEngagement: {
              askAccounts,
              ekstrapensjonContracts,
              ekstrapensjonEmploymentContracts,
              fondskontoLinkContracts,
              garantiContracts,
              ipaLinkContracts,
              kollektivLivrenteContracts,
              ipoContracts,
              ipsContracts,
              livrenteLinkContracts,
              ipaAlisContracts,
              kapitalforsikringAlisContracts,
              livrenteAlisContracts,
              epkContracts,
              pkbContracts,
              epkFleksibelContracts,
              epkFleksibelPkbContracts,
              epkEmploymentContracts,
              fmiContracts,
              fmiMedGarantiContracts,
              ytpContracts,
              fripoliseContracts,
              hybridMedInvesteringsvalgContracts,
              hybridMedGarantiContracts,
              hybridPensjonsbevisContracts,
              pensionFundUnfundedItpContracts,
              pensionFundUnfundedYtpContracts,
              pensionFundYtpContracts,
              pensionFundUnfundedYtpUnderPayoutContracts,
              innskuddspensjonMedGarantiContracts,
            },
            headers,
          },
        }) =>
          [
            ...getNonNullableArray(askAccounts).map((c) => new AskEngagement(c)),
            ...getNonNullableArray(ekstrapensjonContracts).map((c) => new EkstrapensjonEngagement(c)),
            ...getNonNullableArray(ekstrapensjonEmploymentContracts).map(
              (c) => new EkstrapensjonEmploymentEngagement(c),
            ),
            ...getNonNullableArray(fondskontoLinkContracts).map((c) => new FondskontoLinkEngagement(c)),
            ...getNonNullableArray(garantiContracts).map((c) => new GarantiEngagement(c)),
            ...getNonNullableArray(ipaLinkContracts).map((c) => new IpaLinkEngagement(c)),
            ...getNonNullableArray(kollektivLivrenteContracts).map((c) => new IpaLinkEngagement(c)),
            ...getNonNullableArray(ipoContracts).map((c) => new IpoEngagement(c)),
            ...getNonNullableArray(ipsContracts).map((c) => new IpsEngagement(c)),
            ...getNonNullableArray(livrenteLinkContracts).map((c) => new LivrenteLinkEngagement(c)),
            ...getNonNullableArray(ipaAlisContracts).map((c) => new IpaAlisEngagement(c)),
            ...getNonNullableArray(kapitalforsikringAlisContracts).map((c) => new KapitalforsikringEngagement(c)),
            ...getNonNullableArray(livrenteAlisContracts).map((c) => new LivrenteAlisEngagement(c)),
            ...getNonNullableArray(epkContracts).map((c) => new EpkEngagement(c)),
            ...getNonNullableArray(pkbContracts).map((c) => new PkbEngagement(c)),
            ...getNonNullableArray(epkFleksibelContracts).map((c) => new EpkFleksibelEngagement(c)),
            ...getNonNullableArray(epkFleksibelPkbContracts).map((c) => new EpkFleksibelPkbEngagement(c)),
            ...getNonNullableArray(epkEmploymentContracts).map((c) => new EpkEmploymentEngagement(c)),
            ...getNonNullableArray(fmiContracts).map((c) => new FmiEngagement(c)),
            ...getNonNullableArray(fmiMedGarantiContracts).map((c) => new FmiMedGarantiEngagement(c)),
            ...getNonNullableArray(ytpContracts).map((c) => new YtpEngagement(c)),
            ...getNonNullableArray(fripoliseContracts).map((c) => new FripoliseEngagement(c)),
            ...getNonNullableArray(hybridMedInvesteringsvalgContracts).map(
              (c) => new HybridMedInvesteringsvalgEngagement(c),
            ),
            ...getNonNullableArray(hybridMedGarantiContracts).map((c) => new HybridMedGarantiEngagement(c)),
            ...getNonNullableArray(hybridPensjonsbevisContracts).map((c) => new HybridPensjonsbevisEngagement(c)),
            ...getNonNullableArray(pensionFundUnfundedItpContracts).map((c) => new ItpUnfundedEngagement(c)),
            ...getNonNullableArray(pensionFundUnfundedYtpContracts).map((c) => new YtpUnfundedEngagement(c)),
            ...getNonNullableArray(pensionFundYtpContracts).map((c) => new YtpFundedEngagement(c)),
            ...getNonNullableArray(pensionFundUnfundedYtpUnderPayoutContracts).map(
              (c) => new PensionFundUnfundedYtpUnderPayoutEngagement(c),
            ),
            ...getNonNullableArray(innskuddspensjonMedGarantiContracts).map(
              (c) => new InnskuddspensjonMedGarantiEngagement(c),
            ),
          ].map((engagement) => engagement.withContractTraceReference(headers.correlationId)),
      ),
    );
  }

  protected prognosisSimParams$(
    engagementParams?: Partial<CustomerSuppliedData.SimulationParameters>,
  ): Observable<Partial<SavingsPrognosisParams>> {
    return combineLatest([
      this.incomeService.annualGrossIncome$,
      this.commonParametersService.postPensionPartTimePercent$,
      this.startPayoutAgeService.getStartPayoutAge(),
      this.clientDataService.simulationParametersPartialWithdrawalEnable$.pipe(map(({ enable }) => enable)),
      this.clientDataService.simulationParameters$,
      this.simulationParametersQueriesService
        .getRetirementAgesQuery()
        .pipe(catchError(() => of([] as Graph.RetirementAge[]))),
    ]).pipe(
      map(([salary, partTimePercent, _startPayoutAge, isPartialWithdrawal, simulationParameters, retirementAges]) => {
        const { startMonthOffset, startPayoutAge } = toAgeAndMonthOffset(
          retirementAges,
          engagementParams?.startPayoutAge ?? _startPayoutAge,
        );

        if (isPartialWithdrawal) {
          const { startPayoutAgePartialWithdrawal, withdrawalPercentage, partTimePercentage } = simulationParameters;

          const { startMonthOffset: startMonthOffsetWithPartialWithdrawal } = toAgeAndMonthOffset(
            retirementAges,
            startPayoutAgePartialWithdrawal ?? _startPayoutAge,
          );

          return {
            salary,
            partTimePercent, // post pension part time percentage
            startPayoutAge,
            startMonthOffset: startMonthOffsetWithPartialWithdrawal,
            startPayoutAgePartialWithdrawal,
            withdrawalPercentage, // withdrawal percentage for partial withdrawal
            partTimePercentage, // part time percentage for partial withdrawal
          };
        }

        return {
          salary,
          partTimePercent,
          startPayoutAge,
          startMonthOffset,
        };
      }),
    );
  }

  protected composeFailedPrognosis(
    _: AnySavingsAndPensionEngagement,
    isAgeHigherThanMaxSimAge?: boolean,
  ): Graph.Prognosis {
    const key = isAgeHigherThanMaxSimAge ? SimulationErrorCmsKey.AboveMaxAgeError : SimulationErrorCmsKey.GenericError;

    return {
      simulationStatus: createGraphSimulationError(key),
    };
  }

  protected composeMissingPrognosisError(
    engagement: AnySavingsAndPensionEngagement,
  ): MissingSavingsAndPensionPrognosisError {
    return new MissingSavingsAndPensionPrognosisError(engagement);
  }

  private getPrognosis(
    engagement: AnySavingsAndPensionEngagement,
    params: SavingsPrognosisParams,
  ): Observable<FetchPrognosisResult<AnySavingsAndPensionEngagement>> {
    const prognosisParametersInput = mapPrognosisParamsToSimulationParams(params, engagement);

    return this.prognosisQueriesService.getPrognosis(engagement.getId(), prognosisParametersInput).pipe(
      map((prognosis) => toFetchPrognosisResult(prognosis, prognosisParametersInput)),
      catchError(() => of({ prognoses: [] })),
    );
  }

  private registerPartialContractErrors(query: ApolloQueryResult<AllContractsQuery>): void {
    const errorsLength = query?.errors?.length ?? 0;
    const isDataEmpty = getIsNullable(query?.data);
    const hasPartialDataWithError = errorsLength > 0 && !isDataEmpty;

    const convertedErrors = query.errors?.map(
      (error) =>
        new GraphQLError(error.message, {
          extensions: error.extensions,
        }),
    );

    const shouldSkipError = isGraphQLAccessIssue(convertedErrors) && isRequestedByAdvisor();

    if (hasPartialDataWithError && !shouldSkipError) {
      const title = `errors.datafetch.partial.${this.config.fmsKey}.title` as FmsKey;
      const message = `errors.datafetch.partial.${this.config.fmsKey}` as FmsKey;

      const error: ErrorContainer = {
        category: ErrorCategory.Engagement,
        text: {
          title,
          message,
        },
      };

      this.addEngagementHttpError(error);
    }
  }

  private getPrognosisByCompression(
    engagement: AnySavingsAndPensionEngagement,
    params: SavingsPrognosisParams,
    compressionLimit: number | null,
  ): Observable<FetchPrognosisResult<AnySavingsAndPensionEngagement>> {
    const modifiedParams = { ...params, compressionLimit };
    return this.getPrognosis(engagement, modifiedParams);
  }
}

/**
 * @param retirementAges the legal retirement ages provided by savings-graphql
 * @param startPayoutAge the chosen simulation start age
 * @returns
 */
export function toAgeAndMonthOffset(
  retirementAges: Graph.RetirementAge[],
  startPayoutAge: number,
): Pick<FetchPrognosesParams, "startPayoutAge" | "startMonthOffset"> {
  const retirementAge = retirementAges.find((el) => el.age === startPayoutAge);
  const firstMonthOffset = retirementAge?.monthOffset ?? 0;

  return { startPayoutAge, startMonthOffset: firstMonthOffset };
}

function getEndAge(params: FetchPrognosesParams, engagement: AnySavingsAndPensionEngagement): number | null {
  // This agreement is usually lifelong but sometimes it can be fixed to end at a certain date.
  // By setting endAge to null we let the prognosis service determine the right endAge.
  if (isHybridMedInvesteringsvalgEngagement(engagement)) {
    return null;
  }
  return params?.startPayoutAge && params?.durationYears ? params.startPayoutAge + params.durationYears : null;
}

function mapPrognosisParamsToSimulationParams(
  params: FetchPrognosesParams,
  engagement: AnySavingsAndPensionEngagement,
): Graph.PrognosisInput["parameters"] {
  const isPartialWithdrawal = getIsNotNullable(params.startPayoutAgePartialWithdrawal);
  const hasPartialWithdrawalSupport = isEpkEngagement(engagement) || isEpkFleksibelEngagement(engagement);

  if (isPartialWithdrawal && hasPartialWithdrawalSupport) {
    return {
      compressionLimit: params.compressionLimit,
      investmentProfileInPayoutPeriod: params.investmentProfileInPayoutPeriod,
      fullWithdrawal: {
        startAge: params.startPayoutAge,
        startMonthOffset: 0,
      },
      partialWithdrawal: {
        partTimePercentage: params.partTimePercentage,
        startAge: params.startPayoutAgePartialWithdrawal,
        startMonthOffset: params.startMonthOffset,
        withdrawalPercentage: params.withdrawalPercentage,
      },
    };
  }

  const endAge = getEndAge(params, engagement);

  // These kinds of contracts can't be paid out until the employee stops working,
  // so we use the partial withdrawal start age only if the employee is not planning
  // on working part time during the partial payout period
  if (isPartialWithdrawal && isItpUnfundedEngagement(engagement)) {
    const isWorkingPartTime = getIsNotNullable(params.partTimePercentage) && params.partTimePercentage > 0;

    return {
      compressionLimit: params.compressionLimit,
      investmentProfileInPayoutPeriod: params.investmentProfileInPayoutPeriod,
      fullWithdrawal: {
        startAge: isWorkingPartTime
          ? params.startPayoutAge
          : params.startPayoutAgePartialWithdrawal ?? params.startPayoutAge,
        startMonthOffset: params.startMonthOffset,
        endAge,
        endMonthOffset: 0,
      },
    };
  }

  return {
    compressionLimit: params.compressionLimit,
    investmentProfileInPayoutPeriod: params.investmentProfileInPayoutPeriod,
    fullWithdrawal: {
      startAge: isPartialWithdrawal
        ? params.startPayoutAgePartialWithdrawal ?? params.startPayoutAge // for non-supported partial withdrawal engagements, use startPayoutAgePartialWithdrawal as startAge instead
        : params.startPayoutAge,
      startMonthOffset: params.startMonthOffset,
      endAge,
      endMonthOffset: 0,
    },
  };
}

export function getPrognosisWithSanitizedAndStoredCompressionLimit(
  engagement: AnySavingsAndPensionEngagement,
  params: FetchPrognosesParams,
  regularPrognosis$: Observable<FetchPrognosisResult<AnySavingsAndPensionEngagement>>,
  storeFunction: (id: string, affectsPrognosis: boolean) => void,
  getPrognosisByCompression: (
    _engagement: AnySavingsAndPensionEngagement,
    _params: SavingsPrognosisParams,
    compressionLimit: number | null,
  ) => Observable<FetchPrognosisResult<AnySavingsAndPensionEngagement>>,
): Observable<FetchPrognosisResult<AnySavingsAndPensionEngagement>> {
  const prognosisWithHalfGCompression$ = getPrognosisByCompression(engagement, params, 0.5);
  const prognosisWithNullCompression$ = getPrognosisByCompression(engagement, params, null);
  const prognosisWithThirdGCompression$ = getPrognosisByCompression(engagement, params, 0.3);
  const compressionPrognosesCacheWarmup = [prognosisWithNullCompression$, prognosisWithThirdGCompression$];

  return combineLatest([prognosisWithHalfGCompression$, ...compressionPrognosesCacheWarmup]).pipe(
    switchMap(([{ prognoses }]) => {
      const isCompressed = engagement.withPrognosis(prognoses[0]).isCompressed();
      storeFunction(engagement.getId(), isCompressed);

      return iif(() => isCompressed, regularPrognosis$, prognosisWithNullCompression$);
    }),
  );
}
