import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from "@angular/core";
import { Observable, combineLatest, filter, map, of, take } from "rxjs";
import { listAnimation } from "src/app/animations";
import { GenericEngagementOfUnknown } from "src/app/models/engagements/generic-engagement.model";
import { AfpPromotorService } from "src/app/services/afp-promotor.service";
import { ConsentService } from "src/app/services/consent.service";
import { ContractItem, ContractListService } from "src/app/services/contract-list.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import {
  isEngagementFromEmployment,
  isEngagementFromPublic,
  isEngagementFromSavings,
} from "src/app/utils/engagement.utils";
import { Nullable } from "src/app/utils/utils";
import { EngagementListRerenderTriggerService } from "../../../../services/engagement-list-rerender-trigger-service";
import { routes } from "../../../saving-for-pension/routes";

interface HeaderText {
  title: string;
}

@Component({
  selector: "app-contracts-list",
  templateUrl: "./contracts-list.component.html",
  styleUrls: ["./contracts-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation],
})
export class ContractsListComponent {
  @Input()
  public readonly waitForPrognosis!: boolean;

  public readonly readyToRender$: Observable<boolean>;
  public showAddAfpCard$ = this.afpPromotorService.getCanPromoteAfp();
  public readonly savingsPageRouterLink = `/${routes.Root}`;

  public trackById: TrackByFunction<ContractItem> = engagementTrackBy;

  public readonly savingsContractItems$: Observable<ContractItem[]>;
  public readonly employmentContractItems$: Observable<ContractItem[]>;
  public readonly publicContractItems$: Observable<ContractItem[]>;

  public readonly showPublicContracts$: Observable<boolean>;

  constructor(
    private readonly fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    private readonly fmsService: FmsService,
    private readonly profileService: ProfileService,
    private readonly contractListService: ContractListService,
    private readonly consentService: ConsentService,
    private readonly afpPromotorService: AfpPromotorService,
    private readonly keycloakService: KeycloakService,
    private readonly rerenderService: EngagementListRerenderTriggerService,
  ) {
    this.savingsContractItems$ = this.contractListService.contractItems$.pipe(
      map((contractItems) => contractItems.filter((contractItem) => isEngagementFromSavings(contractItem.contract))),
    );

    this.employmentContractItems$ = combineLatest([
      this.contractListService.contractItems$,
      this.profileService.hasAfp$,
      ...this.rerenderService.triggers$,
    ]).pipe(
      map(([contractItems, hasAfp]) =>
        contractItems.filter((contractItem) => isEngagementFromEmployment(contractItem.contract, hasAfp, false)),
      ),
    );

    this.publicContractItems$ = this.contractListService.contractItems$.pipe(
      map((contractItems) => contractItems.filter((contractItem) => isEngagementFromPublic(contractItem.contract))),
    );

    this.showPublicContracts$ = combineLatest([
      this.consentService.hasNorskPensjonConsent$.pipe(map(Boolean)),
      this.keycloakService.isAuthLevel3or4$,
    ]).pipe(map(([hasNpConsent, isAuth]) => hasNpConsent && isAuth));

    if (this.waitForPrognosis) {
      this.readyToRender$ = of(this.waitForPrognosis);
    } else {
      this.readyToRender$ = this.fetchPrognosesRunningJobsService.isCurrentYearLoaded$.pipe(
        filter((isReady) => isReady),
        take(1),
      );
    }
  }

  public getHeaderText(override: Partial<HeaderText> = {}): HeaderText {
    const defaultHeaderText = this.fmsService.instant<HeaderText>("contractList.defaultHeaderText");

    return {
      ...defaultHeaderText,
      ...override,
    };
  }

  public toAnyEngagement(engagement: GenericEngagementOfUnknown): AnyEngagement {
    return engagement as AnyEngagement;
  }

  public openLink(urlFmsKey: string): void {
    window.open(this.fmsService.instant(urlFmsKey));
  }
}

function engagementTrackBy(_: number, contractItem: ContractItem): Nullable<string> {
  return contractItem.contract.getIdentifier();
}
