import { Injectable } from "@angular/core";
import { uniq } from "lodash-es";
import { catchError, combineLatest, distinctUntilChanged, map, Observable, of, tap } from "rxjs";
import {
  DEFAULT_PREFETCH_BOTTOM,
  DEFAULT_PREFETCH_TOP,
  DEFAULT_SIMULATION_AGE,
} from "src/app/constants/business.constants";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { SimulationParametersQueriesService } from "src/app/services/api/simulation-parameters-queries.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { ErrorCategory, ErrorsService } from "src/app/services/errors.service";
import { RemoteConfigKey, RemoteConfigService } from "src/app/services/remote-config.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";
import { getFirstItem, getIsEmpty } from "src/app/utils/array";
import { getIsNotNullable } from "src/app/utils/utils";
import { NAV_ENGAGEMENT_ID } from "../models/engagements/nav-engagement.model";
import { PUBLIC_PENSION_ENGAGEMENT_ID } from "../models/engagements/public-pension-engagement.model";

@Injectable({
  providedIn: "root",
})
export class StartPayoutAgeService {
  constructor(
    private readonly clientDataService: ClientDataService,
    private readonly simulationParametersQueriesService: SimulationParametersQueriesService,
    private readonly remoteConfigService: RemoteConfigService,
    private readonly errorService: ErrorsService,
  ) {}

  public getStartPayoutAge(): Observable<number> {
    const folketrygdStartPayoutAge$ = combineLatest([
      this.getNavStartPayoutAge(),
      this.getPublicPensionPayoutAge(),
    ]).pipe(
      map((items) => items.filter(getIsNotNullable)),
      map((items) => (items.length > 0 ? Math.min(...items) : undefined)),
    );

    return combineLatest([
      this.clientDataService.startPayoutAge$,
      this.getRetirementAges(),
      folketrygdStartPayoutAge$,
    ]).pipe(
      map(([userSelectedStartPayoutAge, retirementAges, folketrygdStartPayoutAge]) => {
        if (getIsEmpty(retirementAges)) {
          return DEFAULT_SIMULATION_AGE;
        }

        const retirementAge = folketrygdStartPayoutAge ?? userSelectedStartPayoutAge ?? DEFAULT_SIMULATION_AGE;

        if (!getIsRetirementAge(retirementAge, retirementAges)) {
          return getFirstItem(retirementAges)?.age ?? DEFAULT_SIMULATION_AGE;
        }

        return retirementAge;
      }),
      distinctUntilChanged(),
      tap(() => {
        this.errorService.clearErrorsByCategory(ErrorCategory.Prognosis);
      }),
    );
  }

  public getStartPayoutAgeDate(): Observable<Date | undefined> {
    return combineLatest([this.getStartPayoutAge(), this.getRetirementAges()]).pipe(
      map(applyArrayTo(getDateFromRetirementAge)),
    );
  }

  public getFirstPayoutAge(): Observable<number> {
    return this.getRetirementAges().pipe(
      map(getFirstItem),
      map((retirementAge) => retirementAge?.age || DEFAULT_SIMULATION_AGE),
    );
  }

  public getStartPayoutAgeRange(): Observable<number[]> {
    return this.getRetirementAges().pipe(
      map((retirementAges) => {
        const allAges = retirementAges.map(({ age }) => age).filter(getIsNotNullable);

        return uniq(allAges);
      }),
    );
  }

  public getStartPayoutAgeRangeForPrefetch(): Observable<number[]> {
    const startPayoutAge$ = this.getStartPayoutAge();
    const startPayoutAgeRange$ = this.getStartPayoutAgeRange();
    const config$ = this.remoteConfigService.get$(RemoteConfigKey.Prefetching);

    return combineLatest([startPayoutAge$, startPayoutAgeRange$, config$]).pipe(
      map(([startPayoutAge, startPayoutAgeRange, config]) => {
        const ageIndex = startPayoutAgeRange.indexOf(startPayoutAge);
        const upperBound = Math.abs(config?.upperBound ?? DEFAULT_PREFETCH_TOP);
        const lowerBound = Math.abs(config?.lowerBound ?? DEFAULT_PREFETCH_BOTTOM);

        return startPayoutAgeRange.slice(
          Math.max(ageIndex - lowerBound, 0),
          Math.min(ageIndex + upperBound, startPayoutAgeRange.length) + 1,
        );
      }),
    );
  }

  private getRetirementAges(): Observable<Graph.RetirementAge[]> {
    return this.simulationParametersQueriesService
      .getRetirementAgesQuery()
      .pipe(catchError(() => of(<Graph.RetirementAge[]>[])));
  }

  private getNavStartPayoutAge(): Observable<number | undefined> {
    return this.clientDataService.simulationParametersByEngagement$.pipe(
      map(getNavStartPayoutAgeFromSimulationParametersByEngagement),
    );
  }

  private getPublicPensionPayoutAge(): Observable<number | undefined> {
    return this.clientDataService.simulationParametersByEngagement$.pipe(
      map(getPublicPensionStartPayoutAgeFromSimulationParametersByEngagement),
    );
  }
}

const makeGetStartPayoutAgeFromSimulationParametersByEngagement =
  (id: string) =>
  (items: CustomerSuppliedData.SimulationParametersByEngagement<{}>[]): number | undefined => {
    return items
      .filter((item) => item[0] === id)
      .map((item) => item[1]?.startPayoutAge)
      .at(0);
  };

const getNavStartPayoutAgeFromSimulationParametersByEngagement =
  makeGetStartPayoutAgeFromSimulationParametersByEngagement(NAV_ENGAGEMENT_ID);

const getPublicPensionStartPayoutAgeFromSimulationParametersByEngagement =
  makeGetStartPayoutAgeFromSimulationParametersByEngagement(PUBLIC_PENSION_ENGAGEMENT_ID);

function getIsRetirementAge(startPayoutAge: number, retirementAges: Graph.RetirementAge[]): boolean {
  return retirementAges.some((retirementAge) => retirementAge.age === startPayoutAge);
}

function getDateFromRetirementAge(startPayoutAge: number, retirementAges: Graph.RetirementAge[]): Date | undefined {
  const date = retirementAges.find((retirementAge) => retirementAge.age === startPayoutAge)?.date;

  return date ? new Date(date) : undefined;
}
