import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Observable, Subject, combineLatest, map } from "rxjs";
import { filter, first, takeUntil, tap } from "rxjs/operators";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { generalNumberInputPipe } from "src/app/utils/rxjs/pipes";
import { HasPublicPensionInStorebrandService } from "../../../../../services/api/has-public-pension-in-storebrand.service";
import { CommonParametersService } from "../../../../../services/common-parameters.service";
import { FmsKey } from "../../../../../services/fms/fms";
import { IncomeService } from "../../../../../services/income/income.service";
import { getIsNotNullable } from "../../../../../utils/utils";
import { SalaryForm } from "../../onboarding/onboarding-salary-step/onboarding-salary-step.component";

@Component({
  selector: "app-yearly-income-gross-form-field",
  templateUrl: "./yearly-income-gross-form-field.component.html",
})
export class YearlyIncomeGrossFormFieldComponent implements OnInit, OnDestroy {
  @Input()
  public formGroup?: FormGroup<SalaryForm>;
  @Input()
  public size: "small" | "medium" | "large" = "medium";
  @Input()
  public isOnboardingContext = false;

  @Output()
  public valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public keydownEnter = new EventEmitter<boolean>();

  public hint$: Observable<FmsKey>;
  public formControl = new FormControl<number | undefined>(undefined, {
    nonNullable: true,
    validators: [Validators.required],
  });
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly profileService: ProfileService,
    private readonly hasPublicPensionInStorebrandService: HasPublicPensionInStorebrandService,
    private readonly commonParametersService: CommonParametersService,
    private readonly incomeService: IncomeService,
  ) {
    this.hint$ = this.incomeService.hint$.pipe(filter(() => !this.isOnboardingContext));
  }

  public ngOnInit(): void {
    this.hasPublicPensionInStorebrandService.isPublicContext$.subscribe((next) =>
      next ? this.formControl.disable() : this.formControl.enable(),
    );

    if (this.formGroup) {
      this.formGroup.addControl("yearlyIncomeGross", this.formControl);
    }

    if (!this.isOnboardingContext) {
      this.bindChangeListener(this.formControl);
    }

    /**
     * Public context gets precedence. This will be incorrect for any user with a private income
     * in addition to the public income, but the complexity of this is not worth the effort so we
     * simplify.
     */
    combineLatest([this.commonParametersService.annualGrossIncome$, this.incomeService.annualGrossIncome$])
      .pipe(
        first(),
        map(([userGivenIncome, currentIncomeFromAllSources]) => {
          if (this.isOnboardingContext) {
            return getIsNotNullable(userGivenIncome) ? userGivenIncome : currentIncomeFromAllSources;
          }

          return currentIncomeFromAllSources;
        }),
        tap((annualGrossIncomeOrUndefined) => {
          this.formControl.setValue(annualGrossIncomeOrUndefined);
          this.valid.emit(this.formControl.valid);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  private bindChangeListener(formControl: UntypedFormControl): void {
    formControl.valueChanges
      .pipe(generalNumberInputPipe())
      .pipe(takeUntil(this.destroy$))
      .subscribe((annualGrossIncome) => {
        this.valid.emit(formControl.valid);

        if (formControl.dirty) {
          this.incomeService.setUserSelectedIncomeSource("USER");
          this.profileService.setProfileSalaryYearGross(annualGrossIncome);
        }
      });
  }
}
