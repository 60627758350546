import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { Monitoring } from "src/app/utils/monitoring";
import { getIsNullable } from "src/app/utils/utils";
import { Action } from "../actions/actions.component";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

export interface ActionButton {
  text: string | undefined;
  trackId: string | undefined;
  icon: string | undefined;
}

export enum ActionStyling {
  RegularAction = "regularAction",
  NoBorderOrPadding = "noBorderOrPadding",
}

@Component({
  selector: "app-action-card",
  templateUrl: "./action-card.component.html",
  styleUrls: ["./action-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCardComponent implements OnInit, OnDestroy {
  @Input()
  public title!: string;

  @Input()
  public icon?: string;

  @Input()
  public content!: string;

  @Input()
  public outlinedPrimaryButton = true;

  @Input()
  public primaryButton?: ActionButton;

  @Input()
  public secondaryButton?: ActionButton;

  @Input()
  public readonly styling?: ActionStyling = ActionStyling.RegularAction;

  @Input()
  public action!: Action;

  @Output()
  public primaryButtonClick = new EventEmitter<void>();

  @Output()
  public secondaryButtonClick = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this.logMissingButtonProperties(this.primaryButton, "primaryButton");
    this.logMissingButtonProperties(this.secondaryButton, "secondaryButton");
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  private logMissingButtonProperties(button: ActionButton | undefined, buttonName: string): void {
    if (getIsNullable(button)) {
      return;
    } else if ([button.text, button.trackId].includes(undefined)) {
      Monitoring.error(`${this.action.key} action card has invalid ${buttonName}`, {
        ignore: isStageLocalhost(),
        extras: {
          values: {
            text: button.text,
            trackId: button.trackId,
          },
        },
      });
    }
  }
}
