import { Injectable } from "@angular/core";
import { configureDatadog } from "@storebrand-digital/common-datadog";
import { Environment } from "@storebrand-digital/common-datadog/dist/environment";
import { environment } from "../../environments/environment";
import { StbStage, getStbStage, isStageLocalhost } from "../utils/storebrand-staging";
import packageJSON from "../../../package.json";
import { applicationId, clientToken } from "../../environments/envconfig";

@Injectable({
  providedIn: "root",
})
export class DatadogService {
  public init(): void {
    if (!isStageLocalhost()) {
      configureDatadog({
        clientPublicToken: clientToken,
        environment: getDatadogEnvironment(),
        appVersion: environment.appVersion || "",
        appId: applicationId,
        appName: packageJSON.name,
        configureLogging: true,
        forwardConsoleLogs: ["error", "warn"],
        trackViewsManually: false,
      });
    }
  }
}

function getDatadogEnvironment(): Environment {
  const stage = getStbStage();
  switch (stage) {
    case StbStage.Localhost:
      return "development";
    case StbStage.Test:
    case StbStage.TestStabil:
      return "client-t";
    case StbStage.Produksjon:
    default:
      return "production";
  }
}
