<ng-container *appLet="isLoaded$ | async as isLoaded">
  <ng-container *ngIf="showInBox; else summaryColumns">
    <div class="user-summary-box full-page-width-limiter">
      <div class="text-wrapper" *ngIf="!isLoaded">
        <app-text-skeleton type="green"></app-text-skeleton>
        <app-text-skeleton type="green"></app-text-skeleton>
        <app-text-skeleton type="green"></app-text-skeleton>
        <app-text-skeleton type="green"></app-text-skeleton>
        <app-text-skeleton type="green"></app-text-skeleton>
        <app-text-skeleton type="green"></app-text-skeleton>
      </div>
      <div class="text-wrapper" *ngIf="isLoaded">
        <div class="summary-row" *ngIf="userAge$ | async as age">
          <p [innerHTML]="'userSummaryBox.age' | fms: { args: { age } }" data-hj-suppress></p>
        </div>

        <div class="summary-row" *ngIf="pensionAgeArgs$ | async as pensionAgeArgs">
          <p [innerHTML]="'userSummaryBox.timeUntilPensionAge' | fms: { args: pensionAgeArgs }" data-hj-suppress></p>
        </div>

        <div class="summary-row">
          <p [innerHTML]="numberOfEngagementsText$ | async"></p>
        </div>

        <div class="summary-row">
          <p [innerHTML]="hasSavings$ | async"></p>
        </div>

        <div class="summary-row" *ngIf="isQualifiedForSmartAccount$ | async">
          <p [innerHTML]="'userSummaryBox.smartAccount' | fms"></p>
        </div>

        <div class="summary-row" *ngIf="hasAnnualSalary$ | async">
          <p [innerHTML]="annualSalaryText$ | async" data-hj-suppress></p>
        </div>

        <div class="summary-row" *ngIf="hasAfp$ | async">
          <p [innerHTML]="'userSummaryBox.afp' | fms" data-hj-suppress></p>
        </div>

        <div class="summary-row" *ngIf="averagePercentOfSalay$ | async as averagePercentOfSalay">
          <p [innerHTML]="'userSummaryBox.averagePercentOfSalary' | fms: { args: { averagePercentOfSalay } }"></p>
        </div>
      </div>
      <div class="img-wrapper">
        <img [src]="iconSrc" alt="" />
      </div>
    </div>
  </ng-container>

  <ng-template #summaryColumns>
    <div class="summary-column" *ngIf="firstName$ | async as firstName">
      <p
        [innerHTML]="'userSummary.firstName' | fms: { args: { firstName: firstName | formatName } }"
        data-hj-suppress
      ></p>
    </div>

    <div class="summary-column" *ngIf="userAge$ | async as age">
      <img [src]="'userSummary.age.iconSrc' | fms" alt />
      <p [innerHTML]="'userSummary.age' | fms: { args: { age } }" data-hj-suppress></p>
    </div>

    <div class="summary-column" *ngIf="pensionAgeArgs$ | async as pensionAgeArgs">
      <img [src]="'userSummary.timeUntilPensionAge.iconSrc' | fms" alt />
      <p
        [innerHTML]="'userSummary.timeUntilPensionAge' | fms: { args: pensionAgeArgs }"
        *ngIf="isLoaded"
        data-hj-suppress
      ></p>
      <app-text-skeleton *ngIf="!isLoaded"></app-text-skeleton>
    </div>

    <div class="summary-column">
      <img [src]="'userSummary.engagements.iconSrc' | fms" alt />
      <p [innerHTML]="numberOfEngagementsText$ | async" *ngIf="isLoaded"></p>
      <app-text-skeleton *ngIf="!isLoaded"></app-text-skeleton>
    </div>

    <div class="summary-column" *ngIf="isLongSinceLastStepVisit$ | async">
      <img [src]="'userSummary.lastStepVisit.iconSrc' | fms" alt />
      <p [innerHTML]="lastStepVisitText$ | async"></p>
    </div>

    <div class="summary-column">
      <img [src]="'userSummary.hasActiveEngagement.iconSrc' | fms" alt />
      <p [innerHTML]="hasSavings$ | async" *ngIf="isLoaded"></p>
      <app-text-skeleton *ngIf="!isLoaded"></app-text-skeleton>
    </div>

    <div class="summary-column" *ngIf="isQualifiedForSmartAccount$ | async">
      <img [src]="'userSummary.smartAccount.iconSrc' | fms" alt />
      <p [innerHTML]="'userSummary.smartAccount' | fms"></p>
    </div>

    <div class="summary-column" *ngIf="hasAnnualSalary$ | async">
      <img [src]="'userSummary.annualSalary.iconSrc' | fms" alt />
      <p [innerHTML]="annualSalaryText$ | async" data-hj-suppress></p>
    </div>

    <div class="summary-column" *ngIf="hasAfp$ | async">
      <img [src]="'userSummary.afp.iconSrc' | fms" alt />
      <p [innerHTML]="'userSummary.afp' | fms" data-hj-suppress></p>
    </div>

    <div class="summary-column" *ngIf="averagePercentOfSalay$ | async as averagePercentOfSalay">
      <img [src]="'userSummary.averagePercentOfSalay.iconSrc' | fms" alt />
      <p [innerHTML]="'userSummary.averagePercentOfSalary' | fms: { args: { averagePercentOfSalay } }"></p>
    </div>
  </ng-template>
</ng-container>
