import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { catchError, combineLatest, forkJoin, map, Observable, of, take, tap, timeout } from "rxjs";
import { switchMap } from "rxjs/operators";
import { SteppableModalStep } from "src/app/modules/shared/components/steppable-modal/dynamic-step-navigation.service";
import { FmsService } from "src/app/services/fms.service";
import { ConsentService } from "../../../../../services/consent.service";
import { CustomerService } from "../../../../../services/customer.service";
import { memoizeObject$ } from "../../../../../utils/rxjs/select";
import { getIsNotNullable, Nullable } from "../../../../../utils/utils";
import { ConsentRadio, getNavConsentRadio, getOfaConsentRadio } from "./onboarding-public-pension-step-radio-creator";

interface PublicPensionConsentsFormGroup {
  navConsent: FormControl<Nullable<boolean>>;
  ofaConsent: FormControl<Nullable<boolean>>;
}

@Component({
  selector: "app-onboarding-public-pension-step",
  templateUrl: "./onboarding-public-pension-step.component.html",
  styleUrls: ["../onboarding-step.component.scss", "./onboarding-public-pension-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingPublicPensionStepComponent implements SteppableModalStep {
  public readonly radios$: Observable<ConsentRadio[]>;

  private readonly formGroup: FormGroup<PublicPensionConsentsFormGroup> = new FormGroup({
    navConsent: new FormControl<Nullable<boolean>>(undefined, { validators: Validators.required }),
    ofaConsent: new FormControl<Nullable<boolean>>(undefined, { validators: Validators.required }),
  });

  private readonly navControl = this.formGroup.controls.navConsent;
  private readonly ofaControl = this.formGroup.controls.ofaConsent;
  private readonly initialNavValue$ = this.customerService.navConsent$.pipe(take(1));
  private readonly initialOfaValue$ = this.customerService.ofaConsent$.pipe(take(1));

  constructor(
    private readonly fmsService: FmsService,
    public readonly consentService: ConsentService,
    public readonly customerService: CustomerService,
  ) {
    const consentLabels$ = memoizeObject$(
      combineLatest([
        this.fmsService.translateAsync<string>("onboardingConsents.yes"),
        this.fmsService.translateAsync<string>("onboardingConsents.no"),
      ]).pipe(
        map(([yesLabel, noLabel]) => ({
          yesLabel,
          noLabel,
        })),
      ),
    );

    this.radios$ = combineLatest([this.initialNavValue$, this.initialOfaValue$, consentLabels$]).pipe(
      take(1),
      tap(([navConsent, ofaConsent]) => {
        this.navControl.setValue(navConsent);
        this.ofaControl.setValue(ofaConsent);
      }),
      map(([navConsent, ofaConsent, labels]) => [
        getNavConsentRadio(navConsent, labels, this.navControl),
        getOfaConsentRadio(ofaConsent, labels, this.ofaControl),
      ]),
    );
  }

  public beforeNext(): Observable<boolean> {
    if (this.formGroup.valid) {
      return combineLatest([this.postAndFetchConsentsIfChanged()]).pipe(
        timeout(5000),
        map(() => true),
        catchError(() => of(true)),
      );
    }

    this.markControlsAsTouched();
    return of(false);
  }

  public getTitle(): Observable<string> {
    return this.fmsService.translateAsync("onboardingPublicPensionStep.consentTitle");
  }

  private postAndFetchConsentsIfChanged(): Observable<CustomerMaster.Response.Customer | null> {
    return forkJoin([
      this.initialNavValue$.pipe(take(1)),
      this.initialOfaValue$.pipe(take(1)),
      of(this.navControl.value),
      of(this.ofaControl.value),
    ]).pipe(
      switchMap(([initialNavValue, initialOfaValue, currentNavValue, currentOfaValue]) => {
        const hasPendingUserChanges = currentNavValue !== initialNavValue || currentOfaValue !== initialOfaValue;
        if (hasPendingUserChanges && getIsNotNullable(currentNavValue) && getIsNotNullable(currentOfaValue)) {
          return this.consentService.postAndFetchNavAndOfaConsent(currentNavValue, currentOfaValue);
        }

        return of(null);
      }),
    );
  }

  private markControlsAsTouched(): void {
    this.navControl.markAsTouched();
    this.ofaControl.markAsTouched();
    this.navControl.updateValueAndValidity();
    this.ofaControl.updateValueAndValidity();
  }
}
