import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable, map, startWith } from "rxjs";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE } from "../../../../../constants/business.constants";

interface WithdrawalPercentages {
  viewValue: string;
  value: number;
}

@Component({
  selector: "app-public-pension-withdrawal-percentage-form-field",
  templateUrl: "./public-pension-withdrawal-percentage-form-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicPensionWithdrawalPercentageFormFieldComponent {
  @Input()
  public readonly disabled = false;

  public readonly withdrawalPercentages = getWithdrawalPercentages();
  public readonly selectedPercent$: Observable<number>;

  constructor(
    private readonly commonParametersService: CommonParametersService,
    private readonly pensionPlanService: PensionPlanService,
  ) {
    this.selectedPercent$ = this.commonParametersService.postPensionSimParams$.pipe(
      map(({ withdrawalPercentage }) => withdrawalPercentage),
      startWith(DEFAULT_PUBLIC_PENSION_WITHDRAWAL_PERCENTAGE),
    );
  }

  public async onWithdrawalPercentageChange(event: Event): Promise<void> {
    const value = (event.target as HTMLFormElement).value;
    const percentage = Number(value);

    return this.pensionPlanService.changePostPensionWithdrawalPercentage(percentage);
  }
}

function getWithdrawalPercentages(): WithdrawalPercentages[] {
  return [100, 80, 60, 50, 40, 20].map((value) => ({ viewValue: `${value}%`, value }));
}
