import { Pipe, PipeTransform } from "@angular/core";
import { Monitoring } from "src/app/utils/monitoring";
import { sanitizeCurrency } from "src/app/utils/utils";
import { isStageLocalhost } from "src/app/utils/storebrand-staging";

export enum Round {
  Up = "up",
  Down = "down",
}

@Pipe({ name: "roundNumber" })
export class RoundNumber implements PipeTransform {
  transform(input: string | number, toNearest: string | number, round?: Round): number | string {
    if (typeof input === "string" || typeof input === "number") {
      const number = sanitizeCurrency(input.toString());
      const toNearestNumber = sanitizeCurrency(toNearest.toString());
      if (Number.isFinite(number) && Number.isFinite(toNearestNumber)) {
        switch (round) {
          case Round.Up:
            return Math.ceil(number / toNearestNumber) * toNearestNumber;
          case Round.Down:
            return Math.floor(number / toNearestNumber) * toNearestNumber;
          default:
            return Math.round(number / toNearestNumber) * toNearestNumber;
        }
      }
    }
    Monitoring.error(
      new Error(
        `Expected string or number in round-number-pipe, got
        input: ${JSON.stringify(input)} (${typeof input})
        toNearest: ${JSON.stringify(toNearest)} (${typeof toNearest})`,
      ),
      { ignore: isStageLocalhost() },
    );
    return input;
  }
}
