import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Observable } from "rxjs";
import { combineLatestWith, map, switchMap } from "rxjs/operators";
import { LARGE_DIALOG_WINDOW_WIDTH } from "src/app/constants/technical.constants";
import { OffentligTjenestepensjonEngagementComponent } from "src/app/modules/shared/components/add-engagement/offentlig-tjenestepensjon-engagement/offentlig-tjenestepensjon-engagement.component";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { NorskpensjonService } from "src/app/services/prognoses-services/norskpensjon.service";

@Component({
  selector: "app-offentlig-tjeneste-cta-card",
  templateUrl: "./offentlig-tjenestepensjon-cta-card.component.html",
  styleUrls: ["./offentlig-tjenestepensjon-cta-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffentligTjenestepensjonCtaCardComponent {
  public showCtaCard$: Observable<boolean>;
  public title$: Observable<string>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly engagementsService: EngagementsService,
    private readonly clientDataService: ClientDataService,
    private readonly norskpensjonService: NorskpensjonService,
    private readonly fmsService: FmsService,
  ) {
    this.showCtaCard$ = this.engagementsService.canManuallyAddOffentligTjenestepensjon$.pipe(
      combineLatestWith(this.clientDataService.optOutOffentligTjenestepensjon$),
      map(([canManuallyAdd, optOut]) => canManuallyAdd && optOut == null),
    );

    this.title$ = this.getTitle();
  }

  public openDialog(): void {
    this.dialog.open(OffentligTjenestepensjonEngagementComponent, {
      width: LARGE_DIALOG_WINDOW_WIDTH,
      panelClass: "large-dialog-padding",
      autoFocus: false,
    });
  }

  public setOptOutTrue(): void {
    this.clientDataService.updateOptOutOffentligTjenestepensjon(true);
  }

  private getTitle(): Observable<string> {
    return this.norskpensjonService.getProviderNameFromPublicPension().pipe(
      switchMap((providerName) =>
        this.fmsService.translateAsync<string>(
          providerName ? "ctaCard.offentligTjeneste.titleWithName" : "ctaCard.offentligTjeneste.title",
          {
            args: { name: providerName },
          },
        ),
      ),
    );
  }
}
