export const eligibleMock: SmartAccount.Eligibility = {
  payload: {
    eligible: true,
    requirement: {
      hasNoSmartAccount: true,
      hasStbPensionRelationship: true,
      isAboveAgeRequirement: true,
    },
  },
};
