import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  Input,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from "@angular/core";
import { isEqual } from "lodash-es";
import { Observable, combineLatest, switchMap } from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { FmsService } from "src/app/services/fms.service";
import { applyArrayTo } from "src/app/utils/applyArrayTo";
import { ActionConfigService } from "../../services/action-config.service";
import { ActionWindowService } from "../../services/action-window.service";
import { ACTION_COMPONENTS, ActionComponentClass, ActionKey, ActionsService } from "../../services/actions.service";
import { Action, ActionComponent, ActionRef } from "../actions/actions.component";

interface Category {
  name: string;
  alternativeName: string;
  description: string;
  id: number;
  order: number;
}

interface CategorizedActionRefs extends Category {
  actions: ActionRef[];
}

@Component({
  selector: "app-actions-widget",
  templateUrl: "./actions-widget.component.html",
  styleUrls: ["./actions-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ActionWindowService, ActionConfigService],
})
export class ActionsWidgetComponent {
  @Input()
  public categories = false;

  @ViewChild("actions", { static: true, read: ViewContainerRef })
  private readonly content!: ViewContainerRef;

  public actions$ = this.getActions();
  public categorizedActions$ = this.getCategorizedActions();
  public isLoaded$ = this.getIsLoaded();

  constructor(
    @Inject(ACTION_COMPONENTS)
    private readonly actionComponents: Map<ActionKey, ActionComponentClass>,
    private readonly actionsService: ActionsService,
    private readonly fmsService: FmsService,
  ) {}

  private getActions(): Observable<ActionRef[]> {
    return this.actionsService.getActionsFromFms().pipe(
      switchMap((actions) => this.getActionsRefs(actions)),
      distinctUntilChanged(isEqual),
    );
  }

  private getCategorizedActions(): Observable<CategorizedActionRefs[]> {
    return combineLatest([this.getCategoriesFromFms(), this.actions$]).pipe(
      map(applyArrayTo(addActionRefsToCategories)),
      map(overrideCategoryNames),
      map(removeEmptyCategories),
      distinctUntilChanged(isEqual),
    );
  }

  private getActionsRefs(actions: Action[]): Observable<ActionRef[]> {
    return combineLatest(
      actions
        .filter((action) => this.actionComponents.has(action.key))
        .map((action) => {
          const { componentRef } = this.createRefs(action);

          return componentRef.instance.getIsVisible().pipe(
            map((isVisible) => ({
              action,
              isVisible,
            })),
          );
        }),
    ).pipe(
      map((actionRefs) =>
        actionRefs
          .filter((actionRef) => actionRef.isVisible)
          .map((actionRef) => {
            const { componentRef, viewRef } = this.createRefs(actionRef.action);

            return {
              ...actionRef,
              componentRef,
              viewRef,
            };
          }),
      ),
    );
  }

  private getCategoriesFromFms(): Observable<Category[]> {
    return this.fmsService
      .translateAsync<Category[]>("actions.categories")
      .pipe(map((categories) => [...categories].sort(byAscendingOrder)));
  }

  private getIsLoaded(): Observable<boolean> {
    return this.actions$.pipe(
      map(() => true),
      startWith(false),
    );
  }

  private createRefs(action: Action): {
    componentRef: ComponentRef<ActionComponent>;
    viewRef: ViewRef;
  } {
    //Skips handeling undefined componentClass
    const componentClass = this.actionComponents.get(action.key) as ActionComponentClass;
    const componentRef = this.content.createComponent(componentClass);

    //Skips handeling null ViewRef
    const viewRef = this.content.detach() as ViewRef;

    // eslint-disable-next-line fp/no-mutation
    componentRef.instance.action = action;

    return {
      componentRef,
      viewRef,
    };
  }
}

function addActionRefsToCategories(categories: Category[], actionRefs: ActionRef[]): CategorizedActionRefs[] {
  return categories.map((category) => ({
    ...category,
    actions: getActionRefsForCategory(actionRefs, category),
  }));
}

function overrideCategoryNames(categorizedActions: CategorizedActionRefs[]): CategorizedActionRefs[] {
  return categorizedActions.map((categorizedAction, index) => {
    const isFirstCategory = index === 0;

    if (isFirstCategory) {
      return categorizedAction;
    }

    const previousCategory = categorizedActions.at(index - 1);
    const previousHasNoActions = previousCategory?.actions.length === 0;

    return {
      ...categorizedAction,
      name: previousHasNoActions ? categorizedAction.alternativeName : categorizedAction.name,
    };
  });
}

function removeEmptyCategories(categorizedActions: CategorizedActionRefs[]): CategorizedActionRefs[] {
  return categorizedActions.filter(({ actions }) => actions.length > 0);
}

function getActionRefsForCategory(actionRef: ActionRef[], category: Category): ActionRef[] {
  return actionRef.filter(({ action }) => action.categoryId === category.id);
}

function byAscendingOrder<T extends { order: number }>(a: T, b: T): number {
  return a.order - b.order;
}
