<section class="mat-typography">
  <app-information-message [alertKey]="AlertKey.Dashboard"></app-information-message>

  <h1>{{ "errorspage.headline" | fms }}</h1>

  <p>{{ "errorspage.information" | fms }}</p>

  <button stbButton="contained" (click)="reloadPage()">
    {{ "errorspage.button.reload" | fms }}
  </button>

  <img alt [src]="'errorspage.image' | fms" />
</section>
