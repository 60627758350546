import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { firstValueFrom, mergeMap, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FmsService } from "src/app/services/fms.service";
import { SmartAccountInterestService } from "src/app/services/smart-account-interest.service";
import { SmartAccountService, SmartAccountState } from "src/app/services/smart-account.service";
import { Action, ActionComponent } from "../actions/actions.component";
import { ActionSmartAccountAboutDialogComponent } from "./action-smart-account-about-dialog/action-smart-account-about-dialog.component";

@Component({
  selector: "app-action-smart-account",
  templateUrl: "./action-smart-account.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSmartAccountComponent implements ActionComponent {
  @Input()
  public action!: Action;

  public content$: Observable<string>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly smartAccountService: SmartAccountService,
    private readonly smartAccountInterestService: SmartAccountInterestService,
    private readonly fmsService: FmsService,
  ) {
    this.content$ = this.smartAccountInterestService.getNewCustomerFormattedInterest().pipe(
      mergeMap((newCustomerInterest) =>
        this.fmsService.translateAsync<string>("actions.smartAccount.contents", {
          args: { newCustomerInterest },
        }),
      ),
    );
  }

  public getIsVisible(): Observable<boolean> {
    return this.smartAccountService.smartAccountState$.pipe(map((state) => state === SmartAccountState.Qualified));
  }

  public async openSmartAccountCreatorClient(): Promise<void> {
    const url = await firstValueFrom(this.fmsService.translateAsync<string>("smartAccount.creatorClient.createNewUrl"));

    window.open(url, "_self");
  }

  public openAboutDialog(): void {
    this.dialog.open<ActionSmartAccountAboutDialogComponent, Action>(ActionSmartAccountAboutDialogComponent, {
      data: this.action,
    });
  }
}
