import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import { FmsService } from "src/app/services/fms.service";
import { IncomeService } from "src/app/services/income/income.service";
import { OnboardingService, OnboardingStepKey } from "src/app/services/onboarding.service";
import { getIsNullable } from "src/app/utils/utils";

@Component({
  selector: "app-norskpensjon-consent-cta-card",
  templateUrl: "./norskpensjon-consent-cta-card.component.html",
  styleUrls: ["./norskpensjon-consent-cta-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NorskpensjonConsentCtaCardComponent {
  @Input()
  public title!: string;
  @Input()
  public enableSalaryRequirement: boolean = true;

  private readonly annualGrossIncome$: Observable<number | undefined>;

  constructor(
    private readonly fmsService: FmsService,
    private readonly incomeService: IncomeService,
    private readonly onboardingService: OnboardingService,
  ) {
    this.annualGrossIncome$ = this.incomeService.annualGrossIncome$;
  }

  public openConsent(): void {
    const steps = [OnboardingStepKey.NorskPensjonAndStorageConsent];

    this.annualGrossIncome$.pipe(take(1)).subscribe((annualGrossIncome) => {
      if (annualGrossIncome === null && this.enableSalaryRequirement) {
        steps.unshift(OnboardingStepKey.Salary);
      }

      this.onboardingService.open({}, steps);
    });
  }

  public getTitle(): string {
    return this.title ?? this.fmsService.instant("norskPensjonConsentCard.title");
  }

  public getDescription(): Observable<string> {
    return this.annualGrossIncome$.pipe(
      map((grossIncome) => getDescriptionFmsKey(grossIncome, this.enableSalaryRequirement)),
      mergeMap((fmsKey) => this.fmsService.translateAsync(fmsKey)),
    );
  }
}

function getDescriptionFmsKey(annualGrossIncome: number | undefined, enableSalaryRequirement: boolean): string {
  return getIsNullable(annualGrossIncome) && enableSalaryRequirement
    ? "norskPensjonConsentCard.description.consentAndSalary"
    : "norskPensjonConsentCard.description.consent";
}
