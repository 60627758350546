import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { UnavailableNorskpensjonEngagement } from "src/app/models/engagements/norskpensjon/unavailable-norskpensjon-engagement.model";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import {
  AbstractPrognosisFetchService,
  FetchPrognosisResult,
  ServiceConfig,
} from "src/app/services/prognoses-services/abstract-prognosis-fetch.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { NorskPensjonQueriesService } from "../api/norskpensjon-queries.service";
import { NorskpensjonRequirementsService } from "./norskpensjon-requirements.service";

export const UNP_CONFIG: ServiceConfig = {
  name: "UnavailableNorskpensjonService",
  fmsKey: "norskpensjon",
};

@Injectable({
  providedIn: "root",
})
export class UnavailableNorskpensjonService extends AbstractPrognosisFetchService<
  UnavailableNorskpensjonEngagement,
  {}
> {
  constructor(
    commonParametersService: CommonParametersService,
    prognosesFetchSpinnerService: FetchPrognosesRunningJobsService,
    private readonly norskPensjonQueriesService: NorskPensjonQueriesService,
    private readonly norskpensjonRequirementsService: NorskpensjonRequirementsService,
  ) {
    super(UNP_CONFIG, commonParametersService, prognosesFetchSpinnerService);
  }

  public fetchPrognosis(
    engagement: UnavailableNorskpensjonEngagement,
  ): Observable<FetchPrognosisResult<UnavailableNorskpensjonEngagement>> {
    const status = engagement.getSimulationStatus().at(0);
    const { key, severity } = status ?? { key: null, severity: null };
    const prognosis: Graph.Prognosis = {
      payoutPlan: [],
      simulationStatus: {
        severity,
        messageKey: key,
      },
    };

    return of({ prognoses: [prognosis] });
  }

  protected _fetchEngagements(): Observable<UnavailableNorskpensjonEngagement[]> {
    const defaultContracts: Graph.UnavailablePensionProvider[] = [];
    return this.norskpensjonRequirementsService.getLatestHasAllRequirements().pipe(
      mergeMap((hasAccess) =>
        hasAccess
          ? this.norskPensjonQueriesService
              .getUnavailableNorskpensjonContracts()
              .pipe(catchError(() => of(defaultContracts)))
          : of(defaultContracts),
      ),
      map((contracts) => contracts.map((contract) => new UnavailableNorskpensjonEngagement(contract))),
    );
  }

  protected prognosisSimParams$(): Observable<null> {
    return of(null);
  }
}
