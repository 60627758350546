import { Injectable } from "@angular/core";
import { AnyEngagement } from "src/app/services/engagements.service";
import { FmsService } from "src/app/services/fms.service";
import { isEkstrapensjonEngagement, isFondskontoLinkEngagement } from "src/app/utils/engagement.typeguards";
import { ContractActionBuilder, ExternalLink } from "../contract-actions.types";

@Injectable()
export class EkstrapensjonWithdrawalActionBuilder implements ContractActionBuilder {
  constructor(private readonly fmsService: FmsService) {}

  public predicate(engagement: AnyEngagement): boolean {
    return isEkstrapensjonEngagement(engagement) || isFondskontoLinkEngagement(engagement);
  }

  public build(engagement: AnyEngagement): ExternalLink {
    return {
      linkLabel: this.getLinkLabel(),
      linkUrl: this.getLinkUrl(engagement),
      linkTrackId: this.getLinkTrackId(),
    };
  }

  private getLinkLabel(): string {
    return this.fmsService.instant("contractActions.ekstraPensjonWithdrawal.linkLabel");
  }

  private getLinkUrl(engagement: AnyEngagement): string {
    return this.fmsService.instant("contractActions.ekstraPensjonWithdrawal.linkUrl", {
      args: {
        contractNumber: engagement.getContractNumber(),
        exitUrl: window.location.href,
      },
    });
  }

  private getLinkTrackId(): string {
    return this.fmsService.instant("contractActions.ekstraPensjonWithdrawal.linkTrackId");
  }
}
