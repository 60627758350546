import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { RadioButton } from "@storeblocks/radio-buttons-ng";
import { FmsKey } from "../../../../../services/fms/fms";
import { stringToBoolean } from "../../../../../utils/utils";
import { ConsentRadio } from "../../onboarding/onboarding-public-pension-step/onboarding-public-pension-step-radio-creator";

export interface ConsentRadioProps {
  title: FmsKey;
  description: FmsKey;
  errorMessage: FmsKey;
  showError: boolean;
  currentConsentValue: boolean | undefined;
}

@Component({
  selector: "app-onboarding-consent",
  templateUrl: "./onboarding-consent.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingConsentComponent {
  @Input()
  public radio!: ConsentRadio;

  public setControl(control: FormControl, item: RadioButton): void {
    const booleanValue = stringToBoolean(item.value);
    control.markAsTouched();
    control.setValue(booleanValue);
  }
}
