import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as Graph from "src/app/services/api/savings-and-pension-queries.types";
import { AnyEngagement } from "src/app/services/engagements.service";
import { getIsNotNullable, Nullable } from "src/app/utils/utils";
import { EngagementName } from "../../pension.model";
import { SavingsAndPensionEngagement } from "./savings-and-pension-engagement.model";

type Contract = Graph.UcitsAccount;

/**
 * This includes askAccount (ASK), investorAccounts and IPA Fond (not in savingsGraph)
 */
export abstract class AbstractUCITSEngagement extends SavingsAndPensionEngagement<Contract> {
  /** @deprecated Use getNameAsync instead */
  public getName(): EngagementName {
    return this.getDefaultName({});
  }

  public getNameAsync(): Observable<EngagementName> {
    return this.getDefaultNameAsync({}).pipe(
      map((defaultName) => ({
        ...defaultName,
        name: /*this.contract.customerSuppliedContractName ??*/ defaultName.name,
      })),
    );
  }

  public getContractNumberCustomer(): Nullable<string> {
    return this.contract?.accountId;
  }

  public getContractNumber(): Nullable<string> {
    return this.contract?.accountId;
  }

  public isEqualTo(other: AnyEngagement): boolean {
    return other.getContractNumber() === this.getContractNumber();
  }

  public isSavingsEngagement(): boolean {
    return true;
  }

  public isActive(): boolean {
    return this.getActiveSavingsAgreements().length > 0;
  }

  public getActiveSavingsAgreements(): Graph.UcitsSavingAgreement[] {
    const savingAgreements = this.contract?.savingAgreements ?? [];
    return savingAgreements.filter((agreement) => agreement?.activeState?.active);
  }

  public hasChangeableProfile(): boolean {
    return false;
  }

  public getBalance(): Nullable<number> {
    const holdings = this.contract.holdings;
    const marketValue = holdings?.fundHoldingsTotal?.marketValue?.amount;
    const cashHoldings = holdings?.cashHoldings?.amount;

    if (getIsNotNullable(marketValue) && getIsNotNullable(cashHoldings)) {
      return marketValue + cashHoldings;
    }

    return marketValue ?? null;
  }

  getReturn(): number | undefined {
    const contract = this.contract;
    const depositInNok = contract?.askTaxDetails?.depositInNok;
    const unrealizedProfit = contract?.holdings?.fundHoldingsTotal?.unrealizedProfit?.amount;
    const balance = this.getBalance();
    const isBalanceZero = balance === 0;

    if (getIsNotNullable(balance) && getIsNotNullable(depositInNok)) {
      return balance - depositInNok;
    }

    if (!getIsNotNullable(unrealizedProfit)) {
      return unrealizedProfit ?? undefined;
    }

    // When the balance is zero we assume the return to be zero as well
    if (isBalanceZero) {
      return 0;
    }

    return undefined;
  }
}

export class AskEngagement extends AbstractUCITSEngagement {}
