import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StorebrandOnlyService } from "src/app/services/storebrand-only.service";
import { getFirstItem, getIsNotEmpty } from "src/app/utils/array";
import { BehaviorStore } from "src/app/utils/rxjs/store";
import { CurrentSimulation, CurrentSimulationSelectorService } from "./current-simulation-selector.service";

export enum ChangeReason {
  NotSpecified = "NotSpecified",
  StartPayoutAgeChanged = "StartPayoutAgeChanged",
  StartPayoutAgePartialWithdrawalChanged = "StartPayoutAgePartialWithdrawalChanged",
  EngagementSimulationChanged = "EngagementSimulationChanged",
  IncludedEngagement = "IncludedEngagement",
  ExcludedEngagement = "ExcludedEngagement",
  AddedEngagement = "AddedEngagement",
  EditedEngagement = "EditedEngagement",
  DeletedEngagement = "DeletedEngagement",
  PostPensionFutureSalary = "PostPensionFutureSalary",
  PostPensionWithdrawalPercentage = "PostPensionWithdrawalPercentage",
  PostPensionPartTimeEnableChanged = "PostPensionPartTimeEnableChanged",
  PostPensionPartTimeEndAgeChanged = "PostPensionPartTimeEndAgeChanged",
  PostPensionPartTimePercentChanged = "PostPensionPartTimePercentChanged",
  WithdrawalProfileChanged = "WithdrawalProfileChanged",
  PartialWithdrawalPercentage = "PartialWithdrawalWithdrawalPercentage",
  PartialWithdrawalPartTimePercentage = "PartialWithdrawalPartTimePercentage",
}

export interface PreviousSimulation extends CurrentSimulation {
  readonly changeReason: ChangeReason;
}

@Injectable({
  providedIn: "root",
})
export class SimulationHistoryService {
  public readonly simulationHistory$: Observable<PreviousSimulation[]>;
  private readonly simulationHistoryStore$: BehaviorStore<PreviousSimulation[]>;

  constructor(
    private readonly currentSimulationSelectorService: CurrentSimulationSelectorService,
    private readonly storebrandOnlyService: StorebrandOnlyService,
  ) {
    this.simulationHistoryStore$ = new BehaviorStore(<PreviousSimulation[]>[]);
    this.simulationHistory$ = this.simulationHistoryStore$.asObservable();

    this.clearHistoryOnStorebrandOnlyChange();
  }

  public getHasPreviousSimulation(): Observable<boolean> {
    return this.simulationHistory$.pipe(map(getIsNotEmpty));
  }

  public getPreviousSimulation(): Observable<PreviousSimulation | undefined> {
    return this.simulationHistory$.pipe(map(getFirstItem));
  }

  public async saveCurrentSimulation(changeReason = ChangeReason.NotSpecified): Promise<void> {
    const currentSimulation = await firstValueFrom(this.currentSimulationSelectorService.select());
    const previousSimulation: PreviousSimulation = {
      ...currentSimulation,
      changeReason,
    };

    this.addPreviousSimulation(previousSimulation);
  }

  public clearSimulationHistory(): void {
    this.simulationHistoryStore$.next([]);
  }

  private addPreviousSimulation(item: PreviousSimulation): void {
    this.simulationHistoryStore$.next([item, ...this.simulationHistoryStore$.getValue()]);
  }

  private clearHistoryOnStorebrandOnlyChange(): void {
    this.storebrandOnlyService.getIsEnabled().subscribe(() => this.clearSimulationHistory());
  }
}
