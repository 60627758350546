import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  ExternalSavingsEngagement,
  ExternalSavingsEngagementType,
} from "src/app/models/engagements/external-savings-engagement.model";
import { ExternalEngagementsService } from "src/app/services/prognoses-services/external-engagements.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { tickerPipe } from "src/app/utils/rxjs/ticker";

@Component({
  selector: "app-external-engagement-confirmation",
  templateUrl: "./external-engagement-confirmation.component.html",
  styleUrls: [
    "./external-engagement-confirmation.component.scss",
    "../../../../../shared/components/add-engagement/add-engagement.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalEngagementConfirmationComponent {
  public injectedEngagementBalance$: Observable<number>;
  public SavingsType = ExternalSavingsEngagementType;
  public startPayoutAge$: Observable<number>;

  private readonly injectedEngagement$: Observable<ExternalSavingsEngagement | undefined>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public engagementData: CustomerSuppliedData.ExternalSaving,
    private readonly externalEngagementsService: ExternalEngagementsService,
    private readonly startPayoutAgeService: StartPayoutAgeService,
  ) {
    this.injectedEngagement$ = this.externalEngagementsService.engagements$.pipe(
      map((engagements) =>
        engagements.find(
          (externalSavingsEngagement) => externalSavingsEngagement.getIdentifier() === this.engagementData.id,
        ),
      ),
    );

    this.startPayoutAge$ = this.startPayoutAgeService.getStartPayoutAge();

    this.injectedEngagementBalance$ = this.injectedEngagement$.pipe(
      map((engagement) => engagement?.getTotalPayout() ?? 0),
      tickerPipe(),
      startWith(0),
    );
  }

  public getInjectedEngagement(): Observable<ExternalSavingsEngagement[]> {
    return this.injectedEngagement$.pipe(map((engagement) => (engagement !== undefined ? [engagement] : [])));
  }
}
