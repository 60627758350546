export const HIGHCHARTS_UPDATE_DEBOUNCE_VALUE = 500;
export const DEFAULT_INPUT_DEBOUNCE_TIME = 1000;
export const DEFAULT_RECOMMENDATION_DEBOUNCE_TIME = 2000;
export const DEFAULT_BATCHER_DEBOUNCE_TIME = 300;

/**
 * Number of concurrent requests  from _each_ prognosis service
 */
export const ICON_BASE_PATH = "https://elements.storebrand.no/din-pensjonsplan/svg";

/*
 * BreakpointObserver media queries
 */
export const OBSERVER_BREAKPOINT_MD_MAX = `(max-width: ${1128}px)`;
export const OBSERVER_BREAKPOINT_MD_MIN = `(min-width: ${600}px)`;

export const NON_BREAKING_SPACE = "\xA0";
export const MINUS_SIGN = "\u2212";

export const LARGE_DIALOG_WINDOW_WIDTH = "672px";
export const LARGER_DIALOG_WINDOW_WIDTH = "900px";

export const RUNNING_JOBS_WARNING_DEBOUNCE_TIME = 10000;
export const RUNNING_JOBS_DEBOUNCE_TIME = 750;

export const DEFAULT_TIMEOUT = 3000;

export const HYPHEN_SEPARATOR = " - ";

export const BULLET_SEPARATOR = " • ";

export const CUSTOMIZED_SIMULATION_AGE_DROPDOWN_VALUE = 0;

export const NUMBER_OF_PADDED_COLUMNS_IN_AGREEMENTS_CHART = 2;

export const HIGHCHARTS_YAXIS_BREAKPOINT = 600;
