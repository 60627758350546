import * as Highcharts from "highcharts";
import PatternFill from "highcharts/modules/pattern-fill";
import { Limit, PENSION_NEEDS_RANGED_START_DECIMAL } from "src/app/constants/business.constants";
import { RangedNeedsColors } from "src/app/constants/highcharts-colors.constants";
import { ChartTranslations } from "src/app/services/chart.service";
import { getIsNullable } from "src/app/utils/utils";
import {
  BLANK_XAXIS_POINT,
  defaultTooltipHeader,
  defaultTooltipPoint,
  toCurrency,
} from "../chart/chart.default-functions";

PatternFill(Highcharts);

export const payoutSeriesPointStart = 4;
export const needsAreaRangePointStart = 1;

interface PointWithRange extends Highcharts.Point {
  high: number;
  low: number;
}

enum ArearangeSeriesId {
  Foreground = "foreground",
  Background = "background",
}

function showTooltipsWithRangeWhenHasValue(
  this: Highcharts.TooltipFormatterContextObject,
  translations: ChartTranslations,
): string[] | false {
  if (getIsNullable(this.points) || this.points[0].point.category === BLANK_XAXIS_POINT) {
    return false;
  }

  const header = defaultTooltipHeader(this.x, translations);
  const points = this.points
    .filter((point, index) => {
      const pointY = point.point.y ?? 0;
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      const pointIndex = this.points!.findIndex(({ series }) => series.name === point.series.name);

      return pointY > 0 && pointIndex === index;
    })
    .map(({ point }) => {
      const low = point.options.low ?? 0;
      const high = point.options.high ?? 0;
      const isArearange = !!low;
      const arearangeValue = `${toCurrency(high)} - ${toCurrency(low)}`;

      return (
        `<hr class="tooltip-point-line">` +
        (isArearange ? defaultTooltipPoint(point, true, arearangeValue) : defaultTooltipPoint(point, true))
      );
    })
    .join("");

  return [header, points];
}

export function getOptions(categories: string[] = [], translations: ChartTranslations): Highcharts.Options {
  return {
    colors: [RangedNeedsColors.ColumnFill, RangedNeedsColors.AreaStroke],
    xAxis: {
      categories: categories,
    },
    tooltip: {
      formatter: function (): string[] | false {
        return showTooltipsWithRangeWhenHasValue.call(this, translations);
      },
    },
    plotOptions: {
      arearange: {
        marker: arearangeMarkerOptions,
      },
    },
  };
}

const arearangeMarkerOptions: Highcharts.PointMarkerOptionsObject = {
  enabled: false,
  symbol: "circle",
  fillColor: RangedNeedsColors.AreaMarkerFill,
  lineColor: RangedNeedsColors.AreaStroke,
  lineWidth: 2,
  states: {
    hover: {
      enabled: false,
    },
  },
};

const addPercentageDatalabelToFirstPoint = function (this: Highcharts.PointLabelObject): string | null {
  const isFirstPoint = this.point.x === needsAreaRangePointStart;
  const isLowPoint = this.y === (this.point as PointWithRange).low;
  const needsRangeInPercent: Limit = {
    min: PENSION_NEEDS_RANGED_START_DECIMAL.min * 100,
    max: PENSION_NEEDS_RANGED_START_DECIMAL.max * 100,
  };

  if (isFirstPoint) {
    const dataLabelPercentageNumber = isLowPoint ? needsRangeInPercent.min : needsRangeInPercent.max;

    return `<b>${dataLabelPercentageNumber}</b><span style="font-size: 14px"> %<span>`;
  }

  return null;
};

export const foregroundArearangeOptions: Partial<Highcharts.SeriesArearangeOptions> = {
  id: ArearangeSeriesId.Foreground,
  zIndex: 10,
  fillOpacity: 0,
  lineWidth: 2,
  states: {
    hover: {
      enabled: false,
    },
    select: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: true,
    xHigh: 15,
    xLow: 15,
    style: {
      color: RangedNeedsColors.DataLabel,
      fontSize: "18px",
    },
    formatter: addPercentageDatalabelToFirstPoint,
  },
};

export const backgroundArearangeOptions: Partial<Highcharts.SeriesArearangeOptions> = {
  id: ArearangeSeriesId.Background,
  linkedTo: ArearangeSeriesId.Foreground,
  showInLegend: false,
  fillColor: {
    pattern: {
      path: {
        d: "M 10 0 L 0 10 M 1 -1 L -1 1 M 11 9 L 9 11", //45 degree SVG stripes
        strokeWidth: 4,
      },
      width: 10,
      height: 10,
      color: RangedNeedsColors.AreaFill,
      id: "yellow-stripes", //Color disappear when chart resizes if an id is not given
      aspectRatio: 0,
      backgroundColor: "",
      image: "",
      opacity: 0.75,
      patternTransform: "",
    },
  },
};
