<ng-container *ngIf="!loading; else skeleton">
  <div class="contract-list" *appLet="isMobile$ | async as isMobile">
    <div
      class="engagement-panel"
      *appLet="isExpanded$ | async as isExpanded"
      [class.expanded]="isExpanded"
      [class.defaultExpanded]="defaultExpanded"
      [class.active]="contract.isActive()"
    >
      <mat-expansion-panel
        (expandedChange)="expandedChange($event)"
        [expanded]="isExpanded"
        [@.disabled]="disableAnimation"
        disabled
        hideToggle
      >
        <mat-expansion-panel-header class="mat-expansion-panel-header">
          <div class="header-title">
            <div class="header-title-details">
              <h4>
                {{ getHeader().title | titlecaseFirstWord }}
              </h4>
              <app-tip-icon
                *ngIf="showDescriptionTooltip && (description$ | async); let description"
                [tooltipText]="description"
                class="hide-only-on-print"
              ></app-tip-icon>
              <app-chip *ngIf="contract.isActive()" [text]="'engagement.active' | fms"></app-chip>
            </div>
            <app-supplier-text-or-logo class="tablet-or-larger" [engagement]="contract"></app-supplier-text-or-logo>
          </div>

          <div *ngIf="!defaultExpanded" class="header-secondary">
            <ng-container *ngIf="getHeader().subtitle?.length">
              <ng-container *ngIf="contract.isActive() && (hasEmployerAnnualSavingsAmount$ | async); else Payer"
                ><div *ngIf="isMobile" class="hint" data-hj-suppress>
                  {{ getSupplier() }}
                </div>
                <div
                  class="paragraph-long active-subtitle"
                  data-hj-suppress
                  [innerHtml]="
                    'contractsComponent.activeSubtitle'
                      | fms
                        : {
                            args: {
                              payer: getPayer(),
                              yearlySavings: getFormattedEmployerAnnualSavingsAmount$ | async
                            }
                          }
                  "
                ></div
              ></ng-container>

              <ng-template #Payer>
                <div *ngIf="isMobile && getHeader().subtitle" class="hint" data-hj-suppress>
                  {{ getHeader().subtitle }}
                </div>
                <div *ngIf="!isMobile && getPayer()" class="hint" data-hj-suppress>
                  {{ getPayer() }}
                </div>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="hasMovedEpkOut; else showBalanceAndReturn">
              <stb-inline-alert
                *ngIf="!isExpanded"
                [label]="'engagement.alert.hasMovedEpkOutFromSTB.title' | fms"
                width="fluid"
                type="info"
                rebrand="false"
              >
              </stb-inline-alert>
            </ng-container>

            <ng-template #showBalanceAndReturn>
              <app-contract-balance-and-total-return [engagement]="contract"></app-contract-balance-and-total-return>
            </ng-template>

            <div class="toggle-button">
              <app-expand-toggle-button
                *ngIf="!defaultExpanded"
                [isExpanded]="isExpanded"
                (toggleClick)="expandedChange(!isExpanded)"
              ></app-expand-toggle-button>
            </div>
          </div>
        </mat-expansion-panel-header>

        <app-contract-actions-container [engagement]="contract"></app-contract-actions-container>

        <stb-alert
          *ngIf="hasMovedEpkOut && isExpanded"
          [heading]="'engagement.alert.hasMovedEpkOutFromSTB.title' | fms"
          [text]="'engagement.alert.hasMovedEpkOutFromSTB.description' | fms"
          type="info"
          width="fluid"
          class="stb-mt2"
        ></stb-alert>

        <div class="stb-mt6 stb-mb4" *ngIf="showMarketValueChart$ | async">
          <app-market-value-chart [marketValueChartData$]="marketValueChartData$"></app-market-value-chart>
        </div>

        <app-contract-details-annual-return-pct
          *ngIf="annualReturn$ | async as annualReturn"
          [annualReturn]="annualReturn"
        ></app-contract-details-annual-return-pct>

        <app-engagement-details
          [engagement]="contract"
          [showSimulationParameters]="false"
          [showPensionPlanEntry]="contract.hasStipultatedBaseRatePayout()"
          [showDescription]="!showDescriptionTooltip"
          [hasError]="false"
        >
        </app-engagement-details>

        <app-link-to-details-page [engagement]="contract"></app-link-to-details-page>
      </mat-expansion-panel>

      <app-engagement-footer
        [engagement]="contract"
        tabindex="-1"
        (click)="expandedChange(!isExpanded)"
      ></app-engagement-footer>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="contract-list app-engagement-skeleton">
    <div class="engagement-panel">
      <div class="skeleton-bar"></div>
      <div class="skeleton-bar skeleton-bar-smaller"></div>
    </div>
  </div>
</ng-template>
